// @ts-nocheck

import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/system';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { selectColor, selectCommissionFilter, selectEmailFilter, selectLeadsFilter, selectThemeMode } from '../../../../store/selector';
import { FilterCommissionDataFtn, FilterDataFtn, FilterEmailDataFtn } from '../../../../store/slices';
import { ButtonFilter, FieldInput } from '../../../atoms';
import { globalColors } from '../../../../utils/constants/color';
import Dropdown from '../../../atoms/dropdown';

interface FilterStyleProps {
  mode: string;
}
interface FilterValues {
  role: string;
  commissionPriority: string;
  commissionLow: number;
  commissionHigh: number;
}

interface FormValuesErrors {
  role: string;
  commissionPriority: string;
  commissionLow: string;
  commissionHigh: string;
}

type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

interface FilterLeadsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function FilterCommission({ setOpen, open }: FilterLeadsProps) {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const filterData = useSelector(selectCommissionFilter);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleFilterValues = async (values: FilterValues) => {
    const { role, commissionPriority, commissionHigh, commissionLow } = values;
    dispatch(FilterCommissionDataFtn({ role, commissionPriority, commissionLow, commissionHigh }));
  };

  const handleClick = (event: any) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setAnchor(null);
        if (anchor === null) {
          setOpen(!open);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchor]);

  return (
    <>
      <Box
        // ref={setAnchor}
        onClick={() => setOpen(!open)}
        width={'100%'}
      >
        <ButtonFilter bgColor="black" onClick={handleClick} />
      </Box>
      <BasePopup anchor={anchor} open={open} withTransition>
        {(props: any) => (
          <PopAnimation {...props}>
            <PopupBody mode={mode} ref={popupRef}>
              <Formik
                initialValues={{
                  role: filterData.role || 'Select',
                  commissionPriority: filterData.commissionPriority || 'Select',
                  commissionHigh: filterData.commissionHigh || 100,
                  commissionLow: filterData.commissionLow || 0,
                }}
                validate={(values) => {
                  const errors = {} as FormValuesErrors;

                  if (values.commissionHigh > 100 || values.commissionHigh < 0) {
                    errors.commissionHigh = 'Value must be between 0 and 100';
                  }
                  if (values.commissionLow > 100 || values.commissionLow < 0) {
                    errors.commissionLow = 'Value must be between 0 and 100';
                  }
                  return errors;
                }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  await handleFilterValues(values);

                  resetForm({
                    values: {
                      role: 'Select',
                      commissionPriority: 'Select',
                      commissionHigh: 100,
                      commissionLow: 0,
                    },
                  });
                  setOpen(false);
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form>
                    <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
                      {/* Role */}
                      {/* <FieldInput
                        checked={values?.role !== ''}
                        label="Role"
                        showCheckBox={true}
                        mode={mode}
                        id="role"
                        name="role"
                        placeholder="Role"
                        type="text"
                      /> */}
                      <Dropdown
                        showCheckBox={true}
                        checked={values?.role !== 'Select'}
                        label="Role"
                        mode={mode}
                        id="role"
                        name="role"
                        values={['Select', 'Executive', 'Manager', 'Admin']}
                        defaultValue={values.role || 'Select'}
                        onUnchecked={() => {
                          values.role = 'Select';
                          const { role, commissionPriority, commissionHigh, commissionLow } = values;
                          dispatch(FilterCommissionDataFtn({ role, commissionPriority, commissionLow, commissionHigh }));
                        }}
                      />

                      {/* Assigned Leads */}
                      {/* <FieldInput
                        checked={values?.assignedLeads !== ''}
                        label="Assigned"
                        showCheckBox={true}
                        mode={mode}
                        id="assignedLeads"
                        name="assignedLeads"
                        placeholder="Assigned Leads"
                        type="text"
                      /> */}
                      <Dropdown
                        showCheckBox={true}
                        checked={values?.commissionPriority !== 'Select'}
                        label="Commission Priority"
                        mode={mode}
                        id="commissionPriority"
                        name="commissionPriority"
                        values={['Select', 'None', 'Low', 'Medium', 'High']}
                        defaultValue={values.commissionPriority || 'Select'}
                        onUnchecked={() => {
                          values.commissionPriority = 'Select';
                          const { role, commissionPriority, commissionHigh, commissionLow } = values;
                          dispatch(FilterCommissionDataFtn({ role, commissionPriority, commissionLow, commissionHigh }));
                        }}
                      />
                      <FieldInput
                        checked={true}
                        label="Minimum Commission (%)"
                        showCheckBox={true}
                        mode={mode}
                        id="commissionLow"
                        name="commissionLow"
                        placeholder="Min"
                        type="number"
                        min={0}
                        max={100}
                      />
                      {touched.commissionLow && errors.commissionLow && (
                        <Typography color={'red'} fontSize={'12px'} marginTop={'2px'}>
                          {errors.commissionLow}
                        </Typography>
                      )}
                      <FieldInput
                        showCheckBox={true}
                        label="Maximum Commission (%)"
                        checked={true}
                        mode={mode}
                        id="commissionHigh"
                        name="commissionHigh"
                        placeholder="Max"
                        type="number"
                        min={0}
                        max={100}
                      />
                      {touched.commissionHigh && errors.commissionHigh && (
                        <Typography color={'red'} fontSize={'12px'} marginTop={'2px'}>
                          {errors.commissionHigh}
                        </Typography>
                      )}

                      <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
                        <Button
                          sx={{
                            border: `1px solid ${color || globalColors.blue}`,
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            width: '100%',
                            borderRadius: '12px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            '&:hover': {
                              backgroundColor: color || globalColors.blue,
                            },
                          }}
                          type="button"
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Loading...' : 'Apply'}
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </PopupBody>
          </PopAnimation>
        )}
      </BasePopup>
    </>
  );
}

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div onAnimationEnd={handleAnimationEnd} className={className + (requestOpen ? ' open' : ' close')}>
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled('div')<FilterStyleProps>(
  ({ theme, mode }) =>
    `
    width: max-content;
    padding: 0.5rem 1rem;
    margin: 8px;
    // border: 1px solid ${mode === 'dark' ? '' : ''};
     background-color: ${mode === 'dark' ? globalColors.black : globalColors.lightgray};
    border-radius: 8px;
     box-shadow: ${mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);
