import { Box, Typography, Stack, TableContainer, TableBody, TableRow, Table, TableHead, Paper, Checkbox, SelectChangeEvent } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCameraVideo, BsEyeFill, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import { FormControl, Select, MenuItem } from '@mui/material';

import { useDispatch } from 'react-redux';

import moment from 'moment';
import LeadModel from '../../../../utils/api/models/leadModel';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import { globalColors } from '../../../../utils/constants/color';
import { LoadingFtn } from '../../../../store/slices';
import { getColorForPriority, getColorForStatus } from '../../../../utils/helpers';
import { ContactToolTip } from '../../../atoms/contactTooltip';
import Loader from '../../../atoms/loader';
import ModalDetailLead from '../../../organisms/leads/modalDetailLead';
import NotFoundLottie from '../../../atoms/notFound';

interface ConversionsListRowProps {
  mode: string;
  color: string;
  content: LeadModel;
  setloading: (isLoading: boolean) => void;
  edit: boolean;
  setOpenBulkEditLeadModal: (isLoading: boolean) => void;
  openBulkEditLeadModal: boolean;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  show: string;
  setShow: (show: string) => void;
}

interface ConversionsListProps {
  mode: string;
  color: string;
  search: string;
  loading: boolean;
  openBulkEditLeadModal: boolean;
  edit: boolean;
  showLead: LeadModel[];
  setloading: (isLoading: boolean) => void;
  setOpenBulkEditLeadModal: (isLoading: boolean) => void;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  showAssignedTo?: boolean;
}

const ConversionsListRow: React.FC<ConversionsListRowProps> = ({
  mode,
  content,
  color,
  setloading,
  edit,
  rowCheckboxState,
  setRowCheckboxState,
  setOpenBulkEditLeadModal,
  openBulkEditLeadModal,
  show,
  setShow,
}) => {
  const [openDeleteLeadModal, setOpenDeleteLeadModal] = useState<boolean>(false);
  const [openDetailLeadModal, setOpenDetailLeadModal] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(true);
  const dispatch = useDispatch();

  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();
  const handleOpenModalDeleteLeads = () => setOpenDeleteLeadModal(true);
  const handleOpenModalDetailsLeads = () => setOpenDetailLeadModal(true);
  const [leadId, setleadId] = useState<string>('');
  const [changeStatus, setChangeStatus] = useState<string>(content?.status ?? 'New');
  const [newStatus, setNewStatus] = useState<string>('');
  const [changePriority, setChangePriority] = useState<string>(content?.priority ?? 'Cold');
  const [latestCommunication, setLatestCommunication] = useState<{ type: string; note: string; createdAt: string; doneBy: string } | null>(null);
  const [communicationLoading, setCommunicationLoading] = useState(true);
  const [revealCommunication, setRevealCommunication] = useState(
    currentUser && (currentUser.get('role') === UserRole.admin || content.assignedTo.id === currentUser.id)
  );

  const handleDeleteLeads = () => {
    const leadRepository = new LeadRepository();
    if (content?.id) {
      const leadIdToDelete = content?.id;

      leadRepository
        .delete(leadIdToDelete)
        .then((result) => {
          if (result) {
            setOpenDeleteLeadModal(false);
            setloading(true);
          } else {
            setOpenDeleteLeadModal(false);
          }
        })
        .catch((error) => {
          console.error('Error deleting lead:', error);
        });
    }
  };

  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, leadId: string | undefined) => {
    if (leadId) {
      const checked = event.target.checked;
      setRowCheckboxState((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [leadId]: checked,
      }));
    }
  };

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const navigate = useNavigate();

  const fetchLatestCommunication = async () => {
    setCommunicationLoading(true);
    const leadRepository = new LeadRepository();
    const latest = await leadRepository.getLatestCommunication(content?.id ?? '');
    if (latest) {
      setLatestCommunication({
        type: latest.get('method'),
        doneBy: latest.get('doneBy').get('firstName') + ' ' + latest.get('doneBy').get('lastName'),
        createdAt: moment(latest.get('createdAt')).fromNow(),
        note: latest.get('note').get('body'),
      });
    }
    setCommunicationLoading(false);
  };

  useEffect(() => {
    fetchLatestCommunication();
  }, []);

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,

          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '10px',
          gap: '20px',
          marginBottom: '20px',
          paddingBottom: '20px',
          borderBottom: show !== content?.id ? `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}` : '',
        }}
        key={content.id}
      >
        <TableCell sx={style} component="th" scope="row">
          {currentUser?.get('role') !== UserRole.executive && edit && content && content.id && (
            <Checkbox
              size="small"
              sx={{
                color: color || globalColors.blue,
                '&.Mui-checked': {
                  color: color || globalColors.secondBlue,
                },
              }}
              onChange={(event) => {
                handleRowCheckboxChange(event, content?.id);
              }}
              checked={rowCheckboxState[content?.id] || false}
            />
          )}
        </TableCell>
        <TableCell sx={style} component="th" scope="row">
          {content?.firstName + ' ' + content?.lastName}
        </TableCell>
        <TableCell sx={style}>
          {content?.assignedTo ? `${content?.assignedTo.get('firstName')} ${content?.assignedTo.get('lastName')}` : 'ASSIGN TO'}
        </TableCell>
        <TableCell sx={style}>{content?.category}</TableCell>
        <TableCell sx={style}>
          <Typography
            color={getColorForPriority(changePriority) ?? (mode === 'dark' ? globalColors.white : globalColors.black)}
            fontSize={'12px'}
            marginTop={'auto'}
            marginBottom={'auto'}
          >
            {changePriority}
          </Typography>
        </TableCell>
        <TableCell sx={style}>
          <Typography
            color={getColorForStatus(changeStatus) ?? (mode === 'dark' ? globalColors.white : globalColors.black)}
            fontSize={'12px'}
            marginTop={'auto'}
            marginBottom={'auto'}
          >
            {changeStatus}
          </Typography>
        </TableCell>
        <TableCell sx={style}>
          <Stack fontSize={'12px'} gap="10px" direction={'row'}>
            {content.primaryPhone &&
              (revealCommunication ? (
                <ContactToolTip value={content.primaryPhone} href={`tel:${content.primaryPhone}`} action={'Call'}>
                  <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BsTelephone size={'1rem'} color={color || globalColors.blue} />
              ))}
            {content.secondaryPhone &&
              (revealCommunication ? (
                <ContactToolTip value={content.secondaryPhone} href={`tel:${content.secondaryPhone}`} action={'Call'}>
                  <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BsTelephone size={'1rem'} color={color || globalColors.blue} />
              ))}
            {content.email &&
              (revealCommunication ? (
                <ContactToolTip value={content.email} href={`mailto:${content.email}`} action={'Email'}>
                  <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
              ))}
          </Stack>
        </TableCell>
        <TableCell sx={style}>
          {communicationLoading ? (
            <Box display={'flex'} justifyContent={'start'} alignItems={'center'} height={'10px'}>
              <Loader size="14px" />
            </Box>
          ) : (
            <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={'10px'}>
              <Typography fontSize={'12px'}>{latestCommunication?.type ?? '-'}</Typography>
              {latestCommunication?.type === 'Call' && <BsTelephone size={'1rem'} color={color || globalColors.blue} />}
              {latestCommunication?.type === 'Email' && <BiEnvelope size={'1rem'} color={color || globalColors.blue} />}
              {latestCommunication?.type === 'Meeting' && <BsCameraVideo size={'1rem'} color={color || globalColors.blue} />}
            </Box>
          )}
        </TableCell>
        <TableCell sx={style}>
          <Box display={'flex'} gap={'10px'}>
            <BsEyeFill
              size={'1.3rem'}
              color={color || globalColors.blue}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowDetail(true);
                handleOpenModalDetailsLeads();
                setleadId(content?.id || '');
              }}
            />
            {currentUser?.get('role') !== UserRole.executive && (
              <MdDelete
                onClick={() => {
                  handleOpenModalDeleteLeads();
                  setleadId(content?.id || '');
                }}
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell sx={{ cursor: 'pointer' }} onClick={() => setShow(content?.id === show ? '' : content?.id ?? '')}>
          <AiOutlineCaretDown
            style={{ rotate: show === content?.id ? '180deg' : '' }}
            size="1rem"
            color={mode === 'dark' ? color || globalColors.white : color || globalColors.black}
          />
        </TableCell>
      </TableRow>
      {show === content?.id && (
        <TableRow
          sx={{
            borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
            backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '10px',
            gap: '20px',
            transition: 'height 0.9s ease-in-out',
            transitionDelay: '0.9s',
            height: show === content?.id ? 'auto' : '0',
            overflow: 'hidden',
          }}
        >
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell
            sx={{
              color: color || globalColors.blue,
              verticalAlign: 'top',
              fontWeight: '400',
              fontSize: '12px',
            }}
          >
            {content.propertyProject?.get('name') ?? '-'}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell
            sx={{
              color: color || globalColors.blue,
              verticalAlign: 'top',
              fontWeight: '400',
              fontSize: '12px',
            }}
          >
            {content.primaryPhone}
            <br />
            {content.email}
          </TableCell>
          <TableCell
            sx={{
              color: globalColors.white,
              verticalAlign: 'top',
              fontWeight: '400',
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap="10px" maxWidth={'200px'}>
              <Box
                bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                color={mode === 'dark' ? globalColors.white : globalColors.black}
                padding={'10px'}
                borderRadius={'10px'}
                fontSize={'12px'}
              >
                {communicationLoading ? (
                  <Box minHeight={'60px'} display={'flex'} alignItems={'center'}>
                    <Loader size={'14px'} />
                  </Box>
                ) : latestCommunication === null ? (
                  <Box minHeight={'60px'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'12px'}>No communication found.</Typography>
                  </Box>
                ) : (
                  <Box>
                    <Box marginTop={'0.5rem'}>
                      <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                        {latestCommunication.note.length > 130 ? latestCommunication.note.substring(0, 127) + '...' : latestCommunication.note}
                      </Typography>
                      <Box display={'flex'} justifyContent={'space-between'} marginTop={'5px'}>
                        <span style={{ color: globalColors.gray, fontSize: '10px' }}>{latestCommunication.createdAt}</span>
                        <span style={{ color: color || globalColors.blue, fontSize: '10px' }}>{latestCommunication.doneBy}</span>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      )}
      <ModalDetailLead
        open={openDetailLeadModal}
        showDetail={showDetail}
        onClose={() => {
          setOpenDetailLeadModal(false);
          setNewStatus('');
        }}
        mode={mode}
        color={color}
        leadId={leadId}
        newStatus={newStatus}
      />
    </>
  );
};

const ConversionsDisabledList: React.FC<ConversionsListProps> = ({
  mode,
  loading,
  showLead,
  color,
  search,
  setloading,
  edit,
  setOpenBulkEditLeadModal,
  openBulkEditLeadModal,
  rowCheckboxState,
  setRowCheckboxState,
  showAssignedTo = true,
}) => {
  const [show, setshow] = useState<string>('');
  const headingdata = ['Name', 'Assigned To', 'Category', 'Priority', 'Status', 'Communication', 'Latest', 'Actions', ''];
  const headingdata2 = ['Name', 'Assign To', 'Category', 'Priority', 'Status', 'Communication', 'Latest', 'Actions', ''];

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const updatedRowCheckboxState = Object.fromEntries(showLead.map((lead) => [lead.id, checked]));
    setRowCheckboxState(updatedRowCheckboxState);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : showLead.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
            border: `1px solid ${mode === 'dark' && globalColors.border.gray}`,
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            paddingBottom: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            borderRadius: '10px',
            marginTop: '20px',
          }}
        >
          {
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    {edit && (
                      <Checkbox
                        sx={{
                          color: color || globalColors.blue,
                          '&.Mui-checked': {
                            color: color || globalColors.secondBlue,
                          },
                        }}
                        onChange={handleHeaderCheckboxChange}
                        checked={Object.values(rowCheckboxState).length > 0 && Object.values(rowCheckboxState).every((isChecked) => isChecked)}
                      />
                    )}
                  </TableCell>
                  {(showAssignedTo ? headingdata : headingdata2)?.map((val, idx) => (
                    <TableCell>
                      <Typography key={idx} fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                        {val}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {showLead?.map((val, idx) => (
                  <ConversionsListRow
                    mode={mode}
                    content={val}
                    color={color}
                    setloading={setloading}
                    edit={edit}
                    rowCheckboxState={rowCheckboxState}
                    setRowCheckboxState={setRowCheckboxState}
                    setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                    openBulkEditLeadModal={openBulkEditLeadModal}
                    show={show}
                    setShow={setshow}
                  />
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>
      ) : (
        <>
          {search !== '' ? (
            <Typography
              sx={{
                color: globalColors.blue,
                textAlign: 'center',
                fontSize: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              No Conversions Found
            </Typography>
          ) : (
            <NotFoundLottie
              showButton={true}
              text="Looks like you have no leads yet. Press the button below and add a new lead right now."
              buttonText="Add New Lead"
              navigateTo="/leads/new"
              setAddNewPath="/leads"
            />
          )}
        </>
      )}
    </>
  );
};

export default ConversionsDisabledList;
