import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import EmailTemplateModel from '../models/emailTemplateModel';
import UserRepository from './userRepository';

interface FormattedEmailTemplate {
  objectId: string;
  subject: string;
  text: string;
  createdAt: Date;
  updatedAt: Date;
  // Add other fields as needed
}

class EmailTemplateRepository {
  private className = 'EmailTemplate';

  public async createEmailTemplate(object: EmailTemplateModel): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const EmailTemplate = Parse.Object.extend(this.className);
        const User = Parse.Object.extend('_User');
        const newEmailTemplate = new EmailTemplate();
  
        newEmailTemplate.set('user', currentUser);
        newEmailTemplate.set('organization', currentUser.get('organization'));
        newEmailTemplate.set('subject', object.subject || '');
        newEmailTemplate.set('text', object.text || '');
        newEmailTemplate.set('name', object.name || '');
  
        const savedEmailTemplate = await newEmailTemplate.save(null, { useMasterKey: true });
        return savedEmailTemplate;
      } 
      else {
        return null;
      }
    }
    catch (error: any) {
      console.log(error);
      return null;
    }
  }

  public async getEmailTemplatesByUser(): Promise<Parse.Object[] | null> {
    try {

      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const EmailTemplate = Parse.Object.extend('EmailTemplate');
        const query = new Parse.Query(EmailTemplate);
        query.equalTo('user', currentUser).ascending('createdAt')

        const emailTemplates = await query.find({ useMasterKey: true });  
        return emailTemplates;
      }
      else {
        return null
      }
      
    } catch (error: any) {
      console.log(error);
      return [];
    }
  }

  public async getEmailTemplateById(objectId: string | null): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const EmailTemplate = Parse.Object.extend(this.className);

        const query = new Parse.Query(EmailTemplate);
        query.equalTo('objectId', objectId);

        const emailTemplate = await query.first({ useMasterKey: true });
        if (emailTemplate) {
          return emailTemplate;
        }
        else {
          return null;
        }
      }
      else {
        return null
      }
      
    } catch (error: any) {
      console.log(error);
      Toast(`Error retrieving email template: ${error.message}`, 'error');
      return null;
    }
  }

  public async updateEmailTemplate(objectId: string, updates: EmailTemplateModel): Promise<Parse.Object | null> {
    try {
      const EmailTemplate = Parse.Object.extend(this.className);

      const query = new Parse.Query(EmailTemplate);
      query.equalTo('objectId', objectId);

      const emailTemplate = await query.first({ useMasterKey: true });

      if (emailTemplate) {
        
        emailTemplate.set('text', updates.text);
        emailTemplate.set('subject', updates.subject);
        
        const savedTemplate = await emailTemplate.save(null, { useMasterKey: true });
        return savedTemplate;
      } else {
        return null;
      }
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  public async deleteEmailTemplate(objectId: string): Promise<boolean> {
    try {
      const EmailTemplate = Parse.Object.extend(this.className);

      const query = new Parse.Query(EmailTemplate);
      query.equalTo('objectId', objectId);

      const emailTemplate = await query.first({ useMasterKey: true });

      if (emailTemplate) {
        const deletedEmailTemplate = await emailTemplate.destroy({ useMasterKey: true });

        if (deletedEmailTemplate) {
          return true;
        }
        else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  }
}

export default EmailTemplateRepository;