import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/molecules/layout';
import CardProductItem from '../../components/molecules/products/cardProductItem';
import { FormProductSkeleton, ProductDetail } from '../../components/organisms';
import FormProperty from '../../components/organisms/product/formProperty';
import useEditProperty from '../../hooks/property/useEditProperty';
import { ProductCategories } from '../../utils/types/products';
import { CardSkeleton } from '../../components/molecules';
import Loader from '../../components/atoms/loader';
import { selectNavigationLayout, selectThemeMode } from '../../store/selector';
import AddPropertyCard from '../../components/molecules/products/addPropertyCard';
import { useEffect, useState } from 'react';
import { formatDate, hasPermissionForAction, isLocked } from '../../utils/helpers';
import NoPermission from '../../components/atoms/noPermission';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Locked from '../../components/atoms/locked';
import { Toast } from '../../components/atoms';
import PropertyRepository from '../../utils/api/repositories/propertyRepository';
import { useDispatch } from 'react-redux';

const EditProperty = () => {
  const { formProperty, title, isLoading } = useEditProperty();
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(false);
  const { id } = useParams();

  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const navigate = useNavigate();

  const onUnlock = async () => {
    if (id) {
      const propertyRepository = new PropertyRepository();
      const unlockedProperty = await propertyRepository.unlock(id);
      if (unlockedProperty) {
        Toast('Property unlocked successfully.', 'success');
        setLocked(false);
      }
    } else {
      Toast('Property could not be unlocked successfully. Please try again.', 'error');
      setTimeout(() => {
        navigate('/properties');
      }, 1000);
    }
  };

  const fetchStatus = async () => {
    setLoading(true);
    if (id) {
      const propertyRepository = new PropertyRepository();
      const property = await propertyRepository.getObjectByObjectId(id);
      if (property) {
        setLocked(property.get('lockedFor') !== null && isLocked(property.get('lockedUntil')));
      }
    } else {
      Toast('Property could not be fetched successfully. Please try again.', 'error');
      setTimeout(() => {
        navigate('/properties');
      }, 1000);
    }
    setLoading(false);
  }

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Properties', 'Update'));
    fetchStatus();
  }, []);

  return loading ? (
    <Box display={'flex'} height={'100vh'} alignItems={'center'}>
      <Loader />
    </Box>
  ) : (
    <MainLayout titlePage={title} mode={mode} navigation={navigation}>
      {locked ? (
        <Box display={'flex'} height={'100vh'} alignItems={'center'} justifyContent={'center'}>
          <Locked
            text={`Looks like the property you are trying to access is locked ${
              formProperty.lockedUntil ? 'until ' + formatDate(formProperty.lockedUntil, true) : 'temorarily'
            }${formProperty.lockedFor ? `, for the lead ${formProperty.lockedFor.get('firstName')} ${formProperty.lockedFor.get('lastName')}` : ''}.`}
            onUnlock={onUnlock}
          />
        </Box>
      ) : (
        <>
          {isLoading && <ProductDetail form={<FormProductSkeleton />} mode={mode} preview={<CardSkeleton />} />}

          {!isLoading &&
            (hasPermission ? (
              <ProductDetail
                form={<FormProperty type="edit" mode={mode} />}
                mode={mode}
                preview={<AddPropertyCard category={ProductCategories.Property} mode={mode} data={formProperty} active={true} />}
              />
            ) : (
              <NoPermission />
            ))}
        </>
      )}
    </MainLayout>
  );
};

export default EditProperty;
