import { useDispatch, useSelector } from 'react-redux';
import { IFormDetailProject, onShowModalBulk } from '../../../store/projects';
import { FilterDataFtn, IFormDetailProperty } from '../../../store/property';
import { ListInventoryProps, ListProductsProps, ProductCategories } from '../../../utils/types/products';
import ListProductItem from '../../molecules/products/listProductItem';
import ListHeadTables from './listHeadTables.json';
// import { useProjects } from '../../../hooks';
import { globalColors } from '../../../utils/constants/color';
// import ModalBulkEdit from '../leads/modalBulkEdit';
import NotFoundLottie from '../../atoms/notFound';
import { UserRole } from '../../../store/user';
import { NavAddFilterView } from '../../molecules';
import { Typography, TableContainer, TableBody, TableRow, Table, TableHead, Paper, Checkbox } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  selectBulkEdit,
  selectColor,
  selectInventoryFilter,
  selectPropertiesFilter,
  selectShowModalBulkEdit,
  selectThemeMode,
  selectUserRole,
} from '../../../store/selector';
import ModalBulkEditProduct from './modalBulkEditInventory';
import ListInventoryItem from '../../molecules/products/listInventoryItem';
import Paginator from '../../atoms/paginator';
import { InventoryFilterDataFtn } from '../../../store/inventory';
import Loader from '../../atoms/loader';
import ModalBulkEditInventory from './modalBulkEditInventory';

/**
 * Renders a list of projects.
 * ! This component can be used for Projects and Properties
 *
 * @return {JSX.Element} The rendered list of projects.
 */
const ListInventory = (props: ListInventoryProps) => {
  const dispatch = useDispatch();
  const showModalBulkEdit = useSelector(selectShowModalBulkEdit);
  const edit = useSelector(selectBulkEdit);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const userRole = useSelector(selectUserRole);
  const filterData = useSelector(selectInventoryFilter);

  const isCanEdit = userRole !== UserRole.executive;

  return (
    <>
      <NavAddFilterView
        onlyShowAddButton={props.datas.length <= 0}
        onChangeView={props.handleSwitchView}
        view={props.view}
        property={false}
        projectComponent
        onClickNewProduct={props.onClickNewProduct}
        titleButton={''}
      />
      {props.isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography
            sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
            onClick={() => dispatch(InventoryFilterDataFtn({ status: '', type: '', purpose: '' }))}
          >
            {filterData.status !== '' || filterData.type !== '' || filterData.purpose !== '' ? 'Clear Filter' : ''}
          </Typography>
          {props.datas.length > 0 ? (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                  border: `1px solid ${mode === 'dark' && globalColors.border.gray}`,
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  paddingBottom: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '10px',
                  marginTop: '20px',
                }}
              >
                {
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {edit && (
                            <Checkbox
                              sx={{
                                color: color || globalColors.blue,
                                '&.Mui-checked': {
                                  color: color || globalColors.secondBlue,
                                },
                              }}
                              onChange={props.handleHeaderCheckboxChange}
                              checked={
                                Object.values(props.rowCheckboxState).length > 0 &&
                                Object.values(props.rowCheckboxState).every((isChecked) => isChecked)
                              }
                            />
                          )}
                        </TableCell>
                        <>
                          <TableCell>
                            <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                              {ListHeadTables.ProjectComponents.cell1}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                              {ListHeadTables.ProjectComponents.cell2}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                              {ListHeadTables.ProjectComponents.cell3}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                              {ListHeadTables.ProjectComponents.cell4}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                              {ListHeadTables.ProjectComponents.cell5}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                              Actions
                            </Typography>
                          </TableCell>
                        </>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.datas.map((data) => (
                        <ListInventoryItem
                          rowCheckboxState={props.rowCheckboxState}
                          setRowCheckboxState={props.setRowCheckboxState}
                          isCanEdit={isCanEdit}
                          edit={edit}
                          key={data.objectId ?? ''}
                          mode={mode}
                          data={data}
                        />
                      ))}
                    </TableBody>
                  </Table>
                }
              </TableContainer>
              <Paginator
                getTotalCount={props.getTotalCount}
                onChangePage={async (newPage) => {
                  await props.onChangePage(newPage);
                  return true;
                }}
              />
            </>
          ) : (
            <NotFoundLottie
              showButton={userRole !== UserRole.executive}
              text={
                userRole !== UserRole.executive
                  ? 'Looks like you have not added any inventory for this project yet. Press the button below and add a new project right now.'
                  : 'Looks like your company has not added any inventory for this project yet. Contact your manager or admin to add a new project.'
              }
              buttonText="Add New Project"
              navigateTo="/projects/new"
              setAddNewPath="/inventory"
            />
          )}
        </>
      )}

      <ModalBulkEditInventory
        rowCheckboxState={props?.rowCheckboxState}
        open={showModalBulkEdit}
        onClose={() => dispatch(onShowModalBulk(false))}
        mode={mode}
        color={color}
      />
    </>
  );
};

export default ListInventory;
