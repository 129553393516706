import { Typography, styled } from "@mui/material";
import { globalColors } from "../../utils/constants/color";

interface HeadingFormAuthProps {
  title: string;
  subTitle: string;
  onGoBack?: (() => void) | null;
  mode?: string;
}
const HeadingFormAuth = ({
  title,
  subTitle,
  onGoBack,
  mode = 'dark',
}: HeadingFormAuthProps) => {
  return (
    <HeadingFormAuthStyle>
      <div className="heading">
        <Typography className="title" color={mode === 'dark' ? globalColors.white : globalColors.black}>{title}</Typography>
        {onGoBack !== null && <button className="btn-go-back" onClick={onGoBack}>
          Go back
        </button>}
      </div>
      <Typography fontSize={'12px'} color={globalColors.text.secondary}>{subTitle}</Typography>
    </HeadingFormAuthStyle>
  );
};

const HeadingFormAuthStyle = styled("div")(({ theme }) => ({
  marginBottom: "1.25rem",
  "& .heading": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "8px",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "start",
    },

    "& .title": {
      fontSize: "1.25rem",
    },

    "& button.btn-go-back": {
      all: "unset",
      color: globalColors.secondBlue,
      cursor: "pointer",
      height: "100%",
      "&:hover": {
        color: globalColors.mainBlue,
      },
    },
  },
}));

export default HeadingFormAuth;
