import React, { useState } from 'react';
import { Box, List, ListItem, Button, ButtonBase, Typography } from '@mui/material';
// import { FiArrowUpRight } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';
import { BiMenuAltRight } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/Images/logo.svg';
import ScheduleModel from './scheduleModel';
import { globalColors } from '../../../utils/constants/color';

type NavbarProps = {
  scrollToContactForm: () => void;
  scrollToPropertFinder: () => void;
  scrollToPropertCloud: () => void;
};
const Navbar: React.FC<NavbarProps> = ({ scrollToContactForm, scrollToPropertFinder, scrollToPropertCloud }) => {
  const navigate = useNavigate();
  // const navbar: string[] = ['Home', 'Why Us', 'Our Platform', 'Contact Us'];
  const navbar: string[] = ['Home', 'Why Us', 'Contact Us', 'Log In', 'Join Now'];
  const [openScheduleModal, setopenScheduleModal] = useState({ open: false, from: '' });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box
      position={'sticky'}
      top={0}
      sx={{
        background: globalColors.mainBackground,
        zIndex: 100,
        minWidth: '100%',
        overflow: 'hidden',
        minHeight: '3vh',
        color: 'white',
      }}
    >
      <Box sx={{ maxWidth: 'lg', mx: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: { xs: '10px', lg: 0 }, paddingTop: '10px' }}>
          <img src={logo} alt="reload" width={100} style={{ paddingRight: '3rem' }} />
          <Box sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center' }}>
            <List sx={{ display: 'flex' }}>
              {navbar?.map(
                (val) =>
                  val !== 'Log In' && val !== 'Join Now' && (
                    <ListItem>
                      <Button
                        onClick={() =>
                          val === 'Contact Us'
                            ? scrollToContactForm()
                            : val === 'Our Platform'
                            ? scrollToPropertCloud()
                            : val === 'Why Us'
                            ? scrollToPropertFinder()
                            : (window.location.href = '/')
                        }
                        component="a"
                        sx={{
                          padding: '5px 20px',
                          whiteSpace: 'nowrap',
                          bgcolor: 'transparent',
                          ':hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontsize: '2rem',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                        >
                          {val}
                        </Typography>
                      </Button>
                    </ListItem>
                  )
              )}
            </List>
          </Box>
        </Box>

        <Box sx={{ display: { xs: 'none', lg: 'flex' }, gap: '10px', alignItems: 'center'}}>
          <ButtonBase
            sx={{
              bgcolor: 'transparent',
              color: 'white',
              padding: '5px 20px',
              marginTop: '8px'
            }}
            onClick={() => {navigate('/requestDemo')}}
          >
            <Typography
              sx={{
                fontsize: '2rem',
                color: 'white',
              }}
            >
              Request a Demo
            </Typography>
          </ButtonBase>
          <ButtonBase
            sx={{
              border: '1px solid #0455C6',
              borderRadius: '8px',
              color: 'white',
              padding: '5px 20px',
              marginTop: '8px'
            }}
            onClick={() => navigate('/login')}
          >
            <Typography
              sx={{
                fontsize: '2rem',
                color: 'white',
                textTransform: 'capitalize',
              }}
            >
              Log In
            </Typography>
          </ButtonBase>
          <ButtonBase
            sx={{
              border: '1px solid #0455C6',
              borderRadius: '8px',
              bgcolor: '#0455C6',
              color: 'white',
              padding: '5px 20px',
              marginTop: '8px'
            }}
            onClick={() => navigate('/onboarding')}
          >
            <Typography
              sx={{
                fontsize: '2rem',
                color: 'white',
              }}
            >
              Join Now
            </Typography>
          </ButtonBase>
        </Box>
        <Box sx={{ display: { lg: 'none' }, alignItems: 'center' }}>
          <Button sx={{ color: 'text.primary', '&:focus': { outline: 'none' } }} onClick={toggleMenu}>
            {isMenuOpen ? <AiOutlineClose size="1.5rem" color="white" /> : <BiMenuAltRight size="2rem" color="white" />}
          </Button>
        </Box>
        {/* <Box sx={{display: {xs: 'none', sm: 'none', lg: 'block', xl: 'block'}}}>awdawd</Box> */}
      </Box>
      {/* Mobile Menu */}
      {isMenuOpen && (
        <Box sx={{ display: { xs: 'flex', lg: 'none' }, mt: 4, overflow: 'auto', position: 'sticky' }}>
          <List sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {navbar?.map((val) => (
              <ListItem sx={{ borderBottom: '1px solid gray', width: '100%' }}>
                <Button
                  component="a"
                  sx={{
                    py: 2,
                    pr: 4,
                    pl: 3,
                    bgcolor: 'transparent',
                    ':hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() =>
                    val === 'Contact Us'
                      ? scrollToContactForm()
                      : val === 'Our Platform'
                      ? scrollToPropertCloud()
                      : val === 'Why Us'
                      ? scrollToPropertFinder()
                      : val === 'Log In'
                      ? navigate('/login')
                      : val === 'Join Now'
                      ? navigate('/onboarding')
                      : navigate('/')
                  }
                >
                  <Typography
                    sx={{
                      fontsize: '2rem',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                  >
                    {val}
                  </Typography>
                </Button>
              </ListItem>
            ))}
            {/* <Quotes /> */}
          </List>
        </Box>
      )}
      <ScheduleModel
        open={openScheduleModal.open}
        onClose={() => {
          setopenScheduleModal({ open: false, from: '' });
        }}
        from={openScheduleModal.from}
      />
    </Box>
  );
};

export default Navbar;
