import { Box, Button, Modal, Stack, Typography, styled } from '@mui/material';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IAmenities, addAminitiesItem } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { FieldInput, FieldInputUnit } from '../../atoms';
import FieldInputFile from '../../atoms/fieldInputFile';
import { HeadingFormAuth } from '../../molecules';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '525px',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: '1.5rem',
  borderRadius: '20px',

  '@media (max-width: 576px)': {
    width: '80%',
  },
};

interface FormValues {
  name: string;
}

interface ModalAddTemplate {
  open: boolean;
  onClose: () => void;
  mode: string;
  color: string;
  onSubmit: (value: string) => Promise<void>
}

const ModalAddTemplate = ({ open, onClose, mode, color, onSubmit }: ModalAddTemplate) => {
  const [formAmenities, setFormAmenities] = React.useState({
    name: '',
  });

  return (
    <Modal open={open} onClose={onClose} aria-nameledby="modal-add-template" aria-describedby="modal-request-add-template" className="content">
      <ModalAddTemplateStyle>
        <Box sx={style} bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.white} className="content">
          <HeadingFormAuth mode={mode} title="Add New Template" subTitle="" onGoBack={null} />
          <Box gap={2}>
            <Formik
              initialValues={{
                name: '',
              }}
              validate={(values) => {
                const errors = {} as FormValues;

                if (!values.name || !values.name.trim()) {
                  errors.name = 'Please enter a valid name';
                }

                if (values.name.length > 20) {
                  errors.name = "Name can't exceed 20 characters";
                }


                return errors;
              }}
              onSubmit={async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
                // const amenity: IAmenities = {
                //   name: values.name,
                //   value: '1'
                // }
                // handleAddAmenities(amenity);
                await onSubmit(values.name);
                onClose();
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Box>
                  <FieldInput
                    className="field-input"
                    placeholder="Template Name"
                    type="text"
                    name="name"
                    id="name"
                    mode={mode}
                    value={formAmenities['name']}
                  />
                  {touched.name && errors.name && (
                    <Typography pt={'0.8rem'} color={'red'} fontSize={'12px'}>
                      {errors?.name}
                    </Typography>
                  )}
                  <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'30px'}>
                    <Button
                      sx={{
                        border: `1px solid ${globalColors.red}`,
                        color: mode == 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: globalColors.red,
                        },
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        border: `1px solid ${color || globalColors.blue}`,
                        color: mode == 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: `${color || globalColors.blue}`,
                        },
                      }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
          {/* <Button variant="contained" type="button" className="btn-add" onClick={handleAddAmenities}>
            Add Amenity
          </Button>
          <Button variant="contained" type="button" className="btn-add" onClick={handleAddAmenities}>
            Add Amenity
          </Button> */}
        </Box>
      </ModalAddTemplateStyle>
    </Modal>
  );
};

const ModalAddTemplateStyle = styled('div')(({ theme }) => ({
  '& .btn-add': {
    marginTop: '1rem',
    width: '100%',
  },
}));

export default ModalAddTemplate;
