import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { useSelector } from 'react-redux';
import Heading from '../../../atoms/heading';
import { useEffect, useState } from 'react';
import { formatMoney, formatRole, getCurrentQuarterDates } from '../../../../utils/helpers';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import Loader from '../../../atoms/loader';
import NotFoundLottie from '../../../atoms/notFound';
import ProjectRepository from '../../../../utils/api/repositories/projectRepository';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface UserDetails {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

const TopPerformerProject = () => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);

  const [topPrice, setTopPrice] = useState(0);

  useEffect(() => {
    const getTopPerformer = async () => {
      setLoading(true);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const projectRepository = new ProjectRepository();
        const topPerformerDetails = await projectRepository.getTopPerformerForCurrentQuarter(currentUser.get('organization').id);
        if (topPerformerDetails) {
          setUserDetails({
            email: topPerformerDetails.user.get('email'),
            firstName: topPerformerDetails.user.get('firstName') ?? 'Unknown',
            lastName: topPerformerDetails.user.get('lastName') ?? 'Member',
            role: topPerformerDetails.user.get('role'),
          });
          setTopPrice(topPerformerDetails.total);
        }
      }
      setLoading(false);
    };

    getTopPerformer();
  }, []);

  return (
    <Accordion
      sx={{
        borderRadius: '20px !important',
        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
        marginTop: '20px',
        padding: '0rem 1rem',
      }}
      defaultExpanded={false}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Box bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight} borderRadius={'10px'} width={'100%'}>
          <Heading text={`Top Performer - ${getCurrentQuarterDates().quarter}`} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {loading ? (
          <Loader />
        ) : userDetails ? (
          <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} gap={'1rem'} paddingTop={'1rem'}>
            <Box component={'span'} display={'flex'} flexDirection={'column'}>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  fontWeight: '400',
                  fontFamily: 'poppins',
                }}
              >
                {`${userDetails.firstName} ${userDetails.lastName}`} <span style={{ fontSize: '13px' }}>- {formatRole(userDetails.role)}</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  color: color || globalColors.blue,
                  fontWeight: '400',
                  fontFamily: "'Poppins', sans-serif !important",
                }}
              >
                {userDetails?.email}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  fontWeight: '600',
                  fontFamily: "'Poppins', sans-serif !important",
                }}
              >
                Total Sales
              </Typography>
              <Typography
                sx={{
                  fontSize: '16px',
                  color: color || globalColors.blue,
                  fontWeight: '400',
                  fontFamily: "'Poppins', sans-serif !important",
                }}
              >
                PKR {formatMoney(topPrice)}
              </Typography>
            </Box>
          </Box>
        ) : (
          <NotFoundLottie
            text={"Looks like there isn't enough data to determine the top performer for the current quarter yet. Please check back later."}
            buttonText=""
            navigateTo=""
            showButton={false}
            size="small"
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default TopPerformerProject;
