import { styled } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { Box, Typography, Button } from '@mui/material';

export const SwitchContainer = styled(Box)(({ children, theme }) => ({
//   borderLeft: '1px solid rgba(48, 48, 48, 1)',
  width: '15%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const NotificationContainer = styled(Box)(({ children, theme }) => ({
  width: '15%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

interface IconInputProps {
  clicked?: boolean;
}

// export const IconInputStyle = styled('input')<IconInputProps>(({ theme, clicked }) => ({
//   '&:focus': {
//     border: '1px solid rgba(48, 48, 48,1', // Change border color on focus
//   },
// }));