import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StatusProduct } from '../utils/types/products';
import ProjectComponentModel from '../utils/api/models/projectComponentModel';
import { CustomField } from '../components/molecules/customFields';

// TODO : make it global types
export enum EnumViews {
  Cards = 'Cards',
  List = 'List',
}

// TODO : make it global types
export interface ICover {
  files: File[];
  preview: string;
}

export interface IImages {
  id: string;
  file?: File;
  url: string;
}

interface FilterData {
  status: string;
  type: string;
  purpose: string;
}

interface InventoryDataState {
  isLoading: boolean;
  viewActive: EnumViews;
  Search: string;
  inventory: ProjectComponentModel[];
  showModalBulkEdit: boolean;
  filterData: FilterData;
  refresh: boolean;
}

const initialState: InventoryDataState = {
  isLoading: false,
  showModalBulkEdit: false,
  viewActive: EnumViews.Cards,
  inventory: [],
  Search: '',
  filterData: {
    status: '',
    type: '',
    purpose: '',
  },
  refresh: false
};
const InventoryReducer = createSlice({
  name: 'Inventory Data',
  initialState,
  reducers: {
    // * Root project page
    onSwitchView: (state, action: PayloadAction<EnumViews>) => {
      state.viewActive = action.payload;
    },
    refreshInventory: (state) => {
      state.refresh = !state.refresh;
    },
    // * Detail Project
    addNewInventory: (state, action: PayloadAction<ProjectComponentModel>) => {
      state.inventory = [...state.inventory, action.payload];
    },
    setDataInventory: (state, action: PayloadAction<ProjectComponentModel[]>) => {
      state.inventory = action.payload;
    },
    onInventoryLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    onShowModalBulk: (state, action: PayloadAction<boolean>) => {
      state.showModalBulkEdit = action.payload;
    },
    InventoryFilterDataFtn: (state, action: PayloadAction<FilterData>) => {
      state.filterData = action.payload;
    },
    SearchInventory: (state, action: PayloadAction<string>) => {
      state.Search = action.payload;
    },
  },
});

export const {
  refreshInventory,
  onSwitchView,
  addNewInventory,
  setDataInventory,
  onInventoryLoading,
  onShowModalBulk,
  InventoryFilterDataFtn,
  SearchInventory
} = InventoryReducer.actions;
export default InventoryReducer.reducer;
