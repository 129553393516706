import { Box, Grid, Typography } from '@mui/material';
import Sidebar from '../../../molecules/sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectAddNew, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import SocialMediaCard from '../../../molecules/socialMediaCard';
import Heading from '../../../atoms/heading';
import LineCharts from '../../../organisms/charts/lineCharts';
import Piechart from '../../../organisms/charts/pieChart';
import { useEffect, useState } from 'react';
import CompanyCard from './companyCards';
import Barchart from '../../../organisms/charts/barCharts';
import ContentTable from './contentTable';
import HalfPiechart from './contentTable/halfPichart';
import FaceIcon from './faceIcon';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import CompanyGraph from './companyGraph';
import { formatMoney } from '../../../../utils/helpers';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import TopPerformerCompany from './topPerformer';

const Company = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const [loadingFigures, setLoadingFigures] = useState(true);
  const [salesCount, setSalesCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const fetchCompanyFigures = async () => {
      const organizationRepository = new OrganizationRepository();
      const sales = await organizationRepository.getTotalSales();
      if (sales) {
        setSalesCount(sales);
      }
      const leads = await organizationRepository.getLeadsCount();
      if (leads) {
        setLeadsCount(leads);
      }
      const properties = await organizationRepository.getPropertiesCount();
      if (properties) {
        setPropertiesCount(properties);
      }
      const projects = await organizationRepository.getProjectsCount();
      if (projects) {
        setProjectsCount(projects);
      }
      setLoadingFigures(false);
    };

    fetchCompanyFigures();
    setLoading(false);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Company Performance'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          <Box width={'100%'} padding={3}>
            <Box display={'flex'} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} justifyContent={'space-between'} gap={'30px'}>
              <CompanyCard value={formatMoney(salesCount)} title={'Total Sales'} iconName={'Sales'} loading={loadingFigures} />
              <CompanyCard value={leadsCount} title={'Total Leads'} iconName={'Leads'} loading={loadingFigures} />
              <CompanyCard value={propertiesCount} title={'Total Properties'} iconName={'Properties'} loading={loadingFigures} />
              <CompanyCard value={projectsCount} title={'Total Projects'} iconName={'Projects'} loading={loadingFigures} />
            </Box>
            <TopPerformerCompany />
            <Box width={'100%'} paddingTop={1} paddingBottom={1}>
              <Box marginTop={'1%'}>
                <CompanyGraph />
              </Box>
            </Box>
            <ContentTable />
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

export default Company;
