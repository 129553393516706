import { Box, Button } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import { CiEdit } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { BulkEditFtn } from '../../../store/slices';
import { selectBulkEdit, selectThemeMode } from '../../../store/selector';

interface ViewLeadFilterProps {
  color: string;
  bgcolor: string;
  setOpenBulkEditLeadModal: (isLoading: boolean) => void;
}

const BulkEditLeads = ({ color, bgcolor, setOpenBulkEditLeadModal }: ViewLeadFilterProps) => {
  const dispatch = useDispatch();
  const edit = useSelector(selectBulkEdit);
  const mode = useSelector(selectThemeMode);

  return (
    <Box>
      <Box
        // bgcolor={bgcolor}
        // border={!edit ? `1px solid ${globalColors?.border.gray}` : ''}
        display="flex"
        gap={'10px'}
        sx={{
          borderRadius: '12px',
          height: '40px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginTop: '0px',
        }}
      >
        {!edit ? (
          <CiEdit
            size={'1.5rem'}
            color={color !== 'default' && color !== '' ? color : globalColors.blue}
            style={{ cursor: 'pointer', margin: 'auto' }}
            onClick={() => {
              dispatch(BulkEditFtn(true));
            }}
          />
        ) : (
          <Box display={'flex'} gap={'10px'} margin={'auto'}>
            <Button
              sx={{
                border: `1px solid ${color !== 'default' && color !== '' ? color : globalColors.blue}`,
                color: mode === 'dark' ? globalColors.white : globalColors.black,
                width: '100%',
                borderRadius: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                '&:hover': {
                  backgroundColor: color !== 'default' && color !== '' ? color : globalColors.black,
                },
              }}
              onClick={() => {
                setOpenBulkEditLeadModal(true);
              }}
            >
              Done
            </Button>
            <Button
              sx={{
                border: `1px solid ${globalColors.red}`,
                color: mode === 'dark' ? globalColors.white : globalColors.black,
                width: '100%',
                borderRadius: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                '&:hover': {
                  backgroundColor: globalColors.red,
                },
              }}
              onClick={() => {
                dispatch(BulkEditFtn(false));
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BulkEditLeads;
