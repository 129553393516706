import { Box, BoxProps, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Sector, Tooltip } from 'recharts';
import { PieSectorDataItem } from 'recharts/types/polar/Pie';
import { ActiveShape } from 'recharts/types/util/types';
// import { globalColors } from '../../../utils/constants/color';

// const COLORS = ['#AE002A', '#C94800', '#599BF7', '#0455C6'  ];
const COLORS = ['#0455C6', '#599BF7', '#C94800', '#AE002A'];
const style = {
  // top: '20%',
  // left: '-50px',
  // transform: 'translate(50%, 0%)',
  // lineHeight: '24px',
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill} fontSize={'14px'} fontWeight={700}>
        {payload.name}
      </text>
      <text x={cx} y={cy + 5} dy={8} textAnchor="middle" fill={fill} fontSize={'10px'} fontWeight={500}>
      {value}
      </text>
      <text x={cx} y={cy + 20} dy={8} textAnchor="middle" fill={fill} fontSize={'10px'}>
      {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill} />
      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" /> */}
      {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text> */}
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text> */}
    </g>
  );
};

interface dataProps {
  name: string;
  value: number;
}
interface PieChartProps {
  data: dataProps[];
  showNumbers?: boolean;
  colors?: string[]
}

const Piechart: React.FC<PieChartProps> = ({ data, showNumbers = false, colors }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [chartDimensions, setChartDimensions] = useState({ innerRadius: 60, outerRadius: 80 });
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  // useEffect(() => {
  //   function handleResize() {
  //     if (window.innerWidth <= 1341) {
  //       setChartDimensions({ innerRadius: 40, outerRadius: 60 });
  //     } else {
  //       setChartDimensions({ innerRadius: 60, outerRadius: 80 });
  //     }
  //   }
  //   window.addEventListener('resize', handleResize);

  //   handleResize();

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [])

  let a = {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    top: 0,
    left: '-155px',
    transform: 'translate(50%, 0%)',
    lineHeight: '24px',
  };

  return (
    <div
      style={{
        height: '230px',
        width: '100%',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            legendType="circle"
            cy={100}
            innerRadius={'65%'}
            fill="#82ca9d"
            // isAnimationActive={false}
            stroke="#000"
            activeIndex={activeIndex}
            outerRadius={'80%'}
            // cornerRadius={20}
            paddingAngle={10}
            dataKey="value"
            onMouseEnter={onPieEnter}
            activeShape={(props: ActiveShape<PieSectorDataItem>) => renderActiveShape(props)}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} stroke={COLORS[index]} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          {/* <Tooltip cursor={{ fill: 'transparent' }} /> */}
          <Legend iconSize={10} layout="vertical" verticalAlign="middle" align="left" wrapperStyle={style} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Piechart;
