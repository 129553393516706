import { Box, Avatar, Typography, Divider, Modal, Slide, Button, Drawer } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { globalColors } from '../../../utils/constants/color';
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { VscMail } from 'react-icons/vsc';
import { MdDelete } from 'react-icons/md';
import EmailModel from '../../../utils/api/models/emailModel';
import EmailRepository from '../../../utils/api/repositories/emailRepository';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectColor } from '../../../store/selector';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { hasPermissionForAction } from '../../../utils/helpers';

interface EmailDetailProps {
  mode: string;
  openEmailDetail: boolean;
  setOpenEmailDetail: (isOpen: boolean) => void;
  emailId: string;
  emailData: any;
  handleMoveLeft: any;
  handleMoveRight: any;
  handleDeleteEmail: any;
  setOpenDeleteEmailModal: any;
}

const EmailDetail: React.FC<EmailDetailProps> = ({
  mode,
  openEmailDetail,
  setOpenEmailDetail,
  emailData,
  emailId,
  handleMoveLeft,
  handleMoveRight,
  handleDeleteEmail,
  setOpenDeleteEmailModal,
}) => {
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [hasPermission, setHasPermission] = useState(false);

  const color = useSelector(selectColor);

  const handleSendEmail = async () => {
    const emailModel: EmailModel = {
      to: selectedEmail?.to || [],
      CC: selectedEmail?.CC,
      subject: selectedEmail?.subject || '',
      text: selectedEmail?.text || '',
      // Other fields as needed
    };

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    const objectId = currentUser?.id ?? '';

    const repository = new EmailRepository();
    await repository.createEmail(emailModel, false, null);
  };

  useEffect(() => {
    // Find the object in the array where CC includes the initialEmailID
    const selectedObject = emailData.find((obj: any) => obj.objectId === emailId);

    // Set the selected object to the state
    setSelectedEmail(selectedObject);
  }, [emailId, emailData]);

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Email', 'Create'));
  }, []);

  const style = {
    marginTop: { xs: '25%', md: '50%' },
    borderRadius: '20px',
    width: '350px',
    bgcolor: `${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
    border: `1px solid ${mode === 'dark' && globalColors.border.gray}`,
    boxShadow: 24,
    p: 4,
  };

  return (
    <Drawer
      anchor="right"
      sx={{
        backgroundColor: 'transparent',
        marginTop: '100px',
        paddingTop: '100px',
        '& .MuiDrawer-paper': { backgroundColor: 'transparent' },
      }}
      open={openEmailDetail}
      onClose={() => {
        setOpenEmailDetail(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-content-wrapper scroll"
    >
      {/* <Slide in={openEmailDetail} direction="left" timeout={1000} className="scroll"> */}
      <Box sx={style} borderRadius={'12px'}>
        <Box
          bgcolor={color || globalColors.blue}
          display={'flex'}
          justifyContent={'space-between'}
          gap={'10px'}
          padding={'1rem'}
          borderRadius={'12px 12px 0px 0px'}
        >
          <Box display={'flex'} gap={'1rem'}>
            {/* <AiOutlineLeft style={{ cursor: 'pointer', color: globalColors.lightgray }} size={'1.2rem'} onClick={handleMoveLeft} />
            <AiOutlineRight style={{ cursor: 'pointer', color: globalColors.lightgray }} size={'1.2rem'} onClick={handleMoveRight} /> */}
          </Box>
          <AiOutlineClose onClick={() => setOpenEmailDetail(false)} size={'1.2rem'} style={{ cursor: 'pointer', color: globalColors.lightgray }} />
        </Box>
        <Box padding={'2rem'} display={'flex'} flexDirection={'column'} gap={'2rem'}>
          <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
            <Box display={'flex'} gap={'1rem'}>
              {selectedEmail?.image ? (
                <Avatar sx={{ width: 24, height: 24 }} alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
              ) : (
                <Avatar sx={{ width: 24, height: 24 }}>
                  <span>{selectedEmail?.senderName ? selectedEmail?.senderName.charAt(0).toUpperCase() : ''}</span>
                </Avatar>
              )}
              <Typography color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray}>{selectedEmail?.senderName}</Typography>
            </Box>
            <Box component={'span'} display={'flex'} gap={'1rem'}>
              <Typography fontSize={'12px'} color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray} margin={'auto 0'}>
                Subject:
              </Typography>
              <Typography fontSize={'14px'} color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray}>
                {selectedEmail?.subject}
              </Typography>
            </Box>
            <Box component={'span'} display={'flex'} gap={'1rem'}>
              <Typography fontSize={'12px'} color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray} margin={'auto 0'}>
                Sender:
              </Typography>
              <Typography fontSize={'14px'} color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray}>
                {selectedEmail?.senderName}
              </Typography>
            </Box>
            <Box component={'span'} display={'flex'} gap={'1rem'} margin={'auto 0'}>
              <Typography fontSize={'12px'} color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray}>
                Time:
              </Typography>
              <Typography fontSize={'14px'} color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray}>
                {moment(selectedEmail?.createdAt).fromNow()}
              </Typography>
            </Box>
          </Box>
          <Typography color={mode !== 'dark' ? globalColors.blackLight : globalColors.lightgray} fontSize={'14px'}>
            {selectedEmail?.text ? <div dangerouslySetInnerHTML={{ __html: selectedEmail?.text }}></div> : 'No text found'}
          </Typography>
          <Divider sx={{ backgroundColor: '#303030' }} />
          {hasPermission && (
            <Button
              sx={{
                justifyContent: 'start',
                textTransform: 'none',
                backgroundColor: 'transparent',
                color: color || globalColors.blue,
                display: 'flex',
                border: `1px solid ${color || globalColors.blue}`,
                gap: '2rem',
                padding: '0.8rem',
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: color || globalColors.blue,
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                },
              }}
              onClick={() => {
                handleSendEmail();
                setOpenEmailDetail(false);
              }}
            >
              <VscMail size={'1.2rem'} style={{ marginTop: 'auto', marginBottom: 'auto' }} />
              <Typography>Resend</Typography>
            </Button>
          )}
          <Button
            sx={{
              justifyContent: 'start',
              textTransform: 'none',
              backgroundColor: globalColors.red,
              color: mode === 'dark' ? globalColors.white : globalColors.black,
              display: 'flex',
              gap: '2rem',
              padding: '0.8rem',
              borderRadius: '12px',
              '&:hover': {
                backgroundColor: globalColors.red,
              },
            }}
            onClick={() => {
              handleDeleteEmail(emailId);
              setOpenDeleteEmailModal(true);
              setOpenEmailDetail(false);
            }}
          >
            <MdDelete size={'1.2rem'} style={{ marginTop: 'auto', marginBottom: 'auto' }} />
            <Typography>Delete</Typography>
          </Button>
        </Box>
      </Box>
      {/* </Slide> */}
    </Drawer>
  );
};

export default EmailDetail;
