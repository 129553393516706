import { Typography, styled } from '@mui/material';
import { ICFilter } from '../../assets/Icons';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';

interface ButtonFilterProps {
  title?: string;
  onClick?: any;
  className?: string;
  bgColor?: string;
}
interface FilterStyleProps {
  mode: string;
  bgColor?: string;
}
const ButtonFilter = ({ title = 'Filter', onClick, className, bgColor }: ButtonFilterProps) => {
  const mode = useSelector(selectThemeMode);
  return (
    <FilterStyle bgColor={bgColor} onClick={onClick} className={className} mode={mode}>
      <Typography className="filter-name">{title}</Typography>
      <img src={ICFilter} alt="icon filter" />
    </FilterStyle>
  );
};

const FilterStyle = styled('button')<FilterStyleProps>(({ theme, mode, bgColor }) => ({
  all: 'unset',
  cursor: 'pointer',
  boxSizing: 'border-box',
  background:
    bgColor === 'black'
      ? mode === 'dark'
        ? globalColors.black
        : globalColors.white
      : mode === 'dark'
      ? globalColors.blackLight
      : globalColors.lightgray,
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '130px',
  minHeight: '42px',
  height: '100%',
  padding: '0 1rem',
  '& .filter-name': {
    fontSize: '0.75rem',
    color: globalColors.text.secondary,
  },
  '@media (max-width: 768px)': {
    width: '100%',
    minWidth: '130px',
  },

  '&:hover': {
    opacity: '0.8',
    '& .filter-name': {
      color: mode === 'dark' && globalColors.white,
    },
  },
}));

export default ButtonFilter;
