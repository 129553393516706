import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { globalColors } from '../../../utils/constants/color';

interface ProductCategory {
  form: React.ReactNode;
  preview?: React.ReactNode;
  mode: string;
}
interface ProductDetailStyleProps {
  mode: string;
}
const ProductDetail = (props: ProductCategory) => {
  return (
    <ProductDetailStyle mode={props.mode}>
      <div style={{ overflow: 'auto' }} className="form-product-wrapper">
        {props.form}
      </div>
      <div className="card-product-wrapper">
        <Typography component="h1" className="title">
          {props.preview ? 'Preview' : ''}
        </Typography>
        <div className="card-product">{props.preview}</div>
      </div>
    </ProductDetailStyle>
  );
};

const ProductDetailStyle = styled('div')<ProductDetailStyleProps>(({ theme, mode }) => ({
  display: 'flex',
  gap: '3.5rem',
  '& .form-product-wrapper': { flex: 1, width: { xl: '70%', lg: '70%', md: '70%', sm: '100%', xs: '100%' } },
  '& .card-product-wrapper': {
    width: '330px',
    '& .title': {
      textAlign: 'center',
      marginBottom: '1.5rem',
      color: mode === 'dark' ? globalColors.text.main : globalColors.black,
      fontSize: '16px',
      fontWeight: 600,
    },
  },

  // * Responsive
  [theme.breakpoints.between('sm', 'md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    '& .card-product-wrapper': {
      width: '100%',
      '& .card-product': {
        width: '100%',
        display: 'grid',
        placeItems: 'center',
        '& .product': {
          minWidth: '300px',
        },
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    gap: '2rem',
    flexDirection: 'column-reverse',
    '& .card-product-wrapper': {
      width: '100%',
    },
  },
}));

export default ProductDetail;
