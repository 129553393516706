import { Button, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectColor, selectThemeMode, selectUserData } from '../../store/selector';
import { globalColors } from '../../utils/constants/color';
import { TbApps } from 'react-icons/tb';
import { LuDatabaseBackup } from 'react-icons/lu';
import { LiaUsersCogSolid } from 'react-icons/lia';
import { CiMoneyCheck1 } from 'react-icons/ci';
import { TbBellCog } from 'react-icons/tb';
import SupportIcon from './settings/Support/supportIcon';
import { RiLock2Fill, RiLock2Line, RiMoneyDollarCircleFill, RiQuestionMark } from 'react-icons/ri';
import { UserRole } from '../../store/user';

interface SettingsSubMenuProps {
  setOpenSetting?: any;
}

export const SettingsSubMenu = ({ setOpenSetting }: SettingsSubMenuProps) => {
  const navigate = useNavigate();
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const pathname = window.location.pathname.split('/');
  const user = useSelector(selectUserData);

  const handleSubMenuClick = (path: any) => {
    navigate(path);
  };

  return (
    <>
      {/* Backup */}
      <Button
        onClick={() => handleSubMenuClick('/settings/import')}
        sx={{
          textTransform: 'none',
          display: 'flex',
          fontWeight: 'bolder',
          cursor: 'pointer',
          alignItems: 'center',
          backgroundColor: pathname[pathname.length - 1] === 'import' ? color || globalColors?.blue : 'transparent',
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '12px',
          // margin: '0px',
          marginLeft: '15px',
          marginRight: '20px',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: '1px solid transparent',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${color || globalColors?.blue}`,
            color: color || globalColors?.blue,
          },
        }}
      >
        <LuDatabaseBackup size={'1.5rem'} />
        <ListItemText sx={{ textAlign: 'right' }} primary={'Import Data'} />
      </Button>

      {/* Leads */}
      {user?.role === UserRole.admin && (
        <Button
          onClick={() => handleSubMenuClick('/settings/leads')}
          sx={{
            textTransform: 'none',
            display: 'flex',
            fontWeight: 'bolder',
            cursor: 'pointer',
            alignItems: 'center',
            backgroundColor: pathname[pathname.length - 1] === 'leads' && pathname.includes('settings') ? color || globalColors?.blue : 'transparent',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '12px',
            // margin: '0px',
            marginLeft: '15px',
            marginRight: '20px',
            paddingLeft: '15px',
            paddingRight: '20px',
            border: '1px solid transparent',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${color || globalColors?.blue}`,
              color: color || globalColors?.blue,
            },
          }}
        >
          <LiaUsersCogSolid size={'1.5rem'} />
          <ListItemText sx={{ textAlign: 'right' }} primary={'Leads'} />
        </Button>
      )}

      {/* Commission */}
      {/* {user?.role === UserRole.admin && (
        <Button
          onClick={() => handleSubMenuClick('/settings/commission')}
          sx={{
            textTransform: 'none',
            display: 'flex',
            fontWeight: 'bolder',
            cursor: 'pointer',
            alignItems: 'center',
            backgroundColor:
              pathname[pathname.length - 1] === 'commission' || pathname[pathname.length - 2] === 'commission'
                ? color || globalColors?.blue
                : 'transparent',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '12px',
            // margin: '0px',
            marginLeft: '15px',
            marginRight: '20px',
            paddingLeft: '15px',
            paddingRight: '20px',
            border: '1px solid transparent',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${color || globalColors?.blue}`,
              color: color || globalColors?.blue,
            },
          }}
        >
          <CiMoneyCheck1 size={'1.5rem'} />
          <ListItemText sx={{ textAlign: 'right' }} primary={'Commission'} />
        </Button>
      )} */}

      {/* Integration Apps */}
      {/* {user?.role === UserRole.admin && (
        <Button
          onClick={() => handleSubMenuClick('/settings/integration')}
          sx={{
            textTransform: 'none',
            display: 'flex',
            fontWeight: 'bolder',
            cursor: 'pointer',
            alignItems: 'center',
            backgroundColor: pathname[pathname.length - 1] === 'integration' ? color || globalColors?.blue : 'transparent',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '12px',
            // margin: '0px',
            marginLeft: '15px',
            marginRight: '20px',
            paddingLeft: '15px',
            paddingRight: '20px',
            border: '1px solid transparent',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${color || globalColors?.blue}`,
              color: color || globalColors?.blue,
            },
          }}
        >
          <TbApps size={'1.5rem'} />
          <ListItemText sx={{ textAlign: 'right' }} primary={'App Integrations'} />
        </Button>
      )} */}

      {/* Notifications */}
      <Button
        onClick={() => handleSubMenuClick('/settings/notifications')}
        sx={{
          textTransform: 'none',
          display: 'flex',
          fontWeight: 'bolder',
          cursor: 'pointer',
          alignItems: 'center',
          backgroundColor: pathname[pathname.length - 1] === 'notifications' ? color || globalColors?.blue : 'transparent',
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '12px',
          // margin: '0px',
          marginLeft: '15px',
          marginRight: '20px',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: '1px solid transparent',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${color || globalColors?.blue}`,
            color: color || globalColors?.blue,
          },
        }}
      >
        <TbBellCog size={'1.5rem'} />
        <ListItemText sx={{ textAlign: 'right' }} primary={'Notifications'} />
      </Button>

      {/* Permissions */}
      {user?.role === UserRole.admin && (
        <Button
          onClick={() => handleSubMenuClick('/settings/permissions')}
          sx={{
            textTransform: 'none',
            display: 'flex',
            fontWeight: 'bolder',
            cursor: 'pointer',
            alignItems: 'center',
            backgroundColor: pathname[pathname.length - 1] === 'permissions' ? color || globalColors?.blue : 'transparent',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '12px',
            // margin: '0px',
            marginLeft: '15px',
            marginRight: '20px',
            paddingLeft: '15px',
            paddingRight: '20px',
            border: '1px solid transparent',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${color || globalColors?.blue}`,
              color: color || globalColors?.blue,
            },
          }}
        >
          <RiLock2Line size={'1.5rem'} />
          <ListItemText sx={{ textAlign: 'right' }} primary={'Permissions'} />
        </Button>
      )}

      {/* Permissions */}
      {user?.role === UserRole.admin && (
        <Button
          onClick={() => handleSubMenuClick('/settings/payments')}
          sx={{
            textTransform: 'none',
            display: 'flex',
            fontWeight: 'bolder',
            cursor: 'pointer',
            alignItems: 'center',
            backgroundColor: pathname[pathname.length - 1] === 'payments' || pathname.includes('payments') ? color || globalColors?.blue : 'transparent',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '12px',
            // margin: '0px',
            marginLeft: '15px',
            marginRight: '20px',
            paddingLeft: '15px',
            paddingRight: '20px',
            border: '1px solid transparent',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: 'transparent',
              border: `1px solid ${color || globalColors?.blue}`,
              color: color || globalColors?.blue,
            },
          }}
        >
          <RiMoneyDollarCircleFill size={'1.5rem'} />
          <ListItemText sx={{ textAlign: 'right' }} primary={'Payments'} />
        </Button>
      )}

      {/* Support */}
      <Button
        onClick={() => handleSubMenuClick('/settings/support')}
        sx={{
          textTransform: 'none',
          display: 'flex',
          fontWeight: 'bolder',
          cursor: 'pointer',
          alignItems: 'center',
          backgroundColor: pathname[pathname.length - 1] === 'support' ? color || globalColors?.blue : 'transparent',
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '12px',
          // margin: '0px',
          marginLeft: '15px',
          marginRight: '20px',
          paddingLeft: '15px',
          paddingRight: '20px',
          border: '1px solid transparent',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: 'transparent',
            border: `1px solid ${color || globalColors?.blue}`,
            color: color || globalColors?.blue,
          },
        }}
      >
        <RiQuestionMark size={'1.5rem'} />
        <ListItemText sx={{ textAlign: 'right' }} primary={'Support'} />
      </Button>
    </>
  );
};
