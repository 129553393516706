// import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import UserRepository from './userRepository';
import SalesExecutiveModel from '../models/salesExecutiveModel';
import ManagerRepository from './managerRepository';
import LeadRepository from './leadRepository';
import ActivityRepository from './activityRepository';
import TaskRepository from './taskRepository';

class SalesExecutiveRepository {
  private className = 'SalesExecutive';

  constructor() {}

  public async create(object: SalesExecutiveModel) {
    try {
      const SalesExecutive = Parse.Object.extend(this.className);
      const executiveObject = new SalesExecutive();
      executiveObject.set('user', object.user);
      executiveObject.set('manager', object.manager);

      return new Promise((resolve, _) => {
        executiveObject.save(null, { useMasterKey: true }).then(
          (savedExecutive: any) => {
            resolve(savedExecutive);
          },
          (error: any) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const SalesExecutive = Parse.Object.extend(this.className);
      const query = new Parse.Query(SalesExecutive).equalTo('objectId', id);

      query.include(['manager.activities', 'manager.leads', 'manager.user', 'manager', 'user']);

      return new Promise<Parse.Object | null>((resolve, _) => {
        query
          .first({ useMasterKey: true })
          .then(async (salesExecutive) => {
            resolve(salesExecutive as Parse.Object);
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } catch (error) {
      return null;
    }
  }

  public async getObjectByUserId(id: string): Promise<Parse.Object | null> {
    try {
      const User = Parse.User;
      const userQuery = new Parse.Query(User).equalTo('objectId', id);

      const SalesExecutive = Parse.Object.extend(this.className);
      const query = new Parse.Query(SalesExecutive).matchesQuery('user', userQuery);

      query.include(['manager.activities', 'manager.leads', 'manager.user', 'manager', 'user']);

      return new Promise<Parse.Object | null>((resolve, _) => {
        query
          .first({ useMasterKey: true })
          .then(async (salesExecutive) => {
            resolve(salesExecutive as Parse.Object);
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } catch (error) {
      return null;
    }
  }

  public async getAllManagedBy(queryFilters?: {role: string}): Promise<Parse.Object[] | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const managerRepository = new ManagerRepository();
        const manager = await managerRepository.getObjectByUserId(currentUser.id);
        if (manager) {
          const SalesExecutive = Parse.Object.extend(this.className);
          const query = new Parse.Query(SalesExecutive).equalTo('manager', manager).include(['user']);
          if (queryFilters && queryFilters.role !== 'Select') {
            const User = Parse.Object.extend('_User');
            const userQuery = new Parse.Query(User).equalTo('role', queryFilters.role);
            query.matchesQuery('user', userQuery);
          }
          return new Promise<Parse.Object[] | null>((resolve, _) => {
            query
              .find({ useMasterKey: true })
              .then((salesExecutives) => {
                resolve(salesExecutives);
              })
              .catch((error) => {
                resolve(null);
              });
          });
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async changeManager(objectId: string, managerUserId: string): Promise<Parse.Object | null> {
    try {
      const salesExecutive = await this.getObjectById(objectId);
      if (salesExecutive) {
        const managerRepository = new ManagerRepository();
        const newManager = await managerRepository.getObjectByUserId(managerUserId);
        if (newManager) {
          salesExecutive.set('manager', newManager);
          return new Promise<Parse.Object | null>((resolve, _) => {
            salesExecutive
              .save(null, { useMasterKey: true })
              .then(async (savedSalesExecutive) => {
                const leadRepository = new LeadRepository();
                const changeAllAssignedLeads = await leadRepository.changeAllManagedByForAssignedTo(savedSalesExecutive.id, newManager.get('user').id);
                const activityRepository = new ActivityRepository();
                const changeAllAssignedActivities = await activityRepository.changeAllManagedByForAssignedTo(savedSalesExecutive.id, newManager.get('user').id);
                const taskRepository = new TaskRepository();
                const changeAllAssignedTasks = await taskRepository.changeAllManagedByForAssignedTo(savedSalesExecutive.id, newManager.get('user').id);
                if (changeAllAssignedLeads && changeAllAssignedActivities && changeAllAssignedTasks) {
                  resolve(savedSalesExecutive);
                }
                else {
                  resolve(null);
                }
              })
              .catch((error) => {
                resolve(null);
              });
          });
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async getAllExecutivesForOrganization(organizationId: string): Promise<Parse.Object[] | null> {
    try {
      const User = Parse.Object.extend('_User');
      const userQuery = new Parse.Query(User).equalTo('organization', organizationId);
      const SalesExecutive = Parse.Object.extend(this.className);
      const query = new Parse.Query(SalesExecutive).matchesQuery('user', userQuery).include('user', 'manager').descending('createdAt');
      return new Promise<Parse.Object[] | null>((resolve, _) => {
        query
          .find({ useMasterKey: true })
          .then(async (salesExecutives) => {
            resolve(salesExecutives);
          })
          .catch((error) => {
            resolve(null);
          });
      });
    }
    catch (e) {
      console.error("Error fetching all executives for organization:", e);
      return null;
    }
  }

  public async addLeadsToSpecificUser(leads: Parse.Object, userId: string): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const user = await userRepository.getObjectById(userId);

    if (user) {
      const User = Parse.User;
      const userQuery = new Parse.Query(User).equalTo('objectId', user.id);

      const Manager = Parse.Object.extend(this.className);
      const query = new Parse.Query(Manager).matchesQuery('user', userQuery).include('manager');

      return new Promise((resolve, _) => {
        query
          .first({ useMasterKey: true })
          .then(async (salesExecutive) => {
            if (salesExecutive) {
              var manager = salesExecutive.get('manager');
              if (manager) {
                var managerLeads = manager.relation('leads');
                managerLeads.add(leads);
                const savedManager = await manager.save(null, { useMasterKey: true });
                resolve(savedManager);
              }
              else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async addLead(lead: Parse.Object): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const salesExecutive = await this.getObjectByUserId(currentUser.id);
        if (salesExecutive) {
          const manager = salesExecutive.get('manager');
          const managerLeads = manager.relation('leads');
          managerLeads.add(lead);
          return new Promise<Parse.Object | null>((resolve, _) => {
            manager
              .save(null, { useMasterKey: true })
              .then((savedManager: Parse.Object | null) => {
                resolve(savedManager);
              })
              .catch((error: Error) => {
                resolve(null);
              });
          });
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default SalesExecutiveRepository;
