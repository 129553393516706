import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import PaymentModel from '../models/paymentModel';
import LeadClientPaymentModel from '../models/leadClientPaymentModel';
import { dataLimit } from '../../helpers';

class LeadClientPaymentRepository {
  private className = 'LeadClientPayment';

  public async create(object: LeadClientPaymentModel): Promise<Parse.Object | null> {
    try {
      const LeadClientPayment = Parse.Object.extend(this.className);
      const newPayment = new LeadClientPayment();

      newPayment.set('currency', object.currency);
      newPayment.set('amount', object.amount);
      newPayment.set('organization', object.organization);
      newPayment.set('paymentMethod', object.paymentMethod);
      newPayment.set('currency', object.currency);
      newPayment.set('lead', object.lead);
      newPayment.set('nature', object.nature);
      newPayment.set('project', object.project);
      newPayment.set('projectComponent', object.projectComponent);
      newPayment.set('property', object.property);

      const savedPayment = await newPayment.save(null, { useMasterKey: true });

      console.log('New payment created with objectId:', savedPayment.id);
      return savedPayment;
    } catch (error: any) {
      console.error('Error creating payment:', error);
      return null;
    }
  }

  public async countByLeadId(leadId: string, organizationId: string, page?: number): Promise<number> {
    try {
      const LeadClientPayment = Parse.Object.extend(this.className);
      const query = new Parse.Query(LeadClientPayment);
      query
        .equalTo('lead', { __type: 'Pointer', className: 'Lead', objectId: leadId })
        .equalTo('organization', { __type: 'Pointer', className: 'Organization', objectId: organizationId })
        .limit(dataLimit);

      if (page) {
        query.skip((page - 1) * dataLimit);
      }
      const payments = await query.count({ useMasterKey: true });
      return payments;
    } catch (error: any) {
      console.error('Error retrieving payments by organization ID:', error);
      return 0;
    }
  }

  public async getByLeadId(leadId: string, organizationId: string, page?: number): Promise<LeadClientPaymentModel[]> {
    try {
      const LeadClientPayment = Parse.Object.extend(this.className);
      const query = new Parse.Query(LeadClientPayment);
      query
        .equalTo('lead', { __type: 'Pointer', className: 'Lead', objectId: leadId })
        .equalTo('organization', { __type: 'Pointer', className: 'Organization', objectId: organizationId })
        .limit(dataLimit);

      if (page) {
        query.skip((page - 1) * dataLimit);
      }
      const payments = await query.find({ useMasterKey: true });

      const paymentData: LeadClientPaymentModel[] = payments.map((payment: Parse.Object) => ({
        objectId: payment.id,
        paymentMethod: payment.get('paymentMethod'),
        currency: payment.get('currency'),
        lead: payment.get('lead'),
        nature: payment.get('nature'),
        amount: payment.get('amount'),
        organization: payment.get('organization'),
        createdAt: payment.createdAt,
        project: payment.get('project'),
        projectComponent: payment.get('projectComponent'),
        property: payment.get('property'),
      }));

      return paymentData;
    } catch (error: any) {
      console.error('Error retrieving payments by organization ID:', error);
      return [];
    }
  }

  public async delete(id: string) {
    try {
      const LeadClientPayment = Parse.Object.extend(this.className);
      const query = new Parse.Query(LeadClientPayment);

      const payment = await query.get(id, { useMasterKey: true });

      if (payment) {
        await payment.destroy({ useMasterKey: true });
        Toast(`Payment deleted successfully.`, 'success');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error deleting payment:', error);
      return false;
    }
  }
}

export default LeadClientPaymentRepository;
