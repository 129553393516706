import React, { useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import { useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import Home from '../../assets/Icons/Home_Icon.svg';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaChevronLeft, FaChevronRight, FaRegBell, FaSignOutAlt, FaUserCheck } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import logo from '../../assets/Images/logo.svg';
import { MdOutlineInventory, MdOutlineMailOutline } from 'react-icons/md';
import Navbar from './navbar';
import { globalColors } from '../../utils/constants/color';
import { useNavigate } from 'react-router-dom';
import { Button, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UpperSidebar from './upperSidebar';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole, resetUserData } from '../../store/user';
import {
  selectAddNew,
  selectColor,
  selectNavigationLayout,
  selectOpenSettings,
  selectOpenSidebar,
  selectOrganizationLogo,
  selectOrganizationTier,
  selectThemeMode,
  selectUserData,
} from '../../store/selector';
import { SettingsSubMenu } from './settingsSubMenu';
import WestIcon from '@mui/icons-material/West';
import { setAddNew, setOpenSettings, setOpenSidebar } from '../../store/routes';
import { useLocation } from 'react-router-dom';
import { SettingsSideSubMenu } from './settingsSideSubMenu';
import { LuLayoutDashboard } from 'react-icons/lu';
import { IoHomeOutline } from 'react-icons/io5';
import { RiBuilding4Line } from 'react-icons/ri';
import {
  PiShareNetwork,
  PiUserCircleBold,
  PiUserCircleGear,
  PiUserCirclePlus,
  PiUserCirclePlusBold,
  PiUserCirclePlusLight,
  PiUserCirclePlusThin,
  PiUserGear,
  PiUsersFourLight,
  PiUsersThreeLight,
} from 'react-icons/pi';
import { AiOutlineSchedule } from 'react-icons/ai';
import { IoShareSocialOutline } from 'react-icons/io5';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { BsBuilding, BsBuildingGear, BsBuildingUp, BsHouse, BsHouseGear } from 'react-icons/bs';
import AlertRepository from '../../utils/api/repositories/alertsRepository';
import { PricingTiers, showNotification } from '../../utils/helpers';
import { BiNetworkChart, BiSolidUserPin, BiTime } from 'react-icons/bi';
import { FaUserGear, FaUserGroup, FaUsersGear } from 'react-icons/fa6';
import { GrUserSettings } from 'react-icons/gr';
import Loader from '../atoms/loader';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface SidebarProps {
  text: string;
}

export default function Sidebar({ text }: SidebarProps) {
  const theme = useTheme();
  const sideBar = useSelector(selectOpenSidebar);
  const organizationTier = useSelector(selectOrganizationTier);
  const organizationLogo = useSelector(selectOrganizationLogo);
  // const logoRef = React.useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(sideBar || false);
  // const [active_item, setActive_item] = React.useState(true);
  const navigate = useNavigate();
  const color = useSelector(selectColor);

  const mode = useSelector(selectThemeMode);
  const showArrow = useSelector(selectAddNew);
  const navigation = useSelector(selectNavigationLayout);
  const openSetting = useSelector(selectOpenSettings);
  const [drawerWidth, setDrawerWidth] = React.useState(280);
  const location = useLocation();
  const [openPerfomance, setOpenPerfomance] = React.useState(false);
  const user = useSelector(selectUserData);

  // dispatch(setAddNew({ path: '/test' }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // backgroundColor: "#171717",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      delay: theme.transitions.duration.shortest,
    }),
    backgroundColor: open ? globalColors?.darkblack : globalColors?.darkblack,
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin', 'background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        delay: theme.transitions.duration.shortest,
      }),
    }),
  }));

  const validBackPaths = [
    '/properties/new',
    '/properties/edit',
    '/projects/new',
    '/projects/edit',
    '/leads/new',
    '/leads/edit',
    '/clients/new',
    '/clients/edit',
    '/activity/task',
    '/email/new',
    '/socialmedia/new',
    '/settings/commission/newTeam',
    '/email/schedule',
    '/email/setup',
    '/alerts/newGroup',
    '/settings/commission/edit',
    '/projects/edit',
    '/performance/company/report',
    '/hr/members/addMember',
    '/performance/company/manage',
    '/settings/payments/changePlan',
    // '/performance/organization/profile',
    // '/performance/organization/history',
    '/manage/conversions/page',
    '/conversions/requests',
    '/hr/member',
    '/inventory'
  ];

  const settingPaths = ['/settings/leads', '/settings/commission', '/settings/integration', '/settings/notifications', '/settings/import'];

  const handleBackClick = () => {
    navigate(showArrow?.path);
    dispatch(setAddNew({ addNew: false, path: '' }));
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    backgroundColor: globalColors?.darkblack,
    transition: theme.transitions.create(['width', 'background-color', 'opacity'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.complex,
      delay: theme.transitions.duration.shortest,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: globalColors?.darkblack,
    transition: theme.transitions.create(['width', 'background-color', 'opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
      delay: theme.transitions.duration.shortest,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    backgroundColor: globalColors?.black,
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const isMobile = useMediaQuery('(max-width:900px)');

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setOpenSidebar());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setOpenSidebar());
  };

  // useEffect(() => {
  //   setOpen(false);
  // }, [navigation]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 900;

      if (isMobile) {
        setDrawerWidth(window.innerWidth);
      } else {
        setDrawerWidth(280);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
          height: '80px',
          margin: 'auto',
          paddingTop: 1,
        }}
        open={open}
      >
        <Toolbar>
          {navigation && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '5px',
                ...(open && { display: 'none' }),
                backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
              }}
            >
              <FaChevronRight color={mode === 'dark' ? globalColors?.white : globalColors.black} />
              {/* <BiMenuAltRight color={mode === 'dark' ? globalColors?.white : globalColors.black} size={'2rem'} /> */}
            </IconButton>
          )}
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'} margin={'auto'} alignItems={'center'}>
            {!navigation ? (
              <img style={{ marginLeft: '32px' }} src={logo} alt="logo" />
            ) : (
              // <Grow in={!navigation}></Grow>
              <Box sx={{ display: 'flex', alignItems: 'center', width: isMobile ? '70%' : '30%' }}>
                {showArrow.addNew &&
                (validBackPaths.includes(window.location.pathname) ||
                  validBackPaths.some((backPath) => window.location.pathname.includes(backPath))) ? (
                  <WestIcon sx={{ cursor: 'pointer', color: mode === 'dark' ? globalColors.white : globalColors.black }} onClick={handleBackClick} />
                ) : (
                  <></>
                )}
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  marginTop={'auto'}
                  marginBottom={'auto'}
                  color={mode === 'dark' ? globalColors?.white : globalColors.black}
                  sx={{ marginLeft: '2%' }}
                >
                  {text}
                </Typography>
              </Box>
            )}
            <Navbar mode={mode} bgcolor={mode === 'dark' ? globalColors.black : globalColors?.white} color={color} />
          </Box>
        </Toolbar>
      </AppBar>
      {navigation ? (
        <Drawer
          variant="permanent"
          className={!open ? 'sidebar' : ''}
          open={open}
          sx={{
            '& .MuiDrawer-paper': { backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray },
          }}
        >
          <DrawerHeader
            sx={{
              backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <FaChevronRight color={mode === 'dark' ? globalColors?.white : globalColors.black} />
              ) : (
                <FaChevronLeft color={mode === 'dark' ? globalColors?.white : globalColors.black} />
              )}
            </IconButton>
          </DrawerHeader>
          <div
            style={{
              height: !open ? '100%' : 'auto',
              backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
            }}
          >
            <Box display={'flex'} marginTop={open ? '-50px' : '10px'} paddingLeft={'10px'}>
              {open && (
                // <Grow in={true} timeout={1000}><img width={open ? 120 : 60} src={logo} alt="" /></Grow>
                <img height={72} loading={'lazy'} src={organizationLogo ?? logo} alt="" />
              )}
            </Box>
            <List
              sx={{
                margin: 'auto',
                display: 'flex',
                marginTop: '10px',
                flexDirection: 'column',
                justifyContent: openSetting ? 'start' : 'space-between',
              }}
            >
              {/* <Box> */}
              {/* <ListItem disablePadding>
                <ListItemButton
                  sx={{ color: 'white', justifyContent: 'space-between', display: 'flex' }}
                  onClick={() => setActive_item((prev) => !prev)}
                >
                  <ListItemIcon>
                    <FaHome size={'1.5rem'} color={color !== 'default' && color !== '' ? color : '#0455C6'} />
                  </ListItemIcon>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ListItemText
                      style={{
                        color: color || globalColors?.blue,
                        fontWeight: 'bolder',
                        marginRight: 'auto',
                      }}
                      primary={'Home'}
                    />
                    <RiArrowDropDownFill
                      style={{
                        transform: active_item ? 'rotate(180deg)' : '',
                        color: color || '#0455C6',
                      }}
                      size={'2rem'}
                      color="#0455C6"
                    />
                  </Box>
                </ListItemButton>
              </ListItem> */}

              {/* <Box padding={'12px 12px'}>
                <Divider sx={{ backgroundColor: mode === 'dark' ? globalColors.white : globalColors.black }} />
              </Box> */}

              {!openSetting ? (
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={'5px'}>
                  {/* Dashboard */}
                  <>
                    {open === false ? (
                      <Button
                        onClick={() => navigate('/dashboard')}
                        sx={{
                          marginLeft: '-10px',
                          color: text.includes('Dashboard') ? color || globalColors?.blue : mode === 'dark' ? globalColors.white : globalColors.black,
                          transition: 'color 0.3s',
                          '&:hover': {
                            color: mode === 'dark' ? globalColors?.white : globalColors.black,
                          },
                          border: '1px solid transparent',
                        }}
                      >
                        <LuLayoutDashboard size={'1.5rem'} style={{ cursor: 'pointer' }} />
                      </Button>
                    ) : open === true ? (
                      <Button
                        onClick={() => navigate('/dashboard')}
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          fontWeight: 'bolder',
                          cursor: 'pointer',
                          alignItems: 'center',
                          backgroundColor: text.includes('Dashboard') ? color || globalColors?.blue : '',
                          color: mode === 'dark' ? globalColors.white : text.includes('Dashboard') ? globalColors.white : globalColors.black,
                          borderRadius: '12px',
                          // margin: '0px',
                          marginLeft: '15px',
                          marginRight: '20px',
                          paddingLeft: '15px',
                          paddingRight: '20px',
                          border: '1px solid transparent',
                          transition: 'background-color 0.3s',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            border: `1px solid ${color || globalColors?.blue}`,
                            color: color || globalColors?.blue,
                          },
                        }}
                      >
                        <LuLayoutDashboard size={'1.5rem'} />
                        <ListItemText sx={{ textAlign: 'right' }} primary={'Dashboard'} />
                      </Button>
                    ) : (
                      <></>
                    )}

                    <Box padding={'0px 12px'}>
                      <Divider sx={{ backgroundColor: globalColors.gray }} />
                      {open && <Typography sx={{ color: color || globalColors.blue, textAlign: 'center', paddingTop: '10px' }}>Manage</Typography>}
                    </Box>
                    <>
                      {/* Properties */}
                      {open === false ? (
                        <Button
                          onClick={() => navigate('/properties')}
                          sx={{
                            marginLeft: '-10px',
                            color:
                              text.includes('Properties') && !text.includes('Performance')
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                            transition: 'color 0.3s',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                            border: '1px solid transparent',
                          }}
                        >
                          <BsHouse size={'1.5rem'} style={{ cursor: 'pointer' }} />
                        </Button>
                      ) : open === true ? (
                        <Button
                          onClick={() => navigate('/properties')}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: text.includes('Properties') && !text.includes('Performance') ? color || globalColors?.blue : '',
                            color:
                              mode === 'dark'
                                ? globalColors.white
                                : text.includes('Properties') && !text.includes('Performance')
                                ? globalColors.white
                                : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          <BsHouse size={'1.5rem'} />
                          <ListItemText sx={{ textAlign: 'right' }} primary={'Properties'} />
                        </Button>
                      ) : (
                        <></>
                      )}
                      {/* Project */}
                      {open === false ? (
                        <Button
                          onClick={() => navigate('/projects')}
                          sx={{
                            marginLeft: '-10px',
                            color:
                              text.includes('Projects') && !text.includes('Performance')
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                            transition: 'color 0.3s',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                            border: '1px solid transparent',
                          }}
                        >
                          <BsBuilding size={'1.5rem'} />
                        </Button>
                      ) : open === true ? (
                        <Button
                          onClick={() => navigate('/projects')}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: text.includes('Projects') && !text.includes('Performance') ? color || globalColors?.blue : '',
                            color:
                              mode === 'dark'
                                ? globalColors.white
                                : text.includes('Projects') && !text.includes('Performance')
                                ? globalColors.white
                                : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          <BsBuilding size={'1.5rem'} />
                          <ListItemText sx={{ textAlign: 'right' }} primary={'Projects'} />
                        </Button>
                      ) : (
                        <></>
                      )}
                      {/* Inventory */}
                      {open === false ? (
                        <Button
                          onClick={() => navigate('/inventory')}
                          sx={{
                            marginLeft: '-10px',
                            color:
                              text.includes('Inventory') || text.includes('Select Project')
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                            transition: 'color 0.3s',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                            border: '1px solid transparent',
                          }}
                        >
                          <MdOutlineInventory size={'1.5rem'} />
                        </Button>
                      ) : open === true ? (
                        <Button
                          onClick={() => navigate('/inventory')}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: text.includes('Inventory') || text.includes('Select Project') ? color || globalColors?.blue : '',
                            color:
                              mode === 'dark'
                                ? globalColors.white
                                : text.includes('Inventory') || text.includes('Select Project')
                                ? globalColors.white
                                : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          <MdOutlineInventory size={'1.5rem'} />
                          <ListItemText sx={{ textAlign: 'right' }} primary={'Inventory'} />
                        </Button>
                      ) : (
                        <></>
                      )}
                      {/* Leads */}
                      {open === false ? (
                        <Button
                          onClick={() => navigate('/leads')}
                          sx={{
                            marginLeft: '-10px',
                            color:
                              text.includes('Leads') && !text.includes('Settings')
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                            transition: 'color 0.3s',
                            border: '1px solid transparent',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                          }}
                        >
                          <PiUsersThreeLight size={'1.5rem'} />
                        </Button>
                      ) : open === true ? (
                        <Button
                          onClick={() => navigate('/leads')}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: text.includes('Leads') && !text.includes('Settings') ? color || globalColors?.blue : '',
                            color:
                              mode === 'dark'
                                ? globalColors.white
                                : text.includes('Leads') && !text.includes('Settings')
                                ? globalColors.white
                                : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          <PiUsersThreeLight size={'1.5rem'} />
                          <ListItemText sx={{ textAlign: 'right' }} primary={'Leads'} />
                        </Button>
                      ) : (
                        <></>
                      )}

                      {open === false ? (
                        <Button
                          onClick={() => navigate('/clients')}
                          sx={{
                            marginLeft: '-10px',
                            color:
                              text.includes('Clients') && !text.includes('Settings')
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                            transition: 'color 0.3s',
                            border: '1px solid transparent',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                          }}
                        >
                          <PiUserCirclePlusLight size={'1.5rem'} />
                        </Button>
                      ) : open === true ? (
                        <Button
                          onClick={() => navigate('/clients')}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: text.includes('Clients') && !text.includes('Settings') ? color || globalColors?.blue : '',
                            color:
                              mode === 'dark'
                                ? globalColors.white
                                : text.includes('Clients') && !text.includes('Settings')
                                ? globalColors.white
                                : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          <PiUserCirclePlusLight size={'1.5rem'} />
                          <ListItemText sx={{ textAlign: 'right' }} primary={'Clients'} />
                        </Button>
                      ) : (
                        <></>
                      )}
                      {/* Calendar */}
                      {organizationTier == PricingTiers.Professional &&
                        (open === false ? (
                          <Button
                            onClick={() => navigate('/schedule')}
                            sx={{
                              marginLeft: '-10px',
                              color: text.includes('Schedule')
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                              transition: 'color 0.3s',
                              border: '1px solid transparent',
                              '&:hover': {
                                color: mode === 'dark' ? globalColors?.white : globalColors.black,
                              },
                            }}
                          >
                            <AiOutlineSchedule size={'1.5rem'} />
                          </Button>
                        ) : open === true ? (
                          <Button
                            onClick={() => navigate('/schedule')}
                            sx={{
                              textTransform: 'none',
                              display: 'flex',
                              fontWeight: 'bolder',
                              cursor: 'pointer',
                              alignItems: 'center',
                              backgroundColor: text.includes('Schedule') ? color || globalColors?.blue : '',
                              color: mode === 'dark' ? globalColors.white : text.includes('Schedule') ? globalColors.white : globalColors.black,
                              borderRadius: '12px',
                              // margin: '0px',
                              marginLeft: '15px',
                              marginRight: '20px',
                              paddingLeft: '15px',
                              paddingRight: '20px',
                              border: '1px solid transparent',
                              transition: 'background-color 0.3s',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                border: `1px solid ${color || globalColors?.blue}`,
                                color: color || globalColors?.blue,
                              },
                            }}
                          >
                            <AiOutlineSchedule size={'1.5rem'} />
                            <ListItemText sx={{ textAlign: 'right' }} primary={'Schedule'} />
                          </Button>
                        ) : (
                          <></>
                        ))}
                      {/* Conversions */}
                      {open === false ? (
                        <Button
                          onClick={() => navigate('/conversions')}
                          sx={{
                            marginLeft: '-10px',
                            color: text.includes('Conversions')
                              ? color || globalColors?.blue
                              : mode === 'dark'
                              ? globalColors.white
                              : globalColors.black,
                            transition: 'color 0.3s',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                            border: '1px solid transparent',
                          }}
                        >
                          <PiUserCirclePlus size={'1.5rem'} />
                        </Button>
                      ) : open === true ? (
                        <Button
                          onClick={() => navigate('/conversions')}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: text.includes('Conversions') ? color || globalColors?.blue : '',
                            color: mode === 'dark' ? globalColors.white : text.includes('Conversions') ? globalColors.white : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          <PiUserCirclePlus size={'1.5rem'} />
                          <ListItemText sx={{ textAlign: 'right' }} primary={'Conversions'} />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </>

                    {organizationTier == PricingTiers.Professional && (
                      <>
                        <Box padding={'0px 12px'}>
                          <Divider sx={{ backgroundColor: globalColors.gray }} />
                          {open && (
                            <Typography sx={{ color: color || globalColors.blue, textAlign: 'center', paddingTop: '10px' }}>Communicate</Typography>
                          )}
                        </Box>
                        <>
                          {/* Email */}
                          {open === false ? (
                            <Button
                              onClick={() => navigate('/email')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Email')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <MdOutlineMailOutline size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/email')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Email') ? color || globalColors?.blue : '',
                                color: mode === 'dark' ? globalColors.white : text.includes('Email') ? globalColors.white : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <MdOutlineMailOutline size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Email'} />
                            </Button>
                          ) : (
                            <></>
                          )}
                          {/* Social Media */}
                          {open === false ? (
                            <Button
                              onClick={() => navigate('/socialmedia')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Social Media')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <IoShareSocialOutline size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/socialmedia')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Social Media') ? color || globalColors?.blue : '',
                                color: mode === 'dark' ? globalColors.white : text.includes('Social Media') ? globalColors.white : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <IoShareSocialOutline size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Social Media'} />
                            </Button>
                          ) : (
                            <></>
                          )}

                          {/* Alerts */}
                          {open === false ? (
                            <Button
                              onClick={() => navigate('/alerts')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Alerts')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <FaRegBell size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/alerts')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Alerts') ? color || globalColors?.blue : '',
                                color: mode === 'dark' ? globalColors.white : text.includes('Alerts') ? globalColors.white : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <FaRegBell size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Alerts'} />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    )}

                    {/* Performance */}
                    {user?.role == UserRole.admin && (
                      <>
                        {' '}
                        <Box padding={'0px 12px'}>
                          <Divider sx={{ backgroundColor: globalColors.gray }} />
                          {open && (
                            <Typography sx={{ color: color || globalColors.blue, textAlign: 'center', paddingTop: '10px' }}>Performance</Typography>
                          )}
                        </Box>
                        <>
                          {open === false ? (
                            <Button
                              onClick={() => navigate('/performance/company')}
                              sx={{
                                marginLeft: '-10px',
                                color:
                                  text.includes('Company Performance') ||
                                  text.includes('Performance / Member') ||
                                  text.includes('Company / Add Member') ||
                                  text.includes('Company / Manage')
                                    ? color || globalColors?.blue
                                    : mode === 'dark'
                                    ? globalColors.white
                                    : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <IoAnalyticsOutline size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/performance/company')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor:
                                  text.includes('Company Performance') ||
                                  text.includes('Performance / Member') ||
                                  text.includes('Company / Add Member') ||
                                  text.includes('Company / Manage')
                                    ? color || globalColors?.blue
                                    : '',
                                color:
                                  mode === 'dark'
                                    ? globalColors.white
                                    : text.includes('Company Performance') ||
                                      text.includes('Performance / Member') ||
                                      text.includes('Company / Add Member') ||
                                      text.includes('Company / Manage')
                                    ? globalColors.white
                                    : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <IoAnalyticsOutline size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Company'} />
                            </Button>
                          ) : (
                            <></>
                          )}

                          {open === false ? (
                            <Button
                              onClick={() => navigate('/performance/properties')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Properties Performance')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <BsHouseGear size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/performance/properties')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Properties Performance') ? color || globalColors?.blue : '',
                                color:
                                  mode === 'dark'
                                    ? globalColors.white
                                    : text.includes('Properties Performance')
                                    ? globalColors.white
                                    : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <BsHouseGear size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Properties'} />
                            </Button>
                          ) : (
                            <></>
                          )}

                          {open === false ? (
                            <Button
                              onClick={() => navigate('/performance/projects')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Projects Performance')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <BsBuildingGear size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/performance/projects')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Projects Performance') ? color || globalColors?.blue : '',
                                color:
                                  mode === 'dark'
                                    ? globalColors.white
                                    : text.includes('Projects Performance')
                                    ? globalColors.white
                                    : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <BsBuildingGear size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Projects'} />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    )}

                    {/* Activity */}
                    {organizationTier == PricingTiers.Professional && (
                      <>
                        <Box padding={'0px 12px'}>
                          <Divider sx={{ backgroundColor: globalColors.gray }} />
                          {open && (
                            <Typography sx={{ color: color || globalColors.blue, textAlign: 'center', paddingTop: '10px' }}>Activity</Typography>
                          )}
                        </Box>
                        <>
                          {open === false ? (
                            <Button
                              onClick={() => navigate('/activity/tasks')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Tasks')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <BiTime size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/activity/tasks')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Tasks') ? color || globalColors?.blue : '',
                                color: mode === 'dark' ? globalColors.white : text.includes('Tasks') ? globalColors.white : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <BiTime size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Tasks'} />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    )}

                    {user?.role == UserRole.admin && (
                      <>
                        <Box padding={'0px 12px'}>
                          <Divider sx={{ backgroundColor: globalColors.gray }} />
                          {open && <Typography sx={{ color: color || globalColors.blue, textAlign: 'center', paddingTop: '10px' }}>HR</Typography>}
                        </Box>
                        <>
                          {open === false ? (
                            <Button
                              onClick={() => navigate('/hr/members')}
                              sx={{
                                marginLeft: '-10px',
                                color: text.includes('Members')
                                  ? color || globalColors?.blue
                                  : mode === 'dark'
                                  ? globalColors.white
                                  : globalColors.black,
                                transition: 'color 0.3s',
                                '&:hover': {
                                  color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                },
                                border: '1px solid transparent',
                              }}
                            >
                              <PiUserGear size={'1.5rem'} />
                            </Button>
                          ) : open === true ? (
                            <Button
                              onClick={() => navigate('/hr/members')}
                              sx={{
                                textTransform: 'none',
                                display: 'flex',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                alignItems: 'center',
                                backgroundColor: text.includes('Members') ? color || globalColors?.blue : '',
                                color: mode === 'dark' ? globalColors.white : text.includes('Members') ? globalColors.white : globalColors.black,
                                borderRadius: '12px',
                                // margin: '0px',
                                marginLeft: '15px',
                                marginRight: '20px',
                                paddingLeft: '15px',
                                paddingRight: '20px',
                                border: '1px solid transparent',
                                transition: 'background-color 0.3s',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  border: `1px solid ${color || globalColors?.blue}`,
                                  color: color || globalColors?.blue,
                                },
                              }}
                            >
                              <PiUserGear size={'1.5rem'} />
                              <ListItemText sx={{ textAlign: 'right' }} primary={'Members'} />
                            </Button>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    )}
                  </>
                </Box>
              ) : (
                <></>
              )}

              {/* Settings */}
              {
                <>
                  <Box padding={'5px 12px'}>
                    <Divider sx={{ backgroundColor: globalColors.gray }} />
                  </Box>
                  <Box marginTop={!openSetting ? '5px' : '0px'}>
                    {open === false ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <Button
                          onClick={() => dispatch(setOpenSettings())}
                          sx={{
                            marginLeft: '-10px',
                            marginBottom: openSetting ? '5px' : '0px',
                            color:
                              settingPaths.includes(location.pathname) && !openSetting
                                ? color || globalColors?.blue
                                : mode === 'dark'
                                ? globalColors.white
                                : globalColors.black,
                            transition: 'color 0.3s',
                            '&:hover': {
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                            },
                          }}
                        >
                          {!openSetting ? (
                            <IoSettingsOutline size={'1.5rem'} />
                          ) : (
                            <FaChevronLeft size={'1.5rem'} color={mode === 'dark' ? globalColors?.white : globalColors.black} />
                          )}
                        </Button>
                        {/* {openSetting ? <SettingsSideSubMenu /> : <></>} */}
                      </Box>
                    ) : (
                      <>
                        <Box
                          // onClick={() => navigate('/settings')}
                          onClick={() => dispatch(setOpenSettings())}
                          display={'flex'}
                          sx={{
                            display: 'flex',
                            fontWeight: 'bolder',
                            cursor: 'pointer',
                            alignItems: 'center',
                            backgroundColor: settingPaths.includes(location.pathname) && !openSetting ? color || globalColors?.blue : '',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            color:
                              mode === 'dark'
                                ? globalColors.white
                                : settingPaths.includes(location.pathname)
                                ? globalColors.white
                                : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            marginLeft: '15px',
                            marginBottom: openSetting ? '5px' : '0px',
                            marginRight: '20px',
                            paddingLeft: '15px',
                            paddingRight: '20px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: !openSetting ? `1px solid ${color || globalColors?.blue}` : '1px solid transparent',
                              // borderLeft: `4px solid ${color || globalColors?.blue}`,
                              color: color || globalColors?.blue,
                            },
                          }}
                        >
                          {!openSetting ? (
                            <IoSettingsOutline size={'1.5rem'} />
                          ) : (
                            <FaChevronLeft size={'1.5rem'} color={mode === 'dark' ? globalColors?.white : globalColors.black} />
                          )}
                          <ListItemText sx={{ textAlign: openSetting ? 'right' : 'center' }} primary={!openSetting ? 'Settings' : 'Go Back'} />
                          {openSetting ? (
                            <></>
                          ) : (
                            <FaChevronRight size={'1.5rem'} color={mode === 'dark' ? globalColors?.white : globalColors.black} />
                          )}
                        </Box>
                        {/* {openSetting ? <SettingsSubMenu /> : <></>} */}
                      </>
                    )}
                    {openSetting && !open && (
                      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={'5px'}>
                        <SettingsSideSubMenu />
                      </Box>
                    )}
                    {openSetting && open && (
                      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={'5px'}>
                        <SettingsSubMenu />
                      </Box>
                    )}

                    {/* <>
                  <Box padding={'20px 12px'}>
                    <Divider sx={{ backgroundColor: mode === 'dark' ? globalColors.white : globalColors.black }} />
                  </Box>
                  <ListItem disablePadding>
                    <ListItemButton sx={{ color: 'white', display: 'flex', justifyContent: 'space-between' }} onClick={() => handleLogout()}>
                      <ListItemIcon
                        sx={{
                          color: globalColors?.red,
                          transition: 'color 0.3s',
                          '&:hover': {
                            color: mode === 'dark' ? globalColors?.white : globalColors.black,
                          },
                        }}
                      >
                        <FaSignOutAlt size={'1.5rem'} />
                      </ListItemIcon>
                      <ListItemText
                        style={{
                          color: globalColors?.red,
                          fontWeight: 'bolder',
                          textAlign: 'right',
                        }}
                        primary={'Log Out'}
                      />
                    </ListItemButton>
                  </ListItem>
                </> */}
                  </Box>
                </>
              }
            </List>
          </div>
        </Drawer>
      ) : (
        <UpperSidebar />
      )}
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box> */}
    </Box>
  );
}
