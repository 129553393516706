import { ICArrowDown } from '../../assets/Icons';
import { Popover, Typography, styled } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarView, onSwitchViewCalendar } from '../../store/calendar';
import { selectCalendarView } from '../../store/selector';

const ViewTitle = {
  day: 'Daily',
  week: 'Weekly',
  month: 'Monthly',
};

interface NavigationCalendarProps {
  mode: string;
}

interface NavigationCalendarStyleProps {
  mode: string;
}

const NavigationCalendar = ({ mode }: NavigationCalendarProps) => {
  const dispatch = useDispatch();
  const view = useSelector(selectCalendarView);

  // ! Handling view calendar
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'view-popover' : undefined;

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onClickView = (view: CalendarView) => {
    handleClosePopover();
    dispatch(onSwitchViewCalendar(view));
  };

  return (
    <NavigationCalendarStyle mode={mode}>
      <div className="tags">
        <div className="tag inPerson">
          <div className="tag-icon"></div>
          <p>In Person</p>
        </div>
        <div className="tag online">
          <div className="tag-icon"></div>
          <p>Online</p>
        </div>
      </div>
      <div className="btn-action-wrapper">
        {/* <button className="btn sync">
          <p>Sync</p>
        </button>
        <button className="btn notification">
          <p>Notification</p>
          <div className="switch"></div>
        </button> */}
        <button className="btn month" onClick={handleOpenPopover}>
          <p>{ViewTitle[view]}</p>
          <img src={ICArrowDown} alt="arrow down" />
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPopover-paper': {
              background: globalColors.blackLight,
              top: {
                lg: '168px !important',
                md: '168px !important',
                sm: '230px !important',
                xs: '296px !important',
              },
            },
          }}
        >
          <PopoverStyle>
            <Typography onClick={() => onClickView('month')} className="view-option">
              Monthly
            </Typography>
            <Typography onClick={() => onClickView('week')} className="view-option list">
              Weekly
            </Typography>
            <Typography onClick={() => onClickView('day')} className="view-option list">
              Daily
            </Typography>
          </PopoverStyle>
        </Popover>
      </div>
    </NavigationCalendarStyle>
  );
};

const NavigationCalendarStyle = styled('div')<NavigationCalendarStyleProps>(({ theme, mode }) => ({
  display: 'flex',
  gap: '2rem',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'start',
    gap: '1rem',
  },
  '& p': {
    margin: '0px',
    padding: '0px',
    color: mode === 'dark' ? globalColors?.white : globalColors.black,
  },

  '& .tags': {
    display: 'flex',
    gap: '1rem',
    height: 'max-content',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& .tag': {
      display: 'flex',
      gap: '1rem',
      justifyItems: 'center',
      alignItems: 'center',
      height: 'max-content',
      '& p': {
        fontSize: '10px',
      },
      '& .tag-icon': {
        width: '16px',
        height: '16px',
        borderRadius: '5px',
      },

      '&.inPerson': {
        color: globalColors.calendar.type.inPerson,
        '& .tag-icon': {
          background: globalColors.calendar.type.inPerson,
        },
      },

      '&.online': {
        color: globalColors.calendar.type.online,
        '& .tag-icon': {
          background: globalColors.calendar.type.online,
        },
      },
    },
  },

  '& .btn-action-wrapper': {
    display: 'flex',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& .btn': {
      all: 'unset',
      border: '1px solid #303030',
      justifyItems: 'center',
      height: '40px',
      padding: '0 1rem',
      borderRadius: '8px',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '1rem',
      color: mode === 'dark' ? globalColors?.white : globalColors.black,

      '& p': {
        fontSize: '1rem',
        fontWeight: 500,
      },

      '&.month': {
        '& img': {
          width: '24px',
          height: '24px',
        },
      },

      '&:hover': {
        cursor: 'pointer',
        border: '1px solid #599BF7',
      },
    },
  },

  // ! Color
}));

const PopoverStyle = styled('div')(({ theme }) => ({
  '& .view-option': {
    width: '165px',
    background: globalColors.blackLight,
    color: globalColors.text.secondary,
    cursor: 'pointer',
    borderRadius: '12px',
    padding: '4px 1rem',
    '&:hover': {
      background: globalColors.blue,
      color: globalColors.white,
    },

    '&.list': {
      marginTop: '0.5rem',
    },
  },
}));

export default NavigationCalendar;
