import { Typography, styled } from '@mui/material';
import React from 'react';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';
interface FieldInputTextareaProps {
  id: string;
  name: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  className?: string;
  rows?: number;
}

const FieldInputTextarea = ({ label, placeholder, onChange, value, name, rows = 4, id, className }: FieldInputTextareaProps) => {
  const mode = useSelector(selectThemeMode);
  return (
    <FieldInputStyle className={className} mode={mode}>
      {label && (
        <Typography mb="1rem" color={mode === "dark" ? globalColors.white : globalColors.black} fontSize="1rem">
          {label}
        </Typography>
      )}
      <div className="group-input">
        <textarea className="textarea" value={value} placeholder={placeholder} id={id} name={name} rows={rows} onChange={onChange}>
          {value}
        </textarea>
      </div>
    </FieldInputStyle>
  );
};

const FieldInputStyle = styled('div')<{ mode: string }>((props) => ({
  width: '100%',
  color: props.mode === 'dark' ? 'white' : 'black',

  '& .group-input': {
    padding: '1rem',
    backgroundColor: props.mode === 'dark' ? globalColors.black : globalColors.white,
    border: props.mode === 'dark' ? '2px solid #303030' : '1px solid #303030',
    height: 'max-content',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',

    '& .textarea': {
      color: props.mode === 'dark' ? globalColors.text.main : globalColors.black,
      backgroundColor: 'transparent',
      border: 'none',
      minHeight: '50px',
      flex: 1,
      width: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
  },
}));

export default FieldInputTextarea;
