import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/molecules/layout';
import CardProductItem from '../../components/molecules/products/cardProductItem';
import { FormProductSkeleton, ProductDetail } from '../../components/organisms';
import FormAddProject from '../../components/organisms/product/formProject';
import useEditProject from '../../hooks/project/useEditProject';
import { ProductCategories } from '../../utils/types/products';
import { CardSkeleton } from '../../components/molecules';
import { selectNavigationLayout, selectThemeMode } from '../../store/selector';
import AddProductCard from '../../components/molecules/products/addProjectCard';
import { useEffect, useState } from 'react';
import { formatDate, hasPermissionForAction, isLocked } from '../../utils/helpers';
import NoPermission from '../../components/atoms/noPermission';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectRepository from '../../utils/api/repositories/projectRepository';
import { Toast } from '../../components/atoms';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import Locked from '../../components/atoms/locked';
import Loader from '../../components/atoms/loader';

const EditProject = () => {
  const { formProject, title, isLoading } = useEditProject();
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [locked, setLocked] = useState(false);
  const { id } = useParams();

  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const navigate = useNavigate();

  const onUnlock = async () => {
    if (id) {
      const projectRepository = new ProjectRepository();
      const unlockedProject = await projectRepository.unlock(id);
      if (unlockedProject) {
        Toast('Project unlocked successfully.', 'success');
        setLocked(false);
      }
    } else {
      Toast('Project could not be unlocked successfully. Please try again.', 'error');
      setTimeout(() => {
        navigate('/projects');
      }, 1000);
    }
  };

  const fetchStatus = async () => {
    setLoading(true);
    if (id) {
      const projectRepository = new ProjectRepository();
      const project = await projectRepository.getObjectByObjectId(id);
      if (project) {
        setLocked(project.get('lockedFor') !== null && isLocked(project.get('lockedUntil')));
      }
    } else {
      Toast('Project could not be fetched successfully. Please try again.', 'error');
      setTimeout(() => {
        navigate('/projects');
      }, 1000);
    }
    setLoading(false);
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Projects', 'Update'));
    fetchStatus();
  }, []);

  return loading ? (
    <Box display={'flex'} height={'100vh'} alignItems={'center'}>
      <Loader />
    </Box>
  ) : (
    <MainLayout titlePage={title} mode={mode} navigation={navigation}>
      {locked ? (
        <Box display={'flex'} height={'100vh'} alignItems={'center'} justifyContent={'center'}>
          <Locked
            text={`Looks like the project you are trying to access is locked ${
              formProject.lockedUntil ? 'until ' + formatDate(formProject.lockedUntil, true) : 'temorarily'
            }${formProject.lockedFor ? `, for the lead ${formProject.lockedFor.get('firstName')} ${formProject.lockedFor.get('lastName')}` : ''}.`}
            onUnlock={onUnlock}
          />
        </Box>
      ) : (
        <>
          {isLoading && <ProductDetail form={<FormProductSkeleton />} mode={mode} preview={<CardSkeleton />} />}

          {!isLoading &&
            (hasPermission ? (
              <ProductDetail
                form={<FormAddProject type="edit" mode={mode} />}
                mode={mode}
                preview={<AddProductCard category={ProductCategories.Project} mode={mode} data={formProject} active={true} />}
              />
            ) : (
              <NoPermission />
            ))}
        </>
      )}
    </MainLayout>
  );
};

export default EditProject;
