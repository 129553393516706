import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../utils/constants/color';
import ViewAllComments from './viewAllComments';
import LeadsDetails from './leadsDetails';
import ModalCommunicate from './modalCommunicate';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import { useDispatch } from 'react-redux';
import { LoadingFtn } from '../../../store/slices';
import ViewAllCommentsAndConvert from './viewAllCommentsAndConvert';
import ModalPaymentHistory from './modalPaymentHistory';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../store/user';

interface ModalDetailLeadProps {
  open: boolean;
  showDetail?: boolean;
  mode: string;
  color: string;
  leadId: string;
  onClose: () => void;
  newStatus?: string;
}
const ModalDetailLead = ({ open, onClose, mode, color, showDetail = true, leadId, newStatus }: ModalDetailLeadProps) => {
  const [activeItem, setActiveItem] = useState<string>(showDetail ? 'details' : 'commentsAndFollowUpDate');
  const [hasPermission, setHasPermission] = useState(false);
  const dispatch = useDispatch();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: activeItem === 'communicationHistory' ? '1000px' : '800px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '90%',
    },
  };

  const handleChangeStatus = async () => {
    if (!showDetail) {
      if (newStatus) {
        const leadRepository = new LeadRepository();
        await leadRepository.changeStatus(leadId, newStatus);
        dispatch(LoadingFtn(true));
      }
    }
  };

  const getUserDetails = () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    setActiveItem(showDetail ? 'details' : 'commentsAndFollowUpDate');
  }, [showDetail]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={[style]} height={'65vh'} className="content">
        {showDetail && (
          <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
            <Box
              onClick={() => setActiveItem('details')}
              border={`2px solid ${activeItem === 'details' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
              borderRadius={'10px'}
              bgcolor={
                mode === 'dark'
                  ? activeItem === 'details'
                    ? color || globalColors.blue
                    : globalColors.blackLight
                  : activeItem === 'details'
                  ? color || globalColors.blue
                  : globalColors.white
              }
              color={mode === 'dark' ? globalColors.white : activeItem === 'details' ? globalColors.white : color || globalColors.black}
              paddingLeft={'10px'}
              sx={{ cursor: 'pointer' }}
              paddingRight={'10px'}
              paddingTop={'2px'}
              paddingBottom={'2px'}
            >
              Details
            </Box>

            <Box
              onClick={() => setActiveItem('commentsAndFollowUpDate')}
              borderRadius={'10px'}
              border={`2px solid ${activeItem === 'commentsAndFollowUpDate' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
              bgcolor={
                mode === 'dark'
                  ? activeItem === 'commentsAndFollowUpDate'
                    ? color || globalColors.blue
                    : globalColors.blackLight
                  : activeItem === 'commentsAndFollowUpDate'
                  ? color || globalColors.blue
                  : globalColors.white
              }
              color={
                mode === 'dark' ? globalColors.white : activeItem === 'commentsAndFollowUpDate' ? globalColors.white : color || globalColors.black
              }
              paddingLeft={'10px'}
              sx={{ cursor: 'pointer' }}
              paddingRight={'10px'}
              paddingTop={'2px'}
              paddingBottom={'2px'}
            >
              Comments & Follow Up
            </Box>
            <Box
              onClick={() => setActiveItem('communicationHistory')}
              borderRadius={'10px'}
              border={`2px solid ${activeItem === 'communicationHistory' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
              bgcolor={
                mode === 'dark'
                  ? activeItem === 'communicationHistory'
                    ? color || globalColors.blue
                    : globalColors.blackLight
                  : activeItem === 'communicationHistory'
                  ? color || globalColors.blue
                  : globalColors.white
              }
              color={mode === 'dark' ? globalColors.white : activeItem === 'communicationHistory' ? globalColors.white : color || globalColors.black}
              paddingLeft={'10px'}
              sx={{ cursor: 'pointer' }}
              paddingRight={'10px'}
              paddingTop={'2px'}
              paddingBottom={'2px'}
            >
              Communication History
            </Box>
            {(
              <Box
                onClick={() => setActiveItem('paymentHistory')}
                borderRadius={'10px'}
                border={`2px solid ${activeItem === 'paymentHistory' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                bgcolor={
                  mode === 'dark'
                    ? activeItem === 'paymentHistory'
                      ? color || globalColors.blue
                      : globalColors.blackLight
                    : activeItem === 'paymentHistory'
                    ? color || globalColors.blue
                    : globalColors.white
                }
                color={mode === 'dark' ? globalColors.white : activeItem === 'paymentHistory' ? globalColors.white : color || globalColors.black}
                paddingLeft={'10px'}
                sx={{ cursor: 'pointer' }}
                paddingRight={'10px'}
                paddingTop={'2px'}
                paddingBottom={'2px'}
              >
                Payment History
              </Box>
            )}
          </Box>
        )}
        {!showDetail &&
          (newStatus !== 'Converted' ? (
            <Typography
              sx={{
                color: color || globalColors.blue,
                textAlign: 'center',
                fontSize: '14px',
                display: 'flex',
                marginTop: '20px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Please add a follow up, comment and contact method to successfully change the status.
            </Typography>
          ) : (
            <Typography
              sx={{
                color: color || globalColors.blue,
                textAlign: 'center',
                fontSize: '14px',
                display: 'flex',
                marginTop: '20px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Please add the conversion details, payment proof, a follow up, comment, contact method to successfully send a conversion request to the
              admin.
            </Typography>
          ))}
        {activeItem === 'details' && showDetail ? (
          <LeadsDetails id={leadId} mode={mode} color={color} />
        ) : activeItem === 'commentsAndFollowUpDate' ? (
          newStatus !== 'Converted' ? (
            <ViewAllComments leadId={leadId} mode={mode} color={color} changeStatus={handleChangeStatus} />
          ) : (
            <ViewAllCommentsAndConvert leadId={leadId} mode={mode} color={color} changeStatus={handleChangeStatus} onClose={onClose} />
          )
        ) : activeItem === 'communicationHistory' ? (
          <ModalCommunicate mode={mode} color={color} id={leadId} />
        ) : activeItem === 'paymentHistory' ? (
          <ModalPaymentHistory mode={mode} color={color} id={leadId} />
        ) : null}
      </Box>
    </Modal>
  );
};

export default ModalDetailLead;
