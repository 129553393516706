import React, { useState, useEffect, useRef } from 'react';
import { drawerWidth } from '../../../../../utils/constants/drawerWidth';
import { Box, Typography, FormGroup, InputLabel, Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Sidebar from '../../../sidebar';
import { globalColors } from '../../../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../../store/selector';
// import { Button } from '../../components/atoms';
import { styled } from '@mui/material';
import { FormEmployeeStyle, IconInputStyle, InputStyle } from './formEmployee.style';
import PhoneIcon from './PhoneIcon';
import EmailIcon from './emailIcon';
import ReportCard from './reportCard';
import IncomeIcon from './incomeIcon';
import EmployeeComponent from './employeeComponent';
import { useNavigate, useParams } from 'react-router-dom';
import CommissionRepository from '../../../../../utils/api/repositories/commissionRepository';
import ModalReport from './modalReport';
import { setAddNew } from '../../../../../store/routes';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import { FieldInput, Toast, Button, FieldInputTextarea } from '../../../../atoms';
import Dropdown from '../../../../atoms/dropdown';
import { formatRole } from '../../../../../utils/helpers';
import * as Mui from '@mui/material';
import ModalViewNotes from './modalViewNotes';

export interface FormValues {
  name: string;
  role: string;
  commissionPercentage: number;
  commissionPriority: string;
  email: string;
  phone: string;
  notes: string;
}

export interface FormValuesErrors {
  name: string;
  role: string;
  commissionPercentage: string;
  commissionPriority: string;
  email: string;
  phone: string;
  notes: string;
}

const PrintReport = () => {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    role: '',
    commissionPercentage: 0,
    commissionPriority: '',
    email: '',
    phone: '',
    notes: '',
  });
  const [checked, setChecked] = useState(false);
  const { id: commissionId } = useParams();
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(true);

  const handleOpenModalReport = () => setOpenReportModal(false);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('formValues', formValues);
  };

  const fetch = async () => {
    try {
      const objectId = commissionId ? commissionId : '';
      if (objectId !== '') {
        const repository = new UserRepository();
        const employee = await repository.getObjectById(objectId);

        if (employee) {
          setFormValues({
            name: `${employee.get('firstName')} ${employee.get('lastName')}`,
            role: formatRole(employee.get('role')),
            commissionPercentage: employee.get('commissionPercentage') ?? 0,
            commissionPriority: employee.get('commissionPriority') ?? 'None',
            email: employee.get('email') ?? '',
            phone: employee.get('phone') ?? '',
            notes: '',
          });
        } else {
          Toast('Error fetching the employee details. Please try again.', 'error');
        }
      } else {
        Toast('Error fetching user details. Please try again.', 'error');
        setTimeout(() => {
          dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
          navigate('/performance/company');
        }, 1000);
      }
    } catch (error: any) {
      console.error('Error fetching posts:', error.message);
    }
  };

  const handleAddNote = async () => {
    const objectId = commissionId ? commissionId : '';
    if (objectId !== '') {
      if (formikRef.current) {
        const note = formikRef.current?.values.notes;
        const userRepository = new UserRepository();
        const currentUser = userRepository.getCurrentUser();
        if (currentUser) {
          const newNote = {
            note: note,
            createdBy: `${currentUser.get('firstName')} ${currentUser.get('lastName')}`,
            createdAt: new Date().toString(),
          };
          const updatedUser = await userRepository.addNoteForUser(newNote, objectId);
          if (updatedUser) {
            setNotes((prevNotes) => [newNote, ...prevNotes]);
            formikRef.current?.setFieldValue('notes', '');
            Toast('Note added successfully.', 'success');
          } else {
            Toast('Note could not be added successfully. Please try again.', 'error');
          }
        } else {
          Toast('Note could not be added successfully. Please try again.', 'error');
        }
      }
    } else {
      Toast('Error fetching user details. Please try again.', 'error');
      setTimeout(() => {
        dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
        navigate('/performance/company');
      }, 1000);
    }
  };

  const fetchNotes = async () => {
    try {
      const objectId = commissionId ? commissionId : '';
      if (objectId !== '') {
        setLoadingNotes(true);
        const repository = new UserRepository();
        const userNotes = await repository.getNotesForUser(objectId);
        if (userNotes) {
          const reversedNotes = [...userNotes].reverse();
          setNotes(reversedNotes);
          setLoadingNotes(false);
        } else {
          Toast('Error fetching the employee notes. Please try again.', 'error');
          setLoadingNotes(false);
        }
      } else {
        Toast('Error fetching user details. Please try again.', 'error');
        setTimeout(() => {
          dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
          navigate('/performance/company');
        }, 1000);
      }
    } catch (error: any) {
      console.error('Error fetching posts:', error.message);
    }
  };

  useEffect(() => {
    fetch();
    fetchNotes();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Performance / Member'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
            <Box marginBottom={'10px'}></Box>
            {/* Lead Assignment Container */}
            <AssignmentContainer
              bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
              padding={'20px'}
              borderRadius={'16px'}
              color={globalColors.white}
              fontSize={'12px'}
              boxShadow={'#171717'}
              marginTop={'1rem'}
            >
              <Formik
                enableReinitialize
                innerRef={formikRef}
                initialValues={{
                  name: formValues.name,
                  role: formValues.role,
                  commissionPercentage: formValues.commissionPercentage,
                  commissionPriority: formValues.commissionPriority,
                  email: formValues.email,
                  phone: formValues.phone,
                  notes: formValues.notes,
                }}
                validate={(values) => {
                  const errors = {} as FormValuesErrors;

                  if (!values.commissionPercentage && values.commissionPercentage !== 0) {
                    errors.commissionPercentage = 'Commission percentage is required';
                  }
                  if (values.commissionPercentage > 100 || values.commissionPercentage < 0) {
                    errors.commissionPercentage = 'Commission percentage must be a value between 0 and 100';
                  }
                  if (!values.commissionPriority || values.commissionPriority == 'Select') {
                    errors.commissionPriority = 'Commission priority is required';
                  }

                  return errors;
                }}
                onSubmit={async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
                  setIsFormSubmitting(true);
                  // const userId = commissionId ? commissionId : '';
                  // if (userId) {
                  //   const commissionRule = {
                  //     priority: values.commissionPriority,
                  //     percentage: values.commissionPercentage,
                  //   };
                  //   const userRepository = new UserRepository();
                  //   const updatedUser = await userRepository.updateUserCommissionByObjectId(userId, commissionRule);
                  //   if (updatedUser) {
                  //     Toast('Employee commission details updated successfully.', 'success');
                  //     setTimeout(() => {
                  //       setIsFormSubmitting(false);
                  //       dispatch(setAddNew({ addNew: false, path: '' }));
                  //       navigate('/settings/commission');
                  //     }, 1000);
                  //   } else {
                  //     Toast('Error updating the employee details. Please try again.', 'error');
                  //     setIsFormSubmitting(false);
                  //   }
                  // } else {
                  //   Toast('Error updating the employee details. Please try again.', 'error');
                  //   setTimeout(() => {
                  //     setIsFormSubmitting(false);
                  //     dispatch(setAddNew({ addNew: false, path: '' }));
                  //     navigate('/settings/commission');
                  //   }, 1000);
                  // }
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form>
                    <Box
                      borderRadius={'20px'}
                      marginBottom={'2px'}
                      bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                      padding={{ xs: '0px', md: '20px' }}
                    >
                      <FormEmployeeStyle>
                        <Grid container display={'flex'} justifyContent={'space-between'}>
                          <Grid item md={6} xs={12} pr={'10px'} pl={'10px'} mt={'0.8rem'} gap={'0.8rem'} display={'flex'} flexDirection={'column'}>
                            <FieldInput
                              label="Name"
                              id="name"
                              value={values.name}
                              mode={mode}
                              name={'name'}
                              placeholder={'Employee Name'}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Phone"
                              id="firstName"
                              value={values.phone}
                              mode={mode}
                              name={'phone'}
                              placeholder={'Phone'}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Email"
                              id="email"
                              value={values.email}
                              mode={mode}
                              name={'email'}
                              placeholder={'Email'}
                              type="text"
                              disabled
                            />
                          </Grid>

                          <Grid item md={6} xs={12} pr={'10px'} pl={'10px'} mt={'0.8rem'} gap={'0.8rem'} display={'flex'} flexDirection={'column'}>
                            <FieldInput
                              label="Role"
                              id="role"
                              value={values.role}
                              mode={mode}
                              name={'role'}
                              placeholder={'Role'}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Commission (%)"
                              id="commissionPercentage"
                              value={values.commissionPercentage.toString()}
                              mode={mode}
                              name={'commissionPercentage'}
                              placeholder={'Commission (%)'}
                              type="number"
                              disabled
                            />
                            <FieldInput
                              label="Commission Priority"
                              id="commissionPriority"
                              value={values.commissionPriority}
                              mode={mode}
                              name={'commissionPriority'}
                              placeholder={'Commission Priority'}
                              type="text"
                              disabled
                            />
                          </Grid>
                        </Grid>
                        {/* Button */}
                        <Box sx={{ width: '100%' }}>
                          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'end'}>
                            <Grid item md={12} xs={12} pr={'10px'} pl={'10px'} mt={'0.8rem'} display={'flex'} flexDirection={'column'}>
                              {/* <Box display="flex" alignItems={'end'} gap="10px">
                                <FieldInput
                                  label="Notes"
                                  id="notes"
                                  value={values.notes}
                                  mode={mode}
                                  name={'notes'}
                                  placeholder={'Add notes'}
                                  type="text"
                                />
                                <Mui.Button type="button" sx={{ color: color, whiteSpace: 'nowrap' }} onClick={handleAddNote}>
                                  Add
                                </Mui.Button>
                              </Box>
                              <Mui.Button onClick={() => {setNotesModalOpen(true)}} type="button" sx={{ color: color, whiteSpace: 'nowrap', padding: 0, width: '50px', marginTop: '10px' }}>
                                View All
                              </Mui.Button> */}
                              <FieldInputTextarea
                                label="Notes"
                                className="col"
                                placeholder="Add notes"
                                name="notes"
                                id="notes"
                                onChange={(e) => {setFieldValue('notes', e.target.value)}}
                                value={values.notes}
                              />
                              <Box display={'flex'} justifyContent={'space-between'} gap={'3rem'} paddingTop={'2rem'}>
                                <Mui.Button
                                  onClick={() => {
                                    setNotesModalOpen(true);
                                  }}
                                  type="button"
                                  sx={{ color: color || globalColors.blue, whiteSpace: 'nowrap', padding: 0, width: '50px', marginTop: '10px' }}
                                >
                                  View All
                                </Mui.Button>
                                <Button type="button" onClick={handleAddNote}>
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            {/* <Grid item md={6} xs={12} pr={'10px'} pl={'10px'} mt={{md: '0.8rem', xs: '50px'}} gap={'0.8rem'} display={'flex'} justifyContent={'end'}>
                              <Button
                                type="reset"
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                Delete Member
                              </Button>
                            </Grid> */}
                          </Grid>
                        </Box>
                      </FormEmployeeStyle>
                    </Box>
                  </Form>
                )}
              </Formik>
            </AssignmentContainer>

            {/* Report Cards */}
            <ReportCard />

            {/* Employee Cards */}
            <EmployeeComponent />
          </Box>
        </Box>
        <ModalReport open={openReportModal} onClose={handleOpenModalReport} mode={mode} color={color} id={'1'} data={[]} />
        <ModalViewNotes
          open={notesModalOpen}
          onClose={() => {
            setNotesModalOpen(false);
          }}
          mode={mode}
          color={color}
          notes={notes}
          loading={loadingNotes}
        />
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  height: '100%',
  width: '100%',
});

const ButtonStyle = styled('button')<any>(({ color, disabled, type }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  background: color !== 'default' && color !== '' ? color : type === 'reset' ? globalColors.blackLight : globalColors.blue,
  color: globalColors.white,
  width: '240px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default PrintReport;
