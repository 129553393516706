import { useDispatch, useSelector } from 'react-redux';
import { IFormDetailProject } from '../../../../store/projects';
import { ListProductsProps, ProductCategories } from '../../../../utils/types/products';
import { Typography, TableContainer, TableBody, TableRow, Table, TableHead, Paper, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { selectThemeMode } from '../../../../store/selector';
import { globalColors } from '../../../../utils/constants/color';
import ContentRow from './contentRow';

const ListHistory = () => {
  const dispatch = useDispatch();
  const mode = useSelector(selectThemeMode);

  const dataHistory = [
    {
      id: '1',
      date: 'DEC 5, 2023',
      invoiceNumber: '#INV001',
      propertyProject: 'Project A',
      amount: 1000,
      status: 'Received',
    },
    {
      id: '2',
      date: 'DEC 28, 2023',
      invoiceNumber: '#INV002',
      propertyProject: 'Project B',
      amount: 1500,
      status: 'Pending',
    },
  ];

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
          //  border: `1px solid ${mode === 'dark' && globalColors.blackLight}`,
          //  borderRadius: '10px',
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          paddingBottom: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          boxShadow: 'none',
        }}
      >
        {
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Date
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Invoice Number
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Property/Project
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Amount
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Status
                    </Heading>
                  </TableCell>
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataHistory.map((item) => (
                <ContentRow data={item} key={item?.id} />
              ))}
            </TableBody>
          </Table>
        }
      </TableContainer>
    </>
  );
};

const Heading = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '-0.02em',
  textAlign: 'left',
});

export default ListHistory;
