import React, { ChangeEvent, FormEvent } from 'react';
import { Input, Button, ButtonProps, Box } from '@mui/material';
import UploadImageIcon from './UploadImageIcon';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../store/selector';
import { globalColors } from '../../../utils/constants/color';

interface ImagePreviewProps {
  selectedImage: File | null;
  setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedImageName: React.Dispatch<React.SetStateAction<string | "">>;
}

const ImageUpload: React.FC<ImagePreviewProps> = ({selectedImage, setSelectedImage, setSelectedImageName}) => {
  // const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const mode = useSelector(selectThemeMode);
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedImage(file);

      const imageName = file.name;
      setSelectedImageName(imageName);
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);

      // Example: Send the formData using fetch
      fetch('your-upload-endpoint', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Input
          type="file"
          inputProps={{ accept: 'image/*,video/*' }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleImageChange(event)}
          style={{ display: 'none' }}
          id="image-upload"
        />
        <label htmlFor="image-upload">
          <Button
            sx={{
              marginRight: '10px',
              padding: 0,
              minWidth: 0,
              border: 'none',
              '&:hover': {
                border: 'none', // Adjust the color as needed
              },
            }}
            component="span"
            variant="outlined"
            color="primary"
            {...({ component: 'span', variant: 'outlined', color: 'primary' } as ButtonProps)}
          >
            <UploadImageIcon />
          </Button>

          <span
            style={{
              color: mode === "dark" ? globalColors.white : globalColors.black,
              fontFamily: 'Poppins',
              fontSize: '13px',
              fontWeight: '400',
              lineHeight: '32px',
              letterSpacing: '0px',
              textAlign: 'left',
            }}
          >
            Photo/Video
          </span>
        </label>
        {/* <Button type="submit" variant="contained" color="primary">
          Submit
        </Button> */}
      </form>
      {/* {selectedImage && (
        <div>
          <img src={URL.createObjectURL(selectedImage)} alt="Selected Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
        </div>
      )} */}
    </Box>
  );
};

export default ImageUpload;