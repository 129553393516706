import { useState } from 'react';
import { BsEyeFill } from 'react-icons/bs';
import { FaDumpster, FaDumpsterFire, FaHeart } from 'react-icons/fa';
import { FaDeleteLeft, FaFlag } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { ICPen } from '../../../assets/Icons';
import { useModalViewProducts } from '../../../hooks';
import useModalDeleteProduct from '../../../hooks/useModalDeleteProduct';
// import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProject } from '../../../store/projects';
import { globalColors } from '../../../utils/constants/color';
import { ColorStatusProduct, ProductCategories, ProductItemProps } from '../../../utils/types/products';
import { ButtonIconCircle } from '../../atoms';
import { Gallery } from '..';
import ModalDeleteProduct from '../products/modalDeleteProduct';
import ModalViewProduct from '../products/modalViewProduct';
import { CardProductItemStyle } from './cardProjectItemStyle';
import { Checkbox } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import Image404 from '../../../assets/Images/img-404.jpeg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../store/routes';

const CardProjectItem = (props: ProductItemProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataProject = props.data as IFormDetailProject;

  const isProperty = props.category === ProductCategories.Property;
  // const isProject = props.category === ProductCategories.Project;
  let styleStatusProperty = props.data.status ? ColorStatusProduct[props.data.status] : 'colorGray';

  // Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (props?.data?.images?.length > 0) {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  // * Modal Delete

  return (
    <CardProductItemStyle className={props.className} mode={props.mode}>
      <div className="cover-wrapper">
        {props?.data?.cover?.preview ? (
          <img onClick={handleOpen} className="cover-image-product" src={props?.data?.cover?.preview} alt="project name" />
        ) : (
          <img className="cover-image-product" src={Image404} alt="project name" />
        )}
        <div className="btn-action-wrapper"></div>
        <div
          style={{
            justifyContent: 'space-between',
          }}
          className="count-photo-wrapper"
          onClick={() => {
            if (props?.data?.images?.length > 0) {
              if (props.active) {
                handleOpen();
              }
            }
          }}
        >
          {/* View Photos */}
          {props?.data?.images?.length > 0 ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CameraAltOutlinedIcon sx={{ color: props.mode === 'dark' ? globalColors.white : globalColors.black }} />
              <p style={{ color: props.mode === 'dark' ? globalColors.white : globalColors.black }}>
                View Photos ({props?.data?.images?.length ?? 0})
              </p>
            </div>
          ) : (
            <div></div>
          )}

          {/* Hot Deals */}
          {/* {dataProject?.priority === 'hot' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: '600',
                  fontSize: '10px',
                  lineHeight: '15px',
                  color: 'rgba(247, 80, 37, 1)',
                }}
              >
                Hot Deal
              </Typography>
              <HotDealsIcon />
            </div>
          )} */}
        </div>
      </div>
      <div
        className="description-wrapper"
        onClick={() => {
          dispatch(setAddNew({addNew: true, path: '/inventory'}))
          navigate(`/inventory/${props.data.id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <h3 className={`title-project ${isProperty && 'property'}`}>
          {/* Name */}
          <span>{props?.data?.name ? props?.data?.name : 'Title'}</span>
        </h3>

        {/* consist of */}
        {props.category === ProductCategories.Project && (
          <p style={{ marginTop: '10px' }} className="detail">
            Inventory: {dataProject.components && dataProject.components.length > 0 ? `${dataProject.components.length}` : '-'}
          </p>
        )}

        {/* type */}
        {props.category === ProductCategories.Project && (
          <p style={{ marginTop: '10px' }} className="detail">
            Type: {dataProject.type ? dataProject.type : '-'}
          </p>
        )}

        {/* Square footage */}
        <p style={{ marginTop: '10px' }} className="detail">
          {`Area: ${dataProject.area ? dataProject.area : '-'} ${dataProject.area ? dataProject.areaUnit : ''}`}
        </p>

        {/* Interested */}
        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <span className="detail">Interested people</span>
          <Typography
            sx={{ display: 'flex', color: `${globalColors.white}` }}
            width={'10px'}
            fontSize={'12px'}
            paddingLeft={'0.2rem'}
            flexGrow={1}
            textAlign="left"
          >
            {dummyData.length > 0
              ? dummyData.length === 1
                ? dummyData[0]
                : dummyData.map((e: any, i: any) => {
                    return <AvatarContainer sx={{ marginLeft: i === 0 ? '' : '-8px !important' }}>{e.charAt(0).toUpperCase()}</AvatarContainer>;
                  })
              : ''}
          </Typography>
        </div> */}

        {/* Details */}
        <p className={`detail status ${styleStatusProperty}`}>{props?.data?.status ? props?.data?.status : 'Status'}</p>
      </div>
      <Gallery open={open} onClose={handleClose} images={props?.data?.images} />
    </CardProductItemStyle>
  );
};

export default CardProjectItem;
