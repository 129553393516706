import { Box, Modal, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { globalColors } from '../../../utils/constants/color';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../atoms/dropdown';
import { FieldInput } from '../../atoms';
import ModalDeleteConfirm from './modalDeleteConfirm';
import Dropdowndata from '../../atoms/dropdown2';
import UserRepository from '../../../utils/api/repositories/userRepository';
import SalesExecutiveRepository from '../../../utils/api/repositories/salesExecutiveRepository';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import { LoadingFtn, BulkEditFtn } from '../../../store/slices';
import { selectLoading } from '../../../store/selector';
import { UserRole } from '../../../store/user';
import { BsCameraVideo, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import { MdPeopleOutline } from 'react-icons/md';

interface ModalBulkLeadProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  rowCheckboxState: { [key: string]: boolean };
}
interface UpdateValues {
  assignedTo: string;
  category: string;
  status: string;
  note: string;
  date: string;
  priority: string;
}
type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
  role: string;
};
const ModalBulkEdit = ({ open, onClose, mode, color, rowCheckboxState }: ModalBulkLeadProps) => {
  const dispatch = useDispatch();
  const [managersandSalesExecutive, setManagersandSalesExecutive] = useState<ManagerSalesExecutive[]>([]);
  const [assignedTo, setassignedTo] = useState<string>('auto');
  const [activeItem, setActiveItem] = useState<string>('safe');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
  const leadsloader = useSelector(selectLoading);
  const [selectedMethodContact, setSelectedMethodContact] = useState('');
  const [showAssigningOptions, setShowAssigningOptions] = useState(true);

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser?.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i]?.get('firstName'),
              lastName: managersAndSalesExecutives[i]?.get('lastName'),
              user: managersAndSalesExecutives[i],
              role: managersAndSalesExecutives[i]?.get('user')?.get('role'),
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i]?.get('user').id,
              firstName: salesExecutives[i]?.get('user')?.get('firstName'),
              lastName: salesExecutives[i]?.get('user')?.get('lastName'),
              user: salesExecutives[i],
              role: salesExecutives[i]?.get('user')?.get('role'),
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser?.get('firstName'),
          lastName: currentUser?.get('lastName'),
          user: currentUser,
          role: currentUser?.get('role'),
        };
        setManagersandSalesExecutive([userData]);
        setShowAssigningOptions(false);
      }
    }
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    height: '80vh',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '525px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 5,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };
  const handleUpdateMultipleLeads = async (values: UpdateValues) => {
    const { assignedTo, priority, status, note, date } = values;
    if (assignedTo !== 'Unassigned' && assignedTo !== '') {
      const user = managersandSalesExecutive.filter((val) => val?.id === assignedTo);
      const repository = new LeadRepository();
      const selectedLeadIds = Object.keys(rowCheckboxState).filter((leadId) => rowCheckboxState[leadId] === true);
      await repository.updateMultipleLeads(selectedLeadIds, priority, status, note, date, selectedMethodContact, user[0]);
    } else if (assignedTo === 'Unassigned') {
      const repository = new LeadRepository();
      const selectedLeadIds = Object.keys(rowCheckboxState).filter((leadId) => rowCheckboxState[leadId] === true);
      await repository.updateMultipleLeads(selectedLeadIds, priority, status, note, date, selectedMethodContact, 'Unassigned');
    } else {
      const repository = new LeadRepository();
      const selectedLeadIds = Object.keys(rowCheckboxState).filter((leadId) => rowCheckboxState[leadId] === true);
      await repository.updateMultipleLeads(selectedLeadIds, priority, status, note, date, selectedMethodContact, null);
    }
    dispatch(BulkEditFtn(false));
    dispatch(LoadingFtn(true));
  };
  const handleDeleteLeads = async () => {
    const repository = new LeadRepository();
    const selectedLeadIds = Object.keys(rowCheckboxState).filter((leadId) => rowCheckboxState[leadId] === true);
    await repository.deleteMultipleLeads(selectedLeadIds);
    dispatch(BulkEditFtn(false));
    dispatch(LoadingFtn(true));
  };
  useEffect(() => {
    getManagersandSalesExecutive();
  }, []);

  return (
    <Modal open={open} onClose={onClose} className="content">
      <Box sx={style} className="content scroll" position={'relative'}>
        <Box display={'flex'} flexWrap={'wrap'} gap={'10px'} marginBottom={'30px'}>
          <Box
            onClick={() => setActiveItem('safe')}
            border={`2px solid ${activeItem === 'safe' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
            borderRadius={'10px'}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'safe'
                  ? color || globalColors.blue
                  : globalColors.blackLight
                : activeItem === 'safe'
                ? color || globalColors.blue
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'safe' ? globalColors.white : color || globalColors.black}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Safe
          </Box>
          <Box
            onClick={() => setActiveItem('dangerous')}
            borderRadius={'10px'}
            border={`2px solid ${globalColors.red}`}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'dangerous'
                  ? globalColors.red
                  : globalColors.blackLight
                : activeItem === 'dangerous'
                ? globalColors.red
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'dangerous' ? globalColors.white : globalColors.red}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Dangerous
          </Box>
        </Box>

        {activeItem === 'safe' ? (
          <Formik
            initialValues={{
              assignedTo: '',
              category: '',
              status: '',
              note: '',
              date: '',
              priority: '',
            }}
            validate={(values) => {
              const errors = {} as UpdateValues;
              if (values.note.trim() !== '') {
                if (values.date === '' || selectedMethodContact === '') {
                  errors.date =
                    'Please provide all three: next follow up date, a comment and a method of contact, to be able to successfully add a comment.';
                }
              }

              return errors;
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              await handleUpdateMultipleLeads(values);

              resetForm({
                values: {
                  assignedTo: '',
                  priority: '',
                  category: '',
                  date: '',
                  status: '',
                  note: '',
                },
              });
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
              <Form>
                <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={'2px'} width={'100%'} position={'relative'}>
                    <Dropdowndata
                      label="Assigned To"
                      id="assignedTo"
                      name="assignedTo"
                      optional={false}
                      disabled={assignedTo === 'auto'}
                      mode={mode}
                      onChange={(e) => {
                        setFieldValue('assignedTo', e.target.value);
                      }}
                      values={[
                        { label: 'Select', value: '' },
                        { label: 'Unassigned', value: 'Unassigned' },
                        ...managersandSalesExecutive.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.id,
                        })),
                      ]}
                      defaultValue=""
                    />
                    <Box display={'flex'} gap={'10px'} position={'absolute'} right={'0'}>
                      <Box
                        onClick={() => setassignedTo('auto')}
                        border={`2px solid ${assignedTo === 'auto' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                        borderRadius={'10px'}
                        bgcolor={
                          mode === 'dark'
                            ? assignedTo !== 'auto'
                              ? globalColors.black
                              : color || globalColors.blue
                            : assignedTo === 'auto'
                            ? color
                            : globalColors.lightgray
                        }
                        color={
                          mode === 'dark'
                            ? assignedTo === 'auto'
                              ? globalColors.white
                              : color !== 'default' && color !== ''
                              ? color
                              : globalColors.white || globalColors.white
                            : assignedTo === 'auto'
                            ? globalColors.white
                            : color !== 'default' && color !== ''
                            ? color
                            : globalColors.black || globalColors.black
                        }
                        paddingLeft={'5px'}
                        sx={{ cursor: 'pointer' }}
                        paddingRight={'5px'}
                        paddingTop={'2px'}
                        paddingBottom={'2px'}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        <Typography fontSize={'10px'}>Cloud AI</Typography>
                      </Box>
                      {showAssigningOptions && (
                        <Box
                          onClick={() => setassignedTo('manual')}
                          borderRadius={'10px'}
                          border={`2px solid ${assignedTo === 'manual' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                          bgcolor={
                            mode === 'dark'
                              ? assignedTo !== 'manual'
                                ? globalColors.black
                                : color || globalColors.blue
                              : assignedTo === 'manual'
                              ? color || globalColors.blue
                              : globalColors.lightgray
                          }
                          color={
                            mode === 'dark'
                              ? assignedTo === 'manual'
                                ? globalColors.white
                                : color !== 'default' && color !== ''
                                ? color
                                : globalColors.white || globalColors.white
                              : assignedTo === 'manual'
                              ? globalColors.white
                              : color !== 'default' && color !== ''
                              ? color
                              : globalColors.black || globalColors.black
                          }
                          paddingLeft={'5px'}
                          sx={{ cursor: 'pointer' }}
                          paddingRight={'5px'}
                          paddingTop={'2px'}
                          paddingBottom={'2px'}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          <Typography fontSize={'10px'}>Manual</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Dropdown
                    label="Priority"
                    mode={mode}
                    id="priority"
                    name="priority"
                    values={['Select', 'Very Hot', 'Hot', 'Moderate', 'Cold']}
                    defaultValue="Select"
                  />
                  <Dropdown
                    label="Status"
                    mode={mode}
                    id="status"
                    name="status"
                    values={['Select', 'New', 'Contacted', 'Qualified', 'Converted', 'Lost']}
                    defaultValue="Select"
                  />
                  <FieldInput label="Next Follow Up" mode={mode} id="date" name="date" placeholder="Add a follow-up date" type="date" />
                  <FieldInput
                    label="Comments"
                    mode={mode}
                    id="note"
                    name="note"
                    placeholder="Type your note here"
                    type="text"
                    error={errors.date ? errors.date : false}
                  />
                  <Box display={'flex'} flexDirection={'column'}>
                    <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Method of Contact</Typography>
                    <Box display={'flex'} flexDirection={'row'} gap={'5px'} marginTop={'1rem'}>
                      <Box
                        onClick={() => {
                          if (selectedMethodContact === 'Call') {
                            setSelectedMethodContact('');
                          } else {
                            setSelectedMethodContact('Call');
                          }
                        }}
                        sx={{
                          cursor: 'pointer',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                          backgroundColor: selectedMethodContact === 'Call' ? color : 'transparent',
                        }}
                      >
                        <BsTelephone size={'1rem'} color={selectedMethodContact === 'Call' ? globalColors.white : color || globalColors.blue} />
                      </Box>
                      <Box
                        onClick={() => {
                          if (selectedMethodContact === 'Email') {
                            setSelectedMethodContact('');
                          } else {
                            setSelectedMethodContact('Email');
                          }
                        }}
                        sx={{
                          cursor: 'pointer',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                          backgroundColor: selectedMethodContact === 'Email' ? color : 'transparent',
                        }}
                      >
                        <BiEnvelope size={'1rem'} color={selectedMethodContact === 'Email' ? globalColors.white : color || globalColors.blue} />
                      </Box>
                      <Box
                        onClick={() => {
                          if (selectedMethodContact === 'Meeting') {
                            setSelectedMethodContact('');
                          } else {
                            setSelectedMethodContact('Meeting');
                          }
                        }}
                        sx={{
                          cursor: 'pointer',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                          backgroundColor: selectedMethodContact === 'Meeting' ? color : 'transparent',
                        }}
                      >
                        <BsCameraVideo size={'1rem'} color={selectedMethodContact === 'Meeting' ? globalColors.white : color || globalColors.blue} />
                      </Box>
                      <Box
                        onClick={() => {
                          if (selectedMethodContact === 'Physical Meeting') {
                            setSelectedMethodContact('');
                          } else {
                            setSelectedMethodContact('Physical Meeting');
                          }
                        }}
                        sx={{
                          cursor: 'pointer',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 50,
                          backgroundColor: selectedMethodContact === 'Physical Meeting' ? color : 'transparent',
                        }}
                      >
                        <MdPeopleOutline
                          size={'1rem'}
                          color={selectedMethodContact === 'Physical Meeting' ? globalColors.white : color || globalColors.blue}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
                    <Button
                      sx={{
                        border: `1px solid ${globalColors.red}`,
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: globalColors.red,
                        },
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        border: `1px solid ${color || globalColors.blue}`,
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: color || globalColors.blue,
                        },
                      }}
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Done'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'15px'}>
            <Button
              sx={{
                border: `1px solid ${globalColors.red}`,
                color: globalColors.white,
                width: '100%',
                bgcolor: globalColors.red,
                borderRadius: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                '&:hover': {
                  backgroundColor: globalColors.red,
                },
              }}
              onClick={() => {
                setShowDeleteConfirmModal(true);
              }}
            >
              Delete All
            </Button>
            <ModalDeleteConfirm
              open={showDeleteConfirmModal}
              onClose={() => {
                setShowDeleteConfirmModal(false);
              }}
              mode={mode}
              color={color}
              onDelete={() => {
                handleDeleteLeads();
              }}
              action={{ permissionName: 'Leads', action: 'Delete' }}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalBulkEdit;
