import { Box, Grid, Typography, styled } from '@mui/material';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { useSelector } from 'react-redux';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import CustomizedProgressBars from './progressBar';
import ContentTable from './contentTable';
import CustomProgressBar from './customProgessBar';
import { useEffect, useState } from 'react';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import UserModel from '../../../../utils/api/models/userModel';
import { Button } from '../../../atoms';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import { setAddNew } from '../../../../store/routes';
import { UserRole } from '../../../../store/user';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';

export interface FormValues {
  name: string;
  employeePosition: string;
  employeeType: string;
  employeeAccountDetails: string;
  email: string;
  dateOfHire: string;
  totalSalary: string;
  employeeSalaryDetails: string;
}

export interface FormValuesErrors {
  name: string;
  employeePosition: string;
  employeeType: string;
  employeeAccountDetails: string;
  email: string;
  dateOfHire: string;
  totalSalary: string;
  employeeSalaryDetails: string;
}

const EmployeePayroll = () => {
  const [counts, setCounts] = useState({ existing: 0, total: 0 });
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchMembers = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      setHasPermission(currentUser.get('role') === UserRole.admin);
      const organizationRepository = new OrganizationRepository();
      const memberCount = await organizationRepository.getSignedUpMembers();
      if (memberCount) {
        setCounts(memberCount);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Members'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : hasPermission ? (
          <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
            <Box marginBottom={'10px'}></Box>
            {/* Top Container */}
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {counts.existing < counts.total ? (
                <Button
                  disabled={
                    counts.existing == -1 || counts.total == -1 || (counts.existing !== -1 && counts.total !== -1 && counts.existing >= counts.total)
                  }
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: '/hr/members' }));
                    navigate('/hr/members/addMember');
                  }}
                  type="button"
                >
                  Add New Member
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    navigate('/settings/payments/changePlan');
                  }}
                  type="button"
                >
                  Upgrade Plan
                </Button>
              )}

              <Typography
                sx={{
                  marginTop: '12px',
                  marginBottom: '1rem',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '28px',
                  textAlign: 'left',
                  color: mode === 'dark' ? globalColors?.white : globalColors?.black,
                }}
              >
                <span style={{ color: color || globalColors.blue }}>{counts.existing === 0 ? '-' : counts.existing}</span>
                &nbsp;{`${counts.existing > 1 ? 'Members' : 'Member'}`}
              </Typography>
            </Box>

            <MembersContainer
              bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
              padding={'20px'}
              borderRadius={'16px'}
              color={globalColors.white}
              fontSize={'12px'}
              boxShadow={'#171717'}
              marginTop={'1rem'}
            >
              <ContentTable />
            </MembersContainer>
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

const MembersContainer = styled(Box)({
  height: '100%',
  width: '100%',
});

export default EmployeePayroll;
