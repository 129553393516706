import { Typography, TableContainer, TableBody, TableRow, Table, TableHead, Paper, styled, Box, Grid } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import { PermissionsInterface, formatRole, getPermissionsList } from '../../../../utils/helpers';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import IosSwitch from '../../IosSwitch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import GroupInput from '../../groupInput';
import { Button, FieldInput, Toast } from '../../../atoms';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../../store/routes';
import Dropdowndata from '../../../atoms/dropdown2';
import SalesExecutiveRepository from '../../../../utils/api/repositories/salesExecutiveRepository';
import NotFoundLottie from '../../../atoms/notFound';

const permissionsList = getPermissionsList();

interface CountsInterface {
  total: number;
  existing: number;
}

interface ValuesAddMember {
  managedBy: string;
}

type Manager = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

const ManageOrganization = () => {
  const formikRef = useRef<FormikProps<ValuesAddMember>>(null);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigation = useSelector(selectNavigationLayout);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [executives, setExecutives] = useState<Parse.Object[]>([]);
  const [formValues, setFormValues] = useState<ValuesAddMember>({
    managedBy: '',
  });

  const handleChangeDropdown = async (executiveId: string, managerUserId: string) => {
    const salesExecutiveRepository = new SalesExecutiveRepository();
    const savedSalesExecutive = await salesExecutiveRepository.changeManager(executiveId, managerUserId);
    if (savedSalesExecutive) {
      Toast('The assigned manager has been changed for this executive successfully.', 'success');
    }
    else {
      Toast('The assigned manager could not be changed for this executive successfully. Please try again.', 'error');
    }
  }

  useEffect(() => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const getManagers = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        const userRole = currentUser.get('role');
        if (userRole === UserRole.admin) {
          const userRepository = new UserRepository();
          const existingManagers = await userRepository.getAllManagers();

          if (existingManagers) {
            const users: Manager[] = [];
            for (let i = 0; i < existingManagers.length; i++) {
              const userData = {
                id: existingManagers[i].id,
                firstName: existingManagers[i].get('firstName'),
                lastName: existingManagers[i].get('lastName'),
                user: existingManagers[i],
              };
              users.push(userData);
            }
            setManagers([...users]);
          }
        }
      }
    };
    const getExecutives = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        const userRole = currentUser.get('role');
        if (userRole === UserRole.admin) {
          const salesExecutiveRepository = new SalesExecutiveRepository();
          const salesExecutives = await salesExecutiveRepository.getAllExecutivesForOrganization(currentUser.get('organization'));

          if (salesExecutives) {
            setExecutives(salesExecutives);
          }
        }
      }
      setLoading(false);
    };

    getManagers();
    getExecutives();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Company / Manage'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          <Box width={'100%'} padding={3} display={'flex'} flexDirection={'column'} gap={'2rem'}>
            {executives.length > 0 ? (
              executives.map((executive) => {
                return (
                  <Grid
                    container
                    sx={{
                      backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                      padding: '2rem',
                      borderRadius: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    rowGap={'1rem'}
                  >
                    <Grid xs={12} sm={6} item display={'flex'} flexDirection={'column'}>
                      <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        {executive.get('user').get('firstName')} {executive.get('user').get('lastName')}{' '}
                        <span style={{ color: globalColors.gray }}>({executive.get('user').get('email')})</span>
                      </Typography>
                      <Typography fontSize={'12px'} color={color}>
                        {formatRole(executive.get('user').get('role'))}
                      </Typography>
                    </Grid>
                    <Grid xs={12} sm={6} item display={'flex'} justifyContent={{ xs: 'start', sm: 'end' }}>
                      <Box width={'100%'}>
                        <Formik
                          innerRef={formikRef}
                          enableReinitialize
                          initialValues={{
                            managedBy: executive.get('manager').get('user').id,
                          }}
                          validate={(values) => {
                            const errors = {} as ValuesAddMember;
                            if (!values.managedBy || values.managedBy === 'Select') {
                              errors.managedBy = 'Required';
                            }
                            return errors;
                          }}
                          onSubmit={async (values: ValuesAddMember, { setSubmitting, resetForm }: FormikHelpers<ValuesAddMember>) => {
                            try {
                            } catch (error) {
                              Toast('There was an error signing this member up. Please try again.', 'error');
                              console.log('ERROR SUBMITTING FORM:', error);
                            }
                          }}
                        >
                          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                            <Form>
                              <Box display={'flex'} gap={'1rem'} alignItems={'center'}>
                                <Dropdowndata
                                  label="Managed By"
                                  id="managedBy"
                                  name="managedBy"
                                  mode={mode}
                                  onChange={(e) => {handleChangeDropdown(executive.id, e.target.value)}}
                                  values={[
                                    { label: `Select`, value: '' },
                                    ...managers.map((user) => ({
                                      label: `${user.firstName} ${user.lastName}`,
                                      value: user.id,
                                    })),
                                  ]}
                                  defaultValue={executive.get('manager').get('user').id}
                                />
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Box width={'100%'} padding={3} display={'flex'} justifyContent={'center'}>
                <NotFoundLottie
                  text="Looks like you have not added any executive role members yet. Press the button below to add one right now."
                  showButton
                  buttonText="Add a Member"
                  navigateTo="/hr/members/addMember"
                />
              </Box>
            )}
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

export default ManageOrganization;
