import { Box, Checkbox, Grid, InputLabel, Modal, Stack, Typography, styled } from '@mui/material';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IAmenities, addAminitiesItem } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { Button, FieldInput, FieldInputTextarea, FieldInputUnit, FieldSelectOption, Toast } from '../../atoms';
import FieldInputFile from '../../atoms/fieldInputFile';
import { HeadingFormAuth } from '../../molecules';
import Dropdowndata from '../../atoms/dropdown2';
import { FieldCover } from '../../molecules/productDetail';
import { StatusProduct } from '../../../utils/types/products';
import UserModel from '../../../utils/api/models/userModel';
import ProjectComponentModel from '../../../utils/api/models/projectComponentModel';
import CustomFields, { CustomField } from '../../molecules/customFields';
import ProjectComponentRepository from '../../../utils/api/repositories/projectComponentRepository';
import ProjectRepository from '../../../utils/api/repositories/projectRepository';
import { formatDate, isLocked } from '../../../utils/helpers';
import Loader from '../../atoms/loader';
import Locked from '../../atoms/locked';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  // maxWidth: '525px',
  maxHeight: '90vh',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: '1.5rem',
  borderRadius: '20px',
  overflow: 'auto',

  '@media (max-width: 576px)': {
    width: '80%',
  },
};

export const areaOptions = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'Marlas',
    value: 'Marlas',
  },
  {
    label: 'Kanals',
    value: 'Kanals',
  },
  {
    label: 'Acres',
    value: 'Acres',
  },
  {
    label: 'Square Meters',
    value: 'Square Meters',
  },
  {
    label: 'Square Feet',
    value: 'Square Feet',
  },
  {
    label: 'Square Yards',
    value: 'Square Yards',
  },
];

interface ValuesAddComponent {
  area: string;
  areaUnit: string;
  contactName: string;
  contactPhone: string;
  contactSecondaryPhone: string;
  description: string;
  name: string;
  price: number;
  downPayment: number;
  purpose: string;
  sellingPrice: number;
  soldAt: string;
  soldBy: string;
  status: string;
}

interface ErrorsAddComponent {
  area: string;
  areaUnit: string;
  contactName: string;
  contactPhone: string;
  contactSecondaryPhone: string;
  description: string;
  name: string;
  price: string;
  downPayment: string;
  purpose: string;
  sellingPrice: string;
  soldAt: string;
  soldBy: string;
  status: string;
}

interface ModalAddComponent {
  open: boolean;
  onClose: () => void;
  mode: string;
  color: string;
  onSubmit: (component: ProjectComponentModel) => Promise<void>;
  organizationMembers: UserModel[];
  selectedComponent?: ProjectComponentModel | null;
}

const ModalAddComponent = ({ open, onClose, mode, color, onSubmit, organizationMembers, selectedComponent }: ModalAddComponent) => {
  const [selectedCover, setSelectedCover] = useState<{ file: File | null; preview: string }>({
    file: selectedComponent?.coverPicture?.file ?? null,
    preview: selectedComponent?.coverPicture?.preview ?? '',
  });
  const [customFields, setCustomFields] = useState<CustomField[]>(
    selectedComponent && selectedComponent.customFields && selectedComponent.customFields.length > 0 ? selectedComponent.customFields : []
  );
  const [useDownPayment, setUseDownPayment] = useState(selectedComponent?.useDownPayment ?? false);
  const [locked, setLocked] = useState(false);
  const [loading, setLoading] = useState(false);

  const onUnlock = async () => {
    if (selectedComponent) {
      const projectComponentRepository = new ProjectComponentRepository();
      const unlockedProjectComponent = await projectComponentRepository.unlock(selectedComponent.objectId ?? '');
      if (unlockedProjectComponent) {
        Toast('Project component unlocked successfully.', 'success');
        setLocked(false);
      }
    } else {
      Toast('Project component could not be unlocked successfully. Please try again.', 'error');
      onClose();
    }
  };

  const fetchStatus = async () => {
    setLoading(true);
    if (selectedComponent) {
      const projectComponentRepository = new ProjectComponentRepository();
      const projectComponent = await projectComponentRepository.getObjectByObjectId(selectedComponent.objectId ?? '');
      if (projectComponent) {
        setLocked(projectComponent.get('lockedFor') !== null && isLocked(projectComponent.get('lockedUntil')));
      }
    } else {
      Toast('Project component could not be fetched successfully. Please try again.', 'error');
      onClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    setSelectedCover({
      file: selectedComponent?.coverPicture?.file ?? null,
      preview: selectedComponent?.coverPicture?.preview ?? '',
    });

    setCustomFields(
      selectedComponent && selectedComponent.customFields && selectedComponent.customFields.length > 0 ? selectedComponent.customFields : []
    );

    setUseDownPayment(selectedComponent?.useDownPayment ?? false);

    if (selectedComponent) {
      fetchStatus();
    }
  }, [selectedComponent]);

  return (
    <Modal open={open} onClose={onClose} aria-nameledby="modal-add-template" aria-describedby="modal-request-add-template" className="content">
      <ModalAddComponentStyle>
        {loading ? (
          <Box sx={style} bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.white} className="content">
            <Loader />
          </Box>
        ) : locked ? (
          <Box sx={style} bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.white} className="content">
            <Locked
              text={selectedComponent ? `Looks like the project component you are trying to access is locked ${
                selectedComponent?.lockedUntil ? 'until ' + formatDate(selectedComponent?.lockedUntil, true) : 'temorarily'
              }${selectedComponent?.lockedFor ? `, for the lead ${selectedComponent?.lockedFor.get('firstName')} ${selectedComponent?.lockedFor.get('lastName')}` : ''}.` : undefined}
              onUnlock={onUnlock}
            />
          </Box>
        ) : (
          <Box sx={style} bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.white} className="content">
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '20px',
                fontWeight: 500,
                lineHeight: '30px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {selectedComponent ? 'Edit Project Inventory' : 'Add Project Inventory'}
            </Typography>
            <Box
              sx={{
                width: '100%',
                marginTop: '10px',
                borderBottom: `1px solid ${globalColors.background.gray}`,
              }}
            ></Box>
            <Formik
              enableReinitialize
              initialValues={{
                area: selectedComponent ? selectedComponent.area?.toString() ?? '' : '',
                areaUnit: selectedComponent ? selectedComponent.areaUnit?.toString() ?? '' : '',
                contactName: selectedComponent ? selectedComponent.contactName?.toString() ?? '' : '',
                contactPhone: selectedComponent ? selectedComponent.contactPhone?.toString() ?? '' : '',
                contactSecondaryPhone: selectedComponent ? selectedComponent.contactSecondaryPhone?.toString() ?? '' : '',
                description: selectedComponent ? selectedComponent.description?.toString() ?? '' : '',
                name: selectedComponent ? selectedComponent.name?.toString() ?? '' : '',
                price: selectedComponent ? selectedComponent.price ?? 0 : 0,
                downPayment: selectedComponent ? selectedComponent.downPayment ?? 0 : 0,
                purpose: selectedComponent ? selectedComponent.purpose?.toString() ?? '' : '',
                sellingPrice: selectedComponent ? selectedComponent.sellingPrice ?? 0 : 0,
                soldAt:
                  selectedComponent && selectedComponent.soldAt
                    ? `${new Date(selectedComponent.soldAt).getFullYear()}-${(new Date(selectedComponent.soldAt).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}-${new Date(selectedComponent.soldAt).getDate().toString().padStart(2, '0')}`
                    : `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`,
                soldBy: selectedComponent ? selectedComponent.soldBy?.toString() ?? '' : '',
                status: selectedComponent ? selectedComponent.status?.toString() ?? '' : '',
              }}
              validate={(values) => {
                const errors = {} as ErrorsAddComponent;

                if (!values.name || !values.name.trim()) {
                  errors.name = 'Required';
                }

                if (!values.status || !values.status.trim() || values.status === 'Select') {
                  errors.status = 'Required';
                }

                if (values.status === 'Sold') {
                  if (!values.sellingPrice || values.sellingPrice <= 0) {
                    errors.sellingPrice = 'Please enter a valid amount greater than 0';
                  }

                  if (!values.soldAt || !values.soldAt.trim()) {
                    errors.soldAt = 'Required';
                  }

                  if (!values.soldBy || !values.soldBy.trim()) {
                    errors.soldBy = 'Required';
                  }
                }

                if (!values.purpose || !values.purpose.trim() || values.purpose === 'Select') {
                  errors.purpose = 'Required';
                }

                if (values.area && !values.areaUnit) {
                  errors.area = 'Please select an area unit';
                }

                if (!values.price || values.price <= 0) {
                  errors.price = 'Please enter a valid amount greater than 0';
                }

                if (useDownPayment && (!values.downPayment || values.downPayment <= 0)) {
                  errors.downPayment = 'Please enter a valid amount greater than 0';
                }

                return errors;
              }}
              onSubmit={async (values: ValuesAddComponent, { setSubmitting, resetForm }: FormikHelpers<ValuesAddComponent>) => {
                const projectComponent: ProjectComponentModel = {
                  objectId: selectedComponent?.objectId,
                  name: values.name,
                  status: values.status,
                  sellingPrice: values.sellingPrice,
                  soldAt: new Date(values.soldAt),
                  soldBy: values.soldBy,
                  coverPicture: selectedCover,
                  purpose: values.purpose,
                  area: parseFloat(values.area),
                  areaUnit: values.areaUnit,
                  price: values.price,
                  downPayment: values.downPayment,
                  description: values.description,
                  contactName: values.contactName,
                  contactPhone: values.contactPhone,
                  contactSecondaryPhone: values.contactSecondaryPhone,
                  customFields: customFields,
                  useDownPayment: useDownPayment,
                };
                onSubmit(projectComponent);
                resetForm();
                setSelectedCover({ file: null, preview: '' });
                onClose();
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <Form>
                  <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
                    <Box pb={'1.5rem'}>
                      <FieldCover
                        className="col"
                        id="cover"
                        name="cover"
                        label="Cover Picture"
                        image={selectedCover.preview}
                        onChangeCover={(cover) => {
                          setSelectedCover({ file: cover.files[0], preview: cover.preview });
                          return cover;
                        }}
                        onDeleteCover={() => {
                          setSelectedCover({ file: null, preview: '' });
                        }}
                        color={color}
                      />
                    </Box>
                    <FieldInput
                      optional={false}
                      label="Title"
                      id="name"
                      mode={mode}
                      name={'name'}
                      placeholder="Component Title"
                      type="text"
                      error={touched.name && errors.name ? errors.name : false}
                    />
                    <Box>
                      <Dropdowndata
                        label="Status"
                        id="status"
                        name="status"
                        mode={mode}
                        optional={false}
                        values={['', StatusProduct.Available, StatusProduct.Hold, StatusProduct.Sold].map((method) => {
                          return {
                            label: method === '' ? 'Select' : method,
                            value: method,
                          };
                        })}
                        value={values.status}
                        onChange={(e) => {
                          setFieldValue('status', e.target.value);
                        }}
                        defaultValue=""
                      />
                      <StyledTypography>{errors.status}</StyledTypography>
                    </Box>
                    {values.status === 'Sold' && (
                      <FieldInput
                        optional={false}
                        label="Selling Price"
                        id="sellingPrice"
                        mode={mode}
                        name={'sellingPrice'}
                        placeholder="Selling Price"
                        type="number"
                        min={0}
                        error={touched.sellingPrice && errors.sellingPrice ? errors.sellingPrice : false}
                      />
                    )}
                    {values.status === 'Sold' && (
                      <FieldInput
                        optional={false}
                        label="Sold At"
                        id="soldAt"
                        mode={mode}
                        name={'soldAt'}
                        placeholder="Sold At"
                        type="date"
                        error={touched.soldAt && errors.soldAt ? errors.soldAt : false}
                      />
                    )}
                    {values.status === 'Sold' && (
                      <Box>
                        <Dropdowndata
                          label="Sold By"
                          id="soldBy"
                          name="soldBy"
                          mode={mode}
                          optional={false}
                          values={[{ firstName: 'Select', lastName: '' }, ...organizationMembers].map((member) => {
                            return {
                              label: member.firstName === 'Select' ? 'Select' : (member.firstName ?? '') + ' ' + (member.lastName ?? ''),
                              value: member.objectId ?? '',
                            };
                          })}
                          value={values.soldBy}
                          onChange={(e) => {
                            setFieldValue('soldBy', e.target.value);
                          }}
                          defaultValue=""
                        />
                        <StyledTypography>{errors.soldBy}</StyledTypography>
                      </Box>
                    )}
                    <Box>
                      <Dropdowndata
                        label="Purpose"
                        id="purpose"
                        name="purpose"
                        mode={mode}
                        optional={false}
                        values={['', 'Commercial', 'Residential'].map((method) => {
                          return {
                            label: method === '' ? 'Select' : method,
                            value: method,
                          };
                        })}
                        value={values.purpose}
                        onChange={(e) => {
                          setFieldValue('purpose', e.target.value);
                        }}
                        defaultValue=""
                      />
                      <StyledTypography>{errors.purpose}</StyledTypography>
                    </Box>

                    <Grid container>
                      <Grid item xs={7}>
                        <FieldInput
                          label="Area"
                          id="area"
                          mode={mode}
                          name={'area'}
                          placeholder="Area"
                          type="number"
                          error={touched.area && errors.area ? errors.area : false}
                        />
                      </Grid>
                      <Grid item xs={5} paddingLeft={'1rem'}>
                        <Dropdowndata
                          label="Unit"
                          id="areaUnit"
                          name="areaUnit"
                          mode={mode}
                          values={areaOptions}
                          value={values.areaUnit}
                          onChange={(e) => {
                            setFieldValue('areaUnit', e.target.value);
                          }}
                          defaultValue=""
                        />
                      </Grid>
                    </Grid>

                    <FieldInput
                      optional={false}
                      label="Price"
                      id="price"
                      mode={mode}
                      name={'price'}
                      placeholder="Price"
                      type="number"
                      error={touched.price && errors.price ? errors.price : false}
                    />

                    <FieldInput
                      optional={!useDownPayment}
                      label="Down Payment"
                      id="downPayment"
                      mode={mode}
                      name={'downPayment'}
                      placeholder="Down Payment"
                      type="number"
                      error={touched.downPayment && errors.downPayment ? errors.downPayment : false}
                    />

                    <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
                      <Checkbox
                        size="small"
                        sx={{
                          color: color || globalColors.blue,
                          '&.Mui-checked': {
                            color: color || globalColors.secondBlue,
                          },
                          marginTop: '-7px',
                        }}
                        onChange={() => setUseDownPayment((prev: boolean) => !prev)}
                        checked={useDownPayment}
                      />
                      <Typography sx={{ fontSize: '12px' }} color={globalColors.gray}>
                        Use Down Payment as the Primary Price
                      </Typography>
                    </Box>

                    <FieldInputTextarea
                      label="Description"
                      className="col"
                      placeholder="Project Component Description"
                      name="description"
                      id="description"
                      value={values.description}
                      onChange={(e) => {
                        setFieldValue('description', e.target.value);
                      }}
                    />

                    <FieldInput
                      label="Contact Name"
                      id="contactName"
                      mode={mode}
                      name={'contactName'}
                      placeholder="Contact Name"
                      type="text"
                      error={touched.contactName && errors.contactName ? errors.contactName : false}
                    />

                    <Grid container display={'flex'} rowGap={'1.5rem'}>
                      <Grid item xs={12} md={6} paddingRight={{ md: '0.5rem' }}>
                        <FieldInput
                          label="Contact Phone"
                          id="contactPhone"
                          mode={mode}
                          name={'contactPhone'}
                          placeholder="Contact Phone"
                          type="number"
                          error={touched.contactPhone && errors.contactPhone ? errors.contactPhone : false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} paddingLeft={{ md: '0.5rem' }}>
                        <FieldInput
                          label="Contact Secondary Phone"
                          id="contactSecondaryPhone"
                          mode={mode}
                          name={'contactSecondaryPhone'}
                          placeholder="Contact Secondary Phone"
                          type="number"
                          error={touched.contactSecondaryPhone && errors.contactSecondaryPhone ? errors.contactSecondaryPhone : false}
                        />
                      </Grid>
                    </Grid>

                    <Box width={'100%'}>
                      <InputLabel
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '32px',
                          letterSpacing: '0px',
                          textAlign: 'left',
                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                        }}
                      >
                        Custom Fields
                      </InputLabel>
                      <CustomFields
                        onChange={(fields) => {
                          setCustomFields(fields);
                        }}
                        initialFields={customFields}
                      />
                    </Box>

                    <Box display={'flex'} justifyContent={'center'} paddingTop={'2rem'}>
                      <Button
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Loading...' : selectedComponent ? 'Update' : 'Add'}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </ModalAddComponentStyle>
    </Modal>
  );
};

const ModalAddComponentStyle = styled('div')(({ theme }) => ({
  '& .btn-add': {
    marginTop: '1rem',
    width: '100%',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '0.75rem',
}));

export default ModalAddComponent;
