import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { useNavigate } from 'react-router-dom';
import CardPlanSelectable from '../../components/molecules/cardPlanSelectable';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Loader from '../../components/atoms/loader';
import InputSlider from '../../components/atoms/sliderWithInput';
import OnboardingModel from '../../utils/api/models/onboardingModel';
import OnboardingRepository from '../../utils/api/repositories/onboardingRepository';
import { PricingSchedules, PricingTiers, getPaymentTiers } from '../../utils/helpers';
import IosSwitch from '../../components/molecules/IosSwitch';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FieldInput, Toast } from '../../components/atoms';
import * as Mui from '@mui/material';

const marks = [
  {
    value: 1,
    label: '1 - 20',
  },
  {
    value: 21,
    label: '21 - 50',
  },
  {
    value: 51,
    label: '51 - 100',
  },
  {
    value: 101,
    label: '101 - 200',
  },
];

interface DiscountCode {
  discountCode: string;
}

const initialPlan = getPaymentTiers();

const OnboardingTier = () => {
  const [selectedSchedule, setSelectedSchedule] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [name, setName] = useState('');
  const [numEmployees, setNumEmployees] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [plan, setPlan] = useState(initialPlan);
  const [discountCodeStatus, setDiscountCodeStatus] = useState('');
  const [discountCodeValue, setDiscountCodeValue] = useState(0);
  const navigate = useNavigate();

  const takeToPayments = async () => {
    setLoading(true);
    const answers = localStorage.getItem('answers');
    if (answers) {
      const jsonifiedAnswers = JSON.parse(answers);
      if (
        jsonifiedAnswers['answers']['full_name'].value &&
        jsonifiedAnswers['answers']['number_users'].value &&
        jsonifiedAnswers['answers']['organization_name'].value &&
        jsonifiedAnswers['answers']['designation'].value &&
        jsonifiedAnswers['answers']['owner_name'].value &&
        jsonifiedAnswers['answers']['owner_email'].value &&
        jsonifiedAnswers['answers']['business_location'].value &&
        jsonifiedAnswers['answers']['admin_email'].value &&
        jsonifiedAnswers['answers']['admin_password'].value
      ) {
        const onboarding: OnboardingModel = {
          fullName: jsonifiedAnswers['answers']['full_name'].value,
          organizationName: jsonifiedAnswers['answers']['organization_name'].value,
          designation: jsonifiedAnswers['answers']['designation'].value,
          numberUsers: numEmployees === 1 ? 20 : numEmployees === 21 ? 50 : numEmployees === 51 ? 100 : numEmployees === 101 ? 200 : 20,
          ownerName: jsonifiedAnswers['answers']['owner_name'].value,
          ownerEmail: jsonifiedAnswers['answers']['owner_email'].value,
          businessLocation: jsonifiedAnswers['answers']['business_location'].value,
          adminEmail: jsonifiedAnswers['answers']['admin_email'].value,
          adminPassword: jsonifiedAnswers['answers']['admin_password'].value,
          tier: selectedPlan === 0 ? PricingTiers.Essential : PricingTiers.Professional,
          paymentCycle: selectedSchedule === 0 ? PricingSchedules.Biannual : PricingSchedules.Annual,
          amountDue: selectedSchedule === 0 ? 6 * plan[selectedPlan].price * discount : 12 * plan[selectedPlan].price * discount,
          perMonthAmount: plan[selectedPlan].price * discount,
        };
        const onboardingRepository = new OnboardingRepository();
        const savedOnboarding = await onboardingRepository.create(onboarding);
        if (savedOnboarding) {
          navigate(`/onboarding/secure/payment/${savedOnboarding.id}`);
        } else {
          setLoading(false);
          setError('Error taking you to payments. Please begin the onboarding process again. Taking you back...');
          setTimeout(() => {
            navigate('/onboarding');
          }, 6000);
        }
      } else {
        setLoading(false);
        setError('Error fetching your onboarding details. Please begin the onboarding process again. Taking you back...');
        setTimeout(() => {
          navigate('/onboarding');
        }, 6000);
      }
    } else {
      setLoading(false);
      setError('Error fetching your onboarding details. Please begin the onboarding process again. Taking you back...');
      setTimeout(() => {
        navigate('/onboarding');
      }, 6000);
    }
  };

  useEffect(() => {
    try {
      const answers = localStorage.getItem('answers');
      if (answers) {
        const jsonifiedAnswers = JSON.parse(answers);
        if (
          jsonifiedAnswers['answers']['full_name'].value &&
          jsonifiedAnswers['answers']['number_users'].value &&
          jsonifiedAnswers['answers']['organization_name'].value &&
          jsonifiedAnswers['answers']['designation'].value &&
          jsonifiedAnswers['answers']['owner_name'].value &&
          jsonifiedAnswers['answers']['owner_email'].value &&
          jsonifiedAnswers['answers']['business_location'].value &&
          jsonifiedAnswers['answers']['admin_email'].value &&
          jsonifiedAnswers['answers']['admin_password'].value
        ) {
          setName(jsonifiedAnswers['answers']['full_name'].value ?? '');
          if (jsonifiedAnswers['answers']['number_users'].value >= 1 && jsonifiedAnswers['answers']['number_users'].value <= 20) {
            setNumEmployees(1);
          } else if (jsonifiedAnswers['answers']['number_users'].value >= 21 && jsonifiedAnswers['answers']['number_users'].value <= 50) {
            setNumEmployees(21);
          } else if (jsonifiedAnswers['answers']['number_users'].value >= 51 && jsonifiedAnswers['answers']['number_users'].value <= 100) {
            setNumEmployees(51);
          } else if (jsonifiedAnswers['answers']['number_users'].value >= 101 && jsonifiedAnswers['answers']['number_users'].value <= 200) {
            setNumEmployees(101);
          } else {
            setNumEmployees(200);
          }
          setLoading(false);
        } else {
          setLoading(false);
          setError('Error fetching your onboarding details. Please begin the onboarding process again. Taking you back...');
          setTimeout(() => {
            navigate('/onboarding');
          }, 6000);
        }
      } else {
        setLoading(false);
        setError('Error fetching your onboarding details. Please begin the onboarding process again. Taking you back...');
        setTimeout(() => {
          navigate('/onboarding');
        }, 6000);
      }
    } catch (error) {
      setLoading(false);
      setError('Error fetching your onboarding details. Please begin the onboarding process again. Taking you back...');
      setTimeout(() => {
        navigate('/onboarding');
      }, 6000);
    }
  }, []);

  //eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE4MjQwNzkwLCJqdGkiOiI2ZDI3MDllOS0xZGQ2LTRlNjUtODc4ZC0wZGFjZTI5YTA0ZTMiLCJ1c2VyX3V1aWQiOiJiNzhlZjY4Mi0yNWFjLTQxNDItODdhMi02ZTdiNjA4NTExNjQifQ.y-xxj6xBlAM4LIIVAMuNIPx5wJXdzEl_svQ8fDFQ7uyG-wNmRHdyfgsJb1hH4dbaMmpsugrfFQCn6eiZNNLJWg

  useEffect(() => {
    if (selectedSchedule === 1) {
      setDiscount(0.75 - discountCodeValue);
    } else {
      setDiscount(1 - discountCodeValue);
    }
  }, [selectedSchedule, discountCodeValue]);

  useEffect(() => {
    if (numEmployees >= 1 && numEmployees <= 20) {
      let temp = [...plan];
      temp[0].price = 99;
      temp[1].price = 120;
      setPlan(temp);
    } else if (numEmployees > 20 && numEmployees <= 50) {
      let temp = [...plan];
      temp[0].price = 140;
      temp[1].price = 160;
      setPlan(temp);
    } else if (numEmployees > 50 && numEmployees <= 100) {
      let temp = [...plan];
      temp[0].price = 180;
      temp[1].price = 200;
      setPlan(temp);
    } else {
      let temp = [...plan];
      temp[0].price = 220;
      temp[1].price = 240;
      setPlan(temp);
    }
  }, [numEmployees]);

  return (
    <Box minHeight={'100vh'} overflow={'hidden'} bgcolor={globalColors.mainBackground} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>

      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'} marginTop={'50vh'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : error !== '' ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'2rem'}
          alignItems={'center'}
          justifyContent={'center'}
          height={'100%'}
          marginTop={'50vh'}
          padding={'2rem'}
        >
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {error}
          </Typography>
        </Box>
      ) : (
        <Box padding={'2rem'}>
          {/* NAVIGATION */}
          <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'}>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  localStorage.removeItem('answers');
                  navigate('/onboarding');
                }}
              />
              <Typography
                fontSize={'12px'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  localStorage.removeItem('answers');
                  navigate('/onboarding');
                }}
              >
                Back to Onboarding
              </Typography>
            </Grid>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'end'} alignItems={'center'} zIndex={9999999} xs={4}>
              <Typography textAlign={'end'} fontSize={'12px'} sx={{ cursor: 'pointer' }} onClick={takeToPayments}>
                Go to Payments
              </Typography>
              <ArrowForward sx={{ cursor: 'pointer' }} onClick={takeToPayments} />
            </Grid>
          </Grid>
          {/* PAYMENT PLAN SELECTION */}
          <Box marginTop={'5rem'} display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'0.5rem'} flexDirection={'column'}>
              <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography variant="h6" textAlign={'center'} sx={{ color: globalColors.white }}>
                  Hi <span style={{ color: globalColors.mainBlue }}>{name}</span>,<br />
                  select a Payment Plan which best suits your requirements.
                  <br />
                  {/* <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>{numEmployees}</span> users */}
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              display={'flex'}
              justifyContent={'space-between'}
              maxWidth={'lg'}
              margin={'auto'}
              marginTop={'3rem'}
              marginBottom={'3rem'}
            >
              <Grid
                item
                xs={12}
                md={4}
                display={'flex'}
                justifyContent={{ xs: 'center', md: 'start' }}
                paddingLeft={{ xs: '1rem', md: 0 }}
                paddingRight={{ xs: '1rem', md: 0 }}
              >
                <InputSlider
                  marks={marks}
                  title={'Number of Users'}
                  showValue={false}
                  value={numEmployees}
                  minValue={1}
                  maxValue={101}
                  setValue={setNumEmployees}
                  step={null}
                  valueColor={globalColors.white}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display={'flex'}
                justifyContent={{ xs: 'center', md: 'end' }}
                gap={'1rem'}
                alignItems={'center'}
                paddingTop={{ xs: '2rem', md: 0 }}
              >
                <Typography variant="h6" textTransform={'uppercase'} sx={{ color: globalColors.white }}>
                  {PricingSchedules.Biannual}
                </Typography>
                <IosSwitch
                  initiallyChecked
                  onChange={(checked) => {
                    if (checked) {
                      setSelectedSchedule(0);
                    } else {
                      setSelectedSchedule(1);
                    }
                  }}
                  monochrome={false}
                  trackColor={globalColors.mainBlue}
                />
                <Typography variant="h6" textTransform={'uppercase'} sx={{ color: globalColors.white }}>
                  {PricingSchedules.Annual}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
            marginTop={'2rem'}
            marginBottom={'2rem'}
            gap={'1rem'}
          >
            <Typography
              variant={discount < 1 ? 'h5' : 'h4'}
              color={discount < 1 ? globalColors.white : globalColors.mainBlue}
              fontWeight={discount < 1 ? '300' : '600'}
              sx={{ textDecoration: discount < 1 ? 'line-through' : 'none' }}
            >
              ${selectedSchedule === 0 ? (6 * plan[selectedPlan].price).toFixed(2) : (12 * plan[selectedPlan].price).toFixed(2)}
            </Typography>
            {discount < 1 && (
              <Typography variant="h4" fontWeight={'600'} color={globalColors.mainBlue}>
                $
                {selectedSchedule === 0
                  ? (6 * plan[selectedPlan].price * discount).toFixed(2)
                  : (12 * plan[selectedPlan].price * discount).toFixed(2)}
              </Typography>
            )}
          </Box>
          <Box position={'relative'}>
            <Formik
              initialValues={{
                discountCode: '',
              }}
              validate={(values) => {
                const errors = {} as DiscountCode;

                if (!values.discountCode.trim()) {
                  errors.discountCode = 'Please enter a valid discount code';
                }

                return errors;
              }}
              onSubmit={async (values: DiscountCode, { setSubmitting, resetForm }: FormikHelpers<DiscountCode>) => {
                const onboardingRepository = new OnboardingRepository();
                const verifyCode = await onboardingRepository.verifyDiscountCode(values.discountCode);
                if (verifyCode) {
                  setDiscountCodeStatus(`${verifyCode.toFixed(2)}% discount code applied successfully!`);
                  setDiscountCodeValue(verifyCode / 100);
                } else {
                  setDiscountCodeStatus('Invalid or expired discount code added.');
                  setDiscountCodeValue(0);
                }
              }}
            >
              {({ isSubmitting, touched, errors }) => (
                <Form>
                  <Box
                    borderRadius={'20px'}
                    marginBottom={'2px'}
                    display={'flex'}
                    width={'100%'}
                    flexDirection={'row'}
                    gap={'1.5rem'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Box sx={{ maxWidth: '400px' }}>
                      <FieldInput
                        id="discountCode"
                        mode={'dark'}
                        name={'discountCode'}
                        placeholder="Discount Code"
                        type="text"
                        error={touched.discountCode && errors.discountCode ? errors.discountCode : false}
                      />
                    </Box>
                    <Box>
                      <Mui.Button disabled={isSubmitting} type="submit" sx={{ color: globalColors.mainBlue, whiteSpace: 'nowrap', padding: 0 }}>
                        {isSubmitting ? 'Verifying...' : 'Verify'}
                      </Mui.Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '14px' }} textAlign={'center'} alignItems={'center'} alignContent={'center'} noWrap fontWeight={'light'} paddingTop={'1rem'} color={discountCodeStatus.includes('Invalid') ? globalColors.red : globalColors.mainBlue} >
              {discountCodeStatus}
            </Typography>
          </Box>
          <Box margin={'auto'} maxWidth={'lg'} display={'flex'} sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }} gap={'30px'} marginTop={'3rem'}>
            {plan.map((val, index) => (
              <CardPlanSelectable
                key={index}
                title={val.title}
                content={val.content}
                extraContent={val.extraContent}
                price={val.price}
                discountedPrice={val.price * discount}
                index={index}
                selectedIndex={selectedPlan}
                setSelectedIndex={setSelectedPlan}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OnboardingTier;
