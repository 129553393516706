import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../store/selector';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import SupportAccordion from './supportAccordian';
import ContactUsModal from './Contact/contactUsModal';
import ContactCallModal from './Contact/contactCallModal';
import ContactResponseModal from './Contact/contactResponseModal';
import CallResponseModal from './Contact/callResponseModal';

const Support = () => {
  const [activeBox, setActiveBox] = useState<string>('Quick Start');
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [contactResponseModalOpen, setContactResponseModalOpen] = useState(false);
  const [callResponseModalOpen, setCallResponseModalOpen] = useState(false);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);

  const handleBoxClick = (boxId: string) => {
    setActiveBox(boxId);
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Support'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
            <Box marginBottom={'10px'}></Box>
            {/* Lead Assignment Container */}
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '30px',
                  fontWeight: 500,
                  lineHeight: '45px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                }}
              >
                We Are Here to Help
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '28px',
                  letterSpacing: '-0.02em',
                  textAlign: 'left',
                  color: globalColors.gray,
                }}
              >
                Explore our guides and FAQ's, and if anything is still unclear, you can contact us or schedule a call and we'll get back to you.
              </Typography>
            </Box>
            {/* <AssignmentContainer
              bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
              padding={'20px'}
              borderRadius={'16px'}
              color={globalColors.white}
              fontSize={'12px'}
              boxShadow={'#171717'}
              marginTop={'1rem'}
            >
              {' '}
              <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                <Typography
                  component="div"
                  fontFamily={'Poppins'}
                  fontSize={'22px'}
                  fontWeight={'500'}
                  lineHeight={'33px'}
                  color={mode === 'dark' ? globalColors?.white : globalColors.black}
                >
                  Tutorial videos
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '15px',
                  marginTop: '20px',
                  flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                }}
              >
                <ButtonContainer
                  mode={mode}
                  sx={{
                    backgroundColor:
                      activeBox === 'Quick Start' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.white,
                  }}
                  onClick={() => handleBoxClick('Quick Start')}
                >
                  <ButtonHeading mode={mode}>Quick Start</ButtonHeading>
                </ButtonContainer>
                <ButtonContainer
                  mode={mode}
                  sx={{
                    backgroundColor:
                      activeBox === 'Add Lead' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.white,
                  }}
                  onClick={() => handleBoxClick('Add Lead')}
                >
                  <ButtonHeading mode={mode}>Add Lead</ButtonHeading>
                </ButtonContainer>
                <ButtonContainer
                  mode={mode}
                  sx={{
                    backgroundColor:
                      activeBox === 'Add Project' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.white,
                  }}
                  onClick={() => handleBoxClick('Add Project')}
                >
                  <ButtonHeading mode={mode}>Add Project</ButtonHeading>
                </ButtonContainer>
                <ButtonContainer
                  mode={mode}
                  sx={{
                    backgroundColor:
                      activeBox === 'Add Property' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.white,
                  }}
                  onClick={() => handleBoxClick('Add Property')}
                >
                  <ButtonHeading mode={mode}>Add Property</ButtonHeading>
                </ButtonContainer>
                <ButtonContainer
                  mode={mode}
                  sx={{
                    backgroundColor:
                      activeBox === 'Add Task' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.white,
                  }}
                  onClick={() => handleBoxClick('Add Task')}
                >
                  <ButtonHeading mode={mode}>Add Task</ButtonHeading>
                </ButtonContainer>
                <ButtonContainer
                  mode={mode}
                  sx={{
                    backgroundColor:
                      activeBox === 'Notifications' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.white,
                  }}
                  onClick={() => handleBoxClick('Notifications')}
                >
                  <ButtonHeading mode={mode}>Notifications</ButtonHeading>
                </ButtonContainer>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '15px',
                  marginTop: '20px',
                  flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                }}
              >
                {[{ name: 'Quick Start' }, { name: 'Setting up' }, { name: 'Create Company' }].map((item) => (
                  <VideoContainer mode={mode}>
                    <Box sx={{ height: '152px', minWidth: '264px', borderRadius: '16px 16px 0px 0px' }}>
                      <video style={{ borderRadius: '16px 16px 0px 0px' }} width="100%" height="100%" controls>
                        <source src="https://youtu.be/y9j-BL5ocW8" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </Box>
                    <Box
                      sx={{
                        height: '40px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 10px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '21px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: mode === 'dark' ? globalColors?.white : globalColors.black,
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '10px',
                          fontWeight: 500,
                          lineHeight: '15px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: mode === 'dark' ? globalColors?.white : globalColors.black,
                        }}
                      >
                        6 min
                      </Typography>
                    </Box>
                  </VideoContainer>
                ))}
              </Box>
            </AssignmentContainer> */}
            {/* Container */}
            <AssignmentContainer
              bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
              padding={'20px'}
              borderRadius={'16px'}
              color={globalColors.white}
              fontSize={'12px'}
              boxShadow={'#171717'}
              marginTop={'1rem'}
            >
              {' '}
              {/* Accordion */}
              <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                <Typography
                  component="div"
                  fontFamily={'Poppins'}
                  fontSize={'22px'}
                  fontWeight={'500'}
                  lineHeight={'33px'}
                  color={mode === 'dark' ? globalColors?.white : globalColors.black}
                >
                  Frequently Asked Questions
                </Typography>
              </Box>
              <Box sx={{ width: '100%', marginTop: '20px' }}></Box>
              <SupportAccordion />
            </AssignmentContainer>

            {/* Container */}
            <AssignmentContainer
              bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
              padding={'20px'}
              borderRadius={'16px'}
              color={globalColors.white}
              fontSize={'12px'}
              boxShadow={'#171717'}
              marginTop={'1rem'}
            >
              {' '}
              {/* Headings */}
              <Box>
                <Typography
                  component="div"
                  fontFamily={'Poppins'}
                  fontSize={'22px'}
                  fontWeight={'500'}
                  lineHeight={'33px'}
                  color={mode === 'dark' ? globalColors?.white : globalColors.black}
                >
                  Can't Find What You Are Looking For?
                </Typography>

                <Typography
                  sx={{
                    marginTop: '20px',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '28px',
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    color: globalColors?.gray,
                  }}
                >
                  Choose any option below and we are more than happy to help you.
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '30px',
                  marginTop: '30px',
                  flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                }}
              >
                <ButtonStyle
                  onClick={() => {
                    setContactModalOpen(true);
                  }}
                  type="submit"
                  color={color}
                  mode={mode}
                >
                  Contact Us
                </ButtonStyle>
                <ButtonStyle
                  onClick={() => {
                    setCallModalOpen(true);
                  }}
                  type="submit"
                  color={color}
                  mode={mode}
                >
                  Schedule a Call
                </ButtonStyle>
              </Box>
            </AssignmentContainer>
          </Box>
        </Box>
        <ContactUsModal
          open={contactModalOpen}
          onClose={() => {
            setContactModalOpen(false);
          }}
          openResponse={() => {
            setContactResponseModalOpen(true);
          }}
          mode={mode}
          color={color}
        />

        <ContactCallModal
          open={callModalOpen}
          onClose={() => {
            setCallModalOpen(false);
          }}
          openResponse={() => {
            setCallResponseModalOpen(true);
          }}
          mode={mode}
          color={color}
        />

        <ContactResponseModal
          open={contactResponseModalOpen}
          onClose={() => {
            setContactResponseModalOpen(false);
          }}
          mode={mode}
        />

        <CallResponseModal
          open={callResponseModalOpen}
          onClose={() => {
            setCallResponseModalOpen(false);
          }}
          mode={mode}
        />
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  height: 'auto',
  width: '100%',
});

const ButtonHeading = styled(Typography)(({ mode }: { mode: any }) => ({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: mode === 'dark' ? globalColors?.white : globalColors.black,
}));

const ButtonContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '32px',
  minWidth: '94px',
  padding: '4px 20px',
  cursor: 'pointer',
  border: `1px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight}`,
  borderRadius: '12px',
  //   backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
}));

const VideoContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '200px',
  minWidth: '264px',
  cursor: 'pointer',
  border: `1px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight}`,
  borderRadius: '16px 16px 6px 6px',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
}));

const ButtonStyle = styled('button')<any>(({ color, disabled, type }) => ({
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '42px',
  background: color !== 'default' && color !== '' ? color : globalColors.blue,
  color: globalColors.white,
  width: '296px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: `1px solid ${color ? color : globalColors.lightblue}`,
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default Support;
