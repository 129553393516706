import { Box, Button, Divider, FormGroup, InputLabel, Modal, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { InputStyle } from './form.style';
import { globalColors } from '../../../utils/constants/color';
import OrganizationRepository from '../../../utils/api/repositories/organizationRepository';
import OrganizationModel from '../../../utils/api/models/organizationModel';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { Toast } from '../../atoms';
import { MdDelete } from 'react-icons/md';

interface ModalCommunicateProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  status: string;
  existingLabel: string;
  onRemoval: () => void;
  onAddition: (label: string, status: string) => void;
}

export interface FormValues {
  apiKey: string;
  apiLabel: string;
}

const IntegrationModal = ({ open, onClose, mode, color, status, existingLabel, onRemoval, onAddition }: ModalCommunicateProps) => {
  const [formValues, setFormValues] = useState<FormValues>({ apiKey: '', apiLabel: '' });
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '525px',
    maxHeight: '440px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (formValues.apiKey == '') {
      Toast('Please add a valid API Key before proceeding.', 'warning');
      return;
    }
    if (formValues.apiLabel == '') {
      Toast('Please add a valid label before proceeding.', 'warning');
      return;
    }
    const organizationRepository = new OrganizationRepository();
    const savedOrganization = await organizationRepository.saveMetaKey(formValues.apiKey, formValues.apiLabel);

    if (savedOrganization) {
      Toast('API Key saved successfully.', 'success');
      setFormValues({ apiKey: '', apiLabel: '' });
      onClose();
      onAddition(formValues.apiLabel, 'pending');
    } else {
      Toast('API Key could not be saved successfully. Please try again later.', 'error');
    }
  };

  const handleDelete = async () => {
    const organizationRepository = new OrganizationRepository();
    const savedOrganization = await organizationRepository.removeMetaKey();

    if (savedOrganization) {
      Toast('API Key removed successfully.', 'success');
      setFormValues({ apiKey: '', apiLabel: '' });
      onClose();
      onRemoval();
    } else {
      Toast('API Key could not be removed successfully. Please try again later.', 'error');
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-add-amenities" aria-describedby="modal-request-add-amenities" className="content">
      <Box sx={style} className="content">
        {status == 'pending' || status == 'approved' ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Typography sx={{ color: globalColors.gray }}>Existing API Key - </Typography>
              <Typography sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>{existingLabel}</Typography>
            </Box>
            <MdDelete onClick={() => handleDelete()} size={'1.3rem'} color={color || globalColors.blue} style={{ cursor: 'pointer' }} />
          </Box>
        ) : (
          <></>
        )}
        {status !== 'pending' && (
          <Box>
            {status == 'approved' && <Divider sx={{ backgroundColor: globalColors.gray, marginTop: '20px', marginBottom: '20px' }} />}
            <FormGroup className="mb-4 text-start" sx={{ width: '100% ' }}>
              {/* Field 1 */}
              <InputLabel
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '28px',
                  letterSpacing: '-2%',
                  color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
                  marginTop: '2%',
                }}
                htmlFor="apiKeyInput"
              >
                API Key
              </InputLabel>
              <InputStyle
                mode={mode}
                required
                name="apiKey"
                type="text"
                id="apiKeyInput"
                placeholder="API Key"
                value={formValues.apiKey}
                onChange={handleChange}
              />
              <InputLabel
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '28px',
                  letterSpacing: '-2%',
                  color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
                  marginTop: '2%',
                }}
                htmlFor="labelInput"
              >
                Label
              </InputLabel>
              <InputStyle
                mode={mode}
                required
                name="apiLabel"
                type="text"
                id="labelInput"
                placeholder="Label"
                value={formValues.apiLabel}
                onChange={handleChange}
              />
              <Typography
                sx={{
                  color: mode === 'dark' ? globalColors.gray : globalColors.black,
                  textAlign: 'left',
                  paddingTop: '2%',
                  paddingBottom: '5%',
                  fontSize: '10px',
                }}
              >
                Add a label to identify the key later on, since the key would no longer be visible to you, once you add it.
              </Typography>
            </FormGroup>

            {/* Button */}
            <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
              <Button
                sx={{
                  border: `1px solid ${globalColors.red}`,
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  width: '100%',
                  borderRadius: '12px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  '&:hover': {
                    backgroundColor: globalColors.red,
                  },
                }}
                onClick={() => {
                  setFormValues({ apiKey: '', apiLabel: '' });
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  border: `1px solid ${color || globalColors.blue}`,
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  width: '100%',
                  borderRadius: '12px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  '&:hover': {
                    backgroundColor: color || globalColors.blue,
                  },
                }}
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Done
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  background: color !== 'default' && color !== '' ? color : type === 'reset' ? globalColors.blackLight : globalColors.blue,
  color: globalColors.white,
  width: '240px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default IntegrationModal;
