import { Box, Grid } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { selectAddNew, selectColor, selectNavigationLayout, selectOrganizationTier, selectThemeMode } from '../../store/selector';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import SocialMediaCard from '../../components/molecules/socialMediaCard';
import Heading from '../../components/atoms/heading';
import LineCharts from '../../components/organisms/charts/lineCharts';
import Piechart from '../../components/organisms/charts/pieChart';
import ContentTable from '../../components/organisms/contentTable';
import AddPost from '../../components/molecules/Post/addPost';
import { useEffect, useState } from 'react';
import UpdatePost from '../../components/molecules/Post/updatePost';
import IntegrationModal from '../../components/molecules/Post/addIntegrationModal';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import Loader from '../../components/atoms/loader';
import SocialPostRepository from '../../utils/api/repositories/socialPostRepository';
import NotFoundLottie from '../../components/atoms/notFound';
import { PricingTiers, hasPermissionForAction } from '../../utils/helpers';
import NoPermission from '../../components/atoms/noPermission';
import InsufficientPlan from '../../components/atoms/insufficientPlan';

const socialMediaData = [
  {
    value: '20.3K',
    platform: 'Facebook',
    direction: 'up',
  },
  {
    value: '20.3K',
    platform: 'Instagram',
    direction: 'up',
  },
  // {
  //   value: '20.3K',
  //   title: 'Integration Required',
  //   direction: 'down',
  // },
  // {
  //   value: '20.3K',
  //   title: 'Integration Required',
  //   direction: 'up',
  // },
];

const SocialMedia = () => {
  const [addPost, setAddPost] = useState<boolean>(false);
  const [updatePost, setUpdatePost] = useState<string>('');
  const [socialPlatform, setSocialPlatform] = useState<any[]>([]);
  const [openIntegrationModal, setOpenIntegrationModal] = useState<boolean>(false);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const showArrow = useSelector(selectAddNew);
  const organizationTier = useSelector(selectOrganizationTier);
  const color = useSelector(selectColor);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [existingLabel, setExistingLabel] = useState('');
  const [postCounts, setPostCounts] = useState<{ name: string; value: number }[]>([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [hasUpdatePermission, setHasUpdatePermission] = useState(false);
  const [hasAddPermission, setHasAddPermission] = useState(false);

  const handleOpenModalReport = () => setOpenIntegrationModal(false);

  const handleRemoval = () => {
    setStatus('');
    setExistingLabel('');
  };

  const handleAddition = (label: string, status: string) => {
    setExistingLabel(label);
    setStatus(status);
  };

  useEffect(() => {
    if (!showArrow?.addNew) {
      setAddPost(false);
    }
  }, [showArrow]);

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Social Media', 'View'));
    setHasAddPermission(hasPermissionForAction('Social Media', 'Create'));
    setHasUpdatePermission(hasPermissionForAction('Social Media', 'Update'));
    const fetchMetaKey = async () => {
      const organizationRepository = new OrganizationRepository();
      const existingKeyDetails = await organizationRepository.fetchMetaKey();

      if (existingKeyDetails) {
        setStatus(existingKeyDetails.status);
        setExistingLabel(existingKeyDetails.label);
      }
    };

    const getPostCounts = async () => {
      const socialPostRepository = new SocialPostRepository();
      const facebookCount = await socialPostRepository.getPostsCount('facebook');
      var tempArray = [];
      if (facebookCount !== null) {
        tempArray.push({ name: 'Facebook', value: facebookCount });
      }
      const instagramCount = await socialPostRepository.getPostsCount('instagram');
      if (instagramCount !== null) {
        tempArray.push({ name: 'Instagram', value: instagramCount });
      }
      setPostCounts(tempArray);
      setLoading(false);
    };

    fetchMetaKey();
    getPostCounts();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={addPost ? (updatePost === '' ? 'Social Media / New' : 'Social Media / Update') : 'Social Media'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? '80px' : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission && organizationTier == PricingTiers.Professional ? (
          <Box width={'100%'} padding={3}>
            {addPost === false ? (
              <>
                <Box
                  display={'flex'}
                  sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }}
                  justifyContent={'space-between'}
                  gap={'30px'}
                >
                  {socialMediaData?.map((val) => (
                    <SocialMediaCard
                      action={() => {
                        setOpenIntegrationModal(true);
                      }}
                      value={val?.value}
                      title={status == 'pending' || status == '' ? 'Integration Pending' : val?.platform}
                      direction={val?.direction}
                      platform={val?.platform}
                      status={status}
                      buttonText={status == 'pending' ? 'View Details' : status == '' ? 'Start Integration' : 'Edit Details'}
                    />
                  ))}
                </Box>

                <Grid container spacing={2} marginTop={'10px'}>
                  <Grid item xs={12} md={12} lg={7}>
                    <Box
                      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                      // border={`1px solid ${globalColors?.border.gray}`}
                      borderRadius={'16px'}
                      boxShadow={'#303030'}
                      paddingLeft={'16px'}
                      paddingRight={'16px'}
                      paddingTop={'10px'}
                      height={'100%'}
                    >
                      <Box paddingBottom={'55px'} paddingTop={'10px'}>
                        <Heading text={'Visitors stats'} />
                      </Box>
                      <LineCharts />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg={5}>
                    <Box
                      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                      // border={`1px solid ${globalColors?.border.gray}`}
                      borderRadius={'16px'}
                      boxShadow={'#303030'}
                      paddingLeft={'30px'}
                      paddingRight={'30px'}
                      paddingTop={'10px'}
                      paddingBottom={'10px'}
                      height={'100%'}
                    >
                      <Box paddingBottom={'25px'} paddingTop={'10px'}>
                        <Heading text={'Posts'} />
                      </Box>

                      {postCounts.length > 0 && postCounts[0].value > 0 ? (
                        <Piechart data={postCounts} />
                      ) : (
                        <NotFoundLottie showButton={false} size={'small'} text="" buttonText="" navigateTo="" />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <ContentTable setAddPost={setAddPost} setUpdatePost={setUpdatePost} />
              </>
            ) : updatePost !== '' ? (
              hasUpdatePermission ? (
                <UpdatePost setAddPost={setAddPost} updatePost={updatePost} setUpdatePost={setUpdatePost} />
              ) : (
                <NoPermission />
              )
            ) : hasAddPermission ? (
              <AddPost addPost={addPost} setAddPost={setAddPost} />
            ) : (
              <NoPermission />
            )}

            {/* {updatePost !== '' && <UpdatePost updatePost={updatePost} setUpdatePost={setUpdatePost} />} */}
          </Box>
        ) : !hasPermission ? (
          <NoPermission />
        ) : (
          <InsufficientPlan />
        )}
      </Box>
      <IntegrationModal
        open={openIntegrationModal}
        onClose={handleOpenModalReport}
        mode={mode}
        color={color}
        status={status}
        existingLabel={existingLabel}
        onRemoval={handleRemoval}
        onAddition={handleAddition}
      />
    </Box>
  );
};

export default SocialMedia;
