import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import ICLock from '../../../assets/Icons/ic-lock.svg';
import ICPerson from '../../../assets/Icons/ic-person.svg';
import { useLogin } from '../../../hooks/auth';
import { globalColors } from '../../../utils/constants/color';
import { ButtonText, FieldInput } from '../../atoms';

interface FormLoginProps {
  className?: string;
  onCreateAccount: () => void;
  onForgotPassword: () => void;
}

interface ValuesFormLogin {
  credentials: string;
  password: string;
}

const FormLogin = ({ className, onCreateAccount, onForgotPassword }: FormLoginProps) => {
  const { validationSchema, handleDummyFlow, handleDummySignUp, handleSubmitLogin } = useLogin();
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  return (
    <FormLoginStyle className={className}>
      <Typography className="heading">Login</Typography>
      <Typography className="sub-heading">Enter your account information.</Typography>
      <Formik
        enableReinitialize
        initialValues={{
          credentials: '',
          password: '',
        }}
        // validate={(values) => {
        //   const errors = {} as ValuesFormLogin;
        //   if (values.credentials === '') {
        //     errors.credentials = 'Please enter valid credentials.';
        //   }
        //   if (values.password === '') {
        //     errors.password = 'Please enter a valid password.';
        //   }
        //   return errors;
        // }}
        validationSchema={validationSchema}
        onSubmit={async (values: ValuesFormLogin, { setSubmitting }: FormikHelpers<ValuesFormLogin>) => {
          try {
            setLoading(true);
            await handleSubmitLogin(values);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.error('Error logging in:', error);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form>
            <FieldInput
              icon={ICPerson}
              iconPosition="left"
              className="input-email"
              label="Email or Username"
              id="credentials"
              name="credentials"
              placeholder="Email or Username"
              error={errors.credentials && touched.credentials ? errors.credentials : false}
            />
            <FieldInput
              icon={ICLock}
              iconPosition="left"
              label="Password"
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              onShowPassword={() => setShowPassword(!showPassword)}
              isShowPassword={showPassword}
              isPassword={true}
              placeholder="Password"
              error={errors.password && touched.password ? errors.password : false}
            />
            <ButtonText className="btn-forget" onClick={onForgotPassword}>
              Forgot password?
            </ButtonText>
            <Stack className="btn-login-wrapper">
              <Button disabled={loading} type="submit" className="btn-login" variant="contained">
                {loading ? "Loading..." : "Login"}
              </Button>
              <Stack className='create-new-account-wrapper'>
                <Typography className="info-create-new-acc">Don't have an account?</Typography>
                <ButtonText onClick={onCreateAccount}>Join Now</ButtonText>
                {/* <ButtonText onClick={handleDummyFlow}>Handle Dummy Flow</ButtonText> */}
              </Stack>
            </Stack>
            
          </Form>
        )}
      </Formik>
    </FormLoginStyle>
  );
};

const FormLoginStyle = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  borderRadius: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },

  // TODO : debuging responsive

  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'green',
  // },
  // [theme.breakpoints.between('lg', 'xl')]: {
  //   backgroundColor: 'yellow',
  // },
  // [theme.breakpoints.between('md', 'lg')]: {
  //   backgroundColor: 'pink',
  // },
  // [theme.breakpoints.between('sm', 'md')]: {
  //   backgroundColor: 'purple',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   backgroundColor: 'cyan',
  // },
  // [theme.breakpoints.down('xs')]: {
  //   backgroundColor: 'blue',
  // },
  '& .heading': {
    marginBottom: '1rem',
    color: globalColors.white,
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'poppins', // TODO : config font
  },

  '& .sub-heading': {
    color: globalColors.text.secondary,
    fontSize: '13px',
    marginBottom: '1.5rem',
  },

  '& .input-email': {
    marginBottom: '1.5rem',
    color: 'pink',
  },

  '& .btn-forget': {
    marginTop: '8px',
  },

  '& .btn-login-wrapper': {
    marginTop: '38px',
    alignItems: 'center',

    '& .btn-login': {
      marginBottom: '1rem',
      width: '291px',
      backgroundColor: globalColors.blue,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },

    '& .create-new-account-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '20px',

      '& .info-create-new-acc': {
        // TODO : not yet aligned
        color: globalColors.text.secondary,
        fontSize: '13px',
        fontWeight: 500,
      },
    },
  },

  '& button': {
    textTransform: 'none',
  },
}));

export default FormLogin;
