import React, { useEffect } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography, AvatarGroup, Avatar, Grid, Divider } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddNew, selectColor, selectCommissionMode, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { Button, SearchInput, Toast } from '../../components/atoms';
import { useState } from 'react';
import NotFoundLottie from '../../components/atoms/notFound';
import { useNavigate } from 'react-router-dom';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole, resetUserData } from '../../store/user';
import Loader from '../../components/atoms/loader';
import { ArrowBack, ArrowForward, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { setCloseSettings } from '../../store/routes';
import NoPermission from '../../components/atoms/noPermission';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import UserModel from '../../utils/api/models/userModel';
import { formatRole } from '../../utils/helpers';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const ActionRequired = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(false);
  const [organization, setOrganization] = useState<Parse.Object | null>(null);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [currentUsers, setCurrentUsers] = useState(0);
  const [allUsers, setAllUsers] = useState<UserModel[]>([]);
  const [selectionMode, setSelectionMode] = useState('retain');
  const [selectedUsers, setSelectedUsers] = useState<UserModel[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const addToSelectedUsers = (user: UserModel) => {
    let temp = [...selectedUsers];
    temp.push(user);
    setSelectedUsers(temp);
  };

  const removeFromSelectedUsers = (user: UserModel) => {
    let temp = [...selectedUsers];
    temp = temp.filter((u) => u.objectId !== user.objectId);
    setSelectedUsers(temp);
  };

  const readyToContinue = () => {
    if (selectionMode === 'retain') {
      return selectedUsers.length <= organization?.get('memberCount') - 1;
    } else {
      return selectedUsers.length >= currentUsers - organization?.get('memberCount') + 1;
    }
  };

  const isCorrectRetention = () => {
    const containsExecutives = selectedUsers.some((u) => u.role === UserRole.executive);
    if (containsExecutives) {
      return selectedUsers.some((u) => u.role === UserRole.manager);
    }
  };

  const isCorrectOffboarding = () => {
    let retainedUsers: UserModel[] = [];
    for (let i = 0; i < allUsers.length; i++) {
      if (!selectedUsers.includes(allUsers[i])) {
        retainedUsers.push(allUsers[i]);
      }
    }
    const containsExecutives = retainedUsers.some((u) => u.role === UserRole.executive);
    if (containsExecutives) {
      return retainedUsers.some((u) => u.role === UserRole.manager);
    }
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        if (currentUser.get('role') === UserRole.admin) {
          setHasPermission(true);
        }
        const currentOrganization = await userRepository.getCurrentOrganization();
        if (currentOrganization) {
          setOrganization(currentOrganization);
          setName(currentOrganization.get('admin').get('firstName') + ' ' + currentOrganization.get('admin').get('lastName'));
          if (currentOrganization.get('actionRequired') == null) {
            setLoading(false);
            setError('Your company does not have any required actions. Taking you to your Property Cloud dashboard...');
            setTimeout(() => {
              navigate('/dashboard');
            }, 6000);
          } else {
            const users = await userRepository.getAllUsersForOrganizationIncludingCurrentUser();
            if (users) {
              setAllUsers(users);
              const organizationRepository = new OrganizationRepository();
              const existingUsers = await organizationRepository.getSignedUpMembers();
              if (existingUsers) {
                // setCurrentUsers(existingUsers.existing);
                setCurrentUsers(150);
                setLoading(false);
              } else {
                setLoading(false);
                setError('Error fetching your company details. Taking you back to login...');
                const logout = await userRepository.logout();
                if (logout) {
                  dispatch(resetUserData());
                  dispatch(setCloseSettings());
                }
                setTimeout(() => {
                  navigate('/login');
                }, 6000);
              }
            } else {
              setLoading(false);
              setError('Error fetching your company details. Taking you back to login...');
              const logout = await userRepository.logout();
              if (logout) {
                dispatch(resetUserData());
                dispatch(setCloseSettings());
              }
              setTimeout(() => {
                navigate('/login');
              }, 6000);
            }
          }
        } else {
          setLoading(false);
          setError('Error fetching your company details. Taking you back to login...');
          const logout = await userRepository.logout();
          if (logout) {
            dispatch(resetUserData());
            dispatch(setCloseSettings());
          }
          setTimeout(() => {
            navigate('/login');
          }, 6000);
        }
      } else {
        setLoading(false);
        setError('Error fetching your company details. Taking you back to login...');
        const logout = await userRepository.logout();
        if (logout) {
          dispatch(resetUserData());
          dispatch(setCloseSettings());
        }
        setTimeout(() => {
          navigate('/login');
        }, 6000);
      }
    };

    fetchOrganization();
  }, []);

  useEffect(() => {
    if (selectAll) {
      let temp = allUsers.filter((u) => u.role !== UserRole.admin);
      setSelectedUsers(temp);
    } else {
      setSelectedUsers([]);
    }
  }, [selectAll]);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : error !== '' ? (
        <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'2rem'}>
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {error}
          </Typography>
        </Box>
      ) : hasPermission ? (
        <Box width={'100%'} minHeight={'100vh'}>
          <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} color={globalColors.white} padding={'2rem'}>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const userRepository = new UserRepository();
                  const logout = await userRepository.logout();
                  if (logout) {
                    dispatch(resetUserData());
                    dispatch(setCloseSettings());
                    navigate('/login');
                  }
                }}
              />
              <Typography
                fontSize={'12px'}
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const userRepository = new UserRepository();
                  const logout = await userRepository.logout();
                  if (logout) {
                    dispatch(resetUserData());
                    dispatch(setCloseSettings());
                    navigate('/login');
                  }
                }}
              >
                Logout
              </Typography>
            </Grid>
            {readyToContinue() && (
              <Grid item display={'flex'} gap={'5px'} justifyContent={'end'} alignItems={'center'} zIndex={9999999} xs={4}>
                <Typography
                  fontSize={'12px'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (selectionMode === 'retain') {
                      if (isCorrectRetention()) {
                        const organizationRepository = new OrganizationRepository();
                        //TODO: WRITE THE LOGIC
                      } else {
                        Toast('Please select at least one manager to retain, to be able to retain any executives.', 'error');
                      }
                    } else {
                      if (isCorrectOffboarding()) {
                      } else {
                        Toast('Please unselect at least one manager to offboard, as your company must have at least one manager.', 'error');
                      }
                    }
                  }}
                >
                  Continue
                </Typography>
                <ArrowForward
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (selectionMode === 'retain') {
                      if (isCorrectRetention()) {
                      } else {
                        Toast('Please select at least one manager to retain, to be able to retain any executives.', 'error');
                      }
                    } else {
                      if (isCorrectOffboarding()) {
                      } else {
                        Toast('Please unselect at least one manager to offboard, as your company must have at least one manager.', 'error');
                      }
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={'2rem'} padding={'3rem'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxWidth={'md'} flexDirection={'column'} gap={'1rem'}>
              <Typography variant="h6" textAlign={'center'} sx={{ color: globalColors.white }}>
                Hi <span style={{ color: globalColors.mainBlue }}>{name}</span>,<br />
                please select the users you want to retain or offboard to regain your access to the{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue, whiteSpace: 'nowrap' }}>Property Cloud</span> network.
              </Typography>
              <Typography textAlign={'center'} sx={{ color: globalColors.gray }}>
                You are seeing this because the number of your current users (<span style={{ color: globalColors.mainBlue }}>{currentUsers}</span>) is
                greater than your selected user limit{' '}
                <span style={{ whiteSpace: 'nowrap' }}>
                  (upto <span style={{ color: globalColors.mainBlue }}>{organization?.get('memberCount')}</span> users)
                </span>
              </Typography>
              {/* <Typography textAlign={'center'} sx={{ color: globalColors.gray }} zIndex={999999}>
                If you want to upgrade your plan, you can do so by clicking{' '}
                <span
                  onClick={() => {
                    navigate('/renewal/changePlan');
                  }}
                  style={{ fontWeight: 'bold', color: globalColors.mainBlue, textDecoration: 'underline', cursor: 'pointer', zIndex: 999999 }}
                >
                  here
                </span>
                .
              </Typography> */}
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'} gap={'2rem'} zIndex={9999999}>
            <Box
              sx={{
                padding: '20px',
                borderRadius: '15px',
                backgroundColor: selectionMode === 'retain' ? globalColors.mainBlue : 'transparent',
                color: globalColors.white,
                border: `1px solid ${globalColors.mainBlue}`,
                cursor: 'pointer',
                zIndex: 9999999,
              }}
              onClick={() => {
                setSelectionMode('retain');
              }}
            >
              <Typography variant="h6" textTransform={'uppercase'} fontSize={'16px'}>
                Retain
              </Typography>
            </Box>
            <Box
              sx={{
                padding: '20px',
                borderRadius: '15px',
                backgroundColor: selectionMode === 'offboard' ? globalColors.red : 'transparent',
                color: globalColors.white,
                border: `1px solid ${globalColors.red}`,
                cursor: 'pointer',
                zIndex: 9999999,
              }}
              onClick={() => {
                setSelectionMode('offboard');
              }}
            >
              <Typography variant="h6" textTransform={'uppercase'} fontSize={'16px'}>
                Offboard
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} maxWidth={'sm'} margin={'auto'} justifyContent={'space-between'} marginTop={'3rem'} width={'100%'}>
            <Box display={'flex'} gap={'1rem'} zIndex={9999999}>
              {selectAll ? (
                <CheckBoxIcon
                  sx={{ color: globalColors.mainBlue, cursor: 'pointer' }}
                  onClick={() => {
                    setSelectAll(false);
                  }}
                />
              ) : (
                <CheckBoxOutlineBlank
                  sx={{
                    color: globalColors.mainBlue,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSelectAll(true);
                  }}
                />
              )}
              <Typography>Select All</Typography>
            </Box>
            <Typography>
              Selected:{' '}
              {selectionMode === 'retain'
                ? `${selectedUsers.length + 1} / ${organization?.get('memberCount')}`
                : `${selectedUsers.length} / ${currentUsers - organization?.get('memberCount') + 1}`}
            </Typography>
          </Box>
          <Box display={'flex'} maxWidth={'sm'} margin={'auto'} justifyContent={'center'} marginTop={'3rem'} gap={'1rem'}>
            {allUsers.map((user, idx) => {
              return (
                <Box display={'flex'} zIndex={9999999} justifyContent={'center'} width={'100%'} gap={'2rem'}>
                  {user.role === UserRole.admin ? (
                    selectionMode === 'retain' ? (
                      <CheckBoxIcon sx={{ color: globalColors.gray }} />
                    ) : (
                      <CheckBoxOutlineBlank
                        sx={{
                          color: globalColors.gray,
                        }}
                      />
                    )
                  ) : selectedUsers.includes(user) ? (
                    <CheckBoxIcon
                      sx={{ color: globalColors.mainBlue, cursor: 'pointer' }}
                      onClick={() => {
                        removeFromSelectedUsers(user);
                      }}
                    />
                  ) : (
                    <CheckBoxOutlineBlank
                      sx={{
                        color: globalColors.mainBlue,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        addToSelectedUsers(user);
                      }}
                    />
                  )}

                  <Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
                    <Typography>
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography color={globalColors.mainBlue}>{formatRole(user.role ?? '')}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <NoPermission />
      )}
    </Box>
  );
};

export default ActionRequired;
