import { Box, Divider, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { globalColors } from '../../../../utils/constants/color';
import { InputStyle, NameInput, commentContainerStyle, crossFileContainerStyle } from './element.style';
import { Button, FieldInput, FieldInputTextarea, Toast } from '../../../atoms';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import EmployeeDropdown from './employeeDropdown';
import ClearIcon from '@mui/icons-material/Clear';
import AttachmentUpload from './attachmentUpload';
import FileIcon from './FileIcon';
import { Form, Formik, FormikHelpers } from 'formik';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dropdown from '../../../atoms/dropdown';
import Dropdowndata from '../../../atoms/dropdown2';
import { UserRole } from '../../../../store/user';
import SalesExecutiveRepository from '../../../../utils/api/repositories/salesExecutiveRepository';
import TaskRepository from '../../../../utils/api/repositories/taskRepository';
import TaskModel from '../../../../utils/api/models/taskModel';
import { useDispatch } from 'react-redux';
import { refreshTasks } from '../../../../store/slices';
import { hasPermissionForAction } from '../../../../utils/helpers';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';

interface ModalDetailLeadProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
}

interface ValuesCreateTask {
  title: string;
  description: string;
  startDate: string;
  dueDate: string;
  priority: string;
  assignTo: string;
}

type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

const CreateTaskModal = ({ open, onClose, mode }: ModalDetailLeadProps) => {
  const [startDate, setStartDate] = useState(dayjs());
  const [dueDate, setDueDate] = useState<Dayjs | null>(null);
  const [managersandSalesExecutive, setManagersandSalesExecutive] = useState<ManagerSalesExecutive[]>([]);
  const dispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '728px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
      height: '600px',
    },
  };

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser?.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i]?.get('firstName'),
              lastName: managersAndSalesExecutives[i]?.get('lastName'),
              user: managersAndSalesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i]?.get('user').id,
              firstName: salesExecutives[i]?.get('user')?.get('firstName'),
              lastName: salesExecutives[i]?.get('user')?.get('lastName'),
              user: salesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser?.get('firstName'),
          lastName: currentUser?.get('lastName'),
          user: currentUser,
        };
        setManagersandSalesExecutive([userData]);
      }
    }
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Tasks', 'Create'));
    getManagersandSalesExecutive();
    setLoading(false);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      {loading ? (
        <Box sx={style}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <Box sx={style} className="content" display={'flex'} flexDirection={'column'} width={'100%'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '30px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            Create a New Task
          </Typography>
          <Box sx={{ width: '100%', marginTop: '10px', borderBottom: `1px solid ${globalColors.background.gray}` }}></Box>

          <Formik
            initialValues={{
              title: '',
              description: '',
              startDate: '',
              dueDate: '',
              priority: '',
              assignTo: '',
            }}
            validate={(values) => {
              const errors = {} as ValuesCreateTask;

              if (!values.title || !values.title.trim()) {
                errors.title = 'Required';
              }

              if (!values.priority || values.priority === 'Select') {
                errors.priority = 'Required';
              }

              if (!values.assignTo) {
                errors.assignTo = 'Required';
              }

              return errors;
            }}
            onSubmit={async (values: ValuesCreateTask, { setSubmitting, resetForm }: FormikHelpers<ValuesCreateTask>) => {
              const taskRepository = new TaskRepository();
              let due = dueDate ? dueDate.toDate() : null;
              if (due) {
                due.setUTCHours(0);
                due.setUTCMinutes(0);
                due.setUTCSeconds(0);
                due.setUTCMilliseconds(0);
              }
              const task: TaskModel = {
                title: values.title,
                description: values.description,
                dueDate: due,
                priority: values.priority,
                assignedTo: values.assignTo,
              };
              const savedTask = await taskRepository.create(task);
              if (savedTask) {
                resetForm();
                Toast('Task has been created and assigned successfully.', 'success');
                dispatch(refreshTasks(true));
              } else {
                Toast('Task could not be created successfully. Please try again', 'error');
              }
              onClose();
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
              <Form>
                <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
                  <FieldInput
                    optional={false}
                    label="Title"
                    id="title"
                    mode={mode}
                    name={'title'}
                    placeholder="Title"
                    type="text"
                    error={touched.title && errors.title ? errors.title : false}
                  />
                  <FieldInputTextarea
                    className="col"
                    placeholder="Description"
                    name="description"
                    id="description"
                    label="Description"
                    value={values.description}
                    onChange={(e) => {
                      values.description = e.target.value;
                    }}
                  />
                  <Grid container display={'flex'} alignItems={'start'} justifyContent={'start'} rowSpacing={'1.5rem'}>
                    {/* <Grid item xs={12} md={4}>
                    <Box width={'100%'} paddingRight={{ xs: 0, md: '10px' }}>
                      <Typography mb="1rem" color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize="1rem">
                        Start
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          sx={{
                            width: '100%',
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                            borderRadius: '12px',
                            border: `2px solid ${globalColors.border.gray}`,
                            padding: '6px',
                          }}
                          format="DD/MM/YYYY"
                          className="customDatePicker"
                          defaultValue={dayjs(new Date())}
                          onChange={(newDate: any) => setStartDate(dayjs(newDate))}
                          minDate={dayjs(new Date())}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid> */}
                    <Grid item xs={12} md={6}>
                      <Box width={'100%'} paddingRight={{ xs: 0, md: '10px' }}>
                        <Typography mb="1rem" color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize="1rem">
                          Due
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            sx={{
                              minWidth: '100%',
                              color: mode === 'dark' ? globalColors.white : globalColors.black,
                              backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                              borderRadius: '12px',
                              border: `2px solid ${globalColors.border.gray}`,
                              padding: '6px',
                              '.input': {
                                fontSize: '13px',
                              },
                            }}
                            format="DD/MM/YYYY"
                            onChange={(newDate: any) => setDueDate(dayjs(newDate))}
                            minDate={dayjs(new Date())}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box width={'100%'} paddingRight={{ xs: 0, md: '10px' }}>
                        <Dropdown
                          label="Priority"
                          mode={mode}
                          id="priority"
                          name="priority"
                          optional={false}
                          values={['Select', 'Very Hot', 'Hot', 'Moderate', 'Cold']}
                          defaultValue="Select"
                          error={touched.priority && errors.priority ? errors.priority : false}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Box>
                    <Dropdowndata
                      label="Assign To"
                      id="assignTo"
                      name="assignTo"
                      optional={false}
                      mode={mode}
                      onChange={(e) => {
                        setFieldValue('assignTo', e.target.value);
                      }}
                      values={[
                        { label: `Select`, value: '' },
                        ...managersandSalesExecutive.map((user) => ({
                          label: `${user.firstName} ${user.lastName}`,
                          value: user.id,
                        })),
                      ]}
                      defaultValue=""
                    />
                    <StyledTypography>{touched.assignTo && errors.assignTo ? errors.assignTo : ''}</StyledTypography>
                  </Box>

                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Create'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      ) : (
        <Box sx={style}>
          <NoPermission size="small" />
        </Box>
      )}
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '48px',
  background:
    color !== 'default' && color !== ''
      ? color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.blackLight
        : globalColors.whiteLight
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
    marginTop: '5px',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '10px',
}));

export default CreateTaskModal;
