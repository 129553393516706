import Parse from 'parse';
import DemoModel from '../models/demoModel';
import { Toast } from '../../../components/atoms';
import SupportRequestModel from '../models/supportRequestModel';
import UserRepository from './userRepository';

class SupportRequestRepository {
  private className = 'SupportRequest';
  
  public async create(object: SupportRequestModel) {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const SupportRequest = Parse.Object.extend(this.className);
        const supportRequest = new SupportRequest();
  
        supportRequest.set('type', object.type);
        supportRequest.set('user', currentUser);
        supportRequest.set('organization', currentUser.get('organization'));
        supportRequest.set('category', object.category);
        supportRequest.set('message', object.message);
        supportRequest.set('contactDate', object.contactDate);
        supportRequest.set('callBetween', object.callBetween);
  
        return new Promise((resolve, _) => {
          supportRequest
            .save(null, { useMasterKey: true })
            .then((savedRequest: Parse.Object | null) => {
              resolve(savedRequest);
            })
            .catch((error: Error) => {
              resolve(error);
            });
        });
      }
      else {
        return null;
      }
    } catch (error) {
      console.error('Error creating demo:', error);
      return null;
    }
  }
}
export default SupportRequestRepository;
