import Home from '../../assets/Icons/Home_Icon.svg';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { globalColors } from '../../utils/constants/color';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { HiBuildingLibrary } from 'react-icons/hi2';
import { FaUsers } from 'react-icons/fa';
import { useState } from 'react';
import { MdDashboard } from 'react-icons/md';
import { Drawer } from '@mui/material';
import { selectColor, selectThemeMode } from '../../store/selector';

const UpperSidebar = () => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const [active_item, setActive_item] = useState(false);
  const navigate = useNavigate();
  return (
    <Drawer
      variant="permanent"
      open={true}
      anchor={'top'}
      // display={"flex"}
      // bgcolor={globalColors.black}
      // gap={"2px"}
    >
      <Box
        bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
        sx={{
          backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray,
          display: 'flex',
          width: 'fit-content',
          minWidth: '100%',
          // width: "100%",
          paddingTop: '70px',
          gap: '0px',
          paddingLeft: '50px',
        }}
      >
        <List
          sx={{
            verticalAlign: 'top',
            marginTop: 0,
          }}
        >
          <ListItem disablePadding sx={{ borderRight: `1px solid ${globalColors.border.gray}` }}>
            <ListItemButton sx={{ color: 'white' }} onClick={() => setActive_item((prev) => !prev)}>
              <ListItemIcon>
                <img src={Home} alt="" />
              </ListItemIcon>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '60px',
                }}
              >
                <ListItemText
                  style={{
                    color: color || globalColors?.blue,
                    fontWeight: 'bolder',
                    marginRight: 'auto',
                  }}
                  primary={'Home'}
                />
                <RiArrowDropDownFill
                  style={{
                    transform: active_item ? 'rotate(180deg)' : '',
                    color: color || '#0455C6',
                  }}
                  size={'2rem'}
                  color="#0455C6"
                />
              </Box>
            </ListItemButton>
          </ListItem>
          {active_item && (
            <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={'10px'}>
              <Box
                onClick={() => navigate('/dashboard')}
                display={'flex'}
                sx={{
                  fontWeight: 'bolder',
                  cursor: 'pointer',
                  alignItems: 'center',
                  backgroundColor: color || globalColors?.blue,
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  color: 'white',
                  borderRadius: '12px',
                  margin: '0px',
                  marginLeft: '20px',
                  marginRight: '20px',
                  gap: '10px',
                  paddingLeft: '20px',
                  border: '1px solid transparent',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${color || globalColors?.blue}`,
                    color: color || globalColors?.blue,
                  },
                }}
              >
                <MdDashboard size={'1.5rem'} />
                <ListItemText sx={{ textAlign: 'left' }} primary={'Dashboard'} />
              </Box>

              <Box
                display={'flex'}
                sx={{
                  fontWeight: 'bolder',
                  cursor: 'pointer',
                  alignItems: 'center',
                  backgroundColor: color || globalColors?.blue,
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  color: 'white',
                  borderRadius: '12px',
                  margin: '0px',
                  marginLeft: '20px',
                  marginRight: '20px',
                  gap: '10px',
                  paddingLeft: '20px',
                  border: '1px solid transparent',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${color || globalColors?.blue}`,
                    color: color || globalColors?.blue,
                  },
                }}
              >
                <HiBuildingLibrary size={'1.5rem'} />
                <ListItemText sx={{ textAlign: 'left' }} primary={'Properties'} />
              </Box>

              <Box
                onClick={() => navigate('/leads')}
                display={'flex'}
                sx={{
                  fontWeight: 'bolder',
                  cursor: 'pointer',
                  alignItems: 'center',
                  backgroundColor: color || globalColors?.blue,
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  color: 'white',
                  borderRadius: '12px',
                  margin: '0px',
                  marginLeft: '20px',
                  marginRight: '20px',
                  gap: '10px',
                  paddingLeft: '20px',
                  border: '1px solid transparent',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: `1px solid ${color || globalColors?.blue}`,
                    color: color || globalColors?.blue,
                  },
                }}
              >
                <FaUsers size={'1.5rem'} />
                <ListItemText sx={{ textAlign: 'left' }} primary={'Leads'} />
              </Box>
            </Box>
          )}
        </List>
        <List sx={{ verticalAlign: 'top', marginTop: 0 }}>
          <ListItem disablePadding sx={{ borderRight: `1px solid ${globalColors.border.gray}` }}>
            <ListItemButton sx={{ color: 'white' }}>
              <ListItemIcon>
                <img src={Home} alt="" />
              </ListItemIcon>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '60px',
                }}
              >
                <ListItemText
                  style={{
                    color: color || globalColors?.blue,
                    fontWeight: 'bolder',
                    marginRight: 'auto',
                  }}
                  primary={'Leads'}
                />
                <RiArrowDropDownFill
                  style={{
                    transform: active_item ? 'rotate(180deg)' : '',
                    color: color || '#0455C6',
                  }}
                  size={'2rem'}
                  color="#0455C6"
                />
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
        <List sx={{ verticalAlign: 'top', marginTop: 0 }}>
          <ListItem disablePadding sx={{ borderRight: `1px solid ${globalColors.border.gray}` }}>
            <ListItemButton sx={{ color: 'white' }}>
              <ListItemIcon>
                <img src={Home} alt="" />
              </ListItemIcon>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '60px',
                }}
              >
                <ListItemText
                  style={{
                    color: color || globalColors?.blue,
                    fontWeight: 'bolder',
                    marginRight: 'auto',
                  }}
                  primary={'Properties'}
                />
                <RiArrowDropDownFill
                  style={{
                    transform: active_item ? 'rotate(180deg)' : '',
                    color: color || '#0455C6',
                  }}
                  size={'2rem'}
                  color="#0455C6"
                />
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
        <List sx={{ verticalAlign: 'top', marginTop: 0 }}>
          <ListItem disablePadding sx={{ borderRight: `1px solid ${globalColors.border.gray}` }}>
            <ListItemButton sx={{ color: 'white' }}>
              <ListItemIcon>
                <img src={Home} alt="" />
              </ListItemIcon>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '60px',
                }}
              >
                <ListItemText
                  style={{
                    color: color || globalColors?.blue,
                    fontWeight: 'bolder',
                    marginRight: 'auto',
                  }}
                  primary={'Calendar'}
                />
                <RiArrowDropDownFill
                  style={{
                    transform: active_item ? 'rotate(180deg)' : '',
                    color: color || '#0455C6',
                  }}
                  size={'2rem'}
                  color="#0455C6"
                />
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
        <List sx={{ verticalAlign: 'top', marginTop: 0 }}>
          <ListItem disablePadding sx={{ borderRight: `1px solid ${globalColors.border.gray}` }}>
            <ListItemButton sx={{ color: 'white' }}>
              <ListItemIcon>
                <img src={Home} alt="" />
              </ListItemIcon>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '60px',
                }}
              >
                <ListItemText
                  style={{
                    color: color || globalColors?.blue,
                    fontWeight: 'bolder',
                    marginRight: 'auto',
                  }}
                  primary={'Emails'}
                />
                <RiArrowDropDownFill
                  style={{
                    transform: active_item ? 'rotate(180deg)' : '',
                    color: color || '#0455C6',
                  }}
                  size={'2rem'}
                  color="#0455C6"
                />
              </Box>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default UpperSidebar;
