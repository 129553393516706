import { Box, Select, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { globalColors } from '../../../utils/constants/color';

const names: string[] = ['facebook', 'instagram'];
interface PostFilter {
  mode: string;
  selectedName: any;
  setSelectedName: any;
}

const Filter: React.FC<PostFilter> = ({ mode, selectedName, setSelectedName }) => {
  return (
    <Box>
      <Box
        bgcolor={mode === 'dark' ? globalColors?.black : globalColors.white}
        border={`1px solid ${mode === 'dark' && globalColors?.border.gray}`}
        display="flex"
        gap={'10px'}
        sx={{
          borderRadius: '30px',
          height: '40px',
          paddingLeft: '10px',
          marginTop: '0px',
        }}
      >
        <Typography margin={'auto'} fontSize={'12px'} color="#939393">
          Filter
        </Typography>
        <Select
          value={selectedName}
          onChange={(event) => setSelectedName(event.target.value)}
          sx={{
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            fontSize: '12px',
            '& .MuiSelect-icon': {
              color: mode === 'dark' ? globalColors.white : globalColors.black,
              backgroundColor: mode === 'dark' ? globalColors?.black : globalColors.white,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderStyle: 'none',
            },
          }}
        >
          {names.map((name) => (
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} key={name} value={name}>
              {`${name[0]?.toUpperCase()}${name.slice(1)?.toLowerCase()}`}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default Filter;
