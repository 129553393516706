import React, { useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../store/selector';
import { styled } from '@mui/styles';
import LeadDropdown from '../../components/atoms/leadsDropdown';
import LeadInput from '../../components/atoms/leadInput';
import { Button, FieldInput, FieldInputUnit, Toast } from '../../components/atoms';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import { MdAddCircle, MdAddCircleOutline, MdDelete } from 'react-icons/md';
import { Field, Form, Formik } from 'formik';

export interface CustomField {
  label: string;
  value: string;
}

interface CustomFieldsProps {
  onChange: (fields: CustomField[]) => void;
  initialFields?: CustomField[] | null;
}

const CustomFields = ({ onChange, initialFields }: CustomFieldsProps) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [fields, setFields] = useState<CustomField[]>(initialFields && initialFields.length > 0 ? initialFields : [{ label: '', value: '' }]);

  const handleAddFields = () => {
    setFields((prevFields) => [...prevFields, { label: '', value: '' }]);
  };

  useEffect(() => {
    onChange(fields);
  }, [fields]);

  return (
    <Box width={'100%'} paddingY={1}>
      <Formik enableReinitialize initialValues={{}} validate={(values) => {}} onSubmit={async (values, { resetForm, setSubmitting }) => {}}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: { xs: 'center', md: 'end' },
                width: '100%',
              }}
            >
              <Box display={'flex'} width={'100%'} flexDirection={'column'} gap={'2rem'} alignItems={'start'}>
                {fields.map((field, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: { xs: 'center', md: 'start' },
                      gap: '2rem',
                      width: '100%',
                    }}
                  >
                    <Box display={'flex'} width={'100%'} flexDirection={'column'} gap="10px" alignItems={'center'}>
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                        <Typography fontSize={'16px'} fontWeight={500} color={globalColors.text.secondary}>
                          Label
                        </Typography>
                        {index !== 0 && (
                          <MdDelete
                            size={'1.5rem'}
                            color={globalColors.red}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let newfields = [...fields];
                              newfields.splice(index, 1);
                              setFields(newfields);
                            }}
                          />
                        )}
                      </Box>
                      {/* <FieldInput
                        label="Label"
                        id={`label${index}`}
                        name={`label${index}`}
                        mode={mode}
                        type="text"
                        placeholder="Label"
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index].label = e.target.value;
                          setFields(newFields);
                        }}
                      /> */}
                      <Field
                        className="col"
                        placeholder="Label"
                        type="text"
                        id={`label${index}`}
                        name={`label${index}`}
                        as={FieldInputUnit}
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | SelectChangeEvent) => {
                          const newFields: CustomField[] = [];
                          for (let i = 0; i < fields.length; i++) {
                            if (i === index) {
                              newFields.push({ label: e.target.value, value: fields[i].value });
                            } else {
                              newFields.push({ label: fields[i].label, value: fields[i].value });
                            }
                          }
                          setFields(newFields);
                        }}
                        value={field.label}
                      />
                    </Box>
                    <Box display={'flex'} width={'100%'} flexDirection={'column'} gap="10px" alignItems={'center'}>
                      <Typography fontSize={'16px'} fontWeight={500} color={globalColors.text.secondary}>
                        Value
                      </Typography>
                      <Field
                        className="col"
                        placeholder="Value"
                        type="text"
                        id={`value${index}`}
                        name={`value${index}`}
                        as={FieldInputUnit}
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | SelectChangeEvent) => {
                          const newFields: CustomField[] = [];
                          for (let i = 0; i < fields.length; i++) {
                            if (i === index) {
                              newFields.push({ label: fields[i].label, value: e.target.value });
                            } else {
                              newFields.push({ label: fields[i].label, value: fields[i].value });
                            }
                          }
                          setFields(newFields);
                        }}
                        value={field.value}
                      />
                      {/* <FieldInput
                        label="Value"
                        id={`value${index}`}
                        name={`value${index}`}
                        mode={mode}
                        type="text"
                        placeholder="Value"
                        onChange={(e) => {
                          const newFields = [...fields];
                          newFields[index].value = e.target.value;
                          setFields(newFields);
                        }}
                      /> */}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box paddingTop={{ xs: '2rem', md: 0 }} paddingLeft={'1rem'}>
                <MdAddCircleOutline
                  color={!fields.every((field) => field.label && field.value) ? globalColors.gray : color || globalColors.blue}
                  size={'2rem'}
                  style={{ cursor: !fields.every((field) => field.label && field.value) ? 'auto' : 'pointer' }}
                  onClick={!fields.every((field) => field.label && field.value) ? () => {} : handleAddFields}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const AssignmentContainer = styled(Box)({
  // height: '272px',
  width: '100%',
});

export default CustomFields;
