import React, { useEffect } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography, AvatarGroup, Avatar, Grid, Divider } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddNew, selectColor, selectCommissionMode, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { Button, SearchInput } from '../../components/atoms';
import { useState } from 'react';
import NotFoundLottie from '../../components/atoms/notFound';
import AddEmployee from '../../components/molecules/settings/Commission/addEmployee';
import { setAddNew, setOpenSettings } from '../../store/routes';
import ContentTable from '../../components/molecules/settings/Commission/contentTable';
import FilterCommission from '../../components/molecules/settings/Commission/filterCommission';
import SortCommission from '../../components/molecules/settings/Commission/sortCommission';
import { EnumCommissionModes, onSwitchMode } from '../../store/commission';
import ButtonMode from '../../components/molecules/buttonMode';
import { useNavigate } from 'react-router-dom';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole } from '../../store/user';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import { PricingSchedules, PricingTiers, formatDate, getPaymentTiers } from '../../utils/helpers';
import CardPlanSelectable from '../../components/molecules/cardPlanSelectable';
import CardPlanShow from '../../components/molecules/cardPlanShow';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';

const initialPlan = getPaymentTiers();

const PaymentSettings = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<any[]>([{}]);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setsearch] = useState<string>('');
  const [show, setShow] = useState<string>('table');
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const dispatch = useDispatch();
  const navigation = useSelector(selectNavigationLayout);
  const navigate = useNavigate();
  const commissionMode = useSelector(selectCommissionMode);
  const [selectedMode, setSelectedMode] = useState<EnumCommissionModes | string>(commissionMode || EnumCommissionModes.Individual);
  const [hasPermission, setHasPermission] = useState(false);
  const [organization, setOrganization] = useState<Parse.Object | null>(null);
  const [payments, setPayments] = useState<Parse.Object[] | null>(null);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [discount, setDiscount] = useState(1);

  useEffect(() => {
    const fetchOrganization = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser?.get('role') === UserRole.admin) {
        setHasPermission(true);
      }
      const currentOrganization = await userRepository.getCurrentOrganization();
      if (currentOrganization) {
        setOrganization(currentOrganization);
        setSelectedPlan(currentOrganization.get('tier') === PricingTiers.Essential ? 0 : 1);
        setDiscount(currentOrganization.get('paymentCycle') === PricingSchedules.Annual ? 0.75 : 1);
        const organizationRepository = new OrganizationRepository();
        const organizationPayments = await organizationRepository.getOrganizationPayments(currentOrganization.id);
        if (organizationPayments) {
          setPayments(organizationPayments);
        }
      }
      setLoading(false);
    };

    fetchOrganization();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Settings / Payments'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission ? (
            <Box width={'100%'} padding={3}>
              {organization ? (
                <Grid container display={'flex'} rowGap={'2rem'}>
                  <Grid item xs={12} md={6} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'2rem'}>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={{ xs: 'center', md: 'start' }}
                      width={'100%'}
                      flexDirection={{ xs: 'column', md: 'row' }}
                      paddingBottom={'2rem'}
                      gap={'1rem'}
                    >
                      <Typography variant="h5" color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        Current Plan
                      </Typography>
                      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          gap={'10px'}
                          color={globalColors.gray}
                        >
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                            <span style={{ color: color || globalColors.blue }}>
                              {organization.get('paymentCycle')} {organization.get('tier')}
                            </span>
                          </Typography>
                          <Box sx={{ height: '20px', backgroundColor: globalColors.gray, width: '2px' }} />
                          <Box display={'flex'} flexDirection={'row'} alignItems={'end'}>
                            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize={'12px'}>
                              Upto&nbsp;
                            </Typography>
                            <Typography color={color || globalColors.blue}>{organization.get('memberCount')}</Typography>
                            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize={'12px'}>
                              &nbsp;users
                            </Typography>
                          </Box>
                        </Box>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                          <span style={{ color: color || globalColors.blue }}>
                            ${organization.get('perMonthAmount')}{' '}
                            <span style={{ color: mode === 'dark' ? globalColors.white : globalColors.black, fontSize: '12px' }}>per month</span>
                          </span>
                        </Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                          <span style={{ color: mode === 'dark' ? globalColors.white : globalColors.black, fontSize: '12px' }}>Next Due&nbsp;</span>
                          <span style={{ color: color || globalColors.blue }}>{formatDate(organization.get('nextPaymentDue'))} </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} width={'100%'} paddingBottom={'2rem'}>
                      <Button
                        type="button"
                        onClick={() => {
                          dispatch(setAddNew({ addNew: true, path: '/settings/payments' }));
                          navigate('/settings/payments/changePlan');
                        }}
                      >
                        Change Plan
                      </Button>
                    </Box>
                    <Box display={'flex'} justifyContent={{ xs: 'start', md: 'center' }} width={'100%'}>
                      <Typography variant="h6" color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        Payment History
                      </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'1rem'} width={'100%'}>
                      {payments?.map((payment, idx) => {
                        return (
                          <Box
                            sx={{
                              borderRadius: '15px',
                              backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                              padding: '20px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                          >
                            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                              ${payment.get('amount') / 100}
                              <br />
                              <span style={{ color: globalColors.gray, fontSize: '14px' }}>{payment.get('type')}</span>
                            </Typography>
                            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                              {formatDate(payment.get('createdAt'))}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                    paddingLeft={{ xs: 0, md: '4rem' }}
                    paddingRight={{ xs: 0, md: '4rem' }}
                  >
                    <CardPlanShow
                      modeSensitive
                      showAnimation={false}
                      title={initialPlan[selectedPlan].title}
                      content={initialPlan[selectedPlan].content}
                      extraContent={initialPlan[selectedPlan].extraContent}
                      price={organization.get('perMonthAmount')}
                      paymentCycle={organization.get('paymentCycle')}
                      index={1}
                    />
                  </Grid>
                </Grid>
              ) : (
                <NotFoundLottie
                  text="We couldn't fetch your company details right now. Please refresh the page and try again."
                  showButton={false}
                  buttonText=""
                  navigateTo=""
                />
              )}
            </Box>
          ) : (
            <NoPermission />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default PaymentSettings;
