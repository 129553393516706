import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import rootReducer from './rootReducers';

const useDataPersistConfig = {
  key: 'root',
  version: 1,
  storage,
};

// ! TODO : perf(code splitting) : It will be deleted ASAP once there is no code that has an impact due to code splitting
// const rootReducer = combineReducers({
//   UseData: persistReducer(useDataPersistConfig, appDataReducer),
// });

export const store = configureStore({
  reducer: persistReducer(useDataPersistConfig, rootReducer),
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types

export default persistor;
