import { Box, Typography } from '@mui/material';
import CardPropertyFinder from '../../molecules/cardPropertyFInder';
import Grid from '@mui/material/Grid';
import { globalColors } from '../../../utils/constants/color';

const Section4 = () => {
  const dummydata = [
    {
      title: 'Performance Analytics',
      paragraph: 'Detailed insights into leads, conversions, and revenue.',
    },
    {
      title: 'Property Management',
      paragraph: 'Comprehensive listings, images, and status tracking.',
    },
    {
      title: 'Communication Hub',
      paragraph: 'Unified email, task reminders, and scheduling.',
    },
    {
      title: 'Document Organization',
      paragraph: 'Folder-based document management with E-signatures.',
    },
    {
      title: 'Automation Workflows',
      paragraph: 'Automated lead nurturing and task assignment.',
    },
    {
      title: 'User Tracking & Performance Analysis',
      paragraph: 'Detailed insights into leads, conversions, and revenue.',
    },
    {
      title: 'Customizable Dashboards',
      paragraph: 'User-configurable layouts for tailored experiences.',
    },
    {
      title: 'Real-Time Insights',
      paragraph: 'Visual data representations for quick decision-making.',
    },
  ];
  return (
    <Box sx={{ padding: { lg: '5rem', xs: '3rem' } }}>
      <Typography
        whiteSpace={'normal'}
        sx={{
          fontSize: { xs: '20px', sm: '30px', lg: '50px' },
          
        }}
      >
        <Typography sx={{
          fontSize: { xs: '20px', sm: '30px', lg: '50px' },
          color: globalColors.blue
        }}>Work Smarter, Not Harder: </Typography>
        Streamline Your Workflow with Feature-Rich Property Cloud
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid marginTop={'3rem'} container spacing={2} rowSpacing={5}>
          {dummydata.map((val, index) => (
            <Grid item xs={12} md={6} lg={3}>
              <CardPropertyFinder title={val?.title} paragraph={val.paragraph} index={index} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Section4;
