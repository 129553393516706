import { SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ChangeCover } from '../../components/molecules/productDetail/fieldCover';
import {
  IFormDetailProperty,
  deleteCoverProperty,
  deleteImageProperty,
  resetFormProperty,
  updateCoverProperty,
  updateFieldProperty,
  updateImagesProperty,
  updateAttachments,
  updateCustomFields,
  changeUseDownPayment
  // updateProperty,
} from '../../store/property';
import PropertyModel, { Picture } from '../../utils/api/models/propertyModel';
import PropertyRepository from '../../utils/api/repositories/propertyRepository';
// import useProjects from '../project/useProjects';
import ProjectRepository from '../../utils/api/repositories/projectRepository';
import { Option } from '../../components/atoms/fieldSelectOption';
import { IImages } from '../../store/projects';
import { isArray, isFile } from '../../utils/helpers';
import { selectPropertyState } from '../../store/selector';
import { CustomField } from '../../components/molecules/customFields';

const useFormProperty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: objectId } = useParams();

  const propertyState = useSelector(selectPropertyState);
  const [optionProjects, setOptionProjects] = useState<Option[]>([]);

  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const field = e.target.name as keyof IFormDetailProperty;
    dispatch(updateFieldProperty({ field, value: e.target.value }));
  };

  const onChangeCover = (callback: ChangeCover) => {
    dispatch(updateCoverProperty({ value: callback }));
    return callback;
  };

  const onChangeCustomFields = (customFields: CustomField[]) => {
    dispatch(updateCustomFields({ customFields }));
    return customFields;
  }

  const onChangeImages = (newImages: IImages[]) => {
    dispatch(updateImagesProperty({ newImages }));
    return newImages;
  };

  const handleDeleteImage = (id: string) => {
    dispatch(deleteImageProperty({ id }));
  };

  const handleChangeUseDownPayment = (useDownPayment: boolean) => {
    dispatch(changeUseDownPayment({ useDownPayment }));
  };

  const onAddNewProperty = async (values: IFormDetailProperty) => {
    // dispatch(addNewProperty(values));
    const pictureFiles: File[] = [];

    if (values.images) {
      values.images.forEach((image) => {
        if (image.file) {
          pictureFiles.push(image.file);
        }
      });
    }
    const propertyRepository = new PropertyRepository();
    const property: PropertyModel = {
      name: values.name,
      coverPicture: values.cover.files[0],
      price: values.price,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      status: values.status,
      pictures: pictureFiles,
      amenities: values.amenities,
      contactSecondaryPhone: values.contactSecondaryPhone,
      country: values.country,
      state: values.state,
      city: values.city,
      address: values.address,
      description: values.description,
      notes: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: null,
      updatedBy: null,
      area: values.area,
      areaUnit: values.areaUnit,
      assignToProject: values.assignToProject,
      sellingPrice: values.sellingPrice,
      soldAt: values.soldAt ? new Date(values.soldAt) : new Date(),
      soldBy: values.soldBy,
      attachments: values.attachments,
      customFields: values.customFields?.filter((field) => field.label !== '' && field.value !== ''),
      downPayment: values.downPayment,
      useDownPayment: values.useDownPayment
    };

    await propertyRepository.create(property);

    navigate('/properties');
    dispatch(resetFormProperty());
  };

  const onUpdateProject = async (objectId: string, values: IFormDetailProperty) => {
    const pictures: Picture[] = [];

    let coverPicture = null;
    if (values.cover && isFile(values.cover.files[0])) {
      coverPicture = values.cover.files[0];
    }

    if (isArray(values.images) && values.images.length > 0) {
      values.images.forEach((image) => {
        if (isFile(image.file)) {
          pictures.push({
            file: image.file,
            id: image.id,
            url: image.url,
          });
        } else {
          pictures.push({
            file: null,
            id: image.id,
            url: image.url,
          });
        }
      });
    }

    const propertyRepository = new PropertyRepository();
    const property: PropertyModel = {
      name: values.name,
      coverPicture: coverPicture,
      price: values.price,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      status: values.status,
      pictures: pictures,
      amenities: values.amenities,
      contactSecondaryPhone: values.contactSecondaryPhone,
      country: values.country,
      state: values.state,
      city: values.city,
      address: values.address,
      description: values.description,
      notes: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: null,
      updatedBy: null,
      area: values.area,
      areaUnit: values.areaUnit,
      assignToProject: values.assignToProject,
      sellingPrice: values.sellingPrice,
      soldAt: values.soldAt ? new Date(values.soldAt) : new Date(),
      soldBy: values.soldBy,
      attachments: values.attachments,
      customFields: values.customFields?.filter((field) => field.label !== '' && field.value !== ''),
      downPayment: values.downPayment,
      useDownPayment: values.useDownPayment
    };

    await propertyRepository.updateByObjectId(objectId, property);

    navigate('/properties');
    dispatch(resetFormProperty());
  };

  const getOptionProjects = async () => {
    const projectRepostiroy = new ProjectRepository();
    const get = await projectRepostiroy.getAll();
    const options = [] as Option[];
    get.forEach((project) => {
      options.push({
        id: project.id,
        value: project.name,
      });
    });
    setOptionProjects(options);
  };

  useEffect(() => {
    getOptionProjects();

    return () => {
      if (!objectId) {
        dispatch(resetFormProperty());
      }
    };
  }, []);

  const handleDeleteCover = () => {
    dispatch(deleteCoverProperty());
  };

  const setAttachments = (files: File[]) => {
    dispatch(updateAttachments({files}));
  };

  return {
    formProperty: propertyState.formProperty,
    dispatch,
    onChangeFormInput,
    onChangeImages,
    onChangeCover,
    onChangeCustomFields,
    onAddNewProperty,
    onUpdateProject,
    optionProjects,
    handleDeleteCover,
    handleDeleteImage,
    setAttachments,
    handleChangeUseDownPayment
  };
};

export default useFormProperty;
