import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RouteState {
  data: any | null;
  openSettings: boolean;
  openSidebar: boolean;
}

const initialState: RouteState = {
  data: {
    addNew: false,
    path: '',
  },
  openSettings: false,
  openSidebar: true,
};

const RouteReducer = createSlice({
  name: 'Route',
  initialState,
  reducers: {
    setAddNew(state, action: PayloadAction<any>) {
      const values = action.payload;
      state.data.addNew = values.addNew;
      state.data.path = values.path;
    },
    setOpenSettings(state) {
      state.openSettings = !state.openSettings;
    },
    setCloseSettings(state) {
      state.openSettings = false;
    },
    setOpenSidebar(state) {
      state.openSidebar = !state.openSidebar;
    },

  },
});

export const { setAddNew, setOpenSettings, setCloseSettings, setOpenSidebar } = RouteReducer.actions;
export default RouteReducer.reducer;
