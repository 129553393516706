import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { selectInventoryFilter, selectInventoryState, selectInventoryloader, selectProjectFilter, selectProjectState, selectRefreshInventoryState, selectSearchInventory } from '../../store/selector';
import { setAddNew } from '../../store/routes';
import ProjectComponentRepository from '../../utils/api/repositories/projectComponentRepository';
import { EnumViews, onInventoryLoading, onShowModalBulk, onSwitchView, setDataInventory } from '../../store/inventory';

const useInventory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inventoryState = useSelector(selectInventoryState);
  const filterData = useSelector(selectInventoryFilter);
  const search = useSelector(selectSearchInventory);
  const refreshInventory = useSelector(selectRefreshInventoryState);
  const { id } = useParams();

  const handleCreateNewProject = () => {
    dispatch(setAddNew({ addNew: true, path: '/projects' }));
    navigate('/projects/new');
  };

  const handleSwitchView = () => {
    const viewActive = inventoryState.viewActive === EnumViews.Cards ? EnumViews.List : EnumViews.Cards;
    dispatch(onSwitchView(viewActive));
  };

  useEffect(() => {
    getAllData();
  }, [filterData.status, filterData.type, filterData.purpose, search]);

  useEffect(() => {
    getAllData();
  }, [refreshInventory]);

  useEffect(() => {
    getAllData();
  }, []);

  // useEffect(() => {
  //   document.title = 'Projects';
  // }, []);

  const getTotalCount = async () => {
    const projectComponentRepository = new ProjectComponentRepository();
    const totalCount = await projectComponentRepository.getAllCount(id, search, filterData.status, filterData.type, filterData.purpose);
    return totalCount;
  };

  const getAllData = async () => {
    dispatch(onInventoryLoading(true));
    const projectComponentRepository = new ProjectComponentRepository();
    const get = await projectComponentRepository.getAll(id, search, filterData.status, filterData.type, filterData.purpose);
    dispatch(setDataInventory(get));
    dispatch(onInventoryLoading(false));
    dispatch(onShowModalBulk(false));
  };

  const getMoreData = async (page: number) => {
    dispatch(onInventoryLoading(true));
    const projectComponentRepository = new ProjectComponentRepository();
    const get = await projectComponentRepository.getAll(id, search, filterData.status, filterData.type, filterData.purpose, page);
    dispatch(setDataInventory(get));
    dispatch(onInventoryLoading(false));
    dispatch(onShowModalBulk(false));
  };

  return {
    inventory: inventoryState.inventory,
    view: inventoryState.viewActive,
    handleSwitchView,
    handleCreateNewProject,
    getMoreData,
    getTotalCount,
    isLoading: inventoryState.isLoading,
  };
};

export default useInventory;
