import { Box, Typography } from '@mui/material';
import React, { forwardRef, useRef } from 'react';
import { globalColors } from '../../../utils/constants/color';
// import Counter from '../../molecules/counter';
import { useEffect, useState } from 'react';

interface CounterProps {
  initialValue: number;
  delay: number;
  unit: string;
  jumps: number;
}
interface ContentBoxProps {
  initialValue: number;
  delay: number;
  children: React.ReactNode;
  unit: string;
  jumps: number;
}

const Counter: React.FC<CounterProps> = ({ initialValue, unit, jumps }) => {
  const [counter, setCounter] = useState(0);
  const counterStartedRef = useRef(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const incrementCounter = () => {
      setCounter((prevCounter) => {
        if (prevCounter < initialValue) {
          return prevCounter + jumps;
        } else {
          clearInterval(intervalId);
          return prevCounter;
        }
      });
    };

    const handleScroll = () => {
      // Adjust this value
      const triggerPosition = window.innerHeight / 5;

      // Get the current scroll position
      const scrollPosition = window.scrollY;

      // Check if the user is within the trigger position
      const isWithinTriggerPosition = scrollPosition > triggerPosition;

      if (isWithinTriggerPosition && !counterStartedRef.current) {
        // Start the counter when scrolling to the specified position
        counterStartedRef.current = true;
        intervalId = setInterval(incrementCounter, 40);
      } else if (!isWithinTriggerPosition && counterStartedRef.current) {
        // Reset the counter when leaving the specified position
        counterStartedRef.current = false;
        setCounter(0);
        clearInterval(intervalId);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup:
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(intervalId);
    };
  }, [initialValue, counterStartedRef]);

  return (
    <Box display="flex">
      {counter}
      {unit}
    </Box>
  );
};

const ContentBox: React.FC<ContentBoxProps> = ({ initialValue, delay, children, unit, jumps }) => {
  return (
    <Box display="flex" flexDirection="column" gap="10px" alignItems={'center'} paddingBottom={{ xs: '30px', sm: '30px', lg: '0px', xl: '0px' }}>
      <Typography sx={{ fontSize: { xs: '20px', sm: '30px', lg: '40px' } }} textAlign="center">
        <Box display="flex">
          <Counter initialValue={initialValue} delay={delay} unit={unit} jumps={jumps} />
        </Box>
      </Typography>
      <div>{children}</div>
    </Box>
  );
};

const Section2 = forwardRef((props, ref) => {
  return (
    <Box ref={ref} sx={{ padding: { lg: '1rem 5rem 5rem 5rem', xs: '1rem 3rem 3rem 3rem' } }}>
      <Typography
        sx={{ fontSize: { lg: '50px', xs: '20px', sm: '30px' } }}
        whiteSpace={'pre-wrap'}
        data-aos="zoom-in"
        data-aos-offset="100"
        textAlign={'center'}
        alignItems={'center'}
        data-aos-easing="ease-in-sine"
      >
        The Property Cloud Network
      </Typography>
      <Box
        alignContent={'center'}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          border: '1px solid #599BF733',
          backgroundColor: globalColors.darkblack,
          borderRadius: '20px',
          padding: '2rem',
          marginTop: '3rem',
          alignItems: 'center',
          // marginRight: '5rem',
          color: 'white',
          justifyContent: { xs: 'center', sm: 'center', md: 'space-evenly', lg: 'space-evenly' },
        }}
      >
        <ContentBox initialValue={30} delay={2} unit={'+'} jumps={1}>
          <Typography
            display="flex"
            justifyContent="center"
            gap="2px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              fontSize: { xs: '12px', sm: '14px', lg: '16px' },
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
            textAlign="center"
          >
            Companies
          </Typography>
        </ContentBox>
        <ContentBox initialValue={800} delay={1} unit={'+'} jumps={40}>
          <Typography
            display="flex"
            justifyContent="center"
            gap="2px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              fontSize: { xs: '12px', sm: '14px', lg: '16px' },
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
            textAlign="center"
          >
            Active Users
          </Typography>
          {/* Additional content for the first box */}
        </ContentBox>
        <ContentBox initialValue={12} delay={2} unit={'+'} jumps={1}>
          <Typography
            display="flex"
            justifyContent="center"
            gap="2px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              fontSize: { xs: '12px', sm: '14px', lg: '16px' },
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
            textAlign="center"
          >
            Countries
          </Typography>
          {/* Additional content for the first box */}
        </ContentBox>
        <ContentBox initialValue={3} delay={2} unit={''} jumps={1}>
          <Typography
            display="flex"
            justifyContent="center"
            gap="2px"
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              fontSize: { xs: '12px', sm: '14px', lg: '16px' },
              transition: 'transform 0.3s ease',
              '&:hover': {
                transform: 'translateY(-10px)',
              },
            }}
            textAlign="center"
          >
            Continents
          </Typography>
          {/* Additional content for the first box */}
        </ContentBox>
      </Box>
    </Box>
  );
});

export default Section2;
