import React, { useEffect } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography, AvatarGroup, Avatar } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddNew,
  selectColor,
  selectEmailFilter,
  selectNavigationLayout,
  selectOrganizationTier,
  selectThemeMode,
  sortEmail,
} from '../../store/selector';
import { Button } from '../../components/atoms';

import { useState } from 'react';
import EmailTable from '../../components/organisms/email/emailTable';
import FilterEmail from '../../components/organisms/email/filter';
import SortTable from '../../components/organisms/email/sort';
import SendEmail from '../../components/organisms/email/sendEmail';
import EmailRepository from '../../utils/api/repositories/emailRepository';
import NotFoundLottie from '../../components/atoms/notFound';
import { setAddNew } from '../../store/routes';
import ScheduleEmail from '../../components/organisms/email/scheduleEmail';
import { useLocation, useNavigate } from 'react-router-dom';
import UserRepository from '../../utils/api/repositories/userRepository';
import { PricingTiers, hasPermissionForAction } from '../../utils/helpers';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import InsufficientPlan from '../../components/atoms/insufficientPlan';
import { FilterEmailDataFtn } from '../../store/slices';
import Paginator from '../../components/atoms/paginator';

const Email = () => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const organizationTier = useSelector(selectOrganizationTier);
  const [open, setOpen] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<any[]>([{}]);
  const [loading, setLoading] = useState<boolean>(true);
  const filterData = useSelector(selectEmailFilter);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(false);
  const [key, setKey] = useState<{ label: string; status: string } | null>(null);
  const sortData = useSelector(sortEmail);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(parseInt(page ?? '1'));

  const getTotalCount = async () => {
    const emailRepository = new EmailRepository();

    const totalCount = await emailRepository.getEmailsCount(filterData.from, filterData.to, filterData.date);
    return totalCount;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const organizationRepository = new OrganizationRepository();
      const mailKey = await organizationRepository.fetchMailerSendKey();
      if (mailKey) {
        setKey(mailKey);
      }
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        const repository = new EmailRepository();
        const templatesData = await repository.getEmails(filterData.from, filterData.to, filterData.date, sortData);
        setEmailData(templatesData);
      }
    } catch (error: any) {
      console.error('Error fetching emails:', error.message);
    }
    setLoading(false);
  };

  const fetchMoreData = async (page: number) => {
    try {
      setLoading(true);
      const organizationRepository = new OrganizationRepository();
      const mailKey = await organizationRepository.fetchMailerSendKey();
      if (mailKey) {
        setKey(mailKey);
      }
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        const repository = new EmailRepository();
        const templatesData = await repository.getEmails(filterData.from, filterData.to, filterData.date, sortData, page);
        setEmailData(templatesData);
      }
    } catch (error: any) {
      console.error('Error fetching emails:', error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Email', 'View'));
  }, []);

  useEffect(() => {
    fetchData();
  }, [sortData, filterData]);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Email'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission && organizationTier == PricingTiers.Professional ? (
            <Box width={'100%'} padding={3}>
              {
                <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} marginBottom={'10px'}>
                  <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      marginTop={'auto'}
                      marginBottom={'auto'}
                      color={mode === 'dark' ? globalColors?.white : globalColors.black}
                    >
                      Email
                    </Typography>
                  </Box>

                  {emailData.length > 0 && !loading && key !== null && (
                    <Box width={{ xs: '100%', md: 'auto' }}>
                      <Box
                        component={'span'}
                        display={'flex'}
                        gap={'20px'}
                        sx={{
                          flexDirection: {
                            xs: 'column',
                            sm: 'row',
                          },
                          width: {
                            xs: '100%',
                            md: 'auto',
                          },
                          marginTop: {
                            xs: '20px',
                            md: '0px',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: {
                              xs: 'flex',
                            },
                            gap: {
                              xs: '5px',
                              md: `15px`,
                            },
                          }}
                        >
                          {/* <Button
                            className="btn-add"
                            onClick={() => {
                              dispatch(setAddNew({ addNew: true, path: '/email' }));
                              navigate('/email/schedule');
                            }}
                          >
                            Schedule Email
                          </Button> */}
                          <Button
                            className="btn-add"
                            onClick={() => {
                              dispatch(setAddNew({ addNew: true, path: '/email' }));
                              navigate('/email/new');
                            }}
                          >
                            Send New Email
                          </Button>
                        </Box>

                        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} gap={'20px'}>
                          <FilterEmail setOpen={setOpen} open={open} />
                          <SortTable mode={mode} />
                        </Box>
                      </Box>
                      <Box display={'flex'} justifyContent={'end'} marginTop={'20px'}>
                        <Button
                          className="btn-add"
                          onClick={() => {
                            dispatch(setAddNew({ addNew: true, path: '/email' }));
                            navigate('/email/setup');
                          }}
                        >
                          Configure
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {emailData.length == 0 && (
                    <Box width={{ xs: '100%', md: 'auto' }}>
                      <Box display={'flex'} justifyContent={'end'} marginTop={'20px'}>
                        <Button
                          className="btn-add"
                          onClick={() => {
                            dispatch(setAddNew({ addNew: true, path: '/email' }));
                            navigate('/email/setup');
                          }}
                        >
                          Configure
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              }
              <Typography
                sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
                onClick={() => {
                  dispatch(FilterEmailDataFtn({ from: '', to: '', date: '' }));
                }}
              >
                {filterData.date !== '' || filterData.from !== '' || filterData.to !== '' ? 'Clear Filter' : ''}
              </Typography>

              {!loading &&
                (key !== null ? (
                  emailData.length > 0 ? (
                    <>
                      <Box
                        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                        padding={'20px'}
                        borderRadius={'10px'}
                        color={globalColors.white}
                        fontSize={'12px'}
                        boxShadow={'#171717'}
                        marginTop={'1rem'}
                      >
                        <EmailTable fetchData={fetchData} emailData={emailData} />
                      </Box>
                      <Paginator
                        getTotalCount={getTotalCount}
                        onChangePage={async (newPage) => {
                          await fetchMoreData(newPage);
                          return true;
                        }}
                      />
                    </>
                  ) : !loading ? (
                    <NotFoundLottie
                      showButton={true}
                      text="Looks like you have not sent any emails yet. Press the button below and send a new email right now."
                      buttonText="Send New Email"
                      navigateTo="/email/new"
                      setAddNewPath="/email"
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <Box>
                    <NotFoundLottie
                      showButton={true}
                      text={
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} sx={{ textAlign: 'center' }} fontSize={'12px'}>
                          Looks like you have not set up your{' '}
                          <span
                            style={{ color: color || globalColors.blue, cursor: 'pointer' }}
                            onClick={() => {
                              window.open('https://www.mailersend.com/', '_blank');
                            }}
                          >
                            MailerSend
                          </span>{' '}
                          integration yet. Press the button below to start right now.
                        </Typography>
                      }
                      buttonText="Set Up Now"
                      navigateTo="/email/setup"
                      setAddNewPath="/email"
                    />
                  </Box>
                ))}
            </Box>
          ) : !hasPermission ? (
            <NoPermission />
          ) : (
            <InsufficientPlan />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Email;
