import type { View } from '@aldabil/react-scheduler/components/nav/Navigation';
import type { ProcessedEvent as ProcessedEventOri } from '@aldabil/react-scheduler/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { calendarEvents } from '../utils/api/dummies/calendar.data';

export type CalendarView = View;
export type ProcessedEvent = ProcessedEventOri;

export interface CalendarEvent {
  event_id: string | number;
  title: string;
  start: string;
  end: string;
  executive?: string;
  lead?: string;
  type?: string;
  color?: string;
}

interface CalendarState {
  view: CalendarView;
  event: CalendarEvent;
  listEvents: CalendarEvent[];
}

const initialState: CalendarState = {
  view: 'week',
  event: {
    title: '',
    executive: '',
    lead: '',
    type: '',
    event_id: '',
    start: '',
    end: '',
  },
  listEvents: calendarEvents,
};
const CalendarReducer = createSlice({
  name: 'Calendar',
  initialState,
  reducers: {
    // * Root project page
    onSwitchViewCalendar: (state, action: PayloadAction<CalendarView>) => {
      state.view = action.payload;
    },
    addNewEventCalendar(state, action: PayloadAction<CalendarEvent>) {
      state.listEvents.push(action.payload);
    },
  },
});

export const { onSwitchViewCalendar, addNewEventCalendar } = CalendarReducer.actions;
export default CalendarReducer.reducer;
