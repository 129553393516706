import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';
import { globalColors } from '../../utils/constants/color';
import { Mark } from '@mui/base';
import { useSelector } from 'react-redux';
import { selectColor } from '../../store/selector';

const Input = styled(MuiInput)`
  width: 42px;
`;

interface SliderProps {
  title: string;
  minValue: number;
  maxValue: number;
  step: number | null;
  value: number;
  setValue: (value: number) => void;
  valueColor: string;
  marks?: Mark[];
  showValue?: boolean;
  color?: string;
}

export default function InputSlider({ title, minValue, maxValue, step, value, setValue, valueColor, marks, showValue = true, color = globalColors.blue }: SliderProps) {
  // const [value, setValue] = React.useState(30);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  function valuetext(value: number) {
    return `${value}°C`;
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h6" gutterBottom>
        {showValue && <span style={{ color: valueColor }}>{value}</span> }
        {title}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        {/* <Grid item>
          <VolumeUp />
        </Grid> */}
        <Grid item xs>
          <Slider
            marks={marks}
            getAriaValueText={valuetext}
            value={typeof value === 'number' ? value : 0}
            min={minValue}
            max={maxValue}
            step={step}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            sx={{color: color}}
          />
        </Grid>
        {/* <Grid item>
          <Input
          sx={{color: globalColors.white}}
            value={value}
            size="small"
            // onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: { step },
              min: { minValue },
              max: { maxValue },
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
}
