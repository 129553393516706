import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LeadRepository from '../../utils/api/repositories/leadRepository';
import LeadModel from '../../utils/api/models/leadModel';
import LeadsEditForm from '../../components/organisms/leads/editLeadsForm';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { Toast } from '../../components/atoms';
import Loader from '../../components/atoms/loader';
import ClientsEditForm from '../../components/organisms/clients/editClientsForm';

const EditClients = () => {
  const [lead, setLead] = useState<LeadModel | null>(null);
  const [loading, setLoading] = useState(true);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const { id } = useParams();
  const navigate = useNavigate();

  const getAllLeads = async () => {
    const repository = new LeadRepository();
    if (id) {
      const leadModel = await repository.getModelById(id);
      if (leadModel) {
        setLead(leadModel);
        setLoading(false);
      } else {
        Toast('Lead details could not be fetched successfully. Please try again.', 'error');
        setTimeout(() => {
          navigate('/leads');
        }, 3000);
      }
    } else {
      Toast('Lead details could not be fetched successfully. Please try again.', 'error');
      setTimeout(() => {
        navigate('/leads');
      }, 3000);
    }
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text="Clients / Edit" />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Box height={'100%'} justifyContent={'center'} alignItems={'center'}>
              <Loader />
            </Box>
          ) : (
            <Box width={'100%'} padding={3}>
              <ClientsEditForm mode={mode} lead={lead} color={color} />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default EditClients;
