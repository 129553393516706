import { styled } from '@mui/material';
import React from 'react';
import { globalColors } from '../../utils/constants/color';

interface LabelInputProductProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  suffix?: string;
}
const LabelInputProduct = ({ children, className, id, suffix }: LabelInputProductProps) => {
  return (
    <StyledLabelInputProduct htmlFor={id} className={className}>
      {children}
      {suffix && <span className="suffix">{suffix}</span>}
    </StyledLabelInputProduct>
  );
};

const StyledLabelInputProduct = styled('label')(() => ({
  color: globalColors.text.secondary,
  fontSize: '16px',
  fontWeight: 500,

  '& .suffix': {
    fontSize: '10px',
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
}));

export default LabelInputProduct;
