import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import Loader from '../../components/atoms/loader';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import UpgradeRequestRepository from '../../utils/api/repositories/upgradeRequestRepository';
import { useDispatch } from 'react-redux';
import { setCloseSettings, setOpenSettings } from '../../store/routes';

const UpgradePaymentConfirmation = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const payment_intent = searchParams.get('payment_intent');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const paymentIntent = payment_intent ?? '';
      if (paymentIntent !== '') {
        const upgradeRequestRepository = new UpgradeRequestRepository();
        const paymentStatus = await upgradeRequestRepository.verifyPayment(paymentIntent);
        if (paymentStatus && paymentStatus === 'succeeded') {
          setLoading(false);
          setStatus('Your payment has been successfully processed. Taking you to your Property Cloud dashboard...');
          setTimeout(() => {
            dispatch(setCloseSettings());
            navigate('/dashboard');
          }, 8000);
        } else {
          setLoading(false);
          setStatus('Error fetching your payment details. Please select your preferences and come back again. Taking you back...');
          setTimeout(() => {
            navigate('/settings/payments/changePlan');
          }, 6000);
        }
      } else {
        setLoading(false);
        setStatus('Error fetching your payment details. Please select your preferences and come back again. Taking you back...');
        setTimeout(() => {
          navigate('/settings/payments/changePlan');
        }, 6000);
      }
    };

    fetchPaymentStatus();
  }, []);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : (
        <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'2rem'}>
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {status}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UpgradePaymentConfirmation;
