import { Box, Modal, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../utils/constants/color';
import Loader from '../../atoms/loader';
import NoPermission from '../../atoms/noPermission';
import { deleteConfirmationText, hasPermissionForAction } from '../../../utils/helpers';
import { Form, Formik, FormikHelpers } from 'formik';
import FieldInput from './fieldInput';
import UserRepository from '../../../utils/api/repositories/userRepository';

interface ModalDeleteLeadProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  onDelete: any;
  action?: {
    permissionName: 'Leads' | 'Properties' | 'Projects' | 'Email' | 'Social Media' | 'Tasks' | 'Lead / Client Payments';
    action: 'View' | 'Create' | 'Update' | 'Delete';
  };
}

interface DeleteValues {
  confirmation: string;
}

const ModalDeleteConfirm = ({ open, onClose, mode, color, onDelete, action }: ModalDeleteLeadProps) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [askDeleteConfirmation, setAskDeleteConfirmation] = useState(true);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    maxWidth: '525px',
    maxHeight: '360px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    // border: '2px solid #000',
    boxShadow: 5,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  const fetchDeleteConfirmation = async () => {
    setLoading(true);
    const repository = new UserRepository();
    const currentUser = repository.getCurrentUser();
    const organization = await repository.getCurrentOrganization();
    if (organization) {
      setAskDeleteConfirmation(organization.get('askDeleteConfirmation') ?? false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (action) {
      setHasPermission(hasPermissionForAction(action.permissionName, action.action));
    } else {
      setHasPermission(true);
    }
    fetchDeleteConfirmation();
  }, []);

  return (
    <Modal open={open} onClose={onClose} className="content">
      {loading ? (
        <Box sx={style}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <Box sx={style}>
          <Typography fontSize={'20px'} textAlign={'center'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
            Are you sure you want to proceed?
          </Typography>
          <Typography marginTop={'15px'} fontSize={'12px'} textAlign={'center'} color={mode === 'dark' ? globalColors.gray : globalColors.black}>
            Please note that this action would be irreversible, and you would not be able to access the deleted data.
          </Typography>
          <Box sx={{ paddingTop: '2rem' }}>
            {askDeleteConfirmation ? (
              <Formik
                initialValues={{
                  confirmation: '',
                }}
                validate={(values) => {
                  const errors = {} as DeleteValues;

                  if (values.confirmation !== deleteConfirmationText) {
                    errors.confirmation = "Please enter 'I am sure' in the field above to continue";
                  }

                  return errors;
                }}
                onSubmit={async (values: DeleteValues, { setSubmitting, resetForm }: FormikHelpers<DeleteValues>) => {
                  onDelete();
                  onClose();
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form>
                    <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
                      <FieldInput
                        optional={false}
                        label="Confirmation"
                        id="confirmation"
                        mode={mode}
                        name={'confirmation'}
                        placeholder="I am sure"
                        type="text"
                        error={touched.confirmation && errors.confirmation ? errors.confirmation : false}
                      />
                    </Box>
                    <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'2rem'}>
                      <Button
                        sx={{
                          border: `1px solid ${color || globalColors.blue}`,
                          color: mode == 'dark' ? globalColors.white : globalColors.black,
                          width: '100%',
                          borderRadius: '12px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          '&:hover': {
                            backgroundColor: color || globalColors.blue,
                          },
                        }}
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: globalColors.red,
                          color: globalColors.white,
                          width: '100%',
                          borderRadius: '12px',
                          '&:hover': {
                            backgroundColor: globalColors.red,
                          },
                        }}
                        type="submit"
                      >
                        Delete
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            ) : (
              <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'}>
                <Button
                  sx={{
                    border: `1px solid ${color || globalColors.blue}`,
                    color: mode == 'dark' ? globalColors.white : globalColors.black,
                    width: '100%',
                    borderRadius: '12px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '&:hover': {
                      backgroundColor: color || globalColors.blue,
                    },
                  }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    backgroundColor: globalColors.red,
                    color: globalColors.white,
                    width: '100%',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: globalColors.red,
                    },
                  }}
                  onClick={onDelete}
                >
                  Delete
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={style}>
          <NoPermission size="small" />
        </Box>
      )}
    </Modal>
  );
};

export default ModalDeleteConfirm;
