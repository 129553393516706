import { Box, Checkbox, Grid, Typography } from '@mui/material';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import {
  selectBulkEdit,
  selectColor,
  selectLeadState,
  selectLeadsFilter,
  selectNavigationLayout,
  selectRefreshConversionRequestsState,
  selectThemeMode,
} from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CompanyCard from './companyCards';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import { formatMoney, hasPermissionForAction } from '../../../../utils/helpers';
import ConversationsCard from './conversionsCardView';
import NotFoundLottie from '../../../atoms/notFound';
import LeadModel from '../../../../utils/api/models/leadModel';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { Button, SearchInput } from '../../../atoms';
import FilterLeads from '../../../organisms/leads/filterLeads';
import ButtonView from '../../buttonView';
import { EnumViews } from '../../../../store/projects';
import { useDispatch } from 'react-redux';
import { FilterDataFtn, onSwitchView } from '../../../../store/slices';
import Loader from '../../../atoms/loader';
import { UserRole } from '../../../../store/user';
import LeadsList from '../../../organisms/leads/leadsListView';
import LeadsCard from '../../../organisms/leads/leadsCardView';
import NoPermission from '../../../atoms/noPermission';
import { setAddNew } from '../../../../store/routes';
import { useNavigate } from 'react-router-dom';
import LeadConversionRequestRepository from '../../../../utils/api/repositories/leadConversionRequestRepository';
import ConversionRequestCard from './conversionRequestCard';
import LeadConversionRequestModel from '../../../../utils/api/models/leadConversionRequestModel';
import Paginator from '../../../atoms/paginator';

const ConversionRequests = () => {
  // Selectors
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const filterData = useSelector(selectLeadsFilter);
  const leadViewState = useSelector(selectLeadState);
  const edit = useSelector(selectBulkEdit);
  const requestsLoader = useSelector(selectRefreshConversionRequestsState);
  // User
  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();
  // States
  const [showLead, setshowLead] = useState<LeadModel[]>([]);
  const [conversionRequests, setConversionRequests] = useState<LeadConversionRequestModel[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setsearch] = useState<string>('');
  const [loading, setloading] = useState<boolean>(true);
  const [view, setview] = useState<EnumViews | string>(leadViewState || EnumViews.Cards);
  const [rowCheckboxState, setRowCheckboxState] = useState<{ [key: string]: boolean }>(Object.fromEntries(showLead.map((lead) => [lead.id, false])));
  const [openBulkEditLeadModal, setOpenBulkEditLeadModal] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState(false);

  const dispatch = useDispatch();

  const getTotalCount = async () => {
    const repository = new LeadConversionRequestRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const totalCount = await repository.getAllCount(search);

      return totalCount;
    }
    return 0;
  };

  const getAllLeads = async () => {
    setloading(true);
    const repository = new LeadConversionRequestRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }

    await repository
      .getAll(search)
      .then((res) => {
        setConversionRequests(res);
        let leadModels: LeadModel[] = [];
        for (let i = 0; i < res.length; i++) {
          const lead = res[i].lead;
          if (lead) {
            leadModels.push({
              id: lead.id,
              firstName: lead.get('firstName'),
              lastName: lead.get('lastName'),
              primaryPhone: lead.get('primaryPhone'),
              secondaryPhone: lead.get('secondaryPhone'),
              email: lead.get('email'),
              cnic: lead.get('cnic'),
              category: lead.get('category'),
              projectDetails: lead.get('projectDetails'),
              country: lead.get('country'),
              state: lead.get('state'),
              city: lead.get('city'),
              address: lead.get('address'),
              source: lead.get('source'),
              purpose: lead.get('purpose'),
              interestedIn: lead.get('interestedIn'),
              budgetFrom: lead.get('budgetFrom'),
              budgetTo: lead.get('budgetTo'),
              propertyProject:
                lead.get('category') === 'Property' ? lead.get('property') : lead.get('category') === 'Project' ? lead.get('project') : null,
              projectComponent: lead.get('projectComponent'),
              organization: lead.get('organization'),
              status: lead.get('status'),
              assignedTo: lead.get('assignedTo'),
              priority: lead.get('priority') || '-',
              paidAmount: lead.get('paidAmount') || '-',
              conversionTime: lead.get('conversionTime') || '-',
            });
          }
        }
        setshowLead(leadModels);
        setOpen(false);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };

  const getMoreLeads = async (page: number) => {
    setloading(true);
    const repository = new LeadConversionRequestRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }

    await repository
      .getAll(search, page)
      .then((res) => {
        setConversionRequests(res);
        let leadModels: LeadModel[] = [];
        for (let i = 0; i < res.length; i++) {
          const lead = res[i].lead;
          if (lead) {
            leadModels.push({
              id: lead.id,
              firstName: lead.get('firstName'),
              lastName: lead.get('lastName'),
              primaryPhone: lead.get('primaryPhone'),
              secondaryPhone: lead.get('secondaryPhone'),
              email: lead.get('email'),
              cnic: lead.get('cnic'),
              category: lead.get('category'),
              projectDetails: lead.get('projectDetails'),
              country: lead.get('country'),
              state: lead.get('state'),
              city: lead.get('city'),
              address: lead.get('address'),
              source: lead.get('source'),
              purpose: lead.get('purpose'),
              interestedIn: lead.get('interestedIn'),
              budgetFrom: lead.get('budgetFrom'),
              budgetTo: lead.get('budgetTo'),
              propertyProject:
                lead.get('category') === 'Property' ? lead.get('property') : lead.get('category') === 'Project' ? lead.get('project') : null,
              projectComponent: lead.get('projectComponent'),
              organization: lead.get('organization'),
              status: lead.get('status'),
              assignedTo: lead.get('assignedTo'),
              priority: lead.get('priority') || '-',
              paidAmount: lead.get('paidAmount') || '-',
              conversionTime: lead.get('conversionTime') || '-',
            });
          }
        }
        setshowLead(leadModels);
        setOpen(false);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const updatedRowCheckboxState = Object.fromEntries(showLead.map((lead) => [lead.id, checked]));
    setRowCheckboxState(updatedRowCheckboxState);
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  useEffect(() => {
    getAllLeads();
  }, [requestsLoader]);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Conversions / Requests'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        <Box width={'100%'} padding={3}>
          {hasPermission ? (
            <Box width={'100%'} paddingTop={3} paddingBottom={3}>
              <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} marginBottom={'10px'}>
                <Box></Box>
                <Box
                  component={'span'}
                  display={'flex'}
                  gap={'20px'}
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    width: {
                      xs: '100%',
                      md: 'auto',
                    },
                    marginTop: {
                      xs: '20px',
                      md: '0px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: 'flex',
                      },
                      gap: {
                        xs: '5px',
                        md: `15px`,
                      },
                    }}
                  >
                    {(showLead.length > 0 || search !== '') && (
                      <SearchInput
                        value={search}
                        onChange={(e) => {
                          setsearch(e.target.value);
                          setloading(true);
                        }}
                        className="input-search"
                        placeholder="Search Conversions"
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Typography
                sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
                onClick={() => dispatch(FilterDataFtn({ status: '', assignedTo: '', priority: '' }))}
              >
                {filterData.status !== '' || filterData.assignedTo !== '' || filterData.priority !== '' ? 'Clear Filter' : ''}
              </Typography>

              {loading ? (
                <Loader />
              ) : (
                <>
                  {showLead.length > 0 ? (
                    <>
                      {currentUser?.get('role') !== UserRole.executive && edit && (
                        <Checkbox
                          sx={{
                            color: color !== 'default' && color !== '' ? color : globalColors.blue,
                            '&.Mui-checked': {
                              color: color || globalColors.secondBlue,
                            },
                          }}
                          onChange={handleHeaderCheckboxChange}
                          checked={Object.values(rowCheckboxState).length > 0 && Object.values(rowCheckboxState).every((isChecked) => isChecked)}
                        />
                      )}

                      <Grid container spacing={2}>
                        {showLead.map((val, idx) => (
                          <Grid key={idx} item xs={12} md={6} lg={4}>
                            <ConversionRequestCard
                              mode={mode}
                              content={val}
                              requestContent={conversionRequests[idx]}
                              color={color !== 'default' && color !== '' ? color : ''}
                              setloading={setloading}
                              edit={edit}
                              rowCheckboxState={rowCheckboxState}
                              setRowCheckboxState={setRowCheckboxState}
                              setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                              openBulkEditLeadModal={openBulkEditLeadModal}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      <Paginator
                        getTotalCount={getTotalCount}
                        onChangePage={async (newPage) => {
                          await getMoreLeads(newPage);
                          return true;
                        }}
                      />
                    </>
                  ) : (
                    <>
                      {search !== '' || filterData.status !== '' || filterData.assignedTo !== '' ? (
                        <Typography
                          sx={{
                            color: color || globalColors.blue,
                            textAlign: 'center',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                          }}
                        >
                          No Conversion Request Found
                        </Typography>
                      ) : (
                        <NotFoundLottie
                          showButton={true}
                          text="Looks like you don't have any lead conversion requests. Press the button below and view your leads to convert them."
                          buttonText="View Leads"
                          navigateTo="/leads"
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          ) : loading ? (
            <Loader />
          ) : (
            <NoPermission />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversionRequests;
