import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDataProjects } from '../store/projects';
import { setDataProperties } from '../store/property';
import ProjectRepository from '../utils/api/repositories/projectRepository';
import PropertyRepository from '../utils/api/repositories/propertyRepository';
import { ProductCategories } from '../utils/types/products';
import ProjectComponentRepository from '../utils/api/repositories/projectComponentRepository';
import { setDataInventory } from '../store/inventory';

const useModalDeleteProduct = () => {
  const dispatch = useDispatch();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [productType, setProductType] = useState<ProductCategories>();
  const handleOpenModalDelete = (type: ProductCategories) => {
    setProductType(type);
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => setOpenModalDelete(false);

  const handleDelete = async (type: ProductCategories, id: string) => {
    let data = [];
    setLoadingModal(true);
    if (type === ProductCategories.Project) {
      const projectRepostiroy = new ProjectRepository();
      await projectRepostiroy.delete(id);
      data = await projectRepostiroy.getAll();
      dispatch(setDataProjects(data));
    } else if (type === ProductCategories.Property) {
      const propertyRepostitory = new PropertyRepository();
      await propertyRepostitory.delete(id);
      data = await propertyRepostitory.getAll();
      dispatch(setDataProperties(data));
      setLoadingModal(false);
    } else if (type === ProductCategories.ProjectComponent) {
      const projectComponentRepository = new ProjectComponentRepository();
      await projectComponentRepository.delete(id);
      data = await projectComponentRepository.getAll();
      dispatch(setDataInventory(data));
      setLoadingModal(false);
    }

    handleCloseModalDelete();
    setLoadingModal(false);
  };

  return { openModalDelete, handleOpenModalDelete, handleCloseModalDelete, loadingModal, productType, handleDelete };
};

export default useModalDeleteProduct;
