import { styled } from '@mui/material';
import { ICFlag } from '../../assets/Icons';
import { globalColors } from '../../utils/constants/color';

interface ButtonIconCircleProps {
  src?: string;
  alt?: string;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button' | undefined;
  component?: React.ReactNode;
}
const ButtonIconCircle = ({ src, alt, onClick, type, component }: ButtonIconCircleProps) => {
  return (
    <ButtonIconCircleStyle className="btn-circle" onClick={onClick} type={type}>
      {src && <img src={src} alt={alt} />}
      {component}
    </ButtonIconCircleStyle>
  );
};

const ButtonIconCircleStyle = styled('button')(({ theme }) => ({
  all: 'unset',
  background: globalColors.icon.whiteSmoke,
  // background: 'rgba(246, 246, 246, 1)',
  height: '32px',
  width: '32px',
  borderRadius: '100%',
  display: 'grid',
  placeItems: 'center',
  cursor: 'pointer',
  // opacity: '0.8',
  '&:hover': {
    opacity: '0.8',
  },
}));

export default ButtonIconCircle;