import { Typography, styled, Switch, SwitchProps, Box } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ICCoffe } from '../../assets/Icons';
// import { ICDopdownicon } from '../../assets/Icons';
import { EnumViews } from '../../store/projects';
import { useSelector } from 'react-redux';
import { globalColors } from '../../utils/constants/color';
import { selectColor, selectThemeMode } from '../../store/selector';

interface ButtonViewProps {
  onChangeView?: (view: EnumViews) => void;
  onClick?: () => void;
  className?: string;
  defaultView?: EnumViews | string;
  view: EnumViews | string;
}

const ButtonView = ({ onChangeView, onClick, className, defaultView, view }: ButtonViewProps) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [viewActive, setViewActive] = useState<EnumViews | string>(view || EnumViews.Cards);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [cardModeOn, setCardModeOn] = useState(view ? view === EnumViews.Cards : true);

  const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: globalColors.white,
        '& + .MuiSwitch-track': {
          backgroundColor: color || globalColors.blue,
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: color || globalColors.blue,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      background: ICCoffe,
      backgroundPosition: 'center',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: color || globalColors.blue,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const handleOpenPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onClickView = (view: EnumViews) => {
    if (view === viewActive) return handleClosePopover();

    if (onChangeView) {
      onChangeView(view);
    }
    setViewActive(view);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'view-popover' : undefined;

  return (
    <>
      {/* <ViewStyle className={className} aria-describedby={id} onClick={handleOpenPopover} mode={mode}>
        <span>View</span>
        <span>{viewActive}</span>
        {mode === 'dark' ? <img src={ICDropdown} alt="icon dropdown" /> : <img src={ICDopdownicon} alt="icon dropdown" />}
      </ViewStyle> */}
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Typography
          sx={{ fontWeight: 400, lineHeight: 1.5, fontSize: '12px', color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight }}
        >
          List
        </Typography>
        <IOSSwitch
          sx={{ m: 1 }}
          checked={cardModeOn}
          onChange={(e) => {
            if (cardModeOn) {
              onClickView(EnumViews.List);
            } else {
              onClickView(EnumViews.Cards);
            }
            setCardModeOn(!cardModeOn);
          }}
        />
        <Typography
          sx={{ fontWeight: 400, lineHeight: 1.5, fontSize: '12px', color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight }}
        >
          Grid
        </Typography>
      </Box>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            background: mode === 'dark' ? globalColors.blackLight : globalColors.white,
            top: {
              lg: '168px !important',
              md: '168px !important',
              sm: '230px !important',
              xs: '296px !important',
            },
          },
        }}
      >
        <PopoverStyle mode={mode}>
          <Typography onClick={() => onClickView(EnumViews.Cards)} className="view-option">
            Card
          </Typography>
          <Typography onClick={() => onClickView(EnumViews.List)} className="view-option list">
            List
          </Typography>
        </PopoverStyle>
      </Popover> */}
    </>
  );
};

// const ViewStyle = styled('button')<ViewStyleProps>(({ theme, mode }) => ({
//   all: 'unset',
//   cursor: 'pointer',
//   background: mode === 'dark' ? globalColors.blackLight : globalColors.white,
//   borderRadius: '12px',
//   display: 'flex',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   width: '131px',
//   minHeight: '42px',
//   height: '100%',
//   padding: '0 1rem',
//   boxSizing: 'border-box',
//   '& span': {
//     fontSize: '0.75rem',
//     color: globalColors.text.secondary,
//   },
//   '@media (max-width: 768px)': {
//     width: '100%',
//   },
//   '&:hover': {
//     opacity: '0.8',
//     '& span': {
//       color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
//     },
//   },
// }));

// const PopoverStyle = styled('div')<ViewStyleProps>(({ theme, mode }) => ({
//   '& .view-option': {
//     width: '165px',
//     background: mode === 'dark' ? globalColors.blackLight : globalColors.white,
//     color: globalColors.text.secondary,
//     cursor: 'pointer',
//     borderRadius: '12px',
//     padding: '4px 1rem',
//     '&:hover': {
//       background: globalColors.blue,
//       color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
//     },

//     '&.list': {
//       marginTop: '0.5rem',
//     },
//   },
// }));

export default ButtonView;
