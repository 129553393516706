import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../store/selector';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import Sidebar from '../sidebar';
import { drawerWidth } from '../../../utils/constants/drawerWidth';
import BasicAccordion from './leadsAccordion';

export interface FormValues {
  title: string;
  message: string;
  Attachment: any;
}

const LeadsInterest = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const theme = useTheme();

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Add Leads'} />
        </div>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
            {/* Para 1 */}
            <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                marginTop={'auto'}
                marginBottom={'auto'}
                color={mode === 'dark' ? globalColors?.white : globalColors.black}
              >
                Interests
              </Typography>
            </Box>
            <Box marginBottom={'10px'}></Box>
            <BasicAccordion />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default LeadsInterest;
