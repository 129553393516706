import { Box, Modal, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../utils/constants/color';
import Loader from '../../atoms/loader';
import Paginator from '../../atoms/paginator';
import LeadClientPaymentRepository from '../../../utils/api/repositories/leadClientPaymentRepository';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../store/user';
import LeadClientPaymentModel from '../../../utils/api/models/leadClientPaymentModel';
import NoPermission from '../../atoms/noPermission';
import NotFoundLottie from '../../atoms/notFound';
import { formatDate, formatMoney, hasPermissionForAction } from '../../../utils/helpers';
import { MdDelete } from 'react-icons/md';
import ModalDeleteConfirm from './modalDeleteConfirm';

interface ModalCommunicateProps {
  mode: string;
  color: string;
  id: string;
  data?: any;
}

const ModalPaymentHistory = ({ mode, color, id, data }: ModalCommunicateProps) => {
  const [paymentHistory, setPaymentHistory] = useState<LeadClientPaymentModel[]>([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [deletionPermission, setDeletionPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const getTotalCount = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser && currentUser.get('role') === UserRole.admin) {
      const leadClientPaymentRepository = new LeadClientPaymentRepository();
      const totalCount = await leadClientPaymentRepository.countByLeadId(id, currentUser.get('organization').id);
      return totalCount;
    }
    return 0;
  };

  const fetchPaymentHistory = async () => {
    setLoading(true);
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const leadClientPaymentRepository = new LeadClientPaymentRepository();
      const payments = await leadClientPaymentRepository.getByLeadId(id, currentUser.get('organization').id);
      if (payments) {
        setPaymentHistory(payments);
      }
    }
    setLoading(false);
  };

  const deletePayment = async () => {
    const leadClientPaymentRepository = new LeadClientPaymentRepository();
    await leadClientPaymentRepository.delete(deleteId);
    setOpenDeleteConfirm(false);
    fetchPaymentHistory();
  };

  const fetchMorePaymentHistory = async (page: number) => {
    setLoading(true);
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser && currentUser.get('role') === UserRole.admin) {
      const leadClientPaymentRepository = new LeadClientPaymentRepository();
      const payments = await leadClientPaymentRepository.getByLeadId(id, currentUser.get('organization').id, page);
      if (payments) {
        setPaymentHistory(payments);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Lead / Client Payments', 'View'));
    setDeletionPermission(hasPermissionForAction('Lead / Client Payments', 'Delete'));
    fetchPaymentHistory();
  }, []);

  return (
    <Box marginTop={'30px'} sx={{ overflow: 'auto', overflowX: 'scroll' }} display={'flex'} flexDirection={'column'} gap={'10px'} className="scroll">
      <Box className="content">
        {loading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%', gap: '1rem' }}>
            <Loader />
          </Box>
        ) : hasPermission ? (
          paymentHistory.length > 0 ? (
            <>
              {paymentHistory.map((payment, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      borderRadius: '8px',
                      backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                      padding: '8px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'end',
                      marginBottom: '8px',
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'} gap={'0.25rem'}>
                      <Typography sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                        {`${payment.nature} - ${payment.currency} ${payment.amount && formatMoney(payment.amount)}`}{' '}
                        <span style={{ fontSize: '12px', color: color || globalColors.blue }}>({payment.paymentMethod})</span>
                      </Typography>
                      {payment.property && (
                        <Typography sx={{ color: mode === 'dark' ? globalColors.text.secondary : globalColors.black, fontSize: '12px' }}>
                          Property&nbsp;
                          <span style={{ color: color || globalColors.blue }}>{payment.property?.get('name')}</span>
                        </Typography>
                      )}
                      {payment.project && (
                        <Typography sx={{ color: mode === 'dark' ? globalColors.text.secondary : globalColors.black, fontSize: '12px' }}>
                          Project&nbsp;
                          <span style={{ color: color || globalColors.blue }}>{payment.project?.get('name')}</span>
                        </Typography>
                      )}
                      {payment.projectComponent && (
                        <Typography sx={{ color: mode === 'dark' ? globalColors.text.secondary : globalColors.black, fontSize: '12px' }}>
                          Inventory&nbsp;
                          <span style={{ color: color || globalColors.blue }}>{payment.projectComponent?.get('name')}</span>
                        </Typography>
                      )}
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'end'} gap={'10px'}>
                      {deletionPermission && (
                        <MdDelete
                          onClick={() => {
                            setDeleteId(payment.objectId ?? '');
                            setOpenDeleteConfirm(true);
                          }}
                          size={'1.3rem'}
                          color={color || globalColors.blue}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                      <Typography sx={{ color: mode === 'dark' ? globalColors.text.secondary : globalColors.black, fontSize: '12px' }}>
                        {payment.createdAt && formatDate(payment.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
              <Paginator
                getTotalCount={getTotalCount}
                onChangePage={async (newPage) => {
                  await fetchMorePaymentHistory(newPage);
                  return true;
                }}
              />
              <ModalDeleteConfirm
                open={openDeleteConfirm}
                onClose={() => {
                  setOpenDeleteConfirm(false);
                }}
                mode={mode}
                color={color}
                onDelete={deletePayment}
                action={{ permissionName: 'Lead / Client Payments', action: 'Delete' }}
              />
            </>
          ) : (
            <NotFoundLottie
              text={'No payment history was found for this client. Convert this client to see their payment history.'}
              showButton={false}
              buttonText=""
              navigateTo=""
            />
          )
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

export default ModalPaymentHistory;
