import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { globalColors } from '../../../utils/constants/color';
import logo from '../../../assets/Images/logo.svg';
import { useNavigate } from 'react-router';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <Box bgcolor={globalColors.mainBackground} maxHeight={'100%'} width={'100%'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>

      <Box className="bgrightshade" position={'absolute'}></Box>
      <Box
        maxHeight={'100vh'}
        // overflow={'scroll'}
        display={'flex'}
        flexDirection={'column'}
        gap={'3rem'}
        maxWidth={'lg'}
        margin={'auto'}
        padding={'3rem'}
        marginBottom={'3rem'}
      >
        <Box>
          <Box display={'flex'} justifyContent={'center'}>
            <img
              src={logo}
              style={{ zIndex: 9999, cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            ></img>
          </Box>
          <Box display={'flex'} justifyContent={'center'}>
            <Typography variant="h4">Privacy Policy</Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">I. Introduction:</Typography>
          <Typography>
            This Privacy Policy explains how propertycloud.ai ("we," "us," or "our") collects, uses, and discloses your personal information when
            you use our websites, applications, and services ("Services").
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">II. Information We Collect:</Typography>
          <Typography>
            Personal Information: This may include your name, email address, phone number, address, company information, and other information you
            provide through the Services
          </Typography>
          <Typography>
            Usage Data: This may include information about how you use the Services, such as the pages you visit, features you use, and searches you
            perform.
          </Typography>
          <Typography>
            Device Data: This may include information about your device, such as your IP address, browser type, operating system, and device
            identifiers.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">III. How We Use Your Information:</Typography>
          <Box paddingLeft={'10px'} display={'flex'} gap={'1rem'} flexDirection={'column'}>
            <Typography>&#x2022; To provide, operate, and maintain the Services</Typography>
            <Typography>&#x2022; To improve and personalize the Services</Typography>
            <Typography>&#x2022; To communicate with you</Typography>
            <Typography>&#x2022; To analyze your use of the Services</Typography>
            <Typography>&#x2022; To comply with legal and regulatory requirements</Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">IV. Sharing Your Information:</Typography>
          <Typography>We may share your information with third-party service providers who help us operate the Services.</Typography>
          <Typography>We may share your information with law enforcement or other government officials if required by law.</Typography>
          <Typography>We may share your information in the event of a merger, acquisition, or other business transaction.</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">V. Data Retention:</Typography>
          <Typography>
            We will retain your information for as long as necessary to provide the Services and comply with our legal obligations.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">VI. Security:</Typography>
          <Typography>
            We use reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.{' '}
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">VII. Your Choices:</Typography>
          <Typography>
            You have the right to access, modify, or delete your personal information. You can also opt out of certain data collection practices.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">VIII. Children's Privacy:</Typography>
          <Typography>
            The Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.{' '}
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">IX. Cross-Border Transfers:</Typography>
          <Typography>Your information may be transferred to and processed in countries other than your own.</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">X. Changes to this Privacy Policy:</Typography>
          <Typography>We may update this Privacy Policy from time to time.</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'} paddingBottom={'3rem'}>
          <Typography variant="h5">XI. Contact Us:</Typography>
          <Typography>If you have any questions about this Privacy Policy, please contact us.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
