import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import { isFile } from '../../helpers';
import NotificationModel from '../models/notificationModel';
import UserRepository from './userRepository';

class NotificationRepository {
  private className = 'Notification';

  public async create(object: NotificationModel): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const Notification = Parse.Object.extend(this.className);
        const newNotification = new Notification();
        const recipientUser = Parse.User.createWithoutData(object.recipient as string);

        newNotification.set('recipient', recipientUser);
        newNotification.set('sender', currentUser);
        newNotification.set('title', object.title);
        newNotification.set('body', object.body);
        newNotification.set('organization', currentUser.get('organization'));
        newNotification.set('priority', object.priority);
        newNotification.set('read', false);

        const savedNotification = await newNotification.save(null, { useMasterKey: true });

        if (savedNotification) {
          return savedNotification;
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }
    } catch (error: any) {
      console.log(error);
      return null;
    }
  }

  public async getAll(): Promise<Parse.Object[] | null> {
    
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Notification = Parse.Object.extend(this.className);
      var query = new Parse.Query(Notification).equalTo('recipient', currentUser.id).descending('createdAt');

      query.include('sender', 'recipient');

      try {
        const properties = await query.find({ useMasterKey: true });
        if (properties) {
          return properties;
        }
        else {
          return null;
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
        return null;
      }
    } else {
      return null;
    }
  }

  public async markAsRead(notification: Parse.Object): Promise<Parse.Object | null> {
    
    notification.set('read', true);
    const savedNotification = await notification.save(null);
    return savedNotification;
  }

  public async delete(notification: Parse.Object): Promise<boolean> {
    
    const deletedNotification = await notification.destroy();
    if (deletedNotification) {
      return true;
    }
    else {
      return false;
    }
  }

}

export default NotificationRepository;
