// import { toast } from 'react-toastify';
// import { Toast } from '../../components/atoms';

interface OptionsInterface {
  id: string;
  value: string;
}

interface LabelValueInterface {
  label: string;
  value: string;
}

export const generateCountryList = (): string[] => {
  return countries;
};

export const generateCountryListAsOptions = (): OptionsInterface[] => {
  return countriesAsOptions;
}

export const generateCountryListAsLabelValues = (): LabelValueInterface[] => {
  return countriesAsLabelValues;
}

const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the Congo',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const countriesAsOptions = [
  { id: 'Afghanistan', value: 'Afghanistan' },
  { id: 'Albania', value: 'Albania' },
  { id: 'Algeria', value: 'Algeria' },
  { id: 'Andorra', value: 'Andorra' },
  { id: 'Angola', value: 'Angola' },
  { id: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { id: 'Argentina', value: 'Argentina' },
  { id: 'Armenia', value: 'Armenia' },
  { id: 'Australia', value: 'Australia' },
  { id: 'Austria', value: 'Austria' },
  { id: 'Azerbaijan', value: 'Azerbaijan' },
  { id: 'Bahamas', value: 'Bahamas' },
  { id: 'Bahrain', value: 'Bahrain' },
  { id: 'Bangladesh', value: 'Bangladesh' },
  { id: 'Barbados', value: 'Barbados' },
  { id: 'Belarus', value: 'Belarus' },
  { id: 'Belgium', value: 'Belgium' },
  { id: 'Belize', value: 'Belize' },
  { id: 'Benin', value: 'Benin' },
  { id: 'Bhutan', value: 'Bhutan' },
  { id: 'Bolivia', value: 'Bolivia' },
  { id: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { id: 'Botswana', value: 'Botswana' },
  { id: 'Brazil', value: 'Brazil' },
  { id: 'Brunei', value: 'Brunei' },
  { id: 'Bulgaria', value: 'Bulgaria' },
  { id: 'Burkina Faso', value: 'Burkina Faso' },
  { id: 'Burundi', value: 'Burundi' },
  { id: 'Cabo Verde', value: 'Cabo Verde' },
  { id: 'Cambodia', value: 'Cambodia' },
  { id: 'Cameroon', value: 'Cameroon' },
  { id: 'Canada', value: 'Canada' },
  { id: 'Central African Republic', value: 'Central African Republic' },
  { id: 'Chad', value: 'Chad' },
  { id: 'Chile', value: 'Chile' },
  { id: 'China', value: 'China' },
  { id: 'Colombia', value: 'Colombia' },
  { id: 'Comoros', value: 'Comoros' },
  { id: 'Congo, Democratic Republic of the Congo', value: 'Congo, Democratic Republic of the Congo' },
  { id: 'Costa Rica', value: 'Costa Rica' },
  { id: "Cote d'Ivoire", value: "Cote d'Ivoire" },
  { id: 'Croatia', value: 'Croatia' },
  { id: 'Cuba', value: 'Cuba' },
  { id: 'Cyprus', value: 'Cyprus' },
  { id: 'Czechia', value: 'Czechia' },
  { id: 'Denmark', value: 'Denmark' },
  { id: 'Djibouti', value: 'Djibouti' },
  { id: 'Dominica', value: 'Dominica' },
  { id: 'Dominican Republic', value: 'Dominican Republic' },
  { id: 'Ecuador', value: 'Ecuador' },
  { id: 'Egypt', value: 'Egypt' },
  { id: 'El Salvador', value: 'El Salvador' },
  { id: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { id: 'Eritrea', value: 'Eritrea' },
  { id: 'Estonia', value: 'Estonia' },
  { id: 'Eswatini', value: 'Eswatini' },
  { id: 'Ethiopia', value: 'Ethiopia' },
  { id: 'Fiji', value: 'Fiji' },
  { id: 'Finland', value: 'Finland' },
  { id: 'France', value: 'France' },
  { id: 'Gabon', value: 'Gabon' },
  { id: 'Gambia', value: 'Gambia' },
  { id: 'Georgia', value: 'Georgia' },
  { id: 'Germany', value: 'Germany' },
  { id: 'Ghana', value: 'Ghana' },
  { id: 'Greece', value: 'Greece' },
  { id: 'Grenada', value: 'Grenada' },
  { id: 'Guatemala', value: 'Guatemala' },
  { id: 'Guinea', value: 'Guinea' },
  { id: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { id: 'Guyana', value: 'Guyana' },
  { id: 'Haiti', value: 'Haiti' },
  { id: 'Honduras', value: 'Honduras' },
  { id: 'Hungary', value: 'Hungary' },
  { id: 'Iceland', value: 'Iceland' },
  { id: 'India', value: 'India' },
  { id: 'Indonesia', value: 'Indonesia' },
  { id: 'Iran', value: 'Iran' },
  { id: 'Iraq', value: 'Iraq' },
  { id: 'Ireland', value: 'Ireland' },
  { id: 'Israel', value: 'Israel' },
  { id: 'Italy', value: 'Italy' },
  { id: 'Jamaica', value: 'Jamaica' },
  { id: 'Japan', value: 'Japan' },
  { id: 'Jordan', value: 'Jordan' },
  { id: 'Kazakhstan', value: 'Kazakhstan' },
  { id: 'Kenya', value: 'Kenya' },
  { id: 'Kiribati', value: 'Kiribati' },
  { id: 'Korea, North', value: 'Korea, North' },
  { id: 'Korea, South', value: 'Korea, South' },
  { id: 'Kosovo', value: 'Kosovo' },
  { id: 'Kuwait', value: 'Kuwait' },
  { id: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { id: 'Laos', value: 'Laos' },
  { id: 'Latvia', value: 'Latvia' },
  { id: 'Lebanon', value: 'Lebanon' },
  { id: 'Lesotho', value: 'Lesotho' },
  { id: 'Liberia', value: 'Liberia' },
  { id: 'Libya', value: 'Libya' },
  { id: 'Liechtenstein', value: 'Liechtenstein' },
  { id: 'Lithuania', value: 'Lithuania' },
  { id: 'Luxembourg', value: 'Luxembourg' },
  { id: 'Madagascar', value: 'Madagascar' },
  { id: 'Malawi', value: 'Malawi' },
  { id: 'Malaysia', value: 'Malaysia' },
  { id: 'Maldives', value: 'Maldives' },
  { id: 'Mali', value: 'Mali' },
  { id: 'Malta', value: 'Malta' },
  { id: 'Marshall Islands', value: 'Marshall Islands' },
  { id: 'Mauritania', value: 'Mauritania' },
  { id: 'Mauritius', value: 'Mauritius' },
  { id: 'Mexico', value: 'Mexico' },
  { id: 'Micronesia', value: 'Micronesia' },
  { id: 'Moldova', value: 'Moldova' },
  { id: 'Monaco', value: 'Monaco' },
  { id: 'Mongolia', value: 'Mongolia' },
  { id: 'Montenegro', value: 'Montenegro' },
  { id: 'Morocco', value: 'Morocco' },
  { id: 'Mozambique', value: 'Mozambique' },
  { id: 'Myanmar', value: 'Myanmar' },
  { id: 'Namibia', value: 'Namibia' },
  { id: 'Nauru', value: 'Nauru' },
  { id: 'Nepal', value: 'Nepal' },
  { id: 'Netherlands', value: 'Netherlands' },
  { id: 'New Zealand', value: 'New Zealand' },
  { id: 'Nicaragua', value: 'Nicaragua' },
  { id: 'Niger', value: 'Niger' },
  { id: 'Nigeria', value: 'Nigeria' },
  { id: 'North Macedonia', value: 'North Macedonia' },
  { id: 'Norway', value: 'Norway' },
  { id: 'Oman', value: 'Oman' },
  { id: 'Pakistan', value: 'Pakistan' },
  { id: 'Palau', value: 'Palau' },
  { id: 'Palestine', value: 'Palestine' },
  { id: 'Panama', value: 'Panama' },
  { id: 'Papua New Guinea', value: 'Papua New Guinea' },
  { id: 'Paraguay', value: 'Paraguay' },
  { id: 'Peru', value: 'Peru' },
  { id: 'Philippines', value: 'Philippines' },
  { id: 'Poland', value: 'Poland' },
  { id: 'Portugal', value: 'Portugal' },
  { id: 'Qatar', value: 'Qatar' },
  { id: 'Romania', value: 'Romania' },
  { id: 'Russia', value: 'Russia' },
  { id: 'Rwanda', value: 'Rwanda' },
  { id: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { id: 'Saint Lucia', value: 'Saint Lucia' },
  {
    id: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines'
  },
  { id: 'Samoa', value: 'Samoa' },
  { id: 'San Marino', value: 'San Marino' },
  { id: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { id: 'Saudi Arabia', value: 'Saudi Arabia' },
  { id: 'Senegal', value: 'Senegal' },
  { id: 'Serbia', value: 'Serbia' },
  { id: 'Seychelles', value: 'Seychelles' },
  { id: 'Sierra Leone', value: 'Sierra Leone' },
  { id: 'Singapore', value: 'Singapore' },
  { id: 'Slovakia', value: 'Slovakia' },
  { id: 'Slovenia', value: 'Slovenia' },
  { id: 'Solomon Islands', value: 'Solomon Islands' },
  { id: 'Somalia', value: 'Somalia' },
  { id: 'South Africa', value: 'South Africa' },
  { id: 'South Sudan', value: 'South Sudan' },
  { id: 'Spain', value: 'Spain' },
  { id: 'Sri Lanka', value: 'Sri Lanka' },
  { id: 'Sudan', value: 'Sudan' },
  { id: 'Suriname', value: 'Suriname' },
  { id: 'Sweden', value: 'Sweden' },
  { id: 'Switzerland', value: 'Switzerland' },
  { id: 'Syria', value: 'Syria' },
  { id: 'Taiwan', value: 'Taiwan' },
  { id: 'Tajikistan', value: 'Tajikistan' },
  { id: 'Tanzania', value: 'Tanzania' },
  { id: 'Thailand', value: 'Thailand' },
  { id: 'Timor-Leste', value: 'Timor-Leste' },
  { id: 'Togo', value: 'Togo' },
  { id: 'Tonga', value: 'Tonga' },
  { id: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { id: 'Tunisia', value: 'Tunisia' },
  { id: 'Turkey', value: 'Turkey' },
  { id: 'Turkmenistan', value: 'Turkmenistan' },
  { id: 'Tuvalu', value: 'Tuvalu' },
  { id: 'Uganda', value: 'Uganda' },
  { id: 'Ukraine', value: 'Ukraine' },
  { id: 'United Arab Emirates', value: 'United Arab Emirates' },
  { id: 'United Kingdom', value: 'United Kingdom' },
  { id: 'United States', value: 'United States' },
  { id: 'Uruguay', value: 'Uruguay' },
  { id: 'Uzbekistan', value: 'Uzbekistan' },
  { id: 'Vanuatu', value: 'Vanuatu' },
  { id: 'Vatican City', value: 'Vatican City' },
  { id: 'Venezuela', value: 'Venezuela' },
  { id: 'Vietnam', value: 'Vietnam' },
  { id: 'Yemen', value: 'Yemen' },
  { id: 'Zambia', value: 'Zambia' },
  { id: 'Zimbabwe', value: 'Zimbabwe' }
]

const countriesAsLabelValues = [
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cabo Verde', value: 'Cabo Verde' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Chile', value: 'Chile' },
  { label: 'China', value: 'China' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Congo, Democratic Republic of the Congo', value: 'Congo, Democratic Republic of the Congo' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: "Cote d'Ivoire", value: "Cote d'Ivoire" },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czechia', value: 'Czechia' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Eswatini', value: 'Eswatini' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'India', value: 'India' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Korea, North', value: 'Korea, North' },
  { label: 'Korea, South', value: 'Korea, South' },
  { label: 'Kosovo', value: 'Kosovo' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Micronesia', value: 'Micronesia' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'North Macedonia', value: 'North Macedonia' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines'
  },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', value: 'Saudi Arabia' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'South Sudan', value: 'South Sudan' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Syria', value: 'Syria' },
  { label: 'Taiwan', value: 'Taiwan' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Timor-Leste', value: 'Timor-Leste' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'United States', value: 'United States' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Vatican City', value: 'Vatican City' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' }
]
