import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './accordian.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../../utils/constants/color';
import { selectColor, selectThemeMode } from '../../../../store/selector';
import SearchInput from './searchInput';
import UserPermissionList from './table/userPermissionList';
import { Box } from '@mui/material';
import { formatRole } from '../../../../utils/helpers';
import UserModel from '../../../../utils/api/models/userModel';
import NotFoundLottie from '../../../atoms/notFound';

// const dummyData = [
//   {
//     id: '1',
//     name: 'John1',
//     role: 'Sale Executives',
//   },
//   {
//     id: '2',
//     name: 'Alex',
//     role: 'Sale',
//   },
//   {
//     id: '3',
//     name: 'Robert',
//     role: 'Executives',
//   },
// ];

interface ContentTableProps {
  data: any;
}

export default function UserAccordion({ data }: ContentTableProps) {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const elements = document.getElementsByClassName('css-1elwnq4-MuiPaper-root-MuiAccordion-root');
  const [search, setsearch] = useState<string>('');
  const [user, setUser] = useState<any>(data);

  const handleSearch = (search: string) => {
    const searchTerm = search.toLowerCase().trim();

    const filteredUser = data.filter((user: any) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchTerm);
    });
    setUser(filteredUser);
  };

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  useEffect(() => {
    for (let elem = 0; elem < elements.length; elem++) {
      const element = elements[elem] as HTMLElement;
      if (mode === 'dark') {
        element?.style.setProperty('--background-color', `${globalColors.blackLight}`);
        element?.style.setProperty('--text-color', `${globalColors.white}`);
      } else {
        element?.style.setProperty('--background-color', `${globalColors.whiteLight}`);
        element?.style.setProperty('--text-color', `${globalColors.black}`);
      }
    }
  }, [mode]);

  return (
    <div>
      {user.length > 0 && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
        <SearchInput
          value={search}
          onChange={(e) => {
            setsearch(e.target.value);
          }}
          className="input-search"
          placeholder="Search"
          bg={mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight}
        />
      </Box>}
      {/* User */}

      {user.length > 0 ? (
        user.map((item: UserModel) => (
          <Accordion
            sx={{
              borderRadius: '20px !important',
              backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
              marginBottom: '2rem',
            }}
            defaultExpanded={false}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '30px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: mode === 'dark' ? globalColors?.white : globalColors?.black,
                  }}
                >
                  {item?.firstName + ' ' + item?.lastName}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '10px',
                    fontWeight: 500,
                    lineHeight: '12px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                    color: color,
                  }}
                >
                  - {formatRole(item?.role ?? '')}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <UserPermissionList data={item.permissions ?? []} id={item.objectId} />
            </AccordionDetails>
          </Accordion>
        ))
      ) : search !== '' ? (
        <Typography
          sx={{
            color: color || globalColors.blue,
            textAlign: 'center',
            fontSize: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          No User Found
        </Typography>
      ) : (
        <NotFoundLottie
          showButton={true}
          text="Looks like you don't have any other members added yet. Press the button below to add a new member."
          buttonText="Add New Member"
          navigateTo="/hr/members/addMember"
          setAddNewPath='/settings/permissions'
        />
      )}
    </div>
  );
}
