import { Stack } from "@mui/material";
import React from "react";

interface GroupInputProps {
  children: React.ReactNode;
  width?: string;
  className?: string;
}
const GroupInput = ({ children, width, className }: GroupInputProps) => {
  return (
    <Stack direction="row" width={width} className={className}>
      {children}
    </Stack>
  );
};

export default GroupInput;
