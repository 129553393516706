import Parse from 'parse';
import DemoModel from '../models/demoModel';
import { Toast } from '../../../components/atoms';

class DemoRepository {
  private className = 'Demo';
  public async create(demo: DemoModel) {
    try {
      const DemoClass = Parse.Object.extend(this.className);
      const newDemo = new DemoClass();

      newDemo.set('name', demo.name);
      newDemo.set('email', demo.email);
      newDemo.set('phone', demo.phone);
      newDemo.set('country', demo.country);
      newDemo.set('message', demo.message);
      newDemo.set('from', demo.from);

      return new Promise((resolve, _) => {
        newDemo
          .save(null, { useMasterKey: true })
          .then((savedDemo: Parse.Object | null) => {
            if (savedDemo) {
              Toast(`Form submitted successfully.`, 'success');
              resolve(savedDemo.toJSON() as DemoModel);
            } else {
              Toast(`Form could not be scheduled successfully. Please try again.`, 'error');
              resolve(null);
            }
          })
          .catch((error: Error) => {
            resolve(error);
          });
      });
    } catch (error) {
      console.error('Error creating demo:', error);
      return null;
    }
  }
}
export default DemoRepository;
