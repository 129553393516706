import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  IMGAutomationWorkFlows,
  IMGCommunicationHub,
  IMGCustomizeDashboard,
  IMGDocumentOrganization,
  IMGPerfomanceAnalytics,
  IMGPropertyManagement,
  IMGRealTimeInsight,
  // IMGUser,
  IMGUserAutomationPerfomanceAnalysis,
  // IMGUserSetting,
} from '../../assets/Images';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
interface CardPropertyFinderProps {
  title: string;
  paragraph: string;
  index: number;
}

const CardPropertyFinder = ({ title, paragraph, index }: CardPropertyFinderProps) => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <Box
      data-aos={[0, 1, 4, 5].includes(index) ? 'fade-right' : 'fade-left'}
      data-aos-offset="30"
      data-aos-delay="300"
      data-aos-easing="ease-in-sine"
      data-aos-duration="500"
      sx={{ border: '1px solid #599BF7', borderRadius: '16px', padding: '18px 20px', minHeight: '90%' }}
    >
      <Box display="flex" justifyContent={'center'} width="100%">
        <img
          width={'50px'}
          src={
            title === 'Performance Analytics'
              ? IMGPerfomanceAnalytics
              : title === 'Property Management'
              ? IMGPropertyManagement
              : title === 'Automation Workflows'
              ? IMGAutomationWorkFlows
              : title === 'Document Organization'
              ? IMGDocumentOrganization
              : title === 'Real-Time Insights'
              ? IMGRealTimeInsight
              : title === 'User Tracking & Performance Analysis'
              ? IMGUserAutomationPerfomanceAnalysis
              : title === 'Customizable Dashboards'
              ? IMGCustomizeDashboard
              : IMGCommunicationHub
          }
          alt=""
          style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}
        />
      </Box>

      <Typography textAlign={'center'} sx={{ fontSize: { xs: '14px', sm: '16px', lg: '20px' } }} marginTop={'3rem'}>
        {title}
      </Typography>
      <Typography textAlign={'center'} whiteSpace={'pre-wrap'} fontSize={'14px'} marginTop={'1rem'}>
        {paragraph}
      </Typography>
    </Box>
  );
};

export default CardPropertyFinder;
