import React, { useEffect, useState } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor, selectOrganizationTier } from '../../store/selector';
import { styled } from '@mui/material';
import { FormGroup, InputLabel, useTheme, IconButton } from '@mui/material';
import { FormAlertsStyle, InputStyle, crossFileContainerStyle, iconButtonStyle, imageNameStyle } from './alerts.style';
import { ThemeProvider } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import FileIcon from '../../components/molecules/communication/alert/FileIcon';
import AttachmentUpload from '../../components/molecules/communication/alert/AttachmentUpload';
import EmployeeDropdown from '../../components/molecules/communication/alert/employeeDropDown';
import UserRepository from '../../utils/api/repositories/userRepository';
import AlertRepository from '../../utils/api/repositories/alertsRepository';
import AlertModel from '../../utils/api/models/alertsModel';
import io from 'socket.io-client';
import { Button, Toast } from '../../components/atoms';
import AlertGroupModel from '../../utils/api/models/alertGroupModel';
import AlertGroupRepository from '../../utils/api/repositories/alertGroupRepository';
import { useNavigate } from 'react-router-dom';
import { setAddNew } from '../../store/routes';
import { UserRole } from '../../store/user';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import { PricingTiers } from '../../utils/helpers';
import InsufficientPlan from '../../components/atoms/insufficientPlan';

const Icons = ['All', 'Select Groups', 'Select Individuals'];

export interface FormValues {
  name: string;
  description: string;
}

const AlertsNewGroup = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [selectedIcons, setSelectedIcons] = useState<string[]>(['All']);
  const [names, setNames] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({ name: '', description: '' });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | ''>('');
  const [selectedNames, setSelectedNames] = useState<string[] | null>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const organizationTier = useSelector(selectOrganizationTier);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleIconClick = (icon: string) => {
    // Clear the selectedIcons array and then add the clicked icon
    setSelectedIcons([icon]);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!selectedNames || selectedNames?.length <= 1) {
      Toast('Please select at least two members to successfully create a group.', 'error');
      return;
    }

    const selectedIds = selectedNames?.map((i) => JSON.parse(i).id);

    const alertGroup: AlertGroupModel = {
      name: formValues?.name,
      description: formValues?.description,
      selectedMembers: selectedIds,
    };

    const repository = new AlertGroupRepository();
    const response = await repository.create(alertGroup);

    if (response) {
      dispatch(setAddNew({ addNew: false, path: '/alerts' }));
      navigate('/alerts');
    }

    // const alertModel: AlertModel = {
    //   title: formValues?.name || '',
    //   message: formValues?.message || '',
    //   attachment: selectedFile || '',
    //   sendTo: slecetedObject || [],
    //   // Other fields as needed
    // };
    // const repository = new AlertRepository();
    // await repository.createAlert(alertModel, objectId);
  };

  const handleCancel = () => {
    // Reset all state variables to their initial values or empty state
    setSelectedIcons([]);
    setFormValues({ name: '', description: '' });
    setSelectedFile(null);
    setSelectedFileName('');
    setSelectedNames([]);
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
  };

  const fetchAllUsers = async () => {
    const repository = new UserRepository();
    const currentUser = repository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const userData = await repository.getAllUsersForOrganization();
    const uniqueNames = Array.from(new Set(userData?.map((user) => ({ name: `${user.firstName} ${user.lastName}`, id: user.objectId }))));
    setNames(uniqueNames);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Alerts / New Group'} />
        </div>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission && organizationTier == PricingTiers.Professional ? (
            <FormAlertsStyle onSubmit={handleSubmit}>
              <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
                <Box marginBottom={'10px'}></Box>
                {/* Assignment Container */}
                <AssignmentContainer
                  bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                  padding={'20px'}
                  borderRadius={'16px'}
                  color={globalColors.white}
                  fontSize={'12px'}
                  boxShadow={'#171717'}
                  marginTop={'1rem'}
                >
                  {' '}
                  {/* Audience Assignment */}
                  <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                    <Typography
                      component="div"
                      fontFamily={'Poppins'}
                      fontWeight={'400'}
                      variant="h5"
                      lineHeight={'32px'}
                      color={mode === 'dark' ? globalColors?.white : globalColors.black}
                    >
                      Select Members
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      marginTop: '2%',
                    }}
                  >
                    <EmployeeDropdown names={names} mode={mode} selectedNames={selectedNames} setSelectedNames={setSelectedNames} />
                  </Box>
                  {/* 2nd Box */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '2%',
                    }}
                  >
                    {/* Select */}
                    <Box
                      display={'flex'}
                      sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        // marginTop: '5%',
                        width: '100%',
                      }}
                      gap={'10px'}
                      justifyContent={'start'}
                      flexWrap={'wrap'}
                      rowGap={'20px'}
                    >
                      {selectedNames?.map((e) => {
                        return (
                          <Box
                            sx={{
                              padding: '8px',
                              borderRadius: '12px',
                              transition: 'opacity 0.3s ease',
                              border: `1px solid ${color ? color : globalColors.whiteBorder}`,
                              backgroundColor: mode === 'dark' ? globalColors?.black : globalColors.white,
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '11px', padding: '10px' }}>{JSON.parse(e).name}</span>
                            <ClearIcon
                              sx={{
                                cursor: 'pointer',
                                fontSize: '16px',
                                '&:hover': {
                                  color: globalColors.red,
                                },
                              }}
                              onClick={() => setSelectedNames(selectedNames.filter((item: any) => item !== e))}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </AssignmentContainer>
              </Box>

              <Box width={'100%'} paddingLeft={3} paddingRight={3} paddingBottom={1}>
                <Box marginBottom={'10px'}></Box>
                {/* Alert Container */}
                <AlertContainer
                  bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                  padding={'20px'}
                  borderRadius={'16px'}
                  color={globalColors.white}
                  fontSize={'12px'}
                  boxShadow={'#171717'}
                  marginTop={'1rem'}
                >
                  {' '}
                  {/* Para 1 */}
                  <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'} marginBottom={'2rem'}>
                    <Typography
                      component="div"
                      fontFamily={'Poppins'}
                      fontWeight={'400'}
                      variant="h5"
                      lineHeight={'32px'}
                      color={mode === 'dark' ? globalColors?.white : globalColors.black}
                    >
                      Group Details
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '100%' }}>
                    <Box display={'flex'} gap="10px" alignItems={'center'} marginBottom={'20px'}>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          width: { xs: '100%', md: '50%' },
                        }}
                        className=""
                      >
                        <InputLabel
                          style={{
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '32px',
                            letterSpacing: '0px',
                            textAlign: 'left',
                            color: globalColors.text.secondary,
                          }}
                          htmlFor="nameInput"
                        >
                          Name
                        </InputLabel>
                        <InputStyle
                          required
                          name="name"
                          type="text"
                          id="nameInput"
                          placeholder="Group Name"
                          value={formValues.name}
                          onChange={handleChange}
                          mode={mode}
                        />
                      </FormGroup>
                    </Box>
                    <Box display={'flex'} gap="10px" alignItems={'center'}>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}
                        className=""
                      >
                        <InputLabel
                          style={{
                            fontFamily: 'Poppins',
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '32px',
                            letterSpacing: '0px',
                            textAlign: 'left',
                            color: globalColors.text.secondary,
                          }}
                          htmlFor="nameInput"
                        >
                          Description
                        </InputLabel>
                        <InputStyle
                          style={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}
                          name="description"
                          type="text"
                          id="descriptionInput"
                          placeholder="Group Description"
                          value={formValues.description}
                          onChange={handleChange}
                          mode={mode}
                        />
                      </FormGroup>
                    </Box>
                  </Box>
                  {/* <Box sx={{ display: 'flex', marginTop: '20px' }}>
				  <InputLabel
					style={{
					  fontFamily: 'Poppins',
					  fontWeight: '400',
					  fontSize: '16px',
					  lineHeight: '32px',
					  letterSpacing: '0px',
					  textAlign: 'left',
					  color: 'rgba(147, 147, 147, 1)',
					}}
					htmlFor="nameInput"
				  >
					Attachments:
				  </InputLabel>
				  <AttachmentUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} setSelectedFileName={setSelectedFileName} />
				</Box> */}
                  <Box>
                    {selectedFile && (
                      <Box sx={{ marginTop: '2%' }}>
                        <ThemeProvider
                          theme={{
                            palette: {
                              primary: {
                                main: '#007FFF',
                                dark: '#0066CC',
                              },
                            },
                          }}
                        >
                          <Box sx={crossFileContainerStyle({ selectedFile, theme, mode })}>
                            <Box sx={{ display: 'flex' }}>
                              <FileIcon />
                              <span
                                style={{
                                  fontFamily: 'Poppins',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '32px',
                                  letterSpacing: '0px',
                                  textAlign: 'left',
                                  color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                  paddingTop: '2%',
                                  paddingLeft: '5%',
                                }}
                              >
                                {selectedFileName}
                              </span>
                            </Box>
                            {/* X button to remove image */}
                            {selectedFile && (
                              <IconButton onClick={handleRemoveAttachment} sx={iconButtonStyle({ theme, mode })}>
                                <ClearIcon
                                  sx={{
                                    fontSize: 16,
                                    '&:hover': {
                                      color: globalColors.red,
                                    },
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </ThemeProvider>
                      </Box>
                    )}
                  </Box>
                </AlertContainer>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '4%', marginBottom: '4%' }}>
                <ButtonStyle type="reset" style={{ marginRight: '10px' }} color={globalColors.red} onClick={handleCancel}>
                  Cancel
                </ButtonStyle>
                <ButtonStyle style={{ marginLeft: '10px' }} color={color || globalColors.blue}>
                  Create
                </ButtonStyle>
              </Box>
            </FormAlertsStyle>
          ) : !hasPermission ? (
            <NoPermission />
          ) : (
            <InsufficientPlan />
          )}
        </Box>
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  height: 'auto',
  width: '100%',
});

const AlertContainer = styled(Box)({
  height: 'auto',
  width: '100%',
});

const ButtonStyle = styled('button')<any>(({ color, disabled, type }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  background: color !== 'default' && color !== '' ? color : type === 'reset' ? globalColors.black : globalColors.blue,
  color: globalColors.white,
  width: '260px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.blue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default AlertsNewGroup;
