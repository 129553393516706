import { Box, Grid, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/atoms/loader';
import ResetPasswordRequestRepository from '../../utils/api/repositories/resetPasswordRequestRepository';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FieldInput, Toast } from '../../components/atoms';
import { ArrowBack } from '@mui/icons-material';

interface FormResetValues {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [user, setUser] = useState<Parse.Object | null>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const request_id = searchParams.get('request_id');
  const navigate = useNavigate();
  const [passwordObscured, setPasswordObscured] = useState(true);
  const [confirmPasswordObscured, setConfirmPasswordObscured] = useState(true);

  useEffect(() => {
    if (!token || !request_id) {
      setError('Invalid request. Taking you back to the home page...');
    }

    const fetchUserDetails = async () => {
      if (request_id && token) {
        const resetPasswordRequestRepository = new ResetPasswordRequestRepository();
        const passwordRequest = await resetPasswordRequestRepository.getObjectById(request_id);
        if (passwordRequest) {
          if (token === passwordRequest.get('token')) {
            setUser(passwordRequest.get('user'));
          } else {
            setError('Invalid request. Taking you back to the home page...');
          }
        } else {
          setError('Error fetching the details of this request. Taking you back to the home page...');
        }
      } else {
        setError('Invalid request. Taking you back to the home page...');
      }
      setLoading(false);
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (error !== '') {
      setTimeout(() => {
        navigate('/');
      }, 5000);
    }
  }, [error]);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : error !== '' ? (
        <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'2rem'}>
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {error}
          </Typography>
        </Box>
      ) : (
        <Box display={'flex'} flexDirection={'column'} width={'100%'} minHeight={'100vh'}>
          <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} color={globalColors.white} padding={'2rem'}>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  navigate('/');
                }}
              />
              <Typography
                fontSize={'12px'}
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  navigate('/');
                }}
              >
                Home
              </Typography>
            </Grid>
          </Grid>
          <Box
            display={'flex'}
            justifyContent={'center'}
            maxWidth={'sm'}
            margin={'auto'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'2rem'}
            padding={'3rem'}
          >
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={'1rem'}>
              <Typography variant="h6" textAlign={'center'} sx={{ color: globalColors.white }}>
                Hi{' '}
                <span style={{ color: globalColors.mainBlue }}>
                  {user?.get('firstName')} {user?.get('lastName')}
                </span>
                ,<br />
                please reset your password using the form below.
              </Typography>
              <Typography textAlign={'center'} sx={{ color: globalColors.gray }}>
                (You cannot use a password that has been previously used for your account.)
              </Typography>
            </Box>
            <FormResetStyle>
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: '',
                }}
                validate={(values) => {
                  const errors = {} as FormResetValues;

                  if (!values.password || !values.password.trim()) {
                    errors.password = 'Required';
                  }

                  if (!values.confirmPassword || !values.confirmPassword.trim()) {
                    errors.confirmPassword = 'Required';
                  }

                  const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:<>?]).{8,}$/);
                  if (values.password.length > 0 && !regex.test(values.password)) {
                    errors.password =
                      'Password does not meet the Password Policy requirements. Password must be at least 8 characters long, and have at least one uppercase character, one lowercase character, one number and one special character (!, $, #, ^, etc.).';
                  }

                  if (values.confirmPassword !== '' && values.confirmPassword !== values.password) {
                    errors.confirmPassword = 'Both passwords must match';
                  }

                  return errors;
                }}
                onSubmit={async (values: FormResetValues, { setSubmitting, resetForm }: FormikHelpers<FormResetValues>) => {
                  try {
                    const resetPasswordRequestRepository = new ResetPasswordRequestRepository();
                    const resetRequest = await resetPasswordRequestRepository.change(values.password, request_id ?? '');
                    if (resetRequest) {
                      if (resetRequest.data !== null) {
                        Toast(resetRequest.message, 'success');
                        resetForm();
                        setTimeout(() => {
                          navigate('/login');
                        }, 3000);
                      }
                      else {
                        if (resetRequest.message.message) {
                          Toast(resetRequest.message.message, 'error');
                        }
                        else {
                          Toast(resetRequest.message, 'error');
                        }
                      }
                    }
                    else {
                      Toast('There was an error resetting your password. Please try again.', 'error');
                    }
                  } catch (error) {
                    console.log('ERROR SUBMITTING FORM:', error);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column" gap="1.5rem" width={'100%'}>
                      <FieldInput
                        label="New Password"
                        id="password"
                        name="password"
                        placeholder="New Password"
                        optional={false}
                        type={passwordObscured ? "password" : "text"}
                        isPassword
                        isShowPassword={!passwordObscured}
                        onShowPassword={() => {setPasswordObscured(!passwordObscured)}}
                        error={errors.password && touched.password ? errors.password : false}
                      />
                      <FieldInput
                        label="Confirm Password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        optional={false}
                        type={confirmPasswordObscured ? "password" : "text"}
                        isPassword
                        isShowPassword={!confirmPasswordObscured}
                        onShowPassword={() => {setConfirmPasswordObscured(!confirmPasswordObscured)}}
                        error={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : false}
                      />
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} marginTop={'3rem'}>
                      <Button type="submit" buttonColor={globalColors.mainBlue}>
                        {isSubmitting ? 'Loading...' : 'Next'}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </FormResetStyle>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const FormResetStyle = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: 'transparent',
  marginBottom: '2rem',
  marginTop: '2rem',
  width: '100%',
  border: `1px solid ${globalColors.gray}`,
  margin: '2rem',
  zIndex: 99999999,
  // TODO : style : fix component Link login
  // TODO : Responsive test
  // [theme.breakpoints.up('sm')]: {
  //   width: 250,
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: 350,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 450,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'pink',
  //   width: '1002px',
  // },
  fontSize: '14px',
  overflow: 'auto',
  '& input': {
    marginBottom: 0,
  },
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
  '& .wrapper-btn-submit': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2.5rem',
    alignItems: 'center',
    '& .btn-submit': {
      marginBottom: '1rem',
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& button': {
    textTransform: 'none',
  },
}));

export default ResetPassword;
