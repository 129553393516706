import { Box, Modal, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import styled from '@emotion/styled';
import { globalColors } from '../../../../../utils/constants/color';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from './doneIcon';
import { useSelector } from 'react-redux';
import { selectColor } from '../../../../../store/selector';

interface ModalProps {
  open: boolean;
  mode: string;
  onClose: () => void;
}

const ContactResponseModal = ({ open, onClose, mode }: ModalProps) => {

  const color = useSelector(selectColor);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '728px',
    maxHeight: '415px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={style} className="content" display={'flex'} flexDirection={'column'}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <ClearIcon sx={{ cursor: 'pointer', color: mode === 'dark' ? globalColors.white : globalColors.black }} onClick={onClose} />
        </Box>

        <Box display={'flex'} gap="10px" alignItems={'center'} marginTop={'20px'} sx={{ justifyContent: 'center' }}>
          <DoneIcon color={color} />
        </Box>

        <Box display={'flex'} gap="10px" alignItems={'center'} marginTop={'30px'} sx={{ justifyContent: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '30px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
              padding: '2rem'
            }}
          >
            Thank you! We have received your message and we will get back to you within 48 - 72 working hours. Please monitor your email inbox in the meanwhile.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '48px',
  background:
    color !== 'default' && color !== ''
      ? color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.blackLight
        : globalColors.whiteLight
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '215px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default ContactResponseModal;
