// export const backgroundImageStyle: React.CSSProperties = {
//   width: '70%',
//   height: '300px',
//   backgroundImage: selectedImage ? `url(${URL.createObjectURL(selectedImage)})` : 'none',
//   backgroundSize: 'cover',
//   backgroundPosition: 'center',
//   backgroundRepeat: 'no-repeat',
//   border: `1px solid ${'rgba(246, 246, 246, 1)'}`,
//   borderRadius: '12px',
// };

interface ImagePreviewProps {
    selectedImage: File | null;
  }
  
  interface CrossContainerProps {
    selectedImage: File | null;
    theme: any;
  }
  
  interface CrossFileContainerProps {
    selectedFile: File | null;
    theme: any;
  }
  
  interface IconButtonProps {
    theme: any;
  }
  
  export const backgroundImageStyle = ({ selectedImage }: ImagePreviewProps) => ({
    width: '70%',
    height: '300px',
    backgroundImage: selectedImage ? `url(${URL.createObjectURL(selectedImage)})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${'rgba(246, 246, 246, 1)'}`,
    borderRadius: '12px',
  });
  
  export const imageNameStyle: React.CSSProperties = {
    color: 'rgba(246, 246, 246, 1)',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '0px',
    textAlign: 'left',
  };
  
  export const crossContainerStyle = ({ selectedImage, theme }: CrossContainerProps) => ({
    padding: '5px 0px 5px 5%',
    width: 258,
    height: 40,
    borderRadius: 5,
    bgcolor: selectedImage ? theme.palette.primary.main : 'transparent',
    '&:hover': {
      bgcolor: selectedImage ? theme.palette.primary.dark : 'transparent',
    },
    position: 'relative',
  });
  
  export const crossFileContainerStyle = ({ selectedFile, theme }: CrossFileContainerProps) => ({
    padding: '5px 0px 5px 5%',
    width: 258,
    height: 40,
    borderRadius: 12,
    border: selectedFile ? `1px solid ${theme.palette.primary.main}` : null,
    position: 'relative',
  });
  
  export const iconButtonStyle = ({ theme }: IconButtonProps) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '8px',
    color: theme.palette.common.white,
  });