import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../../components/molecules/paymentForm/paymentForm';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import Loader from '../../components/atoms/loader';
import { useNavigate, useParams } from 'react-router';
import OnboardingRepository from '../../utils/api/repositories/onboardingRepository';
import UpgradeRequestRepository from '../../utils/api/repositories/upgradeRequestRepository';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setCloseSettings } from '../../store/routes';
import { formatDate } from '../../utils/helpers';

const UpgradePayment = () => {
  const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [amountDue, setAmountDue] = useState(0);
  const [requestObject, setRequestObject] = useState<Parse.Object | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchClientSecret = async () => {
    const objectId = id ?? '';
    if (objectId !== '' && amountDue !== 0) {
      const upgradeRequestRepository = new UpgradeRequestRepository();
      const paymentDetail = await upgradeRequestRepository.initiatePayment(
        amountDue * 100,
        'usd',
        'upgrade',
        objectId,
        requestObject?.get('organization').get('stripeCustomerId')
      );
      if (paymentDetail) {
        return paymentDetail;
      } else {
        return '';
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchRequestDetails = async () => {
      const objectId = id ?? '';
      if (objectId !== '') {
        const upgradeRequestRepository = new UpgradeRequestRepository();
        const upgradeRequest = await upgradeRequestRepository.getObjectById(objectId);
        if (upgradeRequest) {
          setRequestObject(upgradeRequest);
          setAmountDue(parseFloat(upgradeRequest.get('amountDue').toFixed(2)));
          setName(
            upgradeRequest.get('organization').get('admin').get('firstName') + ' ' + upgradeRequest.get('organization').get('admin').get('lastName')
          );
          const publishableKey = process.env.REACT_APP_STRIPE_PK_TEST;
          if (publishableKey) {
            setStripe(loadStripe(publishableKey));
          }
          setLoading(false);
        } else {
          setLoading(false);
          setError('Error fetching your payment details. Please select your preferences and come back again. Taking you back...');
          setTimeout(() => {
            navigate('/settings/payments/changePlan');
          }, 6000);
        }
      } else {
        setLoading(false);
        setError('Error fetching your payment details. Please select your preferences and come back again. Taking you back...');
        setTimeout(() => {
          navigate('/settings/payments/changePlan');
        }, 6000);
      }
    };

    fetchRequestDetails();
  }, []);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : error !== '' ? (
        <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'2rem'}>
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {error}
          </Typography>
        </Box>
      ) : (
        <Box width={'100%'} minHeight={'100vh'}>
          <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} color={globalColors.white} padding={'2rem'}>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  dispatch(setCloseSettings());
                  navigate('/dashboard');
                }}
              />
              <Typography
                fontSize={'12px'}
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  dispatch(setCloseSettings());
                  navigate('/dashboard');
                }}
              >
                Back to Dashboard
              </Typography>
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={'2rem'} padding={'3rem'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxWidth={'md'} flexDirection={'column'} gap={'1rem'}>
              <Typography variant="h6" textAlign={'center'} sx={{ color: globalColors.white }}>
                Hi <span style={{ color: globalColors.mainBlue }}>{name}</span>,<br />
                please initiate your total payment of
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>&nbsp;${amountDue.toFixed(2)}&nbsp;</span>
                to successfully upgrade your plan and continue enjoying the feature rich{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>Property Cloud</span>.
              </Typography>
              <Typography textAlign={'center'} sx={{ color: globalColors.gray }}>
                Please note that this payment is to upgrade your plan until the beginning of your next payment cycle, which starts on{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>
                  {formatDate(requestObject?.get('organization').get('nextPaymentDue'))}
                </span>
                .
              </Typography>
              <Typography textAlign={'center'} sx={{ color: globalColors.gray }}>
                (You can visit this URL at anytime to complete this payment, if you're not ready yet.)
              </Typography>
            </Box>
            {stripe && amountDue !== 0 && (
              <Elements
                stripe={stripe}
                options={{
                  mode: 'payment',
                  currency: 'usd',
                  amount: amountDue * 100,
                  loader: 'always',
                  appearance: {
                    theme: 'night',
                    labels: 'floating',
                    variables: {
                      colorPrimaryText: globalColors.white,
                      focusOutline: `1px solid ${globalColors.mainBlue}`,
                      fontFamily: 'sans-serif',
                      colorPrimary: globalColors.mainBlue,
                      colorTextPlaceholder: globalColors.gray,
                      colorText: globalColors.white,
                      colorTextSecondary: globalColors.white,
                      colorBackground: globalColors.blackLight,
                      colorDanger: globalColors.red,
                    },
                  },
                }}
              >
                {/* <PaymentForm successURL={`/onboarding/secure/payment/success/${id}`}/> */}
                <PaymentForm
                  successURL={`https://propertycloud.ai/upgradeRequest/secure/payment/confirmation`}
                  preProcessingPayment={fetchClientSecret}
                />
              </Elements>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UpgradePayment;
