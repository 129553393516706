import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../../components/molecules/paymentForm/paymentForm';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import Loader from '../../components/atoms/loader';
import { useNavigate, useParams } from 'react-router';
import OnboardingRepository from '../../utils/api/repositories/onboardingRepository';
import { PricingSchedules, formatDate } from '../../utils/helpers';
import UserRepository from '../../utils/api/repositories/userRepository';
import { useDispatch } from 'react-redux';
import { UserRole, resetUserData } from '../../store/user';
import { setCloseSettings } from '../../store/routes';
import { ArrowBack } from '@mui/icons-material';
import NoPermission from '../../components/atoms/noPermission';

const PendingPayment = () => {
  const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [amountDue, setAmountDue] = useState(0);
  const [organizationObject, setOrganizationObject] = useState<Parse.Object | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hasPermission, setHasPermission] = useState(false);

  const fetchClientSecret = async () => {
    const objectId = id ?? '';
    if (objectId !== '' && amountDue !== 0) {
      const organizationRepository = new OrganizationRepository();
      const paymentDetail = await organizationRepository.initiatePayment(
        amountDue * 100,
        'usd',
        'renewal',
        objectId,
        organizationObject?.get('name'),
        organizationObject?.get('ownerEmail')
      );
      if (paymentDetail) {
        return paymentDetail;
      } else {
        return '';
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      const objectId = id ?? '';
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        setHasPermission(currentUser?.get('role') === UserRole.admin);
        if (objectId !== '') {
          const organizationRepository = new OrganizationRepository();
          const organization = await organizationRepository.getObjectById(objectId);
          if (organization) {
            setOrganizationObject(organization);
            const today = new Date();
            if (new Date(organization?.get('nextPaymentDue')) >= today) {
              setLoading(false);
              setError('Your company does not have any pending payments. Taking you to your Property Cloud dashboard...');
              setTimeout(() => {
                navigate('/dashboard');
              }, 6000);
            } else {
              setAmountDue(parseFloat((organization?.get('perMonthAmount') * (organization?.get('paymentCycle') === PricingSchedules.Annual ? 12 : 6)).toFixed(2)));
              setName(
                organization?.get('admin')?.get('firstName')
                  ? organization?.get('admin')?.get('firstName') + ' ' + organization?.get('admin')?.get('lastName')
                  : organization?.get('name')
              );
              const publishableKey = process.env.REACT_APP_STRIPE_PK_TEST;
              if (publishableKey) {
                setStripe(loadStripe(publishableKey));
              }
              setLoading(false);
            }
          } else {
            setLoading(false);
            setError('Error fetching your payment details. Taking you back to login...');
            const logout = await userRepository.logout();
            if (logout) {
              dispatch(resetUserData());
              dispatch(setCloseSettings());
            }
            setTimeout(() => {
              navigate('/login');
            }, 6000);
          }
        } else {
          setLoading(false);
          setError('Error fetching your payment details. Taking you back to login...');
          const logout = await userRepository.logout();
          if (logout) {
            dispatch(resetUserData());
            dispatch(setCloseSettings());
          }
          setTimeout(() => {
            navigate('/login');
          }, 6000);
        }
      } else {
        setLoading(false);
        setError('Error fetching your payment details. Taking you back to login...');
        const logout = await userRepository.logout();
        if (logout) {
          dispatch(resetUserData());
          dispatch(setCloseSettings());
        }
        setTimeout(() => {
          navigate('/login');
        }, 6000);
      }
    };

    fetchOrganizationDetails();
  }, []);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : error !== '' ? (
        <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'2rem'}>
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {error}
          </Typography>
        </Box>
      ) : hasPermission ? (
        <Box width={'100%'} minHeight={'100vh'}>
          <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} color={globalColors.white} padding={'2rem'}>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const userRepository = new UserRepository();
                  const logout = await userRepository.logout();
                  if (logout) {
                    dispatch(resetUserData());
                    dispatch(setCloseSettings());
                    navigate('/login');
                  }
                }}
              />
              <Typography
                fontSize={'12px'}
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const userRepository = new UserRepository();
                  const logout = await userRepository.logout();
                  if (logout) {
                    dispatch(resetUserData());
                    dispatch(setCloseSettings());
                    navigate('/login');
                  }
                }}
              >
                Logout
              </Typography>
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={'2rem'} padding={'3rem'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxWidth={'md'} flexDirection={'column'} gap={'1rem'}>
              <Typography variant="h6" textAlign={'center'} sx={{ color: globalColors.white }}>
                Hi <span style={{ color: globalColors.mainBlue }}>{name}</span>,<br />
                please initiate your total payment of
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>&nbsp;${amountDue.toFixed(2)}&nbsp;</span>
                to renew your access to the{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue, whiteSpace: 'nowrap' }}>Property Cloud</span> network.
              </Typography>
              <Typography textAlign={'center'} sx={{ color: globalColors.gray }}>
                Due Date:{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>{formatDate(organizationObject?.get('nextPaymentDue'))}</span>
              </Typography>
              <Typography textAlign={'center'} sx={{ color: globalColors.gray }} zIndex={999999}>
                This payment is for your{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>
                  {organizationObject?.get('paymentCycle')} {organizationObject?.get('tier')}
                </span>{' '}
                plan, for upto{' '}
                <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>{organizationObject?.get('memberCount')} users</span>.<br />
                If you want to change your plan, you can do so by clicking{' '}
                <span
                  onClick={() => {
                    navigate('/renewal/changePlan');
                  }}
                  style={{ fontWeight: 'bold', color: globalColors.mainBlue, textDecoration: 'underline', cursor: 'pointer', zIndex: 999999 }}
                >
                  here
                </span>
                .
              </Typography>
            </Box>
            {stripe && amountDue !== 0 && (
              <Elements
                stripe={stripe}
                options={{
                  mode: 'payment',
                  currency: 'usd',
                  amount: amountDue * 100,
                  loader: 'always',
                  appearance: {
                    theme: 'night',
                    labels: 'floating',
                    variables: {
                      colorPrimaryText: globalColors.white,
                      focusOutline: `1px solid ${globalColors.mainBlue}`,
                      fontFamily: 'sans-serif',
                      colorPrimary: globalColors.mainBlue,
                      colorTextPlaceholder: globalColors.gray,
                      colorText: globalColors.white,
                      colorTextSecondary: globalColors.white,
                      colorBackground: globalColors.blackLight,
                      colorDanger: globalColors.red,
                    },
                  },
                }}
              >
                {/* <PaymentForm successURL={`/onboarding/secure/payment/success/${id}`}/> */}
                <PaymentForm successURL={`https://propertycloud.ai/renewal/secure/payment/confirmation`} preProcessingPayment={fetchClientSecret} />
              </Elements>
            )}
          </Box>
        </Box>
      ) : <NoPermission />}
    </Box>
  );
};

export default PendingPayment;
