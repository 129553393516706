import React, { CSSProperties, ReactNode } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { globalColors } from '../../utils/constants/color';
import { selectColor } from '../../store/selector';
import { Box, Button, Tooltip, Typography } from '@mui/material';

interface ContactToolTipProps {
  value: string;
  href: string;
  children: ReactNode;
  action: string;
}

export const ContactToolTip = ({ value, href, children, action }: ContactToolTipProps) => {
  const color = useSelector(selectColor);

  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    color: color || globalColors.blue,
  };

  // const indicatorStyles: CSSProperties = {
  //     background: '#fff',
  //     width: 8,
  //     height: 8,
  //     display: 'inline-block',
  //     margin: '0 8px',
  // };

  return (
    <Tooltip
      title={
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography>{value}</Typography>
          <Button sx={{ color: color || globalColors.blue }} href={href}>
            {action}
          </Button>
        </Box>
      }
    >
      <Box height={'1rem'} width={'1rem'}>
        {children}
      </Box>
    </Tooltip>
  );
};
