import { Box, Modal, styled } from '@mui/material';
import { FormForgotPassword } from '../../components/organisms';
import { globalColors } from '../../utils/constants/color';
import ModalVerificationCode from './modalVerificationCode';
import React from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '525px',
  height: '480px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  backgroundColor: globalColors.blackLight,
  padding: '1.5rem',
  borderRadius: '20px',

  '@media (max-width: 576px)': {
    width: '80%',
  },
};

interface ModalForgotPasswordProps {
  open: boolean;
  onClose: () => void;
  onLogin: () => void;
}
const ModalForgotPassword = ({ open, onClose }: ModalForgotPasswordProps) => {
  // * State for Modal verification
  const [openModalVerification, setOpenModalVerification] = React.useState(false);

  const handleOpenModalVerification = () => setOpenModalVerification(true);
  const handleCloseModalVerification = () => setOpenModalVerification(false);

  return (
    <ModalForgotPasswordStyle>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-forgot-password"
        aria-describedby="modal-request-forgot-password"
        className="content"
      >
        <Box sx={style} className="content">
          <FormForgotPassword onConfirm={handleOpenModalVerification} onClose={onClose} />
          {/* Modal Verification Reset Password */}
          <ModalVerificationCode open={openModalVerification} onClose={handleCloseModalVerification} onLogin={handleCloseModalVerification} />
        </Box>
      </Modal>
    </ModalForgotPasswordStyle>
  );
};

const ModalForgotPasswordStyle = styled('div')(({ theme }) => ({
  // TODO : debuging responsive
  // '& .content': {
  //   [theme.breakpoints.down('sm')]: {
  //     backgroundColor: 'cyan !important',
  //   },
  //   [theme.breakpoints.up('sm')]: {
  //     backgroundColor: 'purple !important',
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     backgroundColor: 'pink !important',
  //   },
  //   [theme.breakpoints.up('lg')]: {
  //     backgroundColor: 'yellow !important',
  //   },
  //   [theme.breakpoints.up('xl')]: {
  //     backgroundColor: 'green !important',
  //   },
  // },
}));

export default ModalForgotPassword;
