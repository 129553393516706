import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import NoteModel from '../models/noteModel';
import UserRepository from './userRepository';
import LeadRepository from './leadRepository';
import CommunicationModel from '../models/communicationModel';

class CommunicationRepository {
  private className = 'Communication';

  // constructor() {}

  public async create(object: CommunicationModel): Promise<Parse.Object | null> {
    try {
      const Communication = Parse.Object.extend(this.className);
      const communication = new Communication();
      communication.set('method', object.method);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        communication.set('doneBy', currentUser);
      } 
      else {
        return null;
      }
      communication.set('note', object.note)

      return new Promise((resolve, _) => {
        communication.save(null, { useMasterKey: true }).then(
          (savedCommunication: any) => {
            // Toast(`Note added successfully!`, 'success');
            // const leadRepository = new LeadRepository();
            // leadRepository.getObjectById(note.leadId).then((lead) => {
            //   if (lead) {
            //     lead.relation('notes').add(savedNote);
            //     // lead.relation('notes').query().include(['createdBy']).find().then((note) => {
            //     //   note.get('createdBy').get('firstName');
            //     // })
            //     lead
            //       .save(null, { useMasterKey: true })
            //       .then((updatedLead) => {
            //         // Updated user
            //         resolve(savedNote);
            //       })
            //       .catch((error) => {
            //         resolve(null);
            //         // Handle the error here
            //         console.error('Error updating user data:', error);
            //       });
            //   } else {
            //     resolve(null);
            //   }
            // });
            resolve(savedCommunication);
          },
          (error: any) => {
            // Toast(`Error adding note ${error}`, 'error');
            console.error('Error adding note:', error);
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error adding note:', error);
      return null;
    }
  }

  // Read
  public getObjectById(id: number) {}
}

export default CommunicationRepository;
