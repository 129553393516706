import { useNavigate } from 'react-router-dom';
import { IFormDetailProject, ProjectFilterDataFtn, onShowModalBulk } from '../../../store/projects';
import { FilterDataFtn, IFormDetailProperty } from '../../../store/property';
import { ListProductsProps, ProductCategories } from '../../../utils/types/products';
import CardProductItem from '../../molecules/products/cardProductItem';
import { CardProductsStyle } from './cardProducts.style';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Typography } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import ModalBulkEditProduct from './modalBulkEditProduct';
import NotFoundLottie from '../../atoms/notFound';
import { UserRole } from '../../../store/user';
import { NavAddFilterView } from '../../molecules';
import {
  selectBulkEdit,
  selectColor,
  selectProjectFilter,
  selectPropertiesFilter,
  selectSearchProject,
  selectSearchProperties,
  selectShowModalBulkEdit,
  selectThemeMode,
  selectUserData,
  selectUserRole,
} from '../../../store/selector';
import Loader from '../../atoms/loader';
import CardPropertyItem from '../../molecules/products/cardPropertyItem';
import { setAddNew } from '../../../store/routes';
import Paginator from '../../atoms/paginator';

/**
 * Renders the CardProjects component.
 * ! This component can be used for Projects and Properties
 *
 * @return {JSX.Element} The rendered CardProjects component.
 */
const CardProducts = (props: ListProductsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { category } = props;
  const isProperty = category === ProductCategories.Property;
  const isProject = category === ProductCategories.Project;

  const dataProperties = props.datas as IFormDetailProperty[];
  const dataProjects = props.datas as IFormDetailProject[];

  const edit = useSelector(selectBulkEdit);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const showModalBulkEdit = useSelector(selectShowModalBulkEdit);
  const user = useSelector(selectUserData);
  const userRole = useSelector(selectUserRole);
  const PropertiesfilterData = useSelector(selectPropertiesFilter);
  const ProjectfilterData = useSelector(selectProjectFilter);
  const Projectsearch = useSelector(selectSearchProject);
  const Propertiessearch = useSelector(selectSearchProperties);

  const isCanEdit = user?.role !== UserRole.executive;

  const onButtonCircleClick = (id: string) => {
    if (isProperty) {
      dispatch(setAddNew({ addNew: true, path: '/properties' }));
      return navigate(`/properties/edit/${id}`);
    } else {
      dispatch(setAddNew({ addNew: true, path: '/projects' }));
      return navigate(`/projects/edit/${id}`);
    }
  };

  return (
    <>
      <NavAddFilterView
        onlyShowAddButton={(isProperty && dataProperties.length === 0) || (isProject && dataProjects.length === 0)}
        onChangeView={props.handleSwitchView}
        view={props.view}
        property={isProperty}
        onClickNewProduct={props.onClickNewProduct}
        titleButton={props.category === ProductCategories.Project ? `Add New Project` : `Add New Property`}
      />
      {props?.isLoading ? (
        <Loader />
      ) : (
        <>
          {isProperty ? (
            <Typography
              sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
              onClick={() => dispatch(FilterDataFtn({ status: '', priceLow: 0, priceHigh: 0, location: '', minArea: 0, maxArea: 0 }))}
            >
              {PropertiesfilterData.status !== '' ||
              PropertiesfilterData.priceLow !== 0 ||
              PropertiesfilterData.priceHigh !== 0 ||
              PropertiesfilterData.location !== '' ||
              PropertiesfilterData.minArea !== 0
                ? 'Clear Filter'
                : ''}
            </Typography>
          ) : (
            <Typography
              sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
              onClick={() => dispatch(ProjectFilterDataFtn({ status: '', type: '', purpose: '' }))}
            >
              {ProjectfilterData.status !== '' || ProjectfilterData.type !== '' || ProjectfilterData.purpose !== '' ? 'Clear Filter' : ''}
            </Typography>
          )}
          {(isProperty && dataProperties.length > 0) || (isProject && dataProjects.length > 0) ? (
            <CardProductsStyle>
              {edit && (
                <Checkbox
                  sx={{
                    color: color || globalColors.blue,
                    '&.Mui-checked': {
                      color: color || globalColors.secondBlue,
                    },
                  }}
                  onChange={props.handleHeaderCheckboxChange}
                  checked={Object.values(props.rowCheckboxState).length > 0 && Object.values(props.rowCheckboxState).every((isChecked) => isChecked)}
                />
              )}
              <div className="content">
                {/* RENDER PROPERTIES */}
                {isProperty && (
                  <>
                    {dataProperties?.map((data) => (
                      <CardPropertyItem
                        edit={edit}
                        mode={props.mode}
                        key={data.id}
                        category={category}
                        data={data}
                        onEditClick={() => onButtonCircleClick(data.id)}
                        isCanEdit={isCanEdit}
                        rowCheckboxState={props.rowCheckboxState}
                        setRowCheckboxState={props.setRowCheckboxState}
                        active={true}
                      />
                    ))}
                  </>
                )}

                {/* RENDER PROJECTS */}
                {isProject && (
                  <>
                    {dataProjects?.map((data) => (
                      <CardProductItem
                        edit={edit}
                        mode={props.mode}
                        key={data.id}
                        category={category}
                        data={data}
                        onEditClick={() => onButtonCircleClick(data.id)}
                        isCanEdit={isCanEdit}
                        rowCheckboxState={props.rowCheckboxState}
                        setRowCheckboxState={props.setRowCheckboxState}
                        active={true}
                      />
                    ))}
                  </>
                )}
              </div>
              <ModalBulkEditProduct
                rowCheckboxState={props?.rowCheckboxState}
                open={showModalBulkEdit}
                isProperty={isProperty}
                onClose={() => dispatch(onShowModalBulk(false))}
                mode={mode}
                color={color}
              />
            </CardProductsStyle>
          ) : isProperty ? (
            <>
              {Propertiessearch === '' &&
              PropertiesfilterData.status === '' &&
              PropertiesfilterData.priceLow === 0 &&
              PropertiesfilterData.location === '' &&
              PropertiesfilterData.minArea === 0 ? (
                <NotFoundLottie
                  showButton={userRole !== UserRole.executive}
                  text={
                    userRole !== UserRole.executive
                      ? 'Looks like you have not added any properties yet. Press the button below and add a new property right now.'
                      : 'Looks like your company has not added any properties yet. Contact your manager or admin to add a new property.'
                  }
                  buttonText="Add New Property"
                  navigateTo="/properties/new"
                  setAddNewPath="/properties"
                />
              ) : (
                <NotFoundLottie
                  showButton={userRole !== UserRole.executive}
                  text={
                    userRole !== UserRole.executive
                      ? 'Looks like you have not added any properties yet. Press the button below and add a new property right now.'
                      : 'Looks like your company has not added any properties yet. Contact your manager or admin to add a new property.'
                  }
                  buttonText="Add New Property"
                  navigateTo="/properties/new"
                  setAddNewPath="/properties"
                />
              )}
            </>
          ) : isProject ? (
            <>
              {Projectsearch === '' && ProjectfilterData.status !== '' && ProjectfilterData.type !== '' && ProjectfilterData.purpose !== '' ? (
                <NotFoundLottie
                  showButton={userRole !== UserRole.executive}
                  text={
                    userRole !== UserRole.executive
                      ? 'Looks like you have not added any projects yet. Press the button below and add a new project right now.'
                      : 'Looks like your company has not added any projects yet. Contact your manager or admin to add a new project.'
                  }
                  buttonText="Add New Project"
                  navigateTo="/projects/new"
                  setAddNewPath="/projects"
                />
              ) : (
                <NotFoundLottie
                  showButton={userRole !== UserRole.executive}
                  text={
                    userRole !== UserRole.executive
                      ? 'Looks like you have not added any projects yet. Press the button below and add a new project right now.'
                      : 'Looks like your company has not added any projects yet. Contact your manager or admin to add a new project.'
                  }
                  buttonText="Add New Project"
                  navigateTo="/projects/new"
                  setAddNewPath="/projects"
                />
              )}
            </>
          ) : (
            <></>
          )}
          <Paginator
            getTotalCount={props.getTotalCount}
            onChangePage={async (newPage) => {
              await props.onChangePage(newPage);
              return true;
            }}
          />
        </>
      )}
    </>
  );
};

export default CardProducts;
