import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { Form } from 'react-router-dom';
import { globalColors } from '../../../utils/constants/color';
import { ButtonIconCircle, ButtonText, FieldInput } from '../../atoms';
import { HeadingFormAuth } from '../../molecules';
import GroupInput from '../../molecules/groupInput';
import { useRegister } from '../../../hooks/auth';
import { FieldCover } from '../../molecules/productDetail';
import { ICAddImage, ICDelete } from '../../../assets/Icons';
import { ChangeEvent, useState } from 'react';
import { validateUploadImage } from '../../../utils/helpers';
import { IImages } from '../../../store/projects';
import Dropdown from '../../atoms/dropdown';

interface ValuesFormRegister {
  companyName: string;
  companyAddress: string;
  numberOfEmployees: string;
  companyRegistrationYear: string;
  // ownerName: string;
  // contractOfOwner: string;
  companyPhoneNumber: string;
  secondNumber: string;
  pointOfContact: string;
  contactDesignation: string;
  companyWebsite: string;
  aboutUs: string;
  logo: string;
}

interface FormRegisterProps {
  className?: string;
  onLogin: () => void;
  onClose: () => void;
}

const FormRegister = ({ className, onLogin, onClose }: FormRegisterProps) => {
  const { validationSchema } = useRegister();
  const [logo, setLogo] = useState<IImages | null>(null);

  const handleInputImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const resultFiles = validateUploadImage(files);

      if (resultFiles.length > 0) {
        const processUrl = URL.createObjectURL(resultFiles[0]);
        const image = {
          id: Math.random().toString(36).substr(2, 9),
          file: resultFiles[0],
          url: processUrl,
        };
        setLogo(image);
      }
    }
  };

  return (
    <FormRegisterStyle className={className}>
      {/* HEADING */}
      <HeadingFormAuth
        title="Request an Account"
        subTitle={'Enter the required information below and submit a request to us. Application processing times vary from 24 - 48 hours.'}
        onGoBack={onClose}
      />

      {/* FORM GROUP */}
      <Formik
        initialValues={{
          companyName: '',
          companyAddress: '',
          numberOfEmployees: '',
          companyRegistrationYear: '',
          // ownerName: '',
          // contractOfOwner: '',
          companyPhoneNumber: '',
          secondNumber: '',
          pointOfContact: '',
          contactDesignation: '',
          companyWebsite: '',
          aboutUs: '',
          logo: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values: ValuesFormRegister, { setSubmitting }: FormikHelpers<ValuesFormRegister>) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 500);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap="1.5rem">
              <Box display="flex" justifyContent={'start'} flexDirection={'column'} alignItems={'start'} gap="10px">
                <Box display="flex" gap="10px" mb={'0.8rem'} fontSize="1rem">
                  <Typography color={globalColors.white} fontSize="1rem">
                    Company Logo
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'red' }}>
                    *
                  </Typography>
                </Box>
                <UploadImageStyle className={''}>
                  {logo === null ? (
                    <>
                      <div className="image btn-add">
                        <input type="file" onChange={handleInputImage} />
                        <img src={ICAddImage} alt="Add Company Logo" />
                      </div>
                      {errors.logo && (
                        <Typography variant="caption" className="error" sx={{ color: 'red' }}>
                          {errors.logo}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <div className="photo">
                      <img key={logo.id} className="add image" src={logo.url} alt={logo.url} />
                      <div className="btn-delete">
                        <ButtonIconCircle
                          type="button"
                          src={ICDelete}
                          onClick={() => {
                            setLogo(null);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </UploadImageStyle>
              </Box>
              <GroupInput className="group-input">
                <FieldInput
                  label="Company Name"
                  id="companyName"
                  name="companyName"
                  placeholder="Company Name"
                  optional={false}
                  type="text"
                  error={errors.companyName && touched.companyName ? errors.companyName : false}
                />
                <FieldInput
                  label="Company Address"
                  id="companyAddress"
                  name="companyAddress"
                  placeholder="Full Address"
                  optional={false}
                  type="text"
                  error={errors.companyAddress && touched.companyAddress ? errors.companyAddress : false}
                />
              </GroupInput>
              <GroupInput className="group-input">
                <FieldInput
                  label="Number of Employees"
                  id="numberOfEmployees"
                  name="numberOfEmployees"
                  optional={false}
                  type="number"
                  placeholder="Number of Employees"
                  error={errors.numberOfEmployees && touched.numberOfEmployees ? errors.numberOfEmployees : false}
                />
                <FieldInput
                  label="Registration Year"
                  id="companyRegistrationYear"
                  name="companyRegistrationYear"
                  placeholder="Company Registration Year"
                  optional={false}
                  type="number"
                  error={errors.companyRegistrationYear && touched.companyRegistrationYear ? errors.companyRegistrationYear : false}
                />
              </GroupInput>
              {/* <GroupInput className="group-input">
                <FieldInput label="Owner Name" id="ownerName" name="ownerName" placeholder="Full Name" type="text" />
                <FieldInput
                  label="Owner Contact"
                  id="contractOfOwner"
                  name="contractOfOwner"
                  placeholder="Owner Contact"
                  type="text"
                  error={errors.contractOfOwner && touched.contractOfOwner ? errors.contractOfOwner : false}
                />
              </GroupInput> */}
              <GroupInput className="group-input">
                <FieldInput
                  label="Company Primary Phone"
                  id="companyPhoneNumber"
                  name="companyPhoneNumber"
                  placeholder="Company Primary Phone"
                  optional={false}
                  type="number"
                  error={errors.companyPhoneNumber && touched.companyPhoneNumber ? errors.companyPhoneNumber : false}
                />
                <FieldInput
                  label="Company Secondary Phone"
                  id="secondNumber"
                  name="secondNumber"
                  placeholder="Company Secondary Phone"
                  type="number"
                  error={errors.secondNumber && touched.secondNumber ? errors.secondNumber : false}
                />
              </GroupInput>
              <GroupInput className="group-input">
                <FieldInput
                  label="Point of Contact"
                  id="pointOfContact"
                  name="pointOfContact"
                  placeholder="Full Name"
                  optional={false}
                  type="text"
                  error={errors.pointOfContact && touched.pointOfContact ? errors.pointOfContact : false}
                />
                <FieldInput
                  label="Designation"
                  id="contactDesignation"
                  name="contactDesignation"
                  placeholder="Designation of Point of Contact"
                  optional={false}
                  type="text"
                  error={errors.contactDesignation && touched.contactDesignation ? errors.contactDesignation : false}
                />
              </GroupInput>
              <GroupInput className="group-input">
                <FieldInput label="Company Website" id="companyWebsite" name="companyWebsite" placeholder="Link to Company Website" type="text" />
                <Dropdown
                  label="How'd You Hear About Us"
                  mode={'dark'}
                  id="aboutUs"
                  name="aboutUs"
                  values={[
                    'Select',
                    'Search Engine (e.g., Google, Bing)',
                    'Social Media (e.g., Facebook, Twitter, Instagram)',
                    'Word of Mouth / Referral',
                    'Website / Blog',
                    'Other',
                  ]}
                  defaultValue={'Select'}
                />
                {/* <FieldInput
                  label="How'd You Hear About Us?"
                  id="aboutUs"
                  name="aboutUs"
                  placeholder="Input how did you hear about us"
                  type="text"
                  // error={errors.aboutUs && touched.aboutUs ? errors.aboutUs : false}
                /> */}
              </GroupInput>
            </Box>
            <Box className="wrapper-btn-submit">
              <Button variant="contained" className="btn-submit" type="submit">
                Submit Request
              </Button>
              <Stack direction="column" gap="0px" alignItems={'center'} paddingTop={'20px'}>
                <Typography color={globalColors.text.secondary} fontSize={'13px'}>
                  Already have an account?
                </Typography>
                <ButtonText onClick={onLogin}>Login</ButtonText>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </FormRegisterStyle>
  );
};

const FormRegisterStyle = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  borderRadius: '20px',
  backgroundColor: globalColors.blackLight,
  marginBottom: '2rem',
  marginTop: '2rem',
  // TODO : style : fix component Link login
  // TODO : Responsive test
  // [theme.breakpoints.up('sm')]: {
  //   width: 250,
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: 350,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 450,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'pink',
  //   width: '1002px',
  // },
  overflow: 'auto',
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
  '& .wrapper-btn-submit': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2.5rem',
    alignItems: 'center',
    '& .btn-submit': {
      marginBottom: '1rem',
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& button': {
    textTransform: 'none',
  },
}));

const UploadImageStyle = styled('div')(({ theme }) => ({
  '& .image': {
    borderRadius: '12px',
    width: '200px',
    minWidth: '200px',
    height: '200px',
    objectFit: 'contain',
  },
  '& .btn-add': {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    border: `1px solid ${globalColors.mainBlue}`,
    borderRadius: '12px',
    width: '200px',
    height: '200px',
    '& input': {
      cursor: 'pointer',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },

  '& .photo': {
    position: 'relative',
    '& .btn-delete': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      '& img': {
        width: '1rem',
        height: '1rem',
      },

      '&:hover': {
        opacity: '0.7',
      },
    },
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '100%',
      height: '150px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '.photo': {
      width: 'max-content',
    },
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '150px',
      height: '150px',
    },
    '& .btn-add': {
      width: '150px',
      height: '150px',
    },
  },
}));

export default FormRegister;
