import React, { useEffect, useState } from 'react';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import { globalColors } from '../../../utils/constants/color';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { BsCameraVideo, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import LeadModel from '../../../utils/api/models/leadModel';
import Loader from '../../atoms/loader';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../store/user';
import { ContactToolTip } from '../../atoms/contactTooltip';
import { formatDate } from '../../../utils/helpers';

interface LeadsDetailsProp {
  id: string;
  mode: string;
  color: string;
}

const LeadsDetails = ({ id, mode, color }: LeadsDetailsProp) => {
  const [lead, setLead] = useState<LeadModel | null>(null);
  const [loading, setloading] = useState<boolean>(true);
  const [revealCommunication, setRevealCommunication] = useState(false);

  const getLeads = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const repository = new LeadRepository();
      if (id) {
        await repository
          .getModelById(id)
          .then((res) => {
            if (res) {
              setLead(res);
              setRevealCommunication(currentUser.get('role') === UserRole.admin || res.assignedTo.id === currentUser.id);
              setloading(false);
            } else {
              setloading(false);
            }
          })
          .catch((err) => {
            setLead(null);
            setloading(false);
          });
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <>
      <Box
        className="scroll"
        marginTop={'30px'}
        display={'flex'}
        sx={{ overflow: 'auto', height: '65vh' }}
        flexDirection={'column'}
        gap={'1rem'}
        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
        padding={'20px'}
        borderRadius={'10px'}
        color={globalColors.white}
        fontSize={'12px'}
      >
        {loading ? (
          <Box display={'flex'} flexDirection={'column'} gap={'0'} marginBottom={'10px'}>
            <Loader />
          </Box>
        ) : (
          <>
            {lead ? (
              <>
                <Box display={'flex'} flexDirection={'column'} gap={'0'} marginBottom={'10px'}>
                  <Typography
                    fontSize={'20px'}
                    textTransform={'capitalize'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                    marginTop={'auto'}
                    marginBottom={'auto'}
                  >
                    {lead?.firstName + ' ' + lead?.lastName}
                  </Typography>
                  <Typography marginTop={'auto'} marginBottom={'auto'} color={color || globalColors.lightblue}>
                    {lead?.category}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Assigned To
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {`${lead?.assignedTo.get('firstName')} ${lead?.assignedTo.get('lastName')}`}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Assigned At
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {lead?.assignedAt ? `${formatDate(lead?.assignedAt)}` : '-'}
                  </Typography>

                  {/* <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {`${lead?.assignedAt.get('assignedAt')} ${lead?.assignedAt.get('assignedAt')}`}
                  </Typography> */}
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Priority
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {lead?.priority}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Status
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {lead?.status}
                  </Typography>
                </Box>

                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Communication
                  </Typography>
                  <Typography sx={{ display: 'flex', gap: '20px' }}>
                    {lead.primaryPhone &&
                      (revealCommunication ? (
                        <ContactToolTip value={lead.primaryPhone} href={`tel:${lead.primaryPhone}`} action={'Call'}>
                          <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                        </ContactToolTip>
                      ) : (
                        <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                      ))}
                    {lead.secondaryPhone &&
                      (revealCommunication ? (
                        <ContactToolTip value={lead.secondaryPhone} href={`tel:${lead.secondaryPhone}`} action={'Call'}>
                          <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                        </ContactToolTip>
                      ) : (
                        <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                      ))}
                    {lead.email &&
                      (revealCommunication ? (
                        <ContactToolTip value={lead.email} href={`mailto:${lead.email}`} action={'Email'}>
                          <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
                        </ContactToolTip>
                      ) : (
                        <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
                      ))}
                  </Typography>
                </Box>
              </>
            ) : (
              <Typography>Lead details could not be found.</Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default LeadsDetails;
