import { Box, Modal } from '@mui/material';
import React from 'react';
import { FormVerificationCode } from '../../components/organisms';
import { globalColors } from '../../utils/constants/color';
import ModalResetPassword from './modalResetPassword';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '525px',
  height: '480px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  backgroundColor: globalColors.blackLight,
  padding: '1.5rem',
  borderRadius: '20px',
  '@media (max-width: 576px)': {
    width: '80%',
  },
};

interface ModalForgotPasswordProps {
  open: boolean;
  onClose: () => void;
  onLogin: () => void;
}
const ModalVerificationCode = ({ open, onClose }: ModalForgotPasswordProps) => {
  // * State for Modal verification
  const [openModalResetPassword, setopenModalResetPassword] = React.useState(false);
  const handleOpenModalResetPassword = () => setopenModalResetPassword(true);
  const handleCloseModalResetPassword = () => setopenModalResetPassword(false);

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="modal-forgot-password" aria-describedby="modal-request-forgot-password" hideBackdrop={true}>
        <Box sx={style}>
          <FormVerificationCode onConfirm={handleOpenModalResetPassword} onClose={onClose} />

          {/* MODAL Reset Password */}
          <ModalResetPassword open={openModalResetPassword} onClose={handleCloseModalResetPassword} />
        </Box>
      </Modal>
    </>
  );
};

export default ModalVerificationCode;
