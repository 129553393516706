import { Box, Typography } from '@mui/material';
import { BsArrowRight } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../utils/constants/color';
import { selectColor, selectThemeMode } from '../../../store/selector';
import TaskRepository from '../../../utils/api/repositories/taskRepository';
import Loader from '../../atoms/loader';
import Heading from '../../atoms/heading';
import NotFoundLottie from '../../atoms/notFound';
import { formatDate } from '../../../utils/helpers';
import { useNavigate } from 'react-router';

const UpcomingTask = () => {
  const [isHovered, setIsHovered] = useState(false);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [loading, setLoading] = useState(true);
  const [upcomingTasks, setUpcomingTasks] = useState<Parse.Object[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUpcoming = async () => {
      const taskRepository = new TaskRepository();
      const tasks = await taskRepository.getUpcoming();
      if (tasks) {
        console.log('TASKS', tasks);
        setUpcomingTasks(tasks);
      }
      setLoading(false);
    };

    fetchUpcoming();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Box style={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box>
          <Box paddingBottom={'25px'} paddingTop={'10px'}>
            <Heading text={'Upcoming Tasks'} />
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
            {upcomingTasks.length > 0 ? (
              <>
                {upcomingTasks?.map((task: Parse.Object) => (
                  <Box
                    className="activetask"
                    key={task.id}
                    component={'span'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'start'}
                    position={'relative'}
                    sx={{
                      paddingLeft: '20px',
                    }}
                    onClick={() => {}}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: '16px' }}
                      alignItems={'left'}
                      textAlign={'left'}
                      color={mode === 'dark' ? '#F6F6F6' : globalColors.black}
                    >
                      {task.get('title')}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      sx={{ fontSize: '12px' }}
                      alignItems={'left'}
                      textAlign={'left'}
                      color={color !== 'default' && color !== '' ? color : '#599BF7'}
                    >
                      {formatDate(task.get('dueDate'))}
                    </Typography>

                    <Box
                      className="left-border"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '4px',
                        backgroundColor: color || '#599BF7',
                        borderRadius: '16px',
                      }}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <NotFoundLottie
                size="small"
                text="Looks like you have no upcoming tasks. Press the button below to create one."
                buttonText="Create a Task"
                navigateTo="/activity/tasks"
                showButton
              />
            )}
          </Box>
        </Box>
        {upcomingTasks.length > 0 ? (
          <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            display={'flex'}
            justifyContent={'end'}
            sx={{ cursor: 'pointer' }}
            gap={'10px'}
            marginTop={'35px'}
            onClick={() => {
              navigate('/activity/tasks');
            }}
          >
            <Typography fontWeight={500} color={mode === 'dark' ? globalColors.white : globalColors.black} alignContent={'right'} textAlign={'right'}>
              View all Tasks
            </Typography>
            <Box
              style={{
                transition: 'transform 0.3s',
                transform: isHovered ? 'translateX(5px)' : 'translateX(0)',
              }}
            >
              <BsArrowRight size={'1.5rem'} color={mode === 'dark' ? globalColors.white : globalColors.black} style={{ margin: 'auto' }} />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default UpcomingTask;
