import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { BsArrowRight, BsTelephone } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { BiEnvelope } from 'react-icons/bi';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import Loader from '../../../atoms/loader';
import LeadModel from '../../../../utils/api/models/leadModel';
import { useNavigate } from 'react-router-dom';
import { selectColor, selectThemeMode } from '../../../../store/selector';
import NotFoundLottie from '../../../atoms/notFound';
import Heading from '../../../atoms/heading';
import LeadsFlter from './filter';
import SortTable from './sort';
import { useStyles } from '../style';

const LeadsTable = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setloading] = useState<boolean>(true);
  const [showLead, setshowLead] = useState<LeadModel[]>([]);
  const [show, setShow] = useState<boolean>(true);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();
  const header = ['Name', 'Assigned To', 'Category', 'Status'];
  const classes = useStyles()

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const GetAllLeads = async () => {
    const repository = new LeadRepository();
    await repository
      .getSome()
      .then((res) => {
        console.log(res);
        setshowLead(res);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };
  useEffect(() => {
    GetAllLeads();
  }, []);

  return (
    <Box borderRadius="12px" overflow={'hidden'}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <NavMenuStyle>
            <Box className="filter-wrapper">
              <Heading text={'Leads'} />

              {/* {showLead.length > 0 && <Box display={'flex'} gap={'20px'}>
                <LeadsFlter mode={mode} />
                <SortTable mode={mode} />
              </Box>} */}
            </Box>
          </NavMenuStyle>
          {showLead.length > 0 ? 
          (<>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                border: 'none',
                boxShadow: 'none',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
                paddingBottom: '20px',
                borderRadius: '10px',
                marginTop: '20px',
              }}
            >
              {
                <Table
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {header?.map((val, idx) => (
                        <TableCell>
                          <Typography key={idx} fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                            {val}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {showLead?.map((val, idx) => (
                      <TableRow
                        sx={{
                          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,

                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                          borderRadius: '10px',
                          gap: '20px',
                          marginBottom: '20px',
                          borderBottom: `10px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
                          '&:hover': {
                            backgroundColor: mode === 'dark' ? globalColors?.darkblack : '',
                          },
                        }}
                        key={val.id}
                      >
                        <TableCell sx={style} component="th" scope="row">
                          {`${val.firstName} ${val.lastName}`}
                        </TableCell>
                        <TableCell sx={style}>{val?.assignedTo ? `${val?.assignedTo.get('firstName')} ${val?.assignedTo?.get('lastName')}` : '-'}</TableCell>
                        {/* <TableCell sx={style}>
                          <Box display="flex" gap={1} alignItems="center" justifyContent="flex-start">
                            <BsTelephone size={'0.8rem'} color={color || globalColors.blue} />
                            <BiEnvelope size={'0.8rem'} color={color || globalColors.blue} />
                          </Box>
                        </TableCell> */}
                        <TableCell sx={style}>{val?.category}</TableCell>
                        <TableCell sx={[style, {color: color}]}>{val?.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              }
            </TableContainer>

            {/* View All Leads */}
            <Box
              onClick={() => navigate('/leads')}
              display={'flex'}
              sx={{ cursor: 'pointer' }}
              justifyContent={'end'}
              component={'span'}
              gap={'10px'}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              marginTop={'30px'}
              marginBottom={'10px'}
              marginRight={'10px'}
            >
              <Typography fontWeight={500} color={mode === 'dark' ? globalColors.white : globalColors.black} alignContent={'right'} textAlign={'right'}>
                View all Leads
              </Typography>
              <Box
                style={{
                  transition: 'transform 0.3s',
                  transform: isHovered ? 'translateX(5px)' : 'translateX(0)',
                }}
              >
                <BsArrowRight size={'1.5rem'} color={mode === 'dark' ? globalColors.white : globalColors.black} style={{ margin: 'auto' }} />
              </Box>
            </Box>
          </>)
          : 
          <NotFoundLottie
            size={'small'}
            showButton={true}
            text="Looks like you have not added any leads yet. Press the button below and add a new lead right now."
            buttonText="Add New Lead"
            navigateTo="/leads/new"
          />}
        </>
      )}
    </Box>
  );
};

export default LeadsTable;

const NavMenuStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '& .filter-wrapper': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    paddingTop: '10px',
    paddingBottom: '25px',
  },

  [theme.breakpoints.down('md')]: {
    gap: '1rem',
    '& .btn-add': {
      width: '100%',
    },
    '& .filter-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& .btn-view, .filter': {
        width: '50%',
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    '& .filter-wrapper': {
      flexDirection: 'column',
      gap: '8px',
      '&:nth-child(even)': {
        flex: 1,
        width: '50%',
      },
      '& .btn-view, .filter': {
        width: '100%',
      },
    },
  },
}));
