import { Box, Modal } from "@mui/material";
import { FormResetPassword } from "../../components/organisms";
import { globalColors } from "../../utils/constants/color";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "480px",
  maxWidth: "525px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  backgroundColor: globalColors.blackLight,
  padding: "1.5rem",
  borderRadius: "20px",
  "@media (max-width: 576px)": {
    width: "80%",
  },
};

interface ModalResetPasswordProps {
  open: boolean;
  onClose: () => void;
}
const ModalResetPassword = ({ open, onClose }: ModalResetPasswordProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-content-wrapper"
      hideBackdrop={true}
    >
      <Box sx={style}>
        <FormResetPassword onClose={onClose} />
      </Box>
    </Modal>
  );
};

export default ModalResetPassword;
