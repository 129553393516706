import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Box, Typography, Select, MenuItem, SelectChangeEvent, ThemeProvider, InputLabel, FormGroup, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/styles';
import { selectNavigationLayout, selectThemeMode, selectColor, selectOrganizationTier } from '../../../../store/selector';
import Sidebar from '../../sidebar';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { globalColors } from '../../../../utils/constants/color';
import { Button, FieldInput, FieldInputTextarea, Toast } from '../../../atoms';
import { InputStyle, commentContainerStyle, crossFileContainerStyle } from './element.style';
import ClearIcon from '@mui/icons-material/Clear';
import FileIcon from './FileIcon';
import TaskRepository from '../../../../utils/api/repositories/taskRepository';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import Dropdowndata from '../../../atoms/dropdown2';
import { UserRole } from '../../../../store/user';
import SalesExecutiveRepository from '../../../../utils/api/repositories/salesExecutiveRepository';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { PricingTiers, formatDate, getInitials, hasPermissionForAction } from '../../../../utils/helpers';
import Dropdown from '../../../atoms/dropdown';
import { useNavigate, useParams } from 'react-router';
import Loader from '../../../atoms/loader';
import moment from 'moment';
import NotificationRepository from '../../../../utils/api/repositories/notificationRepository';
import NotificationModel from '../../../../utils/api/models/notificationModel';
import TaskModel from '../../../../utils/api/models/taskModel';
import NoPermission from '../../../atoms/noPermission';
import InsufficientPlan from '../../../atoms/insufficientPlan';

interface ValuesCreateTask {
  title: string;
  description: string;
  dueDate: string;
  priority: string;
  assignTo: string;
}

type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

const ActivityTaskPage = () => {
  const formikRef = useRef<FormikProps<ValuesCreateTask>>(null);
  const [formValues, setFormValues] = useState<ValuesCreateTask>({
    title: '',
    description: '',
    dueDate: '',
    priority: '',
    assignTo: '',
  });
  const [textValue, setTextValue] = useState<string | ''>('');
  const [changePriority, setChangePriority] = useState<string>('Cold');
  const [changeStatus, setChangeStatus] = useState<string>('Completed');
  const [comment, setComment] = useState('');
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const today = new Date().toISOString().split('T')[0];
  const [dueDate, setDueDate] = useState<Dayjs | null>(null);
  const [managersandSalesExecutive, setManagersandSalesExecutive] = useState<ManagerSalesExecutive[]>([]);
  const [hasPermission, setHasPermission] = useState(false);
  const organizationTier = useSelector(selectOrganizationTier);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [task, setTask] = useState<Parse.Object | null>(null);
  const [sendingReminder, setSendingReminder] = useState(false);
  const navigate = useNavigate();

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  const handleChangePriority = async (event: SelectChangeEvent<string>) => {
    setChangePriority(event.target.value);
  };

  const handleChangeStatus = async (event: SelectChangeEvent<string>) => {
    setChangeStatus(event.target.value);
  };

  const getTaskInformation = async () => {
    if (id) {
      const taskRepository = new TaskRepository();
      const task = await taskRepository.getObjectById(id);
      if (task) {
        setTask(task);
        setFormValues({
          title: task.get('title'),
          description: task.get('description'),
          dueDate: task.get('dueDate') ? formatDate(task.get('dueDate')) : '',
          priority: task.get('priority'),
          assignTo: task.get('assignedTo').id,
        });
        setDueDate(dayjs(task.get('dueDate')));
      }
    }
  };

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const userRole = currentUser?.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i]?.get('firstName'),
              lastName: managersAndSalesExecutives[i]?.get('lastName'),
              user: managersAndSalesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i]?.get('user').id,
              firstName: salesExecutives[i]?.get('user')?.get('firstName'),
              lastName: salesExecutives[i]?.get('user')?.get('lastName'),
              user: salesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser?.get('firstName'),
          lastName: currentUser?.get('lastName'),
          user: currentUser,
        };
        setManagersandSalesExecutive([userData]);
      }
    }
    setLoading(false);
  };

  const sendReminder = async () => {
    setSendingReminder(true);
    const notificationRepository = new NotificationRepository();
    const notification: NotificationModel = {
      title: task?.get('title'),
      body: task?.get('createdBy').get('firstName') + ' ' + task?.get('createdBy').get('lastName') + ' has sent you a reminder!',
      recipient: task?.get('assignedTo').id,
    };
    const savedNotification = await notificationRepository.create(notification);
    if (savedNotification) {
      Toast(
        `Reminder has been sent successfully to ${task?.get('assignedTo').get('firstName')} ${task?.get('assignedTo').get('lastName')}.`,
        'success'
      );
    } else {
      Toast('Reminder could not be sent successfully. Please try again', 'success');
    }
    setSendingReminder(false);
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Tasks', 'Update'));
    getTaskInformation();
    getManagersandSalesExecutive();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Tasks / Edit'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission && organizationTier == PricingTiers.Professional ? (
            <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
              <Box marginBottom={'10px'}></Box>
              {/* Assignment Container */}
              <AssignmentContainer
                bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                padding={'20px'}
                borderRadius={'16px'}
                color={globalColors.white}
                fontSize={'12px'}
                boxShadow={'#171717'}
                marginTop={'1rem'}
              >
                <Grid
                  container
                  display={'flex'}
                  justifyContent={'space-between'}
                  flexDirection={'row'}
                  width={'100%'}
                  alignItems={'center'}
                  marginBottom={'3rem'}
                  rowGap={'2rem'}
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    display={'flex'}
                    justifyContent={'start'}
                    flexDirection={'row'}
                    width={'100%'}
                    alignItems={'center'}
                    gap={'1rem'}
                  >
                    <Box
                      display={'flex'}
                      height={'60px'}
                      width={'60px'}
                      bgcolor={color}
                      borderRadius={100}
                      justifyContent={'center'}
                      alignItems={'center'}
                      minHeight={'60px'}
                      minWidth={'60px'}
                    >
                      <Typography fontWeight={600} fontSize={'24px'}>
                        {getInitials(task?.get('createdBy').get('firstName') + ' ' + task?.get('createdBy').get('lastName'))}
                      </Typography>
                    </Box>
                    <Box display={'flex'} flexDirection={'column'} gap={'3px'}>
                      <Typography fontSize={'14px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        {task?.get('title')}
                      </Typography>
                      <Typography fontSize={'12px'} color={color}>
                        <span style={{ color: globalColors.gray }}>Created By: </span>{' '}
                        {task?.get('createdBy').get('firstName') + ' ' + task?.get('createdBy').get('lastName')}
                      </Typography>
                      <Typography fontSize={'12px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        <span style={{ color: globalColors.gray }}>Created: </span> {moment(task?.get('createdAt')).fromNow()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={5} display={'flex'} justifyContent={'end'}>
                    <Button
                      type="button"
                      onClick={() => {
                        sendReminder();
                      }}
                      disabled={sendingReminder}
                    >
                      {sendingReminder ? 'Sending...' : 'Send Reminder'}
                    </Button>
                  </Grid>
                </Grid>
                <Formik
                  enableReinitialize
                  innerRef={formikRef}
                  initialValues={{
                    title: formValues.title,
                    description: formValues.description,
                    dueDate: formValues.dueDate,
                    priority: formValues.priority,
                    assignTo: formValues.assignTo,
                  }}
                  validate={(values) => {
                    const errors = {} as ValuesCreateTask;

                    if (!values.title || !values.title.trim()) {
                      errors.title = 'Required';
                    }

                    if (!values.priority || values.priority === 'Select') {
                      errors.priority = 'Required';
                    }

                    if (!values.assignTo) {
                      errors.assignTo = 'Required';
                    }

                    return errors;
                  }}
                  onSubmit={async (values: ValuesCreateTask, { setSubmitting, resetForm }: FormikHelpers<ValuesCreateTask>) => {
                    const taskRepository = new TaskRepository();
                    let due = dueDate ? dueDate.toDate() : null;
                    if (due) {
                      due.setUTCHours(0);
                      due.setUTCMinutes(0);
                      due.setUTCSeconds(0);
                      due.setUTCMilliseconds(0);
                    }

                    const updatedTask: TaskModel = {
                      objectId: task?.id,
                      title: values.title,
                      description: values.description,
                      dueDate: due,
                      priority: values.priority,
                      assignedTo: values.assignTo,
                    };
                    const savedTask = await taskRepository.update(task?.id ?? '', updatedTask);
                    if (savedTask) {
                      resetForm();
                      Toast('Task has been updated and assigned successfully.', 'success');
                      navigate('/activity/tasks');
                    } else {
                      Toast('Task could not be updated successfully. Please try again', 'error');
                    }
                  }}
                >
                  {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                    <Form>
                      <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
                        <FieldInput
                          optional={false}
                          label="Title"
                          id="title"
                          mode={mode}
                          name={'title'}
                          placeholder="Title"
                          type="text"
                          error={touched.title && errors.title ? errors.title : false}
                        />
                        <FieldInputTextarea
                          className="col"
                          placeholder="Description"
                          name="description"
                          id="description"
                          label="Description"
                          value={values.description}
                          onChange={(e) => {
                            values.description = e.target.value;
                          }}
                        />
                        <Grid container display={'flex'} alignItems={'start'} justifyContent={'start'} rowSpacing={'1.5rem'}>
                          <Grid item xs={12} md={6}>
                            <Box width={'100%'} paddingRight={{ xs: 0, md: '10px' }}>
                              <Typography mb="1rem" color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize="1rem">
                                Due
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  sx={{
                                    minWidth: '100%',
                                    color: mode === 'dark' ? globalColors.white : globalColors.black,
                                    backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                                    borderRadius: '12px',
                                    border: `2px solid ${globalColors.border.gray}`,
                                    padding: '6px',
                                    '.input': {
                                      fontSize: '13px',
                                    },
                                  }}
                                  value={dueDate ? dayjs(dueDate) : dayjs(new Date())}
                                  format="DD/MM/YYYY"
                                  onChange={(newDate: any) => setDueDate(dayjs(newDate))}
                                  minDate={dayjs(new Date())}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box width={'100%'} paddingRight={{ xs: 0, md: '10px' }}>
                              <Dropdown
                                label="Priority"
                                mode={mode}
                                id="priority"
                                name="priority"
                                optional={false}
                                values={['Select', 'Very Hot', 'Hot', 'Moderate', 'Cold']}
                                defaultValue={values.priority}
                                error={touched.priority && errors.priority ? errors.priority : false}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box>
                          <Dropdowndata
                            label="Assign To"
                            id="assignTo"
                            name="assignTo"
                            optional={false}
                            mode={mode}
                            onChange={(e) => {
                              setFieldValue('assignTo', e.target.value);
                            }}
                            values={[
                              { label: `Select`, value: '' },
                              ...managersandSalesExecutive.map((user) => ({
                                label: `${user.firstName} ${user.lastName}`,
                                value: user.id,
                              })),
                            ]}
                            defaultValue={values.assignTo}
                          />
                          <StyledTypography>{touched.assignTo && errors.assignTo ? errors.assignTo : ''}</StyledTypography>
                        </Box>

                        <Box display={'flex'} justifyContent={'center'}>
                          <Button
                            type="button"
                            onClick={() => {
                              handleSubmit();
                            }}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Loading...' : 'Save'}
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>{' '}
              </AssignmentContainer>
            </Box>
          ) : !hasPermission ? (
            <NoPermission />
          ) : (
            <InsufficientPlan />
          )}
        </Box>
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  height: '100%',
  width: '100%',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '10px',
}));

export default ActivityTaskPage;
