import { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { FaFlag } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { ICPen } from '../../../assets/Icons';
import { useModalViewProducts } from '../../../hooks';
import useModalDeleteProduct from '../../../hooks/useModalDeleteProduct';
// import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProject } from '../../../store/projects';
import { globalColors } from '../../../utils/constants/color';
import { ColorStatusProduct, ProductCategories, ProductItemProps } from '../../../utils/types/products';
import { ButtonIconCircle } from '../../atoms';
import { Gallery } from '../../organisms';
import ModalDeleteProduct from '../../organisms/products/modalDeleteProduct';
import ModalViewProduct from '../../organisms/products/modalViewProduct';
import { CardProductItemStyle } from './cardproducttItem.style';
import { Checkbox } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IFormDetailProperty } from '../../../store/property';
import { formatMoney } from '../../../utils/helpers';
import { BsEyeFill } from 'react-icons/bs';

const dummyData = ['Dev1', 'November'];

const dummyAmenties = [
  {
    label: 'Kitchen',
    value: '6',
    icon: '/static/media/ic-bed.fb4a2eb0f623d3f22aa9f2848da1313c.svg',
  },
  {
    label: 'Bedroom',
    value: '4',
    icon: '/static/media/ic-shower.f9f97eceeba5805eceb52f0e2d960b0b.svg',
  },
  {
    label: 'Bathroom',
    value: '2',
    icon: '/static/media/ic-coffe.0042cac3e40b8503c1e44e6e8e29e971.svg',
  },
];

const AddPropertyCard = (props: ProductItemProps) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const dataProperty = props.data as IFormDetailProperty;

  const isProperty = props.category === ProductCategories.Property;
  // const isProject = props.category === ProductCategories.Project;
  let styleStatusProperty = props.data.status ? ColorStatusProduct[props.data.status] : 'colorGray';

  // Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // * Modal View Detail
  const { openModalView, handleOpenModalView, handleCloseModalView, loadingModal, data, productType } = useModalViewProducts();

  // * Modal Delete
  const { openModalDelete, handleOpenModalDelete, handleDelete, handleCloseModalDelete, productType: productTypeDelete } = useModalDeleteProduct();

  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string | undefined) => {
    if (productId) {
      const checked = event.target.checked;
      props.setRowCheckboxState!((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [productId]: checked,
      }));
    }
  };
  return (
    <CardProductItemStyle className={props.className} mode={props.mode}>
      <div className="cover-wrapper">
        {dataProperty?.cover?.preview && (
          <img onClick={handleOpen} className="cover-image-product" src={dataProperty?.cover?.preview} alt="project name" />
        )}
        <div className="btn-action-wrapper">
          <div className="left">
            {/* Flag Icon */}
            <ButtonIconCircle
              component={
                <BsEyeFill
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                  onClick={() => (props?.active ? handleOpenModalView(props.category, props.data.id) : '')}
                />
                // <FaFlag
                //   size={'1.3rem'}
                //   color={globalColors.blue}
                //   style={{ cursor: 'pointer' }}
                //   onClick={() => (props?.active ? handleOpenModalView(props.category, props.data.id) : '')}
                // />
              }
              alt="flag"
              onClick={props.onFlagClick}
            />
          </div>
        </div>
        <div className="count-photo-wrapper" onClick={() => (props.active ? handleOpen() : '')}>
          <CameraAltOutlinedIcon sx={{ color: props.mode === 'dark' ? globalColors.white : globalColors.black }} />
          <p style={{ color: props.mode === 'dark' ? globalColors.white : globalColors.black }}>View Photos ({props?.data?.images?.length ?? 0})</p>
        </div>
      </div>
      <div className="description-wrapper">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className={`title-project ${isProperty && 'property'}`}>
            {/* Name */}
            <span>{props?.data?.name ? props?.data?.name : 'Title'}</span>
          </h3>
          {/* <span>PKR{dataProject?.price || 0}</span> */}
          <span
            style={{
              color: globalColors.lightblue,
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'left',
            }}
          >
            {formatMoney(dataProperty.price ? parseInt(dataProperty.price) : 0)}
          </span>
        </Box>

        {/* address */}
        <Box display={'flex'} justifyContent={'start'}>
          <p style={{ marginTop: '10px', textAlign: 'start' }} className="detail">
            Address: {dataProperty?.address ? dataProperty?.address : '-'}
          </p>
        </Box>

        {/* Square footage */}
        <Box display={'flex'} justifyContent={'start'}>
          <p style={{ marginTop: '10px', textAlign: 'start' }} className="detail">
            {`Area: ${dataProperty.area ? dataProperty.area : '-'} ${dataProperty.area ? dataProperty.areaUnit : ''}`}
          </p>
        </Box>

        {/* status */}
        <p className={`detail status ${styleStatusProperty}`}>{props?.data?.status ? props?.data?.status : 'Status'}</p>
      </div>
      <Gallery open={open} onClose={handleClose} images={props?.data?.images} />
      <ModalViewProduct open={openModalView} onClose={handleCloseModalView} loading={loadingModal} data={data || null} type={productType || null} />
      <ModalDeleteProduct
        open={openModalDelete}
        mode={mode}
        color={color}
        onClose={handleCloseModalDelete}
        type={productTypeDelete || null}
        onDelete={() => handleDelete(props.category, props.data.id)}
        action={{permissionName: isProperty ? 'Properties' : 'Projects', action: 'Delete'}}
      />
    </CardProductItemStyle>
  );
};

const AvatarContainer = styled(Box)({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: '#101010',
  padding: '7px',
});

export default AddPropertyCard;
