import React, { useState, useEffect } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography, Button } from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../store/selector';
// import { Button } from '../../components/atoms';
import { styled } from '@mui/styles';
import { ICCoffe } from '../../assets/Icons';
import IosSwitch from '../../components/molecules/IosSwitch';
import LeadSettingTrigger from './leadSettingsTrigger';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import { Toast } from '../../components/atoms';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole } from '../../store/user';
import NoPermission from '../../components/atoms/noPermission';

const LeadSetting = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [leadPreference, setLeadPreference] = useState<string | null>('');
  const [switchDisabled, setSwitchDisabled] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  const handleLeadPreferenceChange = async (preference: string) => {
    setLeadPreference(preference);
    const organizationRepository = new OrganizationRepository();
    const savedOrganization = await organizationRepository.addLeadPreference(preference);
    if (savedOrganization) {
      Toast('Lead assignment preference updated successfully.', 'success');
    } else {
      Toast('Lead assignment preference could not be updated successfully. Please try again.', 'success');
    }
  };

  useEffect(() => {
    const getLeadPreference = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        setHasPermission(currentUser.get('role') == UserRole.admin);
        const organizationRepository = new OrganizationRepository();
        const preference = await organizationRepository.getLeadPreference();
        if (preference) {
          setLeadPreference(preference);
        }
        setSwitchDisabled(false);
      }
      setSwitchDisabled(false);
    };

    getLeadPreference();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Settings / Leads'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {hasPermission ? (
            <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
              <Box marginBottom={'10px'}></Box>
              {/* Lead Assignment Container */}
              <AssignmentContainer
                bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                padding={'20px'}
                borderRadius={'16px'}
                color={globalColors.white}
                fontSize={'12px'}
                boxShadow={'#171717'}
                marginTop={'1rem'}
              >
                {' '}
                {/* Lead Assignment */}
                <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                  <Typography
                    component="div"
                    fontFamily={'Poppins'}
                    fontSize={'20px'}
                    fontWeight={'600'}
                    lineHeight={'32px'}
                    color={mode === 'dark' ? globalColors?.white : globalColors.black}
                  >
                    Lead Assignment
                  </Typography>
                </Box>
                {/* Upload Your data */}
                <Box sx={{ marginTop: '10px' }} display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                  <Typography fontSize={'12px'} color={globalColors.gray}>
                    Choose whether a lead should be assigned using{' '}
                    <a href={'/'} style={{ textDecoration: 'none', color: color || globalColors.blue }}>
                      Cloud AI
                    </a>{' '}
                    or manually, by default
                  </Typography>
                </Box>
                {/* Toggle */}
                <Box sx={{ marginTop: '5%', justifyContent: 'center', alignItems: 'center' }} display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                  <Typography
                    noWrap
                    component="div"
                    fontFamily={'Poppins'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    lineHeight={'27px'}
                    color={mode === 'dark' ? globalColors?.white : globalColors.black}
                  >
                    Manually
                  </Typography>
                  <IosSwitch
                    onChange={(checked) => {
                      handleLeadPreferenceChange(checked ? 'manually' : 'Cloud AI');
                    }}
                    initiallyChecked={leadPreference == 'Cloud AI'}
                    disabled={switchDisabled}
                  />
                  <Typography
                    noWrap
                    component="div"
                    fontFamily={'Poppins'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    lineHeight={'27px'}
                    color={mode === 'dark' ? globalColors?.white : globalColors.black}
                  >
                    Cloud AI
                  </Typography>
                </Box>
              </AssignmentContainer>
            </Box>
          ) : (
            <NoPermission />
          )}

          {hasPermission && leadPreference == 'Cloud AI' && <LeadSettingTrigger />}
        </Box>
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  height: '272px',
  width: '100%',
});

export default LeadSetting;
