import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import OrganizationModel from '../models/organizationModel';
import UserRepository from './userRepository';
import ProjectRepository from './projectRepository';
import PropertyRepository from './propertyRepository';
import LeadRepository from './leadRepository';
import { PermissionsInterface, extendedDataLimit, getCurrentQuarterDates } from '../../helpers';
import { UserRole } from '../../../store/user';
import UserModel from '../models/userModel';

class OrganizationRepository {
  private className = 'Organization';

  constructor() {}

  public async create(object: OrganizationModel) {
    try {
      const Organization = Parse.Object.extend(this.className);
      const organizationObject = new Organization();
      organizationObject.set('ownerEmail', object.ownerEmail);
      organizationObject.set('address', object.address);
      organizationObject.set('primaryPhone', object.primaryPhone);
      organizationObject.set('secondaryPhone', object.secondaryPhone);
      organizationObject.set('ownerName', object.ownerName);
      organizationObject.set('website', object.website);
      organizationObject.set('heardHow', object.heardHow);
      organizationObject.set('admin', object.admin);
      organizationObject.set('metaKey', object.metaKey);
      if (object.managers) {
        var managersRelation = organizationObject.relation('managers');
        for (let i = 0; i < (object.managers as any[]).length; i++) {
          managersRelation.add((object.managers as any)[i]);
        }
      }
      if (object.salesExecutives) {
        var salesExecutivesRelation = organizationObject.relation('salesExecutives');
        for (let i = 0; i < (object.salesExecutives as any[]).length; i++) {
          salesExecutivesRelation.add((object.salesExecutives as any)[i]);
        }
      }
      organizationObject.set('status', object.status);
      organizationObject.set('tier', object.tier);
      organizationObject.set('logo', object.logo);
      // Organization Profile
      organizationObject.set('name', object.name);
      organizationObject.set('businessLocation', object.businessLocation);

      return new Promise((resolve, _) => {
        organizationObject.save(null, { useMasterKey: true }).then(
          (savedOrganization: any) => {
            Toast(`Organization ${object.name} created successfully!`, 'success');
            resolve(savedOrganization);
          },
          (error: any) => {
            Toast(`Error creating organization ${error}`, 'error');
            resolve(null);
          }
        );
      });
    } catch (error) {
      // console.error('Error adding note:', error);
      Toast(`Error creating organization ${error}`, 'error');
      return null;
    }
  }

  public async getObjectById(id: string) {
    try {
      const query = new Parse.Query(this.className);
      query.equalTo('objectId', id);
      query.include('logo');
      const organization = await query.first({ useMasterKey: true });
      return organization;
    } catch (error) {
      console.error('Error fetching organization:', error);
      return null;
    }
  }

  public async update(object: OrganizationModel, objectId: string) {
    try {
      console.log(object);
      const organizationObject = await this.getObjectById(objectId);
      if (organizationObject) {
        if (object.address) {
          organizationObject.set('address', object.address);
        }
        if (object.registrationYear) {
          organizationObject.set('registrationYear', object.registrationYear);
        }
        if (object.primaryPhone) {
          organizationObject.set('primaryPhone', object.primaryPhone.toString());
        }
        if (object.secondaryPhone) {
          organizationObject.set('secondaryPhone', object.secondaryPhone.toString());
        }
        if (object.contact) {
          organizationObject.set('contact', object.contact);
        }
        if (object.website) {
          organizationObject.set('website', object.website);
        }
        if (object.heardHow) {
          organizationObject.set('heardHow', object.heardHow);
        }
        if (object.logo) {
          organizationObject.set('logo', object.logo);
        }
        if (object.initialSetupCompleted) {
          organizationObject.set('initialSetupCompleted', object.initialSetupCompleted);
        }
        if (object.address) {
          organizationObject.set('address', object.address);
        }
        if (object.ownerEmail) {
          organizationObject.set('ownerEmail', object.ownerEmail);
        }

        if (object.tags) {
          organizationObject.set('tags', object.tags);
        }

        return new Promise((resolve, _) => {
          organizationObject.save(null, { useMasterKey: true }).then(
            (savedOrganization: any) => {
              resolve(savedOrganization);
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error updating organization:', error);
      // Toast(`Error creating organization ${error}`, 'error');
      return null;
    }
  }

  public async signUpNewMember(
    email: string,
    phone: string,
    firstName: string,
    lastName: string,
    role: string,
    permissions: PermissionsInterface[],
    managerId: string
  ) {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser && currentUser.get('role') == UserRole.admin) {
        const params = {
          username: email,
          email: email,
          phone: phone,
          firstName: firstName,
          lastName: lastName,
          role: role,
          organizationId: currentUser.get('organization').id,
          permissions: permissions,
          managerId: managerId,
        };
        const signedUpUser = await Parse.Cloud.run('signupMember', params, { useMasterKey: true });
        if (signedUpUser && signedUpUser.status === 'success') {
          return signedUpUser.data;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error signing up member:', error);
      // Toast(`Error creating organization ${error}`, 'error');
      return null;
    }
  }

  public async getUsersRelatedToSalesExecutives(salesExecutivesPointer: Parse.Object) {
    try {
      const Organization = Parse.Object.extend('Organization');
      const organizationQuery = new Parse.Query(Organization);

      organizationQuery.equalTo('objectId', salesExecutivesPointer.id);

      const organization = await organizationQuery.first({ useMasterKey: true });

      if (organization) {
        const salesExecutivesRelation = organization.relation('salesExecutives');
        const salesExecutivesQuery = salesExecutivesRelation.query();

        const salesExecutiveUsers = await salesExecutivesQuery.find({ useMasterKey: true });
        return salesExecutiveUsers;
      } else {
        console.log('Organization not found.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization or SalesExecutive users:', error);
      return null;
    }
  }

  public async getUsersRelatedToManagers(managersPointer: Parse.Object) {
    try {
      const Organization = Parse.Object.extend('Organization');
      const organizationQuery = new Parse.Query(Organization);

      organizationQuery.equalTo('objectId', managersPointer.id);

      const organization = await organizationQuery.first({ useMasterKey: true });

      if (organization) {
        const managersRelation = organization.relation('managers');
        const managersQuery = managersRelation.query();

        const managerUsers = await managersQuery.find({ useMasterKey: true });
        return managerUsers;
      } else {
        console.log('Organization not found.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization or Manager users:', error);
      return null;
    }
  }

  public async getOrganizationPayments(organizationId: String) {
    try {
      const Organization = Parse.Object.extend('Organization');
      const organizationQuery = new Parse.Query(Organization);

      organizationQuery.equalTo('objectId', organizationId);

      const organization = await organizationQuery.first({ useMasterKey: true });

      if (organization) {
        const paymentsRelation = organization.relation('paymentHistory');
        const paymentsQuery = paymentsRelation.query().descending('createdAt');

        const payments = await paymentsQuery.find({ useMasterKey: true });
        return payments;
      } else {
        console.log('Organization not found.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization payments:', error);
      return null;
    }
  }

  public async addLead(lead: Parse.Object) {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              var organizationLeads = organization.relation('leads');
              organizationLeads.add(lead);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async addImportData(importData: Parse.Object): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('importData', importData);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              if (savedOrganization) {
                Parse.Cloud.run('importLeads', { organizationId: savedOrganization.id }, { useMasterKey: true });
                // console.log("IMPORT LEADS", importLeads);
                resolve(savedOrganization);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getImportData(): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              resolve(organization.get('importData'));
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async addLeadPreference(preference: string) {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('leadPreference', preference);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getLeadPreference(): Promise<string | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              resolve(organization.get('leadPreference'));
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async addLeadTriggers(trigger: { weightage: number; metric: string }[]) {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('leadTriggers', trigger);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getLeadTriggers(): Promise<[] | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              resolve(organization.get('leadTriggers'));
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async saveMetaKey(apiKey: string, label: string) {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            const savedMetaKey = await Parse.Cloud.run(
              'saveMetaKey',
              {
                metaKey: apiKey,
                label: label,
                organizationId: organization.id,
                API_KEY: process.env.REACT_APP_PARSE_CLOUD_API_KEY,
              },
              { useMasterKey: true }
            );

            if (savedMetaKey && savedMetaKey.data) {
              resolve(savedMetaKey.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async fetchMetaKey(): Promise<{ status: string; label: string } | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            const savedMetaKey = await Parse.Cloud.run(
              'fetchMetaKey',
              {
                organizationId: organization.id,
                API_KEY: process.env.REACT_APP_PARSE_CLOUD_API_KEY,
              },
              { useMasterKey: true }
            );

            if (savedMetaKey && savedMetaKey.data) {
              resolve(savedMetaKey.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async removeMetaKey(): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('metaKey', null);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async saveMailerSendKey(apiKey: string, label: string, verifiedDomain: string) {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            const savedMailerSendKey = await Parse.Cloud.run(
              'saveMailerSendKey',
              {
                mailerSendKey: apiKey,
                label: label,
                verifiedDomainEmail: verifiedDomain,
                organizationId: organization.id,
                API_KEY: process.env.REACT_APP_PARSE_CLOUD_API_KEY,
              },
              { useMasterKey: true }
            );

            if (savedMailerSendKey && savedMailerSendKey.data) {
              resolve(savedMailerSendKey.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async fetchMailerSendKey(): Promise<{ status: string; label: string } | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            const mailerSendKey = await Parse.Cloud.run(
              'fetchMailerSendKey',
              {
                organizationId: organization.id,
                API_KEY: process.env.REACT_APP_PARSE_CLOUD_API_KEY,
              },
              { useMasterKey: true }
            );

            if (mailerSendKey && mailerSendKey.data) {
              resolve(mailerSendKey.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async removeMailerSendKey(): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('mailerSendKey', null);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getProjectsCount(): Promise<number | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser && currentUser.get('organization')) {
      const projectRepository = new ProjectRepository();

      return new Promise((resolve, _) => {
        projectRepository
          .countByOrganization(currentUser.get('organization').id)
          .then(async (count) => {
            resolve(count);
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getSoldProjectsCount(): Promise<number | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser && currentUser.get('organization')) {
      const projectRepository = new ProjectRepository();

      return new Promise((resolve, _) => {
        projectRepository
          .countSoldByOrganization(currentUser.get('organization').id)
          .then(async (count) => {
            resolve(count);
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getPropertiesCount(): Promise<number | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser && currentUser.get('organization')) {
      const propertyRepository = new PropertyRepository();

      return new Promise((resolve, _) => {
        propertyRepository
          .countByOrganization(currentUser.get('organization').id)
          .then(async (count) => {
            resolve(count);
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getSoldPropertiesCount(): Promise<number | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser && currentUser.get('organization')) {
      const propertyRepository = new PropertyRepository();

      return new Promise((resolve, _) => {
        propertyRepository
          .countSoldByOrganization(currentUser.get('organization').id)
          .then(async (count) => {
            resolve(count);
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getLeadsCount(): Promise<number | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser && currentUser.get('organization')) {
      const leadRepository = new LeadRepository();

      return new Promise((resolve, _) => {
        leadRepository
          .countByOrganization(currentUser.get('organization').id)
          .then(async (count) => {
            resolve(count);
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getTotalSales(property?: boolean, project?: boolean): Promise<number | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser && currentUser.get('organization')) {
      let totalSales = 0;
      const propertyRepository = new PropertyRepository();
      const propertySales = await propertyRepository.getTotalSales(currentUser.get('organization').id);
      if (propertySales) {
        totalSales += propertySales;
      }
      if (property) {
        return propertySales;
      }
      const projectRepository = new ProjectRepository();
      const projectSales = await projectRepository.getTotalSales(currentUser.get('organization').id);
      if (projectSales) {
        totalSales += projectSales;
      }
      if (project) {
        return projectSales;
      }
      return totalSales;
    } else {
      return null;
    }
  }

  public async getSignedUpMembers(): Promise<{ total: number; existing: number } | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser && currentUser.get('organization')) {
        const organization = await this.getObjectById(currentUser?.get('organization').id);
        if (organization) {
          let total = organization.get('memberCount');
          let existing = organization.get('admin') ? 1 : 0;
          const managers = await organization.relation('managers').query().count({ useMasterKey: true });
          if (managers) {
            existing += managers;
          }
          const executives = await organization.relation('salesExecutives').query().count({ useMasterKey: true });
          if (executives) {
            existing += executives;
          }
          return { total: total, existing: existing };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching organization counts:', error);
      return null;
    }
  }

  public async initiatePayment(
    amount: number,
    currency: string = 'USD',
    type: string,
    objectId: string,
    organizationName?: string,
    ownerEmail?: string
  ): Promise<string | null> {
    try {
      return new Promise((resolve, _) => {
        Parse.Cloud.run(
          'createPaymentIntent',
          {
            amount: amount,
            currency: currency,
            type: type,
            objectId: objectId,
            organizationName: organizationName,
            ownerEmail: ownerEmail,
          },
          { useMasterKey: true }
        )
          .then(async (result) => {
            if (result && result.status == 'success') {
              resolve(result.clientSecret);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error creating payment intent:', error);
      return null;
    }
  }

  public async verifyPayment(paymentId: string): Promise<string | null> {
    try {
      return new Promise((resolve, _) => {
        Parse.Cloud.run(
          'verifySuccessfulPayment',
          {
            paymentId: paymentId,
          },
          { useMasterKey: true }
        )
          .then(async (result) => {
            if (result && result.status == 'success') {
              resolve(result.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error fetching payment intent:', error);
      return null;
    }
  }

  public async updateManagerPermissions(permissions: PermissionsInterface[]): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const allManagers = await userRepository.getAllManagersForOrganization();
      if (allManagers) {
        for (let i = 0; i < allManagers.length; i++) {
          allManagers[i].set('permissions', permissions);
          const savedManager = await allManagers[i].save(null, { useMasterKey: true });
          if (!savedManager) {
            return null;
          }
        }
        const organization = await userRepository.getCurrentOrganization();
        if (organization) {
          organization.set('managerGenericPermissions', true);
          organization.set('managerPermissions', permissions);
          const savedOrganization = await organization.save(null, { useMasterKey: true });
          if (savedOrganization) {
            return savedOrganization;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error updating managers:', error);
      return null;
    }
  }

  public async updateExecutivePermissions(permissions: PermissionsInterface[]): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const allExecutives = await userRepository.getAllExecutivesForOrganization();
      if (allExecutives) {
        for (let i = 0; i < allExecutives.length; i++) {
          allExecutives[i].set('permissions', permissions);
          const savedExecutive = await allExecutives[i].save(null, { useMasterKey: true });
          if (!savedExecutive) {
            return null;
          }
        }
        const organization = await userRepository.getCurrentOrganization();
        if (organization) {
          organization.set('executiveGenericPermissions', true);
          organization.set('executivePermissions', permissions);
          const savedOrganization = await organization.save(null, { useMasterKey: true });
          if (savedOrganization) {
            return savedOrganization;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error updating executives:', error);
      return null;
    }
  }

  public async updateDeleteConfirmation(askDeleteConfirmation: boolean): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const organization = await userRepository.getCurrentOrganization();
      if (organization) {
        organization.set('askDeleteConfirmation', askDeleteConfirmation);
        const savedOrganization = await organization.save(null, { useMasterKey: true });
        if (savedOrganization) {
          return savedOrganization;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error updating executives:', error);
      return null;
    }
  }

  public async getAllOrganizations(
    search?: string,
    queryFilters?: {
      status?: string;
      usersMax?: number;
      usersMin?: number;
    }
  ): Promise<Parse.Object[]> {
    try {
      const query = new Parse.Query(this.className);
      if (search && search !== '') {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }
      console.log('queryFilters', queryFilters);

      if (queryFilters) {
        if (queryFilters.status && queryFilters.status !== 'select') {
          query.equalTo('status', queryFilters.status);
        }
      }
      const organizations = await query.find({ useMasterKey: true });

      if (organizations) {
        const organizationData: any[] = organizations.map((organization) => {
          return {
            objectId: organization.id,
            name: organization.get('name'),
            email: organization.get('ownerEmail'),
            country: organization.get('businessLocation'),
            usersCount: organization.get('memberCount'),
            status: organization.get('status'),
            access: organization.get('access'),
            createdAt: organization.createdAt,
            updatedAt: organization.updatedAt,
            // Add other fields as needed
          };
        });

        return organizationData;
      } else {
        return [];
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error retrieving organization: ${error.message}`, 'error');
      return [];
    }
  }

  public async upgradePlan(plan: { memberCount: number; paymentCycle: string; perMonthAmount: number; tier: string }): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('memberCount', plan.memberCount);
              organization.set('paymentCycle', plan.paymentCycle);
              organization.set('perMonthAmount', plan.perMonthAmount);
              organization.set('tier', plan.tier);
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async downgradePlan(plan: {
    memberCount: number;
    paymentCycle: string;
    perMonthAmount: number;
    tier: string;
    actionRequired: string | null;
  }): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Organization = Parse.Object.extend(this.className);
      const query = new Parse.Query(Organization);

      return new Promise((resolve, _) => {
        query
          .get(currentUser.get('organization').id, { useMasterKey: true })
          .then(async (organization) => {
            if (organization) {
              organization.set('memberCount', plan.memberCount);
              organization.set('paymentCycle', plan.paymentCycle);
              organization.set('perMonthAmount', plan.perMonthAmount);
              organization.set('tier', plan.tier);
              organization.set('actionRequired', plan.actionRequired);
              if (plan.actionRequired) {
                let nextPaymentDue = organization.get('nextPaymentDue');
                organization.set('actionRequiredAt', nextPaymentDue);
              }
              const savedOrganization = await organization.save(null, { useMasterKey: true });
              resolve(savedOrganization);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async delete(objectId: string): Promise<boolean> {
    try {
      const organizationObject = await this.getObjectById(objectId);
      if (organizationObject) {
        // Delete the organization object
        await organizationObject.destroy({ useMasterKey: true });
        console.log(`Organization with ID ${objectId} deleted successfully.`);
        return true;
      } else {
        console.log(`Organization with ID ${objectId} not found.`);
        return false;
      }
    } catch (error) {
      console.error('Error deleting organization:', error);
      return false;
    }
  }

  public async getTopPerformerForCurrentQuarter(organizationId: string): Promise<any | null> {
    const Property = Parse.Object.extend('Property');
    const { start, end } = getCurrentQuarterDates();
    const propertyQuery = new Parse.Query(Property)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', start)
      .lessThanOrEqualTo('soldAt', end)
      .limit(extendedDataLimit)
      .include('soldBy');

    const Project = Parse.Object.extend('Project');
    const projectQuery = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', start)
      .lessThanOrEqualTo('soldAt', end)
      .limit(extendedDataLimit)
      .include('soldBy');

    const ProjectComponent = Parse.Object.extend('ProjectComponent');
    const projectComponentsQuery = new Parse.Query(ProjectComponent)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', start)
      .lessThanOrEqualTo('soldAt', end)
      .limit(extendedDataLimit)
      .include('soldBy');

    try {
      return new Promise(async (resolve, _) => {
        const properties = await propertyQuery.find();
        const projects = await projectQuery.find();
        const projectComponents = await projectComponentsQuery.find();

        let usersData: { user: Parse.Object; total: number }[] = [];

        for (let i = 0; i < properties.length; i++) {
          const existingItemIndex = usersData.findIndex((item) => item.user.id === properties[i].get('soldBy').id);
          if (existingItemIndex !== -1) {
            usersData[existingItemIndex].total += properties[i].get('sellingPrice') ?? 0;
          } else {
            usersData.push({ user: properties[i].get('soldBy'), total: properties[i].get('sellingPrice') ?? 0 });
          }
        }

        for (let i = 0; i < projects.length; i++) {
          const existingItemIndex = usersData.findIndex((item) => item.user.id === projects[i].get('soldBy').id);
          if (existingItemIndex !== -1) {
            usersData[existingItemIndex].total += projects[i].get('sellingPrice') ?? 0;
          } else {
            usersData.push({ user: projects[i].get('soldBy'), total: projects[i].get('sellingPrice') ?? 0 });
          }
        }

        for (let i = 0; i < projectComponents.length; i++) {
          const existingItemIndex = usersData.findIndex((item) => item.user.id === projectComponents[i].get('soldBy').id);
          if (existingItemIndex !== -1) {
            usersData[existingItemIndex].total += projectComponents[i].get('sellingPrice') ?? 0;
          } else {
            usersData.push({ user: projectComponents[i].get('soldBy'), total: projectComponents[i].get('sellingPrice') ?? 0 });
          }
        }

        let highestSellingPrice = 0;
        let highestUserIdx = -1;

        for (let i = 0; i < usersData.length; i++) {
          if (usersData[i].total > highestSellingPrice) {
            highestSellingPrice = usersData[i].total;
            highestUserIdx = i;
          }
        }

        if (highestUserIdx === -1) {
          resolve(null);
        } else {
          resolve(usersData[highestUserIdx]);
        }
      });
    } catch (error) {
      console.error('Error counting properties and projects:', error);
      return null;
    }
  }
}

export default OrganizationRepository;
