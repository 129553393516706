import { Box, Grid, Typography } from '@mui/material';
import { InlineWidget } from 'react-calendly';
import { globalColors } from '../../utils/constants/color';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const RequestDemo = () => {
  const navigate = useNavigate();

  return (
    <Box bgcolor={globalColors.mainBackground} height={'100%'} width={'100%'} color={globalColors.white}>
      <Box padding={'2rem'}>
        {/* NAVIGATION */}
        <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} paddingBottom={'2rem'}>
          <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
            <ArrowBack
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            />
            <Typography
              fontSize={'12px'}
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            >
              Go Back
            </Typography>
          </Grid>
        </Grid>
        {/* PAYMENT PLAN SELECTION */}

        <InlineWidget styles={{ height: '100vh' }} url="https://calendly.com/propertycloud/request-a-demo" />
      </Box>
    </Box>
  );
};
export default RequestDemo;
