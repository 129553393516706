import { useDispatch, useSelector } from 'react-redux';
import { EnumViews, onShowModalBulk } from '../../store/projects';
import { onLoading, onSwitchView, setDataProperties } from '../../store/property';
import { useNavigate } from 'react-router-dom';
import PropertyRepository from '../../utils/api/repositories/propertyRepository';
import { useEffect } from 'react';
import {
  selectPropertiesFilter,
  selectPropertyState,
  selectPropertyloader,
  selectRefreshPropertiesState,
  selectSearchProperties,
} from '../../store/selector';
import { setAddNew } from '../../store/routes';

const useProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertyState = useSelector(selectPropertyState);
  const filterData = useSelector(selectPropertiesFilter);
  const search = useSelector(selectSearchProperties);
  const refreshProperties = useSelector(selectRefreshPropertiesState);

  const handleSwitchView = () => {
    const viewActive = propertyState.viewActive === EnumViews.Cards ? EnumViews.List : EnumViews.Cards;
    dispatch(onSwitchView(viewActive));
  };

  const handleCreateNewProperty = () => {
    dispatch(setAddNew({ addNew: true, path: '/properties' }));
    navigate('/properties/new');
  };

  useEffect(() => {
    getAllData();
  }, [filterData.status, filterData.priceLow, filterData.priceHigh, filterData.location, filterData.minArea, filterData.maxArea, search]);

  useEffect(() => {
    getAllData();
  }, [refreshProperties]);
  
  useEffect(() => {
    getAllData();
  }, []);

  // useEffect(() => {
  //   document.title = 'Properties';
  // }, []);

  const getTotalCount = async () => {
    const propertyRepostitory = new PropertyRepository();
    const totalCount = await propertyRepostitory.getAllCount(
      search,
      filterData.status,
      filterData.priceLow,
      filterData?.priceHigh,
      filterData.location,
      filterData.minArea,
      filterData.maxArea
    );
    return totalCount;
  };

  const getAllData = async () => {
    dispatch(onLoading(true));
    const propertyRepostitory = new PropertyRepository();
    const get = await propertyRepostitory.getAll(
      search,
      filterData.status,
      filterData.priceLow,
      filterData?.priceHigh,
      filterData.location,
      filterData.minArea,
      filterData.maxArea
    );
    dispatch(setDataProperties(get));
    dispatch(onLoading(false));
    dispatch(onShowModalBulk(false));
  };

  const getMoreData = async (page: number) => {
    dispatch(onLoading(true));
    const propertyRepostitory = new PropertyRepository();
    const get = await propertyRepostitory.getAll(
      search,
      filterData.status,
      filterData.priceLow,
      filterData?.priceHigh,
      filterData.location,
      filterData.minArea,
      filterData.maxArea,
      page
    );
    dispatch(setDataProperties(get));
    dispatch(onLoading(false));
    dispatch(onShowModalBulk(false));
  };

  return {
    properties: propertyState.properties,
    view: propertyState.viewActive,
    handleSwitchView,
    handleCreateNewProperty,
    getMoreData,
    getTotalCount,
    isLoading: propertyState.isLoading,
  };
};

export default useProperties;
