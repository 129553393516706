import React from 'react';
import Lottie from 'react-lottie';
import noPermissions from '../../assets/lottifile/noPermissions.json';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { globalColors } from '../../utils/constants/color';
import { Button } from '../../components/atoms';
import { useNavigate } from 'react-router-dom';
import { selectColor, selectThemeMode } from '../../store/selector';
import { useDispatch } from 'react-redux';
import { setOpenSettings } from '../../store/routes';
import logo from '../../assets/Images/logo.svg';
// import UserRepository from '../../utils/api/repositories/userRepository';

const PageNotFound: React.FC = () => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [showButton, setShowButton] = useState(false);
  const notfoundOptions = {
    loop: true,
    autoplay: true,
    animationData: noPermissions,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box
      height={'100vh'}
      maxHeight={'100vh'}
      alignItems={'center'}
      display={'flex'}
      gap={'10px'}
      justifyContent={'center'}
      flexDirection={'column'}
      alignContent={'center'}
      // height={'90vh'}
    >
      <img
        src={logo}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigate('/');
        }}
      />
      <Lottie options={notfoundOptions} width={'50vw'} height={'50vw'} style={{ maxHeight: '400px', maxWidth: '400px' }} />
      <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} sx={{ textAlign: 'center' }} fontSize={'12px'}>
        Oops. This page does not exist. Please recheck the route you want to access.
      </Typography>
      <Box marginTop={'5vh'} width={'100%'} display={'flex'} justifyContent={'center'}>
        <Button
          onClick={() => {
            navigate('/login');
          }}
        >
          Take Me Back
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
