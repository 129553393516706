import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { globalColors } from '../../../../utils/constants/color';
import LeadsDetails from '../../../organisms/leads/leadsDetails';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import { LoadingFtn } from '../../../../store/slices';
import ViewAllComments from '../../../organisms/leads/viewAllComments';
import ModalCommunicate from '../../../organisms/leads/modalCommunicate';
import RequestDetails from './requestDetails';
import LeadConversionRequestRepository from '../../../../utils/api/repositories/leadConversionRequestRepository';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { Toast } from '../../../atoms';

interface ModalDetailLeadProps {
  open: boolean;
  mode: string;
  color: string;
  leadId: string;
  onClose: () => void;
  requestId: string;
  handleRejection: () => Promise<void>;
}
const ModalApproveConversion = ({ open, onClose, mode, color, leadId, requestId, handleRejection }: ModalDetailLeadProps) => {
  const [activeItem, setActiveItem] = useState<string>('request');
  const dispatch = useDispatch();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: activeItem === 'communicationHistory' ? '1000px' : '800px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '90%',
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={[style]} height={'65vh'} className="content">
        <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
          <Box
            onClick={() => setActiveItem('request')}
            border={`2px solid ${activeItem === 'request' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
            borderRadius={'10px'}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'request'
                  ? color || globalColors.blue
                  : globalColors.blackLight
                : activeItem === 'request'
                ? color || globalColors.blue
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'request' ? globalColors.white : color || globalColors.black}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Request
          </Box>

          <Box
            onClick={() => setActiveItem('leadDetails')}
            borderRadius={'10px'}
            border={`2px solid ${activeItem === 'leadDetails' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'leadDetails'
                  ? color || globalColors.blue
                  : globalColors.blackLight
                : activeItem === 'leadDetails'
                ? color || globalColors.blue
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'leadDetails' ? globalColors.white : color || globalColors.black}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Lead Details
          </Box>
          <Box
            onClick={() => setActiveItem('commentsAndFollowUpDate')}
            borderRadius={'10px'}
            border={`2px solid ${activeItem === 'commentsAndFollowUpDate' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'commentsAndFollowUpDate'
                  ? color || globalColors.blue
                  : globalColors.blackLight
                : activeItem === 'commentsAndFollowUpDate'
                ? color || globalColors.blue
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'commentsAndFollowUpDate' ? globalColors.white : color || globalColors.black}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Comments & Follow Up
          </Box>
          <Box
            onClick={() => setActiveItem('communicationHistory')}
            borderRadius={'10px'}
            border={`2px solid ${activeItem === 'communicationHistory' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'communicationHistory'
                  ? color || globalColors.blue
                  : globalColors.blackLight
                : activeItem === 'communicationHistory'
                ? color || globalColors.blue
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'communicationHistory' ? globalColors.white : color || globalColors.black}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Communication History
          </Box>
        </Box>

        {activeItem === 'leadDetails' ? (
          <LeadsDetails id={leadId} mode={mode} color={color} />
        ) : activeItem === 'commentsAndFollowUpDate' ? (
          <ViewAllComments leadId={leadId} mode={mode} color={color} changeStatus={async () => {}} />
        ) : activeItem === 'communicationHistory' ? (
          <ModalCommunicate mode={mode} color={color} id={leadId} />
        ) : (
          <RequestDetails id={requestId} mode={mode} color={color} onClose={onClose} onReject={handleRejection} />
        )}
      </Box>
    </Modal>
  );
};

export default ModalApproveConversion;
