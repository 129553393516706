import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { ICCoffe } from '../../assets/Icons';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../store/selector';

interface IosSwitchProps {
  onChange?: (_: boolean) => void,
  initiallyChecked?: boolean,
  disabled?: boolean,
  monochrome?: boolean
  trackColor?: string
}

export default function IosSwitch({onChange, initiallyChecked = false, disabled = false, monochrome = true, trackColor}: IosSwitchProps) {
  const [checked, setChecked] = useState(initiallyChecked);
  const color = useSelector(selectColor);

  const handleChange = () => {
    setChecked((prev) => !prev);
    if (onChange) {
      onChange(checked);
    }
  };

  useEffect(() => {
    setChecked(initiallyChecked);
  }, [initiallyChecked]);

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  ))(({ theme }) => ({
    width: 60,
    height: 26,
    padding: 0,
    cursor: 'pointer',
    borderRadius: '13px',
    boxShadow: '0 1px 5px #d5bcbc69',
    '& .MuiSwitch-input': {
      width: '600% !important',
      position: 'absolute',
      left: 0,
    },
    '& .Mui-checked .MuiSwitch-input': {
      width: '600%',
      position: 'absolute',
      left: '-500%',
    },
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        // transform: 'translateX(16px)',
        transform: 'translateX(calc(60px - 26px))',
        color: globalColors.white,
        '& + .MuiSwitch-track': {
          // backgroundColor: color || 'rgba(48, 48, 48, 1)',
          // WHEN THUMB IS ON RIGHT SIDE (ENABLED)
          backgroundColor: trackColor ?? (color || globalColors.blue),
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: trackColor ?? color,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      background: ICCoffe,
      backgroundPosition: 'center',
      // backgroundColor: checked ? 'rgba(4, 85, 198, 1)' : color || 'rgba(246, 246, 246, 1)',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      // WHEN THUMB IS ON LEFT SIDE (DISABLED)
      backgroundColor: monochrome ? trackColor ?? (color || globalColors.blue) : globalColors.gray,
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return <IOSSwitch sx={{ m: 1 }} defaultChecked />;
}