import { Box, FormGroup, InputLabel, Modal, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { NameInput } from './element.style';
import { FieldInputTextarea } from '../../../../atoms';
import { globalColors } from '../../../../../utils/constants/color';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import './MyDatePicker.css';

interface ModalProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  setDate: any;
}

const DateModal = ({ open, onClose, mode, color, setDate }: ModalProps) => {
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date().setDate(new Date().getDate() + 1)));

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '400px',
    maxHeight: '616px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  useEffect(() => {
    const formattedDate = dayjs(selectedDate).format('DD / MM / YYYY');
    setDate(formattedDate);
  }, [selectedDate]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={style} className="content" display={'flex'} flexDirection={'column'}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <ClearIcon sx={{ cursor: 'pointer', color: mode === 'dark' ? globalColors.white : globalColors.black }} onClick={onClose} />
        </Box>

        <Box display={'flex'} gap="10px" alignItems={'center'} marginBottom={'20px'}>
          <form style={{ width: '100%' }}>
            {/* Date Picker */}
            <Box sx={{ width: '100%', marginTop: '2%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'MobileDatePicker', 'DesktopDatePicker', 'StaticDatePicker']}>
                  <StaticDatePicker
                    sx={{
                      '& .MuiDateCalendar-root.css-1q04gal-MuiDateCalendar-root': {
                        backgroundColor: `${mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight} !important`,
                        color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                      },
                      '& .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': {
                        color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                      },
                      '& .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button': {
                        color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                      },
                      '& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel': {
                        color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                      },
                      '& .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root': {
                        color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                      },
                      '& .css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                        color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                        border: `1px solid ${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
                      },
                    }}
                    className="customDatePicker"
                    defaultValue={dayjs(new Date().setDate(new Date().getDate() + 1))}
                    onChange={(newDate: any) => setSelectedDate(dayjs(newDate))}
                    minDate={dayjs(new Date().setDate(new Date().getDate() + 1))}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', gap: '15px', alignItems: 'center', justifyContent: 'center' }}>
              <ButtonStyle onClick={onClose} type="reset" color={globalColors.red} mode={mode}>
                Cancel
              </ButtonStyle>
              <ButtonStyle onClick={onClose} type="submit" color={color} mode={mode}>
                Save
              </ButtonStyle>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '42px',
  background:
    color !== 'default' && color !== ''
      ? type === 'reset'
        ? mode === 'dark'
          ? globalColors.red
          : globalColors.red
        : color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.red
        : globalColors.red
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '148px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default DateModal;
