import { Toast } from '../../../components/atoms';
import { UserRole } from '../../../store/user';
import ActivityModel from '../models/activityModel';
import OnboardingModel from '../models/onboardingModel';
import UpgradeRequestModel from '../models/upgradeRequestModel';
import LeadRepository from './leadRepository';
import ManagerRepository from './managerRepository';
import SalesExecutiveRepository from './salesExecutiveRepository';
import UserRepository from './userRepository';
import Parse from 'parse';

class UpgradeRequestRepository {
  private className = 'UpgradeRequest';

  public async create(object: UpgradeRequestModel): Promise<Parse.Object | null> {
    try {
      const UpgradeRequest = Parse.Object.extend(this.className);
      const newRequest = new UpgradeRequest();

      newRequest.set('amountDue', object.amountDue);
      newRequest.set('memberCount', object.memberCount);
      if (typeof(object.organization) == 'string') {
        const Organization = Parse.Object.extend('Organization');
        const organization = Organization.createWithoutData(object.organization);
        newRequest.set('organization', organization);
      }
      else {
        newRequest.set('organization', object.organization);
      }
      newRequest.set('paymentCycle', object.paymentCycle);
      newRequest.set('perMonthAmount', object.perMonthAmount);
      newRequest.set('tier', object.tier);

      return new Promise((resolve, _) => {
        newRequest
          .save(null)
          .then((savedRequest: Parse.Object | null) => {
            resolve(savedRequest);
          })
          .catch((error: Error) => {
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error adding upgrade request:', error);
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const UpgradeRequest = Parse.Object.extend(this.className);
      const query = new Parse.Query(UpgradeRequest).include('organization.admin', 'organization')
      const upgradeRequest = await query.get(id, { useMasterKey: true });
      return upgradeRequest;
    } catch (error) {
      // console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async initiatePayment(
    amount: number,
    currency: string = 'USD',
    type: string,
    objectId: string,
    customerId: string
  ): Promise<string | null> {
    try {
      return new Promise((resolve, _) => {
        Parse.Cloud.run(
          'createPaymentIntent',
          {
            amount: amount,
            currency: currency,
            type: type,
            objectId: objectId,
            customerId: customerId
          },
          { useMasterKey: true }
        )
          .then(async (result) => {
            if (result && result.status == 'success') {
              resolve(result.clientSecret);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error creating payment intent:', error);
      return null;
    }
  }

  public async verifyPayment(paymentId: string): Promise<string | null> {
    try {
      return new Promise((resolve, _) => {
        Parse.Cloud.run(
          'verifySuccessfulPayment',
          {
            paymentId: paymentId,
          },
          { useMasterKey: true }
        )
          .then(async (result) => {
            if (result && result.status == 'success') {
              resolve(result.data);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            console.log(error);
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error fetching payment intent:', error);
      return null;
    }
  }


}

export default UpgradeRequestRepository;
