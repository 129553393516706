import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useModalViewProducts } from '../../../hooks';
import useModalDeleteProduct from '../../../hooks/useModalDeleteProduct';
import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProperty } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { ColorStatusProduct, ProductCategories } from '../../../utils/types/products';
import { Gallery } from '../../organisms';
import ModalDeleteProduct from '../../organisms/products/modalDeleteProduct';
import ModalViewProduct from '../../organisms/products/modalViewProduct';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import { MdOutlineFileDownload } from 'react-icons/md';
import { Box, TableRow, Checkbox, TableCell, Typography, styled } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../store/selector';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagIcon from '@mui/icons-material/Flag';
import BedIcon from './bedIcon';
import BathroomIcon from './bathroomIcon';
import KitchenIcon from './kitchenIcon';
import WifiIcon from './wifiIcon';
import FireIcon from './fireIcon';
import { setAddNew } from '../../../store/routes';
import { BsEyeFill } from 'react-icons/bs';
import { formatMoney } from '../../../utils/helpers';
import ProjectComponentModel from '../../../utils/api/models/projectComponentModel';
import ModalViewProjectComponent from '../../organisms/products/modalViewProjectComponent';

const options = ['Save', 'Edit', 'Download'];

const ITEM_HEIGHT = 48;

interface ListProductItemProps {
  mode: string;
  data: ProjectComponentModel;
  onShowGallery?: () => void;
  onDelete?: () => void;
  edit: boolean;
  isCanEdit: boolean;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  key: string;
}

const ListInventoryItem = (props: ListProductItemProps) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isHovered, setIsHovered] = useState(false);

  // const handleAction = (option: any) => {
  //   if (option == 'Edit') {
  //     dispatch(setAddNew({ addNew: true, path: '/properties' }));
  //     navigate(route);
  //   }
  // };

  // const handleProjectAction = (option: any) => {
  //   if (option == 'Edit') {
  //     dispatch(setAddNew({ addNew: true, path: '/projects' }));
  //     navigate(route);
  //   }
  // };

  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  // const { isLoading, onDelete } = useProjects();

  // * Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  // * Modal View Detail
  const { openModalView, handleOpenModalView, handleCloseModalView, loadingModal, data, productType } = useModalViewProducts();

  // * Modal Delete
  const { openModalDelete, handleOpenModalDelete, handleDelete, handleCloseModalDelete, productType: productTypeDelete } = useModalDeleteProduct();

  // const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const checked = event.target.checked;

  //   const updatedRowCheckboxState = Object.fromEntries(showLead.map((lead) => [lead.id, checked]));
  //   setRowCheckboxState(updatedRowCheckboxState);
  // };

  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string | undefined) => {
    if (productId) {
      const checked = event.target.checked;
      props.setRowCheckboxState((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [productId]: checked,
      }));
    }
  };

  console.log('COVER PICTURE', props.data.coverPicture);

  return (
    <>
      <TableRow
        sx={{
          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '10px',
          gap: '20px',
          marginBottom: '20px',
          paddingBottom: '20px',
          borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
          position: 'relative',
        }}
        key={props.data.objectId}
      >
        <TableCell sx={style} component="th" scope="row">
          {props.edit && (
            <Checkbox
              size="small"
              sx={{
                color: color || globalColors.blue,
                '&.Mui-checked': {
                  color: color || globalColors.secondBlue,
                },
              }}
              onChange={(event) => {
                handleRowCheckboxChange(event, props.data.objectId);
              }}
              checked={props.rowCheckboxState[props.data.objectId ?? ''] || false}
            />
          )}
        </TableCell>
        <TableCell sx={style} component="th" scope="row">
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            {/* image */}
            <Box
              sx={{
                height: '32px',
                width: '32px',
                borderRadius: '6px',
                backgroundImage: `url(${props.data.coverPicture?.preview})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                cursor: 'pointer',
              }}
              onClick={handleOpen}
            ></Box>
            {/* name */}
            {props.data.name}
          </Box>
        </TableCell>

        {/* type */}
        <TableCell sx={style}>{props.data.project ? props.data.project.get('name') : ''}</TableCell>

        {/* consist of */}
        <TableCell sx={style}>{`${props.data.area ? props.data.area : '-'} ${props.data.area ? props.data.areaUnit : ''}`}</TableCell>

        {/* status */}
        <TableCell style={{ color: props.data.status === 'Available' ? '#00cb45' : props.data.status === 'Hold' ? '#8a8a8a' : '#db0000' }} sx={style}>
          {props.data.status}
        </TableCell>

        {/* price */}
        <TableCell style={{ color: color || globalColors.blue }} sx={style}>
          {(props.data.useDownPayment ? (props.data.downPayment ? formatMoney(props.data.downPayment) : '-') : formatMoney(props.data.price ?? 0)) ??
            '-'}
        </TableCell>

        {/* action */}
        <TableCell sx={style}>
          <Box display={'flex'} gap={'10px'}>
            {props.isCanEdit && (
              <CiEdit
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  // dispatch(setAddNew({ addNew: true, path: '/projects' }));
                  // navigate(route);
                }}
              />
            )}
            <BsEyeFill
              size={'1.3rem'}
              color={color || globalColors.blue}
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenModalView(ProductCategories.ProjectComponent, props.data.objectId ?? '')}
            />
            {props.isCanEdit && (
              <MdDelete
                onClick={() => handleOpenModalDelete(ProductCategories.ProjectComponent)}
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        </TableCell>

        {/* Hot Product
        {dataProject?.priority === 'hot' && (
          <Box sx={{ position: 'absolute', right: '1px', top: '-10px' }}>
            <FireIcon />
          </Box>
        )} */}
      </TableRow>
      <ModalViewProjectComponent open={openModalView} onClose={handleCloseModalView} loading={loadingModal} data={props.data || null} />
      <ModalDeleteProduct
        open={openModalDelete}
        mode={mode}
        color={color}
        onClose={handleCloseModalDelete}
        type={productTypeDelete || null}
        onDelete={() => handleDelete(ProductCategories.ProjectComponent, props.data.objectId ?? '')}
        action={{ permissionName: 'Projects', action: 'Delete' }}
      />
    </>
  );
};

export default ListInventoryItem;
