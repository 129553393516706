import { Box, Divider, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { globalColors } from '../../../../../utils/constants/color';
import { Button, FieldInput, FieldInputTextarea, Toast } from '../../../../atoms';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import Loader from '../../../../atoms/loader';
import { useParams } from 'react-router-dom';
import Dropdowndata from '../../../../atoms/dropdown2';
import SalaryRepository from '../../../../../utils/api/repositories/salaryRepository';
import SalaryModel from '../../../../../utils/api/models/salaryModel';
import UserRepository from '../../../../../utils/api/repositories/userRepository';

interface ModalDetailLeadProps {
  open: boolean;
  mode: string;
  onClose: () => void;
  userId: string;
  color: string;
  onSuccess: () => void
}

interface ValuesAddTag {
  amountPaid: number;
  method: string;
}

interface ErrorsAddTag {
  amountPaid: string;
  method: string;
}

const AddSalaryModal = ({ open, onClose, mode, userId, color, onSuccess }: ModalDetailLeadProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fullName, setFullName] = useState('');
  const [salary, setSalary] = useState(0);
  const [error, setError] = useState('');

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '728px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
      height: '600px',
    },
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userId !== '') {
        const userRepository = new UserRepository();
        const user = await userRepository.getObjectById(userId);

        if (user) {
          setFullName(user.get('firstName') ? user.get('firstName') + ' ' + user.get('lastName') : '');
          setSalary(user.get('salary') ?? 0);
        }
      } else {
        setError('User details could not be fetched successfully. Please try again.');
      }
      setLoading(false);
    };

    fetchUserDetails();
  }, []);

  return (
    <Modal
      keepMounted={false}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      {loading ? (
        <Box sx={style}>
          <Loader />
        </Box>
      ) : (
        <Box sx={style} className="content" display={'flex'} flexDirection={'column'} width={'100%'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '30px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            New Salary Slip
          </Typography>
          <Box sx={{ width: '100%', marginTop: '10px', borderBottom: `1px solid ${globalColors.background.gray}` }}></Box>
          <Box display={'flex'} width={'100%'} flexDirection={'column'} paddingTop={'1rem'} paddingBottom={'1rem'} alignItems={'start'}>
            <Typography variant="h6" sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>
              {fullName}
            </Typography>
            <Typography sx={{ color: globalColors.gray }}>
              Current Salary <span style={{ color: color }}>{salary}</span>
            </Typography>
          </Box>
          <Formik
            initialValues={{
              amountPaid: 0,
              method: '',
            }}
            validate={(values) => {
              const errors = {} as ErrorsAddTag;

              if (!values.method || values.method === 'Select') {
                errors.method = 'Required';
              }

              if (!values.amountPaid || values.amountPaid < 0) {
                errors.amountPaid = 'Please enter a valid amount greater than 0';
              }

              return errors;
            }}
            onSubmit={async (values: ValuesAddTag, { setSubmitting, resetForm }: FormikHelpers<ValuesAddTag>) => {
              const userRepository = new UserRepository();
              const newSalary: SalaryModel = {
                amount: values.amountPaid,
                method: values.method,
                difference: Math.abs(values.amountPaid - salary),
              };
              const savedSalary = await userRepository.addSalarySlip(newSalary, userId);
              if (savedSalary) {
                onSuccess();
              }
              onClose();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <Form>
                <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
                  <FieldInput
                    optional={false}
                    label="Payment Amount"
                    id="amountPaid"
                    mode={mode}
                    name={'amountPaid'}
                    placeholder="Payment Amount"
                    type="number"
                    min={0}
                    error={touched.amountPaid && errors.amountPaid ? errors.amountPaid : false}
                  />
                  <Box>
                    <Dropdowndata
                      label="Payment Method"
                      id="method"
                      name="method"
                      mode={mode}
                      optional={false}
                      values={['', 'Bank Transfer', 'Cash', 'Cheque', 'Other'].map((method) => {
                        return { label: method === '' ? 'Select' : method, value: method };
                      })}
                      value={values.method}
                      onChange={(e) => {
                        setFieldValue('method', e.target.value);
                      }}
                      defaultValue=""
                    />
                    <StyledTypography>{errors.method}</StyledTypography>
                  </Box>
                  {values.amountPaid > 0 && (
                    <Box
                      display={'flex'}
                      width={'100%'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      paddingTop={'1rem'}
                      paddingBottom={'1rem'}
                      alignItems={'center'}
                    >
                      <Typography sx={{ color: globalColors.gray }}>
                        {salary - values.amountPaid > 0 ? 'Deduction' : 'Bonus'}{' '}
                        <span style={{ color: salary - values.amountPaid > 0 ? globalColors.red : color }}>
                          {salary - values.amountPaid > 0 ? salary - values.amountPaid : values.amountPaid - salary}
                        </span>
                      </Typography>
                    </Box>
                  )}
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Create'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Modal>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '0.75rem',
}));

export default AddSalaryModal;
