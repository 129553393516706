import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { GrYoutube } from 'react-icons/gr';
import { DownArrow, Instagram, Twitter, UpArrow, Youtube } from '../../assets/Icons';
import NotFoundLottie from '../atoms/notFound';
import SocialMediaNotFoundLottie from '../atoms/SocialMediaNotFoundLottie';
import { Button } from '../atoms';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../store/selector';

interface SocialMediaCardProps {
  value: string;
  title: string;
  direction: string;
  action: any;
  platform: string;
  buttonText: string;
  status: string;
}

type SocialIcons = {
  [key: string]: JSX.Element;
};

const socialIcons: SocialIcons = {
  Facebook: <FaFacebookF size={'1.5rem'} color="#599BF7" style={{ margin: 'auto' }} />,
  Twitter: <img style={{ margin: 'auto' }} src={Twitter} alt="Twitter" width="24px" height="24px" />,
  Instagram: <img style={{ margin: 'auto' }} src={Instagram} alt="Instagram" width="24px" height="24px" />,
  Youtube: <img style={{ margin: 'auto' }} src={Youtube} alt="Youtube" width="24px" height="20px" />,
};

const SocialMediaCard: React.FC<SocialMediaCardProps> = ({ value, title, direction, action, platform, buttonText, status }) => {
  const mode = useSelector(selectThemeMode);
  return (
    <Box bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight} padding={'1rem 2rem'} borderRadius={'10px'} width={'100%'}>
      <Box display={'flex'} gap={'1rem'}>
        <Box
          component={'span'}
          display={'flex'}
          justifyContent={'center'}
          bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
          borderRadius={'100px'}
          padding={'0.2rem 0.6rem'}
        >
          {socialIcons[platform]}
          {/* <SocialMediaNotFoundLottie showButton={false} text="" buttonText="" navigateTo="" /> */}
        </Box>
        <Box component={'span'} display={'flex'} flexDirection={'column'} gap={'10px'}>
          <Typography sx={{ fontSize: '14px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>{title}</Typography>
        </Box>
      </Box>

      <Box display={'flex'} justifyContent={'end'}>
        <ButtonStyle onClick={() => action()}>{buttonText}</ButtonStyle>
      </Box>
    </Box>
  );
};

const ButtonStyle = styled('button')<any>((props) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  opacity: props.disabled ? '0.5' : '1',
  height: '40px',
  background: useSelector(selectColor) ? useSelector(selectColor) : globalColors.blue,
  color: globalColors.white,
  width: '140px',
  padding: '0px 8px',
  display: 'grid',
  marginTop: '2rem',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: props.type === 'reset' ? `1px solid ${props.color ? props.color : globalColors.blue}` : '',
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default SocialMediaCard;
