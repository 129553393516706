import { Box, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Sidebar from '../../components/molecules/sidebar';
import RadialBarchart from '../../components/organisms/charts/radialBarChart';
import { PiHandshakeFill } from 'react-icons/pi';
import { FaUsersGear } from 'react-icons/fa6';
import { FaMoneyBills } from 'react-icons/fa6';
import Piechart from '../../components/organisms/charts/pieChart';
// import Project from '../../assets/Icons/ProjectIcon.svg';
// import Sales from '../../assets/Icons/SalesIcon.svg';
// import Leads from '../../assets/Icons/LeadsIcon.svg';
// import Employee from '../../assets/Icons/EmployeeIcon.svg';
import { useStyles } from '../../components/organisms/dashboard/style';
import Barchart from '../../components/organisms/charts/barCharts';
import UpcomingTask from '../../components/organisms/dashboard/upcomingTask';
import LeadsTable from '../../components/organisms/dashboard/leads/leadstable';
import ConversionsTable from '../../components/organisms/dashboard/conversionsTable';
import SortTable from '../../components/organisms/dashboard/leads/sort';
import Heading from '../../components/atoms/heading';
import { globalColors } from '../../utils/constants/color';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import LeadsFlter from '../../components/organisms/dashboard/leads/filter';
import { HiUserGroup } from 'react-icons/hi2';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import Counts from '../../components/organisms/dashboard/counts';
import CompanyGraph from '../../components/molecules/perfomance/company/companyGraph';
import StatusChart from '../../components/organisms/dashboard/statusChart';
import PriorityChart from '../../components/organisms/dashboard/priorityChart';

const Dashboard = () => {
  const classes = useStyles();
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text="Dashboard" />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} padding={3} overflow={'auto'}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Box
                  bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                  border={`1px solid ${globalColors?.border.gray}`}
                  borderRadius={'16px'}
                  boxShadow={'#599BF740'}
                  paddingLeft={'30px'}
                  paddingRight={'10px'}
                  paddingTop={'10px'}
                  height={'100%'}
                >
                  <StatusChart />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Box
                  bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                  border={`1px solid ${globalColors?.border.gray}`}
                  borderRadius={'16px'}
                  boxShadow={'#303030'}
                  paddingLeft={'30px'}
                  paddingRight={'10px'}
                  paddingTop={'10px'}
                  height={'100%'}
                >
                  <PriorityChart />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Counts />
              </Grid>
            </Grid>
            {/* Second Row */}
            <Grid container spacing={2} marginTop={'10px'}>
              <Grid item xs={12} md={12} lg={12}>
                <CompanyGraph />
              </Grid>
            </Grid>
            {/* THird Row */}
            <Grid container spacing={2} marginTop={'10px'}>
              <Grid item xs={12} md={12} lg={8}>
                <Box
                  bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                  border={`1px solid ${globalColors?.border.gray}`}
                  borderRadius={'16px'}
                  boxShadow={'#303030'}
                  paddingLeft={'30px'}
                  paddingRight={'30px'}
                  paddingTop={'10px'}
                  height={'100%'}
                >
                  <LeadsTable />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Box
                  bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                  border={`1px solid ${globalColors?.border.gray}`}
                  borderRadius={'16px'}
                  boxShadow={'#303030'}
                  paddingLeft={'30px'}
                  paddingRight={'30px'}
                  paddingTop={'10px'}
                  paddingBottom={'10px'}
                  height={'100%'}
                >
                  <UpcomingTask />
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={12} lg={4}>
                <Box
                  bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
                  border={`1px solid ${globalColors?.border.gray}`}
                  borderRadius={'16px'}
                  boxShadow={'#303030'}
                  paddingLeft={'30px'}
                  paddingRight={'30px'}
                  paddingTop={'10px'}
                  paddingBottom={'10px'}
                  height={'100%'}
                >
                  <NavMenuStyle>
                    <Box className="filter-wrapper">
                      <Heading text={'Conversions'} />
                      
                    </Box>
                  </NavMenuStyle>
                  <ConversionsTable />
                </Box>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const NavMenuStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '& .filter-wrapper': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    paddingTop: '10px',
    paddingBottom: '25px',
  },

  [theme.breakpoints.down('md')]: {
    gap: '1rem',
    '& .btn-add': {
      width: '100%',
    },
    '& .filter-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& .btn-view, .filter': {
        width: '50%',
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    '& .filter-wrapper': {
      flexDirection: 'column',
      gap: '8px',
      '&:nth-child(even)': {
        flex: 1,
        width: '50%',
      },
      '& .btn-view, .filter': {
        width: '100%',
      },
    },
  },
}));

export default Dashboard;
