import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './accordian.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../../utils/constants/color';
import { selectPermissionsState, selectThemeMode } from '../../../../store/selector';
import ManagerLeadList from './table/managerLeadList';
import SalesExecutiveList from './table/salesExecutiveList';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { Box } from '@mui/material';
import { PermissionsInterface } from '../../../../utils/helpers';

export default function RoleAccordion() {
  const mode = useSelector(selectThemeMode);
  // const elements = document.getElementsByClassName('css-1elwnq4-MuiPaper-root-MuiAccordion-root');
  const [search, setsearch] = useState<string>('');
  const [managerGenericPermissions, setManagerGenericPermissions] = useState<boolean | null>(null);
  const [executiveGenericPermissions, setExecutiveGenericPermissions] = useState<boolean | null>(null);
  const [managerPermissions, setManagerPermissions] = useState<PermissionsInterface[]>([]);
  const [executivePermissions, setExecutivePermissions] = useState<PermissionsInterface[]>([]);
  const permissionsState = useSelector(selectPermissionsState);

  useEffect(() => {
    const fetchPermissionsStatus = async () => {
      const userRepository = new UserRepository();
      const organization = await userRepository.getCurrentOrganization();
      if (organization) {
        setManagerGenericPermissions(organization.get('managerGenericPermissions') ?? false);
        setExecutiveGenericPermissions(organization.get('executiveGenericPermissions') ?? false);
        setManagerPermissions(organization.get('managerPermissions') ?? []);
        setExecutivePermissions(organization.get('executivePermissions') ?? []);
      }
    };

    fetchPermissionsStatus();
  }, [permissionsState]);

  return (
    <div>
      {/* Managers */}
      <Accordion
        sx={{
          borderRadius: '20px !important',
          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
          marginBottom: '2rem',
        }}
        defaultExpanded={false}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box display={'flex'} flexDirection={'column'}>
            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Managers</Typography>
            {managerGenericPermissions === null ? (
              <Typography></Typography>
            ) : managerGenericPermissions ? (
              <Typography color={globalColors.gray} fontSize={'12px'}>
                Your company is using generic permissions for all managers. Changes will apply to all managers.
              </Typography>
            ) : (
              <Typography color={globalColors.gray} fontSize={'12px'}>
                Your company is using unique permissions for each manager. Changing these permissions would overwrite the unique permissions of each
                manager.
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ManagerLeadList data={managerPermissions} shouldCheck={managerGenericPermissions ?? false} />
        </AccordionDetails>
      </Accordion>

      {/* Sale Executives */}
      <Accordion
        sx={{
          borderRadius: '20px !important',
          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
          marginBottom: '2rem',
        }}
        defaultExpanded={false}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box display={'flex'} flexDirection={'column'}>
            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Executives</Typography>
            {executiveGenericPermissions === null ? (
              <Typography></Typography>
            ) : executiveGenericPermissions ? (
              <Typography color={globalColors.gray} fontSize={'12px'}>
                Your company is using generic permissions for all executives. Changes will apply to all executives.
              </Typography>
            ) : (
              <Typography color={globalColors.gray} fontSize={'12px'}>
                Your company is using unique permissions for each executive. Changing these permissions would overwrite the unique permissions of each
                executive.
              </Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <SalesExecutiveList data={executivePermissions} shouldCheck={executiveGenericPermissions ?? false} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
