import { makeStyles } from '@mui/styles';
import { globalColors } from '../../../utils/constants/color';

export const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${globalColors.border.gray}`,
    boxShadow: '#303030',
    borderRadius: '16px',
    paddingLeft: '20px',
    paddingRight: '20px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  cardWithoutBorder: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '12px',
    transition: 'background-color 0.3s ease',
    width: '100%',
    border: '1px solid transparent',
    '&:hover': {
      // backgroundColor: "#333333",
      border: '1px solid #111111',
    },
  },
  infoBoxContent: {
    display: 'flex',
    gap: '20px',
  },
  infoBoxImage: {
    width: '24px', // Set your desired width
    height: '24px', // Set your desired height
    marginTop: '5px',
  },
  infoBoxTitle: {
    fontSize: '14px',
    color: '#939393',
  },
  infoBoxValue: {
    fontSize: '16px',
    // paddingRight: '40px',
  },
}));
