import { Box, Button, Modal, Stack, Typography, styled } from '@mui/material';
import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { IAmenities, addAminitiesItem } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { FieldInput, FieldInputUnit } from '../../atoms';
import FieldInputFile from '../../atoms/fieldInputFile';
import { HeadingFormAuth } from '../../molecules';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '525px',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: '1.5rem',
  borderRadius: '20px',

  '@media (max-width: 576px)': {
    width: '80%',
  },
};

interface FormValues {
  label: string;
}

interface ModalAddAmenities {
  open: boolean;
  onClose: () => void;
  mode: string;
  color: string;
}
const ModalAddAmenities = ({ open, onClose, mode, color }: ModalAddAmenities) => {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [formAmenities, setFormAmenities] = React.useState({
    label: '',
    value: '',
    icon: '',
  });

  const handleFormAmenities = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log('NAME', name);
    console.log('VALUE', value);
    setFormAmenities({
      ...formAmenities,
      [name]: value,
    });
  };

  const handleAddAmenities = (amenity: IAmenities) => {
    dispatch(addAminitiesItem(amenity));
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setFormAmenities({
      label: '',
      value: '',
      icon: '',
    });
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-add-amenities" aria-describedby="modal-request-add-amenities" className="content">
      <ModalAddAmenitiesStyle>
        <Box sx={style} bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.white} className="content">
          <HeadingFormAuth mode={mode} title="Add New Amenity" subTitle="Please enter amenity details." onGoBack={null} />
          <Box gap={2}>
            <Formik
              initialValues={{
                label: '',
              }}
              validate={(values) => {
                const errors = {} as FormValues;

                if (!values.label) {
                  errors.label = 'Please enter a valid label';
                }

                return errors;
              }}
              onSubmit={(values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
                const amenity: IAmenities = {
                  label: values.label,
                  value: '1'
                }
                handleAddAmenities(amenity);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Box>
                  <FieldInput
                    className="field-input"
                    placeholder="Amenity Label (Laundry, Garage etc.)"
                    type="text"
                    name="label"
                    id="label"
                    mode={mode}
                    value={formAmenities['label']}
                  />
                  {touched.label && errors.label && <Typography pt={'0.8rem'} color={'red'} fontSize={'10px'}>
                    {errors?.label}
                  </Typography>}
                  <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'30px'}>
                    <Button
                      sx={{
                        border: `1px solid ${globalColors.red}`,
                        color: mode == 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: globalColors.red,
                        },
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        border: `1px solid ${color || globalColors.blue}`,
                        color: mode == 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: `${color || globalColors.blue}`,
                        },
                      }}
                      onClick={() => {handleSubmit()}}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
          {/* <Button variant="contained" type="button" className="btn-add" onClick={handleAddAmenities}>
            Add Amenity
          </Button>
          <Button variant="contained" type="button" className="btn-add" onClick={handleAddAmenities}>
            Add Amenity
          </Button> */}
        </Box>
      </ModalAddAmenitiesStyle>
    </Modal>
  );
};

const ModalAddAmenitiesStyle = styled('div')(({ theme }) => ({
  '& .btn-add': {
    marginTop: '1rem',
    width: '100%',
  },
}));

export default ModalAddAmenities;
