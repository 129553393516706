import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/molecules/layout';
import CardProductItem from '../../components/molecules/products/cardProductItem';
import { ProductDetail } from '../../components/organisms';
import FormAddProject from '../../components/organisms/product/formProject';
import { useFormProject } from '../../hooks';
import { ProductCategories } from '../../utils/types/products';
import { selectNavigationLayout, selectThemeMode } from '../../store/selector';
import AddProductCard from '../../components/molecules/products/addProjectCard';
import { useEffect, useState } from 'react';
import { hasPermissionForAction } from '../../utils/helpers';
import { Box } from '@mui/material';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';

const CreateProject = () => {
  const { formProject } = useFormProject();
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Projects', 'Create'));
    setLoading(false);
  }, []);

  return (
    <MainLayout titlePage="Projects / New" mode={mode} navigation={navigation}>
      {loading ? (
        <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <ProductDetail
          form={<FormAddProject type="add" mode={mode} />}
          mode={mode}
          preview={<CardProductItem category={ProductCategories.Project} mode={mode} data={formProject} active={false} />}
        />
      ) : (
        <NoPermission />
      )}
    </MainLayout>
  );
};

export default CreateProject;
