import Parse, { User } from 'parse';
import { IMGDefault } from '../../../assets/Images';
import { Toast } from '../../../components/atoms';
import { IFormDetailProject, IImages } from '../../../store/projects';
import { dataLimit, extendedDataLimit, getCurrentQuarterDates, getYearQuarter, isArray, isFile } from '../../helpers';
import GalleryModel from '../models/galleryModel';
import ProjectModel from '../models/projectModel';
import GalleryRepository from './galleryRepository';
import { Picture } from '../models/propertyModel';
import UserRepository from './userRepository';
import FileRepository from './fileRepository';
import FileModel from '../models/fileModel';
import ProjectComponentRepository from './projectComponentRepository';
import ProjectComponentModel from '../models/projectComponentModel';
import { UserRole } from '../../../store/user';
import SalesExecutiveRepository from './salesExecutiveRepository';
import ManagerRepository from './managerRepository';

class ProjectRepository {
  private className = 'Project';

  constructor() {}

  public async create(object: ProjectModel) {
    const Project = Parse.Object.extend(this.className);
    const userRepository = new UserRepository();
    const project = new Project();

    project.set('floorsBlocks', [object.floorsBlocks]);
    project.set('name', object.name.toString());
    project.set('contactName', object.contactName.toString());
    project.set('contactPhone', object.contactPhone);
    project.set('areaUnit', object.areaUnit.toString());
    project.set('area', +object.area);
    project.set('areaUnit', object.areaUnit.toString());
    const status = object.status ? object.status : 'Available';
    project.set('status', status);
    project.set('consistOf', object.consistOf);
    project.set('consistOfUnit', object.consistOfUnit);
    project.set('purpose', object.purpose);
    project.set('category', object.category);
    project.set('type', object.type);
    project.set('contactSecondaryPhone', object.contactSecondaryPhone);
    project.set('country', object.country);
    project.set('state', object.state);
    project.set('city', object.city);
    project.set('address', { address: object.address });
    project.set('description', object.description);
    project.set('notes', object.notes);
    // const priority = object.priority ? object.priority : 'hot';
    project.set('priority', object.priority);
    project.set('createdAt', object.createdAt);
    project.set('customFields', object.customFields);
    if (object.status === 'Sold') {
      project.set('soldAt', object.soldAt);
      project.set('price', parseFloat(object.sellingPrice ?? '-1'));
      project.set('sellingPrice', parseFloat(object.sellingPrice ?? '-1'));
      if (object.soldBy) {
        const seller = await userRepository.getObjectById(object.soldBy);
        if (seller) {
          if (seller.get('role') === UserRole.executive) {
            const salesExecutiveRepository = new SalesExecutiveRepository();
            const salesExecutive = await salesExecutiveRepository.getObjectByUserId(seller.id);
            if (salesExecutive) {
              project.set('saleManagedBy', salesExecutive.get('manager').get('user'));
            }
          } else if (seller.get('role') === UserRole.manager) {
            const managerRepository = new ManagerRepository();
            const manager = await managerRepository.getObjectByUserId(seller.id);
            if (manager) {
              project.set('saleManagedBy', manager.get('user'));
            }
          }
          project.set('soldBy', seller);
        } else {
          return null;
        }
      }
    }

    if (object.attachments && object.attachments.length > 0) {
      const fileRepository = new FileRepository();
      let savedFiles: any[] = [];
      const attachmentFiles = object.attachments as File[];
      await Promise.all(
        attachmentFiles.map(async (file) => {
          const fileModel: FileModel = {
            file: file,
          };
          const savedFile = await fileRepository.create(fileModel);
          if (savedFile) {
            savedFiles.push(savedFile);
          }
        })
      );
      const attachmentsRelation = project.relation('attachments');
      attachmentsRelation.add(savedFiles);
    }
    let savedComponents: Parse.Object[] = [];
    if (object.components && object.components.length > 0) {
      const projectComponentRepository = new ProjectComponentRepository();
      for (let i = 0; i < object.components.length; i++) {
        const savedComponent = await projectComponentRepository.create(object.components[i]);
        if (savedComponent) {
          savedComponents.push(savedComponent);
        }
      }

      const componentsRelation = project.relation('components');
      componentsRelation.add(savedComponents);
    }

    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      project.set('createdBy', currentUser);
      project.set('updatedBy', currentUser);
      project.set('organization', currentUser.get('organization'));
    } else {
      project.set('createdBy', null);
    }

    // ! Cover Picture
    if (object.coverPicture && isFile(object.coverPicture)) {
      const galleryRepository = new GalleryRepository();
      const galleryModel: GalleryModel = {
        file: object.coverPicture,
      };
      const savedFile = await galleryRepository.createAndReturn(galleryModel);
      project.set('coverPicture', savedFile);
    }

    // ! Pictures
    if (isArray(object.pictures) && object.pictures.length > 0) {
      const galleryRepository = new GalleryRepository();
      let savedFiles: any[] = [];
      const imagesFiles = object.pictures as File[];

      await Promise.all(
        imagesFiles.map(async (file) => {
          const galleryModel: GalleryModel = {
            file: file,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          if (savedFile) {
            savedFiles.push(savedFile);
          }
        })
      );
      const galleriesRelation = project.relation('pictures');
      galleriesRelation.add(savedFiles);
    }

    await project.save(null, { useMasterKey: true }).then(
      async (project: any) => {
        for (let i = 0; i < savedComponents.length; i++) {
          savedComponents[i].set('project', project);
          await savedComponents[i].save(null, { useMasterKey: true });
        }
        return Toast(`Project added successfully.`, 'success');
      },
      (error: Error) => {
        console.log(error);
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        return Toast(`Project could not be added successfuly. Please try again.`, 'error');
        // alert('Failed to create new object, with error code: ' + error.message);
      }
    );
  }

  public async getAll(
    search?: string | null,
    status?: string | null,
    type?: string | null,
    purpose?: string | null,
    page?: number
  ): Promise<IFormDetailProject[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Project = Parse.Object.extend(this.className);
      var query = new Parse.Query(Project).descending('createdAt');

      query.include('coverPicture');
      query.include('pictures');
      query.equalTo('organization', currentUser.get('organization'));
      query.limit(dataLimit);
      if (status && status !== '') query.equalTo('status', status);
      if (type && type !== '') query.equalTo('type', type);
      if (purpose && purpose !== '') query.equalTo('purpose', purpose);
      if (search) {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }

      if (page) {
        query.skip((page - 1) * dataLimit);
      }
      try {
        const projects = await query.find({ useMasterKey: true });
        // Convert the Parse objects to ProjectModel
        const projectDataResult: IFormDetailProject[] = [];

        for (let i = 0; i < projects.length; i++) {
          const project = projects[i];
          const projectData = project.toJSON();

          // ! Handling cover picture
          const coverPicture = project.get('coverPicture');
          let coverPictureUrl = IMGDefault;
          if (coverPicture) {
            coverPictureUrl = coverPicture.toJSON()?.file?.url;
          }

          // ! Handling pictures
          const picturesRelation = project.relation('pictures');

          // Query the relation to get the gallery
          const urlPictures: IImages[] = [];
          await picturesRelation
            .query()
            .find({ useMasterKey: true })
            .then((gallery: { [x: string]: any }) => {
              if (gallery) {
                if (gallery && gallery.length > 0) {
                  gallery.forEach((picture: any) => {
                    const getPicture = picture.toJSON();
                    if (getPicture) {
                      const picture = {
                        id: getPicture.objectId,
                        url: getPicture.file?.url,
                        file: getPicture.file,
                      };
                      urlPictures.push(picture);
                    }
                  });
                }
              } else {
                console.error('Gallery not found.');
              }
            })
            .catch((error: { message: string }) => {
              console.error('Error querying gallery relation: ' + error.message);
            });

          const componentsRelation = project.relation('components');
          const componentCount = await componentsRelation.query().count({ useMasterKey: true });

          let emptyComponents: ProjectComponentModel[] = [];

          for (let i = 0; i < componentCount; i++) {
            emptyComponents.push({});
          }

          const data = {
            id: project.id,
            name: projectData.name ?? '-',
            cover: {
              files: [],
              preview: coverPictureUrl,
            },
            type: projectData.type ?? '-',
            country: projectData.country ?? '-',
            state: projectData.state ?? '-',
            city: projectData.city ?? '-',
            address: projectData.address?.address ?? '-',
            consistOf: projectData.consistOf ?? '-', // TODO : add created
            consistOfUnit: projectData.consistOfUnit ?? '-', // TODO : add created
            area: projectData.area ?? '-',
            areaUnit: projectData.areaUnit ?? '-',
            purpose: projectData.purpose ?? '-', // TODO : add created
            description: projectData.description ?? '-',
            contactName: projectData.contactName ?? '-',
            contactPhone: projectData.contactPhone ?? '-',
            contactSecondaryPhone: projectData.contactSecondaryPhone ?? '-',
            status: projectData.status ?? '-',
            images: urlPictures ?? null,
            priority: projectData.priority ?? '-',
            components: componentCount > 0 ? emptyComponents : undefined,
          };

          // if (project.get('pictures').length > 0) {
          //   project.get('pictures').forEach((picture: any) => {
          //     data.images.push(picture.attributes.file._url);
          //   });
          // }
          projectDataResult.push(data);
        }

        return projectDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getAllCount(search?: string | null, status?: string | null, type?: string | null, purpose?: string | null): Promise<number> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Project = Parse.Object.extend(this.className);
      var query = new Parse.Query(Project).descending('createdAt');

      query.equalTo('organization', currentUser.get('organization'));
      if (status && status !== '') query.equalTo('status', status);
      if (type && type !== '') query.equalTo('type', type);
      if (purpose && purpose !== '') query.equalTo('purpose', purpose);
      if (search) {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }

      try {
        const projects = await query.count({ useMasterKey: true });
        return projects;
      } catch (error) {
        console.error('Error fetching project:', error);
        return 0;
      }
    } else {
      return 0;
    }
  }

  public async getSome(): Promise<IFormDetailProject[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Project = Parse.Object.extend(this.className);
      var query = new Parse.Query(Project).descending('createdAt').limit(5);

      query.include('coverPicture');
      query.include('pictures');
      query.equalTo('organization', currentUser.get('organization'));

      try {
        const projects = await query.find({ useMasterKey: true });
        // Convert the Parse objects to ProjectModel
        const projectDataResult: IFormDetailProject[] = [];
        await Promise.all(
          projects.map(async (project: any) => {
            const projectData = project.toJSON();

            // ! Handling cover picture
            const coverPicture = project.get('coverPicture');
            let coverPictureUrl = IMGDefault;
            if (coverPicture) {
              coverPictureUrl = coverPicture.toJSON()?.file?.url;
            }

            // ! Handling pictures
            const picturesRelation = project.relation('pictures');

            // Query the relation to get the gallery
            const urlPictures: IImages[] = [];
            await picturesRelation
              .query()
              .find({ useMasterKey: true })
              .then((gallery: { [x: string]: any }) => {
                if (gallery) {
                  if (gallery && gallery.length > 0) {
                    gallery.forEach((picture: any) => {
                      const getPicture = picture.toJSON();
                      if (getPicture) {
                        const picture = {
                          id: getPicture.objectId,
                          url: getPicture.file?.url,
                          file: getPicture.file,
                        };
                        urlPictures.push(picture);
                      }
                    });
                  }
                } else {
                  console.error('Gallery not found.');
                }
              })
              .catch((error: { message: string }) => {
                console.error('Error querying gallery relation: ' + error.message);
              });

            const data = {
              id: project.id,
              name: projectData.name ?? '-',
              cover: {
                files: [],
                preview: coverPictureUrl,
              },
              type: projectData.type ?? '-',
              country: projectData.country ?? '-',
              state: projectData.state ?? '-',
              city: projectData.city ?? '-',
              address: projectData.address?.address ?? '-',
              consistOf: projectData.consistOf ?? '-', // TODO : add created
              consistOfUnit: projectData.consistOfUnit ?? '-', // TODO : add created
              area: projectData.area ?? '-',
              areaUnit: projectData.areaUnit ?? '-',
              purpose: projectData.purpose ?? '-', // TODO : add created
              description: projectData.description ?? '-',
              contactName: projectData.contactName ?? '-',
              contactPhone: projectData.contactPhone ?? '-',
              contactSecondaryPhone: projectData.contactSecondaryPhone ?? '-',
              status: projectData.status ?? '-',
              images: urlPictures ?? null,
              priority: projectData.priority ?? '-',
            };

            // if (project.get('pictures').length > 0) {
            //   project.get('pictures').forEach((picture: any) => {
            //     data.images.push(picture.attributes.file._url);
            //   });
            // }
            projectDataResult.push(data);
          })
        );

        return projectDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getByObjectId(objectId: string): Promise<IFormDetailProject | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);

    query.include('coverPicture', 'soldBy');

    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (project) => {
        const projectData = project.toJSON();

        // ! Handling cover picture
        const coverPicture = project.get('coverPicture');
        let coverPictureUrl = null;
        if (coverPicture) {
          coverPictureUrl = coverPicture.toJSON()?.file?.url;
        }

        // ! Handling pictures
        const picturesRelation = project.relation('pictures');

        // Query the relation to get the gallery
        const urlPictures: IImages[] = [];
        await picturesRelation
          .query()
          .find({ useMasterKey: true })
          .then((gallery: { [x: string]: any }) => {
            if (gallery) {
              if (gallery && gallery.length > 0) {
                gallery.forEach((picture: any) => {
                  const getPicture = picture.toJSON();
                  if (getPicture) {
                    const picture = {
                      id: getPicture.objectId,
                      url: getPicture.file?.url,
                      // file: getPicture.file,
                    };
                    urlPictures.push(picture);
                  }
                });
              }
            } else {
              console.error('Gallery not found.');
            }
          })
          .catch((error: { message: string }) => {
            console.error('Error querying gallery relation: ' + error.message);
          });

        const componentsRelation = project.relation('components');
        const componentObjects = await componentsRelation.query().include('coverPicture').find({ useMasterKey: true });

        let components: ProjectComponentModel[] = [];

        for (let i = 0; i < componentObjects.length; i++) {
          let component: ProjectComponentModel = {
            objectId: componentObjects[i].id,
            name: componentObjects[i].get('name'),
            coverPicture: componentObjects[i].get('coverPicture')
              ? { file: null, preview: componentObjects[i].get('coverPicture').get('file')._url }
              : { file: null, preview: '' },
            area: componentObjects[i].get('area') ?? '',
            areaUnit: componentObjects[i].get('areaUnit'),
            contactName: componentObjects[i].get('contactName'),
            contactPhone: componentObjects[i].get('contactPhone'),
            contactSecondaryPhone: componentObjects[i].get('contactSecondaryPhone'),
            description: componentObjects[i].get('description'),
            price: componentObjects[i].get('price'),
            purpose: componentObjects[i].get('purpose'),
            sellingPrice: componentObjects[i].get('sellingPrice'),
            soldAt: componentObjects[i].get('soldAt'),
            soldBy: componentObjects[i].get('soldBy') ? componentObjects[i].get('soldBy').id : null,
            status: componentObjects[i].get('status'),
            customFields: componentObjects[i].get('customFields'),
            useDownPayment: componentObjects[i].get('useDownPayment'),
            downPayment: componentObjects[i].get('downPayment'),
          };

          components.push(component);
        }

        const data = {
          id: project.id,
          name: projectData.name ?? '-',
          cover: {
            files: [],
            preview: coverPictureUrl,
          },
          type: projectData.type ?? '-',
          country: projectData.country ?? '-',
          state: projectData.state ?? '-',
          city: projectData.city ?? '-',
          address: projectData.address?.address ?? '-',
          consistOf: projectData.consistOf ?? '-',
          consistOfUnit: projectData.consistOfUnit ?? '-',
          area: projectData.area ?? '-',
          areaUnit: projectData.areaUnit ?? '-',
          purpose: projectData.purpose ?? '-',
          description: projectData.description ?? '-',
          contactName: projectData.contactName ?? '-',
          contactPhone: projectData.contactPhone ?? '-',
          contactSecondaryPhone: projectData.contactSecondaryPhone ?? '-',
          status: projectData.status ?? '-',
          images: urlPictures ?? null,
          priority: projectData.priority ?? '-',
          sellingPrice: projectData.sellingPrice ?? '0',
          soldAt: projectData.soldAt
            ? projectData.soldAt.iso.split('T')[0]
            : `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
                .getDate()
                .toString()
                .padStart(2, '0')}`,
          soldBy: project.get('soldBy') ? project.get('soldBy').id : '',
          components: components,
          customFields: project.get('customFields'),
          lockedFor: project.get('lockedFor'),
          lockedUntil: project.get('lockedUntil'),
        };

        return data;
      })
      .catch((error) => {
        console.log(error);
        // console.error('Error:', error);
        return null;
      });
  }

  public async getObjectByObjectId(objectId: string): Promise<Parse.Object | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);

    query.include('coverPicture');

    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (project) => {
        return project;
      })
      .catch((error) => {
        console.log(error);
        // console.error('Error:', error);
        return null;
      });
  }

  public async updateByObjectId(objectId: string, object: ProjectModel): Promise<any> {
    // IFormDetailProject | null
    const Project = Parse.Object.extend(this.className);
    const userRepository = new UserRepository();
    const query = new Parse.Query(Project);
    const galleryRepository = new GalleryRepository();

    query.include('coverPicture');

    const project = await query.get(objectId, { useMasterKey: true });

    try {
      if (!project) {
        return false;
      }

      if (project) {
        if (object.name !== project.get('name')) {
          project.set('name', object.name.toString());
        }

        if (object.floorsBlocks) {
          project.set('floorsBlocks', [object.floorsBlocks]);
        }

        if (object.contactName) {
          project.set('contactName', object.contactName.toString());
        }
        if (object.contactPhone) {
          project.set('contactPhone', object.contactPhone);
        }
        if (object.areaUnit) {
          project.set('areaUnit', object.areaUnit.toString());
        }
        if (object.area) {
          project.set('area', +object.area);
        }
        if (object.areaUnit) {
          project.set('areaUnit', object.areaUnit.toString());
        }

        if (object.status) {
          const status = object.status ? object.status : 'Available';
          project.set('status', status);
        }

        if (object.consistOf) {
          project.set('consistOf', object.consistOf);
        }
        if (object.consistOfUnit) {
          project.set('consistOfUnit', object.consistOfUnit);
        }

        if (object.purpose) {
          project.set('purpose', object.purpose);
        }

        if (object.category) {
          project.set('category', object.category);
        }

        if (object.type) {
          project.set('type', object.type);
        }

        if (object.contactSecondaryPhone) {
          project.set('contactSecondaryPhone', object.contactSecondaryPhone);
        }

        if (object.country) {
          project.set('country', object.country);
        }

        if (object.state) {
          project.set('state', object.state);
        }

        if (object.city) {
          project.set('city', object.city);
        }

        if (object.address) {
          project.set('address', { address: object.address });
        }

        if (object.description) {
          project.set('description', object.description);
        }

        if (object.notes) {
          project.set('notes', object.notes);
        }

        if (object.status === 'Sold') {
          if (object.sellingPrice) {
            project.set('sellingPrice', parseFloat(object.sellingPrice ?? '-1'));
            project.set('price', parseFloat(object.sellingPrice ?? '-1'));
          }
          if (object.soldAt) {
            if (object.status === 'Sold') {
              project.set('soldAt', object.soldAt);
            } else {
              project.set('soldAt', null);
            }
          }
          if (object.soldBy) {
            const seller = await userRepository.getObjectById(object.soldBy);
            if (seller) {
              if (seller.get('role') === UserRole.executive) {
                const salesExecutiveRepository = new SalesExecutiveRepository();
                const salesExecutive = await salesExecutiveRepository.getObjectByUserId(seller.id);
                if (salesExecutive) {
                  project.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                }
              } else if (seller.get('role') === UserRole.manager) {
                const managerRepository = new ManagerRepository();
                const manager = await managerRepository.getObjectByUserId(seller.id);
                if (manager) {
                  project.set('saleManagedBy', manager.get('user'));
                }
              }
              project.set('soldBy', seller);
            } else {
              return null;
            }
          }
        } else {
          project.set('soldAt', null);
          project.set('soldBy', null);
          project.set('saleManagedBy', null);
          project.set('sellingPrice', null);
        }

        let savedComponents: Parse.Object[] = [];
        if (object.components && object.components.length > 0) {
          const projectComponentRepository = new ProjectComponentRepository();
          for (let i = 0; i < object.components.length; i++) {
            if (object.components[i].objectId && object.components[i].objectId !== '') {
              const updatedComponent = await projectComponentRepository.updateByObjectId(object.components[i].objectId ?? '', object.components[i]);
            } else {
              const savedComponent = await projectComponentRepository.create(object.components[i], project.id);
              if (savedComponent) {
                savedComponents.push(savedComponent);
              }
            }
          }

          const componentsRelation = project.relation('components');
          componentsRelation.add(savedComponents);
        }

        if (object.attachments && object.attachments.length > 0) {
          const fileRepository = new FileRepository();
          let savedFiles: any[] = [];
          const attachmentFiles = object.attachments as File[];
          await Promise.all(
            attachmentFiles.map(async (file) => {
              const fileModel: FileModel = {
                file: file,
              };
              const savedFile = await fileRepository.create(fileModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            })
          );
          const attachmentsRelation = project.relation('attachments');
          attachmentsRelation.add(savedFiles);
        }

        project.set('updatedAt', object.updatedAt);

        if (object.customFields) {
          project.set('customFields', object.customFields);
        }

        const currentUser = userRepository.getCurrentUser();
        if (currentUser) {
          project.set('createdBy', currentUser);
          project.set('updatedBy', currentUser);
        } else {
          project.set('createdBy', null);
        }
        // project.set('updatedBy', null);
      }

      if (object.coverPicture && isFile(object.coverPicture)) {
        // ! delete prev images
        const galleryRepository = new GalleryRepository();
        const galleryModel: GalleryModel = {
          file: object.coverPicture,
        };
        const savedFile = await galleryRepository.createAndReturn(galleryModel);
        if (savedFile) {
          if (project.get('coverPicture')) {
            await galleryRepository.destroyImage(project.get('coverPicture').id);
          }
          project.set('coverPicture', savedFile);
        }
      }

      const imagesFiles = object.pictures as Picture[];
      if (isArray(imagesFiles) && imagesFiles.length > 0) {
        let savedFiles: any[] = [];

        // ! delete prev images
        const picturesRelation = project.relation('pictures');
        const getPrevPictures = await picturesRelation.query().find({ useMasterKey: true });
        if (getPrevPictures.length > 0) {
          await Promise.all(
            getPrevPictures.map(async (picture: any) => {
              const getPicture = picture.toJSON();

              const isPictureExist = imagesFiles.find((picture: any) => picture.id === getPicture.objectId);
              if (!isPictureExist) {
                await galleryRepository.destroyImage(getPicture.objectId);
              }
            })
          );
        }

        await Promise.all(
          imagesFiles.map(async (file) => {
            if (isFile(file)) {
              const galleryModel: GalleryModel = {
                file: file,
              };
              const savedFile = await galleryRepository.createAndReturn(galleryModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            }
          })
        );
        const galleriesRelation = project.relation('pictures');
        galleriesRelation.add(savedFiles);
      }

      await project.save(null, { useMasterKey: true });
      return Toast(`Project updated successfully.`, 'success');
    } catch (error) {
      return Toast(`Project could not be updated. Error: ${error}`, 'error');
    }
  }

  public async delete(id: string) {
    try {
      const Project = Parse.Object.extend(this.className);
      const query = new Parse.Query(Project);

      const project = await query.get(id, { useMasterKey: true });

      if (project) {
        // if (project.get('coverPicture')) {
        //   await project.get('coverPicture').destroy({ useMasterKey: true });
        // }

        // const attachmentsRelation = project.relation('attachments');
        // const attachments = await attachmentsRelation.query().find({ useMasterKey: true });

        // for (let i = 0; i < attachments.length; i++) {
        //   await attachments[i].destroy({ useMasterKey: true });
        // }

        // const picturesRelation = project.relation('pictures');
        // const pictures = await picturesRelation.query().find({ useMasterKey: true });

        // for (let i = 0; i < pictures.length; i++) {
        //   await pictures[i].destroy({ useMasterKey: true });
        // }

        await project.destroy({ useMasterKey: true });
        Toast(`Project deleted successfully.`, 'success');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error deleting project:', error);
      return false;
    }
  }

  public async updateMultipleProjects(ProjectIds: string[], status: string | null, sellingPrice: number, soldAt: string, soldBy: string) {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).containedIn('objectId', ProjectIds);
    const userRepository = new UserRepository();
    try {
      const Projects = await query.find({ useMasterKey: true });

      var success = true;
      for (const Project of Projects) {
        if (status) {
          Project.set('status', status);
          if (status === 'Sold') {
            Project.set('soldAt', new Date(soldAt));
            Project.set('price', sellingPrice);
            Project.set('sellingPrice', sellingPrice);
            if (soldBy) {
              const seller = await userRepository.getObjectById(soldBy);
              if (seller) {
                if (seller.get('role') === UserRole.executive) {
                  const salesExecutiveRepository = new SalesExecutiveRepository();
                  const salesExecutive = await salesExecutiveRepository.getObjectByUserId(seller.id);
                  if (salesExecutive) {
                    Project.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                  }
                } else if (seller.get('role') === UserRole.manager) {
                  const managerRepository = new ManagerRepository();
                  const manager = await managerRepository.getObjectByUserId(seller.id);
                  if (manager) {
                    Project.set('saleManagedBy', manager.get('user'));
                  }
                }
                Project.set('soldBy', seller);
              } else {
                return null;
              }
            }
          } else {
            Project.set('soldAt', null);
            Project.set('soldBy', null);
            Project.set('saleManagedBy', null);
            Project.set('sellingPrice', null);
          }
        }
        // if (price && price !== 0) {
        //   Project.set('price', price);
        // }

        await Project.save(null, { useMasterKey: true }).then(
          (_: any) => {},
          (error: Error) => {
            success = false;
          }
        );
      }
      if (success) {
        Toast(`Projects updated successfully.`, 'success');
        return true;
      } else {
        Toast(`Projects could not be updated successfully.`, 'error');
        return false;
      }
    } catch (error) {
      console.error('Error updating projects:', error);
      return false;
    }
  }

  public async deleteMultipleProject(ProjectIds: string[]): Promise<boolean | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).containedIn('objectId', ProjectIds);

    try {
      const Projects = await query.find({ useMasterKey: true });

      return new Promise((resolve, _) => {
        Parse.Object.destroyAll(Projects, { useMasterKey: true }).then(
          (_) => {
            Toast(`Project deleted successfully.`, 'success');
            resolve(true);
          },
          (error: Error) => {
            Toast(`Project could not be deleted successfully. The following error occurred: ${error.message}`, 'error');
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.error('Error deleting Project:', error);
      return false;
    }
  }

  public async countByOrganization(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('organization', organizationId);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async countSoldByOrganization(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('organization', organizationId).equalTo('status', 'Sold');

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSales(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('organization', organizationId).limit(extendedDataLimit);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects) {
              let count = 0;
              for (let i = 0; i < projects.length; i++) {
                count += projects[i].get('sellingPrice') ?? 0;
              }
              resolve(count);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForUser(userId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('soldBy', userId).limit(extendedDataLimit);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects) {
              let count = 0;
              for (let i = 0; i < projects.length; i++) {
                count += projects[i].get('sellingPrice') ?? 0;
              }
              resolve(count);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getSalesCountForUser(userId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('soldBy', userId).limit(extendedDataLimit);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getSalesCountForUserByYear(userId: string, year: number): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('soldBy', userId)
      .greaterThanOrEqualTo('soldAt', yearStartDate)
      .lessThanOrEqualTo('soldAt', yearEndDate)
      .limit(extendedDataLimit);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getSalesCountForOrganizationByYear(organizationId: string, year: number): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', yearStartDate)
      .lessThanOrEqualTo('soldAt', yearEndDate)
      .limit(extendedDataLimit);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForUserByYearFormatted(userId: string, year: number): Promise<any | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('soldBy', userId)
      .greaterThanOrEqualTo('soldAt', yearStartDate)
      .lessThanOrEqualTo('soldAt', yearEndDate)
      .limit(extendedDataLimit);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects.length > 0) {
              let quarterlyData = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };

              for (let i = 0; i < projects.length; i++) {
                const quarter = getYearQuarter(projects[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += projects[i].get('sellingPrice') ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              let quarterlyData = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
              resolve(quarterlyData);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForOrganizationByYearFormatted(organizationId: string, year: number, projectId: string): Promise<any | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', yearStartDate)
      .lessThanOrEqualTo('soldAt', yearEndDate)
      .limit(extendedDataLimit);

    if (projectId !== '') {
      query.equalTo('objectId', projectId);
    }

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects.length > 0) {
              let quarterlyData = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };

              for (let i = 0; i < projects.length; i++) {
                const quarter = getYearQuarter(projects[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += projects[i].get('sellingPrice') ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              let quarterlyData = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
              resolve(quarterlyData);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForOrganizationByRange(
    organizationId: string,
    startYear: number,
    endYear: number,
    projectId: string,
    managerId: string
  ): Promise<any | null> {
    const Project = Parse.Object.extend(this.className);
    let startYearStartDate = new Date(startYear, 0, 1);
    let endYearEndDate = new Date(endYear, 11, 31);
    const query = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', startYearStartDate)
      .lessThanOrEqualTo('soldAt', endYearEndDate)
      .limit(extendedDataLimit);

    if (projectId !== '') {
      query.equalTo('objectId', projectId);
    }

    if (managerId !== '') {
      query.equalTo('saleManagedBy', managerId);
    }

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects.length > 0) {
              let quarterlyData = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };

              for (let i = 0; i < projects.length; i++) {
                const quarter = getYearQuarter(projects[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += projects[i].get('sellingPrice') ?? 0;
                }
                resolve(quarterlyData);
              }
            } else {
              let quarterlyData = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
              resolve(quarterlyData);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getAllAttachments(id: string): Promise<Parse.Object[] | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);
    try {
      const project = await query.get(id, { useMasterKey: true });
      if (project) {
        const attachmentsQuery = project.relation('attachments').query().include('file').descending('createdAt');
        return new Promise((resolve, _) => {
          attachmentsQuery.find({ useMasterKey: true }).then(
            (attachments) => {
              if (attachments) {
                resolve(attachments);
              } else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async getAllComponents(id: string): Promise<ProjectComponentModel[] | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);
    try {
      const project = await query.get(id, { useMasterKey: true });
      if (project) {
        const componentsQuery = project.relation('components').query().include('coverPicture', 'lockedFor', 'project').descending('createdAt');
        return new Promise((resolve, _) => {
          componentsQuery.find({ useMasterKey: true }).then(
            (components: Parse.Object[]) => {
              if (components) {
                let result: ProjectComponentModel[] = [];

                for (let i = 0; i < components.length; i++) {
                  let component: ProjectComponentModel = {
                    objectId: components[i].id,
                    area: components[i].get('area') ?? '',
                    areaUnit: components[i].get('areaUnit'),
                    contactName: components[i].get('contactName'),
                    contactPhone: components[i].get('contactPhone'),
                    contactSecondaryPhone: components[i].get('contactSecondaryPhone'),
                    coverPicture: components[i].get('coverPicture')
                      ? { file: null, preview: components[i].get('coverPicture').get('file')._url }
                      : { file: null, preview: '' },
                    description: components[i].get('description'),
                    name: components[i].get('name'),
                    price: components[i].get('price'),
                    purpose: components[i].get('purpose'),
                    sellingPrice: components[i].get('sellingPrice'),
                    soldAt: components[i].get('soldAt'),
                    soldBy: components[i].get('soldBy') ? components[i].get('soldBy').id : null,
                    status: components[i].get('status'),
                    customFields: components[i].get('customFields'),
                    useDownPayment: components[i].get('useDownPayment'),
                    downPayment: components[i].get('downPayment'),
                    lockedFor: components[i].get('lockedFor'),
                    lockedUntil: components[i].get('lockedUntil'),
                    project: components[i].get('project'),
                  };
                  result.push(component);
                }
                resolve(result);
              } else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async getTopPerformerForCurrentQuarter(organizationId: string): Promise<any | null> {
    const Project = Parse.Object.extend(this.className);
    const { start, end } = getCurrentQuarterDates();
    const query = new Parse.Query(Project)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', start)
      .lessThanOrEqualTo('soldAt', end)
      .limit(extendedDataLimit)
      .include('soldBy');

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects.length > 0) {
              let usersData: { user: Parse.Object; total: number }[] = [];

              for (let i = 0; i < projects.length; i++) {
                const existingItemIndex = usersData.findIndex((item) => item.user.id === projects[i].get('soldBy').id);
                if (existingItemIndex !== -1) {
                  usersData[existingItemIndex].total += projects[i].get('sellingPrice') ?? 0;
                } else {
                  usersData.push({ user: projects[i].get('soldBy'), total: projects[i].get('sellingPrice') ?? 0 });
                }
              }

              let highestSellingPrice = 0;
              let highestUserIdx = -1;

              for (let i = 0; i < usersData.length; i++) {
                if (usersData[i].total > highestSellingPrice) {
                  highestSellingPrice = usersData[i].total;
                  highestUserIdx = i;
                }
              }

              if (highestUserIdx === -1) {
                resolve(null);
              } else {
                resolve(usersData[highestUserIdx]);
              }
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async unlock(id: string): Promise<Parse.Object | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);
    try {
      const project = await query.get(id, { useMasterKey: true });
      if (project) {
        project.set('lockedUntil', null);
        project.set('lockedFor', null);
        return new Promise((resolve, _) => {
          project.save(null, { useMasterKey: true }).then(
            (savedProject) => {
              resolve(savedProject);
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default ProjectRepository;
