import { Box, Divider, Modal, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { BsCameraVideo, BsEye, BsEyeFill, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import moment from 'moment';
import Loader from '../atoms/loader';
import { globalColors } from '../../utils/constants/color';
import { MdDelete } from 'react-icons/md';
import NotificationRepository from '../../utils/api/repositories/notificationRepository';

interface ModalNotificationsProps {
  open: boolean;
  onClose: () => void;
  mode: string;
  color: string;
}

interface NotificationTileProps {
  notification: Parse.Object;
  color: string;
  mode: string;
  handleDelete: (notification: Parse.Object, idx: number) => Promise<void>;
  idx: number;
  handleRead: (notification: Parse.Object, idx: number) => Promise<void>;
}

interface Notification {
  notification: Parse.Object;
  read: boolean;
}

const NotificationTile = ({ notification, color, mode, handleDelete, idx, handleRead }: NotificationTileProps) => {
  const [read, setRead] = useState(notification.get('read') ?? true);

  return (
    <>
      <Box
        sx={{
          paddingTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} width={'100%'} gap={'10px'}>
          <Typography
            fontSize={'14px'}
            color={read == false ? color || globalColors.blue : mode === 'dark' ? globalColors.white : globalColors.black}
            display={'flex'}
          >
            {notification.get('title')}
          </Typography>
          <Box display={'flex'} gap={'5px'} justifyContent={'end'}>
            {read === false && (
              <BsEye
                color={color}
                size={'1rem'}
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  setRead(true);
                  await handleRead(notification, idx);
                }}
              />
            )}
            <MdDelete
              color={color}
              size={'1rem'}
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await handleDelete(notification, idx);
              }}
            />
          </Box>
        </Box>
        <Typography fontSize={'12px'} color={globalColors.gray}>
          {notification.get('body')}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingRight: '10px',
          paddingBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'start',
          paddingTop: '5px',
        }}
      >
        <Typography fontSize={'12px'} color={mode === 'dark' ? globalColors.white : globalColors.black} whiteSpace={'nowrap'}>
          {notification.get('sender') ? `${notification.get('sender').get('firstName')} ${notification.get('sender').get('lastName')}` : ''}
        </Typography>
        <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
          {moment(notification.get('createdAt')).fromNow()}
        </Typography>
      </Box>
      <Divider sx={{ width: '70%', backgroundColor: globalColors.gray, color: globalColors.gray, margin: 'auto' }} />
    </>
  );
};

const ModalNotifications = ({ mode, color, open, onClose }: ModalNotificationsProps) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsError, setNotificationsError] = useState('');
  const [notificationsLoading, setNotificationsLoading] = useState(true);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    maxWidth: '525px',
    height: '90vh',
    overflow: 'scroll',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    // border: '2px solid #000',
    boxShadow: 5,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  const handleReadNotification = async (notification: Parse.Object, idx: number) => {
    let temp = [...notifications];
    temp[idx].read = true;
    setNotifications(temp);
    const notificationRepository = new NotificationRepository();
    await notificationRepository.markAsRead(notification);
  };

  const handleDeleteNotification = async (notification: Parse.Object, idx: number) => {
    let temp = [...notifications];
    temp.splice(idx, 1);
    setNotifications(temp);
    const notificationRepository = new NotificationRepository();
    await notificationRepository.delete(notification);
  };

  const fetchNotifications = async () => {
    setNotificationsLoading(true);
    const notificationRepository = new NotificationRepository();
    const allNotifications = await notificationRepository.getAll();
    if (allNotifications) {
      setNotificationsError('');
      let temp: Notification[] = [];
      for (let i = 0; i < allNotifications.length; i++) {
        temp.push({ notification: allNotifications[i], read: allNotifications[i].get('read') });
      }
      setNotifications(temp);
    } else {
      setNotificationsError('Notifications could not be fetched successfully. Please try again.');
    }
    setNotificationsLoading(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Modal open={open} onClose={onClose} className="content">
      <Box sx={style}>
        <Box className="content" height={'100%'}>
          <Box display={'flex'} flexDirection={'column'} padding={'10px'} width={'100%'} height={'100%'}>
            {notificationsError === '' ? (
              notificationsLoading ? (
                <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={'10vh'}>
                  <Loader />
                </Box>
              ) : notifications.length > 0 ? (
                notifications.map((notification, idx) => {
                  return (
                    <NotificationTile
                      notification={notification.notification}
                      color={color}
                      mode={mode}
                      handleDelete={async () => {
                        handleDeleteNotification(notification.notification, idx);
                      }}
                      idx={idx}
                      handleRead={async () => {
                        handleReadNotification(notification.notification, idx);
                      }}
                    />
                  );
                })
              ) : (
                <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
                  <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>No notifications yet.</Typography>
                </Box>
              )
            ) : (
              <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={'10vh'}>
                <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{notificationsError}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalNotifications;
