import { Button, styled } from '@mui/material';
import React from 'react';
import { globalColors } from '../../utils/constants/color';

interface ButtonTextProps {
  onClick?: () => void;
  className?: string;
  children: string;
}
const ButtonText = ({ children, onClick, className }: ButtonTextProps) => {
  return (
    <ButtonTextStyle>
      <Button onClick={onClick} className={className}>
        <span>{children}</span>
      </Button>
    </ButtonTextStyle>
  );
};

const ButtonTextStyle = styled('div')(() => ({
  color: globalColors.mainBlue,
  fontWeight: 500,
  padding: '0',
  '& button': {
    textTransform: 'none',
    display: 'block',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& span': {
    textDecoration: 'underline',
  },
}));

export default ButtonText;
