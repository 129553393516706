import { useEffect, useState } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Grid, Typography, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectThemeMode } from '../../../store/selector';
import { globalColors } from '../../../utils/constants/color';
import NotFoundLottie from '../../atoms/notFound';
import ContentRow from './contentRow';


// const data = [
//   { name: 'John', objectId: '1', year_2019: 5000, year_2020: 6000, year_2021: 7000, year_2022: 8000, year_2023: 9000, Profile: 'Manager' },
//   { name: 'Alice', objectId: '2', year_2019: 3000, year_2020: 4000, year_2021: 5000, year_2022: 6000, year_2023: 7000, Profile: 'Engineer' },
//   { name: 'Bob', objectId: '3', year_2019: 8000, year_2020: 9000, year_2021: 10000, year_2022: 11000, year_2023: 12000, Profile: 'Analyst' },
// ];

const headerContent = ['Payment ID', 'Date', 'Method', 'Amount', 'Difference'];

interface TableProps {
  data: Parse.Object[],
}


const ContentTable = ({ data }: TableProps) => {
  const mode = useSelector(selectThemeMode);

  return (
    <Box
      marginTop={'3%'}
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={'30px'}
      paddingRight={'30px'}
      paddingTop={'30px'}
      height={'100%'}
    >
      <>
        {data.length > 0 ? (
          <TableContainer sx={{ paddingBottom: '30px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerContent.map((val) => (
                    <TableCell sx={{ color: globalColors.gray }}>{val}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <ContentRow data={item} key={item?.id} id={item?.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NotFoundLottie
            showButton={false}
            text="Looks like you have no salary slips yet."
            buttonText=""
            navigateTo=""
          />
        )}

      </>
    </Box>
  );
};

export default ContentTable;
