import { Scheduler } from '@aldabil/react-scheduler';
import { styled } from '@mui/material';
import useCalendarView from '../../../hooks/calendar/useCalendarView';
import { CalendarView } from '../../../store/calendar';
import NavigationCalendar from '../../molecules/navigationCalendar';
import EditorEvent from './editorEvent';
// import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../utils/constants/color';
import { selectThemeMode } from '../../../store/selector';
import { DayProps } from '@aldabil/react-scheduler/views/Day';
import { WeekProps } from '@aldabil/react-scheduler/views/Week';
import { MonthProps } from '@aldabil/react-scheduler/views/Month';

interface CaledarViewProps {
  hasNavigation?: boolean;
  month: string;
  view: CalendarView;
}

interface CaledarViewStyleProps {
  mode: string;
}

const CaledarView = ({ hasNavigation, month = 'OCT, 2023', view }: CaledarViewProps) => {
  const { calendarRef, fetchActivities, deleteActivity } = useCalendarView();

  const mode = useSelector(selectThemeMode);

  const dayProps: DayProps = {
    startHour: 0, 
    endHour: 24, 
    step: 60,
    navigation: true
  }
  const weekProps: WeekProps = {
    weekDays: [0, 1, 2, 3, 4, 5, 6], 
    weekStartOn: 1, 
    startHour: 0, 
    endHour: 24,
    step: 60,
    navigation: true,
    disableGoToDay: false
  }

  const monthProps: MonthProps = {
    weekDays: [0, 1, 2, 3, 4, 5, 6], 
    weekStartOn: 1, 
    startHour: 0, 
    endHour: 24,
    navigation: true,
    disableGoToDay: false
  }

  return (
    <CalendarViewStyle mode={mode}>
      <div className="head">
        <h1>{month}</h1>
        {hasNavigation && <NavigationCalendar mode={mode} />}
      </div>
      <div className="calendar">
        <Scheduler
          day={dayProps}
          week={weekProps}
          month={monthProps}
          view="day"
          ref={calendarRef}
          customEditor={(scheduler) => <EditorEvent scheduler={scheduler} />}
          getRemoteEvents={fetchActivities}
          onDelete={deleteActivity}
        />
      </div>
    </CalendarViewStyle>
  );
};

const CalendarViewStyle = styled('div')<CaledarViewStyleProps>(({ theme, mode }) => ({
  '& .head': {
    display: 'flex !important',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    '& h1': {
      margin: '0',
      color: mode === 'dark' ? globalColors?.white : globalColors.black,
      fontSize: '24px',
    },
  },

  '& .calendar': {
    marginTop: '24px',

    '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1419abu-MuiPaper-root': {
      background: mode === 'dark' ? '#303030' : '#d7d7d7',
      color: mode === 'dark' ? '#939393' : '#262626',
    },

    // Day
    '& .rs__cell .rs__multi_day button h6.MuiTypography-subtitle2': {
      whiteSpace: 'break-spaces',
    },

    '& .css-ncbr6b p, .rs__cell.rs__header, .rs__time ': {
      background: mode === 'dark' ? '#303030' : '#d7d7d7',
      color: mode === 'dark' ? '#939393' : '#262626',
    },

    '& .css-12aktbw >  div': {
      borderWidth: '0px, 1px, 1px, 0px',
      borderStyle: 'solid',
      borderColor: '#303030',
    },

    '& button': {
      // background: 'pink',
    },

    '& .rs__cell': {
      background: mode === 'dark' ? '#303030' : ' #ededed',
      borderWidth: '0px, 1px, 1px, 0px',
      borderStyle: 'solid',
      borderColor: mode === 'dark' ? '#545454' : '#a8a8a8',
    },
  },
}));

export default CaledarView;
