import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectAddNew, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import SendEmail from '../../components/organisms/email/sendEmail';

const SendNewEmail = () => {
  //   const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const showArrow = useSelector(selectAddNew);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Email / Send'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} padding={3} height={'100%'}>
            <SendEmail />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SendNewEmail;
