import { useEffect, useState } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Heading from '../../atoms/heading';
import { useDispatch, useSelector } from 'react-redux';
import { globalColors } from '../../../utils/constants/color';
import { selectAddNew, selectColor, selectThemeMode, sortEmail } from '../../../store/selector';
import LeadsFlter from '../dashboard/leads/filter';
import SortTable from '../email/sort';
import { Button } from '../../atoms';
import ContentRow from './contentRow';
import SocialPostRepository from '../../../utils/api/repositories/socialPostRepository';
import { setAddNew } from '../../../store/routes';
import NotFoundLottie from '../../atoms/notFound';
import moment from 'moment';
import Filter from './filter';
import ModalDeleteConfirm from '../leads/modalDeleteConfirm';
import UserRepository from '../../../utils/api/repositories/userRepository';

const headerContent = ['Recent Content', 'Platforms', 'Reach', 'Likes & Interactions', 'Action'];

interface ContentTableProps {
  setAddPost: (value: boolean) => void;
  setUpdatePost: any;
}

const ContentTable = ({ setAddPost, setUpdatePost }: ContentTableProps) => {
  const [posts, setPosts] = useState<any[]>([]);
  const [selectedName, setSelectedName] = useState<string>('Status');
  const [openDeletePostModal, setOpenDeletePostModal] = useState<boolean>(false);
  const [selectedPostId, setSelectedPostId] = useState<any>('');
  const mode = useSelector(selectThemeMode);
  const showArrow = useSelector(selectAddNew);
  const sortData = useSelector(sortEmail);
  const dispatch = useDispatch();
  const color = useSelector(selectColor);

  // const filterBySelectedName = (data: any, selectedName: any) => {
  //   return data.filter((item: any) => item[selectedName] === 1);
  // };

  const sortByDate = (data: any, sortOrder: any) => {
    // Clone the array to avoid modifying the original data
    const sortedData = [...data];

    sortedData.sort((a, b) => {
      const dateA = new Date(a.createdAt) as any;
      const dateB = new Date(b.createdAt) as any;

      // Compare dates based on the specified sort order
      if (sortOrder === 'Old') {
        return dateA - dateB; // Oldest date first
      } else {
        return dateB - dateA; // Latest date first
      }
    });

    return sortedData;
  };

  const fetchSortPosts = async () => {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      const objectId = currentUser?.id ?? '';
      const socialPostRepository = new SocialPostRepository();
      const allPosts = await socialPostRepository.getAllUserPosts(objectId);

      // Update the state with the fetched posts
      const sortedApiData = sortByDate(allPosts, sortData);
      setPosts(sortedApiData);
    } catch (error: any) {
      console.error('Error fetching posts:', error.message);
    }
  };

  const fetchFilterPosts = async () => {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      const objectId = currentUser?.id ?? '';
      const socialPostRepository = new SocialPostRepository();
      const allPosts = await socialPostRepository.getAllUserPosts(objectId, selectedName);

      // Update the state with the fetched posts
      setPosts(allPosts);
    } catch (error: any) {
      console.error('Error fetching posts:', error.message);
    }
  };

  const fetchPosts = async () => {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      const objectId = currentUser?.id ?? '';
      const socialPostRepository = new SocialPostRepository();
      const allPosts = await socialPostRepository.getAllUserPosts(objectId);

      // Update the state with the fetched posts
      setPosts(allPosts);
    } catch (error: any) {
      console.error('Error fetching posts:', error.message);
    }
  };

  const handleDelete = async (id: any) => {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      const objectId = currentUser?.id ?? '';
      const socialPostRepository = new SocialPostRepository();
      await socialPostRepository.deletePost(objectId, id);
      fetchPosts();

      // Update the state with the fetched posts
    } catch (error: any) {
      console.error('Error Delete post:', error.message);
    }
  };

  const handleDeleteConfirmation = () => {
    if (selectedPostId) {
      handleDelete(selectedPostId);
      setOpenDeletePostModal(false);
      setSelectedPostId('');
    }
  };

  useEffect(() => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    const objectId = currentUser?.id ?? '';
    const fetchPosts = async () => {
      try {
        const socialPostRepository = new SocialPostRepository();
        const allPosts = await socialPostRepository.getAllUserPosts(objectId);

        // Update the state with the fetched posts
        setPosts(allPosts);
      } catch (error: any) {
        console.error('Error fetching posts:', error.message);
      }
    };

    // Call the fetchPosts function when the component mounts
    fetchPosts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!showArrow?.addNew) {
      setAddPost(false);
    }
    // eslint-disable-next-line
  }, [showArrow]);

  useEffect(() => {
    fetchSortPosts();
  }, [sortData]);

  useEffect(() => {
    if (selectedName === 'Status') {
      fetchPosts();
    } else {
      fetchFilterPosts();
    }
  }, [selectedName]);

  return (
    <Box
      marginTop={'2rem'}
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      // border={`1px solid ${globalColors?.border.gray}`}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={'30px'}
      paddingRight={'30px'}
      paddingTop={'10px'}
      height={'100%'}
    >
      <Box
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          width: {
            xs: '100%',
            md: 'auto',
          },
          marginTop: {
            xs: '20px',
            md: '0px',
          },
          paddingBottom: {
            xs: '20px',
            md: '55px',
          },
        }}
        // paddingBottom={'55px'}
        paddingTop={'10px'}
        display={'flex'}
        justifyContent={'space-between'}
      >
        {/* Heading */}
        <Heading text={'Content'} />
        <Box
          sx={{
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
            marginTop: {
              xs: '20px',
              md: '0px',
            },
          }}
          display={'flex'}
          gap={'10px'}
          alignItems={'center'}
        >
          {/* Button */}
          <Button
            onClick={() => {
              dispatch(setAddNew({ addNew: true, path: '/socialmedia' }));
              setAddPost(true);
            }}
          >
            Add New Post
          </Button>

          {posts.length == 0 && selectedName !== 'Status' && (
            <Typography
              sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
              onClick={() => {
                setSelectedName('Status');
              }}
            >
              Clear Filter
            </Typography>
          )}
          {/* Filters */}
          {posts.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Filter mode={mode} selectedName={selectedName} setSelectedName={setSelectedName} />
              <SortTable mode={mode} />
            </Box>
          )}
        </Box>
      </Box>
      {posts.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headerContent.map((val) => (
                  <TableCell sx={{ color: globalColors.gray }}>{val}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {posts.map((item) => (
                <ContentRow
                  data={item}
                  key={item?.objectId}
                  id={item?.objectId}
                  name={item?.name}
                  text={item?.text}
                  createdAt={moment(item?.createdAt).fromNow()}
                  // handleDelete={handleDelete}
                  handleDelete={setSelectedPostId}
                  setOpenDeletePostModal={setOpenDeletePostModal}
                  setUpdatePost={setUpdatePost}
                  setAddPost={setAddPost}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NotFoundLottie
          showButton={false}
          text={
            selectedName == 'Status'
              ? 'Looks like you have no posts yet. Press the button above and add a new post right now.'
              : 'No posts match the selected filter. Press the button above and add a new post right now.'
          }
          buttonText=""
          navigateTo=""
        />
      )}
      <ModalDeleteConfirm
        open={openDeletePostModal}
        onClose={() => {
          setOpenDeletePostModal(false);
        }}
        mode={mode}
        color={color}
        onDelete={handleDeleteConfirmation}
        action={{permissionName: 'Social Media', action: 'Delete'}}
      />
    </Box>
  );
};

export default ContentTable;
