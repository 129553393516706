import { useState } from 'react';
import { BsEyeFill } from 'react-icons/bs';
import { FaDumpster, FaDumpsterFire, FaHeart } from 'react-icons/fa';
import { FaDeleteLeft, FaFlag } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { ICPen } from '../../../assets/Icons';
import { useModalViewProducts } from '../../../hooks';
import useModalDeleteProduct from '../../../hooks/useModalDeleteProduct';
// import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProject } from '../../../store/projects';
import { globalColors } from '../../../utils/constants/color';
import { ColorStatusProduct, InventoryItemProps, ProductCategories, ProductItemProps, StatusProduct } from '../../../utils/types/products';
import { ButtonIconCircle, Toast } from '../../atoms';
import { Gallery } from '../../organisms';
import ModalDeleteProduct from '../../organisms/products/modalDeleteProduct';
import ModalViewProduct from '../../organisms/products/modalViewProduct';
import { CardProductItemStyle } from './cardproducttItem.style';
import { Checkbox } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import HotDealsIcon from './hotDealsIcon';
import Image404 from '../../../assets/Images/img-404.jpeg';
import ModalViewProjectComponent from '../../organisms/products/modalViewProjectComponent';
import { formatMoney } from '../../../utils/helpers';
import ModalAddComponent from '../../organisms/product/modalAddComponent';
import ProjectComponentRepository from '../../../utils/api/repositories/projectComponentRepository';
import { useDispatch } from 'react-redux';
import { onInventoryLoading, setDataInventory } from '../../../store/inventory';

const CardInventoryItem = (props: InventoryItemProps) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  // Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => {};
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [openModalEdit, setOpenModalEdit] = useState(false);

  // * Modal View Detail
  const { openModalView, handleOpenModalView, handleCloseModalView, loadingModal } = useModalViewProducts();

  // * Modal Delete
  const { openModalDelete, handleOpenModalDelete, handleDelete, handleCloseModalDelete, productType: productTypeDelete } = useModalDeleteProduct();

  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string | undefined) => {
    if (productId) {
      const checked = event.target.checked;
      props.setRowCheckboxState!((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [productId]: checked,
      }));
    }
  };
  return (
    <CardProductItemStyle className={props.className} mode={props.mode}>
      <div className="cover-wrapper">
        {props?.data?.coverPicture?.preview ? (
          <img onClick={handleOpen} className="cover-image-product" src={props?.data?.coverPicture?.preview} alt="project component name" />
        ) : (
          <img className="cover-image-product" src={Image404} alt="project name" />
        )}
        <div className="btn-action-wrapper">
          <div className="left">
            <ButtonIconCircle
              component={
                <BsEyeFill
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                  onClick={() => (props?.active ? handleOpenModalView(ProductCategories.ProjectComponent, props.data.objectId ?? '') : '')}
                />
                // <FaFlag
                //   size={'1.3rem'}
                //   color={globalColors.blue}
                //   style={{ cursor: 'pointer' }}
                //   onClick={() => (props?.active ? handleOpenModalView(props.category, props.data.id) : '')}
                // />
              }
              alt="flag"
              onClick={props.onFlagClick}
            />
            {props.isCanEdit && (
              <ButtonIconCircle
                component={
                  <MdDelete
                    onClick={() => (props?.active ? handleOpenModalDelete(ProductCategories.ProjectComponent) : '')}
                    size={'1.3rem'}
                    color={color || globalColors.blue}
                    style={{ cursor: 'pointer' }}
                  />
                }
              />
            )}
          </div>
          <div>
            {props.edit && props?.data && props?.data?.objectId && (
              <Checkbox
                size="small"
                sx={{
                  color: color || globalColors.blue,
                  '&.Mui-checked': {
                    color: color || globalColors.secondBlue,
                  },
                }}
                onChange={(event) => {
                  handleRowCheckboxChange(event, props.data.objectId);
                }}
                checked={props.rowCheckboxState![props.data.objectId] || false}
              />
            )}
            {props.isCanEdit && (
              <ButtonIconCircle
                component={<CiEdit size={'1.3rem'} color={color || globalColors.blue} style={{ cursor: 'pointer' }} />}
                alt="pen"
                onClick={() => {
                  setOpenModalEdit(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="description-wrapper"
        onClick={() => (props?.active ? handleOpenModalView(ProductCategories.ProjectComponent, props.data.objectId ?? '') : '')}
        style={{ cursor: 'pointer' }}
      >
        <h3 className={`title-project property`}>
          {/* Name */}
          <span>{props?.data?.name ? props?.data?.name : 'Title'}</span>
          <p className="price" style={{ color: color }}>
            {props.data?.price ? formatMoney(props.data?.price) : '0'}
          </p>
        </h3>

        <p style={{ marginTop: '10px' }} className="detail">
          Project: {props.data.project ? props.data.project.get('name') ?? '-' : '-'}
        </p>

        {/* Square footage */}
        <p style={{ marginTop: '10px' }} className="detail">
          {`Area: ${props.data.area ? props.data.area : '-'} ${props.data.area ? props.data.areaUnit : ''}`}
        </p>

        {/* Interested */}
        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <span className="detail">Interested people</span>
          <Typography
            sx={{ display: 'flex', color: `${globalColors.white}` }}
            width={'10px'}
            fontSize={'12px'}
            paddingLeft={'0.2rem'}
            flexGrow={1}
            textAlign="left"
          >
            {dummyData.length > 0
              ? dummyData.length === 1
                ? dummyData[0]
                : dummyData.map((e: any, i: any) => {
                    return <AvatarContainer sx={{ marginLeft: i === 0 ? '' : '-8px !important' }}>{e.charAt(0).toUpperCase()}</AvatarContainer>;
                  })
              : ''}
          </Typography>
        </div> */}

        {/* Details */}
        <p className={`detail status ${props.data.status === 'Available' ? 'colorGreen' : props.data.status === 'Hold' ? 'colorGray' : 'colorRed'}`}>
          {props?.data?.status ? props?.data?.status : 'Status'}
        </p>
      </div>
      <ModalViewProjectComponent open={openModalView} onClose={handleCloseModalView} loading={loadingModal} data={props.data || null} />
      <ModalDeleteProduct
        open={openModalDelete}
        mode={mode}
        color={color}
        onClose={handleCloseModalDelete}
        type={productTypeDelete || null}
        onDelete={() => handleDelete(ProductCategories.ProjectComponent, props.data.objectId ?? '')}
        action={{ permissionName: 'Projects', action: 'Delete' }}
      />
      <ModalAddComponent
        open={openModalEdit}
        onClose={() => {
          setOpenModalEdit(false);
        }}
        onSubmit={async (component) => {
          try {
            setOpenModalEdit(false);
            dispatch(onInventoryLoading(true));
            const projectComponentRepository = new ProjectComponentRepository();
            const updatedComponent = await projectComponentRepository.updateByObjectId(component.objectId ?? '', component);
  
            if (updatedComponent) {
              Toast('Inventory updated successfully.', 'success');
            } else {
              Toast('Inventory could not be updated successfully. Please try again.', 'error');
            }
            
            let data = await projectComponentRepository.getAll();
            dispatch(setDataInventory(data));
            dispatch(onInventoryLoading(false));
          }
          catch (e) {
            dispatch(onInventoryLoading(false));
          }
        }}
        color={color}
        mode={mode}
        organizationMembers={props.organizationMembers}
        selectedComponent={props.data}
      />
    </CardProductItemStyle>
  );
};

export default CardInventoryItem;
