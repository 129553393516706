import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, Tooltip, Typography, styled } from '@mui/material';
import { globalColors } from '../../../../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../../../../store/selector';
import { setAddNew } from '../../../../../../store/routes';
import { GoArrowUpRight } from 'react-icons/go';
import { formatDate } from '../../../../../../utils/helpers';

interface ContentRowProp {
  id?: string;
  data: Parse.Object;
}

const ContentRow: React.FC<ContentRowProp> = ({ data, id }) => {
  // Menu
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const dispatch = useDispatch();

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  return (
    <TableRow
      sx={{
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        width: '100%',
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        borderRadius: '10px',
        gap: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
      }}
      key={data.id}
    >
      {/* Id */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data?.id || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Date */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {formatDate(data?.createdAt) || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* method */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data.get('method') || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* amount */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data.get('amount') || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* deduction */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data.get('difference') || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const AvatarContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '7px',
}));

const ButtonStyle = styled('button')<any>((props) => ({
  height: '32px',
  all: 'unset',
  cursor: 'pointer',
  background: props.mode === 'dark' ? globalColors.black : globalColors.white,
  color: props.mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '4px 16px',
  borderRadius: '6px',
  transition: 'opacity 0.3s ease',
  border: `1px solid ${globalColors.secondBlue}`,
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  // '@media (max-width: 768px)': {
  //   width: '100%',
  // },
}));

export default ContentRow;
