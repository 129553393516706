import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../../../utils/constants/color';
import { useNavigate, useParams } from 'react-router-dom';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import { Button, Toast } from '../../../../atoms';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../../../store/routes';
import Loader from '../../../../atoms/loader';
import moment from 'moment';
import NotFoundLottie from '../../../../atoms/notFound';
import styled from '@emotion/styled';

interface ModalDetailLeadProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  notes: any[];
  loading: boolean;
}

const ModalViewNotes = ({ open, onClose, mode, color, notes, loading }: ModalDetailLeadProps) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '600px',
    maxHeight: '600px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={style} height={'65vh'} className="content" display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        {loading ? (
          <Loader />
        ) : notes.length > 0 ? (
          <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
            {notes.reverse().map((note: any, idx: number) => (
              <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'0'}
                key={idx}
                padding={'10px 0px'}
                borderBottom={idx < notes.length - 1 ? `1px solid ${globalColors.border.gray}` : 'none'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontSize={'16px'} marginRight={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                    {note['note']}
                  </Typography>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    fontSize={'10px'}
                    gap={'5px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    <Typography fontSize={'12px'}>{note['createdBy']}</Typography>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'} marginTop={'-4px'}>
                  <Typography color={globalColors.gray} fontSize={'12px'}>
                    {moment(new Date(note['createdAt']))?.fromNow()}
                  </Typography>
                </Box>
              </Box>
            ))}
            
          </Box>
        ) : (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <NotFoundLottie 
            text={'Looks like you have not added any notes for this member yet.'}
            showButton={false}
            navigateTo=''
            buttonText=''
            size='small'
          />
          </Box>
        )}
        <Box display={'flex'} justifyContent={'center'}>
          <ButtonStyle type="reset" color={globalColors.red} mode={mode} onClick={onClose}>
            Close
          </ButtonStyle>
        </Box>
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  background:
    color !== 'default' && color !== ''
      ? color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.blackLight
        : globalColors.whiteLight
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '240px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default ModalViewNotes;
