import type { SchedulerHelpers } from '@aldabil/react-scheduler/types';
import styled from '@emotion/styled';
import { Box, Button, InputBase, NativeSelect, alpha } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCalendarActivity } from '../../../hooks';
import { RootState } from '../../../store/reducers';
import { globalColors } from '../../../utils/constants/color';
import Dropdown from '../../atoms/dropdown';
import { Form, Formik, FormikHelpers } from 'formik';
import { FieldInput } from '../../atoms';
import * as Yup from 'yup';
import ActivityRepository from '../../../utils/api/repositories/activityRepository';

interface CustomEditorProps {
  scheduler: SchedulerHelpers;
}

interface ValuesFormSchedule {
  meetingWith: string;
  title: string;
  assignedTo: string;
  lead: string;
  others: string;
  type: string;
  time: string;
}

const typeAppointment = [
  { id: 'inPerson', text: 'In Person', value: 'InPerson' },
  { id: 'online', text: 'Online', value: 'Online' },
];

const EditorEvent = ({ scheduler }: CustomEditorProps) => {
  const [formValues, setFormValues] = useState<ValuesFormSchedule>({
    meetingWith: '',
    title: '',
    assignedTo: '',
    lead: '',
    others: '',
    type: '',
    time: '',
  });
  const stateReducer = useSelector((state: RootState) => state);
  const mode = stateReducer?.UseData?.thememode;
  const color = stateReducer?.UseData?.color;
  const { onAddNewActivity, onEditActivity, optionExecutive: assignTo, optionLead: leads } = useCalendarActivity();
  const event = scheduler.edited;
  const eventType = event ? 'edit' : 'create';
  const dispatch = useDispatch();
  const [meetingWith, setMeetingWith] = useState<'lead' | 'others'>('lead');

  const validationSchema = Yup.object({
    title: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    assignTo: Yup.string().required('Required'),
  });

  const fetchActivity = async () => {
    const activityRepository = new ActivityRepository();
    const activity = await activityRepository.get(scheduler.edited?.event_id?.toString() ?? '');

    if (activity) {
      setFormValues({
        meetingWith: activity.get('lead') ? 'lead' : 'others',
        title: activity.get('title'),
        assignedTo: activity.get('assignedTo').id,
        lead: activity.get('lead') ? activity.get('lead').id : '',
        others: activity.get('others') ?? '',
        type: activity.get('type'),
        time: activity.get('time')
      });

      setMeetingWith(activity.get('lead') ? 'lead' : 'others');
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  return (
    <EditorEventStyle>
      <Formik
        enableReinitialize
        initialValues={{
          meetingWith: formValues.meetingWith,
          title: formValues.title,
          assignTo: formValues.assignedTo,
          lead: formValues.lead,
          others: formValues.others,
          type: formValues.type,
          time: formValues.time,
        }}
        validate={(values) => {
          const errors = {} as any;
          if (meetingWith === 'lead' && !values.lead) {
            const message = 'Required';
            errors['lead'] = message;
          }
          if (meetingWith === 'others' && !values.others) {
            const message = 'Required';
            errors['others'] = message;
          }

          return errors;
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }: FormikHelpers<any>) => {
          try {
            scheduler.loading(true);

            const activityType = values.type;
            let color = '';
            if (activityType === 'Online') {
              color = globalColors.calendar.type.online;
            } else if (activityType === 'In Person') {
              color = globalColors.calendar.type.inPerson;
            }

            let startAppointment = scheduler.state.start.value;
            let endAppointment = scheduler.state.end.value;
            if (values.time) {
              const customDateTime = startAppointment;
              const timeArray = values.time.split(':');
              customDateTime.setHours(+timeArray[0]);
              customDateTime.setMinutes(+timeArray[1]);

              startAppointment = customDateTime;

              const endDate = new Date(customDateTime);
              endDate.setTime(customDateTime.getTime() + 1 * 60 * 60 * 1000);

              endAppointment = endDate;
            }

            const eventCalendar = {
              event_id: event?.event_id || Math.random(),
              title: values.title,
              start: startAppointment,
              end: endAppointment,
              assignedTo: values.assignTo,
              lead: values.lead,
              others: values.others,
              type: activityType,
              color,
            };

            scheduler.onConfirm(eventCalendar, eventType);
            if (eventType === 'create') {
              onAddNewActivity(eventCalendar);
            } else if (eventType === 'edit') {
              if (event) {
                const objectId = event.event_id.toString();
                onEditActivity(objectId, eventCalendar);
              }
            }
            scheduler.close();
          } finally {
            scheduler.loading(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form>
            <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
              <FieldInput
                label="Appointment Title"
                mode={mode}
                id="title"
                name="title"
                placeholder="Enter title"
                type="text"
                error={errors.title && touched.title ? errors.title : false}
              />
              <Dropdown
                label="Assigned To "
                mode={mode}
                id="assignTo"
                name="assignTo"
                valuesObject={assignTo}
                error={errors.assignTo && touched.assignTo ? errors.assignTo : false}
                defaultValue={values.assignTo || '1'}
              />
              <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={'2px'} width={'100%'} position={'relative'}>
                {meetingWith === 'lead' && (
                  <Dropdown
                    label={meetingWith === 'lead' ? 'Lead' : 'Other Person'}
                    mode={mode}
                    id="lead"
                    name="lead"
                    valuesObject={leads}
                    defaultValue="1"
                    error={errors.lead && touched.lead ? errors.lead : false}
                  />
                )}

                {meetingWith === 'others' && (
                  <FieldInput
                    label="Other Person"
                    mode={mode}
                    id="others"
                    name="others"
                    placeholder="Enter name"
                    type="text"
                    error={errors.others && touched.others ? errors.others : false}
                  />
                )}
                <Box display={'flex'} gap={'10px'} position={'absolute'} right={'0'}>
                  <Box
                    onClick={() => setMeetingWith('lead')}
                    // border={`2px solid ${isLead ? color || globalColors.border.gray : globalColors.border.gray}`}
                    border={`2px solid ${meetingWith === 'lead' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                    borderRadius={'10px'}
                    bgcolor={
                      mode === 'dark'
                        ? meetingWith === 'lead'
                          ? color || globalColors.blue
                          : globalColors.blackLight
                        : meetingWith === 'lead'
                        ? color || globalColors.blue
                        : globalColors.lightgray
                    }
                    color={
                      mode === 'dark'
                        ? meetingWith === 'lead'
                          ? globalColors.white
                          : color || globalColors.white
                        : meetingWith === 'lead'
                        ? globalColors.white
                        : color || globalColors.black
                    }
                    paddingLeft={'10px'}
                    sx={{ cursor: 'pointer' }}
                    paddingRight={'10px'}
                    paddingTop={'2px'}
                    paddingBottom={'2px'}
                  >
                    Lead
                  </Box>
                  <Box
                    onClick={() => setMeetingWith('others')}
                    borderRadius={'10px'}
                    border={`2px solid ${meetingWith === 'others' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                    bgcolor={
                      mode === 'dark'
                        ? meetingWith === 'others'
                          ? color || globalColors.blue
                          : globalColors.blackLight
                        : meetingWith === 'others'
                        ? color || globalColors.blue
                        : globalColors.lightgray
                    }
                    color={
                      mode === 'dark'
                        ? meetingWith === 'others'
                          ? globalColors.white
                          : color || globalColors.white
                        : meetingWith === 'others'
                        ? globalColors.white
                        : color || globalColors.black
                    }
                    paddingLeft={'10px'}
                    sx={{ cursor: 'pointer' }}
                    paddingRight={'10px'}
                    paddingTop={'2px'}
                    paddingBottom={'2px'}
                  >
                    Other
                  </Box>
                </Box>
              </Box>

              <Dropdown
                label="Appointment Type"
                mode={mode}
                id="type"
                name="type"
                values={['Select', 'In Person', 'Online']}
                defaultValue={values.type || 'Select'}
                error={errors.type && touched.type ? errors.type : false}
              />
              {/* <FieldInput
                label="Add a follow-up time"
                mode={mode}
                id="time"
                name="time"
                defaultValue={values.time || 'Select'}
                placeholder="Add a follow-up time"
                type="time"
                optional={false}
              /> */}
              <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
                <Button
                  sx={{
                    border: `1px solid ${globalColors.red}`,
                    color: mode === 'dark' ? globalColors.white : globalColors.black,
                    width: '100%',
                    borderRadius: '12px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '&:hover': {
                      backgroundColor: globalColors.red,
                    },
                  }}
                  onClick={scheduler.close}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    border: `1px solid ${color !== 'default' && color !== '' ? color : globalColors.blue}`,
                    color: mode === 'dark' ? globalColors.white : globalColors.black,
                    width: '100%',
                    borderRadius: '12px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '&:hover': {
                      backgroundColor: color !== 'default' && color !== '' ? color : globalColors.black,
                    },
                  }}
                  onClick={() => handleSubmit()}
                >
                  Done
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </EditorEventStyle>
  );
};

const EditorEventStyle = styled('div')(({ theme }) => ({
  background: '#171717',
  color: 'white',
  minWidth: '500px',
  padding: '1.5rem',
  '& .form-wrapper': {
    padding: '24px',
    minWidth: '400px',
    '& .title': {
      fontsize: '20px',
      fontWeight: 500,
      margin: 0,
    },
    '& .label': {
      color: 'white',
      fontsize: '1rem',
    },

    '& .input-group': {
      marginTop: '1rem',
      width: '100%',
    },
    '& .input': {
      color: 'white',
    },
  },
}));

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: '2rem',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F3F6F9',
    border: '1px solid',
    borderColor: '#E0E3E7',
    fontSize: 16,
    padding: '10px 12px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha('#F3F6F9', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#F3F6F9',
    },
  },
}));

const Select = styled(NativeSelect)(({ theme }) => ({
  'label + &': {
    marginTop: '2rem',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#F3F6F9',
    border: '1px solid',
    borderColor: '#E0E3E7',
    fontSize: 16,
    padding: '10px 12px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha('#F3F6F9', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#F3F6F9',
      backgroundColor: '#F3F6F9',
    },
  },
}));

export default EditorEvent;
