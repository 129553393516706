import { Box, Typography, styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { ICAddImage, ICDelete } from '../../../assets/Icons';
import { globalColors } from '../../../utils/constants/color';
import { validateUploadImage } from '../../../utils/helpers';
import { ButtonIconCircle } from '../../atoms';
import { IFormDetailProject, IImages } from '../../../store/projects';
import ProjectComponentModel from '../../../utils/api/models/projectComponentModel';
import { MdAdd } from 'react-icons/md';
import Image404 from '../../../assets/Images/img-404.jpeg';
import ProjectComponentRepository from '../../../utils/api/repositories/projectComponentRepository';

interface ComponentAddProps {
  className?: string;
  components: ProjectComponentModel[] | undefined;
  onAddComponent: (data: ProjectComponentModel) => void;
  onClickNew: () => void;
  onClickAdded: (idx: number) => void;
  onRemoveComponent: (idx: number) => void;
  color: string;
  mode: string;
  viewOnly?: boolean;
}

const ComponentAdd = ({
  className,
  components,
  onAddComponent,
  onClickNew,
  onClickAdded,
  onRemoveComponent,
  color,
  mode,
  viewOnly = false,
}: ComponentAddProps) => {
  const handleInputImage = async (e: ChangeEvent<HTMLInputElement>) => {};

  return (
    <UploadImageStyle className={className}>
      {components &&
        components.length > 0 &&
        components.map((component, index) => (
          <Box>
            <div className="photo" style={{ cursor: 'pointer' }}>
              {component.coverPicture?.preview !== '' ? (
                <img
                  key={component.objectId}
                  className="image"
                  src={component.coverPicture?.preview}
                  alt={component.coverPicture?.preview}
                  onClick={() => {
                    onClickAdded(index);
                  }}
                />
              ) : (
                <img
                  className="image"
                  src={Image404}
                  alt="project name"
                  onClick={() => {
                    onClickAdded(index);
                  }}
                />
              )}
              {!viewOnly && (
                <div className="btn-delete">
                  <ButtonIconCircle
                    type="button"
                    src={ICDelete}
                    onClick={async () => {
                      if (onRemoveComponent) {
                        if (component.objectId) {
                          const projectComponentRepository = new ProjectComponentRepository();
                          await projectComponentRepository.delete(component.objectId);
                        }
                        onRemoveComponent(index);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            <Typography sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center' }}>
              {component.name && component.name.length > 10 ? `${component.name?.substring(0, 8)}...` : component.name}
            </Typography>
          </Box>
        ))}
      {!viewOnly && (
        <div className="image btn-add" style={{ border: `1px solid ${color || globalColors.blue}`, cursor: 'pointer' }} onClick={onClickNew}>
          <MdAdd size={'1.5rem'} color={color || globalColors.blue} />
        </div>
      )}
    </UploadImageStyle>
  );
};

const UploadImageStyle = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  width: '100%',
  gap: '1rem',

  '& .image': {
    borderRadius: '12px',
    width: '100%',
    maxHeight: '100px',
    minWidth: '98px',
    height: '100%',
    objectFit: 'fitWidth',
  },
  '& .btn-add': {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',

    borderRadius: '12px',
    width: '100%',
    height: '100%',
    minHeight: '100px',
    '& input': {
      cursor: 'pointer',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },

  '& .photo': {
    position: 'relative',
    '& .btn-delete': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      '& img': {
        width: '1rem',
        height: '1rem',
      },

      '&:hover': {
        opacity: '0.7',
      },
    },
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '100%',
      height: '150px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '.photo': {
      width: 'max-content',
    },
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '150px',
      height: '150px',
    },
    '& .btn-add': {
      width: '150px',
      height: '150px',
    },
  },
}));

export default ComponentAdd;
