import { toast } from 'react-toastify';
import { Toast } from '../../components/atoms';

export const validateUploadImage = (files: FileList): File[] => {
  const fileUpload: File[] = [];
  Array.from(files).map((image) => {
    if (image.type === 'image/jpeg' || image.type === 'image/jpg' || image.type === 'image/png') {
      if (image.size > 1048576 * 2) {
        return Toast(`Failed to upload. Max allowed file size is 2 MB.`, 'error');
      }
      else {
        fileUpload.push(image);
      }
    } 
    else {
      return Toast(`Failed to upload. Only images in jpeg, png and jpg format are allowed.`, 'error');
    }
  });

  if (fileUpload.length > 0) {
    toast.dismiss();
  }

  return fileUpload;
};
