import { Box, FormGroup, InputLabel, Modal, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { NameInput } from './element.style';
import { FieldInputTextarea } from '../../../../atoms';
import { globalColors } from '../../../../../utils/constants/color';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';
import './MyDatePicker.css';

interface ModalProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  setDate: any;
  setFromTime: any;
  setToTime: any;
}

const ToTimeModal = ({ open, onClose, mode, color, setDate, setFromTime, setToTime }: ModalProps) => {
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date().setDate(new Date().getDate() + 1)));
  const [selectedFromTime, setSelectedFromTime] = useState<Dayjs | null>(null);
  const [selectedToTime, setSelectedToTime] = useState<Dayjs | null>(null);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '900px',
    maxHeight: '616px',
    bgcolor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    boxShadow: 24,
    p: 4,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  useEffect(() => {
    const formattedDate = dayjs(selectedDate).format('DD / MM / YYYY');
    setDate(formattedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (selectedFromTime) {
      const fromTime = dayjs(selectedFromTime).format('h:mm A');
      setFromTime(fromTime);
    }
    if (selectedToTime) {
      const toTime = dayjs(selectedToTime).format('h:mm A');
      setToTime(toTime);
    }
  }, [selectedFromTime, selectedToTime]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={style} className="content" display={'flex'} flexDirection={'column'} >
        <Box display={'flex'} gap="10px" marginBottom={'20px'} justifyContent={'space-evenly'} alignItems={'space-evenly'} columnGap={'3rem'}>
          <Box
            sx={{
              
              marginTop: '2%',
              backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderRadius: '20px',
            }}
          >
            {' '}
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '20px',
                  fontWeight: 500,
                  lineHeight: '30px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                }}
              >
                From
              </Typography>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'MobileTimePicker', 'DesktopTimePicker', 'StaticTimePicker']}>
                <DemoItem>
                  <MultiSectionDigitalClock
                    sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}
                    defaultValue={dayjs(new Date())}
                    onChange={(newTime: any) => setSelectedFromTime(dayjs(newTime))}
                    minTime={selectedDate.isSame(new Date(), 'date') ? dayjs(new Date()) : null}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              marginTop: '2%',
              backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
              padding: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              borderRadius: '20px',
            }}
          >
            {' '}
            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '20px',
                  fontWeight: 500,
                  lineHeight: '30px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                }}
              >
                To
              </Typography>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['TimePicker', 'MobileTimePicker', 'DesktopTimePicker', 'StaticTimePicker']}>
                <DemoItem>
                  <MultiSectionDigitalClock
                    sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}
                    defaultValue={dayjs(new Date())}
                    onChange={(newTime: any) => setSelectedToTime(dayjs(newTime))}
                    minTime={selectedDate.isSame(new Date(), 'date') ? dayjs(new Date()) : null}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', gap: '15px', alignItems: 'center', justifyContent: 'center', marginTop: '4rem' }}>
          <ButtonStyle onClick={onClose} type="reset" color={globalColors.red} mode={mode}>
            Cancel
          </ButtonStyle>
          <ButtonStyle onClick={onClose} type="submit" color={color} mode={mode}>
            Save
          </ButtonStyle>
        </Box>
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '42px',
  background:
    color !== 'default' && color !== ''
      ? type === 'reset'
        ? mode === 'dark'
          ? globalColors.red
          : globalColors.red
        : color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.red
        : globalColors.red
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '138px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default ToTimeModal;
