import { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { FaFlag } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { ICPen } from '../../../assets/Icons';
import { useModalViewProducts } from '../../../hooks';
import useModalDeleteProduct from '../../../hooks/useModalDeleteProduct';
// import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProject } from '../../../store/projects';
import { globalColors } from '../../../utils/constants/color';
import { ColorStatusProduct, ProductCategories, ProductItemProps } from '../../../utils/types/products';
import { ButtonIconCircle } from '../../atoms';
import { Gallery } from '../../organisms';
import ModalDeleteProduct from '../../organisms/products/modalDeleteProduct';
import ModalViewProduct from '../../organisms/products/modalViewProduct';
import { CardProductItemStyle } from './cardproducttItem.style';
import { Checkbox } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { Box, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { BsEyeFill } from 'react-icons/bs';

const AddProductCard = (props: ProductItemProps) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const dataProject = props.data as IFormDetailProject;

  const isProperty = props.category === ProductCategories.Property;
  // const isProject = props.category === ProductCategories.Project;
  let styleStatusProperty = props.data.status ? ColorStatusProduct[props.data.status] : 'colorGray';

  // Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // * Modal View Detail
  const { openModalView, handleOpenModalView, handleCloseModalView, loadingModal, data, productType } = useModalViewProducts();

  // * Modal Delete
  const { openModalDelete, handleOpenModalDelete, handleDelete, handleCloseModalDelete, productType: productTypeDelete } = useModalDeleteProduct();

  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string | undefined) => {
    if (productId) {
      const checked = event.target.checked;
      props.setRowCheckboxState!((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [productId]: checked,
      }));
    }
  };

  return (
    <CardProductItemStyle className={props.className} mode={props.mode}>
      <div className="cover-wrapper">
        {dataProject?.cover?.preview && (
          <img onClick={handleOpen} className="cover-image-product" src={dataProject?.cover?.preview} alt="project name" />
        )}
        <div className="btn-action-wrapper">
          <div className="left">
            {/* Flag */}
            <ButtonIconCircle
              component={
                <BsEyeFill
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                  onClick={() => (props?.active ? handleOpenModalView(props.category, props.data.id) : '')}
                />
                // <FaFlag
                //   size={'1.3rem'}
                //   color={globalColors.blue}
                //   style={{ cursor: 'pointer' }}
                //   onClick={() => (props?.active ? handleOpenModalView(props.category, props.data.id) : '')}
                // />
              }
              alt="flag"
              onClick={props.onFlagClick}
            />
          </div>
        </div>
        <div className="count-photo-wrapper" onClick={() => (props.active ? handleOpen() : '')}>
          <CameraAltOutlinedIcon sx={{ color: props.mode === 'dark' ? globalColors.white : globalColors.black }} />
          <p style={{ color: props.mode === 'dark' ? globalColors.white : globalColors.black }}>View all photos ({props?.data?.images?.length ?? 0})</p>
        </div>
      </div>
      <div className="description-wrapper">
        <h3 className={`title-project ${isProperty && 'property'}`}>
          {/* Name */}
          <span>{props?.data?.name ? props?.data?.name : 'Title'}</span>
        </h3>

        {/* consist of */}
        <p style={{ marginTop: '10px' }} className="detail">
          Consists of: {dataProject.consistOf ? dataProject.consistOf : '-'} {dataProject.consistOf ? dataProject.consistOfUnit : ''}
        </p>

        {/* type */}
        <p style={{ marginTop: '10px' }} className="detail">
          Type: {dataProject.type ? dataProject.type : '-'}
        </p>

        {/* Square footage */}
        <p style={{ marginTop: '10px' }} className="detail">
          {`Area: ${dataProject.area ? dataProject.area : '-'} ${dataProject.area ? dataProject.areaUnit : ''}`}
        </p>

        {/* Details */}
        <p className={`detail status ${styleStatusProperty}`}>{props?.data?.status ? props?.data?.status : 'Status'}</p>
      </div>
      <Gallery open={open} onClose={handleClose} images={props?.data?.images} />
      <ModalViewProduct open={openModalView} onClose={handleCloseModalView} loading={loadingModal} data={data || null} type={productType || null} />
      <ModalDeleteProduct
        open={openModalDelete}
        mode={mode}
        color={color}
        onClose={handleCloseModalDelete}
        type={productTypeDelete || null}
        onDelete={() => handleDelete(props.category, props.data.id)}
        action={{permissionName: isProperty ? 'Properties' : 'Projects', action: 'Delete'}}
      />
    </CardProductItemStyle>
  );
};

export default AddProductCard;
