import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import GalleryModel from '../models/galleryModel';
import UserRepository from './userRepository';

class GalleryRepository {
  private className = 'Gallery';

  constructor() {}

  // TODO :  Not working
  public async create(object: GalleryModel) {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Gallery = Parse.Object.extend(this.className);
      const gallery = new Gallery();

      const uploadFile = new Parse.File(object.file.name, object.file);

      gallery.set('file', uploadFile);
      gallery.set('createdBy', currentUser);
      gallery.set('size', object.file.size);
      gallery.set('organization', currentUser.get('organization'));

      await gallery.save(null, { useMasterKey: true }).then(
        (gallery: any) => {
          // Execute any logic that should take place after the object is saved.
          console.log('New object created with objectId: ' + gallery.id);
          // return Toast(`Gallery Added Successfully`, 'success');
        },
        (error: Error) => {
          console.log(error);
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          // return Toast(`Something is Missing`, 'error');
          // alert('Failed to create new object, with error code: ' + error.message);
        }
      );
    }
    else {
      return null;
    }
    
  }

  public async createAndReturn(object: GalleryModel) {

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const Gallery = Parse.Object.extend(this.className);
      const gallery = new Gallery();

      const uploadFile = new Parse.File(object.file.name, object.file);

      gallery.set('file', uploadFile);
      gallery.set('createdBy', currentUser);
      gallery.set('size', object.file.size);
      gallery.set('organization', currentUser.get('organization'));

      try {
        return new Promise<Parse.Object | null>((resolve, reject) => {
          gallery.save(null, { useMasterKey: true }).then(
            (savedGallery: Parse.Object | null) => {
              // Toast(`Gallery object added successfully!`, 'success');
              resolve(savedGallery);
            },
            (error: any) => {
              // Toast(`Error adding note ${error}`, 'error');
              resolve(null);
            }
          );
        });
      } catch (error) {
        
        console.error('Error adding gallery:', error);
        // Toast(`Error adding note ${error}`, 'error');
        return null;
      }
    }
    else {
      return null;
    }

    

    
  }

  // Read
  public async getObjectById(objectId: string) {
    const Gallery = Parse.Object.extend(this.className);
    const query = new Parse.Query(Gallery);
    return await query.get(objectId, { useMasterKey: true }).then((gallery) => {
      const galleryData = gallery.toJSON();
      console.log('🚀 ~ file: galleryRepository.ts:25 ~ GalleryRepository ~ returnawaitquery.get ~ galleryData:', galleryData);
      return galleryData;
    });
  }

  public async destroyImage(objectId: string) {
    const Gallery = Parse.Object.extend(this.className);
    const query = new Parse.Query(Gallery);
    const gallery = await query.get(objectId, { useMasterKey: true });
    const execute = await gallery.destroy({ useMasterKey: true });

    return execute;
  }
}

export default GalleryRepository;
