import React, { FC, SVGProps } from 'react';
import { globalColors } from '../../../utils/constants/color';

interface FireIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
  mode?: string;
}

const FireIcon: FC<FireIconProps> = ({ width, height, stroke, mode, ...rest }) => {
  return (
    <svg width={width ? width : '18'} height={height ? height : '24'} viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.6672 7.63722C3.56033 8.77722 3.48533 10.7947 4.15845 11.6535C4.15845 11.6535 3.84158 9.43722 6.6822 6.6566C7.82595 5.53722 8.09033 4.01472 7.69095 2.87285C7.46408 2.22597 7.0497 1.6916 6.6897 1.31847C6.4797 1.0991 6.64095 0.737222 6.94658 0.750347C8.79533 0.832847 11.7916 1.3466 13.0647 4.5416C13.6235 5.9441 13.6647 7.39347 13.3985 8.86722C13.2297 9.80847 12.6297 11.901 13.9985 12.1578C14.9753 12.3416 15.4478 11.5653 15.6597 11.0066C15.7478 10.7741 16.0535 10.716 16.2185 10.9016C17.8685 12.7785 18.0091 14.9891 17.6678 16.8922C17.0078 20.571 13.2822 23.2485 9.58095 23.2485C4.9572 23.2485 1.27658 20.6028 0.322203 15.8141C-0.062172 13.881 0.132828 10.056 3.11408 7.35597C3.33533 7.15347 3.6972 7.33347 3.6672 7.63722Z"
        fill="url(#paint0_radial_1214_1874)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1214_1874"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.66516 23.3068) rotate(-179.751) scale(13.2352 21.7163)"
        >
          <stop offset="0.314" stop-color="#FF9800" />
          <stop offset="0.662" stop-color="#FF6D00" />
          <stop offset="0.972" stop-color="#F44336" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default FireIcon;
