import { Box, Grid, Typography } from '@mui/material';
import { globalColors } from '../../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../../store/selector';
import { styled } from '@mui/material';
import Piechart from '../../../../organisms/charts/pieChart';
import Heading from '../../../../atoms/heading';
import RadialBarchart from '../../../../organisms/charts/radialBarChart';
import Counts from '../../../../organisms/dashboard/counts';
import { useEffect, useState } from 'react';
import PropertyRepository from '../../../../../utils/api/repositories/propertyRepository';
import { useParams } from 'react-router-dom';
import ProjectRepository from '../../../../../utils/api/repositories/projectRepository';
import EmployeeCounts from './employeeCounts';
import NotFoundLottie from '../../../../atoms/notFound';
import Loader from '../../../../atoms/loader';
const pieChartData = [
  { name: 'Category A', value: 50 },
  { name: 'Category B', value: 50 },
];

const data = [
  { name: 'Cold', value: 400 },
  { name: 'Moderate', value: 300 },
  { name: 'Hot', value: 300 },
  { name: 'Very hot', value: 200 },
];

interface SalesValues {
  properties: { name: string; value: number };
  projects: { name: string; value: number };
}

export interface FormValues {
  name: string;
  role: string;
  commission: string;
  email: string;
  phone: string;
  phone2: string;
}

const ReportCard = () => {
  const [sales, setSales] = useState<SalesValues>({ properties: { name: 'Properties', value: 0 }, projects: { name: 'Projects', value: 0 } });
  const [loading, setLoading] = useState(true);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const { id: userId } = useParams();

  useEffect(() => {
    const fetchSales = async () => {
      const objectId = userId ? userId : '';
      if (objectId !== '') {
        const propertyRepository = new PropertyRepository();
        const propertySales = await propertyRepository.getTotalSalesForUser(objectId);
        if (propertySales) {
          setSales({ properties: { name: `Properties`, value: propertySales }, projects: sales.projects });
        }
        const projectRepository = new ProjectRepository();
        const projectSales = await projectRepository.getTotalSalesForUser(objectId);
        if (projectSales) {
          setSales({ properties: sales.properties, projects: { name: 'Projects', value: projectSales } });
        }
      }
      setLoading(false);
    };

    fetchSales();
  }, []);

  return (
    <Box width={'100%'} paddingTop={'3%'}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
            border={`1px solid ${globalColors?.border.gray}`}
            borderRadius={'16px'}
            boxShadow={'#303030'}
            paddingLeft={'30px'}
            paddingRight={'10px'}
            paddingTop={'10px'}
          >
            {loading ? (
              <Box display={'flex'} alignItems={'center'} height={'100%'} paddingTop={'10px'} paddingBottom={'20px'}>
                <Loader />
              </Box>
            ) : (
              <Box>
                <Heading text={'Sales'} />
                {sales.properties.value > 0 || sales.projects.value > 0 ? (
                  <Piechart data={[sales.properties, sales.projects]} showNumbers />
                ) : (
                  <NotFoundLottie
                    text="Looks like this member has not made any sale yet. Motivate them now by sending them an alert."
                    size="small"
                    showButton={true}
                    buttonText="Send Alert"
                    navigateTo="/alerts"
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <EmployeeCounts />
        </Grid>
      </Grid>
    </Box>
  );
};

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  marginTop: '3%',
});

const Card = styled(Box)(({ mode }: { mode: any }) => ({
  height: '240px',
  border: `1px solid ${mode === 'dark' ? globalColors.black : globalColors.white}`,
  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
  borderRadius: '32px',
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  overflow: 'hidden',
}));

export default ReportCard;
