import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import { selectColor, selectNavigationLayout, selectOpenSidebar, selectThemeMode } from '../../../../../store/selector';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import Sidebar from '../../../sidebar';
import { drawerWidth } from '../../../../../utils/constants/drawerWidth';
import { FieldInput, FieldInputTextarea, Toast, Button } from '../../../../atoms';
import { globalColors } from '../../../../../utils/constants/color';
import { FaRegUser } from 'react-icons/fa';
import { BiEnvelope } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useParams } from 'react-router-dom';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import { formatRole, getInitials } from '../../../../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../../../store/routes';
import AddTagModal from '../organization/addTagModal';
import ContentTable from '../history/contentTable';
import UserModel from '../../../../../utils/api/models/userModel';
import GroupInput from '../../../groupInput';
import Dropdowndata from '../../../../atoms/dropdown2';
import Loader from '../../../../atoms/loader';
import { UserRole } from '../../../../../store/user';
import NoPermission from '../../../../atoms/noPermission';
import ModalViewNotes from '../../company/employee/modalViewNotes';
import * as Mui from '@mui/material';
import AddSalaryModal from './addSalaryModal';
import Paginator from '../../../../atoms/paginator';

const priorityOptions = [
  {
    label: 'Select',
    value: '',
  },
  {
    label: 'None',
    value: 'None',
  },
  {
    label: 'Low',
    value: 'Low',
  },
  {
    label: 'Medium',
    value: 'Medium',
  },
  {
    label: 'High',
    value: 'High',
  },
];

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  commissionPriority: string;
  commissionPercentage: number;
  totalSalary: number;
}

export interface FormValuesErrors {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  commissionPriority: string;
  commissionPercentage: string;
  totalSalary: string;
}

const EmployeeProfile = () => {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    commissionPriority: '',
    commissionPercentage: 0,
    totalSalary: 0,
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [openSalaryModal, setOpenSalaryModal] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState<any>(null);
  const [isUpdatingTags, setIsUpdatingTags] = useState(false);
  const [employeeName, setEmployeeName] = useState<string>('');
  const [employeeSalary, setEmployeeSalary] = useState<number>(0);
  const [salaries, setSalaries] = useState<Parse.Object[]>([]);
  const [employeeRole, setEmployeeRole] = useState<string>('');
  const [switchPage, setSwitchPage] = useState<string>('Profile');
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const [note, setNote] = useState('');
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(true);
  const profileCircle = useRef<HTMLDivElement>(null);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const color = useSelector(selectColor);
  const { id } = useParams();

  const onSubmit = async (values: FormValues) => {
    const updatedUser: UserModel = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      commissionPriority: values.commissionPriority,
      commissionPercentage: values.commissionPercentage,
      salary: values.totalSalary,
    };
    if (id) {
      const userRepository = new UserRepository();
      const user = await userRepository.update(updatedUser, id);
      if (user) {
        await getUserInformation();
        Toast('User details updated successfully.', 'success');
      } else {
        Toast('User details could not be updated successfully. Please try again.', 'error');
      }
    } else {
      Toast('Error fetching user details. Please try again.', 'error');
      setTimeout(() => {
        dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
        navigate('/hr/members');
      }, 1000);
    }
  };

  const handleTags = (tagName: string) => {
    setIsUpdatingTags(true);
    const newValue: string = tagName;
    setTags((prevTags) => [...prevTags, newValue]);
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Once the file is loaded, set it as the background image
      setBackgroundImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const openFileDialog = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.onchange = handleFileUpload;
    fileInput.click();
  };

  const getTotalCount = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser && id) {
      const totalCount = await userRepository.getSalarySlipsForUserCount(id);

      return totalCount;
    }
    return 0;
  };

  const getUserInformation = async () => {
    setLoading(true);
    if (id) {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        setHasPermission(currentUser.get('role') === UserRole.admin);
        const user = await userRepository.getObjectById(id);
        if (user) {
          setFormValues({
            firstName: user.get('firstName') ?? '',
            lastName: user.get('lastName') ?? '',
            email: user.get('email') || '',
            phone: user.get('phone') || '',
            commissionPriority: user.get('commissionPriority') || 'None',
            commissionPercentage: user.get('commissionPercentage') || 0,
            totalSalary: user.get('salary') || 0,
          });
          setEmployeeName(user.get('firstName') ? user.get('firstName') + ' ' + user.get('lastName') : '');
          setTags(user.get('tags') ?? []);
          setBackgroundImage(user.get('profilePicture')?.get('file')?._url || null);
          setEmployeeRole(formatRole(user.get('role')));
          setEmployeeSalary(user.get('salary') ?? 0);

          const userSalaries = await userRepository.getSalarySlipsForUser(id);
          if (userSalaries) {
            setSalaries(userSalaries);
          }
        }
      }

      setLoading(false);
    } else {
      Toast('Error fetching user details. Please try again.', 'error');
      setTimeout(() => {
        dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
        navigate('/hr/members');
      }, 1000);
    }
  };

  const getMoreSalarySlips = async (page: number) => {
    const userRepository = new UserRepository();
    if (id) {
      const userSalaries = await userRepository.getSalarySlipsForUser(id, page);
      if (userSalaries) {
        setSalaries(userSalaries);
      }
    }
  };

  const handleUpdateTags = async () => {
    const userRepository = new UserRepository();
    const userData: UserModel = {
      tags: tags,
    };
    if (id && isUpdatingTags) {
      const user = await userRepository.update(userData, id);
      if (user) {
        console.log('updated tags', user);
      }
    }
    setIsUpdatingTags(false);
  };

  const handleNote = (event: any) => {
    const { value } = event.target;

    setNote(value);
  };

  const handleAddNote = async () => {
    const objectId = id ? id : '';
    if (objectId !== '') {
      if (formikRef.current) {
        const userRepository = new UserRepository();
        const currentUser = userRepository.getCurrentUser();
        if (currentUser) {
          const newNote = {
            note: note,
            createdBy: `${currentUser.get('firstName')} ${currentUser.get('lastName')}`,
            createdAt: new Date().toString(),
          };
          const updatedUser = await userRepository.addNoteForUser(newNote, objectId);
          if (updatedUser) {
            setNotes((prevNotes) => [newNote, ...prevNotes]);
            setNote('');
            Toast('Note added successfully.', 'success');
          } else {
            Toast('Note could not be added successfully. Please try again.', 'error');
          }
        } else {
          Toast('Note could not be added successfully. Please try again.', 'error');
        }
      }
    } else {
      Toast('Error fetching user details. Please try again.', 'error');
      setTimeout(() => {
        dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
        navigate('/hr/members');
      }, 1000);
    }
  };

  const fetchNotes = async () => {
    try {
      const objectId = id ? id : '';
      if (objectId !== '') {
        setLoadingNotes(true);
        const repository = new UserRepository();
        const userNotes = await repository.getNotesForUser(objectId);
        if (userNotes) {
          const reversedNotes = [...userNotes].reverse();
          setNotes(reversedNotes);
          setLoadingNotes(false);
        } else {
          Toast('Error fetching the employee notes. Please try again.', 'error');
          setLoadingNotes(false);
        }
      } else {
        Toast('Error fetching user details. Please try again.', 'error');
        setTimeout(() => {
          dispatch(setAddNew({ addNew: false, path: '/performance/company' }));
          navigate('/hr/members');
        }, 1000);
      }
    } catch (error: any) {
      console.error('Error fetching posts:', error.message);
    }
  };

  useEffect(() => {
    getUserInformation();
    fetchNotes();
  }, []);

  useEffect(() => {
    handleUpdateTags();
  }, [isUpdatingTags, tags]);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Members / Profile'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : hasPermission ? (
          <>
            <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
              <Box marginBottom={'10px'}></Box>
              {/* Top Container */}
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { md: 'row', xs: 'column' },
                  alignItems: { xs: 'center', md: 'end' },
                  gap: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                }}
              >
                {/* 1st */}
                <Box
                // sx={{ width: { xl: '18%', lg: '18%', md: '18%', sm: '100%', xs: '100%' } }}
                >
                  <Box
                    ref={profileCircle}
                    sx={{
                      width: '208px',
                      height: '208px',
                      border: `1px solid ${globalColors.black}`,
                      backgroundColor: mode === 'dark' ? globalColors.background.gray : globalColors.whiteLight,
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: 'cover',
                      borderRadius: '110px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Typography fontWeight={600} fontSize={'60px'} color={color || globalColors.blue}>
                      {getInitials(employeeName)}
                    </Typography>
                    {/* {backgroundImage === null ? <ProfileIcon mode={mode} /> : <></>}
                <Box
                  sx={{
                    width: '56px',
                    height: '56px',
                    backgroundColor: globalColors.text.secondary,
                    borderRadius: '80px',
                    position: 'absolute',
                    bottom: '0%',
                    right: '8%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={openFileDialog}
                >
                  <CiCamera size={'2rem'} color={globalColors.black} />
                </Box> */}
                  </Box>
                </Box>

                {/* 2nd */}
                <Box
                  sx={{ width: { xl: '55%', lg: '55%', md: '55%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', gap: '10px' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '2%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                      gap: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xl: '68%', lg: '68%', md: '68%', sm: '100%', xs: '100%' },
                        height: '64px',
                        borderRadius: '50px',
                        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 18px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '18px',
                          fontWeight: 700,
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                        }}
                      >
                        {employeeName || '-'}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: { xl: '28%', lg: '28%', md: '28%', sm: '100%', xs: '100%' },
                        height: '64px',
                        borderRadius: '50px',
                        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 18px',
                      }}
                    >
                      <FaRegUser size={'1.3rem'} color={mode === 'dark' ? globalColors.white : globalColors.black} />
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: 500,
                          // lineHeight: '30px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: color || globalColors.blue,
                          marginLeft: '10px',
                        }}
                      >
                        {employeeRole ?? '-'}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 2.1 */}
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'start' },
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                    }}
                  >
                    {formValues.email !== '' && (
                      <Box
                        sx={{
                          width: '48px',
                          height: '48px',
                          borderRadius: '50px',
                          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BiEnvelope size={'1.5rem'} color={color || (mode === 'dark' ? globalColors.white : globalColors.black)} />
                      </Box>
                    )}
                    {formValues.phone !== '' && (
                      <Box
                        sx={{
                          width: '48px',
                          height: '48px',
                          borderRadius: '50px',
                          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BsTelephone size={'1rem'} color={color || (mode === 'dark' ? globalColors.white : globalColors.black)} />
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    {/* Tags */}
                    <Box
                      display={'flex'}
                      sx={{
                        flexDirection: 'row',
                        // marginTop: '5%',
                        // width: '100%',
                      }}
                      gap={'10px'}
                      justifyContent={'start'}
                      flexWrap={'wrap'}
                      rowGap={'20px'}
                    >
                      {tags?.map((e) => {
                        return (
                          <Box
                            sx={{
                              padding: '8px',
                              borderRadius: '12px',
                              transition: 'opacity 0.3s ease',
                              border: `1px solid ${color ? color : globalColors.blackLight}`,
                              backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.whiteLight,
                              color: mode === 'dark' ? globalColors?.white : globalColors.black,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '11px', padding: '10px' }}>{e}</span>
                            <ClearIcon
                              sx={{ cursor: 'pointer', fontSize: '16px' }}
                              onClick={() => {
                                setTags(tags.filter((item: any) => item !== e));
                                setIsUpdatingTags(true);
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>

                    {/* Add Tags */}
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '19px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        textDecoration: 'underline',
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        cursor: 'pointer',
                        width: { xl: '10%', lg: '10%', md: '10%', sm: '20%', xs: '20%' },
                        whiteSpace: 'nowrap',
                      }}
                      onClick={() => setOpenModal(true)}
                    >
                      + Add Tag
                    </Typography>
                  </Box>
                </Box>

                {/* 3rd */}
                <Box
                  sx={{
                    width: { md: '20%', xs: '100%' },
                    paddingTop: { xs: '2rem', md: '0px' },
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                  }}
                >
                  <Button onClick={() => setSwitchPage(switchPage === 'Profile' ? 'Payroll' : 'Profile')} type="button">
                    {switchPage === 'Profile' ? 'View Payroll' : 'View Profile'}
                  </Button>
                </Box>
              </Box>

              {/* Assignment Container */}
              {switchPage === 'Profile' ? (
                <>
                  <AssignmentContainer
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    padding={'20px'}
                    borderRadius={'16px'}
                    color={globalColors.white}
                    fontSize={'12px'}
                    boxShadow={'#171717'}
                    marginTop={'1rem'}
                  >
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        phone: formValues.phone,
                        email: formValues.email,
                        commissionPriority: formValues.commissionPriority,
                        commissionPercentage: formValues.commissionPercentage,
                        totalSalary: formValues.totalSalary,
                      }}
                      validate={(values) => {
                        const errors = {} as FormValuesErrors;

                        if (!values.firstName || !values.firstName.trim()) {
                          errors.firstName = 'Required';
                        }

                        if (!values.lastName || !values.lastName.trim()) {
                          errors.lastName = 'Required';
                        }

                        if (values.commissionPercentage < 0) {
                          errors.commissionPercentage = 'Value must be non-negative';
                        }

                        if (values.totalSalary < 0) {
                          errors.totalSalary = 'Value must be non-negative';
                        }

                        return errors;
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                        <Form>
                          <Box
                            borderRadius={'20px'}
                            marginBottom={'2px'}
                            bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                            padding={{ xs: '0px', md: '20px' }}
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'1rem'}
                          >
                            <GroupInput className="group-input">
                              <FieldInput
                                label="First Name"
                                id="firstName"
                                value={values.firstName}
                                mode={mode}
                                name={'firstName'}
                                placeholder={'First Name'}
                                type="text"
                                error={touched.firstName && errors.firstName ? errors.firstName : false}
                              />
                              <FieldInput
                                label="Last Name"
                                id="lastName"
                                value={values.lastName}
                                mode={mode}
                                name={'lastName'}
                                placeholder={'Last Name'}
                                type="text"
                                error={touched.lastName && errors.lastName ? errors.lastName : false}
                              />
                            </GroupInput>
                            <GroupInput className="group-input">
                              <FieldInput
                                label="Email"
                                id="email"
                                value={values.email}
                                mode={mode}
                                name={'email'}
                                placeholder={'Example'}
                                type="text"
                                disabled
                              />
                              <FieldInput
                                label="Phone"
                                id="phone"
                                value={values.phone}
                                mode={mode}
                                name={'phone'}
                                placeholder={'Phone'}
                                type="text"
                              />
                            </GroupInput>
                            <GroupInput className="group-input">
                              <Dropdowndata
                                label="Commission Priority"
                                id="commissionPriority"
                                name="commissionPriority"
                                mode={mode}
                                values={priorityOptions}
                                onChange={(e) => {
                                  setFieldValue('commissionPriority', e.target.value);
                                }}
                                defaultValue=""
                              />
                              <FieldInput
                                label="Commission (%)"
                                id="commissionPercentage"
                                value={values.commissionPercentage.toString()}
                                mode={mode}
                                name={'commissionPercentage'}
                                placeholder={'Commission (%)'}
                                type="number"
                                min={0}
                                error={touched.commissionPercentage && errors.commissionPercentage ? errors.commissionPercentage : false}
                              />
                            </GroupInput>
                            <GroupInput className="group-input">
                              <FieldInput
                                label="Salary"
                                id="totalSalary"
                                value={values.totalSalary.toString()}
                                mode={mode}
                                name={'totalSalary'}
                                placeholder="Salary"
                                type="number"
                                min={0}
                                error={touched.totalSalary && errors.totalSalary ? errors.totalSalary : false}
                              />
                              <Box sx={{ width: '100%', height: '100%', alignItems: 'end', display: 'flex', justifyContent: 'end' }}></Box>
                            </GroupInput>
                            <Box sx={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                              <Button type="submit">Update</Button>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                    <Divider sx={{ backgroundColor: globalColors.gray, marginTop: '2rem', marginBottom: '2rem' }} />
                    <Box padding={{ xs: '0px', md: '20px' }}>
                      <FieldInputTextarea
                        label="Notes"
                        className="col"
                        placeholder="Add notes"
                        name="note"
                        id="note"
                        onChange={handleNote}
                        value={note}
                      />
                      <Box display={'flex'} justifyContent={'space-between'} gap={'3rem'} paddingTop={'2rem'}>
                        <Mui.Button
                          onClick={() => {
                            setNotesModalOpen(true);
                          }}
                          type="button"
                          sx={{ color: color || globalColors.blue, whiteSpace: 'nowrap', padding: 0, width: '50px', marginTop: '10px' }}
                        >
                          View All
                        </Mui.Button>
                        <Button type="button" onClick={handleAddNote}>
                          Add
                        </Button>
                      </Box>
                    </Box>
                  </AssignmentContainer>
                </>
              ) : (
                <>
                  <ContentTable
                    data={salaries}
                    openSalaryModal={() => {
                      setOpenSalaryModal(true);
                    }}
                  />
                  <Paginator
                    getTotalCount={getTotalCount}
                    onChangePage={async (newPage) => {
                      await getMoreSalarySlips(newPage);
                      return true;
                    }}
                  />
                </>
              )}
            </Box>
            <AddTagModal
              open={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
              mode={mode}
              color={color}
              handleTags={handleTags}
            />
            <ModalViewNotes
              open={notesModalOpen}
              onClose={() => {
                setNotesModalOpen(false);
              }}
              mode={mode}
              color={color}
              notes={notes}
              loading={loadingNotes}
            />
            <AddSalaryModal
              open={openSalaryModal}
              onClose={() => {
                setOpenSalaryModal(false);
              }}
              mode={mode}
              color={color}
              userId={id ?? ''}
              onSuccess={getUserInformation}
            />
          </>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

const AssignmentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
}));

export default EmployeeProfile;
