import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { onLoading, setFormEditProperty } from '../../store/property';
import PropertyRepository from '../../utils/api/repositories/propertyRepository';
import { Toast } from '../../components/atoms';
import { selectPropertyState } from '../../store/selector';

const useEditProperty = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const propertyState = useSelector(selectPropertyState);
  const { id: objectId } = useParams();
  const [title, setTitle] = useState<string>('Properties / Edit');

  useEffect(() => {
    findOneProperty();
  }, []);

  // useEffect(() => {
  //   document.title = 'Property | ' + propertyState.formProperty.name;
  // }, [propertyState.formProperty.name]);

  const findOneProperty = async () => {
    dispatch(onLoading(true));
    if (!objectId) {
      navigate('/properties');
      return null;
    }

    const propertyRepostiory = new PropertyRepository();
    const getData = await propertyRepostiory.getByObjectId(objectId);
    dispatch(onLoading(false));

    if (!getData) {
      Toast('Property not found', 'error');
      navigate('/properties');
      return null;
    }

    if (getData) {
      // setTitle(getData.name);
      dispatch(setFormEditProperty(getData));
    }
  };

  return { formProperty: propertyState.formProperty, title, isLoading: propertyState.isLoading };
};

export default useEditProperty;
