import { EnumViews, IFormDetailProject } from '../../store/projects';
import { IFormDetailProperty } from '../../store/property';
import ProjectComponentModel from '../api/models/projectComponentModel';
import UserModel from '../api/models/userModel';

export enum ProductCategories {
  Property = 'Properties',
  Project = 'Projects',
  ProjectComponent = 'Project Components'
}
export interface ListProductsProps {
  category: ProductCategories;
  datas: Array<IFormDetailProperty | IFormDetailProject>;
  mode: string;
  isLoading: boolean;
  handleSwitchView: () => void;
  onClickNewProduct: () => void;
  view: EnumViews;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  handleHeaderCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getTotalCount: () => Promise<number>;
  onChangePage: (page: number) => Promise<boolean>;
}

export interface ListInventoryProps {
  category: ProductCategories;
  datas: Array<ProjectComponentModel>;
  mode: string;
  isLoading: boolean;
  handleSwitchView: () => void;
  onClickNewProduct: () => void;
  view: EnumViews;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  handleHeaderCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getTotalCount: () => Promise<number>;
  onChangePage: (page: number) => Promise<boolean>;
}

export interface ProductItemProps {
  category: ProductCategories;
  data: IFormDetailProperty | IFormDetailProject;
  className?: string;
  active: boolean;
  mode: string;
  onFlagClick?: () => void;
  onLoveClick?: () => void;
  onEditClick?: () => void;
  edit?: boolean;
  isCanEdit?: boolean;
  rowCheckboxState?: { [key: string]: boolean };
  setRowCheckboxState?: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
}

export interface InventoryItemProps {
  data: ProjectComponentModel;
  className?: string;
  active: boolean;
  mode: string;
  onFlagClick?: () => void;
  onLoveClick?: () => void;
  onEditClick?: () => void;
  edit?: boolean;
  isCanEdit?: boolean;
  rowCheckboxState?: { [key: string]: boolean };
  setRowCheckboxState?: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  organizationMembers: Array<UserModel>
}

export enum StatusProduct {
  Available = 'Available',
  Unavailable = 'Unavailable',
  Hold = 'Hold',
  Sold = 'Sold',
  None = ''
}

export enum ColorStatusProduct {
  Available = 'colorGreen',
  Unavailable = 'colorGray',
  Hold = 'colorGray',
  Sold = 'colorRed',
}

// * Dummy data
export interface IDataProject {
  id: string;
  name: string;
  type: string;
  consistOf: string;
  purpose: string;
  space: string;
  status: string;
}

export interface IDataProperty {
  id: string;
  name: string;
  address: string;
  amenities: string;
  space: string;
  status: string;
  price: string;
  image: string;
}
