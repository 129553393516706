import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import SalaryModel from '../models/salaryModel';

class SalaryRepository {
  private className = 'Salary';

  public async create(object: SalaryModel): Promise<Parse.Object | null> {
    try {
      const Salary = Parse.Object.extend(this.className);
      const newSalary = new Salary();

      newSalary.set('method', object.method);
      newSalary.set('difference', object.difference);
      newSalary.set('amount', object.amount);

      const savedSalary = await newSalary.save(null, { useMasterKey: true });
      return savedSalary;
    } catch (error: any) {
      console.error('Error creating salary:', error);
      return null;
    }
  }

  // Get
  public async getByUserId(userId: string): Promise<SalaryModel[]> {
    try {
      const Salary = Parse.Object.extend(this.className);
      const query = new Parse.Query(Salary);

      query.equalTo('user', { __type: 'Pointer', className: '_User', objectId: userId });

      // Execute the query
      const salaries = await query.find({ useMasterKey: true });

      // Map Parse objects to SalaryModel
      const salaryData: SalaryModel[] = salaries.map((payment: any) => ({
        objectId: payment.id,
        amount: payment.get('amount'),
        method: payment.get('method'),
        difference: payment.get('difference'),
        createdAt: payment.createdAt,
        updatedAt: payment.updatedAt,
      }));

      return salaryData;
    } catch (error: any) {
      console.error('Error retrieving salaries by organization ID:', error);
      return [];
    }
  }
}

export default SalaryRepository;
