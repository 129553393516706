import { Box, Button, Checkbox, Divider, IconButton, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

// import PlusIcon from '../../../assets/Icons/PlusIcon.svg';
import { FieldInput, Toast } from '../../atoms';
import { Form, Formik, FormikHelpers } from 'formik';
import LeadModel from '../../../utils/api/models/leadModel';
import NoteModel from '../../../utils/api/models/noteModel';
import NoteRepository from '../../../utils/api/repositories/noteRepository';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import { CiEdit } from 'react-icons/ci';
import { MdDelete, MdPeopleOutline } from 'react-icons/md';
import { BsCameraVideo, BsEyeFill, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import ModalDetailLead from './modalDetailLead';
import { useNavigate } from 'react-router-dom';
import ModalBulkEdit from './modalBulkEdit';
import ModalDeleteConfirm from './modalDeleteConfirm';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../store/routes';
import { LoadingFtn } from '../../../store/slices';
import { UserRole } from '../../../store/user';
import moment from 'moment';
import Loader from '../../atoms/loader';
import { getColorForPriority, getColorForStatus } from '../../../utils/helpers';
import { ContactToolTip } from '../../atoms/contactTooltip';

type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

interface LeadsCardProps {
  mode: string;
  color: string;
  content: LeadModel;
  setloading: (isLoading: boolean) => void;
  edit: boolean;
  setOpenBulkEditLeadModal: (isLoading: boolean) => void;
  openBulkEditLeadModal: boolean;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  managersandSalesExecutive: ManagerSalesExecutive[];
}

interface ValuesFollowUpDate {
  date: string;
  note: string;
  objectId: string;
}

const ClientsCard: React.FC<LeadsCardProps> = ({
  mode,
  content,
  color,
  setloading,
  edit,
  rowCheckboxState,
  setRowCheckboxState,
  setOpenBulkEditLeadModal,
  openBulkEditLeadModal,
  managersandSalesExecutive,
}) => {
  const today = new Date().toISOString().split('T')[0];
  const navigate = useNavigate();
  const [openDeleteLeadModal, setOpenDeleteLeadModal] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(true);
  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();
  const [openDetailLeadModal, setOpenDetailLeadModal] = useState<boolean>(false);

  const [leadId, setleadId] = useState<string>('');
  const [changeStatus, setChangeStatus] = useState<string>(content?.status ?? 'New');
  const [newStatus, setNewStatus] = useState<string>('');
  const [changePriority, setChangePriority] = useState<string>(content?.priority ?? 'Cold');
  const [selectedMethodContact, setSelectedMethodContact] = useState('');
  const [latestCommunication, setLatestCommunication] = useState<{ type: string; note: string; createdAt: string; doneBy: string } | null>(null);
  const [communicationLoading, setCommunicationLoading] = useState(true);
  const dispatch = useDispatch();
  const [revealCommunication, setRevealCommunication] = useState(
    currentUser && (currentUser.get('role') === UserRole.admin || content.assignedTo.id === currentUser.id)
  );

  const handleOpenModalDeleteLeads = () => setOpenDeleteLeadModal(false);
  const handleOpenModalDetailsLeads = () => {
    setOpenDetailLeadModal(false);
    setNewStatus('');
  };

  const handleDeleteLeads = () => {
    const leadRepository = new LeadRepository();
    if (content?.id) {
      const leadIdToDelete = content?.id;

      leadRepository
        .delete(leadIdToDelete)
        .then((result) => {
          if (result) {
            setOpenDeleteLeadModal(false);
            dispatch(LoadingFtn(true));
          } else {
            setOpenDeleteLeadModal(false);
          }
        })
        .catch((error) => {
          console.error('Error deleting lead:', error);
        });
    }
  };
  const handleOpenModalBulkEditLeads = () => setOpenBulkEditLeadModal(false);
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, leadId: string | undefined) => {
    if (leadId) {
      const checked = event.target.checked;
      setRowCheckboxState((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [leadId]: checked,
      }));
    }
  };

  const handleChangeStatus = async (event: SelectChangeEvent<string>) => {
    setNewStatus(event.target.value);
    setShowDetail(false);
    // const leadRepository = new LeadRepository();
    // await leadRepository.changeStatus(content?.id ?? '', event.target.value);
    // // dispatch(LoadingFtn(true));
    setTimeout(() => {
      setOpenDetailLeadModal(true);
    }, 2000);
  };

  const handleChangePriority = async (event: SelectChangeEvent<string>) => {
    setChangePriority(event.target.value);
    const leadRepository = new LeadRepository();
    await leadRepository.changePriority(content?.id ?? '', event.target.value);
    dispatch(LoadingFtn(true));
  };

  const handleAssignTo = async (event: SelectChangeEvent<string>) => {
    const leadRepository = new LeadRepository();
    await leadRepository.assign(content?.id ?? '', event.target.value);
    dispatch(LoadingFtn(true));
  };

  const fetchLatestCommunication = async () => {
    setCommunicationLoading(true);
    const leadRepository = new LeadRepository();
    const latest = await leadRepository.getLatestCommunication(content?.id ?? '');
    if (latest) {
      setLatestCommunication({
        type: latest.get('method'),
        doneBy: latest.get('doneBy').get('firstName') + ' ' + latest.get('doneBy').get('lastName'),
        createdAt: moment(latest.get('createdAt')).fromNow(),
        note: latest.get('note').get('body'),
      });
    }
    setCommunicationLoading(false);
  };

  useEffect(() => {
    fetchLatestCommunication();
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'10px'}
        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
        padding={'20px'}
        borderRadius={'10px'}
        color={globalColors.white}
        fontSize={'12px'}
        height={'100%'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'0'} marginBottom={'10px'}>
          {currentUser?.get('role') !== UserRole.executive && (
            <Box display={'flex'} justifyContent={'start'}>
              {edit && content && content.id && (
                <Checkbox
                  size="medium"
                  sx={{
                    color: color || globalColors.blue,
                    '&.Mui-checked': {
                      color: color || globalColors.secondBlue,
                    },
                  }}
                  onChange={(event) => {
                    handleRowCheckboxChange(event, content?.id);
                  }}
                  checked={rowCheckboxState[content?.id] || false}
                />
              )}
            </Box>
          )}
          <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
            <Typography
              fontSize={'20px'}
              textTransform={'capitalize'}
              color={mode === 'dark' ? globalColors.white : globalColors.black}
              marginTop={'auto'}
              marginBottom={'auto'}
            >
              {content?.firstName + ' ' + content?.lastName}
            </Typography>
            <Box display={'flex'} justifyContent={'end'} gap={'10px'}>
              {currentUser?.get('role') !== UserRole.executive && (
                <CiEdit
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: '/clients' }));
                    navigate(`/clients/edit/${content?.id}`);
                  }}
                />
              )}
              <BsEyeFill
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowDetail(true);
                  setOpenDetailLeadModal(true);
                  setleadId(content?.id || '');
                }}
              />
              {currentUser?.get('role') !== UserRole.executive && (
                <MdDelete
                  onClick={() => setOpenDeleteLeadModal(true)}
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'start'}>
            <Typography marginTop={'auto'} marginBottom={'auto'} color={color || globalColors.lightblue}>
              {content?.propertyProject?.get('name') ?? content?.category}
            </Typography>
          </Box>
        </Box>
        {content.assignedTo ? (
          <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
            <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
              Assigned To
            </Typography>
            <Typography color={color || globalColors.blue} fontSize={'12px'} marginTop={'auto'} marginBottom={'auto'}>
              {`${content?.assignedTo.get('firstName')} ${content?.assignedTo.get('lastName')}`}
            </Typography>
          </Box>
        ) : (
          <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
            <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
              Assign To
            </Typography>
            <Typography color={color || globalColors.blue} fontSize={'12px'} marginTop={'auto'} marginBottom={'auto'}>
              <Select
                onChange={(event: SelectChangeEvent<string>) => {
                  handleAssignTo(event);
                }}
                sx={{
                  marginRight: '-15px',
                  border: 'none',
                  fontSize: '12px',
                  outline: 'none',
                  color: color || globalColors.blue,
                  '& .MuiSelect-icon': {
                    color: color || globalColors.blue,
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderStyle: 'none',
                  },
                }}
              >
                {managersandSalesExecutive.map((user) => (
                  <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value={user.id}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </Typography>
          </Box>
        )}

        <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Priority
          </Typography>
          <Typography color={color || globalColors.blue} fontSize={'12px'} marginTop={'auto'} marginBottom={'auto'}>
            <Select
              onChange={(event) => {
                handleChangePriority(event);
              }}
              value={changePriority}
              sx={{
                marginRight: '-15px',
                border: 'none',
                fontSize: '12px',
                outline: 'none',
                color: getColorForPriority(changePriority) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
                '& .MuiSelect-icon': {
                  color: getColorForPriority(changePriority) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'none',
                },
              }}
            >
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Very Hot">
                Very Hot
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Hot">
                Hot
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Moderate">
                Moderate
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Cold">
                Cold
              </MenuItem>
            </Select>
          </Typography>
        </Box>
        <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Status
          </Typography>
          <FormControl>
            <Select
              onChange={(event) => {
                handleChangeStatus(event);
                setleadId(content?.id || '');
              }}
              value={changeStatus}
              sx={{
                marginRight: '-15px',
                border: 'none',
                fontSize: '12px',
                outline: 'none',
                color: getColorForStatus(changeStatus) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
                '& .MuiSelect-icon': {
                  color: getColorForStatus(changeStatus) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'none',
                },
              }}
            >
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="New">
                New
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Contacted">
                Contacted
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Qualified">
                Qualified
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Converted">
                Converted
              </MenuItem>
              <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Lost">
                Lost
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Communication
          </Typography>
          <Typography sx={{ display: 'flex', gap: '20px' }}>
            {content.primaryPhone &&
              (revealCommunication ? (
                <ContactToolTip value={content.primaryPhone} href={`tel:${content.primaryPhone}`} action={'Call'}>
                  <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BsTelephone size={'1rem'} color={color || globalColors.blue} />
              ))}
            {content.secondaryPhone &&
              (revealCommunication ? (
                <ContactToolTip value={content.secondaryPhone} href={`tel:${content.secondaryPhone}`} action={'Call'}>
                  <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BsTelephone size={'1rem'} color={color || globalColors.blue} />
              ))}
            {content.email &&
              (revealCommunication ? (
                <ContactToolTip value={content.email} href={`mailto:${content.email}`} action={'Email'}>
                  <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
              ))}
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} component={'span'} paddingTop={'15.375px'}>
          <Formik
            initialValues={{
              date: today,
              objectId: content?.id || '',
              note: '',
            }}
            validate={(values) => {
              const errors = {} as ValuesFollowUpDate;
              if (values.date === '' || values.note.trim() === '' || selectedMethodContact === '') {
                errors.date = 'Please provide all three: next follow up date, a comment and a method of contact';
              }

              return errors;
            }}
            onSubmit={async (values: ValuesFollowUpDate, { setSubmitting, resetForm }: FormikHelpers<ValuesFollowUpDate>) => {
              const note: NoteModel = { body: values.note, leadId: values?.objectId };
              const leadRepository = new LeadRepository();
              const savedLead = await leadRepository.addFollowUp(values?.objectId, values.date, selectedMethodContact, note);

              if (savedLead) {
                Toast('Follow up has been added successfully.', 'success');
              } else {
                Toast('Follow up could not be added successfully. Please try again', 'error');
              }

              resetForm();
              setSelectedMethodContact('');
              fetchLatestCommunication();
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'end', justifyContent: 'center' }}>
                  <FieldInput min={today} mode={mode} id="date" name="date" placeholder="Add a follow-up date" type="date" label="Next Follow Up" />
                  <FieldInput mode={mode} id="note" name="note" placeholder="Type your note here" type="text" label="Comments" />
                  <Box display={'flex'} flexDirection={'row'} width={'100%'} justifyContent={'space-between'}>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Method of Contact</Typography>
                      <Box display={'flex'} flexDirection={'row'} gap={'5px'} marginTop={'1rem'}>
                        <Box
                          onClick={() => {
                            if (selectedMethodContact === 'Call') {
                              setSelectedMethodContact('');
                            } else {
                              setSelectedMethodContact('Call');
                            }
                          }}
                          sx={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 50,
                            backgroundColor: selectedMethodContact === 'Call' ? color : 'transparent',
                          }}
                        >
                          <BsTelephone size={'1rem'} color={selectedMethodContact === 'Call' ? globalColors.white : color || globalColors.blue} />
                        </Box>
                        <Box
                          onClick={() => {
                            if (selectedMethodContact === 'Email') {
                              setSelectedMethodContact('');
                            } else {
                              setSelectedMethodContact('Email');
                            }
                          }}
                          sx={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 50,
                            backgroundColor: selectedMethodContact === 'Email' ? color : 'transparent',
                          }}
                        >
                          <BiEnvelope size={'1rem'} color={selectedMethodContact === 'Email' ? globalColors.white : color || globalColors.blue} />
                        </Box>
                        <Box
                          onClick={() => {
                            if (selectedMethodContact === 'Meeting') {
                              setSelectedMethodContact('');
                            } else {
                              setSelectedMethodContact('Meeting');
                            }
                          }}
                          sx={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 50,
                            backgroundColor: selectedMethodContact === 'Meeting' ? color : 'transparent',
                          }}
                        >
                          <BsCameraVideo
                            size={'1rem'}
                            color={selectedMethodContact === 'Meeting' ? globalColors.white : color || globalColors.blue}
                          />
                        </Box>
                        <Box
                          onClick={() => {
                            if (selectedMethodContact === 'Physical Meeting') {
                              setSelectedMethodContact('');
                            } else {
                              setSelectedMethodContact('Physical Meeting');
                            }
                          }}
                          sx={{
                            cursor: 'pointer',
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 50,
                            backgroundColor: selectedMethodContact === 'Physical Meeting' ? color : 'transparent',
                          }}
                        >
                          <MdPeopleOutline
                            size={'1rem'}
                            color={selectedMethodContact === 'Physical Meeting' ? globalColors.white : color || globalColors.blue}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Button type="submit" sx={{ color: color }}>
                      Add
                    </Button>
                  </Box>
                </Form>
                {touched.date && errors.date && (
                  <Typography color={'red'} fontSize={'12px'} marginTop={'2px'}>
                    {errors.date}
                  </Typography>
                )}
              </>
            )}
          </Formik>
        </Box>

        <Box display={'flex'} gap={'15px'} flexDirection={'column'} component={'span'} marginTop={'15px'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Latest Communication
          </Typography>
          {communicationLoading ? (
            <Box minHeight={'60px'} display={'flex'} alignItems={'center'}>
              <Loader size={'14px'} />
            </Box>
          ) : latestCommunication === null ? (
            <Box minHeight={'60px'} display={'flex'} alignItems={'center'}>
              <Typography>No communication found.</Typography>
            </Box>
          ) : (
            <Box
              component={'span'}
              bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
              paddingLeft={'20px'}
              color={mode === 'dark' ? globalColors.white : globalColors.black}
              paddingRight={'20px'}
              paddingTop={'10px'}
              paddingBottom={'10px'}
              borderRadius={'12px'}
            >
              <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={'10px'}>
                <Typography>{latestCommunication?.type}</Typography>
                {latestCommunication?.type === 'Call' && <BsTelephone size={'1rem'} color={color || globalColors.blue} />}
                {latestCommunication?.type === 'Email' && <BiEnvelope size={'1rem'} color={color || globalColors.blue} />}
                {latestCommunication?.type === 'Meeting' && <BsCameraVideo size={'1rem'} color={color || globalColors.blue} />}
              </Box>
              <Box marginTop={'0.5rem'}>
                <Typography sx={{ marginTop: '6px', fontSize: '14px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                  {latestCommunication.note.length > 130 ? latestCommunication.note.substring(0, 127) + '...' : latestCommunication.note}
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} marginTop={'5px'}>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{latestCommunication.createdAt}</span>
                  <span style={{ color: color || globalColors.blue, fontSize: '12px' }}>{latestCommunication.doneBy}</span>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <ModalDeleteConfirm
          open={openDeleteLeadModal}
          onClose={handleOpenModalDeleteLeads}
          mode={mode}
          color={color}
          onDelete={handleDeleteLeads}
          action={{ permissionName: 'Leads', action: 'Delete' }}
        />
        <ModalDetailLead
          open={openDetailLeadModal}
          onClose={handleOpenModalDetailsLeads}
          mode={mode}
          showDetail={showDetail}
          color={color}
          leadId={leadId}
          newStatus={newStatus}
        />
        <ModalBulkEdit
          rowCheckboxState={rowCheckboxState}
          open={openBulkEditLeadModal}
          onClose={handleOpenModalBulkEditLeads}
          mode={mode}
          color={color}
        />
      </Box>
    </div>
  );
};

export default ClientsCard;
