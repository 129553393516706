import React from 'react';
import { MainLayout } from '../../components/molecules/layout';
import CaledarView from '../../components/organisms/calendar/caledarView';
import useCalendarView from '../../hooks/calendar/useCalendarView';
import { useSelector } from 'react-redux';
import { selectOrganizationTier, selectThemeMode } from '../../store/selector';
import { PricingTiers } from '../../utils/helpers';
import InsufficientPlan from '../../components/atoms/insufficientPlan';

const Calendar = () => {
  const { view } = useCalendarView();
  const organizationTier = useSelector(selectOrganizationTier);

  const mode = useSelector(selectThemeMode);

  return (
    <MainLayout navigation={true} mode={mode} titlePage="Schedule">
      {organizationTier == PricingTiers.Professional ? <CaledarView view={view} month="Calendar" hasNavigation /> : <InsufficientPlan />}
    </MainLayout>
  );
};

export default Calendar;
