import type { SchedulerRef } from '@aldabil/react-scheduler/types';
import { ProcessedEvent, RemoteQuery } from '@aldabil/react-scheduler/types';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ActivityRepository from '../../utils/api/repositories/activityRepository';
import UserRepository from '../../utils/api/repositories/userRepository';
import { selectCalendarView } from '../../store/selector';

const useCalendarView = () => {
  const view = useSelector(selectCalendarView);
  const calendarRef = useRef<SchedulerRef>(null);

  useEffect(() => {
    handleSwitchView();
  }, [view]);

  const handleSwitchView = () => {
    if (calendarRef.current) {
      if (view === 'day') {
        (calendarRef.current as SchedulerRef).scheduler.handleState('day', 'view');
      } else if (view === 'week') {
        (calendarRef.current as SchedulerRef).scheduler.handleState('week', 'view');
      } else if (view === 'month') {
        (calendarRef.current as SchedulerRef).scheduler.handleState('month', 'view');
      }
    }
  };

  const fetchActivities = async (query: RemoteQuery): Promise<ProcessedEvent[]> => {
    const activityRepository = new ActivityRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    const queryGetAll = await activityRepository.getAll(query.start, query.end, currentUser);

    // ! Display to view
    const viewActivityCalendar: ProcessedEvent[] = [];
    if (queryGetAll.length > 0) {
      queryGetAll.forEach((activity) => {
        const parseActivity = {
          event_id: activity.objectId,
          title: activity.title,
          start: activity.start,
          end: activity.end,
          executive: activity.assignedTo,
          lead: activity.lead,
          type: activity.type,
          color: activity.color,
        } as ProcessedEvent;
        viewActivityCalendar.push(parseActivity);
      });
    }

    return viewActivityCalendar;
  };

  const deleteActivity = async (id: string | number): Promise<string | number | void> => {
    const activityRepository = new ActivityRepository();
    await activityRepository.delete(id as string);
    setTimeout(() => {
      window.location.reload();
    }, 3000)
    return;
  }

  return { view, calendarRef, fetchActivities, deleteActivity };
};

export default useCalendarView;
