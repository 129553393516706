import { styled } from '@mui/material';
// import { ICBed, ICCoffe, ICShower, ICWifi } from '../../assets/Icons';
import { globalColors } from '../../utils/constants/color';
import { IAmenities } from '../../store/property';

interface AmanitiesProps {
  isOnlyText?: boolean;
  amenities: IAmenities[];
  mode: string;
}
interface AmenitiesStyleProps {
  mode: string;
}

const Amenities = ({ isOnlyText, amenities, mode }: AmanitiesProps) => {
  return (
    <AmenitiesStyle mode={mode}>
      {/* Default with icons used on list view */}
      {!isOnlyText && (
        <>
          <div className="with-icon-wrapper">
            {amenities?.map((item, index) => {
              return (
                <div className="item" key={index}>
                  {item.value}
                  {item.icon && <img src={item.icon} alt="property bed" />}
                  {/* {item.label && <>{item.label}</>} */}
                </div>
              );
            })}
          </div>
        </>
      )}

      {/* Render text only used on card view */}
      {isOnlyText && (
        <>
          {amenities?.map((item, index) => {
            return (
              <div key={index}>
                {item.label} {item.value}
              </div>
            );
          })}
        </>
      )}
    </AmenitiesStyle>
  );
};

const AmenitiesStyle = styled('div')<AmenitiesStyleProps>(({ theme, mode }) => ({
  '& .with-icon-wrapper': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& .item': {
      display: 'flex',
      gap: '4px',
      color: mode === 'dark' ? globalColors.white : globalColors.black,
    },

    '& .divider': {
      width: '8px',
      height: '1px',
      background: globalColors.text.secondary,
    },
  },
}));

export default Amenities;
