import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { globalColors } from '../../../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../../../store/selector';
import PhoneIcon from './PhoneIcon';
import EmailIcon from './emailIcon';
import { styled } from '@mui/material';
import { useNavigate } from 'react-router';
import UserModel from '../../../../../utils/api/models/userModel';
import { formatRole } from '../../../../../utils/helpers';
import { BsEyeFill, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import LeadRepository from '../../../../../utils/api/repositories/leadRepository';
import LeadModel from '../../../../../utils/api/models/leadModel';
import RoundedAvatar from '../../../../atoms/roundedAvatar';
import { setAddNew } from '../../../../../store/routes';
const options = ['Save', 'Edit'];
const ITEM_HEIGHT = 48;

interface ContentRowProp {
  id?: string;
  data: UserModel;
}

const ContentRow: React.FC<ContentRowProp> = ({ data, id }) => {
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isHovered, setIsHovered] = useState(false);
  const [isHovered, setIsHovered] = useState('');
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [isHoveredIndex, setIsHoveredIndex] = useState<any>(null);
  const openMenu = Boolean(anchorEl);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assignedLeads, setAssignedLeads] = useState<LeadModel[]>([]);

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const handleAction = (id: any) => {
    navigate(`/performance/company/report/${id}`);
  };

  const handleCardEnter = () => {
    setIsCardHovered(true);
  };

  const handleCardLeave = () => {
    setIsCardHovered(false);
  };

  const fetchAssignedLeads = async () => {
    const leadRepository = new LeadRepository();
    if (data.objectId) {
      const leads = await leadRepository.getAllAssignedTo(data.objectId);
      setAssignedLeads(leads);
    }
  };

  useEffect(() => {
    fetchAssignedLeads();
  }, []);

  return (
    <TableRow
      sx={{
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        width: '100%',
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        borderRadius: '10px',
        gap: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
      }}
      key={data.objectId}
    >
      {/* Name */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {`${data.firstName} ${data.lastName}`}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Role */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '28px',
              letterSpacing: '-2%',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            {formatRole(data.role ?? '')}
          </Typography>
        </Box>
      </TableCell>

      {/* Assigned Leads */}
      <TableCell>
        <Typography
          sx={{ display: 'flex', color: mode === 'dark' ? globalColors.white : globalColors.black }}
          width={'10px'}
          fontSize={'12px'}
          flexGrow={1}
          textAlign="left"
        >
          {assignedLeads.length > 0 ? (
            assignedLeads.length === 1 ? (
              <Tooltip
                title={
                  <>
                    <Typography fontSize={12}>{`${assignedLeads[0].firstName} ${assignedLeads[0].lastName}`}</Typography>
                  </>
                }
              >
                <Box>
                  <RoundedAvatar title={`${assignedLeads[0].firstName} ${assignedLeads[0].lastName}`} />
                </Box>
              </Tooltip>
            ) : (
              assignedLeads.slice(0, 3).map((lead: LeadModel, i: any) => {
                return (
                  <Tooltip
                    title={
                      <>
                        <Typography fontSize={12}>{`${lead.firstName} ${lead.lastName}`}</Typography>
                      </>
                    }
                  >
                    <Box>
                      <RoundedAvatar title={`${lead.firstName} ${lead.lastName}`} />
                    </Box>
                  </Tooltip>
                );
              })
            )
          ) : (
            '-'
          )}
          {/* Remaining Count */}
          {assignedLeads?.length > 3 ? (
            <Tooltip
              title={
                <>
                  {assignedLeads.map((lead: LeadModel, i: any) => (
                    <Typography fontSize={12}>{`${lead.firstName} ${lead.lastName}`}</Typography>
                  ))}
                </>
              }
            >
              <Box>
                <RoundedAvatar title={`+${assignedLeads?.length - 3}`} showInitials={false} />
              </Box>
            </Tooltip>
          ) : (
            <></>
          )}
        </Typography>
      </TableCell>

      {/* Communication */}
      <TableCell sx={style} component="th" scope="row">
        <Box display="flex" gap={2}>
          {/* Contact */}
          <Box component="span" display="flex" justifyContent="center" bgcolor="none" borderRadius="10%" padding="0.3rem">
            {data?.phone !== '' && (
              <Box display={'flex'} gap={'3px'} justifyContent={'center'}>
                <BsTelephone size={'0.8rem'} color={color || globalColors.blue} />
              </Box>
            )}
          </Box>

          {/* Email */}
          <Box component="span" display="flex" justifyContent="center" bgcolor="none" borderRadius="10%" padding="0.3rem">
            {data?.email !== '' && (
              <Box display={'flex'} gap={'3px'} justifyContent={'center'}>
                <BiEnvelope size={'0.8rem'} color={color || globalColors.blue} />
              </Box>
            )}
          </Box>
        </Box>
      </TableCell>

      {/* Commission */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '28px',
              letterSpacing: '-2%',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            {data?.commissionPercentage ? `${data.commissionPercentage}%` : '-'}
          </Typography>
        </Box>
      </TableCell>

      {/* Action */}
      <TableCell>
        <Box sx={{ width: '100%' }}>
          <BsEyeFill
            size={'1.3rem'}
            color={color || globalColors.blue}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(setAddNew({addNew: true, path: '/performance/company'}))
              handleAction(data?.objectId);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

const AvatarContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '7px',
}));

const ButtonStyle = styled('button')<any>((props) => ({
  height: '32px',
  all: 'unset',
  cursor: 'pointer',
  background: props.mode === 'dark' ? globalColors.black : globalColors.white,
  color: props.mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '4px 16px',
  borderRadius: '6px',
  transition: 'opacity 0.3s ease',
  border: `1px solid ${globalColors.secondBlue}`,
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  // '@media (max-width: 768px)': {
  //   width: '100%',
  // },
}));

export default ContentRow;
