import { Box, Modal, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../../utils/constants/color';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import { hasPermissionForAction } from '../../../../utils/helpers';

interface ModalDeleteLeadProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  onReject: any;
  action?: {
    permissionName: 'Leads' | 'Properties' | 'Projects' | 'Email' | 'Social Media' | 'Tasks' | 'Lead / Client Payments';
    action: 'View' | 'Create' | 'Update' | 'Delete';
  };
}
const ModalRejectConversionConfirm = ({ open, onClose, mode, color, onReject, action }: ModalDeleteLeadProps) => {
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    maxWidth: '525px',
    maxHeight: '360px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    // border: '2px solid #000',
    boxShadow: 5,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  useEffect(() => {
    if (action) {
      setHasPermission(hasPermissionForAction(action.permissionName, action.action));
    }
    else {
      setHasPermission(true);
    }
    setLoading(false);
  }, []);

  return (
    <Modal open={open} onClose={onClose} className="content">
      {loading ? (
        <Box sx={style}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <Box sx={style}>
          <Typography fontSize={'20px'} textAlign={'center'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
            Are you sure you want to proceed?
          </Typography>
          <Typography marginTop={'15px'} fontSize={'12px'} textAlign={'center'} color={mode === 'dark' ? globalColors.gray : globalColors.black}>
            Please note that this action would be irreversible, and you would not be able to access the deleted data.
          </Typography>
          <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
            <Button
              sx={{
                border: `1px solid ${color || globalColors.blue}`,
                color: mode == 'dark' ? globalColors.white : globalColors.black,
                width: '100%',
                borderRadius: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                '&:hover': {
                  backgroundColor: color || globalColors.blue,
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: globalColors.red,
                color: globalColors.white,
                width: '100%',
                borderRadius: '12px',
                '&:hover': {
                  backgroundColor: globalColors.red,
                },
              }}
              onClick={onReject}
            >
              Reject
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={style}>
          <NoPermission size='small'/>
        </Box>
      )}
    </Modal>
  );
};

export default ModalRejectConversionConfirm;
