import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, TableCell, TableRow, Typography } from '@mui/material';
import { globalColors } from '../../../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../../../store/selector';
import { styled } from '@mui/material';
import { CiEdit } from 'react-icons/ci';
import { BsEyeFill } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import { TaskStatus, formatDate, formatEmailDate, getColorForPriority, getColorForTaskStatus } from '../../../../../utils/helpers';
import TaskRepository from '../../../../../utils/api/repositories/taskRepository';
import { Toast } from '../../../../atoms';
import { reSortTasks, refreshTasks } from '../../../../../store/slices';
import ModalDeleteConfirm from '../../../../organisms/leads/modalDeleteConfirm';
import { useNavigate } from 'react-router';
import { setAddNew } from '../../../../../store/routes';

interface ContentRowProp {
  id: string;
  data: Parse.Object;
  key: string;
}

const ContentRow: React.FC<ContentRowProp> = ({ id, data, key }) => {
  const [changePriority, setChangePriority] = useState<string>(data.get('priority') ?? 'Hot');
  const [changeStatus, setChangeStatus] = useState<string>(data.get('status') ?? 'In Progress');
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const handleChangePriority = async (event: SelectChangeEvent<string>) => {
    setChangePriority(event.target.value);
    const taskRepository = new TaskRepository();
    const savedTask = await taskRepository.updatePriority(data.id, event.target.value);
    if (savedTask) {
      Toast('Task priority has been updated successfully.', 'success');
    } else {
      Toast('Task priority could not be updated successfully. Please try again', 'error');
    }
  };

  const handleChangeStatus = async (event: SelectChangeEvent<string>) => {
    setChangeStatus(event.target.value);
    const taskRepository = new TaskRepository();
    const savedTask = await taskRepository.updateStatus(data.id, event.target.value);
    if (savedTask) {
      Toast('Task status has been updated successfully.', 'success');
      dispatch(reSortTasks(true));
    } else {
      Toast('Task status could not be updated successfully. Please try again', 'error');
    }
  };

  const handleDeleteTask = async () => {
    const taskRepository = new TaskRepository();
    const taskDeleted = await taskRepository.delete(data.id);
    if (taskDeleted) {
      Toast('Task has been deleted successfully.', 'success');
      dispatch(refreshTasks(true));
    } else {
      Toast('Task could not be deleted successfully. Please try again', 'error');
    }
  };

  return (
    <TableRow
      sx={{
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        width: '100%',
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        borderRadius: '10px',
        gap: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
      }}
      key={id}
    >
      <TableCell sx={style} component="th" scope="row">
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '-0.02em',
            textAlign: 'left',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
          }}
        >
          {data?.get('title')}
        </Typography>
      </TableCell>

      <TableCell sx={style} component="th" scope="row">
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '-0.02em',
            textAlign: 'left',
            color: mode === 'dark' ? globalColors.white : globalColors.black,
          }}
        >
          {`${data?.get('assignedTo').get('firstName')} ${data?.get('assignedTo').get('lastName')}`}
        </Typography>
      </TableCell>

      {/* startDate */}
      <TableCell style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }} sx={style}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '-0.02em',
            textAlign: 'left',
            color: mode === 'dark' ? globalColors.gray : globalColors.black,
          }}
        >
          {moment(data.get('createdAt')).fromNow()}
        </Typography>
      </TableCell>

      {/* endDate */}
      <TableCell style={{ color: color || globalColors.blue }} sx={style}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '-0.02em',
            textAlign: 'left',
            color: mode === 'dark' ? globalColors.gray : globalColors.black,
          }}
        >
          {data.get('dueDate') ? formatDate(data.get('dueDate')) : '-'}
        </Typography>
      </TableCell>

      {/* Priority */}
      <TableCell style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }} sx={style}>
        <Typography color={color || globalColors.blue} fontSize={'12px'} marginTop={'auto'} marginBottom={'auto'}>
          <Select
            onChange={(event) => {
              handleChangePriority(event);
            }}
            value={changePriority}
            sx={{
              marginRight: '-15px',
              border: 'none',
              fontSize: '12px',
              outline: 'none',
              color: getColorForPriority(changePriority) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
              '& .MuiSelect-icon': {
                color: getColorForPriority(changePriority) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderStyle: 'none',
              },
            }}
          >
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Very Hot">
              Very Hot
            </MenuItem>
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Hot">
              Hot
            </MenuItem>
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Moderate">
              Moderate
            </MenuItem>
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value="Cold">
              Cold
            </MenuItem>
          </Select>
        </Typography>
      </TableCell>

      {/* status */}
      <TableCell style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }} sx={style}>
        <Typography color={color || globalColors.blue} fontSize={'12px'} marginTop={'auto'} marginBottom={'auto'}>
          <Select
            onChange={(event) => {
              handleChangeStatus(event);
            }}
            value={changeStatus}
            sx={{
              marginRight: '-15px',
              border: 'none',
              fontSize: '12px',
              outline: 'none',
              color: getColorForTaskStatus(changeStatus) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
              '& .MuiSelect-icon': {
                color: getColorForTaskStatus(changeStatus) ?? (mode === 'dark' ? globalColors.white : globalColors.black),
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderStyle: 'none',
              },
            }}
          >
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value={TaskStatus.inProgress}>
              {TaskStatus.inProgress}
            </MenuItem>
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value={TaskStatus.blocked}>
              {TaskStatus.blocked}
            </MenuItem>
            <MenuItem className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'} value={TaskStatus.completed}>
              {TaskStatus.completed}
            </MenuItem>
          </Select>
        </Typography>
      </TableCell>

      {/* Action */}
      <TableCell>
        <Box sx={{ width: '100%' }} display={'flex'} gap={'10px'}>
          <CiEdit
            size={'1.3rem'}
            color={color || globalColors.blue}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(setAddNew({ addNew: true, path: '/activity/tasks' }));
              navigate(`/activity/task/${data.id}`);
            }}
          />
          <MdDelete
            size={'1.3rem'}
            color={color || globalColors.blue}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenDelete(true);
            }}
          />
        </Box>
      </TableCell>
      <ModalDeleteConfirm
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        mode={mode}
        color={color}
        onDelete={handleDeleteTask}
        action={{ permissionName: 'Tasks', action: 'Delete' }}
      />
    </TableRow>
  );
};

const AvatarContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '7px',
}));

const ButtonStyle = styled('button')<any>((props) => ({
  height: '32px',
  all: 'unset',
  cursor: 'pointer',
  background: props.mode === 'dark' ? globalColors.black : globalColors.white,
  color: props.mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '4px 16px',
  borderRadius: '6px',
  transition: 'opacity 0.3s ease',
  border: `1px solid ${globalColors.secondBlue}`,
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
}));

export default ContentRow;
