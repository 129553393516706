import { useEffect, useState } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Heading from '../../../../atoms/heading';
import { useDispatch, useSelector } from 'react-redux';
import { globalColors } from '../../../../../utils/constants/color';
import { selectAddNew, selectThemeMode } from '../../../../../store/selector';
import ContentRow from './contentRow';
import SocialPostRepository from '../../../../../utils/api/repositories/socialPostRepository';
import NotFoundLottie from '../../../../atoms/notFound';
import { useProjects } from '../../../../../hooks';
import { IFormDetailProject } from '../../../../../store/projects';
import ProjectRepository from '../../../../../utils/api/repositories/projectRepository';
import Loader from '../../../../atoms/loader';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const headerContent = ['Name', 'Type', 'Consists of', 'Purpose', 'Status'];

const ContentTable = () => {
  const [projects, setProjects] = useState<IFormDetailProject[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(true);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true);
        const projectRepository = new ProjectRepository();
        const data = await projectRepository.getSome();
        if (data) {
          setProjects(data);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return (
    <Box
      marginTop={'1%'}
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      // border={`1px solid ${globalColors?.border.gray}`}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={'30px'}
      paddingRight={'30px'}
      paddingTop={'10px'}
      height={'100%'}
    >
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'200px'}>
          <Loader />
        </Box>
      ) : projects.length > 0 ? (
        <>
          <Heading text="Recently Added" />
          <TableContainer sx={{ paddingTop: '30px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerContent.map((val) => (
                    <TableCell sx={{ color: globalColors.gray }}>
                      <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                        {val}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((item) => (
                  <ContentRow data={item} key={item?.id} id={item?.id} name={item?.name} />
                ))}
              </TableBody>
            </Table>
            <Box
              onClick={() => navigate('/projects')}
              display={'flex'}
              sx={{ cursor: 'pointer' }}
              justifyContent={'end'}
              component={'span'}
              gap={'10px'}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              marginTop={'30px'}
              marginBottom={'10px'}
              marginRight={'10px'}
            >
              <Typography
                fontWeight={500}
                color={mode === 'dark' ? globalColors.white : globalColors.black}
                alignContent={'right'}
                textAlign={'right'}
              >
                View all Projects
              </Typography>
              <Box
                style={{
                  transition: 'transform 0.3s',
                  transform: isHovered ? 'translateX(5px)' : 'translateX(0)',
                }}
              >
                <BsArrowRight size={'1.5rem'} color={mode === 'dark' ? globalColors.white : globalColors.black} style={{ margin: 'auto' }} />
              </Box>
            </Box>
          </TableContainer>
        </>
      ) : (
        <NotFoundLottie
          showButton={true}
          text={'Looks like you have not added any projects yet. Navigate to projects to add a new project.'}
          buttonText="Go to Projects"
          navigateTo="/projects"
        />
      )}
    </Box>
  );
};

export default ContentTable;
