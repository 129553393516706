import { Toast } from '../../../components/atoms';
import { UserRole } from '../../../store/user';
import { TaskStatus, dataLimit } from '../../helpers';
import ActivityModel from '../models/activityModel';
import TaskModel from '../models/taskModel';
import LeadRepository from './leadRepository';
import ManagerRepository from './managerRepository';
import SalesExecutiveRepository from './salesExecutiveRepository';
import UserRepository from './userRepository';
import Parse from 'parse';

class TaskRepository {
  private className = 'Task';

  public async create(object: TaskModel): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (!currentUser) {
        return null;
      }

      const Task = Parse.Object.extend(this.className);
      const newTask = new Task();

      newTask.set('title', object.title);
      newTask.set('dueDate', object.dueDate);
      newTask.set('status', TaskStatus.inProgress);
      newTask.set('priority', object.priority)
      newTask.set('createdBy', currentUser);
      newTask.set('updatedBy', currentUser);
      newTask.set('description', object.description);
      newTask.set('organization', currentUser.get('organization'));

      let assignedTo: Parse.Object | null = null;
      if (object.assignedTo && typeof(object.assignedTo) == 'string') {
        assignedTo = await userRepository.getObjectById(object.assignedTo);
        if (assignedTo) {
          newTask.set('assignedTo', assignedTo);
          if (assignedTo.get('role') === UserRole.executive) {
            const salesExecutiveRepository = new SalesExecutiveRepository();
            const executive = await salesExecutiveRepository.getObjectByUserId(assignedTo.id);
            if (executive) {
              newTask.set('managedBy', executive.get('manager').get('user'));
            }
            else {
              return null;
            }
          }
          else if (assignedTo.get('role') === UserRole.manager) {
            newTask.set('managedBy', assignedTo);
          }
        }
        else {
          return null;
        }
      }
      else {
        return null;
      }

      return new Promise((resolve, _) => {
        newTask
          .save(null, { useMasterKey: true })
          .then((savedTask: Parse.Object | null) => {
            resolve(savedTask)
          })
          .catch((error: Error) => {
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error creating task:', error);
      return null;
    }
  }

  public async update(id: string, object: TaskModel): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (!currentUser) {
        return null;
      }

      const Task = Parse.Object.extend(this.className);
      const query = new Parse.Query(Task);
      query.include('assignedTo');
      const task = await query.get(id, { useMasterKey: true });
      if (task) {
        task.set('title', object.title);
        task.set('dueDate', object.dueDate);
        task.set('status', object.status);
        task.set('priority', object.priority)
        task.set('updatedBy', currentUser);
        task.set('description', object.description);

        let assignedTo: Parse.Object | null = null;
        if (object.assignedTo && typeof(object.assignedTo) == 'string') {
          assignedTo = await userRepository.getObjectById(object.assignedTo);
          if (assignedTo) {
            task.set('assignedTo', assignedTo);
            if (assignedTo.get('role') === UserRole.executive) {
              const salesExecutiveRepository = new SalesExecutiveRepository();
              const executive = await salesExecutiveRepository.getObjectByUserId(assignedTo.id);
              if (executive) {
                task.set('managedBy', executive.get('manager').get('user'));
              }
              else {
                return null;
              }
            }
            else if (assignedTo.get('role') === UserRole.manager) {
              task.set('managedBy', assignedTo);
            }
            else if (assignedTo.get('role') === UserRole.admin) {
              task.set('managedBy', null);
            }
          }
          else {
            return null;
          }
        }
        else {
          return null;
        }

        return new Promise((resolve, _) => {
          task
            .save(null, { useMasterKey: true })
            .then((savedTask: Parse.Object | null) => {
              resolve(savedTask)
            })
            .catch((error: Error) => {
              resolve(null);
            });
        });
      }
      else {
        return null;
      }

      

      
    } catch (error) {
      console.error('Error creating task:', error);
      return null;
    }
  }

  public async updatePriority(id: string, priority: string): Promise<Parse.Object | null> {
    const Task = Parse.Object.extend(this.className);
    const query = new Parse.Query(Task);
    try {
      const task = await query.get(id, { useMasterKey: true });
      if (task) {
        task.set('priority', priority);
        const savedTask = await task.save(null, { useMasterKey: true });
        return savedTask;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error updating priority for task:", error);
      return null;
    }
  }

  public async delete(id: string): Promise<boolean | null> {
    const Task = Parse.Object.extend(this.className);
    const query = new Parse.Query(Task);
    try {
      const task = await query.get(id, { useMasterKey: true });
      if (task) {
        const deletedTask = await task.destroy({ useMasterKey: true });
        if (deletedTask) {
          return true;
        }
        else {
          return false;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error updating status for task:", error);
      return null;
    }
  }

  public async updateStatus(id: string, status: string): Promise<Parse.Object | null> {
    const Task = Parse.Object.extend(this.className);
    const query = new Parse.Query(Task);
    try {
      const task = await query.get(id, { useMasterKey: true });
      if (task) {
        task.set('status', status);
        const savedTask = await task.save(null, { useMasterKey: true });
        return savedTask;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error updating status for task:", error);
      return null;
    }
  }

  public async getAllCount(search?: string): Promise<number> {
    try {
      const Task = Parse.Object.extend(this.className);
      const query = new Parse.Query(Task).withCount();
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        if (currentUser.get('role') === UserRole.admin) {
          query.equalTo('organization', currentUser.get('organization'));
        }
        else if (currentUser.get('role') === UserRole.manager) {
          query.equalTo('managedBy', currentUser);
        }
        else {
          query.equalTo('assignedTo', currentUser);
        }
        query.descending('createdAt');
        if (search) {
          query.matches('title', RegExp(search), 'i');
        }
        const tasks = await query.count({ useMasterKey: true });
        return tasks;
      }
      else {
        return 0;
      }

    } catch (error: any) {
      console.error('Error fetching tasks:', error);
      return 0;
    }
  }

  public async getAll(search?: string, page?: number): Promise<Parse.Object[] | null> {
    try {
      const Task = Parse.Object.extend(this.className);
      const query = new Parse.Query(Task);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        if (currentUser.get('role') === UserRole.admin) {
          query.equalTo('organization', currentUser.get('organization'));
        }
        else if (currentUser.get('role') === UserRole.manager) {
          query.equalTo('managedBy', currentUser);
        }
        else {
          query.equalTo('assignedTo', currentUser);
        }
        query.include('assignedTo');
        query.descending('createdAt');
        if (search) {
          query.matches('title', RegExp(search), 'i');
        }
        if (page) {
          query.skip((page - 1) * dataLimit);
        }
        const tasks = await query.find({ useMasterKey: true });
        return tasks;
      }
      else {
        return null;
      }

    } catch (error: any) {
      console.error('Error fetching tasks:', error);
      return null;
    }
  }

  public async getUpcoming(): Promise<Parse.Object[] | null> {
    try {
      const Task = Parse.Object.extend(this.className);
      const query = new Parse.Query(Task);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        if (currentUser.get('role') === UserRole.admin) {
          query.equalTo('organization', currentUser.get('organization'));
        }
        else if (currentUser.get('role') === UserRole.manager) {
          query.equalTo('managedBy', currentUser);
        }
        else {
          query.equalTo('assignedTo', currentUser);
        }
        query.notEqualTo('dueDate', null);
        query.include('assignedTo');
        query.ascending('dueDate');
        query.limit(5);
        const tasks = await query.find({ useMasterKey: true });
        return tasks;
      }
      else {
        return null;
      }

    } catch (error: any) {
      console.error('Error fetching tasks:', error);
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const Task = Parse.Object.extend(this.className);
      const query = new Parse.Query(Task);
      query.include('assignedTo');
      const tasks = await query.get(id, { useMasterKey: true });
      return tasks;

    } catch (error: any) {
      console.error('Error fetching tasks:', error);
      return null;
    }
  }

  public async changeAllManagedByForAssignedTo(assignedTo: string, newManagerId: string): Promise<boolean> {
    const Task = Parse.Object.extend(this.className);
    const query = new Parse.Query(Task).equalTo('assignedTo', assignedTo);
    try {
      const managerUser = Parse.User.createWithoutData(newManagerId);
      const tasks = await query.find({ useMasterKey: true });
      if (tasks) {
        for (let i = 0; i < tasks.length; i++) {
          tasks[i].set('managedBy', managerUser);
          const savedTask = await tasks[i].save(null, { useMasterKey: true });
          if (!savedTask) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
  
}

export default TaskRepository;
