import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Scatter } from 'recharts';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../store/selector';
import { Box, Typography } from '@mui/material';
import { formatMoney } from '../../utils/helpers';

// const data = [
//   {
//     name: 'Q1',
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: 'Q2',
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: 'Q3',
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: 'Q4',
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
  
// ];

interface StackChartData {
  name: string;
  uv: number;
  pv: number;
  amt: number;
}

interface StackChartProps {
  data: StackChartData[];
}



const StackChart = ({data}: StackChartProps) => {

  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box padding={'20px'} bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}>
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{`${label} : ${formatMoney(payload[0].value)}`}</Typography>
        </Box>
      );
    }
  
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 70,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" fontSize={'14px'} />
        <YAxis fontSize={'12px'}/>
        <Tooltip content={<CustomTooltip />}/>
        <Area type="monotone" dataKey="uv" stroke={mode == 'dark' ? globalColors.white : globalColors.black} fill={color || globalColors.blue} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default StackChart;
