import React from 'react';
import { RadialBarChart, RadialBar, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { globalColors } from '../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../store/selector';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

const ddata = [
  {
    name: 'New',
    uv: 31.47,
    pv: 2400,
    fill: '#599BF7',
  },
  {
    name: 'Follow Up',
    uv: 26.69,
    pv: 4567,
    fill: '#F0EDDD',
  },
  {
    name: 'Arrange',
    uv: 15.69,
    pv: 1398,
    fill: '#0455C6',
  },
  {
    name: 'Meet',
    uv: 8.22,
    pv: 9800,
    fill: '#00C0EB',
  },
  {
    name: 'Closed',
    uv: 8.63,
    pv: 3908,
    fill: '#0097A0',
  },
  {
    name: 'Lost',
    uv: 2.63,
    pv: 4800,
    fill: '#6EA3C1',
  },
];

const style = {
  lineHeight: '24px',
};

interface ChartData {
  name: string,
  uv: number,
  pv: number,
  fill: string,
}

interface ChartProps {
  data: ChartData[];
}

const RadialBarchart: React.FC<ChartProps> = ({data}: ChartProps) => {
  // const renderLegendTooltip = (value: string, entry: any) => {
  //   if (entry && entry.payload) {
  //     const percentage = `${(entry.payload.uv * 100).toFixed(2)}%`;
  //     return <span>{`${value} (${percentage})`}</span>;
  //   }

  //   return null;
  // };
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box padding={'20px'} bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}>
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{`${data[label].uv}`}</Typography>
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{`${data[label].name}`}</Typography>
        </Box>
      );
    }
  
    return null;
  };
  const mode = useSelector(selectThemeMode);

  return (
    <div
      style={{
        height: '230px',
        width: '100%',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <RadialBarChart cx="68%" cy={110} innerRadius="10%" outerRadius="110%" barSize={20} data={data}>
          <RadialBar
            legendType="circle"
            // label={{ position: "insideStart", fill: "#333333" }}
            background={{ fill: mode === 'dark' ? globalColors.border.gray : globalColors.white }}
            dataKey="uv"
          />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
          <Legend
            iconSize={10}
            layout="vertical"
            verticalAlign="middle"
            align="left"
            wrapperStyle={style}
            // content={renderLegendTooltip as LegendProps['content']}
          />
        </RadialBarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RadialBarchart;
