import { Box, Button, FormGroup, InputLabel, Modal, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../../../utils/constants/color';
import { InputStyle } from './formEmployee.style';

interface ModalCommunicateProps {
  open: boolean;
  mode: string;
  color: string;
  id: string;
  data?: any;
  onClose: () => void;
}

export interface FormValues {
  notes: string;
  field1: string;
  field2: string;
}

const ModalReport = ({ open, onClose, mode, color, id, data }: ModalCommunicateProps) => {
  const [formValues, setFormValues] = useState<FormValues>({ notes: '', field1: '', field2: '' });
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '525px',
    maxHeight: '440px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-add-amenities" aria-describedby="modal-request-add-amenities" className="content">
      <Box sx={style} height={'65vh'} className="content">
        <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
          <Typography
            sx={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 500, lineHeight: '30px' }}
            color={mode === 'dark' ? globalColors.white : globalColors.blackLight}
          >
            Notes
          </Typography>
        </Box>

        <Box>
          {/* Note */}
          <form>
            <Box display={'flex'} flexDirection={'column'} component={'span'} paddingTop={'15.375px'}>
              <textarea
                style={{
                  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                  borderRadius: '8px',
                  border: `2px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.gray}`,
                  padding: '10px',
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                }}
                className="textarea"
                placeholder="Type your note here"
                id="note"
                name="note"
                rows={3}
              ></textarea>
            </Box>

            <FormGroup className="mb-4 text-start" sx={{ width: '100% ' }}>
              {/* Field 1 */}
              <InputLabel
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '28px',
                  letterSpacing: '-2%',
                  color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
                  marginTop: '2%',
                }}
                htmlFor="field1Input"
              >
                DEC 5, 2023
              </InputLabel>
              <InputStyle
                mode={mode}
                required
                name="field1"
                type="text"
                id="field1Input"
                placeholder="Enter"
                value={formValues.field1}
                onChange={handleChange}
              />

              {/* Field 2 */}
              <InputLabel
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '28px',
                  letterSpacing: '-2%',
                  color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
                  marginTop: '2%',
                }}
                htmlFor="field2Input"
              >
                DEC 6, 2023
              </InputLabel>
              <InputStyle
                mode={mode}
                required
                name="field2"
                type="text"
                id="field2Input"
                placeholder="Enter"
                value={formValues.field2}
                onChange={handleChange}
              />
            </FormGroup>

            {/* Button */}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '6%' }}>
              <ButtonStyle type="reset" style={{ marginLeft: '10px' }} color={color} mode={mode} onClick={onClose}>
                Cancel
              </ButtonStyle>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  background:
    color !== 'default' && color !== ''
      ? color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.blackLight
        : globalColors.whiteLight
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '240px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default ModalReport;
