import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import LoginPage from '../views/auth/login';
import Project from '../views/project';
import CreateProject from '../views/project/createProject';
import EditProject from '../views/project/editProject';
import Property from '../views/property';
import AddLeads from '../views/leads/addLeads';
import ViewLeads from '../views/leads/viewLeads';
import EditProperty from '../views/property/editProperty';
import CreateProperty from '../views/property/createProperty';
import Calendar from '../views/calendar';
import AuthGuard from '../components/atoms/authGuard';
import EditLeads from '../views/leads/editLeads';
import LandingPage from '../views/landingPage';
import Email from '../views/email';
import SendNewEmail from '../views/email/sendEmail';
import SocialMedia from '../views/socialMedia';
import Setting from '../views/setting/importData';
import LeadSetting from '../views/setting/leadSettings';
import CommissionSetting from '../views/setting/commissionSettings';
import IntegrationSettings from '../views/setting/integrationSettings';
import NotificationSettings from '../views/setting/notificationSettings';
import Alerts from '../views/alerts/alerts';
import ProjectPerfomance from '../components/molecules/perfomance/project/project';
import PropertyPerfomance from '../components/molecules/perfomance/property/property';
import CompanyPerfomance from '../views/perfomance/companyPerfomance';
import ScheduleNewEmail from '../views/email/scheduleEmail';
import PrintReport from '../components/molecules/perfomance/company/employee/printReport';
import AlertsNewGroup from '../views/alerts/createGroup';
import LeadsInterest from '../components/molecules/leads';
import CommissionNewTeam from '../views/setting/commissionNewTeam';
import EditEmployee from '../components/molecules/settings/Commission/editEmployee';
import EditEmployeeContainer from '../components/molecules/settings/Commission/editEmployeeContainer';
import Dashboard from '../views/dashboard/dashboard';
import Onboarding from '../views/onboarding/information';
import OnboardingTier from '../views/onboarding/tier';
import OnboardingPayment from '../views/onboarding/payments';
import OnboardingConfirmation from '../views/onboarding/confirmation';
import AccountSetup from '../views/onboarding/accountSetup';
import SupportSettings from '../views/setting/supportSettings';
import PermissionSettings from '../views/setting/permissionSettings';
import ActivityComponent from '../views/task/activityComponent';
import ActivityTasks from '../views/task/activityTasks';
import TermsAndConditions from '../components/organisms/landingPage/termsAndConditions';
import PrivacyPolicy from '../components/organisms/landingPage/privacyPolicy';
import AddMember from '../components/molecules/perfomance/company/addMember';
import ManageOrganization from '../components/molecules/perfomance/company/manageOrganization';
import PaymentSettings from '../views/setting/paymentSettings';
import EmployeePayroll from '../components/molecules/hr/members';
import EmployeeProfile from '../components/molecules/perfomance/profile/employee';
import ConversationPerfomance from '../views/perfomance/conversationPerformance';
import OrganizationProfile from '../components/molecules/perfomance/profile/organization';
import ResetPassword from '../views/resetPassword';
import ChangePlanSettings from '../views/setting/changePlanSettings';
import UpgradePayment from '../views/setting/upgradePayment';
import UpgradePaymentConfirmation from '../views/setting/upgradePaymentConfirmation';
import PendingPayment from '../views/auth/pendingPayment';
import LoginError from '../components/atoms/loginError';
import PendingPaymentConfirmation from '../views/auth/pendingPaymentConfirmation';
import ChangePlan from '../views/auth/changePlan';
import ActionRequired from '../views/auth/actionRequired';
import SetupEmail from '../views/email/setupEmail';
import ConversationPage from '../components/molecules/perfomance/conversions/conversionPage';
import PageNotFound from '../components/atoms/pageNotFound';
import Profile from '../components/organisms/profile';
import ViewClients from '../views/clients/viewClients';
import AddClients from '../views/clients/addClients';
import EditClients from '../views/clients/editClients';
import Conversions from '../components/molecules/perfomance/conversions';
import ConversionRequests from '../components/molecules/perfomance/conversions/conversionRequests';
import Inventory from '../views/inventory';
import SelectProject from '../views/inventory/selectProject';
import RequestDemo from '../views/landingPage/requestDemo';

export const navigation = createBrowserRouter([
  {
    element: <PageNotFound />,
    path: '*',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Dashboard />
      </AuthGuard>
    ),
    path: '/dashboard',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={true} shouldNavigateToLogin={false}>
        <LoginPage />
      </AuthGuard>
    ),
    path: '/login',
  },
  {
    element: <LoginError />,
    path: '/accessLimited/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <PendingPayment />
      </AuthGuard>
    ),
    path: '/renewal/secure/payment/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <PendingPaymentConfirmation />
      </AuthGuard>
    ),
    path: '/renewal/secure/payment/confirmation',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ChangePlan />
      </AuthGuard>
    ),
    path: '/renewal/changePlan',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ActionRequired />
      </AuthGuard>
    ),
    path: '/actionRequired',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AddLeads />
      </AuthGuard>
    ),
    path: '/leads/new',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ViewLeads />
      </AuthGuard>
    ),
    path: '/leads',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <EditLeads />
      </AuthGuard>
    ),
    path: '/leads/edit/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AddClients />
      </AuthGuard>
    ),
    path: '/clients/new',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ViewClients />
      </AuthGuard>
    ),
    path: '/clients',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <EditClients />
      </AuthGuard>
    ),
    path: '/clients/edit/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <SelectProject />
      </AuthGuard>
    ),
    path: '/inventory',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Inventory />
      </AuthGuard>
    ),
    path: '/inventory/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Project />
      </AuthGuard>
    ),
    path: '/projects',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <CreateProject />
      </AuthGuard>
    ),
    path: '/projects/new',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <EditProject />
      </AuthGuard>
    ),
    path: '/projects/edit/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Property />
      </AuthGuard>
    ),
    path: '/properties',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <CreateProperty />
      </AuthGuard>
    ), // TODO : [WIP] fix soon
    path: '/properties/new',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <EditProperty />
      </AuthGuard>
    ),
    path: '/properties/edit/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Calendar />
      </AuthGuard>
    ),
    path: '/schedule',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Email />
      </AuthGuard>
    ),
    path: '/email',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <SetupEmail />
      </AuthGuard>
    ),
    path: '/email/setup',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <SendNewEmail />
      </AuthGuard>
    ),
    path: '/email/new',
  },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
  //       <ScheduleNewEmail />
  //     </AuthGuard>
  //   ),
  //   path: '/email/schedule',
  // },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <SocialMedia />
      </AuthGuard>
    ),
    path: '/socialmedia',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Setting />
      </AuthGuard>
    ),
    path: '/settings',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Setting />
      </AuthGuard>
    ),
    path: '/settings/import',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <LeadSetting />
      </AuthGuard>
    ),
    path: '/settings/leads',
  },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
  //       <CommissionSetting />
  //     </AuthGuard>
  //   ),
  //   path: '/settings/commission',
  // },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
  //       <CommissionNewTeam />
  //     </AuthGuard>
  //   ),
  //   path: '/settings/commission/newTeam',
  // },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
  //       <IntegrationSettings />
  //     </AuthGuard>
  //   ),
  //   path: '/settings/integration',
  // },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <NotificationSettings />
      </AuthGuard>
    ),
    path: '/settings/notifications',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <PropertyPerfomance />
      </AuthGuard>
    ),
    path: '/performance/properties',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ProjectPerfomance />
      </AuthGuard>
    ),
    path: '/performance/projects',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <CompanyPerfomance />
      </AuthGuard>
    ),
    path: '/performance/company',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ManageOrganization />
      </AuthGuard>
    ),
    path: '/performance/company/manage',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AddMember />
      </AuthGuard>
    ),
    path: '/hr/members/addMember',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <CompanyPerfomance />
      </AuthGuard>
    ),
    path: '/performance/company/report',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <PrintReport />
      </AuthGuard>
    ),
    path: '/performance/company/report/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Alerts />
      </AuthGuard>
    ),
    path: '/alerts',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AlertsNewGroup />
      </AuthGuard>
    ),
    path: '/alerts/newGroup',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <LeadsInterest />
      </AuthGuard>
    ),
    path: '/leads/interests',
  },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
  //       <EditEmployeeContainer />
  //     </AuthGuard>
  //   ),
  //   path: '/settings/commission/edit/:id',
  // },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <SupportSettings />
      </AuthGuard>
    ),
    path: '/settings/support',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <PermissionSettings />
      </AuthGuard>
    ),
    path: '/settings/permissions',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <PaymentSettings />
      </AuthGuard>
    ),
    path: '/settings/payments',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ChangePlanSettings />
      </AuthGuard>
    ),
    path: '/settings/payments/changePlan',
  },
  {
    element: <UpgradePayment />,
    path: '/upgradeRequest/secure/payment/:id',
  },
  {
    element: <UpgradePaymentConfirmation />,
    path: '/upgradeRequest/secure/payment/confirmation',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ActivityComponent />
      </AuthGuard>
    ),
    path: '/activity/task/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ActivityTasks />
      </AuthGuard>
    ),
    path: '/activity/tasks',
  },
  {
    element: <LandingPage />,
    path: '/',
  },
  {
    element: <TermsAndConditions />,
    path: '/policies/termsAndConditions',
  },
  {
    element: <PrivacyPolicy />,
    path: '/policies/privacy',
  },
  {
    element: <Onboarding />,
    path: '/onboarding',
  },
  {
    element: <OnboardingTier />,
    path: '/onboarding/tier',
  },
  {
    element: <OnboardingPayment />,
    path: '/onboarding/secure/payment/:id',
  },
  {
    element: <OnboardingConfirmation />,
    path: '/onboarding/secure/payment/confirmation',
  },
  {
    element: <ResetPassword />,
    path: '/resetPassword',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AccountSetup />
      </AuthGuard>
    ),
    path: '/accountSetup',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={false}>
        <EmployeePayroll />
      </AuthGuard>
    ),
    path: '/hr/members',
  },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={false}>
  //       <AdminDashboard />
  //     </AuthGuard>
  //   ),
  //   path: '/admin/dashboard',
  // },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={false}>
  //       <OrganizationHistory />
  //     </AuthGuard>
  //   ),
  //   path: '/performance/organization/history/:id',
  // },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={false}>
        <EmployeeProfile />
      </AuthGuard>
    ),
    path: '/hr/member/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <ConversationPage />
      </AuthGuard>
    ),
    path: 'conversions/page/:id',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={false}>
        <Conversions />
      </AuthGuard>
    ),
    path: '/conversions',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={false}>
        <ConversionRequests />
      </AuthGuard>
    ),
    path: '/conversions/requests',
  },
  // {
  //   element: (
  //     <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
  //       <OrganizationProfile />
  //     </AuthGuard>
  //   ),
  //   path: '/performance/organization/profile/:id',
  // },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <Profile />
      </AuthGuard>
    ),
    path: '/profile',
  },
  {
    element: (
      <RequestDemo />
    ),
    path: '/requestDemo',
  },
]);
