import { useNavigate } from 'react-router-dom';
import { IFormDetailProject, ProjectFilterDataFtn, onShowModalBulk } from '../../../store/projects';
import { FilterDataFtn, IFormDetailProperty } from '../../../store/property';
import { ListInventoryProps, ListProductsProps, ProductCategories } from '../../../utils/types/products';
import CardProductItem from '../../molecules/products/cardProductItem';
import { CardProductsStyle } from './cardInventory.style';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Typography } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import ModalBulkEditProduct from './modalBulkEditInventory';
import NotFoundLottie from '../../atoms/notFound';
import { UserRole } from '../../../store/user';
import { NavAddFilterView } from '../../molecules';
import {
  selectBulkEdit,
  selectColor,
  selectInventoryFilter,
  selectProjectFilter,
  selectPropertiesFilter,
  selectSearchInventory,
  selectSearchProject,
  selectSearchProperties,
  selectShowModalBulkEdit,
  selectThemeMode,
  selectUserData,
  selectUserRole,
} from '../../../store/selector';
import Loader from '../../atoms/loader';
import CardPropertyItem from '../../molecules/products/cardPropertyItem';
import { setAddNew } from '../../../store/routes';
import { useEffect, useState } from 'react';
import { InventoryFilterDataFtn } from '../../../store/inventory';
import CardInventoryItem from '../../molecules/products/cardInventoryItem';
import Paginator from '../../atoms/paginator';
import UserRepository from '../../../utils/api/repositories/userRepository';
import UserModel from '../../../utils/api/models/userModel';
import ModalBulkEditInventory from './modalBulkEditInventory';

/**
 * Renders the CardProjects component.
 * ! This component can be used for Projects and Properties
 *
 * @return {JSX.Element} The rendered CardProjects component.
 */
const CardInventory = (props: ListInventoryProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>([]);
  const edit = useSelector(selectBulkEdit);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const showModalBulkEdit = useSelector(selectShowModalBulkEdit);
  const user = useSelector(selectUserData);
  const userRole = useSelector(selectUserRole);
  const filterData = useSelector(selectInventoryFilter);
  const search = useSelector(selectSearchInventory);

  const getOrganizationMembers = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const allMembers = await userRepository.getAllUsersForOrganizationIncludingCurrentUser();
      if (allMembers) {
        setOrganizationMembers(allMembers);
      }
    }
  };

  useEffect(() => {
    getOrganizationMembers();
  }, []);

  const isCanEdit = user?.role !== UserRole.executive;

  return (
    <>
      <NavAddFilterView
        onlyShowAddButton={props.datas.length === 0}
        onChangeView={props.handleSwitchView}
        view={props.view}
        property={false}
        projectComponent
        onClickNewProduct={props.onClickNewProduct}
        titleButton={''}
      />
      {props?.isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography
            sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
            onClick={() => dispatch(InventoryFilterDataFtn({ status: '', type: '', purpose: '' }))}
          >
            {filterData.status !== '' || filterData.type !== '' || filterData.purpose !== '' ? 'Clear Filter' : ''}
          </Typography>
          {props.datas.length > 0 ? (
            <CardProductsStyle>
              {edit && (
                <Checkbox
                  sx={{
                    color: color || globalColors.blue,
                    '&.Mui-checked': {
                      color: color || globalColors.secondBlue,
                    },
                  }}
                  onChange={props.handleHeaderCheckboxChange}
                  checked={Object.values(props.rowCheckboxState).length > 0 && Object.values(props.rowCheckboxState).every((isChecked) => isChecked)}
                />
              )}
              <div className="content">
                {/* RENDER INVENTORY */}
                {props.datas?.map((data) => (
                  <CardInventoryItem
                    edit={edit}
                    mode={props.mode}
                    key={data.objectId}
                    data={data}
                    isCanEdit={isCanEdit}
                    rowCheckboxState={props.rowCheckboxState}
                    setRowCheckboxState={props.setRowCheckboxState}
                    active={true}
                    organizationMembers={organizationMembers}
                  />
                ))}
              </div>
            </CardProductsStyle>
          ) : (
            <NotFoundLottie
              showButton={userRole !== UserRole.executive}
              text={
                userRole !== UserRole.executive
                  ? 'Looks like you have not added any inventory for this project yet. Press the button below and add a new project right now.'
                  : 'Looks like your company has not added any inventory for this project yet. Contact your manager or admin to add a new project.'
              }
              buttonText="Add New Project"
              navigateTo="/projects/new"
              setAddNewPath="/inventory"
            />
          )}
          <Paginator
            getTotalCount={props.getTotalCount}
            onChangePage={async (newPage) => {
              await props.onChangePage(newPage);
              return true;
            }}
          />
        </>
      )}
      <ModalBulkEditInventory
        rowCheckboxState={props?.rowCheckboxState}
        open={showModalBulkEdit}
        onClose={() => dispatch(onShowModalBulk(false))}
        mode={mode}
        color={color}
      />
    </>
  );
};

export default CardInventory;
