import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectColor, selectThemeMode } from '../../store/selector';
import { useDispatch } from 'react-redux';
import { globalColors } from '../../utils/constants/color';
import { Toast } from './Toast';
import Loader from './loader';
import { dataLimit } from '../../utils/helpers';

interface PaginatorProps {
  getTotalCount: () => Promise<number>;
  onChangePage: (page: number) => Promise<boolean>;
  activePage?: number;
  internal?: boolean;
}

const Paginator: React.FC<PaginatorProps> = ({ getTotalCount, onChangePage, activePage, internal = false }) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(activePage ?? parseInt(page ?? '1'));
  const [pagesArray, setPagesArray] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [maxPages, setMaxPages] = useState(0);

  const setup = async () => {
    const count = await getTotalCount();
    setMaxPages(Math.ceil(count / dataLimit));
    setLoading(false);
  };

  const navigateToPage = async (page: number) => {
    const changePageSuccess = await onChangePage(page);
    if (changePageSuccess) {
      if (!internal) {
        const baseURL = location;
        navigate(`${baseURL.pathname}?page=${page}`);
      }
      setCurrentPage(page);
    } else {
      Toast('An error occured. Please try again.', 'error');
    }
  };

  function generatePagesArray(page: number, maxPages: number): number[] {
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(maxPages, currentPage + 1);

    if (endPage - startPage < 2) {
      if (startPage === 1) {
        endPage = Math.min(maxPages, startPage + 2);
      } else if (endPage === maxPages) {
        startPage = Math.max(1, endPage - 2);
      }
    }

    const pagesArray = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    return pagesArray;
  }

  useEffect(() => {
    setPagesArray(generatePagesArray(currentPage, maxPages));
  }, [currentPage, maxPages]);

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    setCurrentPage(activePage ?? parseInt(page ?? '1'));
  }, [page]);

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'end'} gap={'10px'} paddingTop={'2rem'}>
      {loading ? (
        // <Loader />
        <></>
      ) : (
        <>
          {currentPage > 1 && (
            <Box
              sx={{
                minWidth: '25px',
                minHeight: '25px',
                display: 'flex',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                color: color || globalColors.blue,
                border: `1px solid ${color || globalColors.blue}`,
                borderRadius: '10px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: color || globalColors.blue,
                  color: mode === 'dark' ? globalColors.black : globalColors.white,
                },
              }}
              onClick={() => {
                navigateToPage(currentPage - 1);
              }}
            >
              <Typography sx={{ fontSize: '16px' }}>Prev</Typography>
            </Box>
          )}
          {pagesArray.map((p, idx) => (
            <Box
              sx={{
                minWidth: '25px',
                minHeight: '25px',
                display: 'flex',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                color: currentPage === p ? (mode === 'dark' ? globalColors.black : globalColors.white) : (color || globalColors.blue),
                border: `1px solid ${color || globalColors.blue}`,
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: currentPage === p ? (color || globalColors.blue) : null,
                '&:hover': {
                  backgroundColor: color || globalColors.blue,
                  color: mode === 'dark' ? globalColors.black : globalColors.white,
                },
              }}
              onClick={() => {
                if (currentPage !== p) {
                  navigateToPage(p);
                }
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: currentPage === p ? 700 : 400 }}>{p}</Typography>
            </Box>
          ))}

          {maxPages > 1 && currentPage < maxPages && (
            <Box
              sx={{
                minWidth: '25px',
                minHeight: '25px',
                display: 'flex',
                padding: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                color: color || globalColors.blue,
                border: `1px solid ${color || globalColors.blue}`,
                borderRadius: '10px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: color || globalColors.blue,
                  color: mode === 'dark' ? globalColors.black : globalColors.white,
                },
              }}
              onClick={() => {
                navigateToPage(currentPage + 1);
              }}
            >
              <Typography sx={{ fontSize: '16px' }}>Next</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Paginator;
