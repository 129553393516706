import React from 'react';
import * as Yup from 'yup';

const useRegister = () => {
  const validationSchema = Yup.object({
    firstName: Yup.string().trim().required('Required'),
    lastName: Yup.string().trim().required('Required'),
    companyName: Yup.string().trim().required('Required'),
    companyAddress: Yup.string().trim().required('Required'),
    numberOfEmployees: Yup.number().required('Required'),
    companyRegistrationYear: Yup.string().trim().required('Required'),
    // ownerName: Yup.string().required('Required'),
    // contractOfOwner: Yup.string().required('Required'),
    companyPhoneNumber: Yup.string().trim().required('Required'),
    // secondNumber: Yup.string().required('Required'),
    pointOfContact: Yup.string().trim().required('Required'),
    contactDesignation: Yup.string().trim().required('Required'),
    // companyWebsite: Yup.string().trim().required('Required'),
    // aboutUs: Yup.string().required('Required'),
    // email: Yup.string().email('Invalid email address').required('Email is required'),
    logo: Yup.string().trim().required('Required'),
  });

  return { validationSchema };
};

export default useRegister;
