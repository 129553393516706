import { Box, styled } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/atoms/button';
import { EnumViews, onShowModalBulk, SearchProject } from '../../store/projects';
import { globalColors } from '../../utils/constants/color';
import { SearchInput } from '../atoms';
import BulkEditLeads from '../organisms/leads/bulkEdit';
import { UserRole } from '../../store/user';
import { selectColor, selectThemeMode, selectSearchProject, selectSearchProperties, selectSearchInventory } from '../../store/selector';
import FilterProperties from '../organisms/product/filterProperties';
import FilterProject from '../organisms/product/filterProject';
import { SearchProperty } from '../../store/property';
import ViewProperty from './property/viewProperty';
import ButtonView from './buttonView';
import { useRef } from 'react';
import { SearchInventory } from '../../store/inventory';
import FilterInventory from '../organisms/product/filterInventory';

interface NavAddFilterViewProps {
  onChangeView: (view: EnumViews) => void;
  titleButton: string;
  onClickNewProduct: () => void;
  defaultView?: EnumViews;
  view: EnumViews;
  onlyShowAddButton: boolean;
  property: boolean;
  projectComponent?: boolean;
  showViewSwitch?: boolean;
  showBulkEdit?: boolean;
}

const NavAddFilterView = ({
  onChangeView,
  titleButton,
  onClickNewProduct,
  defaultView,
  view,
  onlyShowAddButton = false,
  property,
  projectComponent = false,
  showViewSwitch = true,
  showBulkEdit = true
}: NavAddFilterViewProps) => {
  const dispatch = useDispatch();
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const userRole = useSelector(selectThemeMode);
  const isCanEdit = userRole !== UserRole.executive;
  const Projectsearch = useSelector(selectSearchProject);
  const Propertiessearch = useSelector(selectSearchProperties);
  const Inventorysearch = useSelector(selectSearchInventory);

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    projectComponent
      ? dispatch(SearchInventory(event.target.value))
      : property
      ? dispatch(SearchProperty(event.target.value))
      : dispatch(SearchProject(event.target.value));
  };

  const useDebounce = (callback: Function, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    return (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const debouncedHandleSearch = useDebounce(handleSearch, 300);
  if (onlyShowAddButton) {
    return (
      <NavMenuStyle>
        {isCanEdit && (
          <Box width={{ xs: '100%', md: 'auto' }} display={'flex'} justifyContent={{ xs: 'center', sm: 'start' }}>
            {titleButton && (
              <Button className="btn-add" onClick={onClickNewProduct}>
                {titleButton}
              </Button>
            )}
          </Box>
        )}
        {Propertiessearch !== '' || Projectsearch !== '' || Inventorysearch !== '' ? (
          <SearchInput
            value={projectComponent ? Inventorysearch : property ? Propertiessearch : Projectsearch}
            onChange={handleSearch}
            className="input-search"
            placeholder={projectComponent ? 'Search Inventory' : property ? 'Search Property' : 'Search Projects'}
          />
        ) : (
          <Box></Box>
        )}
      </NavMenuStyle>
    );
  }
  return (
    <NavMenuStyle>
      <div className="filter-wrapper">
        {isCanEdit && (
          <Box width={{ xs: '100%', md: 'auto' }} display={'flex'} justifyContent={{ xs: 'center', sm: 'start' }}>
            {titleButton && (
              <Button className="btn-add" onClick={onClickNewProduct}>
                {titleButton}
              </Button>
            )}
          </Box>
        )}
        <Box component={'span'} display={'flex'} gap={'20px'} flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
          <Box display={'flex'} gap={'20px'} justifyContent={{ xs: 'space-between' }} width={'100%'} marginTop={{ xs: '10px', sm: '0px' }}>
            {showBulkEdit && <BulkEditLeads
              color={color}
              bgcolor={mode === 'dark' ? globalColors.black : globalColors?.lightgray}
              setOpenBulkEditLeadModal={() => dispatch(onShowModalBulk(true))}
            />}
            <SearchInput
              value={projectComponent ? Inventorysearch : property ? Propertiessearch : Projectsearch}
              onChange={handleSearch}
              className="input-search"
              placeholder={projectComponent ? 'Search Inventory' : property ? 'Search Property' : 'Search Projects'}
            />
          </Box>
          <Box display={'flex'} width={'100%'} justifyContent={'space-between'} gap={'20px'}>
            {projectComponent ? <FilterInventory /> : property ? <FilterProperties /> : <FilterProject />}
            {!property
              ? showViewSwitch && <ButtonView className="btn-view" onChangeView={onChangeView} defaultView={defaultView} view={view} />
              : showViewSwitch && <ButtonView className="btn-view" onChangeView={onChangeView} defaultView={defaultView} view={view} />}
          </Box>
        </Box>
      </div>
    </NavMenuStyle>
  );
};
const NavMenuStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  '& .filter-wrapper': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
  },

  [theme.breakpoints.down('md')]: {
    gap: '1rem',
    '& .filter-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      '& .btn-view, .filter': {
        width: '50%',
      },
    },
    '& .input-search': {
      width: '100%',
    },
  },

  [theme.breakpoints.down('sm')]: {
    '& .filter-wrapper': {
      flexDirection: 'column',
      gap: '8px',
    },
  },
}));

export default NavAddFilterView;
