import React, { useEffect, useState } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { selectNavigationLayout, selectThemeMode, selectColor, selectOrganizationTier } from '../../store/selector';
import { styled } from '@mui/material';
import { FormGroup, InputLabel, useTheme, IconButton } from '@mui/material';
import { FormAlertsStyle, InputStyle, crossFileContainerStyle, iconButtonStyle, imageNameStyle } from './alerts.style';
import { ThemeProvider } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import FileIcon from '../../components/molecules/communication/alert/FileIcon';
import AttachmentUpload from '../../components/molecules/communication/alert/AttachmentUpload';
import EmployeeDropdown from '../../components/molecules/communication/alert/employeeDropDown';
import UserRepository from '../../utils/api/repositories/userRepository';
import AlertRepository from '../../utils/api/repositories/alertsRepository';
import AlertModel from '../../utils/api/models/alertsModel';
import io from 'socket.io-client';
import { Button, Toast } from '../../components/atoms';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAddNew } from '../../store/routes';
import AlertGroupRepository from '../../utils/api/repositories/alertGroupRepository';
import { PricingTiers, removeDuplicates } from '../../utils/helpers';
import NotificationRepository from '../../utils/api/repositories/notificationRepository';
import NotificationModel from '../../utils/api/models/notificationModel';
import { UserRole } from '../../store/user';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import InsufficientPlan from '../../components/atoms/insufficientPlan';

const Icons = ['All', 'Select Groups', 'Select Individuals'];

export interface FormValues {
  title: string;
  message: string;
  Attachment: any;
}

const Alerts = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [selectedIcons, setSelectedIcons] = useState<string[]>(['All']);
  const [names, setNames] = useState<any[]>([]);
  const [groupNames, setGroupNames] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({ title: '', message: '', Attachment: '' });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | ''>('');
  const [selectedNames, setSelectedNames] = useState<string[] | null>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const organizationTier = useSelector(selectOrganizationTier);
  const [loading, setLoading] = useState(true);
  const [selectedPriority, setSelectedPriority] = useState('Medium');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setFormValues({ title: '', message: '', Attachment: '' });
    setSelectedIcons([]);
  };

  const handleIconClick = (icon: string) => {
    // Clear the selectedIcons array and then add the clicked icon
    setSelectedNames([]);
    setSelectedIcons([icon]);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);
    var selectedIds: string[] = [];
    if (selectedNames) {
      selectedIds = selectedNames?.map((i) => JSON.parse(i).id);
    }

    if (selectedIds) {
      var userIds: string[] = [];
      // EMPLOYEES SELECTED
      if (selectedIcons[0] === Icons[2]) {
        userIds = selectedIds;
      }
      // GROUPS SELECTED
      else if (selectedIcons[0] === Icons[1]) {
        const alertGroupRepository = new AlertGroupRepository();
        for (let i = 0; i < selectedIds.length; i++) {
          const members = await alertGroupRepository.getAllMembersForAlertGroup(selectedIds[i]);
          if (members) {
            for (let j = 0; j < members.length; j++) {
              userIds.push(members[i].id);
            }
          }
        }
      }
      // ALL SELECTED
      else {
        const userRepository = new UserRepository();
        const allUsers = await userRepository.getAllUsersForOrganization();
        if (allUsers) {
          for (let i = 0; i < allUsers.length; i++) {
            if (allUsers[i].objectId) {
              userIds.push(allUsers[i].objectId!);
            }
          }
        }
      }
      let uniqueUserIds = removeDuplicates(userIds);

      const notificationRepository = new NotificationRepository();
      var error = false;
      for (let i = 0; i < uniqueUserIds.length; i++) {
        const notification: NotificationModel = {
          title: formValues.title,
          body: formValues.message,
          recipient: uniqueUserIds[i],
          priority: selectedPriority
        };

        const newNotification = await notificationRepository.create(notification);
        if (!newNotification) {
          error = true;
        }
      }

      if (!error) {
        Toast('Notifications sent successfully.', 'success');
      } else {
        Toast('All notifications could not be sent successfully. Please try again.', 'error');
      }

      resetForm();
      setSelectedNames([]);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    // Reset all state variables to their initial values or empty state
    setSelectedIcons([]);
    setFormValues({ title: '', message: '', Attachment: '' });
    setSelectedFile(null);
    setSelectedFileName('');
    setSelectedNames([]);
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
  };

  const setColor = (val: any) => {
    if (color !== 'default' && color !== '') {
      if (selectedIcons.includes(val)) {
        return color;
      } else if (mode === 'dark') {
        return 'transparent';
      } else if (mode === 'light') {
        return 'transparent';
      }
    } else {
      if (selectedIcons.includes(val)) {
        return globalColors.blue;
      } else if (mode === 'dark') {
        return 'transparent';
      } else if (mode === 'light') {
        return 'transparent';
      }
    }
  };

  const handleTextColor = () => {
    if (mode === 'dark') {
      return globalColors.white;
    } else if (mode === 'light') {
      return globalColors.black;
    }
  };

  const fetchAllUsers = async () => {
    const repository = new UserRepository();
    const currentUser = repository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const userData = await repository.getAllUsersForOrganization();
    const uniqueNames = Array.from(new Set(userData?.map((user) => ({ name: `${user.firstName} ${user.lastName}`, id: user.objectId }))));
    setNames(uniqueNames);
    setLoading(false);
  };

  const fetchAllGroups = async () => {
    const repository = new AlertGroupRepository();
    const alertGroupsData = await repository.getAllAlertGroupsForOrganization();
    const uniqueNames = Array.from(new Set(alertGroupsData?.map((alertGroup) => ({ name: alertGroup.name, id: alertGroup.objectId }))));
    setGroupNames(uniqueNames);
  };

  useEffect(() => {
    fetchAllUsers();
    fetchAllGroups();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Alerts'} />
        </div>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission && organizationTier == PricingTiers.Professional ? (
            <Box>
              <Box paddingTop={3} paddingLeft={3} paddingRight={5} paddingBottom={1}>
                <Button
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: '/alerts' }));
                    navigate('/alerts/newGroup');
                  }}
                >
                  Create New Group
                </Button>
              </Box>
              <FormAlertsStyle onSubmit={handleSubmit}>
                <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
                  <Box marginBottom={'10px'}></Box>
                  {/* Assignment Container */}
                  <AssignmentContainer
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    padding={'20px'}
                    borderRadius={'16px'}
                    color={globalColors.white}
                    fontSize={'12px'}
                    boxShadow={'#171717'}
                    marginTop={'1rem'}
                  >
                    {' '}
                    {/* Audience Assignment */}
                    <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                      <Typography
                        component="div"
                        fontFamily={'Poppins'}
                        fontWeight={'400'}
                        variant="h5"
                        lineHeight={'32px'}
                        color={mode === 'dark' ? globalColors?.white : globalColors.black}
                      >
                        Audience
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '2%',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* Select */}
                      <Box
                        display={'flex'}
                        sx={{
                          flexDirection: { xs: 'column', md: 'row' },
                        }}
                        gap={'10px'}
                        justifyContent={'space-between'}
                        alignItems={'start'}
                        // flexWrap={'wrap'}
                      >
                        {Icons.map((val, index) => (
                          <Box
                            key={val}
                            component={'span'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            gap={'20px'}
                            // bgcolor={selectedIcons.includes(val) ? globalColors.blue : globalColors.black}
                            border={`2px solid ${setColor(val)}`}
                            bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                            borderRadius={'8px'}
                            padding={'1rem'}
                            // color={globalColors.white}
                            color={handleTextColor}
                            onClick={() => handleIconClick(val)}
                            sx={{ cursor: 'pointer', marginLeft: index === 1 || index === 2 ? { md: '20px' } : '' }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '21px',
                                textAlign: 'center',
                              }}
                            >
                              {val}
                            </Typography>
                          </Box>
                        ))}
                      </Box>

                      {/* Search */}
                      {selectedIcons[0] === Icons[2] && (
                        <Box>
                          <EmployeeDropdown names={names} mode={mode} selectedNames={selectedNames} setSelectedNames={setSelectedNames} />
                        </Box>
                      )}
                      {selectedIcons[0] === Icons[1] && (
                        <Box>
                          <EmployeeDropdown names={groupNames} mode={mode} selectedNames={selectedNames} setSelectedNames={setSelectedNames} />
                        </Box>
                      )}
                    </Box>
                    {/* 2nd Box */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '30px',
                      }}
                    >
                      {/* Select */}
                      <Box
                        display={'flex'}
                        sx={{
                          flexDirection: { xs: 'column', sm: 'row' },
                          // marginTop: '5%',
                          width: '100%',
                        }}
                        gap={'10px'}
                        justifyContent={'start'}
                        flexWrap={'wrap'}
                        rowGap={'20px'}
                      >
                        {selectedNames?.map((e) => {
                          return (
                            <Box
                              sx={{
                                padding: '8px',
                                borderRadius: '12px',
                                transition: 'opacity 0.3s ease',
                                border: `1px solid ${color ? color : globalColors.whiteBorder}`,
                                backgroundColor: mode === 'dark' ? globalColors?.black : globalColors.white,
                                color: mode === 'dark' ? globalColors?.white : globalColors.black,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <span style={{ fontFamily: 'Poppins', fontWeight: '400', fontSize: '11px', padding: '10px' }}>
                                {JSON.parse(e).name}
                              </span>
                              <ClearIcon
                                sx={{ cursor: 'pointer', fontSize: '16px' }}
                                onClick={() => setSelectedNames(selectedNames.filter((item: any) => item !== e))}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </AssignmentContainer>
                </Box>

                {/* 2nd */}
                <Box width={'100%'} paddingLeft={3} paddingRight={3} paddingBottom={1}>
                  <Box marginBottom={'10px'}></Box>
                  {/* Alert Container */}
                  <AlertContainer
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    padding={'20px'}
                    borderRadius={'16px'}
                    color={globalColors.white}
                    fontSize={'12px'}
                    boxShadow={'#171717'}
                    marginTop={'1rem'}
                  >
                    {' '}
                    {/* Para 1 */}
                    <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'} marginBottom={'2rem'} justifyContent={'space-between'}>
                      <Typography
                        component="div"
                        fontFamily={'Poppins'}
                        fontWeight={'400'}
                        variant="h5"
                        lineHeight={'32px'}
                        color={mode === 'dark' ? globalColors?.white : globalColors.black}
                      >
                        Alert Content
                      </Typography>
                      <Box></Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: '100%' }}>
                      <Box display={'flex'} gap="10px" alignItems={'center'} marginBottom={'20px'}>
                        <FormGroup
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            width: { xs: '100%', md: '50%' },
                          }}
                          className=""
                        >
                          <InputLabel
                            style={{
                              fontFamily: 'Poppins',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '32px',
                              letterSpacing: '0px',
                              textAlign: 'left',
                              color: globalColors.text.secondary,
                            }}
                            htmlFor="nameInput"
                          >
                            Title
                          </InputLabel>
                          <InputStyle
                            required
                            name="title"
                            type="text"
                            id="titleInput"
                            placeholder="Alert Title"
                            value={formValues.title}
                            onChange={handleChange}
                            mode={mode}
                          />
                        </FormGroup>
                      </Box>
                      <Box display={'flex'} gap="10px" alignItems={'center'}>
                        <FormGroup
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            width: '100%',
                          }}
                          className=""
                        >
                          <InputLabel
                            style={{
                              fontFamily: 'Poppins',
                              fontWeight: '400',
                              fontSize: '16px',
                              lineHeight: '32px',
                              letterSpacing: '0px',
                              textAlign: 'left',
                              color: globalColors.text.secondary,
                            }}
                            htmlFor="nameInput"
                          >
                            Message
                          </InputLabel>
                          <InputStyle
                            style={{ width: '100%', maxWidth: '100%', minWidth: '100%' }}
                            required
                            name="message"
                            type="text"
                            id="messageInput"
                            placeholder="Alert Message"
                            value={formValues.message}
                            onChange={handleChange}
                            mode={mode}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                    {/* <Box sx={{ display: 'flex', marginTop: '20px' }}>
            <InputLabel
            style={{
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '32px',
              letterSpacing: '0px',
              textAlign: 'left',
              color: 'rgba(147, 147, 147, 1)',
            }}
            htmlFor="nameInput"
            >
            Attachments:
            </InputLabel>
            <AttachmentUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} setSelectedFileName={setSelectedFileName} />
          </Box> */}
                    <Box>
                      {selectedFile && (
                        <Box sx={{ marginTop: '2%' }}>
                          <ThemeProvider
                            theme={{
                              palette: {
                                primary: {
                                  main: '#007FFF',
                                  dark: '#0066CC',
                                },
                              },
                            }}
                          >
                            <Box sx={crossFileContainerStyle({ selectedFile, theme, mode })}>
                              <Box sx={{ display: 'flex' }}>
                                <FileIcon />
                                <span
                                  style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '32px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                    paddingTop: '2%',
                                    paddingLeft: '5%',
                                  }}
                                >
                                  {selectedFileName}
                                </span>
                              </Box>
                              {/* X button to remove image */}
                              {selectedFile && (
                                <IconButton onClick={handleRemoveAttachment} sx={iconButtonStyle({ theme, mode })}>
                                  <ClearIcon
                                    style={{
                                      fontSize: 16,
                                    }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </ThemeProvider>
                        </Box>
                      )}
                    </Box>
                    <Typography
                      component="div"
                      fontFamily={'Poppins'}
                      fontWeight={'400'}
                      variant="h5"
                      lineHeight={'32px'}
                      color={mode === 'dark' ? globalColors?.white : globalColors.black}
                      paddingTop={'1rem'}
                    >
                      Priority
                    </Typography>
                    <Box display={'flex'} justifyContent={'start'} width={'100%'} gap={'1rem'} paddingTop={'2rem'}>
                      {['Low', 'Medium', 'High'].map((priority) => {
                        return (
                          <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            // width={'50px'}
                            // bgcolor={selectedIcons.includes(val) ? globalColors.blue : globalColors.black}
                            border={`2px solid ${priority === selectedPriority ? color ?? globalColors.blue : 'transparent'}`}
                            bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                            borderRadius={'8px'}
                            padding={'1rem'}
                            // color={globalColors.white}
                            color={handleTextColor}
                            onClick={() => {setSelectedPriority(priority)}}
                            sx={{ cursor: 'pointer' }}
                          >
                            <Typography
                              sx={{
                                fontFamily: 'Poppins',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '21px',
                                textAlign: 'center',
                              }}
                            >
                              {priority}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </AlertContainer>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '4%' }}>
                  <ButtonStyle type="reset" style={{ marginRight: '10px' }} color={globalColors.red} onClick={handleCancel}>
                    Cancel
                  </ButtonStyle>
                  <ButtonStyle style={{ marginLeft: '10px' }} color={color || globalColors.blue} disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Send Alert'}
                  </ButtonStyle>
                </Box>
              </FormAlertsStyle>
            </Box>
          ) : !hasPermission ? (
            <NoPermission />
          ) : (
            <InsufficientPlan />
          )}
        </Box>
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  height: 'auto',
  width: '100%',
});

const AlertContainer = styled(Box)({
  height: 'auto',
  width: '100%',
});

const ButtonStyle = styled('button')<any>(({ color, disabled, type }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  background: color !== 'default' && color !== '' ? color : type === 'reset' ? globalColors.black : globalColors.blue,
  color: globalColors.white,
  width: '260px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.blue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default Alerts;
