import { useDispatch, useSelector } from 'react-redux';
import { Typography, TableContainer, TableBody, TableRow, Table, TableHead, Paper, styled, Box } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { globalColors } from '../../../../../utils/constants/color';
import { selectThemeMode } from '../../../../../store/selector';
import IosSwitch from '../../../IosSwitch';
import { useEffect, useState } from 'react';
import { PermissionsInterface, getPermissionsList } from '../../../../../utils/helpers';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import { Button, Toast } from '../../../../atoms';
import { refreshPermissions } from '../../../../../store/slices';

const permissionsList = getPermissionsList();

interface PermissionProps {
  data: PermissionsInterface[];
  id?: string;
}

const UserPermissionList = ({ data, id }: PermissionProps) => {
  const mode = useSelector(selectThemeMode);
  const [permissions, setPermissions] = useState<PermissionsInterface[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  const style = {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '12px',
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const handleChange = (name: string, permission: string, value: boolean) => {
    const temp = [...permissions];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].name === name) {
        if (value) {
          temp[i].permissions.push(permission);
        } else {
          const indexToRemove = temp[i].permissions.indexOf(permission);
          if (indexToRemove !== -1) {
            temp[i].permissions.splice(indexToRemove, 1);
          }
        }
        break;
      }
    }
    setPermissions(temp);
  };

  const handleSave = async () => {
    setIsUpdating(true);
    const userRepository = new UserRepository();
    const savedUser = await userRepository.updatePermissions(id ?? '', permissions);
    if (savedUser) {
      Toast('User permissions have been applied successfully', 'success');
    } else {
      Toast('User permissions could not be applied successfully. Please try again', 'error');
    }
    dispatch(refreshPermissions(true));
    setIsUpdating(false);
  };

  useEffect(() => {
    setPermissions(data);
  }, [data]);

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          paddingBottom: '20px',
          paddingLeft: '20px',
          paddingRight: '20px',
          boxShadow: 'none',
        }}
      >
        {
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}></Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      View
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Create
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Update
                    </Heading>
                  </TableCell>
                  <TableCell>
                    <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                      Delete
                    </Heading>
                  </TableCell>
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissionsList.map((permission, idx) => {
                return (
                  <TableRow
                    key={idx}
                    sx={{
                      backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,

                      color: mode === 'dark' ? globalColors.white : globalColors.black,
                      borderRadius: '10px',
                      gap: '20px',
                      marginBottom: '20px',
                      paddingBottom: '20px',
                      borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
                    }}
                  >
                    <TableCell sx={style} component="th" scope="row">
                      {permission.name}
                    </TableCell>
                    {permission.permissions.map((name, i) => {
                      return (
                        <TableCell sx={style} key={i}>
                          {name !== 'N/A' ? (
                            <IosSwitch
                              initiallyChecked={permissions.length > 0 ? permissions[idx].permissions.includes(name) : false}
                              monochrome={false}
                              onChange={(checked) => {
                                handleChange(permission.name, name, !checked);
                              }}
                            />
                          ) : (
                            <Box></Box>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        }
        <Box display={'flex'} justifyContent={'center'} width={'100%'} marginTop={'2rem'}>
          <Button type="button" disabled={isUpdating} onClick={handleSave}>
            {isUpdating ? 'Loading...' : 'Apply'}
          </Button>
        </Box>
      </TableContainer>
    </>
  );
};

const Heading = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '-0.02em',
  textAlign: 'left',
});

export default UserPermissionList;
