import { PaymentElement } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import './styles.css';
import { globalColors } from '../../../utils/constants/color';
import { Box, Typography } from '@mui/material';
import { ButtonText } from '../../atoms';
import { Button } from '../../atoms';
import OrganizationRepository from '../../../utils/api/repositories/organizationRepository';

interface PaymentFormProps {
  successURL: string;
  preProcessingPayment: () => Promise<string>,
}

export default function PaymentForm({ successURL, preProcessingPayment }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    elements.submit()

    const clientSecret = await preProcessingPayment();

    console.log('CLIENT SECRET', clientSecret);

    const { error } = await stripe.confirmPayment({
      clientSecret: clientSecret,
      elements,
      confirmParams: {
        return_url: successURL,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? 'An unexpected error occured. Please try again.');
    } else {
      setMessage('An unexpected error occured. Please try again.');
    }

    setIsProcessing(false);
  };

  return (
    <Box
      margin={'auto'}
      maxWidth={'md'}
      width={'100%'}
      sx={{ border: `1px solid ${globalColors.gray}`, borderRadius: '15px', padding: '20px', margin: '3rem' }}
      zIndex={999999}
    >
      <form onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={{ layout: 'tabs' }} />
        <Box display={'flex'} justifyContent={'center'} marginTop={'2rem'} zIndex={999999}>
          <Button
            type="submit"
            buttonColor={globalColors.mainBlue}
            onClick={() => {
              // handleSubmit();
            }}
          >
            {isProcessing ? 'Processing...' : 'Pay Now'}
          </Button>
        </Box>
        <Box display={'flex'} justifyContent={'center'} marginTop={'2rem'}>
          {message && <Typography color={globalColors.red}>{message}</Typography>}
        </Box>
      </form>
    </Box>
  );
}
