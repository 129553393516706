import { globalColors } from '../../constants/color';

export const calendarEvents = [
  {
    event_id: 1,
    title: 'Event 1',
    start: '2023/10/1 09:30',
    end: '2023/10/1 10:30',
    type: 'done',
    executive: 'John',
    lead: 'Wick',
  },
  {
    event_id: 2,
    title: 'Event 2',
    start: '2023/10/8 10:00',
    end: '2023/10/8 11:00',
    type: 'meeting',
    executive: 'John',
    lead: 'Wick',
  },
  {
    event_id: 3,
    title: 'Event 2',
    start: '2023/10/10 10:00',
    end: '2023/10/10 11:00',
    type: 'appointment',
    executive: 'John',
    lead: 'Wick',
  },
  {
    event_id: 4,
    title: 'Event 2',
    start: '2023/10/12 10:00',
    end: '2023/10/12 11:00',
    type: 'call',
    executive: 'John',
    lead: 'Wick',
  },
];

// export const calendarResources = [
//   {
//     admin_id: 1,
//     title: 'John',
//     mobile: '555666777',
//     avatar: 'https://picsum.photos/200/300',
//     color: '#ab2d2d',
//   },
//   {
//     admin_id: 2,
//     title: 'Sarah',
//     mobile: '545678354',
//     avatar: 'https://picsum.photos/200/300',
//     color: '#58ab2d',
//   },
//   {
//     admin_id: 3,
//     title: 'Joseph',
//     mobile: '543678433',
//     avatar: 'https://picsum.photos/200/300',
//     color: '#a001a2',
//   },
//   {
//     admin_id: 4,
//     title: 'Mera',
//     mobile: '507487620',
//     avatar: 'https://picsum.photos/200/300',
//     color: '#08c5bd',
//   },
// ];
