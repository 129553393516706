interface CrossContainerProps {
  selectedImage: File | null;
  theme: any;
}

interface CrossFileContainerProps {
  selectedFile: File | null;
  theme: any;
}

interface IconButtonProps {
  theme: any;
}

export const imageNameStyle: React.CSSProperties = {
  color: 'rgba(246, 246, 246, 1)',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '32px',
  letterSpacing: '0px',
  textAlign: 'left',
};

export const iconButtonStyle = ({ theme }: IconButtonProps) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '8px',
  color: theme.palette.common.white,
});

export const crossFileContainerStyle = ({ selectedFile, theme }: CrossFileContainerProps) => ({
  padding: '5px 0px 5px 1%',
  width: 258,
  height: 40,
  borderRadius: 12,
  border: selectedFile ? `1px solid ${theme.palette.primary.main}` : null,
  // bgcolor: selectedFile ? theme.palette.primary.main : 'transparent',
  // '&:hover': {
  //   bgcolor: selectedFile ? theme.palette.primary.dark : 'transparent',
  // },
  position: 'relative',
});
