import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  tableCellClasses,
  TableBody,
  Paper,
  Tooltip,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectColor, selectEmailFilter, selectThemeMode, sortEmail } from '../../../store/selector';
import Loader from '../../atoms/loader';
import { globalColors } from '../../../utils/constants/color';
import EmailDetail from './emailDetail';
import EmailRepository from '../../../utils/api/repositories/emailRepository';
import { formatEmailDate } from '../../../utils/helpers';
import { styled } from '@mui/styles';
import ModalDeleteConfirm from '../leads/modalDeleteConfirm';
import UserRepository from '../../../utils/api/repositories/userRepository';
import RoundedAvatar from '../../atoms/roundedAvatar';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import NotFoundLottie from '../../atoms/notFound';

interface EmailTableProps {
  emailData: any[];
  fetchData: () => Promise<void>
}

const EmailTable = ({emailData, fetchData}: EmailTableProps) => {
  // const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const [loading, setloading] = useState<boolean>(false);
  // const [show, setshow] = useState<boolean>(false);
  const [openEmailDetail, setOpenEmailDetail] = useState<boolean>(false);
  // const [emailData, setEmailData] = useState<any[]>([]);
  const [emailId, setEmailId] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openDeleteEmailModal, setOpenDeleteEmailModal] = useState<boolean>(false);
  const [selectedEmailId, setSelectedEmailId] = useState<any>('');
  const color = useSelector(selectColor);

  const header = ['Subject', 'From', 'To', 'Date', 'Status'];

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    // textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
      minWidth: '120px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  // const fetchData = async () => {
  //   setloading(true);
  //   try {
  //     const userRepository = new UserRepository();
  //     const currentUser = userRepository.getCurrentUser();
  //     const objectId = currentUser?.id ?? '';

  //     if (objectId) {
  //       const repository = new EmailRepository();
  //       const templatesData = await repository.getEmailsByUser(sortData);
  //       // console.log('Email Templates:', templatesData);
  //       setEmailData(templatesData);
  //     }
  //   } catch (error: any) {
  //     console.error('Error fetching email templates:', error.message);
  //   }
  //   setloading(false);
  // };

  // const fetchFilterData = async () => {
  //   setloading(true);
  //   try {
  //     const userRepository = new UserRepository();
  //     const currentUser = userRepository.getCurrentUser();

  //     if (currentUser) {
  //       const repository = new EmailRepository();
  //       const templatesData = await repository.getEmails(filterData.from, filterData.to, filterData.date);
  //       // console.log('Email Templates:', templatesData);
  //       setEmailData(templatesData);
  //     }
  //   } catch (error: any) {
  //     console.error('Error fetching email templates:', error.message);
  //   }
  //   setloading(false);
  // };

  const handleMoveLeft = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setEmailId(emailData[currentIndex - 1]?.objectId);
    }
  };

  const handleMoveRight = () => {
    if (currentIndex < emailData.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setEmailId(emailData[currentIndex + 1]?.objectId);
    }
  };

  const handleDeleteEmail = async (emailId: any) => {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      const objectId = currentUser?.id ?? '';

      if (objectId) {
        const repository = new EmailRepository();
        const templatesData = await repository.deleteEmail(objectId, emailId);
        // console.log('Email Templates:', templatesData);
        fetchData();
      }
    } catch (error: any) {
      console.error('Email delete successfully:', error.message);
    }
  };

  const handleDeleteConfirmation = () => {
    if (selectedEmailId !== '') {
      handleDeleteEmail(selectedEmailId);
      setOpenDeleteEmailModal(false);
      setSelectedEmailId('');
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   fetchFilterData();
  // }, [filterData]);

  // useEffect(() => {
  //   fetchData();
  // }, [sortData, openEmailDetail]);

  return (
    <Box width={'100%'} display={'flex'} gap={'10px'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box borderRadius="12px" overflow="hidden">
            {loading ? (
              <Loader />
            ) : emailData.length > 0 ? (
              <>
                <TableContainer
                  component={Paper}
                  sx={{
                    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                    border: 'none',
                    boxShadow: 'none',
                    color: mode === 'dark' ? globalColors.white : globalColors.black,
                    paddingBottom: '20px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    borderRadius: '10px',
                    marginTop: '20px',
                  }}
                >
                  {
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: 'none',
                        },
                        minWidth: '700px',
                      }}
                    >
                      {/* Head */}
                      <TableHead>
                        <TableRow>
                          {header?.map((val, idx) => (
                            <TableCell>
                              <Typography key={idx} fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {val}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* Body */}
                      <TableBody>
                        {emailData?.map((val, index) => (
                          <TableRow
                            onClick={() => {
                              setEmailId(val?.objectId);
                              setOpenEmailDetail(true);
                            }}
                            sx={{
                              backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,

                              color: mode === 'dark' ? globalColors.white : globalColors.black,
                              borderRadius: '10px',
                              gap: '20px',
                              marginBottom: '20px',
                              borderBottom: `10px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
                              cursor: 'pointer',
                              '&:hover': {
                                backgroundColor: mode === 'dark' ? globalColors?.darkblack : '',
                              },
                            }}
                            key={val.id}
                          >
                            <TableCell sx={style} component="th" scope="row">
                              <Typography fontSize={'12px'} textAlign="left">
                                {val.subject}
                              </Typography>
                            </TableCell>
                            <TableCell sx={style}>
                              <Typography fontSize={'12px'} textAlign="left">
                                {val?.senderName}{' '}
                              </Typography>
                            </TableCell>
                            <TableCell sx={style} width={'200px'}>
                              <Typography
                                sx={{ display: 'flex', color: mode === 'dark' ? globalColors.white : globalColors.black }}
                                width={'10px'}
                                fontSize={'12px'}
                                flexGrow={1}
                                textAlign="left"
                              >
                                <Box display={'flex'} flexDirection={'row'} justifyContent={'start'}>
                                  {val.to.length > 0 ? (
                                    val.to.length === 1 ? (
                                      <Tooltip
                                        title={
                                          <>
                                            <Typography fontSize={12}>{`${val.to[0]}`}</Typography>
                                          </>
                                        }
                                      >
                                        <Box>
                                          <RoundedAvatar title={`${val.to[0]}`} />
                                        </Box>
                                      </Tooltip>
                                    ) : (
                                      val.to.slice(0, 3).map((to: any, i: any) => {
                                        return (
                                          <Tooltip
                                            title={
                                              <>
                                                <Typography fontSize={12}>{`${to}`}</Typography>
                                              </>
                                            }
                                          >
                                            <Box>
                                              <RoundedAvatar title={`${to}`} />
                                            </Box>
                                          </Tooltip>
                                        );
                                      })
                                    )
                                  ) : (
                                    '-'
                                  )}
                                  {/* Remaining Count */}
                                  {val.to?.length > 3 ? (
                                    <Tooltip
                                      title={
                                        <>
                                          {val.to.map((to: any, i: any) => (
                                            <Typography fontSize={12}>{`${to}`}</Typography>
                                          ))}
                                        </>
                                      }
                                    >
                                      <Box>
                                        <RoundedAvatar title={`+${val.to?.length - 3}`} showInitials={false} />
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                              </Typography>
                            </TableCell>
                            <TableCell sx={style}>
                              <Typography fontSize={'12px'} textAlign="left">
                                {moment(val?.createdAt).fromNow()}
                              </Typography>
                            </TableCell>

                            <TableCell sx={style}>
                              <Typography
                                width={'10px'}
                                flexGrow={1}
                                paddingLeft={'1.2rem'}
                                fontSize={'12px'}
                                textAlign="left"
                                color={val.scheduled ? globalColors.gray : color || globalColors.blue}
                              >
                                {val.scheduled ? 'Scheduled' : 'Sent'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  }
                </TableContainer>
              </>
            ) : (
              <NotFoundLottie
                showButton={true}
                text="Looks like you have not sent any emails yet. Press the button below and send a new email right now."
                buttonText="Send New Email"
                navigateTo="/email/new"
                setAddNewPath="/email"
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <EmailDetail
        emailId={emailId}
        emailData={emailData}
        // setshow={setshow}
        mode={mode}
        setOpenEmailDetail={setOpenEmailDetail}
        openEmailDetail={openEmailDetail}
        handleMoveLeft={handleMoveLeft}
        handleMoveRight={handleMoveRight}
        // handleDeleteEmail={handleDeleteEmail}
        handleDeleteEmail={setSelectedEmailId}
        setOpenDeleteEmailModal={setOpenDeleteEmailModal}
      />

      <ModalDeleteConfirm
        open={openDeleteEmailModal}
        onClose={() => {
          setOpenDeleteEmailModal(false);
        }}
        mode={mode}
        color={color}
        onDelete={handleDeleteConfirmation}
        action={{ permissionName: 'Email', action: 'Delete' }}
      />
    </Box>
  );
};

export default EmailTable;
