import React from 'react';
import { Box, Typography } from '@mui/material';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiFillInstagram } from 'react-icons/ai';
import { FiArrowUpRight } from 'react-icons/fi';
import { BsLinkedin } from 'react-icons/bs';
import Grid from '@mui/material/Grid';
import ContactusForm from '../../molecules/contactusForm';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { forwardRef } from 'react';

const Section7 = forwardRef((props, ref) => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  // const social = ['facebook', 'linkedin', 'instagram'];
  const social = [
    {
      name: 'instagram',
      link: 'https://www.instagram.com/propertycloudsolutions'
    }, 
    {
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/propertycloudsolutions'
    }, 
    {
      name: 'facebook',
      link: 'https://www.facebook.com/propertycloudsolutions'
    }, 
  ];

  return (
    <Box sx={{ padding: { lg: '5rem', xs: '3rem' } }} alignItems={'center'}>
      <Typography sx={{ fontSize: { xs: '20px', sm: '30px', lg: '50px' } }}>Contact Us</Typography>
      <Box
        display={'flex'}
        width="100%"
        sx={{
          marginLeft: 'auto',
          flexWrap: { xs: 'wrap', lg: 'nowrap' },
          gap: { xs: '50px', lg: '30px' },
          justifyContent: { xs: 'center', lg: 'start' },
        }}
        marginTop={'3rem'}
      >
        <Grid
          container
          rowSpacing={1}
          sx={{ justifyContent: { xs: 'center', lg: 'start' }, marginLeft: 'auto !important' }}
          columnSpacing={{ xs: 3, sm: 2, md: 3 }}
          rowGap={'25px'}
          columnGap={'50px'}
          columns={3}
          paddingTop={'30px'}
        >
          {social.map((val, index) => (
            <Grid
              data-aos={index === 0 || index === 1 ? 'fade-right' : index === 2 || index === 3 ? 'fade-left' : ''}
              data-aos-offset="30"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
              data-aos-duration="500"
              className="bgleftshade grid-container"
              item
              sm={1}
              xs={3}
              borderRadius={'16px'}
              padding={'20px'}
              border="1px solid #599BF7"
              sx={{
                height: { xs: '320px', lg: '50%', xl: '50%' },
              }}
            >
              <Box position="relative" height="100%" display="flex" flexDirection="column">
                <Box display="flex" width="100%" padding={'10px'}>
                  <div className="icon-wrapper" style={{ border: '1px solid #303030', borderRadius: '8px', padding: '4px' }}>
                    {
                      val.name === 'facebook' ? (
                        <BiLogoFacebook
                          size={'2rem'}
                          color="white"
                          style={{ margin: 'auto', display: 'flex', justifyContent: 'center', scale: '10px' }}
                        />
                      ) : val.name === 'linkedin' ? (
                        <BsLinkedin size={'2rem'} color="white" style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} />
                      ) : val.name === 'instagram' ? (
                        <AiFillInstagram size={'2rem'} color="white" style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} />
                      ) : null
                      // <BsDiscord size={'2rem'} color="white" style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} />
                    }
                  </div>
                </Box>
                <Box display={'flex'} justifyContent={'center'} position={'absolute'} margin={'auto'} bottom={0} width="100%" onClick={() => {window.open(val.link, '_blank');}}>
                  <Typography
                    display={'flex'}
                    gap={'2px'}
                    justifyContent={'center'}
                    border="1px solid #0455C6"
                    textTransform="capitalize"
                    width="100%"
                    margin={'auto'}
                    borderRadius={'16px'}
                    padding={'10px'}
                    textAlign={'center'}
                    sx={{
                      position: 'relative',
                      borderRadius: '16px',
                      cursor: 'pointer',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '0',
                        color: 'white',
                        borderRadius: '16px',
                        height: '100%',
                        background: '#0455C6',
                        transition: 'width 0.3s ease',
                      },

                      '&:hover': {
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          borderRadius: '16px',
                          height: '100%',
                          background: '#0455C6',
                          color: 'white',
                          transition: 'width 0.3s ease',
                        },
                        '& svg': {
                          transform: 'translateX(5px)',
                          transition: 'transform 0.3s ease',
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        zIndex: 1,
                      }}
                    >
                      {val.name}<FiArrowUpRight />
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'} paddingTop={'30px'} ref={ref}>
          <ContactusForm />
        </Box>
      </Box>
    </Box>
  );
});

export default Section7;
