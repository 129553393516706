import { Box, Modal, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { globalColors } from '../../../utils/constants/color';
import { BsCameraVideo, BsEyeFill, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import moment from 'moment';
import Loader from '../../atoms/loader';
import Paginator from '../../atoms/paginator';
import { MdPeopleOutline } from 'react-icons/md';

interface ModalCommunicateProps {
  mode: string;
  color: string;
  id: string;
  data?: any;
}

const ModalCommunicate = ({ mode, color, id, data }: ModalCommunicateProps) => {
  const [calls, setCalls] = useState<Parse.Object[]>([]);
  const [emails, setEmails] = useState<Parse.Object[]>([]);
  const [meetings, setMeetings] = useState<Parse.Object[]>([]);
  const [physicalMeeting, setPhysicalMeeting] = useState<Parse.Object[]>([]);
  const [loading, setLoading] = useState(true);

  const getTotalCount = async () => {
    const repository = new LeadRepository();

    const totalCount = await repository.getCommunicationHistoryCount(id);

    return totalCount;
  };

  const fetchCommunicationHistory = async () => {
    setLoading(true);
    const leadRepository = new LeadRepository();
    const communicationHistory = await leadRepository.getCommunicationHistory(id);
    if (communicationHistory) {
      for (let i = 0; i < communicationHistory.length; i++) {
        if (communicationHistory[i].name === 'Calls') {
          setCalls(communicationHistory[i].values);
        } else if (communicationHistory[i].name === 'Emails') {
          setEmails(communicationHistory[i].values);
        } else if (communicationHistory[i].name === 'Physical Meeting') {
          setPhysicalMeeting(communicationHistory[i].values);
        } else if (communicationHistory[i].name === 'Meetings') {
          setMeetings(communicationHistory[i].values);
        }
      }
    }
    setLoading(false);
  };

  const fetchMoreCommunicationHistory = async (page: number) => {
    setLoading(true);
    const leadRepository = new LeadRepository();
    const communicationHistory = await leadRepository.getCommunicationHistory(id, page);
    if (communicationHistory) {
      for (let i = 0; i < communicationHistory.length; i++) {
        if (communicationHistory[i].name === 'Calls') {
          setCalls(communicationHistory[i].values);
        } else if (communicationHistory[i].name === 'Emails') {
          setEmails(communicationHistory[i].values);
        } else if (communicationHistory[i].name === 'Physical Meeting') {
          setPhysicalMeeting(communicationHistory[i].values);
        } else if (communicationHistory[i].name === 'Meetings') {
          setMeetings(communicationHistory[i].values);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCommunicationHistory();
  }, []);

  return (
    <Box marginTop={'30px'} sx={{ overflow: 'auto', overflowX: 'scroll' }} display={'flex'} flexDirection={'column'} gap={'10px'} className="scroll">
      <Box className="content">
        {loading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%', gap: '1rem' }}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '2%', gap: '1rem' }}>
              {/* Call */}
              <Box sx={{ minWidth: '150px', maxWidth: '300px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* Heading */}
                <Stack direction={'row'} gap={'10px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: mode === 'dark' ? globalColors.white : globalColors.black,
                    }}
                  >
                    Calls
                  </Typography>
                  <BsTelephone size={'24px'} color={color || globalColors.lightblue} />
                </Stack>
                {/* Box */}
                {calls.length > 0 ? (
                  calls.map((e) => {
                    return (
                      <Box
                        component={'span'}
                        bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                        paddingLeft={'10px'}
                        color={mode === 'dark' ? globalColors.white : globalColors.black}
                        paddingRight={'10px'}
                        paddingTop={'10px'}
                        paddingBottom={'10px'}
                        borderRadius={'12px'}
                      >
                        <Box>
                          <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                            {e.get('note').get('body')}
                          </Typography>
                          <span style={{ color: globalColors.gray, fontSize: '12px' }}>{moment(e.get('createdAt'))?.fromNow()} by</span>
                          <span style={{ color: color || globalColors.blue, fontSize: '12px' }}>{` ${e.get('doneBy').get('firstName')} ${e
                            .get('doneBy')
                            .get('lastName')}`}</span>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                      -
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Email */}
              <Box sx={{ minWidth: '150px', maxWidth: '300px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* Heading */}
                <Stack direction={'row'} gap={'10px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: mode === 'dark' ? globalColors.white : globalColors.black,
                    }}
                  >
                    Emails
                  </Typography>
                  <BiEnvelope size={'24px'} color={color || globalColors.lightblue} />
                </Stack>
                {/* Box */}
                {emails.length > 0 ? (
                  emails.map((e) => {
                    return (
                      <Box
                        component={'span'}
                        bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                        paddingLeft={'10px'}
                        color={mode === 'dark' ? globalColors.white : globalColors.black}
                        paddingRight={'10px'}
                        paddingTop={'10px'}
                        paddingBottom={'10px'}
                        borderRadius={'12px'}
                      >
                        <Box>
                          <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                            {e.get('note').get('body')}
                          </Typography>
                          <span style={{ color: globalColors.gray, fontSize: '12px' }}>{moment(e.get('createdAt'))?.fromNow()} by</span>
                          <span style={{ color: color || globalColors.blue, fontSize: '12px' }}>{` ${e.get('doneBy').get('firstName')} ${e
                            .get('doneBy')
                            .get('lastName')}`}</span>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                      -
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Meeting */}
              <Box sx={{ minWidth: '150px', maxWidth: '300px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* Heading */}
                <Stack direction={'row'} gap={'10px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: mode === 'dark' ? globalColors.white : globalColors.black,
                    }}
                  >
                    Meetings
                  </Typography>
                  <BsCameraVideo size={'24px'} color={color || globalColors.lightblue} />
                </Stack>
                {/* Box */}
                {meetings.length > 0 ? (
                  meetings.map((e) => {
                    return (
                      <Box
                        component={'span'}
                        bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                        paddingLeft={'10px'}
                        color={mode === 'dark' ? globalColors.white : globalColors.black}
                        paddingRight={'10px'}
                        paddingTop={'10px'}
                        paddingBottom={'10px'}
                        borderRadius={'12px'}
                      >
                        <Box>
                          <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                            {e.get('note').get('body')}
                          </Typography>
                          <span style={{ color: globalColors.gray, fontSize: '12px' }}>{moment(e.get('createdAt'))?.fromNow()} by</span>
                          <span style={{ color: color || globalColors.blue, fontSize: '12px' }}>{` ${e.get('doneBy').get('firstName')} ${e
                            .get('doneBy')
                            .get('lastName')}`}</span>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                      -
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* physical Meeting */}
              <Box sx={{ minWidth: '150px', maxWidth: '300px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* Heading */}
                <Stack direction={'row'} gap={'10px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: mode === 'dark' ? globalColors.white : globalColors.black,
                    }}
                  >
                    Physical Meetings
                  </Typography>
                  {/* <BsTelephone size={'24px'} color={color || globalColors.lightblue} /> */}
                  <MdPeopleOutline size={'24px'} color={color || globalColors.lightblue} />
                </Stack>
                {/* Box */}
                {physicalMeeting.length > 0 ? (
                  physicalMeeting.map((e) => {
                    return (
                      <Box
                        component={'span'}
                        bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                        paddingLeft={'10px'}
                        color={mode === 'dark' ? globalColors.white : globalColors.black}
                        paddingRight={'10px'}
                        paddingTop={'10px'}
                        paddingBottom={'10px'}
                        borderRadius={'12px'}
                      >
                        <Box>
                          <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                            {e.get('note').get('body')}
                          </Typography>
                          <span style={{ color: globalColors.gray, fontSize: '12px' }}>{moment(e.get('createdAt'))?.fromNow()} by</span>
                          <span style={{ color: color || globalColors.blue, fontSize: '12px' }}>{` ${e.get('doneBy').get('firstName')} ${e
                            .get('doneBy')
                            .get('lastName')}`}</span>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Typography sx={{ marginTop: '6px', fontSize: '12px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                      -
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Paginator
              getTotalCount={getTotalCount}
              onChangePage={async (newPage) => {
                await fetchMoreCommunicationHistory(newPage);
                return true;
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ModalCommunicate;
