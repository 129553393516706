import { Box, Modal, Button } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Dropdown from '../../atoms/dropdown';
import { FieldInputUnit, FieldSelectOption, LabelInputProduct } from '../../atoms';
import ModalDeleteConfirm from '../leads/modalDeleteConfirm';
import PropertyRepository from '../../../utils/api/repositories/propertyRepository';
import ProjectRepository from '../../../utils/api/repositories/projectRepository';
import { onLoading } from '../../../store/property';
import { onProjectLoading } from '../../../store/projects';
import { useDispatch } from 'react-redux';
import { BulkEditFtn } from '../../../store/slices';
import ProjectComponentRepository from '../../../utils/api/repositories/projectComponentRepository';
import { onInventoryLoading, refreshInventory } from '../../../store/inventory';
import UserModel from '../../../utils/api/models/userModel';
import UserRepository from '../../../utils/api/repositories/userRepository';

interface ModalBulkProductProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  rowCheckboxState: { [key: string]: boolean };
}
interface UpdateValues {
  price: number;
  status: string;
  soldAt: string;
  soldBy: string;
  sellingPrice: number;
}
interface UpdateValueErrors {
  price: string;
  status: string;
  soldAt: string;
  soldBy: string;
  sellingPrice: string;
}
const ModalBulkEditInventory = ({ open, onClose, mode, color, rowCheckboxState }: ModalBulkProductProps) => {
  const dispatch = useDispatch();

  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>([]);
  const [activeItem, setActiveItem] = useState<string>('safe');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'auto',
    height: '75vh',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '525px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 5,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  const handleUpdateMultipleProducts = async (values: UpdateValues) => {
    const { status, price, sellingPrice, soldAt, soldBy } = values;
    const projectComponentRepository = new ProjectComponentRepository();
    const selectedComponentIds = Object.keys(rowCheckboxState).filter((id) => rowCheckboxState[id] === true);
    await projectComponentRepository.updateMultipleComponents(selectedComponentIds, status, price, sellingPrice, soldAt, soldBy);
    dispatch(refreshInventory());
    dispatch(onInventoryLoading(true));
    dispatch(BulkEditFtn(false));
  };

  const handleDeleteProducts = async () => {
    const projectComponentRepository = new ProjectComponentRepository();
    const selectedComponentIds = Object.keys(rowCheckboxState).filter((id) => rowCheckboxState[id] === true);
    await projectComponentRepository.deleteMultipleComponents(selectedComponentIds);
    dispatch(refreshInventory());
    dispatch(onInventoryLoading(true));
    dispatch(BulkEditFtn(false));
  };

  const getOrganizationMembers = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const allMembers = await userRepository.getAllUsersForOrganizationIncludingCurrentUser();
      if (allMembers) {
        setOrganizationMembers(allMembers);
      }
    }
  };

  useEffect(() => {
    getOrganizationMembers();
  }, []);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-add-amenities" aria-describedby="modal-request-add-amenities" className="content">
      <Box sx={style} className="content scroll" position={'relative'}>
        <Box display={'flex'} flexWrap={'wrap'} gap={'10px'} marginBottom={'30px'}>
          <Box
            onClick={() => setActiveItem('safe')}
            border={`2px solid ${activeItem === 'safe' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
            borderRadius={'10px'}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'safe'
                  ? color || globalColors.blue
                  : globalColors.blackLight
                : activeItem === 'safe'
                ? color || globalColors.blue
                : globalColors.white
            }
            color={mode === 'dark' ? globalColors.white : activeItem === 'safe' ? globalColors.white : color || globalColors.black}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Safe
          </Box>
          <Box
            onClick={() => setActiveItem('dangerous')}
            borderRadius={'10px'}
            border={`2px solid ${globalColors.red}`}
            bgcolor={
              mode === 'dark'
                ? activeItem === 'dangerous'
                  ? globalColors.red
                  : globalColors.blackLight
                : activeItem === 'dangerous'
                ? globalColors.red
                : globalColors.white
            }
            color={globalColors.white}
            paddingLeft={'10px'}
            sx={{ cursor: 'pointer' }}
            paddingRight={'10px'}
            paddingTop={'2px'}
            paddingBottom={'2px'}
          >
            Dangerous
          </Box>
        </Box>
        {activeItem === 'safe' ? (
          <Formik
            enableReinitialize
            initialValues={{
              status: '',
              price: 0,
              soldAt: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
                .getDate()
                .toString()
                .padStart(2, '0')}`,
              soldBy: '',
              sellingPrice: 0,
            }}
            validate={(values) => {
              const errors = {} as UpdateValueErrors;

              if (values['status'] === 'Sold' && (!values.sellingPrice || values.sellingPrice <= 0)) {
                errors['sellingPrice'] = 'Selling price must be greater than 0';
              }

              if (values['status'] === 'Sold' && !values.soldBy) {
                errors['soldBy'] = 'Please select a seller';
              }

              return errors;
            }}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              await handleUpdateMultipleProducts(values);
              resetForm();
              setSubmitting(false);
              onClose();
            }}
          >
            {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
              <Form>
                <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
                  <LabelInputProduct className="label">Price</LabelInputProduct>
                  <Field
                    className="col"
                    placeholder="Inventory Price"
                    type="number"
                    name="price"
                    id="price"
                    unit="PKR"
                    as={FieldInputUnit}
                    error={touched.price && errors.price ? errors.price : false}
                  />

                  <LabelInputProduct className="label">Status</LabelInputProduct>
                  <Dropdown label="" mode={mode} id="status" name="status" values={['Select', 'Available', 'Hold', 'Sold']} defaultValue="Select" />

                  {values['status'] === 'Sold' && (
                    <>
                      <LabelInputProduct className="label">Selling Price</LabelInputProduct>
                      <Field
                        className="col"
                        placeholder="Inventory Selling Price"
                        type="number"
                        name="sellingPrice"
                        id="sellingPrice"
                        unit="PKR"
                        as={FieldInputUnit}
                        // onChange={() => {}}
                        value={values.sellingPrice}
                        error={touched.sellingPrice && errors.sellingPrice ? errors.sellingPrice : false}
                      />
                    </>
                  )}
                  {values['status'] === 'Sold' && (
                    <>
                      <LabelInputProduct className="label">Sold At</LabelInputProduct>
                      <Field
                        className="col"
                        placeholder="Inventory Sold At"
                        type="date"
                        name="soldAt"
                        id="soldAt"
                        as={FieldInputUnit}
                        // onChange={() => {}}
                        value={values.soldAt}
                      />
                      {/* <Typography>{formProject['soldAt']?.toDateString()}</Typography> */}
                    </>
                  )}
                  {values['status'] === 'Sold' && (
                    <>
                      <LabelInputProduct className="label">Sold By</LabelInputProduct>
                      <FieldSelectOption
                        className="col"
                        id="soldBy"
                        name="soldBy"
                        renderId
                        options={organizationMembers.map((user, idx) => ({
                          id: `${user.firstName} ${user.lastName}`,
                          value: user.objectId ?? idx.toString(),
                        }))}
                        placeholder="Sold By"
                        onChange={(e) => {
                          setFieldValue('soldBy', e.target.value);
                        }}
                        value={values.soldBy}
                        error={touched.soldBy && errors?.soldBy ? errors?.soldBy : ''}
                      />
                    </>
                  )}

                  <Box
                    display={'flex'}
                    position={'absolute'}
                    bottom={'30px'}
                    justifyContent={'space-evenly'}
                    gap={'15px'}
                    width={'90%'}
                    marginTop={'auto'}
                    marginBottom={'0'}
                  >
                    <Button
                      sx={{
                        border: `1px solid ${globalColors.red}`,
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: globalColors.red,
                        },
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        border: `1px solid ${color || globalColors.blue}`,
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        width: '100%',
                        borderRadius: '12px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        '&:hover': {
                          backgroundColor: color || globalColors.blue,
                        },
                      }}
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Done'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} gap={'15px'}>
            <Button
              sx={{
                border: `1px solid ${globalColors.red}`,
                color: globalColors.white,
                width: '100%',
                bgcolor: globalColors.red,
                borderRadius: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                '&:hover': {
                  backgroundColor: globalColors.red,
                },
              }}
              onClick={() => {
                setShowDeleteConfirmModal(true);
              }}
            >
              Delete All
            </Button>
            <ModalDeleteConfirm
              open={showDeleteConfirmModal}
              onClose={() => {
                setShowDeleteConfirmModal(false);
              }}
              mode={mode}
              color={color}
              onDelete={() => {
                handleDeleteProducts();
              }}
              action={{ permissionName: 'Projects', action: 'Delete' }}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ModalBulkEditInventory;
