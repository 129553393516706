import React, { useEffect, useRef } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, ButtonProps, Typography, useTheme } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { Button, Toast } from '../../components/atoms';
import { ThemeProvider } from '@mui/styles';
import { IconButton, CircularProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import Upload from '../../components/molecules/settings/Upload';
import { styled } from '@mui/styles';
import { crossFileContainerStyle, iconButtonStyle, imageNameStyle } from '../../components/molecules/settings/element';
import FileRepository from '../../utils/api/repositories/fileRepository';
import FileModel from '../../utils/api/models/fileModel';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import processing from '../../assets/lottifile/processing.json';
import Lottie from 'react-lottie';
import Loader from '../../components/atoms/loader';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import SettingUploadIcon from '../../components/molecules/settings/SettingUploadIcon';
import * as Mui from '@mui/material';
import { confirmFileSize } from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setCloseSettings } from '../../store/routes';
import { useNavigate } from 'react-router-dom';

const processingOptions = {
  loop: true,
  autoplay: true,
  animationData: processing,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const necessary_fields = ['phone', 'category'];

const Setting = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigation = useSelector(selectNavigationLayout);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | ''>('');
  const theme = useTheme();
  const [submitted, setSubmitted] = useState(false);
  const [existingImportData, setExistingImportData] = useState<Parse.Object | null>(null);
  const [loading, setLoading] = useState(true);
  const [numRows, setNumRows] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, '_'),
  };

  const handleFileUpload = async (data: any[], fileInfo: IFileInfo, originalFile?: File | undefined) => {
    if (fileInfo.size <= 50 * 1000 * 1000) {
      if (data.length > 0) {
        let csv_columns = Object.keys(data[0]);
        let containsAll = true;
        let missingColumns = '';
        for (let i = 0; i < necessary_fields.length; i++) {
          if (!csv_columns.includes(necessary_fields[i])) {
            containsAll = false;
            if (i < necessary_fields.length - 1) {
              missingColumns += necessary_fields[i] + ', ';
            } else {
              missingColumns += necessary_fields[i];
            }
          }
        }
        if (!containsAll) {
          Toast('Invalid csv format uploaded. Missing columns: ' + missingColumns, 'error');
        } else {
          if (originalFile) {
            setSelectedFile(originalFile);
            setNumRows(data.length);
          } else {
            Toast('Failed to upload. Please try again.', 'error');
          }
        }
      } else {
        Toast('Invalid csv format uploaded. This csv does not contain any data.', 'error');
      }
    } else {
      Toast(`Failed to upload. Max allowed file size is 50 MB.`, 'error');
    }
  };
  const handleFileSubmit = async () => {
    const fileRepository = new FileRepository();
    if (selectedFile) {
      const file: FileModel = {
        file: selectedFile,
      };
      const importData = await fileRepository.create(file);
      if (importData) {
        const organizationRepository = new OrganizationRepository();
        const savedOrganization = await organizationRepository.addImportData(importData);
        if (savedOrganization) {
          Toast('File uploaded successfully', 'success');
          setExistingImportData(savedOrganization);
          dispatch(setCloseSettings());
          navigate('/dashboard');
        } else {
          Toast('File could not be uploaded successfully. Please try again.', 'error');
        }
      }
    }
  };

  useEffect(() => {
    const getImportData = async () => {
      const organizationRepository = new OrganizationRepository();
      const existingImportData = await organizationRepository.getImportData();
      setExistingImportData(existingImportData);
      setLoading(false);
    };

    getImportData();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Settings / Import Data'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Box>
              {!existingImportData && (
                <Box width={'100%'} padding={3}>
                  <Box marginBottom={'10px'}>
                    {/* Import Your Data */}
                    <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        marginTop={'auto'}
                        marginBottom={'auto'}
                        color={mode === 'dark' ? globalColors?.white : globalColors.black}
                      >
                        Import Your Data
                      </Typography>
                    </Box>
                    {/* Upload Your data */}
                    <Typography fontSize={'12px'} color={globalColors.gray}>
                      Upload your data to Property Cloud
                    </Typography>
                    {/* review your data */}
                    <Box sx={{ marginTop: '10px' }} display={'flex'} gap={'3rem'} flexWrap={'wrap'} justifyContent={'flex-end'}>
                      <Box sx={{ marginTop: '10px' }} display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                        <Typography fontSize={'12px'} color={mode === 'dark' ? globalColors?.white : globalColors.black}>
                          It may take some time to process your data
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/* Upload Now */}
                  {!selectedFile && (
                    <Box>
                      <UploadContainer
                        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                        padding={'20px'}
                        borderRadius={'16px'}
                        color={globalColors.white}
                        fontSize={'12px'}
                        boxShadow={'#171717'}
                        marginTop={'1rem'}
                      >
                        {' '}
                        {/* <Upload selectedFile={selectedFile} setSelectedFile={setSelectedFile} setSelectedFileName={setSelectedFileName} /> */}
                        <Mui.Button
                          sx={{
                            marginRight: '10px',
                            padding: 0,
                            minWidth: 0,
                            border: 'none',
                            '&:hover': {
                              border: 'none', // Adjust the color as needed
                            },
                          }}
                          component="span"
                          variant="outlined"
                          color="primary"
                          {...({ component: 'span', variant: 'outlined', color: 'primary' } as ButtonProps)}
                          onClick={() => {
                            const fileInput = document.getElementById('react-csv-reader-input') as HTMLInputElement;
                            if (fileInput) {
                              fileInput.value = '';
                              fileInput.click();
                            }
                          }}
                        >
                          <SettingUploadIcon width={23} height={23} />
                          <CSVReader onFileLoaded={handleFileUpload} parserOptions={papaparseOptions} inputStyle={{ display: 'none' }}></CSVReader>
                        </Mui.Button>
                      </UploadContainer>

                      {/* Upload Your data */}
                      <Box sx={{ marginTop: '10px' }}>
                        <Typography fontSize={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                          Upload guidelines
                        </Typography>
                        <ul style={{ paddingInlineStart: '20px', color: 'rgba(147, 147, 147, 1)' }}>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              File Size: Up to <span style={{ color: color || globalColors.blue }}>50 MB</span>
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              Supported Format: <span style={{ color: color || globalColors.blue }}>.csv</span> only
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              Required Columns:
                            </Typography>
                            <ul>
                              {/* <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={color || globalColors.blue}>
                                  first_name <span style={{ color: globalColors.gray }}>&nbsp;{`=>`}&nbsp; Text</span>
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={color || globalColors.blue}>
                                  last_name <span style={{ color: globalColors.gray }}>&nbsp;{`=>`}&nbsp; Text</span>
                                </Typography>
                              </li> */}
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={color || globalColors.blue}>
                                  phone<span style={{ color: globalColors.gray }}>&nbsp;{`=>`}&nbsp; Number</span>
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={color || globalColors.blue}>
                                  category <span style={{ color: globalColors.gray }}>&nbsp;{`=>`}&nbsp; Text | ('Property' or 'Project')</span>
                                </Typography>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              Optional Columns:
                            </Typography>
                            <ul>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  first_name &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  last_name &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  email &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  cnic &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  country &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  state &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  city &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  address &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  source &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  purpose &nbsp;&nbsp;{`=>`}&nbsp;&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  interested_in &nbsp;{`=>`}&nbsp; Text
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  budget_from &nbsp;{`=>`}&nbsp; Number
                                </Typography>
                              </li>
                              <li>
                                <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                                  budget_to &nbsp;{`=>`}&nbsp; Number
                                </Typography>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              Leads would be auto assigned by <span style={{ color: color || globalColors.blue }}>Cloud AI</span>
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              If a row has an empty value for any required column, it would be discarded.
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              Extra columns would be ignored.
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              Once submitted for upload, the process cannot be interrupted.
                            </Typography>
                          </li>
                          <li>
                            <Typography fontWeight={'400'} fontSize={'16px'} color={globalColors.gray}>
                              You can only upload <span style={{ color: color || globalColors.blue }}>one file</span> at a time.
                            </Typography>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  )}
                  {selectedFile && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ marginTop: '2%', display: 'flex', justifyContent: 'center' }}>
                        <ThemeProvider
                          theme={{
                            palette: {
                              primary: {
                                main: color,
                              },
                            },
                          }}
                        >
                          <Box sx={crossFileContainerStyle({ selectedFile, theme })}>
                            <Typography sx={imageNameStyle}>{selectedFile.name}</Typography>
                            {/* X button to remove image */}
                            {selectedFile && (
                              <IconButton onClick={handleRemoveAttachment} sx={iconButtonStyle({ theme })}>
                                <ClearIcon
                                  sx={{
                                    fontSize: 16,
                                    '&:hover': {
                                      color: globalColors.red,
                                    },
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </ThemeProvider>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '5%' }}>
                        <Typography sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center', width: '60%' }}>
                          <span style={{ color: color || globalColors.blue }}>{numRows} rows found.</span>
                          <br />
                          <br />
                          Important: Kindly recheck the data you are submitting. It takes us some time to review the data you submit and reflect it to
                          your Property Cloud dashboard. Once this process begins, you would have to wait for it to complete as it cannot be
                          interrupted.
                        </Typography>
                        <Typography
                          sx={{
                            color: mode === 'dark' ? globalColors.gray : globalColors.black,
                            textAlign: 'center',
                            width: '60%',
                            marginTop: '2%',
                            fontSize: '10px',
                          }}
                        >
                          The data you are submitting to Property Cloud will be processed in line with our{' '}
                          <a href="/policies/privacy" target="_blank" style={{ color: color || globalColors.blue, textDecoration: 'none' }}>
                            privacy policy
                          </a>
                          .
                        </Typography>
                      </Box>
                      <Box display={'flex'} gap={'10px'} justifyContent={'center'} marginTop={'1.5rem'}>
                        <Button
                          type="reset"
                          onClick={() => {
                            setSelectedFile(null);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" disabled={submitted} onClick={handleFileSubmit}>
                          {!submitted ? 'Submit' : <CircularProgress color="inherit" style={{ width: '25px', height: '25px' }} />}
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {existingImportData && (
                <Box width={'100%'} padding={3}>
                  <Box marginBottom={'10px'}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', margin: '5%' }}>
                      <Lottie options={processingOptions} width={250} height={250} />
                      <Typography sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center', width: '60%' }}>
                        We are currently processing your data. Once your data has been completely processed, you would be notified.
                      </Typography>
                      <Typography
                        sx={{
                          color: mode === 'dark' ? globalColors.gray : globalColors.black,
                          textAlign: 'center',
                          width: '60%',
                          marginTop: '2%',
                          marginBottom: '5%',
                          fontSize: '10px',
                        }}
                      >
                        If you believe this is an error or have any questions, please click{' '}
                        <a href="/" style={{ color: color || globalColors.blue, textDecoration: 'none' }}>
                          here
                        </a>{' '}
                        to contact support.
                      </Typography>
                      {/* <Button
                        type="reset"
                        onClick={() => {
                          setExistingImportData(null);
                        }}
                      >
                        Test Cancel
                      </Button> */}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

const UploadContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '272px',
  width: '100%',
});

// const BulletText = styled(Typography)({
//   fontFamily: 'Poppins',
//   fontWeight: '400',
//   fontSize: '16px',
//   lineHeight: '24px',
//   color: 'rgba(147, 147, 147, 1)',
// });

export default Setting;
