import React, { useState, useEffect } from 'react';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { Box, Typography, Button } from '@mui/material';
import Sidebar from '../../../../components/molecules/sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../store/selector';
import { styled } from '@mui/styles';
import IosSwitch from '../../../../components/molecules/IosSwitch';
import { NotificationContainer, SwitchContainer } from './notification.style';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { Toast } from '../../../atoms';

const Notifications = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [checked, setChecked] = useState(false);
  const [mediumPriority, setMediumPriority] = useState(false);
  const [lowPriority, setLowPriority] = useState(false);
  const [initialStates, setInitialStates] = useState({ medium: false, low: false });
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const fetchNotificationSettings = async () => {
      const userRepository = new UserRepository();
      const notificationSettings = await userRepository.getNotificationSettings();
      if (notificationSettings) {
        setMediumPriority(notificationSettings['medium']);
        setLowPriority(notificationSettings['low']);
      } else {
        Toast('Notification preferences could not be fetched. Please try again.', 'error');
      }
    };

    fetchNotificationSettings();
  }, []);

  const handleChange = async (medium: boolean, low: boolean) => {
    const userRepository = new UserRepository();
    const settings = {
      medium: medium,
      low: low,
    };
    const notificationSettings = await userRepository.updateNotificationSettings(settings);
    if (notificationSettings) {
      Toast('Notification preferences updated successfully.', 'success');
    } else {
      Toast('Notification preferences could not be updated successfully. Please try again.', 'error');
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Notifications'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
            <Box marginBottom={'10px'}></Box>
            {/* Assignment Container */}
            <AssignmentContainer
              bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
              padding={'20px'}
              borderRadius={'16px'}
              color={globalColors.white}
              fontSize={'12px'}
              boxShadow={'#171717'}
              marginTop={'1rem'}
            >
              {' '}
              {/* Para 1 */}
              <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                <Typography
                  component="div"
                  fontFamily={'Poppins'}
                  fontSize={'20px'}
                  fontWeight={'600'}
                  lineHeight={'32px'}
                  color={mode === 'dark' ? globalColors.white : globalColors.black}
                >
                  Notification Preferences
                </Typography>
              </Box>
              {/* Para 2 */}
              <Box sx={{ marginTop: '10px', marginBottom: '50px' }} display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
                <Typography
                  fontSize={'12px'}
                  color={globalColors.gray}
                >
                  Choose which notifications you want delivered to you
                </Typography>
              </Box>
              {/* Toggle */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '3%', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center', gap: {xs: '50px', md: '20px'}}}>
                <NotificationContainer>
                  <Typography
                    noWrap
                    component="div"
                    fontFamily={'Poppins'}
                    fontWeight={'500'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    All Notifications
                  </Typography>
                  <Box sx={{ marginTop: '5%', justifyContent: 'center', alignItems: 'center' }} display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                    <IosSwitch
                      initiallyChecked={mediumPriority && lowPriority}
                      monochrome={false}
                      onChange={(checked) => {
                        setTouched(true);
                        setLowPriority(!checked);
                        setMediumPriority(!checked);
                        if (!checked) {
                            handleChange(true, true);
                        }
                        else {
                            handleChange(false, false);
                        }
                      }}
                    />
                  </Box>
                </NotificationContainer>
                <SwitchContainer>
                  <Typography
                    noWrap
                    component="div"
                    fontFamily={'Poppins'}
                    fontWeight={'500'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    Medium Priority
                  </Typography>
                  <Box sx={{ marginTop: '5%', justifyContent: 'center', alignItems: 'center' }} display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                    <IosSwitch
                      monochrome={false}
                      initiallyChecked={mediumPriority}
                      onChange={(checked) => {
                        setTouched(true);
                        setMediumPriority(!checked);
                        handleChange(!checked, lowPriority);
                      }}
                    />
                  </Box>
                </SwitchContainer>

                <SwitchContainer>
                  <Typography
                    noWrap
                    component="div"
                    fontFamily={'Poppins'}
                    fontWeight={'500'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    Low Priority
                  </Typography>
                  <Box sx={{ marginTop: '5%', justifyContent: 'center', alignItems: 'center' }} display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                    <IosSwitch
                      initiallyChecked={lowPriority}
                      monochrome={false}
                      onChange={(checked) => {
                        setTouched(true);
                        setLowPriority(!checked);
                        handleChange(mediumPriority, !checked);
                      }}
                    />
                  </Box>
                </SwitchContainer>
              </Box>
            </AssignmentContainer>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const AssignmentContainer = styled(Box)({
  width: '100%',
});

export default Notifications;
