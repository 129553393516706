import { Box, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { BsCameraVideo, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import { globalColors } from '../../../../utils/constants/color';

interface CommunicateProps {
  mode: string;
  color: string;
}

const calls = ['', '', '', ''];
const emails = ['', '', ''];
const meetings = ['', ''];

const CommunicationHistory = ({ mode, color }: CommunicateProps) => {
  return (
    <Box height={'auto'} minHeight={'65vh'} className="content">
      <Box display={'flex'} flexWrap={'wrap'} gap={'10px'}>
        <Typography
          sx={{ fontFamily: 'Poppins', fontSize: '20px', fontWeight: 500, lineHeight: '30px' }}
          color={mode === 'dark' ? globalColors.white : globalColors.black}
        >
          Communication History:
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '2%',
          flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
        }}
      >
        {/* Call */}
        <Box sx={{ width: { xl: '30%', lg: '30%', md: '30%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* Heading */}
          <Stack direction={'row'} gap={'10px'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              Calls
            </Typography>
            <BsTelephone size={'24px'} color={color || globalColors.lightblue} />
          </Stack>
          {/* Box */}
          {calls.map((e) => {
            return (
              <Box
                component={'span'}
                bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                paddingLeft={'20px'}
                color={mode === 'dark' ? globalColors.white : globalColors.black}
                paddingRight={'20px'}
                paddingTop={'10px'}
                paddingBottom={'10px'}
                borderRadius={'12px'}
              >
                <Box>
                  <CommunicateText sx={{ marginTop: '6px' }}>- DEC 6, 2023 - 9 pm</CommunicateText>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{' ' + 'From' + ' '}</span>
                  <CommunicateText>Nour Eldaly</CommunicateText>
                </Box>

                <Box>
                  <CommunicateText sx={{ marginTop: '6px' }}>- DEC 6, 2023 - 9 pm</CommunicateText>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{' ' + 'From' + ' '}</span>
                  <CommunicateText>Nour Eldaly</CommunicateText>
                </Box>
              </Box>
            );
          })}
        </Box>

        {/* Email */}
        <Box sx={{ width: { xl: '30%', lg: '30%', md: '30%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* Heading */}
          <Stack direction={'row'} gap={'10px'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              Emails
            </Typography>
            <BiEnvelope size={'24px'} color={color || globalColors.lightblue} />
          </Stack>
          {/* Box */}
          {emails.map((e) => {
            return (
              <Box
                component={'span'}
                bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                paddingLeft={'20px'}
                color={mode === 'dark' ? globalColors.white : globalColors.black}
                paddingRight={'20px'}
                paddingTop={'10px'}
                paddingBottom={'10px'}
                borderRadius={'12px'}
              >
                <Box>
                  <CommunicateText sx={{ marginTop: '6px' }}>- DEC 6, 2023 - 9 pm</CommunicateText>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{' ' + 'From' + ' '}</span>
                  <CommunicateText>Nour Eldaly</CommunicateText>
                </Box>

                <Box>
                  <CommunicateText sx={{ marginTop: '6px' }}>- DEC 6, 2023 - 9 pm</CommunicateText>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{' ' + 'From' + ' '}</span>
                  <CommunicateText>Nour Eldaly</CommunicateText>
                </Box>
              </Box>
            );
          })}
        </Box>

        {/* Meeting */}
        <Box sx={{ width: { xl: '30%', lg: '30%', md: '30%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* Heading */}
          <Stack direction={'row'} gap={'10px'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              Meetings
            </Typography>
            <BsCameraVideo size={'24px'} color={color || globalColors.lightblue} />
          </Stack>
          {/* Box */}
          {meetings.map((e) => {
            return (
              <Box
                component={'span'}
                bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
                paddingLeft={'20px'}
                color={mode === 'dark' ? globalColors.white : globalColors.black}
                paddingRight={'20px'}
                paddingTop={'10px'}
                paddingBottom={'10px'}
                borderRadius={'12px'}
              >
                <Box>
                  <CommunicateText sx={{ marginTop: '6px' }}>- DEC 6, 2023 - 9 pm</CommunicateText>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{' ' + 'From' + ' '}</span>
                  <CommunicateText>Nour Eldaly</CommunicateText>
                </Box>

                <Box>
                  <CommunicateText sx={{ marginTop: '6px' }}>- DEC 6, 2023 - 9 pm</CommunicateText>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{' ' + 'From' + ' '}</span>
                  <CommunicateText>Nour Eldaly</CommunicateText>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const CommunicateText = styled('span')({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '-0.02em',
  textAlign: 'left',
});

export default CommunicationHistory;
