import { Box, Typography, styled, Checkbox } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { Field } from 'formik';
import { selectColor } from '../../store/selector';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface DropDownInputProps {
  name: string;
  id: string;
  mode: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  optional?: true | false;
  disabled?: true | false;
  values?: string[];
  valuesObject?: { id: null | string; value: string }[];
  defaultValue?: string;
  showCheckBox?: true | false;
  checked?: true | false;
  error?: string | boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onUnchecked?: () => void;
}
const Dropdown = <T, _>({
  label,
  checked,
  showCheckBox = false,
  value,
  name,
  mode,
  disabled = false,
  id,
  optional = true,
  values,
  defaultValue,
  error,
  onBlur,
  valuesObject,
  onUnchecked,
}: DropDownInputProps) => {
  const [checkBox, setCheckedBox] = useState(!showCheckBox ? true : checked || false);
  const color = useSelector(selectColor);

  useEffect(() => {
    if (!checkBox) {
      if (onUnchecked) {
        onUnchecked();
      }
    }
  }, [checkBox])

  return (
    <DropdownInputStyle>
      <Box display="flex" justifyContent={optional ? 'space-between' : ''} gap="10px" mb={label && '1rem'} fontSize="1rem">
        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{label}</Typography>
        {!optional && (
          <Typography  sx={{ color: 'red' }}>
            *
          </Typography>
        )}
        {showCheckBox && (
          <Checkbox
            size="small"
            sx={{
              color: color || globalColors.blue,
              '&.Mui-checked': {
                color: color || globalColors.secondBlue,
              },
              marginTop: '-7px',
            }}
            onChange={() => setCheckedBox((prev: boolean) => !prev)}
            checked={checkBox || false}
          />
        )}
      </Box>
      {checkBox && (
        <div className={mode === 'dark' ? 'group-input' : 'group-input-light'}>
          <Field as="select" value={value} className="input" name={name} id={id} disabled={disabled} onBlur={onBlur}>
            {values?.map((value, idx) => (
              <option key={idx} disabled={idx === 0} selected={value === defaultValue} value={value}>
                {value}
              </option>
            ))}
            {valuesObject?.map((value, idx) => (
              <option key={idx} disabled={idx === 0} selected={value.id === defaultValue} value={value?.id || ''}>
                {value.value}
              </option>
            ))}
          </Field>
        </div>
      )}
      {error && (
        <Typography variant="caption" className="text-error">
          {error}
        </Typography>
      )}
    </DropdownInputStyle>
  );
};

const DropdownInputStyle = styled('div')({
  width: '100%',
  color: 'white',

  '& .group-input': {
    padding: '0 1rem',
    backgroundColor: globalColors.black,
    border: '2px solid #303030',
    height: '48px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    fontSize: "13px",

    '& .input': {
      color: globalColors.text.secondary,
      backgroundColor: globalColors.black,

      border: 'none',
      flex: 1,
      width: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  '& .group-input-light': {
    padding: '0 1rem',
    backgroundColor: globalColors.white,
    border: `1px solid #303030`,
    height: '48px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',

    '& .input': {
      color: globalColors.text.secondary,
      backgroundColor: globalColors.white,

      border: 'none',
      flex: 1,
      width: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
  },
});
export default Dropdown;
