import { Typography } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';

interface HeaderProps {
  text: string;
  textcolor?: string;
}

const Heading = ({ text, textcolor = globalColors.white }: HeaderProps) => {
  const mode = useSelector(selectThemeMode);

  return (
    <div>
      <Typography variant="h5" fontSize={'1.3rem'} alignItems={'left'} textAlign={'left'} sx={{ color: mode === 'dark' ? textcolor : globalColors.black }}>
        {text}
      </Typography>
    </div>
  );
};

export default Heading;
