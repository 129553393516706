import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// TODO : make it global types
export enum EnumCommissionModes {
  Individual = 'Individual',
  Team = 'Team'
}

export interface ICommissionFormDetail {
  id: string;
  name: string;
  type: string;
  country: string;
  state: string;
  city: string;
  address: string;
  consistOf: string;
  consistOfUnit: string;
  area: string;
  areaUnit: string;
  purpose: string;
  description: string;
  contactName: string;
  contactPhone: string;
  contactSecondaryPhone: string;
}

interface FilterData {
  status: string;
  type: string;
  purpose: string;
}

interface CommissionDataState {
  isLoading: boolean;
  commissionMode: EnumCommissionModes;
  Search: string;
//   formProject: IFormDetailProject;
//   projects: IFormDetailProject[];
  showModalBulkEdit: boolean;
  filterData: FilterData;
}

const initialState: CommissionDataState = {
  isLoading: false,
  showModalBulkEdit: false,
  commissionMode: EnumCommissionModes.Individual,
  Search: '',
//   formProject: {
//     id: '',
//     cover: {
//       files: [],
//       preview: '',
//     },
//     name: '',
//     type: '',
//     country: 'Pakistan',
//     state: '',
//     city: '',
//     address: '',
//     consistOf: '',
//     consistOfUnit: 'Floor',
//     area: '',
//     areaUnit: '',
//     purpose: '',
//     description: '',
//     contactName: '',
//     contactPhone: '',
//     contactSecondaryPhone: '',
//     status: '',
//     images: [],
//   },
  filterData: {
    status: '',
    type: '',
    purpose: '',
  },
};
const CommissionReducer = createSlice({
  name: 'Project Data',
  initialState,
  reducers: {
    
    onSwitchMode: (state, action: PayloadAction<EnumCommissionModes>) => {
      state.commissionMode = action.payload;
    },
    onProjectLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    onShowModalBulk: (state, action: PayloadAction<boolean>) => {
      state.showModalBulkEdit = action.payload;
    },
    ProjectFilterDataFtn: (state, action: PayloadAction<FilterData>) => {
      state.filterData = action.payload;
    },
    SearchProject: (state, action: PayloadAction<string>) => {
      state.Search = action.payload;
    },
  },
});

export const {
  onSwitchMode,
  ProjectFilterDataFtn,
  SearchProject,
  onProjectLoading,
  onShowModalBulk,
} = CommissionReducer.actions;

export default CommissionReducer.reducer;
