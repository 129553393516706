import { Box, Typography, Rating } from '@mui/material';
import { DummyUserImg, IMGBackgroundTexture } from '../../assets/Images';

interface Testimonial {
  description: string;
  name: string;
  role: string;
}

interface CardTestimonialProps {
  testimonial: Testimonial
}


const CardTestimonials = ({ testimonial }: CardTestimonialProps) => {
  // const cardStyle = {
  //   position: 'relative',
  //   height: '100%',
  // };

  const contentStyle = {
    padding: '3rem 1rem 1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    textAlign: 'center',
  };

  return (
    <Box justifyContent="center" display="flex">
      <img
        src={IMGBackgroundTexture}
        alt=""
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      <Box sx={contentStyle} position="absolute" top={0} left={0} right={0} bottom={0}>
        <Typography sx={{ fontSize: { lg: '40px', xs: '20px' } }}>“</Typography>
        <Typography sx={{ fontSize: { lg: '18px', xs: '10px' } }}>
          {testimonial.description}
        </Typography>
        <Box display="flex" justifyContent="center" sx={{ marginTop: { xs: '0rem', lg: '1rem' } }}>
          <Rating name="simple-controlled" value={5} readOnly />
        </Box>
        <Box display="flex" gap="10px" marginTop="1rem">
          <Box display="flex" flexDirection="column" gap="3px">
            <Typography sx={{ fontSize: { lg: '18px', xs: '12px' } }}>{testimonial.name}</Typography>
            <Typography color="#FFFFFF80" whiteSpace={'nowrap'} sx={{ fontSize: { lg: '14px', xs: '11px', sm: '12px' } }}>
              {testimonial.role}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CardTestimonials;
