import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Mui from '@mui/material';
import { FaRegUser } from 'react-icons/fa';
import { BiEnvelope } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { Button, FieldInput, FieldInputTextarea, Toast } from '../../atoms';
import { setAddNew } from '../../../store/routes';
import UserModel from '../../../utils/api/models/userModel';
import Sidebar from '../../molecules/sidebar';
import { globalColors } from '../../../utils/constants/color';
import { drawerWidth } from '../../../utils/constants/drawerWidth';
import Loader from '../../atoms/loader';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../store/selector';
import { useDispatch } from 'react-redux';
import { formatRole, getInitials } from '../../../utils/helpers';
import GroupInput from '../../molecules/groupInput';
import ContentTable from './contentTable';
import { setUserData } from '../../../store/user';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface FormValuesReset {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface FormValuesErrors {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const Profile = () => {
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const formikRefReset = useRef<FormikProps<FormValuesReset>>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [backgroundImage, setBackgroundImage] = useState<any>(null);
  const [employeeName, setEmployeeName] = useState<string>('');
  const [salaries, setSalaries] = useState<Parse.Object[]>([]);
  const [employeeRole, setEmployeeRole] = useState<string>('');
  const [switchPage, setSwitchPage] = useState<string>('Profile');
  const [loading, setLoading] = useState(true);
  const profileCircle = useRef<HTMLDivElement>(null);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const color = useSelector(selectColor);
  const [obscureOldPassword, setObscureOldPassword] = useState(true);
  const [obscureNewPassword, setObscureNewPassword] = useState(true);
  const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);

  const onSubmit = async (values: FormValues) => {
    const updatedUser: UserModel = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
    };

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const user = await userRepository.update(updatedUser, currentUser.id);
      if (user) {
        await getUserInformation();
        dispatch(setUserData(user));
        Toast('User details updated successfully.', 'success');
      } else {
        Toast('User details could not be updated successfully. Please try again.', 'error');
      }
    }
  };

  const onSubmitReset = async (values: FormValuesReset) => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const resettedUser = await userRepository.updatePassword(currentUser.get('username'), values.oldPassword, values.newPassword)
      if (resettedUser) {
        const logout = await userRepository.logout();
        if (logout) {
          Toast('Password updation successful. Please re-login to continue.', 'success');
          navigate('/login');
        }
        else {
          Toast('Password updation successful. Please re-login to continue.', 'success');
          navigate('/login');
        }
      }
      else {
        Toast('Current password is incorrect. Please recheck and try again.', 'error');
      }
    }
    else {
      Toast('User password could not be changed successfully. Please try again.', 'error');
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Once the file is loaded, set it as the background image
      setBackgroundImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const getUserInformation = async () => {
    setLoading(true);
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      setFormValues({
        firstName: currentUser.get('firstName') ?? '',
        lastName: currentUser.get('lastName') ?? '',
        email: currentUser.get('email') || '',
        phone: currentUser.get('phone') || '',
      });
      setEmployeeName(currentUser.get('firstName') ? currentUser.get('firstName') + ' ' + currentUser.get('lastName') : '');
      setBackgroundImage(currentUser.get('profilePicture')?.get('file')?._url || null);
      setEmployeeRole(formatRole(currentUser.get('role')));

      const userSalaries = await userRepository.getSalarySlipsForUser(currentUser.id);
      if (userSalaries) {
        setSalaries(userSalaries);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserInformation();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Profile'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
              <Box marginBottom={'10px'}></Box>
              {/* Top Container */}
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { md: 'row', xs: 'column' },
                  alignItems: { xs: 'center', md: 'end' },
                  gap: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                }}
              >
                {/* 1st */}
                <Box
                // sx={{ width: { xl: '18%', lg: '18%', md: '18%', sm: '100%', xs: '100%' } }}
                >
                  <Box
                    ref={profileCircle}
                    sx={{
                      width: '208px',
                      height: '208px',
                      border: `1px solid ${globalColors.black}`,
                      backgroundColor: mode === 'dark' ? globalColors.background.gray : globalColors.whiteLight,
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: 'cover',
                      borderRadius: '110px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Typography fontWeight={600} fontSize={'60px'} color={color || globalColors.blue}>
                      {getInitials(employeeName)}
                    </Typography>
                    {/* {backgroundImage === null ? <ProfileIcon mode={mode} /> : <></>}
                <Box
                  sx={{
                    width: '56px',
                    height: '56px',
                    backgroundColor: globalColors.text.secondary,
                    borderRadius: '80px',
                    position: 'absolute',
                    bottom: '0%',
                    right: '8%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={openFileDialog}
                >
                  <CiCamera size={'2rem'} color={globalColors.black} />
                </Box> */}
                  </Box>
                </Box>

                {/* 2nd */}
                <Box
                  sx={{ width: { xl: '55%', lg: '55%', md: '55%', sm: '100%', xs: '100%' }, display: 'flex', flexDirection: 'column', gap: '10px' }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '2%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: { xl: 'row', lg: 'row', md: 'row', sm: 'column', xs: 'column' },
                      gap: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                    }}
                  >
                    <Box
                      sx={{
                        width: { xl: '68%', lg: '68%', md: '68%', sm: '100%', xs: '100%' },
                        height: '64px',
                        borderRadius: '50px',
                        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 18px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '18px',
                          fontWeight: 700,
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                        }}
                      >
                        {employeeName || '-'}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: { xl: '28%', lg: '28%', md: '28%', sm: '100%', xs: '100%' },
                        height: '64px',
                        borderRadius: '50px',
                        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 18px',
                      }}
                    >
                      <FaRegUser size={'1.3rem'} color={mode === 'dark' ? globalColors.white : globalColors.black} />
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: 500,
                          // lineHeight: '30px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          color: color || globalColors.blue,
                          marginLeft: '10px',
                        }}
                      >
                        {employeeRole ?? '-'}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 2.1 */}
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'start' },
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                    }}
                  >
                    {formValues.email !== '' && (
                      <Box
                        sx={{
                          width: '48px',
                          height: '48px',
                          borderRadius: '50px',
                          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BiEnvelope size={'1.5rem'} color={color || (mode === 'dark' ? globalColors.white : globalColors.black)} />
                      </Box>
                    )}
                    {formValues.phone !== '' && (
                      <Box
                        sx={{
                          width: '48px',
                          height: '48px',
                          borderRadius: '50px',
                          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <BsTelephone size={'1rem'} color={color || (mode === 'dark' ? globalColors.white : globalColors.black)} />
                      </Box>
                    )}
                  </Box>
                </Box>

                {/* 3rd */}
                <Box
                  sx={{
                    width: { md: '20%', xs: '100%' },
                    paddingTop: { xs: '2rem', md: '0px' },
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'end',
                  }}
                >
                  <Button onClick={() => setSwitchPage(switchPage === 'Profile' ? 'Payroll' : 'Profile')} type="button">
                    {switchPage === 'Profile' ? 'View Payroll' : 'View Profile'}
                  </Button>
                </Box>
              </Box>

              {/* Assignment Container */}
              {switchPage === 'Profile' ? (
                <>
                  <AssignmentContainer
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    padding={'20px'}
                    borderRadius={'16px'}
                    color={globalColors.white}
                    fontSize={'12px'}
                    boxShadow={'#171717'}
                    marginTop={'1rem'}
                  >
                    <Formik
                      innerRef={formikRef}
                      initialValues={{
                        firstName: formValues.firstName,
                        lastName: formValues.lastName,
                        phone: formValues.phone,
                        email: formValues.email,
                      }}
                      validate={(values) => {
                        const errors = {} as FormValuesErrors;

                        if (!values.firstName || !values.firstName.trim()) {
                          errors.firstName = 'Required';
                        }

                        if (!values.lastName || !values.lastName.trim()) {
                          errors.lastName = 'Required';
                        }

                        return errors;
                      }}
                      onSubmit={onSubmit}
                    >
                      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                        <Form>
                          <Box
                            borderRadius={'20px'}
                            marginBottom={'2px'}
                            bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                            padding={{ xs: '0px', md: '20px' }}
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'1rem'}
                          >
                            <GroupInput className="group-input">
                              <FieldInput
                                label="First Name"
                                id="firstName"
                                value={values.firstName}
                                mode={mode}
                                name={'firstName'}
                                placeholder={'First Name'}
                                type="text"
                                error={touched.firstName && errors.firstName ? errors.firstName : false}
                              />
                              <FieldInput
                                label="Last Name"
                                id="lastName"
                                value={values.lastName}
                                mode={mode}
                                name={'lastName'}
                                placeholder={'Last Name'}
                                type="text"
                                error={touched.lastName && errors.lastName ? errors.lastName : false}
                              />
                            </GroupInput>
                            <GroupInput className="group-input">
                              <FieldInput
                                label="Email"
                                id="email"
                                value={values.email}
                                mode={mode}
                                name={'email'}
                                placeholder={'Example'}
                                type="text"
                                disabled
                              />
                              <FieldInput
                                label="Phone"
                                id="phone"
                                value={values.phone}
                                mode={mode}
                                name={'phone'}
                                placeholder={'Phone'}
                                type="text"
                              />
                            </GroupInput>

                            <Box sx={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                              <Button type="submit">Update</Button>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                    <Divider sx={{ backgroundColor: globalColors.gray, marginTop: '2rem', marginBottom: '2rem' }} />
                    <Formik
                      innerRef={formikRefReset}
                      initialValues={{
                        oldPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                      }}
                      validate={(values) => {
                        const errors = {} as FormValuesReset;

                        if (!values.oldPassword || !values.oldPassword.trim()) {
                          errors.oldPassword = 'Required';
                        }

                        const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:<>?]).{8,}$/);
                        if (!regex.test(values.newPassword)) {
                          errors.newPassword =
                            'Password does not meet the Password Policy requirements. Password must be at least 8 characters long, and have at least one uppercase character, one lowercase character, one number and one special character (!, $, #, ^, etc.).';
                        }

                        if (values.confirmPassword !== '' && values.confirmPassword !== values.newPassword) {
                          errors.confirmPassword = 'Both passwords must match';
                        }

                        return errors;
                      }}
                      onSubmit={onSubmitReset}
                    >
                      {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                        <Form>
                          <Box
                            borderRadius={'20px'}
                            marginBottom={'2px'}
                            bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                            padding={{ xs: '0px', md: '20px' }}
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'1rem'}
                          >
                            <GroupInput className="group-input">
                              <FieldInput
                                label="Current Password"
                                id="oldPassword"
                                value={values.oldPassword}
                                mode={mode}
                                name={'oldPassword'}
                                placeholder={'Current Password'}
                                type={obscureOldPassword ? 'password' : 'text'}
                                isPassword
                                onShowPassword={() => {
                                  setObscureOldPassword(!obscureOldPassword);
                                }}
                                isShowPassword={obscureOldPassword}
                                error={touched.oldPassword && errors.oldPassword ? errors.oldPassword : false}
                                autoComplete="off"
                              />
                            </GroupInput>
                            <GroupInput className="group-input">
                              <FieldInput
                                label="New Password"
                                id="newPassword"
                                value={values.newPassword}
                                mode={mode}
                                name={'newPassword'}
                                placeholder={'New Password'}
                                type={obscureNewPassword ? 'password' : 'text'}
                                isPassword
                                onShowPassword={() => {
                                  setObscureNewPassword(!obscureNewPassword);
                                }}
                                isShowPassword={obscureNewPassword}
                                error={touched.newPassword && errors.newPassword ? errors.newPassword : false}
                                autoComplete="off"
                              />
                            </GroupInput>
                            <GroupInput className="group-input">
                              <FieldInput
                                label="Confirm New Password"
                                id="confirmPassword"
                                value={values.confirmPassword}
                                mode={mode}
                                name={'confirmPassword'}
                                placeholder={'Confirm New Password'}
                                type={obscureConfirmPassword ? 'password' : 'text'}
                                isPassword
                                onShowPassword={() => {
                                  setObscureConfirmPassword(!obscureConfirmPassword);
                                }}
                                isShowPassword={obscureConfirmPassword}
                                error={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : false}
                                autoComplete="off"
                              />
                            </GroupInput>

                            <Box sx={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'end' }}>
                              <Button type="submit">Update Password</Button>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </AssignmentContainer>
                </>
              ) : (
                <ContentTable data={salaries} />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

const AssignmentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
}));

export default Profile;
