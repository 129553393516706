import { Box, Modal, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { globalColors } from '../../../../../utils/constants/color';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from './doneIcon';
import { useSelector } from 'react-redux';
import { selectColor } from '../../../../../store/selector';

interface ModalProps {
  open: boolean;
  mode: string;
  onClose: () => void;
}

const CallResponseModal = ({ open, onClose, mode }: ModalProps) => {

  const color = useSelector(selectColor);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '728px',
    maxHeight: '415px',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={style} className="content" display={'flex'} flexDirection={'column'}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <ClearIcon sx={{ cursor: 'pointer', color: mode === 'dark' ? globalColors.white : globalColors.black }} onClick={onClose} />
        </Box>

        <Box display={'flex'} gap="10px" alignItems={'center'} marginTop={'20px'} sx={{ justifyContent: 'center' }}>
          <DoneIcon color={color} />
        </Box>

        <Box display={'flex'} gap="10px" alignItems={'center'} marginTop={'30px'} sx={{ justifyContent: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '30px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
              padding: '2rem'
            }}
          >
            Thank you! We have received your request and will call you during the time slot you have selected.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default CallResponseModal;
