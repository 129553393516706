import React, { useEffect, useState } from 'react';
import './App.css';
import Dashboard from './views/dashboard/dashboard';
import { initializeParse } from './services/parse';
import UserRepository from './utils/api/repositories/userRepository';
import Parse from 'parse';
import { RouterProvider } from 'react-router-dom';
import { navigation } from './utils/navigator';
import { NotificationToast } from './components/atoms/notification';
import { Toast } from './components/atoms';
import { useDispatch } from 'react-redux';
import { UserRole } from './store/user';
import { Box } from '@mui/material';
import Loader from './components/atoms/loader';
import { useSelector } from 'react-redux';
import { addNewNotifications } from './store/notification';

initializeParse();

function App() {
  const [sendNotification, setSendNotification] = useState<{ body: string; title: string }>({ body: '', title: '' });
  const dispatch = useDispatch();

  const initLiveQuery = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      let query = new Parse.Query('Notification');
      const subscription = await query.subscribe();
      subscription.on('create', (object) => {
        if (object.get('recipient') && object.get('recipient').id == currentUser.id) {
          const priority = object.get('priority').toLowerCase();
          if (currentUser.get('notificationSettings')[priority] === true || priority === 'high') {
            setSendNotification({ body: object.get('body') ?? 'No Body', title: object.get('title') ?? 'No Title' });
          }
          dispatch(addNewNotifications());
        }
      });
    }
  };
  useEffect(() => {
    try {
      //initialize live query for notifications
      initLiveQuery();

    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (sendNotification.body !== '' || sendNotification.title !== '') {
      NotificationToast(`${sendNotification.title} - ${sendNotification.body}`);
      setSendNotification({body: '', title: ''});
    }
  }, [sendNotification]);


  return (
    <div className="App">
      <RouterProvider router={navigation} />
    </div>
  );
}

export default App;
