import { styled } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';

export const FormProjectStyle = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  fontSize: "13px",
  '& .row': {
    display: 'flex',
    alignItems: 'center',
    gap: '3.5rem',

    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      flexDirection: 'column',
      alignItems: 'start',

      '& button': {
        width: '100%',
      },
    },

    '& .label': {
      width: '200px',
    },

    '& .col': {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '&.btn-wrapper': {
      margin: '1rem 0',
      justifyContent: 'center',
    },
  },

  '& .address': {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .group-input': {
      gap: '1rem',
      '& .item': {
        width: '100%',
        color: globalColors.text.secondary,
      },
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
  },

  '& .area-wrapper': {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& .area': {
      flex: 1,
    },
    '& .area-unit': {
      width: '15%',
      minWidth: '160px',
    },
  },

  [theme.breakpoints.down('md')]: {
    '& .area-wrapper': {
      flexDirection: 'column',
      '& .area-unit': {
        width: '100%',
        minWidth: '160px',
      },
    },
  },
}));
