import React, { useEffect } from 'react';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, Typography, AvatarGroup, Avatar, Grid, Divider } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddNew, selectColor, selectCommissionMode, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { Button, SearchInput, Toast } from '../../components/atoms';
import { useState } from 'react';
import NotFoundLottie from '../../components/atoms/notFound';
import { useNavigate } from 'react-router-dom';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole } from '../../store/user';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import { OrganizationActions, PricingSchedules, PricingTiers, differenceInMonths, formatDate, getPaymentTiers } from '../../utils/helpers';
import CardPlanSelectable from '../../components/molecules/cardPlanSelectable';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import IosSwitch from '../../components/molecules/IosSwitch';
import InputSlider from '../../components/atoms/sliderWithInput';
import { BsExclamationCircle } from 'react-icons/bs';
import UpgradeRequestRepository from '../../utils/api/repositories/upgradeRequestRepository';
import UpgradeRequestModel from '../../utils/api/models/upgradeRequestModel';
import { ArrowBack } from '@mui/icons-material';

const marks = [
  {
    value: 1,
    label: '1 - 20',
  },
  {
    value: 21,
    label: '21 - 50',
  },
  {
    value: 51,
    label: '51 - 100',
  },
  {
    value: 101,
    label: '101 - 200',
  },
];

const initialPlan = getPaymentTiers();

const ChangePlan = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  const [organization, setOrganization] = useState<Parse.Object | null>(null);
  const [discount, setDiscount] = useState(1);
  const [numEmployees, setNumEmployees] = useState(1);
  const [initialNumEmployees, setInitialNumEmployees] = useState(1);
  const [selectedSchedule, setSelectedSchedule] = useState(1);
  const [initialSelectedSchedule, setInitialSelectedSchedule] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [initialSelectedPlan, setInitialSelectedPlan] = useState(1);
  const [plan, setPlan] = useState(initialPlan);
  const [buttonLoading, setButtonLoading] = useState(false);

  const checkChanges = () => {
    return numEmployees !== initialNumEmployees || selectedSchedule !== initialSelectedSchedule || selectedPlan !== initialSelectedPlan;
  };

  const isUpgrade = () => {
    const newTotalPrice = selectedSchedule === 0 ? 6 * plan[selectedPlan].price * discount : 12 * plan[selectedPlan].price * discount;
    const currentTotalPrice = organization?.get('perMonthAmount') * (organization?.get('paymentCycle') === PricingSchedules.Annual ? 12 : 6);
    return newTotalPrice >= currentTotalPrice;
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser?.get('role') === UserRole.admin) {
        setHasPermission(true);
      }
      const currentOrganization = await userRepository.getCurrentOrganization();
      if (currentOrganization) {
        setOrganization(currentOrganization);
        setSelectedPlan(currentOrganization.get('tier') === PricingTiers.Essential ? 0 : 1);
        setInitialSelectedPlan(currentOrganization.get('tier') === PricingTiers.Essential ? 0 : 1);
        setSelectedSchedule(currentOrganization.get('paymentCycle') === PricingSchedules.Annual ? 1 : 0);
        setInitialSelectedSchedule(currentOrganization.get('paymentCycle') === PricingSchedules.Annual ? 1 : 0);
        setDiscount(currentOrganization.get('paymentCycle') === PricingSchedules.Annual ? 0.75 : 1);
        const memberCount = currentOrganization.get('memberCount');
        if (memberCount === 20) {
          setNumEmployees(1);
          setInitialNumEmployees(1);
        } else if (memberCount === 50) {
          setNumEmployees(21);
          setInitialNumEmployees(21);
        }
        if (memberCount === 100) {
          setNumEmployees(51);
          setInitialNumEmployees(51);
        }
        if (memberCount === 200) {
          setNumEmployees(101);
          setInitialNumEmployees(101);
        }
      }
      setLoading(false);
    };

    fetchOrganization();
  }, []);

  useEffect(() => {
    if (selectedSchedule === 1) {
      setDiscount(0.75);
    } else {
      setDiscount(1);
    }
  }, [selectedSchedule]);

  useEffect(() => {
    if (numEmployees >= 1 && numEmployees <= 20) {
      let temp = [...plan];
      temp[0].price = 99;
      temp[1].price = 120;
      setPlan(temp);
    } else if (numEmployees > 20 && numEmployees <= 50) {
      let temp = [...plan];
      temp[0].price = 140;
      temp[1].price = 160;
      setPlan(temp);
    } else if (numEmployees > 50 && numEmployees <= 100) {
      let temp = [...plan];
      temp[0].price = 180;
      temp[1].price = 200;
      setPlan(temp);
    } else {
      let temp = [...plan];
      temp[0].price = 220;
      temp[1].price = 240;
      setPlan(temp);
    }
  }, [numEmployees]);

  return (
    <div>
      <Box minHeight={'100vh'} overflow={'hidden'} bgcolor={globalColors.mainBackground} color={globalColors.white}>
        <Box className="bgleftshade" position={'absolute'}></Box>
        <Box className="bgrightshade" position={'absolute'}></Box>
        {loading ? (
          <Box display={'flex'} alignItems={'center'} height={'100%'} marginTop={'50vh'}>
            <Loader loaderColor={globalColors.mainBlue} />
          </Box>
        ) : hasPermission ? (
          <Box>
            <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} color={globalColors.white} padding={'2rem'}>
              <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
                <ArrowBack
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Typography
                  fontSize={'12px'}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Typography>
              </Grid>
            </Grid>
            <Box padding={3}>
              {organization ? (
                <Box maxWidth={'lg'} margin={'auto'}>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={{ xs: 'center', md: 'start' }}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    paddingBottom={'2rem'}
                    gap={'1rem'}
                  >
                    <Typography variant="h5" color={mode === 'dark' ? globalColors.white : globalColors.black}>
                      Current Plan
                    </Typography>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={'10px'}
                        color={globalColors.gray}
                      >
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                          <span style={{ color: globalColors.mainBlue }}>
                            {organization.get('paymentCycle')} {organization.get('tier')}
                          </span>
                        </Typography>
                        <Box sx={{ height: '20px', backgroundColor: globalColors.gray, width: '2px' }} />
                        <Box display={'flex'} flexDirection={'row'} alignItems={'end'}>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize={'12px'}>
                            Upto&nbsp;
                          </Typography>
                          <Typography color={globalColors.mainBlue}>{organization.get('memberCount')}</Typography>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} fontSize={'12px'}>
                            &nbsp;users
                          </Typography>
                        </Box>
                      </Box>
                      <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                        <span style={{ color: globalColors.mainBlue }}>
                          ${organization.get('perMonthAmount')}{' '}
                          <span style={{ color: mode === 'dark' ? globalColors.white : globalColors.black, fontSize: '12px' }}>per month</span>
                        </span>
                      </Typography>
                      <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                        <span style={{ color: mode === 'dark' ? globalColors.white : globalColors.black, fontSize: '12px' }}>Next Due&nbsp;</span>
                        <span style={{ color: globalColors.mainBlue }}>{formatDate(organization.get('nextPaymentDue'))} </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ width: '70%', backgroundColor: globalColors.gray, margin: 'auto', marginTop: '3rem', marginBottom: '3rem' }} />
                  <Grid
                    container
                    display={'flex'}
                    justifyContent={'space-between'}
                    maxWidth={'lg'}
                    margin={'auto'}
                    marginTop={'3rem'}
                    marginBottom={'3rem'}
                    rowGap={'2rem'}
                    paddingLeft={'2rem'}
                    paddingRight={'2rem'}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display={'flex'}
                      justifyContent={{ xs: 'center', md: 'start' }}
                      color={mode === 'dark' ? globalColors.white : globalColors.black}
                    >
                      <InputSlider
                        marks={marks}
                        title={'Number of Users'}
                        showValue={false}
                        value={numEmployees}
                        minValue={1}
                        maxValue={101}
                        setValue={setNumEmployees}
                        step={null}
                        valueColor={globalColors.white}
                        color={globalColors.mainBlue}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} display={'flex'} justifyContent={{ xs: 'center', md: 'end' }} gap={'1rem'} alignItems={'center'}>
                      <Typography variant="h6" textTransform={'uppercase'} sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                        {PricingSchedules.Biannual}
                      </Typography>
                      <IosSwitch
                        initiallyChecked={selectedSchedule === 1}
                        onChange={(checked) => {
                          if (checked) {
                            setSelectedSchedule(0);
                          } else {
                            setSelectedSchedule(1);
                          }
                        }}
                        monochrome={false}
                        trackColor={globalColors.mainBlue}
                      />
                      <Typography variant="h6" textTransform={'uppercase'} sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                        {PricingSchedules.Annual}
                      </Typography>
                    </Grid>
                  </Grid>
                  {checkChanges() ? (
                    <Box display={'flex'} flexDirection={'column'} gap={'2rem'}>
                      <Grid container display={'flex'}>
                        <Grid
                          xs={12}
                          md={6}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={{ xs: 'center', md: 'end' }}
                          marginTop={'2rem'}
                          paddingRight={{ xs: 0, md: '4rem' }}
                        >
                          <Typography variant="h6" color={mode === 'dark' ? globalColors.white : globalColors.black}>
                            Current Plan
                          </Typography>
                          <hr
                            style={{
                              width: '150px',
                              marginTop: '20px',
                              marginBottom: '20px',
                              backgroundColor: globalColors.gray,
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                          />
                          <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                            marginBottom={'2rem'}
                            gap={'1rem'}
                          >
                            <Typography variant="h4" fontWeight={'600'} color={globalColors.mainBlue} display={'flex'} alignItems={'center'}>
                              ${organization.get('perMonthAmount') * (organization.get('paymentCycle') === PricingSchedules.Annual ? 12 : 6)}
                              <span style={{ color: mode === 'dark' ? globalColors.white : globalColors.black, fontSize: '16px' }}>
                                &nbsp;{organization.get('paymentCycle')}
                              </span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          xs={12}
                          md={6}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={{ xs: 'center', md: 'start' }}
                          marginTop={'2rem'}
                          paddingLeft={{ xs: 0, md: '4rem' }}
                        >
                          <Typography variant="h6" color={mode === 'dark' ? globalColors.white : globalColors.black}>
                            New Plan
                          </Typography>
                          <hr
                            style={{
                              width: '150px',
                              marginTop: '20px',
                              marginBottom: '20px',
                              backgroundColor: globalColors.gray,
                              marginLeft: 0,
                              marginRight: 0,
                            }}
                          />
                          <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                            marginBottom={'2rem'}
                            gap={'1rem'}
                          >
                            <Typography
                              variant={discount < 1 ? 'h5' : 'h4'}
                              color={discount < 1 ? (mode === 'dark' ? globalColors.white : globalColors.black) : globalColors.mainBlue}
                              fontWeight={discount < 1 ? '300' : '600'}
                              sx={{ textDecoration: discount < 1 ? 'line-through' : 'none' }}
                            >
                              ${selectedSchedule === 0 ? (6 * plan[selectedPlan].price).toFixed(2) : (12 * plan[selectedPlan].price).toFixed(2)}
                            </Typography>
                            {discount < 1 && (
                              <Typography variant="h4" fontWeight={'600'} color={globalColors.mainBlue}>
                                $
                                {selectedSchedule === 0
                                  ? (6 * plan[selectedPlan].price * discount).toFixed(2)
                                  : (12 * plan[selectedPlan].price * discount).toFixed(2)}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'1rem'} width={'100%'}>
                        {initialNumEmployees > numEmployees && (
                          <Box display={'flex'} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                            <BsExclamationCircle color={globalColors.red} size={'1.5rem'} />
                            <Typography color={globalColors.red} fontSize={'14px'}>
                              You might have to offboard members because your new selected number of users is lesser than your current user limit.
                            </Typography>
                          </Box>
                        )}
                        {initialSelectedPlan === 1 && selectedPlan === 0 && (
                          <Box display={'flex'} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                            <BsExclamationCircle color={globalColors.red} size={'1.5rem'} />
                            <Typography color={globalColors.red} fontSize={'14px'}>
                              You are downgrading your tier, which would result in a loss of access to certain features.
                            </Typography>
                          </Box>
                        )}
                        <Box display={'flex'} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                          <BsExclamationCircle color={globalColors.gray} size={'1.5rem'} />
                          <Typography color={globalColors.gray} fontSize={'14px'}>
                            {isUpgrade()
                              ? 'Your plan upgrade would come into effect immediately, since your new payment cycle has not started yet.'
                              : 'Your plan downgrade would come into effect immediately, since your new payment cycle has not started yet.'}
                          </Typography>
                        </Box>
                        <Box zIndex={999999} width={'100%'} display={'flex'} justifyContent={'center'}>
                          <Button
                            disabled={buttonLoading}
                            onClick={async () => {
                              try {
                                setButtonLoading(true);
                                if (isUpgrade()) {
                                  const organizationRepository = new OrganizationRepository();
                                  const newNumEmployees =
                                    numEmployees === 1 ? 20 : numEmployees === 21 ? 50 : numEmployees === 51 ? 100 : numEmployees === 101 ? 200 : 20;
                                  const upgradeRequest = {
                                    memberCount: newNumEmployees,
                                    paymentCycle: selectedSchedule === 0 ? PricingSchedules.Biannual : PricingSchedules.Annual,
                                    perMonthAmount: plan[selectedPlan].price * discount,
                                    tier: selectedPlan === 0 ? PricingTiers.Essential : PricingTiers.Professional,
                                  };
                                  const savedOrganization = await organizationRepository.upgradePlan(upgradeRequest);
                                  if (savedOrganization) {
                                    Toast('Your plan has been upgraded successfully.', 'success');
                                    setTimeout(() => {
                                      setButtonLoading(false);
                                      navigate(`/renewal/secure/payment/${savedOrganization.id}`);
                                    }, 2000);
                                  } else {
                                    setButtonLoading(false);
                                    Toast('Your upgrade request could not be processed right now. Please try again.', 'error');
                                  }
                                } else {
                                  const organizationRepository = new OrganizationRepository();
                                  const newNumEmployees =
                                    numEmployees === 1 ? 20 : numEmployees === 21 ? 50 : numEmployees === 51 ? 100 : numEmployees === 101 ? 200 : 20;
                                  const downgradeRequest = {
                                    memberCount: newNumEmployees,
                                    paymentCycle: selectedSchedule === 0 ? PricingSchedules.Biannual : PricingSchedules.Annual,
                                    perMonthAmount: plan[selectedPlan].price * discount,
                                    tier: selectedPlan === 0 ? PricingTiers.Essential : PricingTiers.Professional,
                                    actionRequired: newNumEmployees < initialNumEmployees ? OrganizationActions.offboard : null,
                                  };
                                  const savedOrganization = await organizationRepository.downgradePlan(downgradeRequest);
                                  if (savedOrganization) {
                                    Toast('Your plan has been downgraded successfully.', 'success');
                                    setTimeout(() => {
                                      setButtonLoading(false);
                                      navigate(`/renewal/secure/payment/${savedOrganization.id}`);
                                    }, 3000);
                                  } else {
                                    setTimeout(() => {
                                      Toast('Your plan could not be changed successfully. Please try again.', 'error');
                                      setButtonLoading(false);
                                    }, 3000);
                                  }
                                }
                              } catch (e) {
                                setButtonLoading(false);
                                Toast('Something went wrong. Please try again.', 'error');
                              }
                            }}
                          >
                            {buttonLoading ? 'Loading...' : 'Proceed'}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                      marginTop={'2rem'}
                      marginBottom={'2rem'}
                      gap={'1rem'}
                    >
                      <Typography
                        variant={discount < 1 ? 'h5' : 'h4'}
                        color={discount < 1 ? (mode === 'dark' ? globalColors.white : globalColors.black) : globalColors.mainBlue}
                        fontWeight={discount < 1 ? '300' : '600'}
                        sx={{ textDecoration: discount < 1 ? 'line-through' : 'none' }}
                      >
                        ${selectedSchedule === 0 ? (6 * plan[selectedPlan].price).toFixed(2) : (12 * plan[selectedPlan].price).toFixed(2)}
                      </Typography>
                      {discount < 1 && (
                        <Typography variant="h4" fontWeight={'600'} color={globalColors.mainBlue}>
                          $
                          {selectedSchedule === 0
                            ? (6 * plan[selectedPlan].price * discount).toFixed(2)
                            : (12 * plan[selectedPlan].price * discount).toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <Box
                    margin={'auto'}
                    maxWidth={'lg'}
                    display={'flex'}
                    sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}
                    gap={'30px'}
                    marginTop={'3rem'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    {plan.map((val, index) => (
                      <CardPlanSelectable
                        key={index}
                        title={val.title}
                        content={val.content}
                        extraContent={val.extraContent}
                        price={val.price}
                        discountedPrice={val.price * discount}
                        index={index}
                        selectedIndex={selectedPlan}
                        setSelectedIndex={setSelectedPlan}
                        modeSensitive
                        showAnimation={false}
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <NotFoundLottie
                  text="We couldn't fetch your company details right now. Please refresh the page and try again."
                  showButton={false}
                  buttonText=""
                  navigateTo=""
                />
              )}
            </Box>
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </div>
  );
};

export default ChangePlan;
