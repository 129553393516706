import { Box } from '@mui/material';
import React, { useRef } from 'react';
import { globalColors } from '../../utils/constants/color';
import HeroSection from '../../components/organisms/landingPage/heroSection';
import Section2 from '../../components/organisms/landingPage/section2';
import Section3 from '../../components/organisms/landingPage/section3';
import Section4 from '../../components/organisms/landingPage/section4';
import Section5 from '../../components/organisms/landingPage/section5';
import Section6 from '../../components/organisms/landingPage/section6';
import Section7 from '../../components/organisms/landingPage/section7';
import Navbar from '../../components/organisms/landingPage/navbar';
import Footer from '../../components/organisms/landingPage/footer';
import PropertCloud from '../../components/organisms/landingPage/propertCloud';
import Testinomals from '../../components/organisms/landingPage/testinomals';

const LandingPage = () => {
  const contactFormRef = useRef<HTMLElement | null>(null);
  const propertFinderRef = useRef<HTMLElement | null>(null);
  const propertCloudRef = useRef<HTMLElement | null>(null);
  const propertyNetworkRef = useRef<HTMLElement | null>(null);

  const scrollToContactForm = () => {
    if (contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToPropertyNetwork = () => {
    if (propertyNetworkRef.current) {
      propertyNetworkRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToPropertFinder = () => {
    if (propertFinderRef.current) {
      propertFinderRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToPropertCloud = () => {
    if (propertCloudRef.current) {
      propertCloudRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box bgcolor={globalColors.mainBackground} height={'100%'} width={'100%'} sx={{ overflow: 'hidden' }} color={globalColors.white}>
      <Navbar scrollToContactForm={scrollToContactForm} scrollToPropertFinder={scrollToPropertFinder} scrollToPropertCloud={scrollToPropertCloud} />
      <HeroSection onButtonClick={scrollToPropertyNetwork} scrollToContactForm={scrollToContactForm}/>
      {/* <Box className="bgrightshade" position={'absolute'} bottom={'-37rem'}></Box> */}
      {/*  */}
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Section2 ref={propertyNetworkRef}/>
      {/* Why Property Finder? */}
      <Section3 ref={propertFinderRef} onButtonClick={scrollToContactForm}/>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {/* Property Finder: Enjoy all the the Features */}
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Section4 />
      
      {/* Dashboard */}
      <Box className="bgrightshade" position={'absolute'}></Box>
      <Box className="bgleftshade" position={'absolute'}></Box>
      {/* <Section5 /> */}
      {/* Pricing and Plans */}
      <Box className="bgrightshade" position={'absolute'}></Box>
      <Section6 />
      {/*  */}
      <Box className="bgrightshade" position={'absolute'}></Box>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade2" position={'absolute'}></Box>
      {/* Regional Partner */}
      <PropertCloud ref={propertCloudRef} />
      <Testinomals />
      <Section7 ref={contactFormRef} />
      <Footer />
    </Box>
  );
};

export default LandingPage;
