import React from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import { IMGDummyProperty } from '../../../assets/Images';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { FaFacebookF } from 'react-icons/fa';
import { Instagram, Twitter } from '../../../assets/Icons';
import { CiEdit } from 'react-icons/ci';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { setAddNew } from '../../../store/routes';
import { MdDelete } from 'react-icons/md';

interface ContentRowProp {
  id: string;
  name: string;
  text: string;
  createdAt: string;
  data: any;
  handleDelete: any;
  setUpdatePost: any;
  setAddPost: any;
  setOpenDeletePostModal: any;
}

const ContentRow: React.FC<ContentRowProp> = ({
  id,
  name,
  text,
  createdAt,
  data,
  handleDelete,
  setUpdatePost,
  setAddPost,
  setOpenDeletePostModal,
}) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const dispatch = useDispatch();
  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };
  return (
    <TableRow
      sx={{
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        width: '100%',
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        borderRadius: '10px',
        gap: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
      }}
      key={id}
    >
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <img style={{ borderRadius: '10px' }} height={50} width={50} src={IMGDummyProperty} alt="" />
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {name ? name : 'Post Title'}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 400,
                fontSize: '10px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: 'rgba(147, 147, 147, 1)',
              }}
            >
              {createdAt ? createdAt : 'Time not avaliable'}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={style} component="th" scope="row">
        <Box display="flex" gap={2}>
          {['#599BF7'].map((background, index) =>
            data.facebook ? (
              <Box
                key={index}
                component="span"
                display="flex"
                justifyContent="center"
                bgcolor="none"
                borderRadius="50%"
                padding="0.3rem"
                width="28px"
                height="28px"
              >
                <FaFacebookF size="0.7rem" color={background} style={{ margin: 'auto' }} />
              </Box>
            ) : (
              <></>
            )
          )}

          {[Instagram].map((background, index) =>
            data.instagram ? (
              <Box
                key={index}
                component="span"
                display="flex"
                justifyContent="center"
                bgcolor="none"
                borderRadius="50%"
                padding="0.3rem"
                width="28px"
                height="28px"
              >
                <img style={{ borderRadius: '50%', width: '14px', objectFit: 'cover', margin: 'auto' }} src={background} alt="" />
              </Box>
            ) : (
              <></>
            )
          )}
        </Box>
      </TableCell>
      <TableCell sx={style} component="th" scope="row">
        <Typography> - </Typography>
      </TableCell>
      <TableCell sx={style} component="th" scope="row">
        <Box display="flex" gap={2}>
          {['#599BF7', Instagram].map((background, index) => (
            <Box key={index} component="span" display="flex" justifyContent="center" bgcolor="none" borderRadius="10%" padding="0.3rem">
              {background === '#599BF7' ? (
                <Box display={'flex'} gap={'3px'} justifyContent={'center'}>
                  <FaFacebookF size="0.7rem" color={background} style={{ margin: 'auto' }} />
                  <Typography fontSize={'10px'}> - </Typography>
                </Box>
              ) : (
                <Box display={'flex'} gap={'3px'} justifyContent={'center'}>
                  <img style={{ borderRadius: '50%', width: '14px', objectFit: 'cover', margin: 'auto' }} src={background} alt="" />
                  <Typography fontSize={'10px'}> - </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </TableCell>
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          {/* Edit */}
          <div
            style={{ display: 'flex', gap: '5px', justifyContent: 'center', background: 'none', borderRadius: '50%', }}
            onClick={() => {
              dispatch(setAddNew({ addNew: true, path: '/socialmedia' }));
              setUpdatePost(id);
              setAddPost(true);
            }}
          >
            <CiEdit size={'1.3rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} style={{ cursor: 'pointer' }} />
          </div>
          {/* Delete */}
          <div
            style={{ display: 'flex', gap: '5px', justifyContent: 'center', background: 'none', borderRadius: '50%', }}
            onClick={() => {
              handleDelete(id);
              setOpenDeletePostModal(true);
            }}
          >
            <MdDelete size={'1.3rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} style={{ cursor: 'pointer' }} />
          </div>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ContentRow;
