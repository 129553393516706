import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import NoteModel from '../models/noteModel';
import UserRepository from './userRepository';
import LeadRepository from './leadRepository';

class NoteRepository {
  private className = 'Note';

  // constructor() {}

  public async create(note: NoteModel): Promise<Parse.Object | null> {
    try {
      const Note = Parse.Object.extend(this.className);
      const noteObject = new Note();
      noteObject.set('body', note.body);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        noteObject.set('createdBy', currentUser);
        noteObject.set('updatedBy', currentUser);
      } else {
        return null;
      }

      return new Promise((resolve, _) => {
        noteObject.save(null, { useMasterKey: true }).then(
          (savedNote: any) => {
            // Toast(`Note added successfully!`, 'success');
            // const leadRepository = new LeadRepository();
            // leadRepository.getObjectById(note.leadId).then((lead) => {
            //   if (lead) {
            //     lead.relation('notes').add(savedNote);
            //     // lead.relation('notes').query().include(['createdBy']).find().then((note) => {
            //     //   note.get('createdBy').get('firstName');
            //     // })
            //     lead
            //       .save(null, { useMasterKey: true })
            //       .then((updatedLead) => {
            //         // Updated user
            //         resolve(savedNote);
            //       })
            //       .catch((error) => {
            //         resolve(null);
            //         // Handle the error here
            //         console.error('Error updating user data:', error);
            //       });
            //   } else {
            //     resolve(null);
            //   }
            // });
            resolve(savedNote);
          },
          (error: any) => {
            console.error('Error adding note:', error);
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error adding note:', error);
      return null;
    }
  }

  // Read
  public getObjectById(id: number) {}
}

export default NoteRepository;
