import React, { ChangeEvent, FormEvent } from 'react';
import { Input, Button, ButtonProps, Box } from '@mui/material';
import AttachmentIcon from './AttachmentIcon';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../store/selector';
import { globalColors } from '../../../utils/constants/color';

interface ImagePreviewProps {
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  setSelectedFileName: React.Dispatch<React.SetStateAction<string | ''>>;
}

const AttachmentUpload: React.FC<ImagePreviewProps> = ({ selectedFile, setSelectedFile, setSelectedFileName }) => {
  
  const mode = useSelector(selectThemeMode);
  
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);

      const fileName = file.name;
      setSelectedFileName(fileName);
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Example: Send the formData using fetch
      fetch('your-upload-endpoint', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Input
          type="file"
          inputProps={{ accept: '!image/*,video/*' }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
          style={{ display: 'none' }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            sx={{
              marginRight: '10px',
              padding: 0,
              minWidth: 0,
              border: 'none',
              '&:hover': {
                border: 'none', // Adjust the color as needed
              },
            }}
            component="span"
            variant="outlined"
            color="primary"
            {...({ component: 'span', variant: 'outlined', color: 'primary' } as ButtonProps)}
          >
            <AttachmentIcon width={23} height={23} />
          </Button>

          <span
            style={{
              color: mode === "dark" ? globalColors.white : globalColors.black,
              fontFamily: 'Poppins',
              fontSize: '13px',
              fontWeight: '400',
              lineHeight: '32px',
              letterSpacing: '0px',
              textAlign: 'left',
            }}
          >
            Attachment
          </span>
        </label>
      </form>
    </Box>
  );
};

export default AttachmentUpload;