import { Box, Typography } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../store/selector';
import { styled } from '@mui/material';
import Heading from '../../../atoms/heading';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectRepository from '../../../../utils/api/repositories/projectRepository';
import { BsBuilding, BsHouse } from 'react-icons/bs';
import PropertyRepository from '../../../../utils/api/repositories/propertyRepository';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import { PiMoney, PiUsersThreeLight } from 'react-icons/pi';
import Loader from '../../../atoms/loader';
import { getYearsArray } from '../../../../utils/helpers';
import StackChart from '../../../atoms/stackChart';
import YearSelector from '../../../atoms/yearSelector';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import ProjectComponentRepository from '../../../../utils/api/repositories/projectComponentRepository';

export interface FormValues {
  name: string;
  role: string;
  commission: string;
  email: string;
  phone: string;
  phone2: string;
}

interface YearValues {
  name: string;
  value: number;
}

interface QuarterlyData {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
}

const CompanyGraph = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [yearData, setYearData] = useState<YearValues[]>([]);
  const [quarterlyData, setQuarterlyData] = useState<QuarterlyData>({ Q1: 0, Q2: 0, Q3: 0, Q4: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchYearData = async () => {
      setLoading(true);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization').id;
        if (organizationId) {
          const projectRepository = new ProjectRepository();
          const projectComponentRepository = new ProjectComponentRepository();
          const projectsSold = await projectComponentRepository.getSalesCountForOrganizationByYear(organizationId, selectedYear);
          const quarterlyDataProjects = await projectRepository.getTotalSalesForOrganizationByYearFormatted(organizationId, selectedYear, '');
          var data = { Q1: 0, Q2: 0, Q3: 0, Q4: 0 };
          if (quarterlyDataProjects) {
            data['Q1'] = quarterlyDataProjects['Q1'];
            data['Q2'] = quarterlyDataProjects['Q2'];
            data['Q3'] = quarterlyDataProjects['Q3'];
            data['Q4'] = quarterlyDataProjects['Q4'];
          }
          const propertyRepository = new PropertyRepository();
          const propertiesSold = await propertyRepository.getSalesCountForOrganizationByYear(organizationId, selectedYear);
          const quarterlyDataProperties = await propertyRepository.getTotalSalesForOrganizationByYearFormatted(organizationId, selectedYear);
          // const selectedStartYear = 2024;
          // const selectedEndYear = 2024;
          // const quarterlyDataProperties = await propertyRepository.getTotalSalesForOrganizationByRange(
          //   organizationId,
          //   selectedStartYear,
          //   selectedEndYear
          // );

          if (quarterlyDataProperties) {
            data['Q1'] += quarterlyDataProperties['Q1'];
            data['Q2'] += quarterlyDataProperties['Q2'];
            data['Q3'] += quarterlyDataProperties['Q3'];
            data['Q4'] += quarterlyDataProperties['Q4'];
          }
          setQuarterlyData(data);
          const leadRepository = new LeadRepository();
          const leadsAssigned = await leadRepository.countByUserIdByYear(organizationId, selectedYear);
          let tempArray = [...yearData];
          tempArray[0] = { name: 'Total Sales', value: (projectsSold ?? 0) + (propertiesSold ?? 0) };
          tempArray[1] = { name: 'Leads Managed', value: leadsAssigned ?? 0 };
          tempArray[2] = { name: 'Project Units Sold', value: projectsSold ?? 0 };
          tempArray[3] = { name: 'Properties Sold', value: propertiesSold ?? 0 };
          setYearData(tempArray);
        }
      }
      setLoading(false);
    };

    fetchYearData();
  }, [selectedYear]);

  return (
    <CardContainer
      sx={{
        flexDirection: 'column',
        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
      }}
    >
      {loading ? (
        <Box height={'346px'} display={'flex'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            paddingBottom={'20px'}
            paddingLeft={'30px'}
            paddingRight={'30px'}
            paddingTop={'10px'}
          >
            <Heading text={'Revenue'} />
            <YearSelector mode={mode} values={getYearsArray()} selectedValue={selectedYear} setSelectedValue={setSelectedYear} />
          </Box>

          <Box display="flex" flexDirection={{ md: 'row', xs: 'column' }} paddingBottom={'20px'}>
            <Box
              sx={{
                width: { md: '70%', xs: '100%' },
                height: '300px',
                padding: '10px 20px',
              }}
            >
              <StackChart
                data={[
                  { name: 'Q1', uv: quarterlyData['Q1'], pv: quarterlyData['Q1'], amt: quarterlyData['Q1'] },
                  { name: 'Q2', uv: quarterlyData['Q2'], pv: quarterlyData['Q2'], amt: quarterlyData['Q2'] },
                  { name: 'Q3', uv: quarterlyData['Q3'], pv: quarterlyData['Q3'], amt: quarterlyData['Q3'] },
                  { name: 'Q4', uv: quarterlyData['Q4'], pv: quarterlyData['Q4'], amt: quarterlyData['Q4'] },
                ]}
              />
            </Box>

            <Box
              sx={{
                width: { xl: '30%', lg: '30%', md: '30%', sm: '100%', xs: '100%' },
                marginTop: { xl: '0px', lg: '0px', md: '0px', sm: '10px', xs: '10px' },
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                paddingRight: '20px',
              }}
            >
              {yearData.map((data, index) => {
                return (
                  <MiniCard mode={mode}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: 500,
                          fontSize: '30px',
                          lineHeight: '32px',
                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                        }}
                      >
                        {data.value}
                      </Typography>
                      {data.name == 'Total Sales' && (
                        <PiMoney size={'2rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
                      )}
                      {data.name == 'Leads Managed' && (
                        <PiUsersThreeLight size={'2rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
                      )}
                      {data.name == 'Projects Sold' && (
                        <BsBuilding size={'2rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
                      )}
                      {data.name == 'Properties Sold' && (
                        <BsHouse size={'2rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
                      )}
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: 400,
                          fontSize: '12px',
                          lineHeight: '1rem',
                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                        }}
                      >
                        {data.name}
                      </Typography>
                    </Box>
                  </MiniCard>
                );
              })}
            </Box>
          </Box>
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  // marginTop: '3%',
  borderRadius: '16px',
  border: `1px solid ${globalColors?.border.gray}`,
});

const Card = styled(Box)(({ mode }: { mode: any }) => ({
  width: '30%',
  height: '240px',
  border: `1px solid ${globalColors.black}`,
  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
  borderRadius: '32px',
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
}));

const GraphText = styled(Typography)(({ mode }: { mode: any }) => ({
  fontFamily: 'Poppins',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: mode === 'dark' ? globalColors.white : globalColors.black,
}));

const GraphHeading = styled(Typography)(({ mode }: { mode: any }) => ({
  fontFamily: 'Poppins',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: mode === 'dark' ? globalColors.gray : globalColors.black,
}));

const MiniCard = styled(Typography)(({ mode }: { mode: any }) => ({
  width: '48%',
  height: '128px',
  borderRadius: '8px',
  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default CompanyGraph;
