import React, { FC, SVGProps } from 'react';

interface UploadImageIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
}

const UploadImageIcon: FC<UploadImageIconProps> = ({ width, height, stroke, ...rest }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 3C20.5304 3 21.0391 3.21071 21.4142 3.58579C21.7893 3.96086 22 4.46957 22 5V19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H20ZM20 5H4V15.1L8.995 10.106C9.11108 9.98989 9.24889 9.89779 9.40057 9.83495C9.55225 9.77211 9.71482 9.73977 9.879 9.73977C10.0432 9.73977 10.2058 9.77211 10.3574 9.83495C10.5091 9.89779 10.6469 9.98989 10.763 10.106L14.828 14.172L16.066 12.934C16.1821 12.8179 16.3199 12.7258 16.4716 12.6629C16.6232 12.6001 16.7858 12.5678 16.95 12.5678C17.1142 12.5678 17.2768 12.6001 17.4284 12.6629C17.5801 12.7258 17.7179 12.8179 17.834 12.934L20 15.101V5ZM15.5 7C15.8978 7 16.2794 7.15804 16.5607 7.43934C16.842 7.72064 17 8.10218 17 8.5C17 8.89782 16.842 9.27936 16.5607 9.56066C16.2794 9.84196 15.8978 10 15.5 10C15.1022 10 14.7206 9.84196 14.4393 9.56066C14.158 9.27936 14 8.89782 14 8.5C14 8.10218 14.158 7.72064 14.4393 7.43934C14.7206 7.15804 15.1022 7 15.5 7Z"
        fill="#599BF7"
      />
    </svg>
  );
};

export default UploadImageIcon;