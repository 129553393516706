import { Box, Typography } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../store/selector';
import { styled } from '@mui/material';
import Heading from '../../../atoms/heading';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjectRepository from '../../../../utils/api/repositories/projectRepository';
import { BsBuilding, BsHouse } from 'react-icons/bs';
import PropertyRepository from '../../../../utils/api/repositories/propertyRepository';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import { PiMoney, PiUsersThreeLight } from 'react-icons/pi';
import Loader from '../../../atoms/loader';
import { getYearsArray } from '../../../../utils/helpers';
import StackChart from '../../../atoms/stackChart';
import YearSelector from '../../../atoms/yearSelector';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ManagerRepository from '../../../../utils/api/repositories/managerRepository';
import Parse from 'parse';
import UserModel from '../../../../utils/api/models/userModel';
import { Form, Formik } from 'formik';
import Dropdowndata from '../../../atoms/dropdown2';

export interface FormValues {
  name: string;
  role: string;
  commission: string;
  email: string;
  phone: string;
  phone2: string;
}

interface QuarterlyData {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
}
type Manager = {
  objectId: any;
  firstName: string;
  lastName: string;
};

const PropertyGraph = () => {
  const mode = useSelector(selectThemeMode);
  const [quarterlyData, setQuarterlyData] = useState<QuarterlyData>({ Q1: 0, Q2: 0, Q3: 0, Q4: 0 });
  const [loading, setLoading] = useState(true);
  const [managers, setManagers] = useState<Parse.Object[]>([]);
  const [selectedStartYear, setSelectedStartYear] = useState<number>(new Date().getFullYear());
  const [selectedEndYear, setSelectedEndYear] = useState<number>(new Date().getFullYear());
  const [selectedManagerId, setSelectedManagerId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization').id;
        if (organizationId) {
          const propertyRepository = new PropertyRepository();
          const data = await propertyRepository.getTotalSalesForOrganizationByRange(
            organizationId,
            selectedStartYear,
            selectedEndYear,
            selectedManagerId === 'all' ? '' : selectedManagerId
          );
          if (data) {
            setQuarterlyData(data);
          }
        }
      }
      setLoading(false);
    };

    fetchData();
  }, [selectedStartYear, selectedEndYear, selectedManagerId]);

  useEffect(() => {
    const fetchManagers = async () => {
      const userRepository = new UserRepository();
      const allManagers = await userRepository.getAllManagers();
      if (allManagers) {
        setManagers(allManagers);
      }
    };
    fetchManagers();
  }, []);

  return (
    <CardContainer
      sx={{
        flexDirection: 'column',
        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
      }}
    >
      {loading ? (
        <Box height={'346px'} display={'flex'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            paddingBottom={'20px'}
            paddingLeft={'30px'}
            paddingRight={'30px'}
            paddingTop={'10px'}
          >
            <Heading text={'Revenue'} />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box>
                <YearSelector
                  label="Start"
                  mode={mode}
                  values={getYearsArray()}
                  selectedValue={selectedStartYear}
                  setSelectedValue={setSelectedStartYear}
                />
              </Box>
              <Box sx={{ marginLeft: '5px' }}>
                <YearSelector
                  label="End"
                  mode={mode}
                  values={getYearsArray()}
                  selectedValue={selectedEndYear}
                  setSelectedValue={setSelectedEndYear}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection={'column'} paddingBottom={'20px'}>
            {managers.length > 0 && (
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Box width={'220px'} paddingLeft={'0.5rem'} paddingRight={'0.5rem'}>
                  <Formik
                    initialValues={{
                      manager: '',
                    }}
                    validate={(values) => {}}
                    onSubmit={async (values, { resetForm, setSubmitting }) => {
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <Form>
                        <Dropdowndata
                          mode={mode}
                          id="manager"
                          name="manager"
                          values={[
                            { label: 'All Teams', value: 'all' },
                            ...managers.map((manager) => {
                              return { label: manager.get('firstName') + ' ' + manager.get('lastName'), value: manager.id };
                            }),
                          ]}
                          value={selectedManagerId}
                          onChange={(e) => {
                            setSelectedManagerId(e.target.value);
                          }}
                          defaultValue={'all'}
                        />
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            )}
            <Box sx={{ width: '100%', height: '300px', padding: '10px 20px' }}>
              <StackChart
                data={[
                  { name: `${selectedStartYear.toString().slice(2)}Q1`, uv: quarterlyData['Q1'], pv: quarterlyData['Q1'], amt: quarterlyData['Q1'] },
                  { name: `${selectedStartYear.toString().slice(2)}Q2`, uv: quarterlyData['Q2'], pv: quarterlyData['Q2'], amt: quarterlyData['Q2'] },
                  { name: `${selectedStartYear.toString().slice(2)}Q3`, uv: quarterlyData['Q3'], pv: quarterlyData['Q3'], amt: quarterlyData['Q3'] },
                  { name: `${selectedStartYear.toString().slice(2)}Q4`, uv: quarterlyData['Q4'], pv: quarterlyData['Q4'], amt: quarterlyData['Q4'] },
                ]}
              />
            </Box>
          </Box>
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  // marginTop: '3%',
  borderRadius: '16px',
  border: `1px solid ${globalColors?.border.gray}`,
});

const Card = styled(Box)(({ mode }: { mode: any }) => ({
  width: '30%',
  height: '240px',
  border: `1px solid ${globalColors.black}`,
  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
  borderRadius: '32px',
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
}));

const GraphText = styled(Typography)(({ mode }: { mode: any }) => ({
  fontFamily: 'Poppins',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: mode === 'dark' ? globalColors.white : globalColors.black,
}));

const GraphHeading = styled(Typography)(({ mode }: { mode: any }) => ({
  fontFamily: 'Poppins',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '15px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: mode === 'dark' ? globalColors.gray : globalColors.black,
}));

const MiniCard = styled(Typography)(({ mode }: { mode: any }) => ({
  width: '48%',
  height: '128px',
  borderRadius: '8px',
  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export default PropertyGraph;
