import { Box, Divider, FormControl, FormGroup, Grid, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { globalColors } from '../../../../../utils/constants/color';
import { Button, FieldInput, FieldInputTextarea, Toast } from '../../../../atoms';
import { Form, Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import Loader from '../../../../atoms/loader';

interface ModalDetailLeadProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  handleTags: any;
}

interface ValuesAddTag {
  name: string;
}

const AddTagModal = ({ open, onClose, mode, handleTags }: ModalDetailLeadProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '728px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',

    '@media (max-width: 576px)': {
      width: '80%',
      height: '600px',
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      {loading ? (
        <Box sx={style}>
          <Loader />
        </Box>
      ) : (
        <Box sx={style} className="content" display={'flex'} flexDirection={'column'} width={'100%'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '30px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            Add Tag
          </Typography>
          <Box sx={{ width: '100%', marginTop: '10px', borderBottom: `1px solid ${globalColors.background.gray}` }}></Box>

          <Formik
            initialValues={{
              name: '',
            }}
            validate={(values) => {
              const errors = {} as ValuesAddTag;

              if (!values.name || !values.name.trim()) {
                errors.name = 'Required';
              }

              return errors;
            }}
            onSubmit={async (values: ValuesAddTag, { setSubmitting, resetForm }: FormikHelpers<ValuesAddTag>) => {
              handleTags(values.name);
              onClose();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Form>
                <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'1.5rem'}>
                  <FieldInput
                    optional={false}
                    label="Name"
                    id="name"
                    mode={mode}
                    name={'name'}
                    placeholder="Name"
                    type="text"
                    error={touched.name && errors.name ? errors.name : false}
                  />
                  <Box display={'flex'} justifyContent={'center'}>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Loading...' : 'Create'}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '48px',
  background:
    color !== 'default' && color !== ''
      ? color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.blackLight
        : globalColors.whiteLight
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
    marginTop: '5px',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '10px',
}));

export default AddTagModal;
