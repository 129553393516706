import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { globalColors } from '../../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../../../store/selector';
import { CiEdit } from 'react-icons/ci';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagIcon from '@mui/icons-material/Flag';
import { styled } from '@mui/material';
import BedIcon from './bedIcon';
import BathroomIcon from './bathroomIcon';
import KitchenIcon from './kitchenIcon';
import WifiIcon from './wifiIcon';
const options = ['Save', 'Edit'];
const ITEM_HEIGHT = 48;

interface ContentRowProp {
  id: string;
  name: string;
  data: any;
}

const ContentRow: React.FC<ContentRowProp> = ({ id, name, data }) => {
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isHovered, setIsHovered] = useState(false);
  const [isHovered, setIsHovered] = useState('');
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [isHoveredIndex, setIsHoveredIndex] = useState<any>(null);
  const openMenu = Boolean(anchorEl);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAction = (option: any) => {
    if (option == 'Edit') {
      // navigate(route);
      console.log('Edit');
    }
  };

  const handleCardEnter = () => {
    setIsCardHovered(true);
  };

  const handleCardLeave = () => {
    setIsCardHovered(false);
  };

  return (
    <TableRow
      sx={{
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        width: '100%',
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        borderRadius: '10px',
        gap: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
      }}
      key={id}
    >
      {/* Name */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} alignItems={'center'} gap={'10px'}>
          {/* image */}
          <Box
            sx={{
              height: '32px',
              width: '32px',
              borderRadius: '6px',
              backgroundImage: `url(${data.cover.preview})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              cursor: 'pointer',
            }}
          ></Box>
          {/* name */}
          {data?.name}
        </Box>
      </TableCell>

      {/* Address */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '28px',
              letterSpacing: '-2%',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            {data.address ? data.address : '-'}
          </Typography>
        </Box>
      </TableCell>

      {/* Space */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '28px',
              letterSpacing: '-2%',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            {data.area === '' ? '-' : data.area} {data.area === '' ? '' : data.areaUnit}
          </Typography>
        </Box>
      </TableCell>

      {/* status */}
      <TableCell style={{ color: data.status === 'Available' ? 'rgba(0, 203, 69, 1)' : 'rgba(219, 0, 0, 1)' }} sx={style}>
        {data?.status}
      </TableCell>

      {/* price */}
      <TableCell style={{ color: 'rgba(89, 155, 247, 1)' }} sx={style}>
        {data.price === '0' ? '-' : data?.price}
      </TableCell>

      {/* interest */}
      {/* <TableCell>
        <Typography sx={{ display: 'flex' }} width={'10px'} fontSize={'12px'} flexGrow={1} textAlign="left">
          {dummyData.length > 0
            ? dummyData.length === 1
              ? dummyData[0]
              : dummyData.slice(0, 3).map((e: any, i: any) => {
                  return (
                    <AvatarContainer
                      sx={{
                        marginLeft: i === 0 ? '' : '-6px !important',
                        backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
                        cursor: 'pointer',
                      }}
                      onMouseEnter={() => {
                        setIsHovered(e);
                        setIsHoveredIndex(id);
                      }}
                      onMouseLeave={() => setIsHovered('')}
                      mode={mode}
                    >
                      {e.charAt(0).toUpperCase()}
                      {isHovered === e && isHoveredIndex === id && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '100%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: '#333',
                            color: '#fff',
                            padding: '4px',
                            borderRadius: '4px',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                          }}
                        >
                          {e}
                        </Box>
                      )}
                    </AvatarContainer>
                  );
                })
            : ''}
          {dummyData.length > 3 ? (
            <AvatarContainer
              sx={{
                marginLeft: '-6px !important',
                color: mode !== 'dark' ? globalColors.black : globalColors.white,
                cursor: 'pointer',
              }}
              onMouseEnter={() => {
                handleCardEnter();
                setIsHoveredIndex(id);
              }}
              onMouseLeave={handleCardLeave}
              mode={mode}
            >
              <span>+{dummyData.length - 3}</span>
            </AvatarContainer>
          ) : (
            <></>
          )}
        </Typography>
        {isCardHovered && isHoveredIndex === id && (
          <div
            style={{
              position: 'absolute',
              backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
              border: '1px solid rgba(48, 48, 48, 1)',
              borderRadius: '10px',
              padding: '8px',
              zIndex: '1',
              whiteSpace: 'nowrap',
              display: 'flex',
              flexDirection: 'column',
              right: '10%',
            }}
          >
            {dummyData.slice(3).map((name: any, index: any) => (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={index}>
                <AvatarContainer mr={'16px'} mode={mode}>
                  {name.charAt(0).toUpperCase()}
                </AvatarContainer>
                <div style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>{name}</div>
              </div>
            ))}
          </div>
        )}
      </TableCell> */}

    </TableRow>
  );
};

const AvatarContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '7px',
}));

export default ContentRow;
