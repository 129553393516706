import { styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { selectColor, selectThemeMode } from '../../../../store/selector';
import { globalColors } from '../../../../utils/constants/color';

interface SearchInputProps {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value?: string;
  bg?: string;
}
interface SearchProps {
  mode: string;
  bg?: string;
}

const SearchInput = ({ placeholder = 'Search', onChange, className, value, bg }: SearchInputProps) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  return (
    <Search className={className} mode={mode} bg={bg}>
      <FaSearch size={'1.2rem'} color={color || '#0455C6'} style={{ marginTop: '3px' }} />
      <input className="input" type="search" value={value} placeholder={placeholder} onChange={onChange} />
    </Search>
  );
};

const Search = styled('div')<SearchProps>(({ theme, mode, bg }) => ({
  // width: '197px',
  background: bg ? bg : mode === 'dark' ? globalColors.black : globalColors.white,
  display: 'flex',
  gap: '1rem',
  padding: '0.625rem 1.25rem',
  borderRadius: '14px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
  color: globalColors.text.secondary,
  '& .input': {
    background: globalColors.text.secondary,
    fontSize: '10.75rem',
    all: 'unset',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  '& input::placeholder': {
    fontSize: '0.75rem',
  },
}));

export default SearchInput;
