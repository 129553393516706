import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Stack, Typography } from '@mui/material';
import LeadConversionRequestModel from '../../../../utils/api/models/leadConversionRequestModel';
import { globalColors } from '../../../../utils/constants/color';
import Loader from '../../../atoms/loader';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import LeadConversionRequestRepository from '../../../../utils/api/repositories/leadConversionRequestRepository';
import { formatDate, formatMoney, removeIdentifier } from '../../../../utils/helpers';
import { ThemeProvider } from '@mui/styles';
import { crossFileContainerStyle } from '../../../organisms/leads/leadsForm.style';
import { Button, FieldInput, Toast } from '../../../atoms';
import FileIcon from '../../../organisms/leads/FileIcon';
import { refreshConversionRequests } from '../../../../store/slices';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import GroupInput from '../../groupInput';
import Dropdown from '../../../atoms/dropdown';

interface RequestDetailsProps {
  id: string;
  mode: string;
  color: string;
  onClose: () => void;
  onReject: () => Promise<void>;
}

export interface FormValuesLock {
  lockAmount: string;
  lockMetric: string;
  paymentMethod: string;
  nature: string;
  currency: string;
}

const RequestDetails = ({ id, mode, color, onClose, onReject }: RequestDetailsProps) => {
  const [conversionRequest, setConversionRequest] = useState<LeadConversionRequestModel | null>(null);
  const [loading, setloading] = useState<boolean>(true);
  const [existingAttachments, setExistingAttachments] = useState<Parse.Object[]>([]);
  const dispatch = useDispatch();
  const [buttonsLoading, setButtonsLoading] = useState(false);
  const [showLock, setShowLock] = useState(true);
  const [applyDiscount, setApplyDiscount] = useState(false);

  const getRequest = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const repository = new LeadConversionRequestRepository();
      if (id) {
        await repository
          .getModelById(id)
          .then((res) => {
            if (res) {
              setConversionRequest(res);
              setloading(false);
            } else {
              setloading(false);
            }
          })
          .catch((err) => {
            setloading(false);
          });
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };

  const getAllAttachments = async () => {
    const leadConversionRequestRepository = new LeadConversionRequestRepository();
    const attachments = await leadConversionRequestRepository.getAllAttachments(id ?? '');
    if (attachments) {
      setExistingAttachments(attachments);
    }
  };

  const handleDownloadAll = async () => {
    try {
      for (const file of existingAttachments) {
        const response = await fetch(file.get('file')['_url']);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.get('file')['_name'];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  };

  const approveRequest = async (values?: FormValuesLock) => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const repository = new LeadConversionRequestRepository();
      if (id) {
        const approvedRequest = await repository.approveRequest(id, values);
        if (approvedRequest) {
          dispatch(refreshConversionRequests(true));
          onClose();
          Toast('Lead conversion request approved successfully.', 'success');
        } else {
          Toast('Lead conversion request could not be approved successfully. Please try again.', 'error');
        }
      }
    }
  };

  const onSubmitLock = async (values: FormValuesLock) => {
    setButtonsLoading(true);
    await approveRequest(values).then(() => {
      setButtonsLoading(false);
    });
  };

  useEffect(() => {
    getRequest();
    getAllAttachments();
  }, []);

  return (
    <>
      <Box
        className="scroll"
        marginTop={'30px'}
        display={'flex'}
        sx={{ overflow: 'auto', height: '65vh' }}
        flexDirection={'column'}
        gap={'1rem'}
        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
        padding={'20px'}
        borderRadius={'10px'}
        color={globalColors.white}
        fontSize={'12px'}
      >
        {loading ? (
          <Box display={'flex'} flexDirection={'column'} gap={'0'} marginBottom={'10px'}>
            <Loader />
          </Box>
        ) : (
          <>
            {conversionRequest ? (
              <>
                <Box display={'flex'} flexDirection={'column'} gap={'0'} marginBottom={'10px'}>
                  <Typography
                    fontSize={'20px'}
                    textTransform={'capitalize'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                    marginTop={'auto'}
                    marginBottom={'auto'}
                  >
                    {conversionRequest.lead?.get('firstName') ?? ''} {conversionRequest.lead?.get('lastName') ?? ''}
                  </Typography>
                  <Typography marginTop={'auto'} marginBottom={'auto'} color={color || globalColors.lightblue}>
                    {conversionRequest.lead?.get('category') ?? ''}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Converted By
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.convertedBy?.get('firstName') ?? 'Unknown'} {conversionRequest.convertedBy?.get('lastName') ?? 'Member'}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Converted On
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.convertedOn ?? ''}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.convertedOn} Title
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.convertedOn === 'Property'
                      ? conversionRequest.property?.get('name') ?? ''
                      : conversionRequest.project?.get('name') ?? ''}
                  </Typography>
                </Box>
                {conversionRequest.convertedOn === 'Project' && conversionRequest.projectComponent && (
                  <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                    <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                      Component
                    </Typography>
                    <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                      {conversionRequest.projectComponent?.get('name') ?? ''}
                    </Typography>
                  </Box>
                )}
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Amount Paid
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.currency ?? 'PKR'} {conversionRequest.sellingPrice ? formatMoney(conversionRequest.sellingPrice) : '-'}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Discount (%) Given
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.discountPercentage ?? '-'}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Paid At
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.soldAt ? formatDate(conversionRequest.soldAt) : '-'}
                  </Typography>
                </Box>
                <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Sold By
                  </Typography>
                  <Typography fontSize={'12px'} color={color || globalColors.blue} marginTop={'auto'} marginBottom={'auto'}>
                    {conversionRequest.soldBy?.get('firstName') ?? 'Unknown'} {conversionRequest.soldBy?.get('lastName') ?? 'Member'}
                  </Typography>
                </Box>
                <Box display={'flex'} gap={'10px'} justifyContent={'start'}>
                  <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
                    Payment Proof
                  </Typography>
                  {existingAttachments.length > 0 && (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: '11px',
                        lineHeight: '32px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        color: color || globalColors.blue,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleDownloadAll();
                      }}
                    >
                      Download All
                    </Typography>
                  )}
                </Box>
                {existingAttachments.length > 0 && (
                  <Box sx={{ marginTop: '2%' }}>
                    <ThemeProvider
                      theme={{
                        palette: {
                          primary: {
                            main: '#007FFF',
                            dark: '#0066CC',
                          },
                        },
                      }}
                    >
                      <Box display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                        {existingAttachments.map((attachment, idx) => {
                          return (
                            <Box sx={crossFileContainerStyle({ selectedFiles: existingAttachments, color, mode })}>
                              <Box
                                sx={{ display: 'flex', gap: '10px' }}
                                onClick={async () => {
                                  try {
                                    const response = await fetch(attachment.get('file')['_url']);
                                    const blob = await response.blob();
                                    const blobUrl = URL.createObjectURL(blob);

                                    const link = document.createElement('a');
                                    link.href = blobUrl;
                                    link.download = attachment.get('file')['_name'];
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);

                                    URL.revokeObjectURL(blobUrl);
                                  } catch (error) {
                                    Toast('This attachment could not be downloaded successfully. Please try again.', 'error');
                                  }
                                }}
                              >
                                <FileIcon />
                                <Typography
                                  noWrap
                                  style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '32px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                  }}
                                >
                                  {removeIdentifier(attachment.get('file')['_name'])}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </ThemeProvider>
                  </Box>
                )}
                {/* {conversionRequest.lead?.get('discountPercentage') && (
                  <Box display={'flex'} gap={'10px'} justifyContent={'start'} alignItems={'center'}>
                    <Typography fontSize={'13px'} color={globalColors.gray}>
                      Apply the <span style={{ color: color || globalColors.blue }}>{conversionRequest.lead?.get('discountPercentage')}%</span>{' '}
                      discount
                    </Typography>
                    <Checkbox
                      size="small"
                      sx={{
                        color: color || globalColors.blue,
                        '&.Mui-checked': {
                          color: color || globalColors.secondBlue,
                        },
                      }}
                      onChange={() => setApplyDiscount(!applyDiscount)}
                      checked={applyDiscount}
                    />
                  </Box>
                )} */}
                <Box display={'flex'} gap={'10px'} justifyContent={'start'} alignItems={'center'}>
                  <Typography fontSize={'13px'} color={globalColors.gray}>
                    Lock the{' '}
                    {conversionRequest.convertedOn === 'Property' ? 'Property' : conversionRequest.projectComponent ? 'Project Component' : 'Project'}
                  </Typography>
                  <Checkbox
                    size="small"
                    sx={{
                      color: color || globalColors.blue,
                      '&.Mui-checked': {
                        color: color || globalColors.secondBlue,
                      },
                    }}
                    onChange={() => setShowLock(!showLock)}
                    checked={showLock}
                  />
                </Box>

                {
                  <Formik
                    enableReinitialize
                    initialValues={{
                      lockAmount: '1',
                      lockMetric: 'day(s)',
                      paymentMethod: conversionRequest.paymentMethod ?? '',
                      nature: conversionRequest.nature ?? '',
                      currency: conversionRequest.currency ?? '',
                    }}
                    validate={(values) => {
                      const errors = {} as FormValuesLock;

                      if (showLock && (!values.lockAmount || parseInt(values.lockAmount) < 1)) {
                        errors.lockAmount = 'Please enter a valid amount greater than 0';
                      }

                      if (showLock && values.lockMetric === 'hour(s)' && parseInt(values.lockAmount) < 12) {
                        errors.lockAmount = "The lock duration can't be lesser than 12 hour(s)";
                      }

                      if (!values.paymentMethod || values.paymentMethod === 'Select') {
                        errors.paymentMethod = 'Required';
                      }
                      if (!values.currency || values.currency === 'Select') {
                        errors.currency = 'Required';
                      }
                      if (!values.nature || values.nature === 'Select') {
                        errors.nature = 'Required';
                      }

                      return errors;
                    }}
                    onSubmit={onSubmitLock}
                  >
                    {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                      <Form>
                        <Box
                          borderRadius={'20px'}
                          marginBottom={'2px'}
                          bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                          display={'flex'}
                          gap={'1rem'}
                        >
                          {showLock && (
                            <>
                              <FieldInput
                                id="lockAmount"
                                value={values.lockAmount.toString()}
                                mode={mode}
                                name={'lockAmount'}
                                placeholder={'Lock for'}
                                type={'number'}
                                error={touched.lockAmount && errors.lockAmount ? errors.lockAmount : false}
                              />
                              <Dropdown
                                mode={mode}
                                id="lockMetric"
                                name="lockMetric"
                                values={['Select', 'hour(s)', 'day(s)', 'month(s)']}
                                defaultValue={values.lockMetric}
                                error={touched.lockMetric && errors.lockMetric ? errors.lockMetric : false}
                              />
                            </>
                          )}
                        </Box>
                        <Box
                          borderRadius={'20px'}
                          marginBottom={'2px'}
                          bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                          display={'flex'}
                          gap={'1rem'}
                          paddingTop={showLock ? '1rem' : 0}
                        >
                          <Dropdown
                            optional={false}
                            label="Payment Method"
                            mode={mode}
                            id="paymentMethod"
                            name="paymentMethod"
                            values={['Select', 'Bank Transfer', 'Cash', 'Cheque', 'Other']}
                            defaultValue={values.paymentMethod}
                            error={touched.paymentMethod && errors.paymentMethod ? errors.paymentMethod : false}
                          />
                          <Dropdown
                            optional={false}
                            label="Currency"
                            mode={mode}
                            id="currency"
                            name="currency"
                            values={['Select', 'PKR', 'USD', 'AED']}
                            defaultValue={values.currency}
                            error={touched.currency && errors.currency ? errors.currency : false}
                          />
                        </Box>
                        <Box paddingTop={'1rem'}>
                          <Dropdown
                            optional={false}
                            label="Nature"
                            mode={mode}
                            id="nature"
                            name="nature"
                            values={['Select', 'Token', 'Partial Payment', 'Full Payment']}
                            defaultValue={values.nature}
                            error={touched.nature && errors.nature ? errors.nature : false}
                          />
                        </Box>

                        <Box display={'flex'} gap={'10px'} paddingTop={'2rem'} justifyContent={'center'}>
                          <Button
                            disabled={buttonsLoading}
                            onClick={async () => {
                              setButtonsLoading(true);
                              await onReject().then(() => {
                                setButtonsLoading(false);
                              });
                            }}
                            buttonColor={globalColors.red}
                          >
                            {buttonsLoading ? 'Loading...' : 'Reject'}
                          </Button>
                          <Button type="submit">{buttonsLoading ? 'Loading...' : 'Approve'}</Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                }
                {/* {!showLock && (
                  <Box display={'flex'} gap={'10px'} paddingTop={'8px'} justifyContent={'center'}>
                    <Button
                      disabled={buttonsLoading}
                      onClick={async () => {
                        setButtonsLoading(true);
                        await onReject().then(() => {
                          setButtonsLoading(false);
                        });
                      }}
                      buttonColor={globalColors.red}
                    >
                      {buttonsLoading ? 'Loading...' : 'Reject'}
                    </Button>
                    <Button
                      onClick={async () => {
                        setButtonsLoading(true);
                        await approveRequest().then(() => {
                          setButtonsLoading(false);
                        });
                      }}
                    >
                      {buttonsLoading ? 'Loading...' : 'Approve'}
                    </Button>
                  </Box>
                )} */}
              </>
            ) : (
              <Typography>Lead conversion request details could not be found.</Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default RequestDetails;
