import { Toast } from '../../../components/atoms';
import { UserRole } from '../../../store/user';
import ActivityModel from '../models/activityModel';
import OnboardingModel from '../models/onboardingModel';
import LeadRepository from './leadRepository';
import ManagerRepository from './managerRepository';
import SalesExecutiveRepository from './salesExecutiveRepository';
import UserRepository from './userRepository';
import Parse from 'parse';

class OnboardingRepository {
  private className = 'Onboarding';

  public async create(object: OnboardingModel): Promise<Parse.Object | null> {
    try {
      const Onboarding = Parse.Object.extend(this.className);
      const newOnboarding = new Onboarding();

      newOnboarding.set('fullName', object.fullName);
      newOnboarding.set('organizationName', object.organizationName);
      newOnboarding.set('designation', object.designation);
      newOnboarding.set('ownerName', object.ownerName);
      newOnboarding.set('ownerEmail', object.ownerEmail);
      newOnboarding.set('businessLocation', object.businessLocation);
      newOnboarding.set('adminEmail', object.adminEmail);
      newOnboarding.set('numberUsers', object.numberUsers);
      newOnboarding.set('paymentCycle', object.paymentCycle);
      newOnboarding.set('tier', object.tier);
      newOnboarding.set('amountDue', object.amountDue);
      newOnboarding.set('perMonthAmount', object.perMonthAmount);

      return new Promise((resolve, _) => {
        newOnboarding
          .save(null, { useMasterKey: true })
          .then(async (savedOnboarding: Parse.Object | null) => {
            if (savedOnboarding) {
              const savedAdminPassword = await Parse.Cloud.run(
                'saveAdminPassword',
                {
                  password: object.adminPassword,
                  onboardingId: savedOnboarding.id,
                  API_KEY: process.env.REACT_APP_PARSE_CLOUD_API_KEY,
                },
                { useMasterKey: true }
              );
              if (savedAdminPassword && savedAdminPassword.data) {
                const sentPaymentLink = await Parse.Cloud.run(
                  'sendPaymentLink',
                  {
                    onboardingId: savedOnboarding.id,
                    emailTo: object.adminEmail,
                  },
                  { useMasterKey: true }
                );
                resolve(savedOnboarding);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          })
          .catch((error: Error) => {
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error adding onboarding:', error);
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const Onboarding = Parse.Object.extend(this.className);
      const query = new Parse.Query(Onboarding);
      const onboarding = await query.get(id, { useMasterKey: true });
      return onboarding;
    } catch (error) {
      // console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async verifyDiscountCode(code: string): Promise<number | null> {
    try {
      const verifiedDiscountCode = await Parse.Cloud.run(
        'verifyDiscountCode',
        {
          code: code,
        },
        { useMasterKey: true }
      );
      if (verifiedDiscountCode && verifiedDiscountCode.data) {
        return verifiedDiscountCode.data;
      }
      return null;
    } catch (e) {
      return null;
    }
  }
}

export default OnboardingRepository;
