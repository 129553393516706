import { useEffect, useState } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../../../utils/constants/color';
import { selectThemeMode } from '../../../../../store/selector';
import ContentRow from './contentRow';
import NotFoundLottie from '../../../../atoms/notFound';
import Loader from '../../../../atoms/loader';

const headerContent = ['Title', 'Assigned To', 'Assigned At', 'Due Date', 'Priority', 'Status', 'Actions'];

interface ContentTableProps {
  tasks: Parse.Object[];
}

const ContentTable = ({ tasks }: ContentTableProps) => {
  const mode = useSelector(selectThemeMode);
  const [loading, setLoading] = useState(false);

  return (
    <Box
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={{ xs: 0, md: '30px' }}
      paddingRight={{ xs: 0, md: '30px' }}
      paddingTop={{ xs: 0, md: '0px' }}
      height={'100%'}
    >
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'200px'}>
          <Loader />
        </Box>
      ) : tasks.length > 0 ? (
        <TableContainer sx={{ paddingBottom: '30px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {headerContent.map((val) => (
                  <TableCell key={val} sx={{ color: globalColors.gray }}>
                    {val}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((item) => (
                <ContentRow data={item} key={item?.id} id={item?.id ?? ''} />
                
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box display={'flex'} justifyContent={'center'}>
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
            No Data Found
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ContentTable;
