import { styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { ICAddImage, ICDelete, ICPen } from '../../../assets/Icons';
import { globalColors } from '../../../utils/constants/color';
import { validateUploadImage } from '../../../utils/helpers/images';
import { ButtonIconCircle } from '../../atoms';
import { RiImageAddLine } from 'react-icons/ri';

export interface ChangeCover {
  files: File[];
  preview: string;
}
interface FieldCoverProps {
  label: string;
  image: string;
  className?: string;
  id: string;
  name: string;
  onChangeCover: (data: ChangeCover) => ChangeCover;
  onDeleteCover: () => void;
  color: string;
}
const FieldCover = ({ label = 'Label', image, className, id, name, onChangeCover, onDeleteCover, color }: FieldCoverProps) => {
  const handleInputCover = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const resultCover = validateUploadImage(files);

      if (resultCover.length > 0) {
        const preview = URL.createObjectURL(resultCover[0]);

        onChangeCover({
          files: resultCover,
          preview: preview,
        });
      }
    }
  };

  return (
    <FieldCoverStyle className={className}>
      {image && (
        <>
          <img className="preview-cover" src={image} alt={label} />
          <div className="input-cover-wrapper">
            <input type="file" name={name} id={id} onChange={handleInputCover} />
            <ButtonIconCircle alt={label} src={ICPen} />
          </div>
          <div className="btn-delete">
            <ButtonIconCircle type="button" src={ICDelete} onClick={onDeleteCover} />
          </div>
        </>
      )}

      {!image && (
        <div className="btn-add" style={{border: `1px solid ${color || globalColors.blue}`,}}>
          <input type="file" onChange={handleInputCover} />
          <RiImageAddLine size={'2rem'} color={color || globalColors.blue} />
        </div>
      )}
    </FieldCoverStyle>
  );
};

const FieldCoverStyle = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '200px',
  '& .preview-cover': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '6px',
  },
  '& .input-cover-wrapper': {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: '36px',
    width: '36px',
    '&:hover': {
      opacity: '0.7',
    },

    '& input': {
      cursor: 'pointer',
      position: 'absolute',
      height: '36px',
      width: '36px',
      opacity: 0,
    },
  },
  '& .btn-add': {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    
    borderRadius: '12px',
    width: '100%',
    height: '200px',
    '& input': {
      cursor: 'pointer',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },

  '& .btn-delete': {
    position: 'absolute',
    right: '8px',
    top: '8px',
    '& img': {
      width: '1rem',
      height: '1rem',
    },

    '&:hover': {
      opacity: '0.7',
    },
  },
}));

export default FieldCover;
