import { styled } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';

interface CardProductsStyleProp {
  mode: string;
}

export const CardProductItemStyle = styled('div')<CardProductsStyleProp>(({ theme, mode }) => ({
  // width: '328px',
  borderRadius: '20px',
  boxShadow: mode === 'dark' ? '2px 2px 7px 0px #1F1F1F' : '',
  border: `1px solid ${mode === 'dark' ? '#303030' : globalColors.border.gray}`,
  background: mode === 'dark' ? globalColors.background.gray : globalColors.lightgray,
  height: '100%',
  '& .cover-wrapper': {
    position: 'relative',
    height: '208px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '20px 20px 0px 0px',
    padding: '8px',

    '& .cover-image-product': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      top: 0,
      left: 0,
      borderRadius: '20px 20px 0px 0px',
    },
    '& .btn-action-wrapper': {
      zIndex: 1,
      display: 'flex',
      gap: '1rem',
      justifyContent: 'space-between',
      '& .left': { display: 'flex', flexDirection: 'column', gap: '5px' },
    },
    '& .count-photo-wrapper': {
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      height: '38px',
      gap: '8px',
      cursor: 'pointer',
      color: globalColors.white,
    },
  },
  '& .description-wrapper': {
    background: mode === 'dark' ? '#1F1F1F' : globalColors.white,
    padding: '1rem',
    borderRadius: '0 0 20px 20px',
    '& h3, p': {
      margin: '0',
    },
    '& .title-project': {
      fontSize: '1rem',
      fontWeight: 400,
      color: mode === 'dark' ? globalColors.white : globalColors.black,

      '&.property': {
        display: 'flex',
        justifyContent: 'space-between',
        '& .price': {
          fontSize: '1rem',
          fontWeight: 500,
        },
      },
    },
    '& .detail': {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: mode === 'dark' ? globalColors.text.secondary : globalColors.black,
      '&.status': { textAlign: 'right' },
    },
    '& .detail-area': {
      fontSize: '0.875rem',
      fontWeight: 800,
      color: mode === 'dark' ? globalColors.text.secondary : globalColors.black,
    },
  },

  // TODO : refactor buttonIconCircle.tsx
  '& .btn-circle': {
    background: globalColors.icon.whiteSmoke,
    height: '32px',
    width: '32px',
    borderRadius: '100%',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },

  '& .tooltip': {
    position: 'absolute',
    backgroundColor: 'rgba(16, 16, 16, 1)',
    border: '1px solid rgba(48, 48, 48, 1)',
    padding: '5px',
    zIndex: '1',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
  },

  '& .tooltip span': {
    margin: '5px 0',
  },
}));