import { useEffect, useState } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Grid, Typography, Tooltip } from '@mui/material';
import Heading from '../../../../atoms/heading';
import { useDispatch, useSelector } from 'react-redux';
import { globalColors } from '../../../../../utils/constants/color';
import { selectAddNew, selectColor, selectThemeMode } from '../../../../../store/selector';
import { Button } from '../../../../atoms';
import ContentRow from './contentRow';
import SocialPostRepository from '../../../../../utils/api/repositories/socialPostRepository';
import { setAddNew } from '../../../../../store/routes';
import NotFoundLottie from '../../../../atoms/notFound';
import moment from 'moment';
import CommissionRepository from '../../../../../utils/api/repositories/commissionRepository';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import UserModel from '../../../../../utils/api/models/userModel';
import Loader from '../../../../atoms/loader';
import OrganizationRepository from '../../../../../utils/api/repositories/organizationRepository';
import { RiQuestionFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';

const headerContent = ['Name', 'Role', 'Assigned Leads', 'Communication', 'Commission (%)', 'Actions'];

interface CountsInterface {
  total: number;
  existing: number;
}

const ContentTable = () => {
  const [commissions, setCommissions] = useState<UserModel[]>([]);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [loading, setLoading] = useState(true);
  const showArrow = useSelector(selectAddNew);
  const [counts, setCounts] = useState<CountsInterface>({ total: -1, existing: -1 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCommission = async () => {
    const userRepository = new UserRepository();
    const users = await userRepository.getAllUsersForOrganizationIncludingCurrentUser();
    if (users) {
      setCommissions(users);
    }
    const organizationRepository = new OrganizationRepository();
    const counts: CountsInterface | null = await organizationRepository.getSignedUpMembers();
    if (counts) {
      setCounts(counts);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCommission();
  }, []);

  return (
    <Box
      marginTop={'1%'}
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      // border={`1px solid ${globalColors?.border.gray}`}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={'30px'}
      paddingRight={'30px'}
      paddingTop={'10px'}
      height={'100%'}
    >
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'200px'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Grid container paddingBottom={'15px'} paddingTop={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={12} sm={6}>
              <Box display={'flex'} alignItems={'center'} gap={'1rem'}>
                <Heading text={'Members'} />
                {counts.existing !== -1 && counts.total !== -1 && (
                  <Typography sx={{ color: color || globalColors.blue }}>
                    {counts.existing} / {counts.total}
                  </Typography>
                )}
                {counts.existing !== -1 && counts.total !== -1 && (
                  <Tooltip
                    title={
                      <>
                        <Typography fontSize={12}>Your organization quota allows you to onboard a maximum of {counts.total} members.</Typography>
                      </>
                    }
                  >
                    <Box>
                      <RiQuestionFill size={'1.5rem'} color={globalColors.gray} />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} paddingTop={{ xs: '1rem', sm: 0 }} display={'flex'} justifyContent={'end'}>
              <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'end'} alignItems={'end'} gap={'10px'} width={'100%'}>
                <Button
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: '/performance/company' }));
                    navigate('/performance/company/manage');
                  }}
                  type="button"
                >
                  Manage
                </Button>
                {counts.existing < counts.total ? (
                  <Button
                    disabled={
                      counts.existing == -1 ||
                      counts.total == -1 ||
                      (counts.existing !== -1 && counts.total !== -1 && counts.existing >= counts.total)
                    }
                    onClick={() => {
                      dispatch(setAddNew({ addNew: true, path: '/performance/company' }));
                      navigate('/hr/members/addMember');
                    }}
                    type="button"
                  >
                    Add New Member
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      navigate('/settings/payments/changePlan');
                    }}
                    type="button"
                  >
                    Upgrade Plan
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
          {commissions.length > 0 ? (
            <TableContainer sx={{ paddingBottom: '30px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headerContent.map((val) => (
                      <TableCell sx={{ color: globalColors.gray }}>{val}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissions.map((item) => (
                    <ContentRow data={item} key={item?.objectId} id={item?.objectId} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NotFoundLottie showButton={false} text="Looks like you have no Employee yet." buttonText="" navigateTo="" />
          )}
        </>
      )}
    </Box>
  );
};

export default ContentTable;
