import { Box, Typography } from '@mui/material';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaLocationDot } from 'react-icons/fa6';
import { FiMail } from 'react-icons/fi';
import { globalColors } from '../../../utils/constants/color';
import { useNavigate } from 'react-router';

const Footer = () => {

  const navigate = useNavigate();

  return (
    <Box bgcolor={globalColors.darkblack}>
      <Box display={'flex'} sx={{ justifyContent: { xs: 'center', lg: 'center' }, flexDirection: { xs: 'column', lg: 'row' } }} gap={'20px'}>
        {/* <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
          <Typography fontSize={'20px'} marginBottom={'20px'}>
            Company
          </Typography>
          <Typography>Home</Typography>
          <Typography>Assessment</Typography>
          <Typography>Gallery</Typography>
          <Typography>Talent pool</Typography>
        </Box> */}
        {/* <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
          <Typography fontSize={'20px'} marginBottom={'20px'}>
            More
          </Typography>
          <Typography>Skill library</Typography>
          <Typography>Career path</Typography>
          <Typography>Coaching</Typography>
          <Typography>Privacy Policy</Typography>
        </Box> */}
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'2px'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ padding: { lg: '5rem', xs: '3rem' } }}
        >
          <Typography fontSize={'20px'} marginBottom={'20px'}>
            Contact Us
          </Typography>
          <Typography textAlign={'center'}>
            <FaLocationDot color="#599BF7" />
            &nbsp;Headoffice: DSO, Building HQ, Dubai Silicon Oasis, UAE
          </Typography>
          <Typography textAlign={'center'}>
            <FiMail color="#599BF7" />
            &nbsp;register@propertycloud.ai
          </Typography>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={{xs: 'column', md: 'row'}} gap={'2rem'} justifyContent={'center'}>
        <Typography
          textAlign={'center'}
          fontSize={'14px'}
          color={globalColors.gray}
          sx={{ cursor: 'pointer', '&:hover': { color: '#599BF7', textDecoration: 'underline' } }}
          
        >
          <div onClick={() => {navigate('/policies/termsAndConditions')}}>Terms & Conditions</div>
        </Typography>
        <Typography
          textAlign={'center'}
          fontSize={'14px'}
          color={globalColors.gray}
          sx={{ cursor: 'pointer', '&:hover': { color: '#599BF7', textDecoration: 'underline' } }}
        >
          <div onClick={() => {navigate('/policies/privacy')}}>Privacy Policy</div>
        </Typography>
      </Box>
      <Typography textAlign={'center'} color={'#599BF7'} marginTop={'3rem'} paddingBottom={'1rem'}>
        All rights reserved @ Property Cloud 2023
      </Typography>
    </Box>
  );
};

export default Footer;
