import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import SocialPostModel from '../models/socialPostModel';
import GalleryRepository from './galleryRepository';
import GalleryModel from '../models/galleryModel';
import { isFile } from '../../helpers';
import UserRepository from './userRepository';
import { UserRole } from '../../../store/user';

class SocialPostRepository {
  private className = 'SocialPost';

  public async createSocialPost(object: SocialPostModel, userId: string, socialValues: any) {
    try {

      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const Post = Parse.Object.extend(this.className);
        const Gallery = Parse.Object.extend('Gallery');
        const SocialPlatform = Parse.Object.extend('SocialPlatform');
        const newPost = new Post();
        const socialPlatform = await this.getSocialPlatform(userId);
  
        // Update SocialPlatform values based on the object
        socialPlatform.set('facebook', (socialPlatform.get('facebook') || 0) + (socialValues.facebook ? 1 : 0));
        socialPlatform.set('twitter', (socialPlatform.get('twitter') || 0) + (socialValues.twitter ? 1 : 0));
        socialPlatform.set('instagram', (socialPlatform.get('instagram') || 0) + (socialValues.instagram ? 1 : 0));
        socialPlatform.set('youtube', (socialPlatform.get('youtube') || 0) + (socialValues.youtube ? 1 : 0));
  
        // Save SocialPlatform
        await socialPlatform.save(null, { useMasterKey: true });
  
        const socialPlatformPointer = SocialPlatform.createWithoutData(socialPlatform.id);
  
        newPost.set('user', currentUser);
        newPost.set('socialPlatform', socialPlatformPointer);
        newPost.set('organization', currentUser.get('organization'));
  
        newPost.set('name', object.name);
        newPost.set('text', object.text);
        newPost.set('facebook', socialValues.facebook ? 1 : 0);
        newPost.set('twitter', socialValues.twitter ? 1 : 0);
        newPost.set('instagram', socialValues.instagram ? 1 : 0);
        newPost.set('youtube', socialValues.youtube ? 1 : 0);
  
        // Check if media is provided
        if (object.media && isFile(object.media) && (object.media.type.startsWith('image/') || object.media.type.startsWith('video/'))) {
          const galleryRepository = new GalleryRepository();
          const galleryModel: GalleryModel = {
            file: object.media,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          newPost.set('media', savedFile);
        }
  
        // Check if allMedia is provided and not an image
        if (object.allMedia && isFile(object.allMedia) && !object.allMedia.type.startsWith('image/')) {
          const galleryRepository = new GalleryRepository();
          const galleryModel: GalleryModel = {
            file: object.allMedia,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          newPost.set('allMedia', savedFile);
        }
  
        // Save the newPost
        const savedPost = await newPost.save(null, { useMasterKey: true });
  
        // Log success and return the saved post
        console.log('New post created with objectId: ' + savedPost.id);
        Toast(`Post added successfully.`, 'success');
        return savedPost;
      }
      else {
        return null;
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error saving post: ${error.message}`, 'error');
      return null;
    }
  }

  public async getAllUserPosts(userId: string, selectedName?: string): Promise<Parse.Object[]> {
    try {

      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const Post = Parse.Object.extend(this.className);
        const query = new Parse.Query(Post);

        if (currentUser.get('role') == UserRole.admin) {
          query.equalTo('organization', currentUser.get('organization'));
        }
        else {
          query.equalTo('user', currentUser);
        }
  
  
        if (selectedName && ['facebook', 'instagram', 'youtube', 'twitter'].includes(selectedName.toLowerCase())) {
          query.greaterThan(selectedName.toLowerCase(), 0);
        }
  
        const posts = await query.find({ useMasterKey: true });
        const socialPosts: any[] = posts.map((post) => {
          return {
            objectId: post.id,
            name: post.get('name'),
            text: post.get('text'),
            facebook: post.get('facebook'),
            twitter: post.get('twitter'),
            instagram: post.get('instagram'),
            youtube: post.get('youtube'),
            createdAt: post.createdAt,
            updatedAt: post.updatedAt,
            // Add other fields as needed
          };
        });
  
        return socialPosts;
      }
      else {
        return [];
      }

    } catch (error: any) {
      console.log(error);
      Toast(`Error retrieving posts: ${error.message}`, 'error');
      return [];
    }
  }

  public async getAllPosts(): Promise<Parse.Object[]> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const Post = Parse.Object.extend(this.className);
        const query = new Parse.Query(Post);

        query.equalTo('organization', currentUser.get('organization'));
  
        const posts = await query.find({ useMasterKey: true });
        const socialPosts: any[] = posts.map((post) => {
          return {
            objectId: post.id,
            name: post.get('name'),
            text: post.get('text'),
            createdAt: post.createdAt,
            updatedAt: post.updatedAt,
            // Add other fields as needed
          };
        });
  
        return socialPosts;
      }
      else {
        return [];
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error retrieving posts: ${error.message}`, 'error');
      return [];
    }
  }

  // get user social platforms
  private async getSocialPlatform(userId: string): Promise<Parse.Object> {
    const SocialPlatform = Parse.Object.extend('SocialPlatform');
    const query = new Parse.Query(SocialPlatform);
    query.equalTo('user', Parse.User.createWithoutData(userId));

    try {
      const result = await query.first({ useMasterKey: true });

      if (result) {
        return result;
      } else {
        // If no SocialPlatform exists for the user, create a new one
        const newSocialPlatform = new SocialPlatform();
        newSocialPlatform.set('user', Parse.User.createWithoutData(userId));
        // Initialize other fields as needed
        await newSocialPlatform.save(null, { useMasterKey: true });
        return newSocialPlatform;
      }
    } catch (error: any) {
      console.error('Error fetching/creating social platform:', error.message);
      throw error;
    }
  }

  // Delete
  public async deletePost(userId: string, postId: string): Promise<boolean> {
    try {
      const Post = Parse.Object.extend(this.className);
      const User = Parse.Object.extend('_User');
      const userPointer = User.createWithoutData(userId);

      const query = new Parse.Query(Post);
      query.equalTo('user', userPointer);
      query.equalTo('objectId', postId);

      const postToDelete = await query.first({ useMasterKey: true });

      if (postToDelete) {
        // Delete the post
        await postToDelete.destroy({ useMasterKey: true });
        console.log('Post deleted successfully.');
        Toast('Post deleted successfully.', 'success');
        return true;
      } else {
        console.log('Post not found.');
        Toast('Post not found.', 'warning');
        return false;
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error deleting post: ${error.message}`, 'error');
      return false;
    }
  }

  // get post where user and post id match
  public async getPostById(userId: string, postId: string): Promise<any | null> {
    try {
      const Post = Parse.Object.extend(this.className);
      const query = new Parse.Query(Post);

      const User = Parse.Object.extend('_User');
      const userPointer = User.createWithoutData(userId);
      query.equalTo('user', userPointer);
      query.equalTo('objectId', postId); // Add this condition to match post id

      const post = await query.first({ useMasterKey: true });

      if (post) {
        return {
          objectId: post.id,
          name: post.get('name'),
          text: post.get('text'),
          facebook: post.get('facebook'),
          twitter: post.get('twitter'),
          instagram: post.get('instagram'),
          youtube: post.get('youtube'),
          createdAt: post.createdAt,
          updatedAt: post.updatedAt,
          // Add other fields as needed
        };
      } else {
        console.log('Post not found.');
        Toast('Post not found.', 'warning');
        return null;
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error retrieving post: ${error.message}`, 'error');
      return null;
    }
  }

  // update post
  public async updateSocialPost(userId: string, postId: string, updates: Partial<SocialPostModel>): Promise<boolean> {
    try {
      const Post = Parse.Object.extend(this.className);
      const User = Parse.Object.extend('_User');
      const SocialPlatform = Parse.Object.extend('SocialPlatform');
      const Gallery = Parse.Object.extend('Gallery');

      const userPointer = User.createWithoutData(userId);

      const query = new Parse.Query(Post);
      query.equalTo('user', userPointer);
      query.equalTo('objectId', postId);

      const post = await query.first({ useMasterKey: true });

      if (post) {
        const socialPlatform = await this.getSocialPlatform(userId);

        // Update SocialPlatform values based on the updates
        socialPlatform.set('facebook', (socialPlatform.get('facebook') || 0) + (updates.facebook ? 1 : 0));
        socialPlatform.set('twitter', (socialPlatform.get('twitter') || 0) + (updates.twitter ? 1 : 0));
        socialPlatform.set('instagram', (socialPlatform.get('instagram') || 0) + (updates.instagram ? 1 : 0));
        socialPlatform.set('youtube', (socialPlatform.get('youtube') || 0) + (updates.youtube ? 1 : 0));

        // Save SocialPlatform
        await socialPlatform.save(null, { useMasterKey: true });

        const socialPlatformPointer = SocialPlatform.createWithoutData(socialPlatform.id);

        // Update the post with the provided values
        for (const key in updates) {
          if (Object.prototype.hasOwnProperty.call(updates, key)) {
            const propertyKey = key as keyof SocialPostModel;
            post.set(propertyKey, updates[propertyKey]);
          }
        }

        post.set('user', userPointer);
        post.set('socialPlatform', socialPlatformPointer);

        // Check if media is provided
        if (updates.media && isFile(updates.media) && (updates.media.type.startsWith('image/') || updates.media.type.startsWith('video/'))) {
          const galleryRepository = new GalleryRepository();
          const galleryModel: GalleryModel = {
            file: updates.media,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          post.set('media', savedFile);
        }
  
        // Check if allMedia is provided and not an image
        if (updates.allMedia && isFile(updates.allMedia) && !updates.allMedia.type.startsWith('image/')) {
          const galleryRepository = new GalleryRepository();
          const galleryModel: GalleryModel = {
            file: updates.allMedia,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          post.set('allMedia', savedFile);
        }

        // Save the updated post
        await post.save(null, { useMasterKey: true });

        console.log('Post updated successfully.');
        Toast('Post updated successfully.', 'success');
        return true;
      } else {
        console.log('Post not found.');
        Toast('Post not found.', 'warning');
        return false;
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error updating post: ${error.message}`, 'error');
      return false;
    }
  }

  public async getPostsCount(platform: string): Promise<number | null> {

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const SocialPost = Parse.Object.extend(this.className);
      const query = new Parse.Query(SocialPost).equalTo(platform, 1);

      if (currentUser.get('role') == UserRole.admin) {
        query.equalTo('organization', currentUser.get('organization'));
      }
      else {
        query.equalTo('user', currentUser);
      }
  
      try {
        return new Promise((resolve, _) => {
          query.count({ useMasterKey: true }).then(
            (count) => {
              resolve(count);
            },
            (error: Error) => {
              resolve(null);
            }
          );
        });
      } catch (error) {
        console.error('Error counting projects:', error);
        return null;
      }
    }
    else {
      return null;
    }
  }
}

export default SocialPostRepository;
