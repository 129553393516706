import { styled } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const FormProductSkeleton = () => {
  return (
    <Wrapper>
      <Skeleton variant="rectangular" className="head skeleton" />
      <Skeleton variant="rectangular" className="sub-head skeleton" />
      <Skeleton variant="rectangular" className="sub-head skeleton" />
      <div className="content">
        <Skeleton className="skeleton" />
        <Skeleton className="text skeleton" />
        <Skeleton className="text skeleton" />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  '.skeleton': {
    backgroundColor: '#333333',
  },

  '.head': {
    height: '2rem',
  },
  '.sub-head': {
    height: '1.5rem',
    width: '70%',
  },

  '.content': {
    width: '40%',
  },
}));

export default FormProductSkeleton;
