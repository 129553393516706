import { Box } from '@mui/material';
import { Form } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
import logo from '../../assets/Images/logo.svg';
import { LandingHero } from '../../assets/Images';
import onboardingHero from '../../assets/Images/onboarding_hero.png';
import { globalColors } from '../../utils/constants/color';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../components/atoms';
import UserRepository from '../../utils/api/repositories/userRepository';
import { generateCountryListAsLabelValues } from '../../utils/helpers/countries';
const reactRenderer = require('@quillforms/react-renderer-utils');
reactRenderer.registerCoreBlocks();

const Onboarding = () => {
  const navigate = useNavigate();

  function handleClick(event: any) {
    event.preventDefault();
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      var passwordInput = document.getElementById('short-text-admin_password');

      if (passwordInput) {
        passwordInput.setAttribute('type', 'password');
        passwordInput.setAttribute('autoComplete', 'new-password');
        clearInterval(intervalId);
      }
    }, 2000);
    const intervalSecondId = setInterval(() => {
      var businessLocationDropdown = document.getElementsByClassName('block-dropdown-renderer-expand-icon');
      var businessLocationCloseDropdown = document.getElementsByClassName('block-dropdown-renderer-close-icon');
      for (let i = 0; i < businessLocationDropdown.length; i++) {
        var element = businessLocationDropdown[i] as HTMLElement;
        element.style.display = 'none';
      }
      for (let i = 0; i < businessLocationCloseDropdown.length; i++) {
        var element = businessLocationCloseDropdown[i] as HTMLElement;
        element.style.display = 'none';
      }
    }, 10);
  }, []);

  return (
    <Box sx={{ width: '100%', height: '100vh', backgroundColor: globalColors.mainBackground }}>
      <Form
        applyLogic
        formObj={{
          messages: {
            'block.defaultThankYouScreen.label':
              'Thank you for filling out the information. Redirecting you to payments, where your payment would be processed securely. Please do not close this browser window.',
          },
          settings: {
            disableProgressBar: true,
            showLettersOnAnswers: false,
            showQuestionsNumbers: false,
          },
          theme: {
            backgroundColor: globalColors.mainBackground,
            logo: {
              src: logo,
            },
            questionsColor: globalColors.white,
            answersColor: globalColors.mainBlue,
            buttonsBgColor: globalColors.mainBlue,
            buttonsBorderRadius: 12,
            errorsFontColor: globalColors.red,
          },
          blocks: [
            {
              name: 'welcome-screen',
              id: 'welcome',
              attributes: {
                label: 'Welcome to Property Cloud',
                description: 'Where innovation and AI meet Real Estate.',
                layout: 'split-left',
                attachment: {
                  type: 'image',
                  url: LandingHero,
                },
                buttonText: "I'm ready to get started",
              },
            },
            {
              name: 'short-text',
              id: 'full_name',
              attributes: {
                classnames: 'first-block',
                required: true,
                label: "Let's start with your full name",
                nextBtnLabel: 'Next',
              },
            },
            {
              name: 'short-text',
              id: 'organization_name',
              attributes: {
                classnames: 'first-block',
                required: true,
                label: 'The name of your company',
                nextBtnLabel: 'Next',
              },
            },
            {
              name: 'short-text',
              id: 'designation',
              attributes: {
                classnames: 'first-block',
                required: true,
                label: 'Your designation at your company',
                nextBtnLabel: 'Next',
              },
            },
            {
              name: 'slider',
              id: 'number_users',
              attributes: {
                label: 'Number of users (including you)',
                required: true,
                description: 'Please enter the number of users in your company who would be onboarded to Property Cloud',
                min: 1,
                max: 200,
                step: 1,
                prefix: '',
                suffix: ' user(s)',
                nextBtnLabel: 'Next',
                defaultValue: '20',
              },
            },
            {
              name: 'group',
              id: 'owner',
              attributes: {
                classnames: 'first-block',
                label: 'Company Owner Information',
                description:
                  'This information is required to offer swift support and to keep Property Cloud safe for everyone to use and is processed and stored using SSL encryption',
                nextBtnLabel: 'Next',
              },
              innerBlocks: [
                {
                  name: 'short-text',
                  id: 'owner_name',
                  attributes: {
                    classnames: 'first-block',
                    required: true,
                    label: 'Full name of the owner',
                  },
                },
                {
                  name: 'email',
                  id: 'owner_email',
                  attributes: {
                    classnames: 'first-block',
                    required: true,
                    label: 'Email of the owner',
                  },
                },
                {
                  name: 'dropdown',
                  id: 'business_location',
                  attributes: {
                    classnames: 'first-block',
                    required: true,
                    label: 'Business Location',
                    default: { label: 'Pakistan', value: 'Pakistan' },
                    choices: generateCountryListAsLabelValues(),
                  },
                },
              ],
            },
            {
              name: 'group',
              id: 'admin',
              attributes: {
                classnames: 'first-block',
                label: 'Account Admin Details',
                description:
                  'This information would be used to sign the Property Cloud account admin up. To maximize the safety of your information, only one admin can be assigned, and can only be changed later by contacting support.',
                nextBtnLabel: 'Next',
              },
              innerBlocks: [
                {
                  name: 'email',
                  id: 'admin_email',
                  attributes: {
                    classnames: 'first-block',
                    required: true,
                    label: 'Admin Email',
                  },
                },
                {
                  name: 'short-text',
                  id: 'admin_password',
                  attributes: {
                    classnames: 'first-block',
                    required: true,
                    label: 'Admin Password',
                    placeholder: 'Type your password here',
                    type: 'password',
                  },
                },
              ],
            },
          ],
        }}
        onSubmit={async (data: any, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }) => {
          const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:<>?]).{8,}$/);
          if (regex.test(data['answers']['admin_password'].value.toString())) {
            const userRepository = new UserRepository();
            const user = await userRepository.getObjectByEmail(data['answers']['admin_email'].value);
            if (user) {
              setSubmissionErr('A user with this admin email already exists. Please enter a different email.');
              setTimeout(() => {
                goToBlock('admin_email');
              }, 3000)
            } else {
              setIsSubmitting(false);
              completeForm();
              localStorage.setItem('answers', JSON.stringify(data));
              setTimeout(() => {
                if (localStorage.getItem('answers') !== null) {
                  navigate('/onboarding/tier');
                } else {
                  Toast('There was an error taking you to payments, please try again later.', 'error');
                }
              }, 4000);
            }
          } else {
            setSubmissionErr("Admin Password does not meet the Password Policy requirements. Password must be at least 8 characters long, and have at least one uppercase character, one lowercase character, one number and one special character (!, $, #, ^, etc.).");
            setTimeout(() => {
              goToBlock('admin_password');
            }, 3000)
          }
        }}
      />
    </Box>
  );
};

export default Onboarding;
