import React, { ChangeEvent, useState } from 'react';
import { Form, Formik } from 'formik';
import { Box, Input, Typography, useTheme, IconButton, CircularProgress } from '@mui/material';
import Heading from '../../atoms/heading';
import { globalColors } from '../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { Button, FieldInput, FieldInputTextarea } from '../../atoms';
import { FaFacebookF } from 'react-icons/fa';
import { Instagram, Twitter, Youtube } from '../../../assets/Icons';
import ImageUpload from './ImageUpload';
import { backgroundImageStyle, crossContainerStyle, crossFileContainerStyle, iconButtonStyle, imageNameStyle } from './element';
import { ThemeProvider } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import SocialPostModel from '../../../utils/api/models/socialPostModel';
import SocialPostRepository from '../../../utils/api/repositories/socialPostRepository';
import AttachmentUpload from './AttachmentUpload';
import { setAddNew } from '../../../store/routes';
import UserRepository from '../../../utils/api/repositories/userRepository';

type SocialIcons = {
  [key: string]: JSX.Element;
};

type SocialValues = {
  facebook: number;
  twitter: number;
  instagram: number;
  youtube: number;
};
interface AddPostProps {
  addPost: boolean;
  setAddPost: React.Dispatch<React.SetStateAction<boolean>>;
}

const Icons = ['Facebook', 'Instagram'];

const AddPost: React.FC<AddPostProps> = ({ addPost, setAddPost }) => {
  const [selectedIcons, setSelectedIcons] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageName, setSelectedImageName] = useState<string | ''>('');
  const [textValue, setTextValue] = useState<string | ''>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState<string | ''>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const mode = useSelector(selectThemeMode);
  const color= useSelector(selectColor);
  const theme = useTheme();
  const dispatch = useDispatch();

  const socialIcons: SocialIcons = {
    Facebook: <FaFacebookF size={'1.5rem'} color="#599BF7" />,
    Twitter: <img src={Twitter} alt="Twitter" width="24px" height="24px" />,
    Instagram: <img src={Instagram} alt="Instagram" width="24px" height="24px" />,
    Youtube: <img src={Youtube} alt="Youtube" width="24px" height="20px" />,
  };

  const initialValues = {
    name: '',
    text: '',
  };

  const onSubmit = async (values: any) => {
    // Handle form submission logic here
    // console.log(values, textValue);
    setSubmitted(true);
    let imageUrl;
    if (selectedImage) {
      imageUrl = URL.createObjectURL(selectedImage);
      // console.log("imageUrl",imageUrl)
    }

    if (selectedFile) {
      // console.log("selectedFile",selectedFile)
    }

    const user: SocialPostModel = {
      name: values?.name,
      text: textValue,
      media: selectedImage,
      allMedia: selectedFile,
    };
    // Default Values
    const socialValues = {
      facebook: 0,
      twitter: 0,
      instagram: 0,
      youtube: 0,
    };

    // Iterate through the properties of socialValues
    for (const platform in socialValues) {
      // Check if the platform key exists in the array
      if (selectedIcons.some((icon) => icon?.toLowerCase() === platform?.toLowerCase())) {
        socialValues[platform as keyof SocialValues] = 1;
      } else {
        socialValues[platform as keyof SocialValues] = 0;
      }
    }

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    const objectId = currentUser?.id ?? '';

    const repository = new SocialPostRepository();
    await repository.createSocialPost(user, objectId, socialValues);
    setSubmitted(false);
    setAddPost(false);
    dispatch(setAddNew({ addNew: false, path: '' }));
  };

  const handleIconClick = (icon: string) => {
    // Check if the icon is already selected
    if (selectedIcons.includes(icon)) {
      // If selected, remove it from the selectedIcons array
      setSelectedIcons((prevSelected) => prevSelected.filter((selectedIcon) => selectedIcon !== icon));
    } else {
      // If not selected, add it to the selectedIcons array
      setSelectedIcons((prevSelected) => [...prevSelected, icon]);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  const handleRemoveAttachment = () => {
    setSelectedFile(null);
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form>
          <Box>
            <Heading text="Write a post" />
            <Box
              bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
              borderRadius={'16px'}
              marginTop={'1rem'}
              boxShadow={'#303030'}
              minHeight={'100%'}
              padding={'20px 30px'}
              height={'100%'}
            >
              <Box display={'flex'} flexDirection={'column'} gap={'1rem'} width={'100%'}>
                <FieldInput label="Name" id="name" mode={mode} name="name" placeholder="Name" type="text" />

                <FieldInputTextarea label="Text" className="col" placeholder="Text" name="text" id="text" onChange={handleTextChange} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', width: '25%', justifyContent: 'space-between' }}>
                    <ImageUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} setSelectedImageName={setSelectedImageName} />

                    <AttachmentUpload selectedFile={selectedFile} setSelectedFile={setSelectedFile} setSelectedFileName={setSelectedFileName} />
                  </Box>
                  {/* Cross Box */}
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {selectedImage && (
                      <Box>
                        <ThemeProvider
                          theme={{
                            palette: {
                              primary: {
                                main: '#007FFF',
                                dark: '#0066CC',
                              },
                            },
                          }}
                        >
                          <Box sx={crossContainerStyle({ selectedImage, theme })}>
                            <Typography sx={imageNameStyle}>{selectedImageName}</Typography>

                            {/* X button to remove image */}
                            {selectedImage && (
                              <IconButton onClick={handleRemoveImage} sx={iconButtonStyle({ theme })}>
                                <ClearIcon
                                  sx={{
                                    fontSize: 16,
                                    '&:hover': {
                                      color: globalColors.red
                                    }
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </ThemeProvider>
                      </Box>
                    )}
                    {selectedFile && (
                      <Box sx={{ marginTop: '2%' }}>
                        <ThemeProvider
                          theme={{
                            palette: {
                              primary: {
                                main: '#007FFF',
                                dark: '#0066CC',
                              },
                            },
                          }}
                        >
                          <Box sx={crossFileContainerStyle({ selectedFile, theme })}>
                            <Typography sx={imageNameStyle}>{selectedFileName}</Typography>
                            {/* X button to remove image */}
                            {selectedFile && (
                              <IconButton onClick={handleRemoveAttachment} sx={iconButtonStyle({ theme })}>
                                <ClearIcon
                                  sx={{
                                    fontSize: 16,
                                    '&:hover': {
                                      color: globalColors.red
                                    }
                                  }}
                                />
                              </IconButton>
                            )}
                          </Box>
                        </ThemeProvider>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              {selectedImage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>
                  {selectedImage.type.startsWith('image/') ? (
                    <Box sx={backgroundImageStyle({ selectedImage })} />
                  ) : selectedImage.type.startsWith('video/') ? (
                    <video
                      controls
                      width="70%"
                      height="300px"
                      style={{
                        border: `1px solid ${'rgba(246, 246, 246, 1)'}`,
                        borderRadius: '12px',
                      }}
                    >
                      <source src={URL.createObjectURL(selectedImage)} type={selectedImage.type} />
                      Your browser does not support the video tag.
                    </video>
                  ) : null}
                </Box>
              )}
            </Box>
            <Box marginTop={'1rem'}>
              <Heading text="Choose Platform" />
              <Box
                display={'flex'}
                sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
                gap={'10px'}
                marginTop={'1rem'}
                justifyContent={'space-between'}
              >
                {Icons.map((val) => (
                  <Box
                    key={val}
                    component={'span'}
                    display={'flex'}
                    justifyContent={'center'}
                    gap={'10px'}
                    bgcolor={mode==="dark"? globalColors.blackLight : globalColors.whiteLight}
                    borderRadius={'8px'}
                    width={'100%'}
                    padding={'1rem 0'}
                    color={ mode==="dark" ? globalColors.white : globalColors.black}
                    onClick={() => handleIconClick(val)}
                    sx={{ 
                      cursor: 'pointer',
                      border: `2px ${mode==="dark"?( selectedIcons.includes(val) ? color : globalColors.blackLight) : ( selectedIcons.includes(val) ? color : globalColors.whiteLight)} solid`
                    }}
                  >
                    {socialIcons[val]}
                    <Typography>{val}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box display={'flex'} gap={'10px'} justifyContent={'center'} marginTop={'1.5rem'}>
              <Button type="reset">Cancel</Button>
              <Button type="submit" disabled={submitted}>
                {!submitted ? 'Post' : <CircularProgress color="inherit" style={{ width: '25px', height: '25px' }} />}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddPost;