import React, { CSSProperties } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { globalColors } from '../../utils/constants/color';
import { selectColor } from '../../store/selector';

interface CarouselProps {
  images: string[];
  showThumbs?: boolean;
  showArrows?: boolean;
}

export const CustomCarousel = ({ images, showThumbs = false, showArrows = true }: CarouselProps) => {
  const color = useSelector(selectColor);

  const arrowStyles: CSSProperties = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 30,
    height: 30,
    cursor: 'pointer',
    color: color || globalColors.blue,
  };

  // const indicatorStyles: CSSProperties = {
  //     background: '#fff',
  //     width: 8,
  //     height: 8,
  //     display: 'inline-block',
  //     margin: '0 8px',
  // };

  return (
    <Carousel
      showIndicators={false}
      showThumbs={showThumbs}
      autoPlay={true}
      showStatus={false}
      showArrows={showArrows}
      infiniteLoop
      renderArrowPrev={(onClickHandler, hasPrev, label) => hasPrev && <ArrowBackIos onClick={onClickHandler} sx={{ ...arrowStyles, left: 15 }} />}
      renderArrowNext={(onClickHandler, hasNext, label) => hasNext && <ArrowForwardIos onClick={onClickHandler} sx={{ ...arrowStyles, right: 15 }} />}
    >
      {images.map((image, i) => (
        <img key={i} width={'100%'} style={{ objectFit: 'contain', maxWidth: '400px' }} src={image} alt="Login" />
      ))}
    </Carousel>
  );
};
