import { styled } from '@mui/material';

export const CardProductsStyle = styled('div')(({ theme }) => ({
  marginTop: '2rem',
  '& .content': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
      marginTop: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
