import { drawerWidth } from '../../utils/constants/drawerWidth';
import { Box, FormGroup, InputLabel, Typography, styled } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectAddNew, selectColor, selectNavigationLayout, selectOrganizationTier, selectThemeMode } from '../../store/selector';
import SendEmail from '../../components/organisms/email/sendEmail';
import { useEffect, useRef, useState } from 'react';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import Loader from '../../components/atoms/loader';
import { InputStyle } from '../../components/molecules/Post/form.style';
import { Button, FieldInput, Toast } from '../../components/atoms';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { MdDelete } from 'react-icons/md';
import ModalDeleteConfirm from '../../components/organisms/leads/modalDeleteConfirm';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../store/routes';
import { BsExclamationCircle } from 'react-icons/bs';
import UserRepository from '../../utils/api/repositories/userRepository';
import { PricingTiers, hasPermissionForAction } from '../../utils/helpers';
import NoPermission from '../../components/atoms/noPermission';
import InsufficientPlan from '../../components/atoms/insufficientPlan';

interface FormValues {
  apiKey: string;
  apiLabel: string;
  verifiedDomain: string;
}

const SetupEmail = () => {
  const color = useSelector(selectColor);
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const showArrow = useSelector(selectAddNew);
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState<{ label: string; status: string } | null>(null);
  const [processing, setProcessing] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  const organizationTier = useSelector(selectOrganizationTier);

  const handleDelete = async () => {
    const organizationRepository = new OrganizationRepository();
    const savedOrganization = await organizationRepository.removeMailerSendKey();
    if (savedOrganization) {
      Toast('API Key removed successfully.', 'success');
      setKey(null);
    } else {
      Toast('API Key could not be removed successfully. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    const fetchKey = async () => {
      setLoading(true);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        setHasPermission(hasPermissionForAction('Email', 'Create'));
        const organizationRepository = new OrganizationRepository();
        const mailKey = await organizationRepository.fetchMailerSendKey();
        if (mailKey) {
          setKey(mailKey);
        }
      }
      setLoading(false);
    };

    fetchKey();
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Email / Setup'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? '80px' : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} padding={3} height={'100%'}>
            {loading ? (
              <Box display={'flex'} alignItems={'center'} height={'100%'}>
                <Loader />
              </Box>
            ) : hasPermission && organizationTier == PricingTiers.Professional ? (
              <Box>
                <FormSetupStyle>
                  <Formik
                    innerRef={formikRef}
                    initialValues={{
                      apiKey: '',
                      apiLabel: '',
                      verifiedDomain: '',
                    }}
                    validate={(values) => {
                      const errors = {} as FormValues;

                      if (!values.apiKey || !values.apiKey.trim()) {
                        errors.apiKey = 'Required';
                      }
                      if (values.apiKey.length < 60) {
                        errors.apiKey = 'Please enter a valid API Key from MailerSend';
                      }
                      if (values.apiKey.includes(' ')) {
                        errors.apiKey = "API Key can't contain white spaces";
                      }
                      if (!values.apiLabel || !values.apiKey.trim()) {
                        errors.apiLabel = 'Required';
                      }
                      if (values.apiLabel === values.apiKey) {
                        errors.apiLabel = "Label can't be the same as the API Key";
                      }
                      const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/);
                      if (!regex.test(values.verifiedDomain.trim())) {
                        errors.verifiedDomain = 'Please enter a valid email address';
                      }

                      return errors;
                    }}
                    onSubmit={async (values: FormValues, { setSubmitting, resetForm }: FormikHelpers<FormValues>) => {
                      try {
                        setProcessing(true);
                        const organizationRepository = new OrganizationRepository();
                        const savedOrganization = await organizationRepository.saveMailerSendKey(
                          values.apiKey,
                          values.apiLabel,
                          values.verifiedDomain
                        );

                        if (savedOrganization) {
                          Toast('API Key saved successfully.', 'success');
                          resetForm();
                          setKey(null);
                          setTimeout(() => {
                            setProcessing(false);
                            dispatch(setAddNew({ addNew: false, path: '/' }));
                            navigate('/email');
                          }, 3000);
                        } else {
                          setProcessing(false);
                          Toast('API Key could not be saved successfully. Please try again later.', 'error');
                        }
                      } catch (error) {
                        setProcessing(false);
                        console.log('ERROR SUBMITTING FORM:', error);
                      }
                    }}
                  >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <Form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" gap="1.5rem">
                          {key?.status == 'pending' || key?.status == 'approved' ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                <Typography sx={{ color: globalColors.gray }}>Existing API Key - </Typography>
                                <Typography sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>{key.label}</Typography>
                              </Box>
                              <MdDelete
                                onClick={() => setOpenDelete(true)}
                                size={'1.3rem'}
                                color={color || globalColors.blue}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          ) : (
                            <></>
                          )}
                          <FieldInput
                            label="API Key"
                            id="apiKey"
                            name="apiKey"
                            placeholder="MailerSend API Key"
                            optional={false}
                            type="text"
                            error={errors.apiKey && touched.apiKey ? errors.apiKey : false}
                          />
                          <FieldInput
                            label="Sender Email Address (with verified domain)"
                            id="verifiedDomain"
                            name="verifiedDomain"
                            placeholder="Email Address"
                            optional={false}
                            type="text"
                            error={errors.verifiedDomain && touched.verifiedDomain ? errors.verifiedDomain : false}
                          />
                          <FieldInput
                            label="Key Label"
                            id="apiLabel"
                            name="apiLabel"
                            placeholder="API Key Label"
                            optional={false}
                            type="text"
                            error={errors.apiLabel && touched.apiLabel ? errors.apiLabel : false}
                          />
                          <Box display={'flex'} flexDirection={'row'} gap={'10px'} alignItems={'center'}>
                            <BsExclamationCircle color={globalColors.gray} size={'1.5rem'} />
                            <Typography color={globalColors.gray} fontSize={'14px'}>
                              Add a label to identify the key later on, since the key would no longer be visible to you, once you add it.
                            </Typography>
                          </Box>
                        </Box>
                        <Box display={'flex'} justifyContent={'center'} marginTop={'3rem'}>
                          <Button disabled={processing} type="submit">
                            {processing ? 'Loading...' : 'Submit'}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </FormSetupStyle>
              </Box>
            ) : !hasPermission ? (
              <NoPermission />
            ) : (
              <InsufficientPlan />
            )}
          </Box>
        </Box>
        <ModalDeleteConfirm
          open={openDelete}
          onClose={() => {
            setOpenDelete(false);
          }}
          onDelete={handleDelete}
          mode={mode}
          color={color}
        />
      </Box>
    </div>
  );
};

const FormSetupStyle = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: 'transparent',
  marginBottom: '2rem',
  // marginTop: '2rem',
  margin: '2rem',
  zIndex: 99999999,
  // TODO : style : fix component Link login
  // TODO : Responsive test
  // [theme.breakpoints.up('sm')]: {
  //   width: 250,
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: 350,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 450,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'pink',
  //   width: '1002px',
  // },
  fontSize: '14px',
  overflow: 'auto',
  '& input': {
    marginBottom: 0,
  },
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
  '& .wrapper-btn-submit': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2.5rem',
    alignItems: 'center',
    '& .btn-submit': {
      marginBottom: '1rem',
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& button': {
    textTransform: 'none',
  },
}));

export default SetupEmail;
