import { styled } from '@mui/material';
import { globalColors } from '../../../../../utils/constants/color';

export const FormEmployeeStyle = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& .row': {
    display: 'flex',
    alignItems: 'center',
    gap: '3.5rem',

    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      flexDirection: 'column',
      alignItems: 'start',

      '& button': {
        width: '100%',
      },
    },

    '& .label': {
      width: '200px',
    },

    '& .col': {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '&.btn-wrapper': {
      margin: '1rem 0',
      justifyContent: 'center',
    },
  },

  '& .column': {
    // alignItems: 'center',
    gap: '3.5rem',
    // width: '100%',

    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      flexDirection: 'column',
      alignItems: 'start',

      '& button': {
        width: '100%',
      },
    },

    '& .label': {
      width: '200px',
    },

    '& .col': {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '&.btn-wrapper': {
      margin: '1rem 0',
      justifyContent: 'center',
    },
  },

  '& .address': {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .group-input': {
      gap: '1rem',
      '& .item': {
        width: '100%',
        color: globalColors.text.secondary,
      },
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
  },

  '& .group-row': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  '& .group-column': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  '& .area-wrapper': {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& .area': {
      flex: 1,
    },
    '& .area-unit': {
      width: '15%',
      minWidth: '160px',
    },
  },

  [theme.breakpoints.down('md')]: {
    '& .area-wrapper': {
      flexDirection: 'column',
      '& .area-unit': {
        width: '100%',
        minWidth: '160px',
      },
    },
  },
}));

export const InputStyle = styled('input')<any>(({ theme, mode }) => ({
  width: '100%',
  height: '48px',
//   marginTop: '4px',
  // marginLeft: '32px',
  padding: '12px 16px',
  borderRadius: '12px',
  border: '1px solid rgba(48, 48, 48,1)',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
  color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
  outline: 'none',
  '&:focus': {
    border: '1px solid rgba(48, 48, 48,1', // Change border color on focus
  },
}));

interface IconInputProps {
  clicked?: boolean;
  mode: string;
}

export const IconInputStyle = styled('input')<IconInputProps>(({ theme, clicked, mode }) => ({
  width: '100%',
  height: '48px',
//   marginTop: '4px',
  // marginLeft: '32px',
  // padding: '8px 257px 8px 16px',
  padding: '12px 16px',
  borderRadius: '0 12px 12px 0',
  border: '1px solid rgba(48, 48, 48,1)',
  borderLeft: 'none',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
  color: mode === 'dark' ? globalColors.white : globalColors.blackLight,
  outline: 'none',
  '&:focus': {
    border: '1px solid rgba(48, 48, 48,1', // Change border color on focus
  },
}));
