import { Box } from '@mui/material';
import React from 'react';
import Sidebar from '../sidebar';
import { globalColors } from '../../../utils/constants/color';
import { MainLayoutStyle } from './mainLayout.style';
const drawerWidth = 280;

interface MainLayoutProps {
  children: React.ReactNode;
  titlePage: string;
  mode: string;
  navigation: boolean;
}

const MainLayout = ({ children, titlePage, mode, navigation }: MainLayoutProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={titlePage} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        <Box width={'100%'} padding={3}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
