import { useEffect, useState } from 'react';
import { Modal } from '@mui/base/Modal';
import { Backdrop } from '@mui/material';
import { Box, Theme, styled } from '@mui/system';
// import ImageGallery from 'react-image-gallery';
import { IImages } from '../../../store/projects';
import { globalColors } from '../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { CustomCarousel } from '../../atoms/carousel';
import { selectThemeMode } from '../../../store/selector';

interface GalleryProps {
  onClose: () => void;
  open: boolean;
  images: IImages[];
}

const Gallery = ({ onClose, open, images }: GalleryProps) => {
  const isImageExist = images.length > 0;
  const [localImages, setLocalImages] = useState<string[]>([]);
  const mode = useSelector(selectThemeMode);
  useEffect(() => {
    if (isImageExist) {
      const tmp: string[] = [];
      images.map((img) => {
        tmp.push(img.url);
      });

      setLocalImages(tmp);
    }
  }, [open]);

  return (
    <div>
      <StyledModal
        mode={mode}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={onClose}
        slots={{ backdrop: StyledBackdrop }}
        isImageExist={isImageExist}
      >
        <Box
          sx={style}
          bgcolor={mode === 'dark' ? '#171717' : globalColors.lightgray}
          className={isImageExist ? 'modal-content-wrapper' : 'no-images'}
        >
          {isImageExist && <CustomCarousel images={localImages} showThumbs />}
          {!isImageExist && <p>No images...</p>}
        </Box>
      </StyledModal>
    </div>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`;

const style = (theme: Theme) => ({
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  marginTop: '20px',
  marginBottom: '20px',
  '&.no-images': {
    height: 'max-content',
    padding: '1rem',
    '& p': {
      margin: '0px',
      textAlign: 'center',
      color: globalColors.white,
    },
  },
  // background: '#171717',
  // backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  // boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,

  '& .image-gallery-thumbnails-bottom button': {
    '&.image-gallery-left-nav, &.image-gallery-right-nav': {
      transform: 'translateY(180%) !important',
      '& .image-gallery-svg': {
        height: '43px',
        width: '60px',
        color: globalColors.lightblue,
      },
    },

    '&.image-gallery-play-button, &.image-gallery-fullscreen-button': {
      display: 'none',
    },
  },

  '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
    minHeight: '390px',
    width: '35rem',
    '& img.image-gallery-image': {
      height: '100%',
      width: '100%',
      objectFit: 'cover !important',
    },
  },

  [theme.breakpoints.between('lg', 'xl')]: {
    // height: '500px',
    '& .image-gallery-thumbnails-bottom button': {
      '&.image-gallery-left-nav, &.image-gallery-right-nav': {
        transform: 'translateY(150%) !important',
      },
    },
    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      minHeight: '350px',
      width: '18rem',
    },
  },

  [theme.breakpoints.down('md')]: {
    // height: '400px',
    width: '60%',
    '& .image-gallery-thumbnails-bottom button': {
      '&.image-gallery-left-nav, &.image-gallery-right-nav': {
        transform: 'translateY(-40%) !important',
      },
    },
    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      minHeight: '260px',
      width: '17rem',
    },
  },
  [theme.breakpoints.down('sm')]: {
    // height: '400px',
    width: '80%',
    '& .image-gallery-thumbnails-bottom button': {
      '&.image-gallery-left-nav, &.image-gallery-right-nav': {
        transform: 'translateY(-40%) !important',
      },
    },
    '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
      minHeight: '300px',
    },
  },
});

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export default Gallery;
