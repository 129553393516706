import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectColor, selectThemeMode, selectUserData } from '../../store/selector';
import { globalColors } from '../../utils/constants/color';
import { LiaUsersCogSolid } from 'react-icons/lia';
import { CiMoneyCheck1 } from 'react-icons/ci';
import { TbApps } from 'react-icons/tb';
import { LuDatabaseBackup } from 'react-icons/lu';
import { TbBellCog } from 'react-icons/tb';
import SupportIcon from './settings/Support/supportIcon';
import { RiLock2Line, RiMoneyDollarCircleFill, RiQuestionAnswerLine, RiQuestionMark } from 'react-icons/ri';
import { UserRole } from '../../store/user';

interface SettingsSubMenuProps {
  setOpenSetting?: any;
}

export const SettingsSideSubMenu = ({ setOpenSetting }: SettingsSubMenuProps) => {
  const navigate = useNavigate();
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const pathname = window.location.pathname.split('/');
  const user = useSelector(selectUserData);

  const handleSubMenuClick = (path: any) => {
    navigate(path);
  };

  return (
    <>
      {/* Backup */}
      <Button
        onClick={() => navigate('/settings/import')}
        sx={{
          marginLeft: '-10px',
          color: pathname[pathname.length - 1] === 'import' ? color || globalColors?.blue : mode === 'dark' ? globalColors.white : globalColors.black,
          transition: 'color 0.3s',
          '&:hover': {
            color: mode === 'dark' ? globalColors?.white : globalColors.black,
          },
        }}
      >
        <LuDatabaseBackup size={'1.5rem'} />
      </Button>

      {user?.role === UserRole.admin && (
        <Button
          onClick={() => navigate('/settings/leads')}
          sx={{
            marginLeft: '-10px',
            color:
              pathname[pathname.length - 1] === 'leads' && pathname.includes('settings')
                ? color || globalColors?.blue
                : mode === 'dark'
                ? globalColors.white
                : globalColors.black,
            transition: 'color 0.3s',
            '&:hover': {
              color: mode === 'dark' ? globalColors?.white : globalColors.black,
            },
          }}
        >
          <LiaUsersCogSolid size={'1.5rem'} />
        </Button>
      )}

      {/* {user?.role === UserRole.admin && (
        <Button
          onClick={() => navigate('/settings/commission')}
          sx={{
            marginLeft: '-10px',
            color:
              pathname[pathname.length - 1] === 'commission' || pathname[pathname.length - 2] === 'commission'
                ? color || globalColors?.blue
                : mode === 'dark'
                ? globalColors.white
                : globalColors.black,
            transition: 'color 0.3s',
            '&:hover': {
              color: mode === 'dark' ? globalColors?.white : globalColors.black,
            },
          }}
        >
          <CiMoneyCheck1 size={'1.5rem'} />
        </Button>
      )} */}

      {/* {user?.role === UserRole.admin && (
        <Button
          onClick={() => navigate('/settings/integration')}
          sx={{
            marginLeft: '-10px',
            color:
              pathname[pathname.length - 1] === 'integration'
                ? color || globalColors?.blue
                : mode === 'dark'
                ? globalColors.white
                : globalColors.black,
            transition: 'color 0.3s',
            '&:hover': {
              color: mode === 'dark' ? globalColors?.white : globalColors.black,
            },
          }}
        >
          <TbApps size={'1.5rem'} />
        </Button>
      )} */}

      <Button
        onClick={() => navigate('/settings/notifications')}
        sx={{
          marginLeft: '-10px',
          color:
            pathname[pathname.length - 1] === 'notifications'
              ? color || globalColors?.blue
              : mode === 'dark'
              ? globalColors.white
              : globalColors.black,
          transition: 'color 0.3s',
          '&:hover': {
            color: mode === 'dark' ? globalColors?.white : globalColors.black,
          },
        }}
      >
        <TbBellCog size={'1.5rem'} />
      </Button>

      {user?.role === UserRole.admin && (
        <Button
          onClick={() => navigate('/settings/permissions')}
          sx={{
            marginLeft: '-10px',
            color:
              pathname[pathname.length - 1] === 'permissions'
                ? color || globalColors?.blue
                : mode === 'dark'
                ? globalColors.white
                : globalColors.black,
            transition: 'color 0.3s',
            '&:hover': {
              color: mode === 'dark' ? globalColors?.white : globalColors.black,
            },
          }}
        >
          <RiLock2Line size={'1.5rem'} />
        </Button>
      )}

      {user?.role === UserRole.admin && (
        <Button
          onClick={() => navigate('/settings/payments')}
          sx={{
            marginLeft: '-10px',
            color:
              pathname[pathname.length - 1] === 'payments' || pathname.includes('payments') ? color || globalColors?.blue : mode === 'dark' ? globalColors.white : globalColors.black,
            transition: 'color 0.3s',
            '&:hover': {
              color: mode === 'dark' ? globalColors?.white : globalColors.black,
            },
          }}
        >
          <RiMoneyDollarCircleFill size={'1.5rem'} />
        </Button>
      )}

      <Button
        onClick={() => navigate('/settings/support')}
        sx={{
          marginLeft: '-10px',
          color:
            pathname[pathname.length - 1] === 'support' ? color || globalColors?.blue : mode === 'dark' ? globalColors.white : globalColors.black,
          transition: 'color 0.3s',
          '&:hover': {
            color: mode === 'dark' ? globalColors?.white : globalColors.black,
          },
        }}
      >
        <RiQuestionMark size={'1.5rem'} />
      </Button>
    </>
  );
};
