import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor, selectPermissionsState } from '../../../../store/selector';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material';
import RoleAccordion from './roleAccordion';
import { globalColors } from '../../../../utils/constants/color';
import Sidebar from '../../sidebar';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import RoleBindingIcon from './roleBindingIcon';
import UserIcon from './userIcon';
import UserAccordion from './userAccordian';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import Loader from '../../../atoms/loader';
import { UserRole } from '../../../../store/user';
import NoPermission from '../../../atoms/noPermission';
import IosSwitch from '../../IosSwitch';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import { Toast } from '../../../atoms';

export interface FormValues {
  title: string;
  message: string;
  Attachment: any;
}

const Permissions = () => {
  const [activeBox, setActiveBox] = useState<string>('role');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>([]);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const theme = useTheme();
  const color = useSelector(selectColor);
  const permissionsState = useSelector(selectPermissionsState);
  const [hasPermission, setHasPermission] = useState(false);
  const [askDeleteConfirmation, setAskDeleteConfirmation] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const repository = new UserRepository();
    const currentUser = repository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const commissionData = await repository.getAllUsersForOrganization();
    const organization = await repository.getCurrentOrganization();
    if (organization) {
      setAskDeleteConfirmation(organization.get('askDeleteConfirmation') ?? false);
    }
    if (commissionData) {
      setUser(commissionData);
    }
    setLoading(false);
  };

  const updateDeleteConfirmation = async (checked: boolean) => {
    const organizationRepository = new OrganizationRepository();
    const updatedOrganization = await organizationRepository.updateDeleteConfirmation(checked);
    if (updatedOrganization) {
      Toast('Organization permissions updated successfully.', 'success');
    } else {
      Toast('Organization permissions could not be updated successfully. Please try again.', 'error');
    }
  };

  const handleButtonClick = (boxId: string) => {
    setActiveBox(boxId);
  };

  useEffect(() => {
    fetchUsers();
  }, [permissionsState]);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Permissions'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission ? (
            <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
              <Box sx={{ paddingBottom: '5rem', display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Delete Confirmation</Typography>
                  <Typography color={globalColors.gray} fontSize={'12px'}>
                    Ask for confirmation before any destructive or deletion tasks.
                  </Typography>
                </Box>
                <IosSwitch
                  initiallyChecked={askDeleteConfirmation}
                  monochrome={false}
                  onChange={(checked) => {
                    updateDeleteConfirmation(!checked);
                  }}
                />
              </Box>

              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '15px' }}>
                {/* Role Btn */}

                <Box
                  sx={{
                    cursor: 'pointer',
                    height: '40px',
                    width: '314px',
                    border: `1px solid ${mode === 'dark' ? globalColors?.black : globalColors?.white}`,
                    backgroundColor:
                      activeBox === 'role' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                  onClick={() => handleButtonClick('role')}
                >
                  <RoleBindingIcon stroke={mode === 'dark' ? globalColors?.white : globalColors?.black} />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '21px',
                      textAlign: 'center',
                      color: mode === 'dark' ? globalColors?.white : globalColors?.black,
                    }}
                  >
                    Role
                  </Typography>
                </Box>

                {/* User Btn */}
                <Box
                  sx={{
                    cursor: 'pointer',
                    height: '40px',
                    width: '314px',
                    border: `1px solid ${mode === 'dark' ? globalColors?.black : globalColors?.white}`,
                    backgroundColor:
                      activeBox === 'user' ? color || globalColors.blue : mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                    borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                  onClick={() => handleButtonClick('user')}
                >
                  <UserIcon stroke={mode === 'dark' ? globalColors?.white : globalColors?.black} />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '21px',
                      textAlign: 'center',
                      color: mode === 'dark' ? globalColors?.white : globalColors?.black,
                    }}
                  >
                    User
                  </Typography>
                </Box>
              </Box>
              <Box marginBottom={'10px'} marginTop={'3rem'}></Box>

              {activeBox === 'role' && <RoleAccordion />}
              {activeBox !== 'role' && <UserAccordion data={user} />}
            </Box>
          ) : (
            <NoPermission />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Permissions;
