import { Typography, TableContainer, TableBody, TableRow, Table, TableHead, Paper, styled, Box } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import { PermissionsInterface, getPermissionsList } from '../../../../utils/helpers';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import IosSwitch from '../../IosSwitch';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import GroupInput from '../../groupInput';
import { Button, FieldInput, Toast } from '../../../atoms';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../../store/routes';
import Dropdowndata from '../../../atoms/dropdown2';
import NotFoundLottie from '../../../atoms/notFound';

const permissionsList = getPermissionsList();

interface CountsInterface {
  total: number;
  existing: number;
}

interface ValuesAddMember {
  firstName: string;
  lastName: string;
  role: string;
  phone: string;
  email: string;
  managedBy: string;
}

type Manager = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

const AddMember = () => {
  const formikRef = useRef<FormikProps<ValuesAddMember>>(null);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigation = useSelector(selectNavigationLayout);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [counts, setCounts] = useState<CountsInterface>({ total: -1, existing: -1 });
  const [hasPermission, setHasPermission] = useState(false);
  const [managers, setManagers] = useState<Manager[]>([]);
  const [permissions, setPermissions] = useState<PermissionsInterface[]>([
    {
      name: 'Leads',
      permissions: ['View', 'Create', 'Update', 'Delete'],
    },
    {
      name: 'Properties',
      permissions: ['View', 'Create', 'Update', 'Delete'],
    },
    {
      name: 'Projects',
      permissions: ['View', 'Create', 'Update', 'Delete'],
    },
    {
      name: 'Email',
      permissions: ['View', 'Create', 'Update', 'Delete'],
    },
    {
      name: 'Social Media',
      permissions: ['View', 'Create', 'Update', 'Delete'],
    },
    {
      name: 'Tasks',
      permissions: ['View', 'Create', 'Update', 'Delete'],
    },
  ]);
  const [role, setRole] = useState(UserRole.executive);

  const style = {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '12px',
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  const handleChangeCheckbox = (name: string, permission: string, value: boolean) => {
    const temp = [...permissions];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].name === name) {
        if (value) {
          temp[i].permissions.push(permission);
        } else {
          const indexToRemove = temp[i].permissions.indexOf(permission);
          if (indexToRemove !== -1) {
            temp[i].permissions.splice(indexToRemove, 1);
          }
        }
        break;
      }
    }
    setPermissions(temp);
  };

  useEffect(() => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const getManagers = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        const userRole = currentUser.get('role');
        if (userRole === UserRole.admin) {
          const userRepository = new UserRepository();
          const existingManagers = await userRepository.getAllManagers();

          if (existingManagers) {
            const users: Manager[] = [];
            for (let i = 0; i < existingManagers.length; i++) {
              const userData = {
                id: existingManagers[i].id,
                firstName: existingManagers[i].get('firstName'),
                lastName: existingManagers[i].get('lastName'),
                user: existingManagers[i],
              };
              users.push(userData);
            }
            setManagers([...users]);
          }
        }
      }
    };
    const fetchCompanyFigures = async () => {
      const organizationRepository = new OrganizationRepository();
      const counts: CountsInterface | null = await organizationRepository.getSignedUpMembers();
      if (counts) {
        setCounts(counts);
      }
      setLoading(false);
    };

    getManagers();
    fetchCompanyFigures();
  }, []);

  useEffect(() => {
    if (role === UserRole.manager) {
      const temp = [...permissions];
      for (let i = 0; i < temp.length; i++) {
        temp[i].permissions = ['View', 'Create', 'Update', 'Delete'];
      }
      setPermissions(temp);
    } else {
      const temp = [...permissions];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].name === 'Leads') {
          temp[i].permissions = ['View', 'Create', 'Update'];
        } else if (temp[i].name === 'Properties' || temp[i].name === 'Projects') {
          temp[i].permissions = ['View'];
        } else if (temp[i].name === 'Email' || temp[i].name === 'Social Media' || temp[i].name === 'Tasks') {
          temp[i].permissions = ['View', 'Create', 'Update', 'Delete'];
        }
      }
      setPermissions(temp);
    }
  }, [role]);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Members / Add Member'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          counts.existing < counts.total ? (
            <Box width={'100%'} padding={3}>
              <FormAddMemberStyle>
                <Formik
                  innerRef={formikRef}
                  enableReinitialize
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    role: '',
                    phone: '',
                    email: '',
                    managedBy: '',
                  }}
                  validate={(values) => {
                    const errors = {} as ValuesAddMember;
                    if (!values.firstName || !values.firstName.trim()) {
                      errors.firstName = 'Required';
                    }
                    if (!values.lastName || !values.lastName.trim()) {
                      errors.lastName = 'Required';
                    }
                    if (!values.phone) {
                      errors.phone = 'Required';
                    }
                    if (!values.email || !values.email.trim()) {
                      errors.email = 'Required';
                    }
                    const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/);
                    if (!regex.test(values.email.trim())) {
                      errors.email = 'Please enter a valid email';
                    }
                    if (role === UserRole.executive && (!values.managedBy || values.managedBy === 'Select')) {
                      errors.managedBy = 'Required';
                    }
                    return errors;
                  }}
                  onSubmit={async (values: ValuesAddMember, { setSubmitting, resetForm }: FormikHelpers<ValuesAddMember>) => {
                    try {
                      // console.log(permissions);
                      const organizationRepository = new OrganizationRepository();
                      const signedUpMember = await organizationRepository.signUpNewMember(
                        values.email,
                        values.phone.toString(),
                        values.firstName,
                        values.lastName,
                        role,
                        permissions,
                        values.managedBy
                      );
                      if (signedUpMember) {
                        Toast('New member has been successfully signed up and has been emailed instructions to set up their account.', 'success');
                        resetForm();
                        dispatch(setAddNew({ addNew: false, path: '/' }));
                        navigate('/hr/members');
                      } else {
                        Toast('There was an error signing this member up. Please try again.', 'error');
                      }
                    } catch (error) {
                      Toast('There was an error signing this member up. Please try again.', 'error');
                    }
                  }}
                >
                  {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box display="flex" flexDirection="column" gap="1.5rem">
                        <GroupInput className="group-input">
                          <FieldInput
                            label="First Name"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            optional={false}
                            type="text"
                            error={errors.firstName && touched.firstName ? errors.lastName : false}
                          />
                          <FieldInput
                            label="Last Name"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            optional={false}
                            type="text"
                            error={errors.lastName && touched.lastName ? errors.lastName : false}
                          />
                        </GroupInput>
                        <GroupInput className="group-input">
                          <FieldInput
                            label="Phone"
                            id="phone"
                            name="phone"
                            placeholder="(+YY) XXX XXXXXXX"
                            optional={false}
                            type="number"
                            error={errors.phone && touched.phone ? errors.phone : false}
                          />
                          <FieldInput
                            label="Email"
                            id="email"
                            name="email"
                            placeholder="example@domain.com"
                            optional={false}
                            type="text"
                            error={errors.email && touched.email ? errors.email : false}
                          />
                        </GroupInput>
                      </Box>
                      <Box display={'flex'} justifyContent={'center'} margin={'3rem'} alignItems={'center'} gap={'10px'}>
                        <Typography textTransform={'uppercase'} sx={{ color: globalColors.white }}>
                          Executive
                        </Typography>
                        <IosSwitch
                          onChange={(checked) => {
                            if (!checked) {
                              setRole(UserRole.manager);
                            } else {
                              setRole(UserRole.executive);
                            }
                          }}
                          monochrome
                        />
                        <Typography textTransform={'uppercase'} sx={{ color: globalColors.white }}>
                          Manager
                        </Typography>
                      </Box>

                      {role === UserRole.executive && (
                        <Box display={'flex'} flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>
                          <Dropdowndata
                            label="Managed By"
                            id="managedBy"
                            name="managedBy"
                            optional={false}
                            mode={mode}
                            onChange={(e) => {
                              setFieldValue('managedBy', e.target.value);
                            }}
                            values={[
                              { label: `Select`, value: '' },
                              ...managers.map((user) => ({
                                label: `${user.firstName} ${user.lastName}`,
                                value: user.id,
                              })),
                            ]}
                            defaultValue=""
                          />
                          <StyledTypography>{touched.managedBy && errors.managedBy ? errors.managedBy : ''}</StyledTypography>
                        </Box>
                      )}

                      <Accordion
                        sx={{
                          borderRadius: '20px !important',
                          backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                          marginBottom: '2rem',
                        }}
                        defaultExpanded={false}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                          <Box display={'flex'} flexDirection={'column'}>
                            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                              Permissions <span style={{ color: globalColors.gray, fontSize: '12px' }}>(optional)</span>
                            </Typography>
                            <Typography color={globalColors.gray} fontSize={'12px'}>
                              Select the permissions you want to grant to the new member. Some permissions have been selected by default according to
                              how others are using them for the <span style={{ color: color }}>{role}</span> role.
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TableContainer
                            component={Paper}
                            sx={{
                              backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                              color: mode === 'dark' ? globalColors.white : globalColors.black,
                              paddingBottom: '20px',
                              paddingLeft: '20px',
                              paddingRight: '20px',
                              boxShadow: 'none',
                            }}
                          >
                            {
                              <Table
                                sx={{
                                  [`& .${tableCellClasses.root}`]: {
                                    borderBottom: 'none',
                                  },
                                }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <>
                                      <TableCell>
                                        <Heading color={globalColors.gray} whiteSpace={'nowrap'}></Heading>
                                      </TableCell>
                                      <TableCell>
                                        <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                                          View
                                        </Heading>
                                      </TableCell>
                                      <TableCell>
                                        <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                                          Create
                                        </Heading>
                                      </TableCell>
                                      <TableCell>
                                        <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                                          Update
                                        </Heading>
                                      </TableCell>
                                      <TableCell>
                                        <Heading color={globalColors.gray} whiteSpace={'nowrap'}>
                                          Delete
                                        </Heading>
                                      </TableCell>
                                    </>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {permissionsList.map((permission, idx) => {
                                    return (
                                      <TableRow
                                        key={idx}
                                        sx={{
                                          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                                          borderRadius: '10px',
                                          gap: '20px',
                                          marginBottom: '20px',
                                          paddingBottom: '20px',
                                          borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
                                        }}
                                      >
                                        <TableCell sx={style} component="th" scope="row">
                                          {permission.name}
                                        </TableCell>
                                        {permission.permissions.map((name, i) => {
                                          return (
                                            <TableCell sx={style} key={i}>
                                              <IosSwitch
                                                initiallyChecked={permissions.length > 0 ? permissions[idx].permissions.includes(name) : false}
                                                monochrome={false}
                                                onChange={(checked) => {
                                                  handleChangeCheckbox(permission.name, name, !checked);
                                                }}
                                              />
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            }
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                      {role === UserRole.executive && managers.length === 0 ? (
                        <Box display={'flex'} justifyContent={'center'} marginTop={'3rem'}>
                          <Typography color={globalColors.red}>
                            Please create at least one manager role user to be able to create an executive role user.
                          </Typography>
                        </Box>
                      ) : (
                        <Box display={'flex'} justifyContent={'center'} marginTop={'3rem'}>
                          <Button type="submit">{isSubmitting ? 'Loading...' : 'Add'}</Button>
                        </Box>
                      )}
                    </Form>
                  )}
                </Formik>
              </FormAddMemberStyle>
            </Box>
          ) : (
            <Box width={'100%'} padding={3} display={'flex'} justifyContent={'center'}>
              <NotFoundLottie
                text="Looks like your company has reached the maximum limit of users it can onboard with the current plan. Press the button below and upgrade now."
                showButton
                buttonText="Upgrade Now"
                navigateTo="/"
              />
            </Box>
          )
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

const FormAddMemberStyle = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: 'transparent',
  marginBottom: '2rem',
  // marginTop: '2rem',
  zIndex: 99999999,
  // TODO : style : fix component Link login
  // TODO : Responsive test
  // [theme.breakpoints.up('sm')]: {
  //   width: 250,
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: 350,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 450,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'pink',
  //   width: '1002px',
  // },
  fontSize: '14px',
  overflow: 'auto',
  '& input': {
    marginBottom: 0,
  },
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
  '& .wrapper-btn-submit': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2.5rem',
    alignItems: 'center',
    '& .btn-submit': {
      marginBottom: '1rem',
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& button': {
    textTransform: 'none',
  },
}));

const Heading = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '28px',
  letterSpacing: '-0.02em',
  textAlign: 'left',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '10px',
}));

export default AddMember;
