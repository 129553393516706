import { Box, IconButton, InputLabel, styled, Typography, useTheme } from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import Heading from '../../atoms/heading';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import GroupInput from '../../molecules/groupInput';
import { Button, FieldInput, Toast } from '../../atoms';
import Dropdown from '../../atoms/dropdown';
// import TextButton from '../../atoms/textbutton';
import { generateCountryList } from '../../../utils/helpers/countries';
import { useEffect, useRef } from 'react';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import LeadModel from '../../../utils/api/models/leadModel';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import ProjectRepository from '../../../utils/api/repositories/projectRepository';
import { IFormDetailProject } from '../../../store/projects';
import Dropdowndata from '../../atoms/dropdown2';
import UserRepository from '../../../utils/api/repositories/userRepository';
// import OrganizationRepository from '../../../utils/api/repositories/organizationRepository';
// import ManagerRepository from '../../../utils/api/repositories/managerRepository';
import SalesExecutiveRepository from '../../../utils/api/repositories/salesExecutiveRepository';
import { generateStateList } from '../../../utils/helpers/state';
import AttachmentUpload from './AttachmentUpload';
import FileIcon from './FileIcon';
import ClearIcon from '@mui/icons-material/Clear';
import { ThemeProvider } from '@mui/styles';
import { crossFileContainerStyle, iconButtonStyle } from './leadsForm.style';
import { MuiTelInput } from 'mui-tel-input';
import { IFormDetailProperty } from '../../../store/property';
import PropertyRepository from '../../../utils/api/repositories/propertyRepository';
import { confirmFilesTotalSize, hasPermissionForAction } from '../../../utils/helpers';
import { UserRole } from '../../../store/user';
import NotFoundLottie from '../../atoms/notFound';
import NoPermission from '../../atoms/noPermission';
import Loader from '../../atoms/loader';
import ProjectComponentModel from '../../../utils/api/models/projectComponentModel';
import CustomFields, { CustomField } from '../../molecules/customFields';

interface ValuesFormRegister {
  firstName: string;
  lastName: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  cnic: string;
  category: string;
  propertyProject: string;
  projectComponent: string;
  blockFloor: string;
  block: string;
  size: string;
  country: string;
  state: string;
  city: string;
  address: string;
  source: string;
  purpose: string;
  assignedTo: string;
  interestedIn: string;
  budgetFrom: number;
  budgetTo: number;
  discountPercentage: string;
}
type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

interface LeadsFormProp {
  mode: string;
  color: string;
}

const ClientsForm: React.FC<LeadsFormProp> = ({ mode, color }) => {
  const formikRef = useRef<FormikProps<ValuesFormRegister>>(null);
  const navigate = useNavigate();
  const [project, setProject] = useState<IFormDetailProject[]>([]);
  const [property, setProperty] = useState<IFormDetailProperty[]>([]);
  const [projectComponents, setProjectComponents] = useState<ProjectComponentModel[]>([]);
  const [managersandSalesExecutive, setManagersandSalesExecutive] = useState<ManagerSalesExecutive[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [assignedTo, setassignedTo] = useState<string>('manual');
  const [showAssigningOptions, setShowAssigningOptions] = useState(true);
  const theme = useTheme();
  const [phone, setPhone] = React.useState('');
  const [secondaryPhone, setSecondaryPhone] = React.useState('');
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const handlePhoneChange = (newPhone: any) => {
    setPhone(newPhone);
  };

  const handleSecondaryPhone = (newPhone: any) => {
    setSecondaryPhone(newPhone);
  };

  const handleRemoveAttachment = (index: number) => {
    let tempFiles = [...selectedFiles];
    let tempFileNames = [...selectedFileNames];
    tempFiles.splice(index, 1);
    tempFileNames.splice(index, 1);
    setSelectedFiles(tempFiles);
    setSelectedFileNames(tempFileNames);
  };

  const getAllProjects = async () => {
    const projectRepository = new ProjectRepository();
    await projectRepository
      .getAll()
      .then((res) => {
        if (res) {
          setProject(res);
        }
      })
      .catch((err) => {
        setProject([]);
      });
  };

  const getProjectComponents = async (projectId: string) => {
    const projectRepository = new ProjectRepository();
    await projectRepository
      .getAllComponents(projectId)
      .then((res) => {
        if (res) {
          setProjectComponents(res);
        }
      })
      .catch((err) => {
        setProjectComponents([]);
      });
  };

  const getAllProperties = async () => {
    const propertyRepository = new PropertyRepository();
    await propertyRepository
      .getAll()
      .then((res) => {
        if (res) {
          setProperty(res);
        }
      })
      .catch((err) => {
        setProject([]);
      });
  };

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser?.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i]?.get('firstName'),
              lastName: managersAndSalesExecutives[i]?.get('lastName'),
              user: managersAndSalesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i]?.get('user').id,
              firstName: salesExecutives[i]?.get('user')?.get('firstName'),
              lastName: salesExecutives[i]?.get('user')?.get('lastName'),
              user: salesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser?.get('firstName'),
          lastName: currentUser?.get('lastName'),
          user: currentUser,
        };
        setManagersandSalesExecutive([userData]);
        setShowAssigningOptions(false);
      }
    }
  };

  const selectedAssignmentPreference = async () => {
    const userRepository = new UserRepository();
    const currentOrganization = await userRepository.getCurrentOrganization();

    if (currentOrganization) {
      setassignedTo(currentOrganization.get('leadPreference') === 'manually' ? 'manual' : 'auto');
    }
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Leads', 'Create'));
    getAllProjects();
    getAllProperties();
    getManagersandSalesExecutive();
    selectedAssignmentPreference();
    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <Formik
          innerRef={formikRef}
          initialValues={{
            firstName: '',
            lastName: '',
            phone: '',
            secondaryPhone: '',
            email: '',
            cnic: '',
            category: '',
            propertyProject: '',
            projectComponent: '',
            blockFloor: '',
            block: '',
            size: '',
            country: 'Pakistan',
            state: '',
            city: '',
            address: '',
            source: '',
            purpose: '',
            assignedTo: 'Unassigned',
            interestedIn: '',
            budgetFrom: 0,
            budgetTo: 0,
            discountPercentage: '',
          }}
          validate={(values) => {
            const errors = {} as ValuesFormRegister;

            if (!values.phone) {
              errors.phone = 'Phone is required';
            }

            if (!values.category) {
              errors.category = 'Category is required';
            }

            // else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
            //     values.firstName
            //   )
            // )
            // {
            //   errors.firstName = "Invalid first name.";
            // }

            if (parseInt(values.discountPercentage) < 0 || parseInt(values.discountPercentage) > 100) {
              errors.discountPercentage = 'Please enter a valid value between 0 and 100';
            }

            return errors;
          }}
          onSubmit={async (values: ValuesFormRegister, { setSubmitting, resetForm }: FormikHelpers<ValuesFormRegister>) => {
            if (confirmFilesTotalSize(selectedFiles)) {
              const repository = new LeadRepository();
              const lead: LeadModel = {
                firstName: values.firstName,
                lastName: values.lastName,
                primaryPhone: values.phone,
                secondaryPhone: values.secondaryPhone,
                email: values.email,
                cnic: values.cnic,
                category: values.category,
                // block: values.block,
                propertyProject: values.propertyProject,
                projectComponent: values.projectComponent,
                projectDetails: { blockFloor: values.blockFloor, size: values.size },
                country: values.country,
                state: values.state,
                city: values.city,
                address: values.address,
                source: values.source,
                purpose: values.purpose,
                interestedIn: values.interestedIn,
                budgetFrom: values.budgetFrom,
                budgetTo: values.budgetTo,
                assignedTo: values.assignedTo === '' ? 'Unassigned' : values.assignedTo,
                attachments: selectedFiles,
                customFields: customFields.filter((field) => field.label !== '' && field.value !== ''),
                discountPercentage: parseInt(values.discountPercentage),
              };
              await repository.create(lead);
              const navigateToLeads = values.assignedTo !== 'Unassigned' && values.assignedTo !== '';
              resetForm({
                values: {
                  firstName: '',
                  lastName: '',
                  phone: '',
                  secondaryPhone: '',
                  email: '',
                  cnic: '',
                  category: 'Select',
                  propertyProject: '',
                  projectComponent: '',
                  blockFloor: '',
                  block: '',
                  size: '',
                  country: 'Pakistan',
                  state: '',
                  city: '',
                  address: '',
                  source: '',
                  purpose: '',
                  assignedTo: '',
                  interestedIn: '',
                  budgetFrom: 0,
                  budgetTo: 0,
                  discountPercentage: '',
                },
              });

              setTimeout(() => {
                if (navigateToLeads) {
                  navigate('/leads');
                } else {
                  navigate('/clients');
                }
                setSubmitting(false);
              }, 500);
            } else {
              Toast('You have selected too many files. The max total allowed size of the files is 40MB.', 'error');
            }
          }}
        >
          {({ values, errors, touched, setFieldValue, handleSubmit, isSubmitting }) => (
            <Form>
              <Box borderRadius={'20px'} marginBottom={'2px'} bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}>
                {/* Personal Information */}
                <LeadsFormStyle
                  sx={{ backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray }}
                  className={mode === 'dark' ? 'bgdarkcolor' : 'bglightcolor'}
                >
                  {/* HEADING */}
                  <Heading text={'Personal Information'} />
                  {/* FORM GROUP */}
                  <Box display="flex" flexDirection="column" gap="1.5rem" marginTop={'30px'}>
                    {/* 1st */}
                    <GroupInput className="group-input">
                      {/* Name */}
                      <Box display={'flex'} flexDirection={'column'} gap={'2px'} width={'100%'} position={'relative'}>
                        <FieldInput label="First Name" id="firstName" mode={mode} name={'firstName'} placeholder="First Name" type="text" />
                      </Box>

                      {/* Last Name */}
                      <Box display={'flex'} flexDirection={'column'} gap={'2px'} width={'100%'} position={'relative'}>
                        <FieldInput label="Last Name" id="lastName" mode={mode} name="lastName" placeholder="Last Name" type="text" />
                      </Box>
                    </GroupInput>

                    {/* 2nd */}
                    <GroupInput className="group-input">
                      <Box display={'flex'} flexDirection={'column'} gap={'10px'} width={'100%'} position={'relative'}>
                        {/* Phone Number */}
                        <FieldInput
                          label="Phone Number"
                          id="phone"
                          name="phone"
                          mode={mode}
                          type="number"
                          placeholder="(+YY) XXX XXXXXXX"
                          optional={false}
                        />

                        {/* <Typography mb={1} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        Phone Number
                      </Typography>

                      <MuiTelInput
                        style={{
                          background: mode === 'dark' ? globalColors.black : globalColors.white,
                          borderRadius: '14px',
                          border: mode === 'dark' ? '2px solid #303030' : '1px solid #303030',
                        }}
                        sx={{
                          '.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                            background: mode === 'dark' ? globalColors.black : globalColors.white,
                            borderRadius: '14px',
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            padding: '0.8rem',
                          },
                          '.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiTelInput-IconButton.css-104c99h-MuiButtonBase-root-MuiIconButton-root':
                            { backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white },
                        }}
                        value={phone}
                        name={'phone'}
                        id={'phone'}
                        onChange={handlePhoneChange}
                        defaultCountry='PK'
                      /> */}

                        {errors?.phone && touched?.phone && <StyledTypography>{errors?.phone}</StyledTypography>}
                      </Box>

                      {/* Phone Number */}
                      <FieldInput
                        label="Phone Number"
                        id="secondaryPhone"
                        name="secondaryPhone"
                        mode={mode}
                        type="number"
                        placeholder="(+YY) XXX XXXXXXX"
                      />

                      {/* <Box display={'flex'} flexDirection={'column'} gap={'10px'} width={'100%'} position={'relative'}>
                      <Typography mb={1} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                        Phone Number
                      </Typography>

                      <MuiTelInput
                        style={{
                          background: mode === 'dark' ? globalColors.black : globalColors.white,
                          borderRadius: '14px',
                          border: mode === 'dark' ? '2px solid #303030' : '1px solid #303030',
                        }}
                        sx={{
                          '.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                            background: mode === 'dark' ? globalColors.black : globalColors.white,
                            borderRadius: '14px',
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            padding: '0.8rem',
                          },
                          '.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiTelInput-IconButton.css-104c99h-MuiButtonBase-root-MuiIconButton-root':
                            { backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white },
                        }}
                        value={secondaryPhone}
                        defaultCountry={'PK'}
                        onChange={handleSecondaryPhone}
                      />
                    </Box> */}
                    </GroupInput>

                    {/* 3rd */}
                    <GroupInput className="group-input">
                      <FieldInput mode={mode} label="Email" id="email" name="email" placeholder="example@domain.com" type="email" />
                      <Box display={'flex'} flexDirection={'column'} gap={'2px'} width={'100%'} position={'relative'}>
                        <FieldInput label="CNIC Number" id="cnic" mode={mode} name="cnic" placeholder="XXXXX-XXXXXXX-X" type="text" />
                      </Box>
                    </GroupInput>

                    {/* 4th */}
                    <GroupInput className="group-input">
                      <Box display={'flex'} flexDirection={'column'} gap={'2px'} width={'100%'} position={'relative'}>
                        <Dropdown
                          label="Category (Property / Project)"
                          mode={mode}
                          id="category"
                          name="category"
                          optional={false}
                          values={['Select', 'Property', 'Project']}
                          defaultValue="Select"
                        />
                        <StyledTypography>{errors?.category}</StyledTypography>
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} gap={'2px'} width={'100%'} position={'relative'}>
                        <Dropdowndata
                          label={values.category === 'Project' ? 'Select Project' : 'Select Property'}
                          mode={mode}
                          id="propertyProject"
                          name="propertyProject"
                          values={[
                            { label: 'Select', value: '' },
                            ...(values.category === 'Project'
                              ? project.map((val) => ({
                                  label: val?.name,
                                  value: val?.id,
                                }))
                              : property.map((val) => ({
                                  label: val?.name,
                                  value: val?.id,
                                }))),
                          ]}
                          value={values.propertyProject}
                          onChange={async (e) => {
                            setFieldValue('propertyProject', e.target.value);
                            if (values.category === 'Project') {
                              await getProjectComponents(e.target.value);
                            }
                          }}
                          defaultValue=""
                        />
                      </Box>
                    </GroupInput>
                    {projectComponents.length > 0 && (
                      <Dropdowndata
                        label={'Inventory'}
                        mode={mode}
                        id="projectComponent"
                        name="projectComponent"
                        values={[
                          { label: 'Select', value: '' },
                          ...projectComponents.map((val) => ({
                            label: val.name ?? '',
                            value: val.objectId ?? '',
                          })),
                        ]}
                        value={values.projectComponent}
                        onChange={async (e) => {
                          setFieldValue('projectComponent', e.target.value);
                        }}
                        defaultValue=""
                      />
                    )}
                    {/*  */}
                  </Box>
                </LeadsFormStyle>

                {/* Location Information  */}
                <LeadsFormStyle sx={{ backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray }}>
                  {/* HEADING */}
                  <Heading text={'Location Information'} />
                  {/* FORM GROUP */}
                  <Box display="flex" flexDirection="column" gap="1.5rem" marginTop={'30px'}>
                    {/* 1st */}
                    <GroupInput className="group-input">
                      {/* Country */}
                      <Dropdown label="Country" mode={mode} id="country" name="country" values={generateCountryList()} defaultValue="Pakistan" />

                      {/* State */}
                      <FieldInput label="State" mode={mode} id="state" name="state" type="text" placeholder="State" />
                    </GroupInput>

                    {/* 2nd */}
                    <GroupInput className="group-input">
                      {/* City */}
                      <FieldInput label="City" mode={mode} id="city" name="city" type="text" placeholder="City" />

                      {/* Address */}
                      <FieldInput label="Address" mode={mode} id="address" name="address" type="text" placeholder="Complete Address" />
                    </GroupInput>

                    {/*  */}
                  </Box>
                </LeadsFormStyle>

                {/* Other Information  */}
                <LeadsFormStyle sx={{ backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray }}>
                  {/* HEADING */}
                  <Heading text={'Other Information'} />
                  {/* FORM GROUP */}
                  <Box display="flex" flexDirection="column" gap="1.5rem" marginTop={'30px'}>
                    {/* 1st */}
                    <GroupInput className="group-input">
                      {/* Source */}
                      <FieldInput mode={mode} label="Source" id="source" name="source" placeholder="Source" type="text" />

                      {/* Purpose */}
                      <FieldInput mode={mode} label="Purpose" id="purpose" name="purpose" placeholder="Purpose" type="text" />
                    </GroupInput>

                    {/* 2nd */}
                    <GroupInput className="group-input">
                      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={'2px'} width={'100%'} position={'relative'}>
                        <Dropdowndata
                          label="Assigned To"
                          id="assignedTo"
                          name="assignedTo"
                          optional={false}
                          disabled={assignedTo === 'auto'}
                          mode={mode}
                          onChange={(e) => {
                            setFieldValue('assignedTo', e.target.value);
                          }}
                          values={[
                            { label: 'Unassigned', value: 'Unassigned' },
                            ...managersandSalesExecutive.map((user) => ({
                              label: `${user.firstName} ${user.lastName}`,
                              value: user.id,
                            })),
                          ]}
                          defaultValue="Select"
                        />

                        <Box display={'flex'} gap={'10px'} position={'absolute'} right={'0'}>
                          <Box
                            onClick={() => setassignedTo('auto')}
                            border={`2px solid ${assignedTo === 'auto' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                            borderRadius={'10px'}
                            bgcolor={
                              mode === 'dark'
                                ? assignedTo !== 'auto'
                                  ? globalColors.black
                                  : color || globalColors.blue
                                : assignedTo === 'auto'
                                ? color
                                : globalColors.lightgray
                            }
                            color={
                              mode === 'dark'
                                ? assignedTo === 'auto'
                                  ? globalColors.white
                                  : color !== 'default' && color !== ''
                                  ? color
                                  : globalColors.white || globalColors.white
                                : assignedTo === 'auto'
                                ? globalColors.white
                                : color !== 'default' && color !== ''
                                ? color
                                : globalColors.black || globalColors.black
                            }
                            paddingLeft={'5px'}
                            sx={{ cursor: 'pointer' }}
                            paddingRight={'5px'}
                            paddingTop={'2px'}
                            paddingBottom={'2px'}
                            display={'flex'}
                            alignItems={'center'}
                          >
                            <Typography fontSize={'10px'}>Cloud AI</Typography>
                          </Box>
                          {showAssigningOptions && (
                            <Box
                              onClick={() => setassignedTo('manual')}
                              borderRadius={'10px'}
                              border={`2px solid ${assignedTo === 'manual' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                              bgcolor={
                                mode === 'dark'
                                  ? assignedTo !== 'manual'
                                    ? globalColors.black
                                    : color || globalColors.blue
                                  : assignedTo === 'manual'
                                  ? color || globalColors.blue
                                  : globalColors.lightgray
                              }
                              color={
                                mode === 'dark'
                                  ? assignedTo === 'manual'
                                    ? globalColors.white
                                    : color !== 'default' && color !== ''
                                    ? color
                                    : globalColors.white || globalColors.white
                                  : assignedTo === 'manual'
                                  ? globalColors.white
                                  : color !== 'default' && color !== ''
                                  ? color
                                  : globalColors.black || globalColors.black
                              }
                              paddingLeft={'5px'}
                              sx={{ cursor: 'pointer' }}
                              paddingRight={'5px'}
                              paddingTop={'2px'}
                              paddingBottom={'2px'}
                              display={'flex'}
                              alignItems={'center'}
                            >
                              <Typography fontSize={'10px'}>Manual</Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      <FieldInput mode={mode} label="Interested In" id="interestedIn" name="interestedIn" type="text" placeholder="Interested In" />
                    </GroupInput>

                    {/* 3rd */}
                    <GroupInput className="group-input">
                      {/* Budget From */}
                      <FieldInput mode={mode} label="Budget From" id="budgetFrom" name="budgetFrom" type="number" placeholder="XXXX" />

                      {/* Budget To */}
                      <FieldInput mode={mode} label="Budget To" id="budgetTo" name="budgetTo" type="number" placeholder="XXXX" />
                    </GroupInput>

                    <GroupInput className="group-input">
                      {/* Budget From */}
                      <FieldInput
                        mode={mode}
                        label="Discount (%)"
                        id="discountPercentage"
                        name="discountPercentage"
                        type="number"
                        placeholder="XXXX"
                        error={touched.discountPercentage && errors.discountPercentage ? errors.discountPercentage : false}
                      />

                      {/* Budget To */}
                      <Box width={'100%'}></Box>
                    </GroupInput>

                    {/*  */}
                    {/* Attachment */}
                    <Box sx={{ display: 'flex' }}>
                      <InputLabel
                        style={{
                          fontFamily: 'Poppins',
                          fontWeight: '400',
                          fontSize: '16px',
                          lineHeight: '32px',
                          letterSpacing: '0px',
                          textAlign: 'left',
                          color: mode === 'dark' ? globalColors.white : globalColors.black,
                        }}
                        htmlFor="nameInput"
                      >
                        Attachments
                      </InputLabel>
                      <AttachmentUpload
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        selectedFileNames={selectedFileNames}
                        setSelectedFileNames={setSelectedFileNames}
                      />
                    </Box>

                    {/* Selected File */}
                    <Box>
                      {selectedFiles.length > 0 && (
                        <Box sx={{ marginTop: '2%' }}>
                          <ThemeProvider
                            theme={{
                              palette: {
                                primary: {
                                  main: '#007FFF',
                                  dark: '#0066CC',
                                },
                              },
                            }}
                          >
                            <Box display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                              {selectedFileNames.map((fileName, index) => {
                                return (
                                  <Box sx={crossFileContainerStyle({ selectedFiles, color, mode })}>
                                    <Box sx={{ display: 'flex', gap: '10px' }}>
                                      <FileIcon />
                                      <Typography
                                        noWrap
                                        style={{
                                          fontFamily: 'Poppins',
                                          fontSize: '14px',
                                          fontWeight: '500',
                                          lineHeight: '32px',
                                          letterSpacing: '0px',
                                          textAlign: 'left',
                                          color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                        }}
                                      >
                                        {fileName.replaceAll(' ', '_').substring(0, 10)}
                                      </Typography>
                                    </Box>
                                    {/* X button to remove image */}
                                    {fileName && (
                                      <ClearIcon
                                        onClick={() => handleRemoveAttachment(index)}
                                        sx={{
                                          fontSize: 16,
                                          marginLeft: '10px',
                                          color: mode === 'dark' ? 'white' : 'black',
                                          '&:hover': {
                                            color: globalColors.red,
                                          },
                                        }}
                                      />
                                    )}
                                  </Box>
                                );
                              })}
                            </Box>
                          </ThemeProvider>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box width={'100%'}>
                    <InputLabel
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '32px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                      }}
                    >
                      Custom Fields
                    </InputLabel>
                    <CustomFields
                      onChange={(fields) => {
                        setCustomFields(fields);
                      }}
                    />
                  </Box>
                </LeadsFormStyle>
                <Box display={'flex'} justifyContent={'center'}>
                  <Button
                    type="button"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Loading...' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      ) : (
        <NoPermission />
      )}
    </div>
  );
};

const LeadsFormStyle = styled('div')(({ theme }) => ({
  padding: '1.5rem',
  borderRadius: '20px',
  marginBottom: '2rem',
  overflow: 'auto',
  '& .group-input': {
    gap: '3rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  '& .wrapper-btn-submit': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2.5rem',
    alignItems: 'center',
    '& .btn-submit': {
      marginBottom: '1rem',
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& button': {
    textTransform: 'capitalize',
    backgroundColor: globalColors.blue,
    borderRadius: '12px',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  color: 'red',
  fontSize: '10px',
  [theme.breakpoints.down('sm')]: {
    bottom: '-15px',
  },

  [theme.breakpoints.up('md')]: {
    bottom: '-20px',
  },
}));

export default ClientsForm;
