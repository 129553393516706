import React, { FC, SVGProps } from 'react';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../../store/selector';
interface RoleBindingProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
}

const RoleBindingIcon: FC<RoleBindingProps> = ({ width, height, stroke, ...rest }) => {
  const mode = useSelector(selectThemeMode);
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1407_403)">
        <path
          d="M15.3333 12.6667C15.3333 13.3739 15.0524 14.0522 14.5523 14.5523C14.0522 15.0524 13.3739 15.3333 12.6667 15.3333H11.3333V14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667C14 12.313 13.8595 11.9739 13.6095 11.7239C13.3594 11.4738 13.0203 11.3333 12.6667 11.3333H11.3333V10H12.6667C13.3739 10 14.0522 10.281 14.5523 10.781C15.0524 11.2811 15.3333 11.9594 15.3333 12.6667ZM6 12.6667C6 11.9594 6.28095 11.2811 6.78105 10.781C7.28115 10.281 7.95942 10 8.66667 10H10V11.3333H8.66667C8.31304 11.3333 7.97391 11.4738 7.72386 11.7239C7.47381 11.9739 7.33333 12.313 7.33333 12.6667C7.33333 13.0203 7.47381 13.3594 7.72386 13.6095C7.97391 13.8595 8.31304 14 8.66667 14H10V15.3333H8.66667C7.95942 15.3333 7.28115 15.0524 6.78105 14.5523C6.28095 14.0522 6 13.3739 6 12.6667Z"
          fill={stroke ? stroke : '#F7F7F7'}
        />
        <path
          d="M9.33333 11.9987H12V13.332H9.33333V11.9987ZM6 3.33203C5.60444 3.33203 5.21776 3.44933 4.88886 3.66909C4.55996 3.88886 4.30362 4.20121 4.15224 4.56666C4.00087 4.93212 3.96126 5.33425 4.03843 5.72221C4.1156 6.11017 4.30608 6.46654 4.58579 6.74625C4.86549 7.02595 5.22186 7.21643 5.60982 7.2936C5.99778 7.37077 6.39991 7.33117 6.76537 7.17979C7.13082 7.02842 7.44318 6.77207 7.66294 6.44317C7.8827 6.11427 8 5.72759 8 5.33203C7.99842 4.80209 7.7872 4.2943 7.41247 3.91957C7.03774 3.54484 6.52995 3.33361 6 3.33203ZM6 5.9987C5.86815 5.9987 5.73925 5.9596 5.62962 5.88634C5.51999 5.81309 5.43454 5.70897 5.38408 5.58715C5.33362 5.46534 5.32042 5.33129 5.34614 5.20197C5.37187 5.07265 5.43536 4.95386 5.5286 4.86063C5.62183 4.76739 5.74062 4.7039 5.86994 4.67817C5.99926 4.65245 6.13331 4.66565 6.25512 4.71611C6.37694 4.76657 6.48106 4.85202 6.55431 4.96165C6.62757 5.07128 6.66667 5.20018 6.66667 5.33203C6.66614 5.50868 6.59573 5.67794 6.47082 5.80285C6.34591 5.92776 6.17665 5.99817 6 5.9987ZM3.54 9.9987C4.27409 9.52476 5.12633 9.26608 6 9.25203C6.17367 9.25426 6.34697 9.26852 6.51867 9.2947C7.15964 8.88359 7.90518 8.66516 8.66667 8.66536H8.836C7.96247 8.19794 6.99054 7.94434 6 7.92536C4.64667 7.92536 2 8.6387 2 10.312V11.332H4.89933C5.0736 10.8401 5.34268 10.3871 5.69133 9.9987H3.54Z"
          fill={stroke ? stroke : '#F7F7F7'}
        />
        <path
          d="M10.6667 1.33347H7.88C7.74349 0.943761 7.48925 0.606106 7.15245 0.367214C6.81564 0.128322 6.41292 0 6 0C5.58708 0 5.18436 0.128322 4.84755 0.367214C4.51075 0.606106 4.25651 0.943761 4.12 1.33347H1.33333C0.980036 1.33452 0.64151 1.47534 0.39169 1.72516C0.14187 1.97498 0.00105515 2.3135 0 2.6668L0 12.0001C0.00105515 12.3534 0.14187 12.692 0.39169 12.9418C0.64151 13.1916 0.980036 13.3324 1.33333 13.3335H4.76067C4.63495 12.8979 4.63495 12.4357 4.76067 12.0001H1.33333V2.6668H10.6667V8.6668H12V2.6668C11.9989 2.3135 11.8581 1.97498 11.6083 1.72516C11.3585 1.47534 11.02 1.33452 10.6667 1.33347ZM6 2.1668C5.86766 2.16593 5.74099 2.11297 5.64741 2.01939C5.55383 1.92581 5.50087 1.79914 5.5 1.6668C5.5 1.53419 5.55268 1.40702 5.64645 1.31325C5.74022 1.21948 5.86739 1.1668 6 1.1668C6.13261 1.1668 6.25979 1.21948 6.35355 1.31325C6.44732 1.40702 6.5 1.53419 6.5 1.6668C6.49913 1.79914 6.44617 1.92581 6.35259 2.01939C6.25901 2.11297 6.13234 2.16593 6 2.1668Z"
          fill={stroke ? stroke : '#F7F7F7'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1407_403">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoleBindingIcon;
