import { Box } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import LeadsForm from '../../components/organisms/leads/leadsForm';
import { useSelector } from 'react-redux';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../store/selector';

const AddLeads = () => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text="Leads / Create" />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          <Box width={'100%'} padding={3}>
            <LeadsForm mode={mode} color={color} />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default AddLeads;
