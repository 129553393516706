import React, { ChangeEvent, FormEvent } from 'react';
import { Input, Button, ButtonProps, Box } from '@mui/material';
import AttachmentIcon from './AttachmentIcon';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { globalColors } from '../../../utils/constants/color';
import { confirmFileSize } from '../../../utils/helpers';
import { Toast } from '../../atoms';

interface ImagePreviewProps {
  selectedFiles: File[];
  selectedFileNames: string[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setSelectedFileNames: React.Dispatch<React.SetStateAction<string[]>>;
}

const AttachmentUpload: React.FC<ImagePreviewProps> = ({ selectedFiles, setSelectedFiles, setSelectedFileNames, selectedFileNames }) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        console.log(event.target.files);
        const files = event.target.files;
        let tempFiles = [...selectedFiles];
        let tempNames = [...selectedFileNames];
        for (let i = 0; i < files.length; i++) {
          if (!confirmFileSize(files[i])) {
            Toast(`Failed to upload. Max allowed file size is 2 MB.`, 'error');
          }
          else {
            tempFiles.push(files[i]);
            tempNames.push(files[i].name);
          }
        }
        setSelectedFiles(tempFiles);
        setSelectedFileNames(tempNames);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (selectedFiles) {
      const formData = new FormData();
      formData.append('file', selectedFiles[0]);

      // Example: Send the formData using fetch
      fetch('your-upload-endpoint', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error('Error:', error));
    }
  };

  return (
    <Box sx={{ marginLeft: '2%' }}>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          multiple
          accept='.jpg, .png, .pdf, .jpeg, .doc, .docx, .xlx'
          // inputProps={{ accept: '*' }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
          style={{ display: 'none' }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            sx={{
              marginRight: '10px',
              padding: 0,
              minWidth: 0,
              border: 'none',
              '&:hover': {
                border: 'none', // Adjust the color as needed
              },
            }}
            component="span"
            variant="outlined"
            color="primary"
            {...({ component: 'span', variant: 'outlined', color: 'primary' } as ButtonProps)}
          >
            <AttachmentIcon color={color !== '' ? color : globalColors.blue} width={16} height={16} />
          </Button>

          <span
            style={{
              color: color !== '' ? color : globalColors.blue,
              fontFamily: 'Poppins',
              fontSize: '13px',
              fontWeight: '400',
              lineHeight: '32px',
              letterSpacing: '0px',
              textAlign: 'left',
              cursor: 'pointer',
              flexWrap:'nowrap'
            }}
          >
            Add Attachment
          </span>
        </label>
      </form>
    </Box>
  );
};

export default AttachmentUpload;
