import { Box, Typography } from '@mui/material';
import { Button } from '../atoms';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import ScheduleModel from '../organisms/landingPage/scheduleModel';
import { globalColors } from '../../utils/constants/color';
interface CardPlanProps {
  title: string;
  price: string;
  index: number;
  content: string[];
  extraContent: string[];
  bgcolor?: string;
}

const CardPlan = ({ title, price, content, extraContent, bgcolor = 'transparent', index }: CardPlanProps) => {
  const [openScheduleModal, setopenScheduleModal] = useState({ open: false, from: '' });

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <Box
      data-aos={index === 0 ? 'fade-right' : index === 2 ? 'fade-left' : ''}
      data-aos-offset="50"
      data-aos-delay="300"
      data-aos-easing="ease-in-sine"
      data-aos-duration="500"
      sx={{
        border: '1px solid #599BF7',
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingBottom: '80px',
        borderRadius: '16px',
        cursor: 'pointer',
        '&::before': {
          content: '""',
          position: 'absolute',
          // top: 0,
          left: 0,
          bottom: 0,
          width: '0',
          color: 'white',
          borderRadius: '16px',
          height: '0',
          // backgroundColor:
          // 'linear-gradient(45deg, rgba(4, 85, 198, 0.7) 15.75%, #121C2B 26.11%, #121C2B 81.06%),linear-gradient(0deg, #599BF7, #599BF7)',
          // backgroundColor: '#EDA841',
          transition: 'height 0.6s ease',
        },

        '&:hover': {
          '&::before': {
            content: '""',
            position: 'absolute',
            border: '1px solid #599BF7',
            bottom: 0,
            left: 0,
            width: '100%',
            borderRadius: '16px',
            height: '100%',
            background: `linear-gradient(161.01deg, rgba(4, 85, 198, 0.7) 15.75%, #121C2B 76.11%, #121C2B 81.06%),linear-gradient(0deg, #599BF7, #599BF7)`,
            // color: 'white',
            transition: 'height 0.6s ease',
          },
          transform: 'scale(1.05)',
          transition: 'transform 0.3s ease',
          '& .inner-box': {
            // backgroundColor: '#F6F6F6',
            color: 'black',
          },
        },
      }}
    >
      <Typography
        position={'relative'}
        sx={{ fontSize: { xs: '20px', lg: '30px' }, zIndex: 999, paddingTop: '20px' }}
        alignContent={'center'}
        textAlign={'center'}
      >
        {title}
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'center'}
        marginTop={'10px'}
        sx={{
          zIndex: 1,
        }}
      >
        <Box
          className="inner-box"
          sx={{
            backgroundColor: title === 'Premium' ? '#F6F6F6' : '#0455C6',
            color: title === 'Premium' ? '#101010' : '#F6F6F6',
            borderRadius: '80px 0px 80px 80px',
            width: '148px',
            zIndex: 1,
            height: { xs: '120px', sm: '120px' },
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            transition: 'background-color 0.3s', // Add this line
            padding: '10px',
          }}
        >
          {title == 'Custom' ? (
            <Typography
              sx={{ fontSize: '16px' }}
              textAlign={'center'}
              alignItems={'center'}
              alignContent={'center'}
              noWrap
              color={globalColors.white}
              fontWeight={'light'}
            >
              {price}
            </Typography>
          ) : (
            <>
              <Typography
                sx={{ fontSize: '12px' }}
                textAlign={'center'}
                alignItems={'center'}
                alignContent={'center'}
                noWrap
                color={globalColors.white}
                fontWeight={'light'}
              >
                Starting from
              </Typography>
              <Typography
                sx={{ fontSize: '26px' }}
                textAlign={'center'}
                alignItems={'center'}
                alignContent={'center'}
                noWrap
                color={globalColors.white}
                fontWeight={'bold'}
              >
                {`$${price}`}
              </Typography>
              <Typography
                sx={{ fontSize: '12px' }}
                textAlign={'center'}
                alignItems={'center'}
                alignContent={'center'}
                noWrap
                color={globalColors.white}
                fontWeight={'light'}
              >
                per month
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        margin={'auto'}
        alignContent={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'10px'}
        marginTop={'20px'}
        sx={{
          zIndex: 1,
        }}
      >
        {content.map((val) => (
          <Typography
            sx={{
              fontSize: { xs: '12px', lg: '14px' },
              textAlign: 'center',
              fontWeight: 400,
              zIndex: 1,
            }}
            key={val}
          >
            {val}
          </Typography>
        ))}
        {extraContent.map((val) => (
          <Typography
            sx={{
              fontSize:{ xs: '12px', lg: '14px' },
              textAlign: 'center',
              fontWeight: 800,
              color: globalColors.blue,
              zIndex: 1,
            }}
            key={val}
          >
            {val}
          </Typography>
        ))}
      </Box>

      <Box>
        <Box position={'absolute'} right={0} left={0} bottom={10} width={'80%'} margin={'auto'} display={'flex'} justifyContent={'center'}>
          <Button buttonColor={globalColors.blue} type="submit" onClick={() => setopenScheduleModal({ open: true, from: `${title} Pricing` })}>
            Get Started
          </Button>
        </Box>
      </Box>
      <ScheduleModel
        open={openScheduleModal.open}
        onClose={() => {
          setopenScheduleModal({ open: false, from: '' });
        }}
        from={openScheduleModal.from}
      />
    </Box>
  );
};

export default CardPlan;
