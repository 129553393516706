import { Box, Modal } from '@mui/material';
import React from 'react';
import { globalColors } from '../../../utils/constants/color';
import DemoForm from '../../molecules/demoForm';

// import ReCAPTCHA from 'react-google-recaptcha';

interface ScheduleModelProps {
  open: boolean;
  mode?: string;
  onClose: () => void;
  from: string;
}
const ScheduleModel = ({ open, onClose, mode = 'dark', from }: ScheduleModelProps) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    // border: '2px solid #000',
    boxShadow: 5,
    p: 4,
    overflow: 'auto',
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',

    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  return (
    <Modal open={open} onClose={onClose} className="content">
      <Box sx={style}>
        <DemoForm onClose={onClose} from={from} />
      </Box>
    </Modal>
  );
};

export default ScheduleModel;
