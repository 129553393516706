// import React from 'react';
import { styled, Typography } from '@mui/material';
// import { FiArrowUpRight } from 'react-icons/fi';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import DemoForm from './demoForm';

const ContactusForm = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <ContactusFormStyle data-aos="fade-left" data-aos-offset="30" data-aos-delay="300" data-aos-easing="ease-in-sine" data-aos-duration="500">
      <Typography fontSize={'30px'}>Get in touch</Typography>
      <DemoForm from={'Contact'} />
    </ContactusFormStyle>
  );
};
const ContactusFormStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  border: '1px solid #599BF7',
  borderRadius: '16px',
  width: '100%',
  margin: 'auto !important',
  padding: '30px',
  flexDirection: 'column',
  gap: '30px',
}));
export default ContactusForm;
