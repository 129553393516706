import { useSelector } from 'react-redux';
// import { NavAddFilterView } from '../../components/molecules';
import { MainLayout } from '../../components/molecules/layout';
import { CardProducts, ListProducts } from '../../components/organisms';
import { useProjects } from '../../hooks';
import { ProductCategories } from '../../utils/types/products';
import { useEffect, useState } from 'react';
import { selectNavigationLayout, selectProjectFilter, selectThemeMode } from '../../store/selector';
import { hasPermissionForAction } from '../../utils/helpers';
import { Box } from '@mui/material';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import Paginator from '../../components/atoms/paginator';

const Project = () => {
  const { view, projects, isLoading, handleSwitchView, handleCreateNewProject, getMoreData, getTotalCount } = useProjects();
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const [rowCheckboxState, setRowCheckboxState] = useState<{ [key: string]: boolean }>(Object.fromEntries(projects.map((data) => [data.id, false])));
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);
  const filterData = useSelector(selectProjectFilter);

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedRowCheckboxState = Object.fromEntries(projects.map((product) => [product.id, checked]));
    setRowCheckboxState(updatedRowCheckboxState);
  };

  const viewList = {
    Cards: (
      <CardProducts
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        rowCheckboxState={rowCheckboxState}
        setRowCheckboxState={setRowCheckboxState}
        mode={mode}
        isLoading={isLoading}
        handleSwitchView={handleSwitchView}
        view={view}
        onClickNewProduct={handleCreateNewProject}
        category={ProductCategories.Project}
        datas={projects}
        getTotalCount={getTotalCount}
        onChangePage={async (page) => {
          await getMoreData(page);
          return true;
        }}
      />
    ),
    List: (
      <ListProducts
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        rowCheckboxState={rowCheckboxState}
        setRowCheckboxState={setRowCheckboxState}
        mode={mode}
        isLoading={isLoading}
        handleSwitchView={handleSwitchView}
        view={view}
        onClickNewProduct={handleCreateNewProject}
        category={ProductCategories.Project}
        datas={projects}
        getTotalCount={getTotalCount}
        onChangePage={async (page) => {
          await getMoreData(page);
          return true;
        }}
      />
    ),
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Projects', 'View'));
    setLoading(false);
  }, []);

  useEffect(() => {}, [filterData]);

  return (
    <MainLayout titlePage="Projects" mode={mode} navigation={navigation}>
      {loading ? (
        <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <>
          {viewList[view]}
        </>
      ) : (
        <NoPermission />
      )}
    </MainLayout>
  );
};

export default Project;
