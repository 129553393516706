import { Box, Checkbox, Stack, Typography, styled } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { Field, Formik, FormikHelpers } from 'formik';
import useFormProperty from '../../../hooks/property/useFormProperty';
import { countryOption } from '../../../utils/api/dummies/location.data';
import { ValuesFormDetailProject, ValuesFormDetailProjectError, ValuesFormDetailProperty } from '../../../utils/types/productDetail';
import { Button, FieldInputTextarea, FieldInputUnit, FieldSelectOption, LabelInputProduct, Toast } from '../../atoms';
import GroupInput from '../../molecules/groupInput';
import { FieldAminities, FieldCover } from '../../molecules/productDetail';
import { FormProductProps } from './formProject';
import { FormProjectStyle } from './formProject.style';
import UploadImage from './uploadImage';
import { setAddNew } from '../../../store/routes';
import { useDispatch, useSelector } from 'react-redux';
import { generateCountryListAsOptions } from '../../../utils/helpers/countries';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { useEffect, useState } from 'react';
import UserModel from '../../../utils/api/models/userModel';
import Dropdowndata from '../../atoms/dropdown2';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { globalColors } from '../../../utils/constants/color';
import AttachmentUpload from '../leads/AttachmentUpload';
import { crossFileContainerStyle } from '../leads/leadsForm.style';
import FileIcon from '../leads/FileIcon';
import ClearIcon from '@mui/icons-material/Clear';
import { confirmFilesTotalSize, removeIdentifier } from '../../../utils/helpers';
import SalesExecutiveRepository from '../../../utils/api/repositories/salesExecutiveRepository';
import { UserRole } from '../../../store/user';
import FileRepository from '../../../utils/api/repositories/fileRepository';
import ProjectRepository from '../../../utils/api/repositories/projectRepository';
import { useParams } from 'react-router';
import PropertyRepository from '../../../utils/api/repositories/propertyRepository';
import CustomFields from '../../molecules/customFields';

const options = [
  {
    id: 'Available',
    value: 'Available',
  },
  {
    id: 'Hold',
    value: 'Hold',
  },
  {
    id: 'Sold',
    value: 'Sold',
  },
];

export const areaOptions = [
  {
    id: 'Marlas',
    value: 'Marlas',
  },
  {
    id: 'Kanals',
    value: 'Kanals',
  },
  {
    id: 'Acres',
    value: 'Acres',
  },
  {
    id: 'Square Meters',
    value: 'Square Meters',
  },
  {
    id: 'Square Feet',
    value: 'Square Feet',
  },
  {
    id: 'Square Yards',
    value: 'Square Yards',
  },
];

const FormProperty = ({ className, type }: FormProductProps) => {
  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [existingAttachments, setExistingAttachments] = useState<Parse.Object[]>([]);
  const {
    onChangeFormInput,
    onChangeCover,
    optionProjects,
    onAddNewProperty,
    formProperty,
    onChangeImages,
    handleDeleteCover,
    handleDeleteImage,
    onUpdateProject,
    setAttachments,
    onChangeCustomFields,
    handleChangeUseDownPayment,
  } = useFormProperty();
  const { id } = useParams();

  const getOrganizationMembers = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: UserModel[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData: UserModel = {
              objectId: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i].get('firstName'),
              lastName: managersAndSalesExecutives[i].get('lastName'),
              username: managersAndSalesExecutives[i].get('username'),
              role: managersAndSalesExecutives[i].get('role'),
              email: managersAndSalesExecutives[i].get('email'),
              phone: managersAndSalesExecutives[i].get('phone'),
            };
            users.push(userData);
          }
          setOrganizationMembers([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: UserModel[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData: UserModel = {
              objectId: salesExecutives[i].id,
              firstName: salesExecutives[i].get('firstName'),
              lastName: salesExecutives[i].get('lastName'),
              username: salesExecutives[i].get('username'),
              role: salesExecutives[i].get('role'),
              email: salesExecutives[i].get('email'),
              phone: salesExecutives[i].get('phone'),
            };
            users.push(userData);
          }
          setOrganizationMembers([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData: UserModel = {
          objectId: currentUser.id,
          firstName: currentUser.get('firstName'),
          lastName: currentUser.get('lastName'),
          username: currentUser.get('username'),
          role: currentUser.get('role'),
          email: currentUser.get('email'),
          phone: currentUser.get('phone'),
        };
        setOrganizationMembers([userData]);
      }
    }
  };

  const handleRemoveAttachment = (index: number) => {
    let tempFiles = [...selectedFiles];
    let tempFileNames = [...selectedFileNames];
    tempFiles.splice(index, 1);
    tempFileNames.splice(index, 1);
    setSelectedFiles(tempFiles);
    setSelectedFileNames(tempFileNames);
  };

  const handleRemoveExistingAttachment = async (index: number) => {
    const attachmentToRemove = existingAttachments[index];
    const fileRepository = new FileRepository();
    const deletion = await fileRepository.delete(attachmentToRemove.id);
    if (deletion) {
      const tempFiles = [...existingAttachments];
      tempFiles.splice(index, 1);
      setExistingAttachments(tempFiles);
      Toast('Attachment removed successfully.', 'success');
    } else {
      Toast('Attachment could not be removed successfully. Please try again', 'error');
    }
  };

  const getAllAttachments = async () => {
    const propertyRepository = new PropertyRepository();
    const attachments = await propertyRepository.getAllAttachments(id ?? '');
    if (attachments) {
      setExistingAttachments(attachments);
    }
  };

  useEffect(() => {
    getOrganizationMembers();
    if (type === 'edit') {
      getAllAttachments();
    }
  }, []);

  useEffect(() => {
    setAttachments(selectedFiles);
  }, [selectedFiles]);

  return (
    <Formik
      initialValues={{
        cover: '',
        name: '',
        type: '',
        address: '',
        consistOf: '',
        squareFootage: '',
        squarePurpose: '',
        description: '',
        sellingPrice: 0,
        soldAt: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
          .getDate()
          .toString()
          .padStart(2, '0')}`,
        soldBy: '',
        price: 0,
        downPayment: 0,
      }}
      validate={(values) => {
        const errors = {} as ValuesFormDetailProjectError;
        const message = 'Required';
        if (!formProperty.name) {
          errors['name'] = message;
        }

        if (formProperty['status'] === 'Sold' && (!formProperty.sellingPrice || parseFloat(formProperty.sellingPrice) <= 0)) {
          errors['sellingPrice'] = 'Selling price must be greater than 0';
        }

        if (formProperty['status'] === 'Sold' && !formProperty.soldBy) {
          errors['soldBy'] = 'Please select a seller';
        }

        if (!formProperty['price'] || parseFloat(formProperty['price']) <= 0) {
          errors['price'] = 'Please enter a valid amount greater than 0';
        }

        if (formProperty['useDownPayment'] && (!formProperty['downPayment'] || parseFloat(formProperty['downPayment']) <= 0)) {
          errors['downPayment'] = 'Please enter a valid amount greater than 0';
        }

        return errors;
      }}
      onSubmit={(values: ValuesFormDetailProperty, { setSubmitting }: FormikHelpers<ValuesFormDetailProperty>) => {
        if (confirmFilesTotalSize(selectedFiles)) {
          if (type === 'add') {
            onAddNewProperty(formProperty);
          }

          if (type === 'edit') {
            const objectId = formProperty.id;
            onUpdateProject(objectId, formProperty);
          }
        } else {
          Toast('You have selected too many files. The max total allowed size of the files is 40MB.', 'error');
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <FormProjectStyle onSubmit={handleSubmit} className={className}>
          <div className="row">
            <LabelInputProduct className="label">Cover Picture</LabelInputProduct>
            <FieldCover
              className="col"
              id="cover"
              name="cover"
              label="Cover Picture"
              image={formProperty['cover']['preview']}
              onChangeCover={onChangeCover}
              onDeleteCover={handleDeleteCover}
              color={color}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Title</LabelInputProduct>
            <Field
              className="col"
              id="name"
              name="name"
              placeholder="Property Title"
              as={FieldInputUnit}
              onChange={onChangeFormInput}
              value={formProperty['name']}
              error={errors.name && touched.name ? errors.name : false}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Status</LabelInputProduct>
            <FieldSelectOption
              className="col"
              id="status"
              name="status"
              options={options}
              placeholder="Property Status"
              onChange={onChangeFormInput}
              value={formProperty['status']}
            />
          </div>
          {formProperty['status'] === 'Sold' && (
            <div className="row">
              <LabelInputProduct className="label">Selling Price</LabelInputProduct>
              <Field
                className="col"
                placeholder="Property Selling Price"
                type="number"
                name="sellingPrice"
                id="sellingPrice"
                unit="PKR"
                as={FieldInputUnit}
                onChange={onChangeFormInput}
                value={formProperty['sellingPrice']}
                error={errors.sellingPrice ? errors.sellingPrice : false}
              />
            </div>
          )}
          {formProperty['status'] === 'Sold' && (
            <div className="row">
              <LabelInputProduct className="label">Sold At</LabelInputProduct>
              <Field
                className="col"
                placeholder="Property Sold At"
                type="date"
                name="soldAt"
                id="soldAt"
                as={FieldInputUnit}
                onChange={onChangeFormInput}
                value={formProperty['soldAt']}
              />
            </div>
          )}
          {formProperty['status'] === 'Sold' && (
            <>
              <div className="row">
                <LabelInputProduct className="label">Sold By</LabelInputProduct>
                <FieldSelectOption
                  className="col"
                  id="soldBy"
                  name="soldBy"
                  renderId
                  options={organizationMembers.map((user, idx) => ({
                    id: `${user.firstName} ${user.lastName}`,
                    value: user.objectId ?? idx.toString(),
                  }))}
                  placeholder="Sold By"
                  onChange={onChangeFormInput}
                  value={formProperty['soldBy']}
                  error={touched.soldBy && errors?.soldBy ? errors?.soldBy : ''}
                />
              </div>
            </>
          )}
          <div className="row">
            <LabelInputProduct className="label">Location</LabelInputProduct>
            <div className="address">
              <GroupInput className="group-input">
                <div className="item">
                  <p>Country</p>
                  <FieldSelectOption
                    id="country"
                    name="country"
                    options={generateCountryListAsOptions()}
                    placeholder="Country"
                    onChange={onChangeFormInput}
                    value={formProperty['country']}
                  />
                </div>
                <div className="item">
                  <p>State</p>
                  <Field id="state" as={FieldInputUnit} name="state" placeholder="State" onChange={onChangeFormInput} value={formProperty['state']} />
                </div>
              </GroupInput>
              <GroupInput className="group-input">
                <div className="item">
                  <p>City</p>
                  <Field as={FieldInputUnit} id="city" name="city" placeholder="City" onChange={onChangeFormInput} value={formProperty['city']} />
                </div>
                <div className="item">
                  <p>Address</p>
                  <Field
                    id="address"
                    name="address"
                    as={FieldInputUnit}
                    placeholder="Address"
                    onChange={onChangeFormInput}
                    value={formProperty['address']}
                  />
                </div>
              </GroupInput>
            </div>
          </div>
          <div className="row">
            <LabelInputProduct className="label">Available Amenities</LabelInputProduct>
            <FieldAminities amenities={formProperty?.amenities} className="col" />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Area</LabelInputProduct>
            <Stack direction="row" gap={2} className="area-wrapper col">
              <Field
                as={FieldInputUnit}
                id="area"
                name="area"
                type="number"
                className="area"
                placeholder="Property Area"
                onChange={onChangeFormInput}
                value={formProperty['area']}
              />
              <FieldSelectOption
                id="areaUnit"
                name="areaUnit"
                className="area-unit"
                options={areaOptions}
                placeholder="Unit"
                onChange={onChangeFormInput}
                value={formProperty['areaUnit']}
              />
            </Stack>
          </div>
          <div className="row" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <LabelInputProduct className="label">Upload Photos</LabelInputProduct>
            <UploadImage
              className="col"
              onChangeImages={onChangeImages}
              images={formProperty['images']}
              onDeletePicture={handleDeleteImage}
              color={color}
            />
          </div>
          <div className="row" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
            <LabelInputProduct className="label">Attachments</LabelInputProduct>
            <AttachmentUpload
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              selectedFileNames={selectedFileNames}
              setSelectedFileNames={setSelectedFileNames}
            />
          </div>
          <div className="row" style={{ paddingBottom: '2rem' }}>
            <LabelInputProduct className="label"></LabelInputProduct>
            <Box width={'100%'} marginLeft={{ xs: '0', sm: '3rem' }}>
              {(selectedFiles.length > 0 || existingAttachments.length > 0) && (
                <Box sx={{}}>
                  <ThemeProvider
                    theme={{
                      palette: {
                        primary: {
                          main: '#007FFF',
                          dark: '#0066CC',
                        },
                      },
                    }}
                  >
                    <Box display={'flex'} flexDirection={'row'} gap={'1rem'} flexWrap={'wrap'}>
                      {existingAttachments.map((attachment, idx) => {
                        return (
                          <Box sx={crossFileContainerStyle({ selectedFiles: existingAttachments, color, mode })}>
                            <Box
                              sx={{ display: 'flex', gap: '10px' }}
                              onClick={async () => {
                                try {
                                  const response = await fetch(attachment.get('file')['_url']);
                                  const blob = await response.blob();
                                  const blobUrl = URL.createObjectURL(blob);

                                  const link = document.createElement('a');
                                  link.href = blobUrl;
                                  link.download = attachment.get('file')['_name'];
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);

                                  URL.revokeObjectURL(blobUrl);
                                } catch (error) {
                                  Toast('This attachment could not be downloaded successfully. Please try again', 'error');
                                }
                              }}
                            >
                              <FileIcon />
                              <Typography
                                noWrap
                                style={{
                                  fontFamily: 'Poppins',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '32px',
                                  letterSpacing: '0px',
                                  textAlign: 'left',
                                  color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                }}
                              >
                                {removeIdentifier(attachment.get('file')['_name'])}
                              </Typography>
                            </Box>
                            {/* X button to remove image */}
                            <ClearIcon
                              onClick={() => handleRemoveExistingAttachment(idx)}
                              sx={{
                                fontSize: 16,
                                marginLeft: '10px',
                                color: mode === 'dark' ? 'white' : 'black',
                                '&:hover': {
                                  color: globalColors.red,
                                },
                              }}
                            />
                          </Box>
                        );
                      })}
                      {selectedFileNames.map((fileName, index) => {
                        return (
                          <Box sx={crossFileContainerStyle({ selectedFiles, color, mode })}>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                              <FileIcon />
                              <Typography
                                noWrap
                                style={{
                                  fontFamily: 'Poppins',
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  lineHeight: '32px',
                                  letterSpacing: '0px',
                                  textAlign: 'left',
                                  color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                }}
                              >
                                {fileName.replaceAll(' ', '_').substring(0, 10)}
                              </Typography>
                            </Box>
                            {fileName && (
                              <ClearIcon
                                onClick={() => handleRemoveAttachment(index)}
                                sx={{
                                  fontSize: 16,
                                  marginLeft: '10px',
                                  color: mode === 'dark' ? 'white' : 'black',
                                  '&:hover': {
                                    color: globalColors.red,
                                  },
                                }}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Box>
                  </ThemeProvider>
                </Box>
              )}
            </Box>
          </div>
          <div className="row">
            <LabelInputProduct className="label">Price</LabelInputProduct>
            <Field
              className="col"
              placeholder="Property Price"
              type="number"
              name="price"
              id="price"
              unit="PKR"
              as={FieldInputUnit}
              onChange={onChangeFormInput}
              value={formProperty['price']}
              error={errors.price ? errors.price : false}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Down Payment</LabelInputProduct>
            <Box sx={{flex: 1}} display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
              <Field
                className="col"
                placeholder="Down Payment"
                type="number"
                name="downPayment"
                id="downPayment"
                unit="PKR"
                as={FieldInputUnit}
                onChange={onChangeFormInput}
                value={formProperty['downPayment']}
                error={errors.downPayment ? errors.downPayment : false}
              />
              <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
                <Checkbox
                  size="small"
                  sx={{
                    color: color || globalColors.blue,
                    '&.Mui-checked': {
                      color: color || globalColors.secondBlue,
                    },
                    marginTop: '-7px',
                  }}
                  onChange={() => handleChangeUseDownPayment(!formProperty['useDownPayment'])}
                  checked={formProperty['useDownPayment']}
                />
                <Typography sx={{ fontSize: '12px' }} color={globalColors.gray}>
                  Use Down Payment as the Primary Price
                </Typography>
              </Box>
            </Box>
          </div>

          <div className="row">
            <LabelInputProduct className="label" suffix="(optional)">
              Description
            </LabelInputProduct>
            <FieldInputTextarea
              className="col"
              placeholder="Property Description"
              name="description"
              id="description"
              onChange={onChangeFormInput}
              value={formProperty['description']}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Contact Name</LabelInputProduct>
            <Field
              className="col"
              placeholder="Contact Full Name"
              type="text"
              name="contactName"
              id="contactName"
              as={FieldInputUnit}
              onChange={onChangeFormInput}
              value={formProperty['contactName']}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Contact Primary Phone</LabelInputProduct>
            <Field
              className="col"
              placeholder="Primary Phone"
              type="number"
              name="contactPhone"
              id="contactPhone"
              as={FieldInputUnit}
              onChange={onChangeFormInput}
              value={formProperty['contactPhone']}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Contact Secondary Phone</LabelInputProduct>
            <Field
              className="col"
              placeholder="Secondary Phone"
              type="number"
              name="contactSecondaryPhone"
              id="contactSecondaryPhone"
              as={FieldInputUnit}
              onChange={onChangeFormInput}
              value={formProperty['contactSecondaryPhone']}
            />
          </div>
          <div className="row">
            <LabelInputProduct className="label">Custom Fields</LabelInputProduct>
            <CustomFields onChange={onChangeCustomFields} initialFields={formProperty['customFields']} />
          </div>

          <div className="row btn-wrapper">
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </FormProjectStyle>
      )}
    </Formik>
  );
};

export default FormProperty;
