import { styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { ICAddImage, ICDelete } from '../../../assets/Icons';
import { globalColors } from '../../../utils/constants/color';
import { validateUploadImage } from '../../../utils/helpers';
import { ButtonIconCircle } from '../../atoms';
import { IImages } from '../../../store/projects';
import { RiImageAddLine } from 'react-icons/ri';

interface UploadImageProps {
  className?: string;
  images: IImages[];
  onChangeImages: (data: IImages[]) => IImages[];
  onDeletePicture: (id: string) => void;
  color: string;
}

const UploadImage = (props: UploadImageProps) => {
  const handleInputImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const resultCover = validateUploadImage(files);

      if (resultCover.length > 0) {
        let images: IImages[] = [];
        for (let i = 0; i < resultCover.length; i++) {
          const processUrl = URL.createObjectURL(resultCover[i]);
          const image = {
            id: Math.random().toString(36).substr(2, 9),
            file: resultCover[i],
            url: processUrl,
          };
          images.push(image);
        }

        if (props.onChangeImages) {
          props.onChangeImages(images);
        }
      }
    }
  };

  return (
    <UploadImageStyle className={props.className}>
      {props.images.length > 0 &&
        props.images.map((image, index) => (
          <>
            <div className="photo">
              <img key={image.id} className="image" src={image.url} alt={image.url} />
              <div className="btn-delete">
                <ButtonIconCircle
                  type="button"
                  src={ICDelete}
                  onClick={() => {
                    if (props.onDeletePicture) {
                      props.onDeletePicture(image.id);
                    }
                  }}
                />
              </div>
            </div>
          </>
        ))}
      <div className="image btn-add" style={{border: `1px solid ${props.color || globalColors.blue}`,}}>
        <input type="file" multiple onChange={(e) => handleInputImage(e)} />
        <RiImageAddLine size={'1.5rem'} color={props.color || globalColors.blue} />
      </div>
    </UploadImageStyle>
  );
};

const UploadImageStyle = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  width: '100%',
  gap: '1rem',

  '& .image': {
    borderRadius: '12px',
    width: '100%',
    minWidth: '98px',
    height: '100%',
    objectFit: 'contain',
  },
  '& .btn-add': {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    borderRadius: '12px',
    width: '100%',
    height: '100%',
    minHeight: '100px',
    '& input': {
      cursor: 'pointer',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },

  '& .photo': {
    position: 'relative',
    '& .btn-delete': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      '& img': {
        width: '1rem',
        height: '1rem',
      },

      '&:hover': {
        opacity: '0.7',
      },
    },
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '100%',
      height: '150px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '.photo': {
      width: 'max-content',
    },
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '150px',
      height: '150px',
    },
    '& .btn-add': {
      width: '150px',
      height: '150px',
    },
  },
}));

export default UploadImage;
