import { Box, Select, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { sortEmailFtn } from '../../store/slices';
import { sortEmail } from '../../store/selector';

interface SortTableProps {
  mode: string;
  values: number[];
  selectedValue: number;
  setSelectedValue: (value: number) => void;
  label?: string;
}

const YearSelector: React.FC<SortTableProps> = ({ mode, values, selectedValue, setSelectedValue, label }) => {

  return (
    <Box>
      <Box
        bgcolor={mode === 'dark' ? globalColors?.black : globalColors.white}
        border={`1px solid ${mode === 'dark' && globalColors?.border.gray}`}
        display="flex"
        gap={'10px'}
        sx={{
          borderRadius: '30px',
          height: '40px',
          paddingLeft: '10px',
          marginTop: '0px',
        }}
      >
        <Typography margin={'auto'} fontSize={'12px'} color="#939393">
          {label ?? 'Year'}
        </Typography>
        <Select
          value={selectedValue}
          onChange={(event) => {
            setSelectedValue(event.target.value as number);
          }}
          sx={{
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            fontSize: '12px',
            '& .MuiSelect-icon': {
              color: mode === 'dark' ? globalColors.white : globalColors.black,
              backgroundColor: mode === 'dark' ? globalColors?.black : globalColors.white,
            },
            '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
          }}
        >
          {values.map((value) => (
            <MenuItem 
              sx={{
                border: 'none', 
                backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white, 
                color: mode === 'dark' ? globalColors.white : globalColors.black,  
                '&:hover': {
                  color: mode === 'dark' ? globalColors.black : globalColors.white,
                  backgroundColor: mode === 'dark' ? globalColors.white : globalColors.black, 
                },
              }}
              key={value} value={value} className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default YearSelector;
