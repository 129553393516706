import { styled } from '@mui/material';
import React from 'react';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor } from '../../store/selector';

interface ButtonProps {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  buttonColor?: string;
  disabled?: boolean;
  selected?: boolean;
}
const Button = ({ children, onClick, className, type, disabled, selected, buttonColor }: ButtonProps) => {
  const color = useSelector(selectColor);

  return (
    <ButtonStyle
      onClick={onClick}
      className={className}
      type={type}
      color={buttonColor ? buttonColor : type == 'reset' ? globalColors.red : color}
      disabled={disabled}
      selected={selected}
    >
      {children}
    </ButtonStyle>
  );
};

// * Style
const ButtonStyle = styled('button')<{ color: string; disabled?: boolean; selected?: boolean; type?: string }>((props) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  opacity: props.disabled ? '0.5' : '1',
  height: '40px',
  // zIndex: 9999999,
  // background: props?.color !== 'default' && props?.color !== '' ? props?.color : globalColors.blue,
  background:
    props.color !== 'default' && props.color !== ''
      ? props.color
      : props.selected === undefined
      ? props.type === 'reset'
        ? globalColors.blackLight
        : globalColors.blue
      : props.selected
      ? globalColors.blue // Add a color for when the button is selected
      : globalColors.blackLight,
  color: globalColors.white,
  width: '260px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: props.type === 'reset' ? `1px solid ${props.color ? props.color : globalColors.blue}` : '',
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default Button;
