import { Box, Modal } from '@mui/material';
import { FormRegister } from '../../components/organisms';

const styleModal = {
  overflow: 'auto',
};
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  minWidth: '1002px',
  boxShadow: 24,
  backgroundColor: 'transparent',
  margin: '2rem 0',
  '@media (min-width: 1280px)': {
    top: '38rem',
  },
  '@media (max-width: 1366px)': {
    minWidth: '80% !important',
    top: '37rem !important',
  },
  '@media (max-width: 390px)': {
    top: '55rem !important',
  },
};

interface ModalRegisterProps {
  open: boolean;
  onClose: () => void;
  onLogin: () => void;
}
const ModalRegister = ({ open, onClose, onLogin }: ModalRegisterProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-content-wrapper"
      sx={styleModal}
    >
      <Box sx={style}>
        <FormRegister onLogin={onLogin} onClose={onClose} />
      </Box>
    </Modal>
  );
};

export default ModalRegister;
