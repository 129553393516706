import { useState } from 'react';
import { IFormDetailProject } from '../store/projects';
import { IFormDetailProperty } from '../store/property';
import ProjectRepository from '../utils/api/repositories/projectRepository';
import PropertyRepository from '../utils/api/repositories/propertyRepository';
import { ProductCategories } from '../utils/types/products';
import ProjectComponentModel from '../utils/api/models/projectComponentModel';
import ProjectComponentRepository from '../utils/api/repositories/projectComponentRepository';

const useModalViewProducts = () => {
  const [openModalView, setOpenModalView] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [data, setData] = useState<IFormDetailProperty | IFormDetailProject | null>();
  const [productType, setProductType] = useState<ProductCategories>();
  const handleOpenModalView = async (type: ProductCategories, id: string) => {
    setOpenModalView(true);
    setLoadingModal(true);

    if (type === ProductCategories.Property) {
      setProductType(ProductCategories.Property);
      const propertyRepostiory = new PropertyRepository();
      const getData = await propertyRepostiory.getByObjectId(id);
      if (getData) {
        setData(getData);
      }
    }

    else if (type === ProductCategories.Project) {
      setProductType(ProductCategories.Project);
      const projectRepostiory = new ProjectRepository();
      const getData = await projectRepostiory.getByObjectId(id);
      if (getData) {
        setData(getData);
      }
    }
    setLoadingModal(false);
  };
  const handleCloseModalView = () => setOpenModalView(false);

  return { openModalView, handleOpenModalView, handleCloseModalView, loadingModal, productType, data };
};

export default useModalViewProducts;
