import { Box, ButtonBase, Grid, Typography, styled } from '@mui/material';
import { IMGArrow, IMGHeaderLandingPage, LandingBg } from '../../../assets/Images';
import { Button } from '../../atoms';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Timer from '../../atoms/timer';
import { globalColors } from '../../../utils/constants/color';
import { BsArrowDownCircleFill } from 'react-icons/bs';
import { ArrowBack, ArrowBackIos, ArrowDownwardRounded } from '@mui/icons-material';
import { FaArrowCircleDown, FaArrowDown } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension: string, time: number) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time: number) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time: number) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time: number) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time: number) => (time / daySeconds) | 0;

type HeroSectionProps = {
  onButtonClick: () => void;
  scrollToContactForm: () => void;
};

const HeroSection: React.FC<HeroSectionProps> = ({ onButtonClick, scrollToContactForm }) => {
  const navigate = useNavigate();

  const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = startTime + 243248; // use UNIX timestamp in seconds

  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <HeroSectionStyle>
      <Box width={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'} overflow={'hidden'}>
        <Box
          height={'20vh'}
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'start' }}
          alignItems={'end'}
          maxWidth={'lg'}
          mx="auto"
          width={'100%'}
          overflow={'hidden'}
        >
          <Typography
            sx={{
              width: { xs: '100%' },
              fontSize: { lg: '60px', xs: '30px', sm: '40px', md: '45px' },
              textAlign: { xs: 'center', md: 'left' },
              fontWeight: 600,
              color: globalColors.mainBlue,
              paddingLeft: { md: '30px', lg: 0 },
            }}
            whiteSpace={'pre-wrap'}
            data-aos="zoom-in"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            AI Powered
          </Typography>
        </Box>
        <Box
          // height={'50vh'}
          width={'100%'}
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'start' }}
          alignItems={'start'}
          sx={{
            background:
              'linear-gradient(161.01deg, rgba(4, 85, 198, 0.7) 15.75%, #121C2B 76.11%, #121C2B 81.06%),linear-gradient(0deg, #599BF7, #599BF7)',
          }}
        >
          <Box maxWidth={'lg'} mx="auto" width={'100%'} sx={{ paddingLeft: { md: '30px', lg: 0 } }}>
            <Typography
              sx={{
                width: { xs: '100%' },
                fontSize: { lg: '60px', xs: '30px', sm: '40px', md: '45px' },
                textAlign: { xs: 'center', md: 'left' },
                fontWeight: 600,
                paddingBottom: '50px',
              }}
              whiteSpace={'pre-wrap'}
              data-aos="zoom-in"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              Prop-Tech Solution
            </Typography>
            <Box
              sx={{
                zIndex: 99999,
                display: 'flex',
                justifyContent: 'start',
                borderRadius: '12px',
                marginBottom: { xs: '5rem', md: '5rem' },
                width: { xs: '100%', md: '50%' },
              }}
              data-aos="fade-right"
              data-aos-easing="ease-in-sine"
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Typography
                  sx={{
                    padding: { xs: '20px', md: 0 },
                    fontWeight: 'light',
                    fontSize: { lg: '16px', xs: '12px', sm: '14px' },
                    textAlign: { xs: 'center', md: 'left' },
                    fontFamily: 'Sans',
                  }}
                >
                  Effortlessly scale your business and increase sales by upto 40%. <br />
                  <br />
                  Streamlined Processes, Enhanced Workflow Automation, Data Mastery, Advanced Lead Tracking, and Analytics - <br />
                  <span style={{ fontWeight: 900, fontStyle: 'italic' }}>All in One Software Solution</span>
                </Typography>
                <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={{xs: 'center', md: 'start'}} alignItems={'center'} gap={'1rem'} paddingTop={'4rem'}>
                  <ButtonBase
                    sx={{
                      border: '1px solid #0455C6',
                      borderRadius: '8px',
                      bgcolor: '#0455C6',
                      color: 'white',
                      padding: '5px 20px',
                      marginTop: '8px',
                      width: {xs: '50%', md: 'auto'}
                    }}
                    onClick={() => navigate('/onboarding')}
                  >
                    <Typography
                      sx={{
                        fontsize: '2rem',
                        color: 'white',
                      }}
                    >
                      Join Now
                    </Typography>
                  </ButtonBase>
                  <ButtonBase
                    sx={{
                      bgcolor: 'transparent',
                      color: 'white',
                      padding: '5px 20px',
                      marginTop: '8px',
                    }}
                    onClick={() => {navigate('/requestDemo')}}
                  >
                    <Typography
                      sx={{
                        fontsize: '2rem',
                        color: 'white',
                      }}
                    >
                      Request a Demo
                    </Typography>
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
            <Box width={'100%'} display={{ xs: 'flex', md: 'none' }} justifyContent={'center'}>
              <img src={LandingBg} width={'80%'} alt="" />
            </Box>
          </Box>
        </Box>
        <Box position={'absolute'} width={'48%'} right={0} display={{ xs: 'none', md: 'flex' }} justifyContent={'end'}>
          <img src={LandingBg} width={'100%'} alt="" />
        </Box>
      </Box>
      <Box width={'100%'} display={'flex'} justifyContent={'center'} paddingTop={'2rem'}>
        <Box
          width={'4rem'}
          height={'4rem'}
          borderRadius={'100px'}
          bgcolor={globalColors.mainBlue}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ cursor: 'pointer', ':hover': { transform: 'scale(1.10)', transition: 'all 0.2s ease-in-out' } }}
          onClick={onButtonClick}
        >
          <IoIosArrowDown size={'2rem'} style={{ color: globalColors.black }} />
        </Box>
      </Box>
      {/* <Box
        onClick={onButtonClick}
        margin={'auto'}
        paddingTop={'2rem'}
        display={'flex'}
        // position={'relative'}
        justifyContent={'center'}
        alignItems={'center'}
        // height={'100%'}
        // width={'101.2px'}
        sx={{ cursor: 'pointer' }}
      >
        <img style={{ display: 'flex', justifyContent: 'center', margin: 'auto', cursor: 'pointer' }} src={IMGArrow} alt="" />
      </Box> */}
    </HeroSectionStyle>
  );
};

const HeroSectionStyle = styled('div')(({ theme }) => ({
  // backgroundImage: `url(${LandingBg})`,
  // backgroundSize: 'cover',
  // backgroundPosition: 'center',
  // backgroundRepeat: 'no-repeat',
  height: '100%',
  width: '100%',
  color: 'white',
  // paddingTop: '5rem',
  paddingBottom: '5rem',
  // position: 'relative',
}));

export default HeroSection;
