import { SelectChangeEvent } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeCover } from '../../components/molecules/productDetail/fieldCover';
import {
  IFormDetailProject,
  IImages,
  // addNewProject,
  deleteCoverProject,
  deleteImageProject,
  resetFormProject,
  updateCover,
  updateFieldProject,
  updateImages,
  updateAttachments,
  updateComponents,
  removeComponent,
  editComponent,
  fetchComponents,
  updateCustomFields
} from '../../store/projects';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectRepository from '../../utils/api/repositories/projectRepository';
import ProjectModel from '../../utils/api/models/projectModel';
import { isArray, isFile } from '../../utils/helpers';
import { Picture } from '../../utils/api/models/propertyModel';
import { selectProjectState } from '../../store/selector';
import ProjectComponentModel from '../../utils/api/models/projectComponentModel';
import { CustomField } from '../../components/molecules/customFields';

const useFormProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: objectId } = useParams();
  const projectState = useSelector(selectProjectState);
  const onChangeFormInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const field = e.target.name as keyof IFormDetailProject;
    dispatch(updateFieldProject({ field, value: e.target.value }));
  };

  const onChangeCover = (callback: ChangeCover) => {
    dispatch(updateCover({ value: callback }));
    return callback;
  };

  const onChangeImages = (newImages: IImages[]) => {
    dispatch(updateImages({ newImages }));
    return newImages;
  };

  const onChangeCustomFields = (customFields: CustomField[]) => {
    dispatch(updateCustomFields({ customFields }));
    return customFields;
  }

  const onAddComponent = (component: ProjectComponentModel) => {
    dispatch(updateComponents({ component }));
    return component;
  }

  const onFetchComponents = (components: ProjectComponentModel[]) => {
    dispatch(fetchComponents({ components }));
    return components;
  }

  const onEditComponent = (component: ProjectComponentModel, idx: number) => {
    dispatch(editComponent({ component, idx }));
    return component;
  }

  const handleRemoveComponent = (idx: number) => {
    dispatch(removeComponent({ idx }));
  };

  const onAddNewProject = async (values: IFormDetailProject) => {
    const pictureFiles: File[] = [];

    if (values.images) {
      values.images.forEach((image) => {
        if (image.file) {
          pictureFiles.push(image.file);
        }
      });
    }

    const projectRepostiroy = new ProjectRepository();
    const project: ProjectModel = {
      name: values.name,
      coverPicture: values.cover.files[0],
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      consistOf: values.consistOf,
      consistOfUnit: values.consistOfUnit,
      floorsBlocks: values.area,
      status: values.status,
      purpose: values.purpose,
      pictures: pictureFiles,
      type: values.type,
      contactSecondaryPhone: values.contactSecondaryPhone,
      country: values.country,
      state: values.state,
      city: values.city,
      address: values.address,
      description: values.description,
      category: '',
      notes: null,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: null,
      updatedBy: null,
      area: values.area,
      areaUnit: values.areaUnit,
      sellingPrice: values.sellingPrice,
      soldAt: values.soldAt ? new Date(values.soldAt) : new Date(),
      soldBy: values.soldBy,
      attachments: values.attachments,
      components: values.components,
      customFields: values.customFields?.filter((field) => field.label !== '' && field.value !== '')
    };

    await projectRepostiroy.create(project);

    navigate('/projects');
    dispatch(resetFormProject());
  };

  useEffect(() => {
    return () => {
      if (!objectId) {
        dispatch(resetFormProject());
      }
    };
  }, []);

  const onUpdateProject = async (objectId: string, values: IFormDetailProject) => {
    const pictures: Picture[] = [];

    let coverPictureFile = null;
    if (values.cover.files[0]) {
      coverPictureFile = values.cover.files[0];
    }

    if (isArray(values.images) && values.images.length > 0) {
      values.images.forEach((image) => {
        if (isFile(image.file)) {
          pictures.push({
            file: image.file,
            id: image.id,
            url: image.url,
          });
        } else {
          pictures.push({
            file: null,
            id: image.id,
            url: image.url,
          });
        }
      });
    }

    const projectRepostiroy = new ProjectRepository();
    const project: ProjectModel = {
      name: values.name,
      coverPicture: coverPictureFile,
      contactName: values.contactName,
      contactPhone: values.contactPhone,
      consistOf: values.consistOf,
      consistOfUnit: values.consistOfUnit,
      floorsBlocks: values.area,
      status: values.status,
      purpose: values.purpose,
      pictures: pictures,
      type: values.type,
      contactSecondaryPhone: values.contactSecondaryPhone,
      country: values.country,
      state: values.state,
      city: values.city,
      address: values.address,
      description: values.description,
      category: '',
      notes: null,
      updatedAt: new Date(),
      updatedBy: null,
      area: values.area,
      areaUnit: values.areaUnit,
      sellingPrice: values.sellingPrice,
      soldAt: values.soldAt ? new Date(values.soldAt) : new Date(),
      soldBy: values.soldBy,
      attachments: values.attachments,
      components: values.components,
      customFields: values.customFields?.filter((field) => field.label !== '' && field.value !== '')
    };

    await projectRepostiroy.updateByObjectId(objectId, project);
    dispatch(resetFormProject());
    navigate('/projects');
  };

  const handleDeleteCover = () => {
    dispatch(deleteCoverProject());
  };

  const handleDeleteImage = (id: string) => {
    dispatch(deleteImageProject({ id }));
  };

  const setAttachments = (files: File[]) => {
    dispatch(updateAttachments({files}));
  };

  return {
    dispatch,
    onChangeFormInput,
    handleDeleteCover,
    onAddNewProject,
    onChangeCover,
    // onSaveEditProject,
    formProject: projectState.formProject,
    onChangeImages,
    onChangeCustomFields,
    handleDeleteImage,
    onUpdateProject,
    setAttachments,
    onAddComponent,
    onFetchComponents,
    handleRemoveComponent,
    onEditComponent
  };
};

export default useFormProject;
