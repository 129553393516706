import React, { FC, SVGProps } from 'react';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../../store/selector';
interface UserProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
}

const UserIcon: FC<UserProps> = ({ width, height, stroke, ...rest }) => {
  const mode = useSelector(selectThemeMode);
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 6.66536C9.47341 6.66536 10.6673 5.47146 10.6673 3.9987C10.6673 2.52594 9.47341 1.33203 8.00065 1.33203C6.52789 1.33203 5.33398 2.52594 5.33398 3.9987C5.33398 5.47146 6.52789 6.66536 8.00065 6.66536Z"
        stroke={stroke ? stroke : '#F7F7F7'}
      />
      <path
        d="M13.3317 11.9974C13.3337 11.8881 13.3337 11.7767 13.3337 11.6641C13.3337 10.0074 10.9457 8.66406 8.00033 8.66406C5.05499 8.66406 2.66699 10.0074 2.66699 11.6641C2.66699 13.3207 2.66699 14.6641 8.00033 14.6641C9.48766 14.6641 10.5603 14.5594 11.3337 14.3727"
        stroke={stroke ? stroke : '#F7F7F7'}
        stroke-linecap="round"
      />
    </svg>
  );
};

export default UserIcon;
