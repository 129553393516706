import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Form, useNavigate } from 'react-router-dom';
import { globalColors } from '../../utils/constants/color';
import { ButtonIconCircle, ButtonText, FieldInput, Toast } from '../../components/atoms';
import { HeadingFormAuth } from '../../components/molecules';
import GroupInput from '../../components/molecules/groupInput';
import { FieldCover } from '../../components/molecules/productDetail';
import { ICAddImage, ICDelete } from '../../assets/Icons';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { validateUploadImage } from '../../utils/helpers';
import { IImages } from '../../store/projects';
import Dropdown from '../../components/atoms/dropdown';
import { useRegister } from '../../hooks/auth';
import pcLogo from '../../assets/Images/logo.svg';
import { Button } from '../../components/atoms';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import UserRepository from '../../utils/api/repositories/userRepository';
import Loader from '../../components/atoms/loader';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import OrganizationModel from '../../utils/api/models/organizationModel';
import GalleryRepository from '../../utils/api/repositories/galleryRepository';
import GalleryModel from '../../utils/api/models/galleryModel';
import Parse from 'parse';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../store/user';

interface ValuesFormRegister {
  firstName: string;
  lastName: string;
  companyName: string;
  companyAddress: string;
  numberOfEmployees: string;
  companyRegistrationYear: string;
  // ownerName: string;
  // contractOfOwner: string;
  companyPhoneNumber: string;
  secondNumber: string;
  pointOfContact: string;
  contactDesignation: string;
  companyWebsite: string;
  aboutUs: string;
  logo: string;
}

const AccountSetup = () => {
  const { validationSchema } = useRegister();
  const formikRef = useRef<FormikProps<ValuesFormRegister>>(null);
  const [formValues, setFormValues] = useState<ValuesFormRegister>({
    firstName: '',
    lastName: '',
    companyName: '',
    companyAddress: '',
    numberOfEmployees: '',
    companyRegistrationYear: '',
    companyPhoneNumber: '',
    secondNumber: '',
    pointOfContact: '',
    contactDesignation: '',
    companyWebsite: '',
    aboutUs: '',
    logo: '',
  });
  const [logo, setLogo] = useState<IImages | null>(null);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputImage = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const resultFiles = validateUploadImage(files);

      if (resultFiles.length > 0) {
        const processUrl = URL.createObjectURL(resultFiles[0]);
        const image = {
          id: Math.random().toString(36).substr(2, 9),
          file: resultFiles[0],
          url: processUrl,
        };
        formikRef.current?.setFieldValue('logo', processUrl);
        setLogo(image);
      }
    }
  };

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      const userRepository = new UserRepository();
      const organization = await userRepository.getCurrentOrganization();
      if (organization) {
        if (organization.get('initialSetupCompleted')) {
          navigate('/dashboard');
        }
        setFormValues({
          firstName: '',
          lastName: '',
          companyName: organization.get('name'),
          companyAddress: '',
          numberOfEmployees: organization.get('memberCount')?.toString(),
          companyRegistrationYear: '',
          companyPhoneNumber: '',
          secondNumber: '',
          pointOfContact: '',
          contactDesignation: '',
          companyWebsite: '',
          aboutUs: '',
          logo: '',
        });
        setLoading(false);
      } else {
        setLoading(false);
        Toast('There was an error fetching your account details. Please try again.', 'error');
      }
    };

    fetchOrganizationDetails();
  }, []);

  return (
    <Box>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Grid container display={'flex'} justifyContent={'space-between'} gap={'2rem'} color={globalColors.white} padding={'2rem'}>
            <Grid item display={'flex'} gap={'5px'} justifyContent={'start'} alignItems={'center'} zIndex={9999999} xs={4}>
              <ArrowBack
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const userRepository = new UserRepository();
                  await userRepository.logout();
                  navigate('/login');
                }}
              />
              <Typography
                fontSize={'12px'}
                sx={{ cursor: 'pointer' }}
                onClick={async () => {
                  const userRepository = new UserRepository();
                  await userRepository.logout();
                  navigate('/login');
                }}
              >
                Logout
              </Typography>
            </Grid>
          </Grid>
          <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} maxWidth={'xl'} margin={'auto'}>
            <Box
              display={'flex'}
              justifyContent={'center'}
              marginTop={'4rem'}
              alignItems={'center'}
              marginLeft={'2rem'}
              marginRight={'2rem'}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <img src={pcLogo}></img>
              <Typography variant="h4" color={globalColors.white} textAlign={'center'}>
                Welcome to
                <br />
                <span style={{ color: globalColors.mainBlue }}>Property Cloud</span>
              </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'} margin={'2rem'} alignItems={'center'}>
              <Typography color={globalColors.gray} textAlign={'center'}>
                (Please fill all of the details as required below and press next to continue)
              </Typography>
            </Box>
            <FormRegisterStyle>
              {/* HEADING */}
              {/* FORM GROUP */}
              <Formik
                innerRef={formikRef}
                initialValues={{
                  firstName: '',
                  lastName: '',
                  companyName: formValues.companyName,
                  companyAddress: '',
                  numberOfEmployees: formValues.numberOfEmployees,
                  companyRegistrationYear: '',
                  // ownerName: '',
                  // contractOfOwner: '',
                  companyPhoneNumber: '',
                  secondNumber: '',
                  pointOfContact: '',
                  contactDesignation: '',
                  companyWebsite: '',
                  aboutUs: '',
                  logo: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values: ValuesFormRegister, { setSubmitting }: FormikHelpers<ValuesFormRegister>) => {
                  try {
                    setProcessing(true);
                    const userRepository = new UserRepository();
                    const updatedUser = await userRepository.updateUserName(values.firstName, values.lastName);
                    if (updatedUser) {
                      dispatch(setUserData(updatedUser));
                      const galleryRepository = new GalleryRepository();
                      if (logo && logo.file) {
                        const gallery: GalleryModel = {
                          file: logo?.file,
                        };
                        console.log(gallery);
                        const savedGallery = await galleryRepository.createAndReturn(gallery);
                        if (savedGallery) {
                          const organizationRepository = new OrganizationRepository();
                          const organization: OrganizationModel = {
                            address: values.companyAddress,
                            registrationYear: parseInt(values.companyRegistrationYear),
                            primaryPhone: values.companyPhoneNumber,
                            secondaryPhone: values.secondNumber,
                            contact: {
                              name: values.pointOfContact,
                              designation: values.contactDesignation,
                            },
                            website: values.companyWebsite,
                            heardHow: values.aboutUs,
                            logo: savedGallery,
                            initialSetupCompleted: true,
                          };
                          const updatedOrganization = await organizationRepository.update(organization, updatedUser.get('organization'));
                          if (updatedOrganization) {
                            Toast('Account setup successful.', 'success');
                            navigate('/dashboard');
                          } else {
                            Toast('There was an error updating your account details. Please try again.', 'error');
                          }
                        } else {
                          Toast('There was an error updating your logo. Please try again.', 'error');
                        }
                      }
                      else {
                        Toast('There was an error updating your account details. Please try again.', 'error');
                      }
                    } else {
                      Toast('There was an error updating your account details. Please try again.', 'error');
                    }
                    setProcessing(false);
                  } catch (error) {
                    console.log('ERROR SUBMITTING FORM:', error);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column" gap="1.5rem">
                      <Box display="flex" justifyContent={'start'} flexDirection={'column'} alignItems={'start'} gap="10px">
                        <Box display="flex" gap="10px" mb={'0.8rem'} fontSize="1rem">
                          <Typography color={globalColors.white} fontSize="1rem">
                            Company Logo
                          </Typography>
                          <Typography variant="subtitle1" sx={{ color: 'red' }}>
                            *
                          </Typography>
                        </Box>
                        <UploadImageStyle className={''}>
                          {logo === null ? (
                            <>
                              <div className="image btn-add">
                                <input type="file" onChange={handleInputImage} />
                                <img src={ICAddImage} alt="Add Company Logo" />
                              </div>
                              {errors.logo && (
                                <Typography variant="caption" className="error" sx={{ color: 'red' }}>
                                  {errors.logo}
                                </Typography>
                              )}
                            </>
                          ) : (
                            <div className="photo">
                              <img key={logo.id} className="add image" src={logo.url} alt={logo.url} />
                              <div className="btn-delete">
                                <ButtonIconCircle
                                  type="button"
                                  src={ICDelete}
                                  onClick={() => {
                                    setLogo(null);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </UploadImageStyle>
                      </Box>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Admin First Name"
                          id="firstName"
                          name="firstName"
                          placeholder="Your First Name"
                          optional={false}
                          type="text"
                          error={errors.lastName && touched.lastName ? errors.lastName : false}
                        />
                        <FieldInput
                          label="Admin Last Name"
                          id="lastName"
                          name="lastName"
                          placeholder="Your Last Name"
                          optional={false}
                          type="text"
                          error={errors.lastName && touched.lastName ? errors.lastName : false}
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Company Name"
                          id="companyName"
                          name="companyName"
                          placeholder="Company Name"
                          optional={false}
                          type="text"
                          disabled
                          error={errors.companyName && touched.companyName ? errors.companyName : false}
                        />
                        <FieldInput
                          label="Company Address"
                          id="companyAddress"
                          name="companyAddress"
                          placeholder="Full Address"
                          optional={false}
                          type="text"
                          error={errors.companyAddress && touched.companyAddress ? errors.companyAddress : false}
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Number of Employees"
                          id="numberOfEmployees"
                          name="numberOfEmployees"
                          optional={false}
                          type="number"
                          disabled
                          placeholder="Number of Employees"
                          error={errors.numberOfEmployees && touched.numberOfEmployees ? errors.numberOfEmployees : false}
                        />
                        <FieldInput
                          label="Registration Year"
                          id="companyRegistrationYear"
                          name="companyRegistrationYear"
                          placeholder="Company Registration Year"
                          optional={false}
                          type="number"
                          error={errors.companyRegistrationYear && touched.companyRegistrationYear ? errors.companyRegistrationYear : false}
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Company Primary Phone"
                          id="companyPhoneNumber"
                          name="companyPhoneNumber"
                          placeholder="Company Primary Phone"
                          optional={false}
                          type="number"
                          error={errors.companyPhoneNumber && touched.companyPhoneNumber ? errors.companyPhoneNumber : false}
                        />
                        <FieldInput
                          label="Company Secondary Phone"
                          id="secondNumber"
                          name="secondNumber"
                          placeholder="Company Secondary Phone"
                          type="number"
                          error={errors.secondNumber && touched.secondNumber ? errors.secondNumber : false}
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Point of Contact"
                          id="pointOfContact"
                          name="pointOfContact"
                          placeholder="Full Name"
                          optional={false}
                          type="text"
                          error={errors.pointOfContact && touched.pointOfContact ? errors.pointOfContact : false}
                        />
                        <FieldInput
                          label="Designation"
                          id="contactDesignation"
                          name="contactDesignation"
                          placeholder="Designation of Point of Contact"
                          optional={false}
                          type="text"
                          error={errors.contactDesignation && touched.contactDesignation ? errors.contactDesignation : false}
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Company Website"
                          id="companyWebsite"
                          name="companyWebsite"
                          placeholder="Link to Company Website"
                          type="text"
                        />
                        <Dropdown
                          label="How'd You Hear About Us"
                          mode={'dark'}
                          id="aboutUs"
                          name="aboutUs"
                          values={[
                            'Select',
                            'Search Engine (e.g., Google, Bing)',
                            'Social Media (e.g., Facebook, Twitter, Instagram)',
                            'Word of Mouth / Referral',
                            'Website / Blog',
                            'Other',
                          ]}
                          defaultValue={'Select'}
                        />
                      </GroupInput>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} marginTop={'3rem'}>
                      <Button type="submit" buttonColor={globalColors.mainBlue}>
                        {processing ? 'Loading...' : 'Next'}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </FormRegisterStyle>
          </Box>
        </>
      )}
    </Box>
  );
};

const FormRegisterStyle = styled('div')(({ theme }) => ({
  padding: '2rem',
  borderRadius: '20px',
  backgroundColor: 'transparent',
  marginBottom: '2rem',
  // marginTop: '2rem',
  border: `1px solid ${globalColors.gray}`,
  margin: '2rem',
  zIndex: 99999999,
  // TODO : style : fix component Link login
  // TODO : Responsive test
  // [theme.breakpoints.up('sm')]: {
  //   width: 250,
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: 350,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 450,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'pink',
  //   width: '1002px',
  // },
  fontSize: '14px',
  overflow: 'auto',
  '& input': {
    marginBottom: 0,
  },
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'cyan !important',
      flexDirection: 'column',
    },
  },
  '& .wrapper-btn-submit': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2.5rem',
    alignItems: 'center',
    '& .btn-submit': {
      marginBottom: '1rem',
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  '& button': {
    textTransform: 'none',
  },
}));

const UploadImageStyle = styled('div')(({ theme }) => ({
  '& .image': {
    borderRadius: '12px',
    width: '200px',
    minWidth: '200px',
    height: '200px',
    objectFit: 'contain',
  },
  '& .btn-add': {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    border: `1px solid ${globalColors.mainBlue}`,
    borderRadius: '12px',
    width: '200px',
    height: '200px',
    '& input': {
      cursor: 'pointer',
      position: 'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
    },
  },

  '& .photo': {
    position: 'relative',
    '& .btn-delete': {
      position: 'absolute',
      right: '8px',
      top: '8px',
      '& img': {
        width: '1rem',
        height: '1rem',
      },

      '&:hover': {
        opacity: '0.7',
      },
    },
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '100%',
      height: '150px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '.photo': {
      width: 'max-content',
    },
    gridTemplateColumns: 'repeat(2, 1fr)',
    '& .image': {
      width: '150px',
      height: '150px',
    },
    '& .btn-add': {
      width: '150px',
      height: '150px',
    },
  },
}));

export default AccountSetup;
