// @ts-nocheck
import * as React from 'react';
import { styled } from '@mui/system';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { selectColor, selectLeadsFilter, selectThemeMode } from '../../../store/selector';
import { FilterDataFtn } from '../../../store/slices';
import UserRepository from '../../../utils/api/repositories/userRepository';
import Dropdowndata from '../../atoms/dropdown2';
import { globalColors } from '../../../utils/constants/color';
import { ButtonFilter, FieldInput } from '../../atoms';
import Dropdown from '../../atoms/dropdown';
import SalesExecutiveRepository from '../../../utils/api/repositories/salesExecutiveRepository';
import { UserRole } from '../../../store/user';

interface FilterStyleProps {
  mode: string;
}
interface FilterValues {
  status: string;
  assignedTo: string;
  priority: string;
}
type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};
interface FilterLeadsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function FilterLeads({ setOpen, open }: FilterLeadsProps) {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [managersandSalesExecutive, setManagersandSalesExecutive] = React.useState<ManagerSalesExecutive[]>([]);
  const filterData = useSelector(selectLeadsFilter);
  const popupRef = React.useRef<HTMLDivElement>(null);
  const [statusList, setStatusList] = React.useState
  (['Select', 'New', 'Contacted', 'Qualified', 'Converted']);

  const handleClick = (event: any) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setAnchor(null);
        if (anchor === null) {
          setOpen(!open);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchor]);

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser?.get('role');
      if (userRole === UserRole.admin) {
        setStatusList(['Select', 'New', 'Contacted', 'Qualified', 'Converted', 'Lost']);
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i]?.get('firstName'),
              lastName: managersAndSalesExecutives[i]?.get('lastName'),
              user: managersAndSalesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i]?.get('user').id,
              firstName: salesExecutives[i]?.get('user')?.get('firstName'),
              lastName: salesExecutives[i]?.get('user')?.get('lastName'),
              user: salesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser?.get('firstName'),
          lastName: currentUser?.get('lastName'),
          user: currentUser,
        };
        setManagersandSalesExecutive([userData]);
      }
    }
  };

  const handleFilterValues = async (values: FilterValues) => {
    const { status, assignedTo, priority } = values;
    dispatch(FilterDataFtn({ status, assignedTo, priority }));
  };
  
  React.useEffect(() => {
    getManagersandSalesExecutive();
  }, []);

  return (
    <div>
      <Box
        // ref={setAnchor}
        onClick={() => setOpen(!open)}
        width={'100%'}
      >
        <ButtonFilter onClick={handleClick} />
      </Box>
      <BasePopup anchor={anchor} open={open} withTransition>
        {(props: any) => (
          <PopAnimation {...props}>
            <PopupBody mode={mode} ref={popupRef}>
              <Formik
                initialValues={{
                  assignedTo: filterData.assignedTo || '',
                  status: filterData.status || '',
                  priority: filterData.priority || '',
                }}
                validate={(values) => {}}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  await handleFilterValues(values);

                  resetForm({
                    values: {
                      assignedTo: '',
                      status: '',
                      priority: '',
                    },
                  });
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form>
                    <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
                      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={'2px'} width={'100%'} position={'relative'}>
                        <Dropdowndata
                          label="Assigned To"
                          id="assignedTo"
                          name="assignedTo"
                          showCheckBox={true}
                          checked={values?.assignedTo !== ''}
                          onChange={(e) => {
                            setFieldValue('assignedTo', e.target.value);
                          }}
                          mode={mode}
                          values={managersandSalesExecutive.map((user) => ({
                            label: `${user.firstName} ${user.lastName}`,
                            value: user.id,
                          }))}
                          defaultValue="Select"
                        />
                      </Box>
                      <Dropdown
                        label="Status"
                        mode={mode}
                        id="status"
                        defaultValue={values?.status || 'Select'}
                        showCheckBox={true}
                        checked={values?.status !== ''}
                        name="status"
                        values={statusList}
                      />

                      <Dropdown
                        label="Priority"
                        mode={mode}
                        id="priority"
                        defaultValue={values?.priority || 'Select'}
                        showCheckBox={true}
                        checked={values?.priority !== ''}
                        name="priority"
                        values={['Select', 'Very Hot', 'Hot', 'Moderate', 'Cold']}
                      />

                      <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
                        <Button
                          sx={{
                            border: `1px solid ${color || globalColors.blue}`,
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            width: '100%',
                            borderRadius: '12px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            '&:hover': {
                              backgroundColor: color || globalColors.blue,
                            },
                          }}
                          type="button"
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Loading...' : 'Apply'}
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </PopupBody>
          </PopAnimation>
        )}
      </BasePopup>
    </div>
  );
}

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div onAnimationEnd={handleAnimationEnd} className={className + (requestOpen ? ' open' : ' close')}>
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled('div')<FilterStyleProps>(
  ({ theme, mode }) =>
    `
    width: max-content;
    padding: 0.5rem 1rem;
    margin: 8px;
    // border: 1px solid ${mode === 'dark' ? '' : ''};
     background-color: ${mode === 'dark' ? globalColors.black : globalColors.lightgray};
    border-radius: 8px;
     box-shadow: ${mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);
