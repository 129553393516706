import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/molecules/layout';
import { CardProducts, ListProducts } from '../../components/organisms';
import { useProjects } from '../../hooks';
import { ProductCategories } from '../../utils/types/products';
import { useEffect, useState } from 'react';
import { selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { hasPermissionForAction } from '../../utils/helpers';
import { Box } from '@mui/material';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';
import Paginator from '../../components/atoms/paginator';
import CardInventory from '../../components/organisms/inventory/cardInventory';
import useInventory from '../../hooks/inventory/useInventory';
import ListInventory from '../../components/organisms/inventory/listInventory';

const Inventory = () => {
  const { view, inventory, isLoading, handleSwitchView, getMoreData, getTotalCount } = useInventory();
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const [rowCheckboxState, setRowCheckboxState] = useState<{ [key: string]: boolean }>(Object.fromEntries(inventory.map((inventory) => [inventory.objectId, false])));
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedRowCheckboxState = Object.fromEntries(inventory.map((inventory) => [inventory.objectId, checked]));
    setRowCheckboxState(updatedRowCheckboxState);
  };

  const viewList = {
    Cards: (
      <CardInventory
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        rowCheckboxState={rowCheckboxState}
        setRowCheckboxState={setRowCheckboxState}
        mode={mode}
        isLoading={isLoading}
        handleSwitchView={handleSwitchView}
        view={view}
        onClickNewProduct={() => {}}
        category={ProductCategories.Project}
        datas={inventory}
        getTotalCount={getTotalCount}
        onChangePage={async (page) => {
          await getMoreData(page);
          return true;
        }}
      />
    ),
    List: (
      <ListInventory
        handleHeaderCheckboxChange={handleHeaderCheckboxChange}
        rowCheckboxState={rowCheckboxState}
        setRowCheckboxState={setRowCheckboxState}
        mode={mode}
        isLoading={isLoading}
        handleSwitchView={handleSwitchView}
        view={view}
        onClickNewProduct={() => {}}
        category={ProductCategories.Project}
        datas={inventory}
        getTotalCount={getTotalCount}
        onChangePage={async (page) => {
          await getMoreData(page);
          return true;
        }}
      />
    ),
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Projects', 'View'));
    setLoading(false);
  }, []);

  return (
    <MainLayout titlePage="Inventory" mode={mode} navigation={navigation}>
      {loading ? (
        <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <>{viewList[view]}</>
      ) : (
        <NoPermission />
      )}
    </MainLayout>
  );
};

export default Inventory;
