import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/molecules/layout';
import CardProductItem from '../../components/molecules/products/cardProductItem';
import { ProductDetail } from '../../components/organisms';
import FormProperty from '../../components/organisms/product/formProperty';
import useFormAddProperty from '../../hooks/property/useFormProperty';
import { ProductCategories } from '../../utils/types/products';
import { selectNavigationLayout, selectThemeMode } from '../../store/selector';
import AddPropertyCard from '../../components/molecules/products/addPropertyCard';
import CardPropertyItem from '../../components/molecules/products/cardPropertyItem';
import { useEffect, useState } from 'react';
import { hasPermissionForAction } from '../../utils/helpers';
import { Box } from '@mui/system';
import Loader from '../../components/atoms/loader';
import NoPermission from '../../components/atoms/noPermission';

const CreateProperty = () => {
  const { formProperty } = useFormAddProperty();
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(true);

  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Properties', 'Create'));
    setLoading(false);
  }, []);

  return (
    <MainLayout titlePage="Properties / New" mode={mode} navigation={navigation}>
      {loading ? (
        <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'} marginTop={'2rem'}>
          <Loader />
        </Box>
      ) : hasPermission ? (
        <ProductDetail
          form={<FormProperty type="add" mode={mode} />}
          mode={mode}
          preview={<CardPropertyItem category={ProductCategories.Property} mode={mode} data={formProperty} active={false} />}
        />
      ) : (
        <NoPermission />
      )}
    </MainLayout>
  );
};

export default CreateProperty;
