import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, TableRow, TableCell, styled } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../../store/selector';
import { globalColors } from '../../../../utils/constants/color';

interface ListProductItemProps {
  data: any;
  key: string;
}

const ContentRow = (props: ListProductItemProps) => {
  // const dispatch = useDispatch();
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const style = {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '12px',
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };
  return (
    <>
      <TableRow
        sx={{
          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,

          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '10px',
          gap: '20px',
          marginBottom: '20px',
          paddingBottom: '20px',
          borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
        }}
        key={props.data.id}
      >
        {/* date */}
        <TableCell sx={style} component="th" scope="row">
          {props.data.date}
        </TableCell>

        {/* invoiceNumber */}
        <TableCell sx={style}>{props.data.invoiceNumber === '' ? '-' : props.data.invoiceNumber}</TableCell>

        {/* propertyProject */}
        <TableCell sx={style}>{props.data.propertyProject === '' ? '-' : props.data.propertyProject}</TableCell>

        {/* amount */}
        <TableCell sx={style}>{props.data.amount === '' ? '-' : props.data.amount}</TableCell>

        {/* status */}
        <TableCell style={{ color: props.data.status === 'Received' ? 'rgba(0, 203, 69, 1)' : 'rgba(219, 0, 0, 1)' }} sx={style}>
          {props.data.status === '' ? '-' : props.data.status}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContentRow;
