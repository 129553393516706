import { Box, Typography } from '@mui/material';
import { BsArrowRight, BsBuilding, BsHouse } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../../../utils/constants/color';
import { selectColor, selectThemeMode } from '../../../../../store/selector';
import { useStyles } from '../../../../organisms/dashboard/style';
import { PiUsersThreeLight } from 'react-icons/pi';
import OrganizationRepository from '../../../../../utils/api/repositories/organizationRepository';
import Loader from '../../../../atoms/loader';
import ProjectRepository from '../../../../../utils/api/repositories/projectRepository';
import PropertyRepository from '../../../../../utils/api/repositories/propertyRepository';
import LeadRepository from '../../../../../utils/api/repositories/leadRepository';
import { useParams } from 'react-router-dom';

interface InfoBoxProps {
  title: string;
  value: number;
  icon: React.ReactElement;
  mode: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, value, icon, mode }) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoBox} bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}>
      <Box className={classes.infoBoxContent}>
        {icon}
        <Typography className={classes.infoBoxTitle}>{title}</Typography>
      </Box>
      <Typography
        className={classes.infoBoxValue}
        paddingLeft={'10px'}
        marginTop={'auto'}
        color={mode === 'dark' ? globalColors.white : globalColors.black}
      >
        {value}
      </Typography>
    </Box>
  );
};

const EmployeeCounts = () => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const classes = useStyles();
  const [projectsCount, setProjectsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { id: userId } = useParams();

  useEffect(() => {
    const fetchDataCounts = async () => {
      const objectId = userId ? userId : '';
      if (objectId !== '') {
        const projectRepository = new ProjectRepository();
        const projectCount = await projectRepository.getSalesCountForUser(objectId);
        if (projectCount) {
          setProjectsCount(projectCount);
        }
        const propertyRepository = new PropertyRepository();
        const propertyCount = await propertyRepository.getSalesCountForUser(objectId);
        if (propertyCount) {
          setPropertiesCount(propertyCount);
        }
        const leadRepository = new LeadRepository();
        const leadCount = await leadRepository.countByUserId(objectId);
        if (leadCount) {
          setLeadsCount(leadCount);
        }
        setLoading(false);
      }
    };

    fetchDataCounts();
  }, []);

  return (
    <Box
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      className={classes.card}
      paddingTop={'20px'}
      paddingBottom={'20px'}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <InfoBox
            mode={mode}
            title="Projects Sold"
            value={projectsCount}
            icon={<BsBuilding style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />}
          />
          <InfoBox
            mode={mode}
            title="Properties Sold"
            value={propertiesCount}
            icon={<BsHouse style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />}
          />
          <InfoBox
            mode={mode}
            title="Leads Assigned"
            value={leadsCount}
            icon={
              <PiUsersThreeLight style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
            }
          />
          <InfoBox
            mode={mode}
            title="Leads Converted"
            value={0}
            icon={
              <PiUsersThreeLight style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
            }
          />
        </>
      )}
    </Box>
  );
};

export default EmployeeCounts;
