import React, { useEffect, useState } from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../store/selector';
import { styled } from '@mui/styles';
import LeadDropdown from '../../components/atoms/leadsDropdown';
import LeadInput from '../../components/atoms/leadInput';
import { Button, Toast } from '../../components/atoms';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import { MdDelete } from 'react-icons/md';

const values: string[] = ['SELECT', 'ASSIGNED LEADS', 'LEADS CONVERTED'];
const weightValues: string[] = ['SELECT', 'GREATER THAN', 'LESSER THAN', 'EQUAL TO'];

const initialMetrics: string[] = [
  'SELECT',
  'Active Leads',
  'Conversions',
  'Sales Value',
  'Number of Hot Leads',
  'Number of Very Hot Leads',
  'Members with Lesser Leads have Higher Priority',
];

interface MetricRow {
  metric: string;
  weightage: number;
}

const LeadSettingTrigger = ({}) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [rows, setRows] = useState<MetricRow[]>([{ metric: '', weightage: 0 }]);
  const [remainingMetrics, setRemainingMetrics] = useState<string[]>(initialMetrics);
  

  const handleAddMetric = () => {
    setRows((prevRows) => [...prevRows, { metric: '', weightage: 0 }]);
  };

  useEffect(() => {
    setRemainingMetrics(initialMetrics.filter((m) => !rows.some((r) => r.metric == m)));
  }, [rows]);

  useEffect(() => {
    const fetchTrigger = async () => {
      const organizationRepository = new OrganizationRepository();
      const savedTriggers: any = await organizationRepository.getLeadTriggers();
      if (savedTriggers && savedTriggers.length > 0) {
        setRows(savedTriggers);
      }
    };

    fetchTrigger();
  }, []);

  const handleSaveTrigger = async () => {
    let weightages = 0;
    for (let i = 0; i < rows.length; i++) {
      weightages += rows[i].weightage;
    }
    console.log(weightages, "WEIGHTAGES");
    if (weightages !== 100) {
      Toast('The sum of all weightages added must be equal to 100.', 'error');
      return;
    }
    const organizationRepository = new OrganizationRepository();
    const savedTriggers = await organizationRepository.addLeadTriggers(rows);
    if (savedTriggers) {
      Toast('Trigger preferences saved successfully.', 'success');
    } else {
      Toast('Trigger preferences could not be saved successfully. Please try again.', 'success');
    }
  };

  return (
    <Box width={'100%'} paddingX={3} paddingY={1}>
      <Box marginBottom={'10px'}></Box>
      <AssignmentContainer
        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
        padding={'20px'}
        borderRadius={'16px'}
        color={globalColors.white}
        fontSize={'12px'}
        boxShadow={'#171717'}
        marginTop={'1rem'}
      >
        {' '}
        {/* Lead Assignment */}
        <Box display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
          <Typography
            component="div"
            fontFamily={'Poppins'}
            fontSize={'20px'}
            fontWeight={'600'}
            lineHeight={'32px'}
            color={mode === 'dark' ? globalColors?.white : globalColors.black}
          >
            <span style={{ textDecoration: 'none', color: color || globalColors.blue }}>Cloud AI</span> Trigger Preferences
          </Typography>
        </Box>
        {/* Upload Your data */}
        <Box sx={{ marginTop: '10px' }} display={'flex'} gap={'3rem'} flexWrap={'wrap'}>
          <Typography fontSize={'12px'} color={globalColors.gray}>
            Select the metrics you want Cloud AI to take into account, and assign weightages to each metric. The sum of all weightages added must be
            equal to 100
          </Typography>
        </Box>
        {/* Assign */}
        <Box
          sx={{
            marginTop: '50px',
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-around',
            alignItems: { xs: 'center', md: 'end' },
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'start'}>
            {rows.map((row, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'center', md: 'start' },
                  gap: '2rem',
                }}
              >
                <Box display={'flex'} flexDirection={'column'} gap="10px" alignItems={'center'}>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                    <Typography fontSize={'16px'}>Metric</Typography>
                    {index !== 0 && (
                      <MdDelete
                        size={'1.5rem'}
                        color={globalColors.red}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let newRows = [...rows];
                          newRows.splice(index, 1);
                          setRows(newRows);
                        }}
                      />
                    )}
                  </Box>
                  <LeadDropdown
                    mode={mode}
                    values={remainingMetrics}
                    initialValue={row.metric}
                    onChange={(text) => {
                      const newRows = [...rows];
                      newRows[index].metric = text;
                      setRows(newRows);
                    }}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap="10px" alignItems={'center'}>
                  <Typography fontSize={'16px'}>Weightage</Typography>
                  <LeadInput
                    className="col"
                    placeholder="Weightage"
                    type="number"
                    name="weightage"
                    id="weightage"
                    onChange={(e: any) => {
                      const newRows = [...rows];
                      newRows[index].weightage = parseInt(e.target.value);
                      setRows(newRows);
                    }}
                    value={row.weightage.toString()}
                    width={'176px'}
                    min={0}
                    max={100}
                  />
                </Box>
              </Box>
            ))}
          </Box>
          <Box paddingTop={{ xs: '2rem', md: 0 }}>
            <Button disabled={!rows.every((row) => row.weightage && row.metric && row.metric !== 'SELECT')} className="btn-add" onClick={handleAddMetric}>
              Add Metric
            </Button>
          </Box>
        </Box>
        <Box paddingTop={'4rem'} width={'100%'} display={'flex'} flexDirection={{xs: 'column', md: 'row'}} justifyContent={'center'} alignItems={'center'} gap={'10px'}>
          <Button buttonColor={globalColors.red} className="btn-add" onClick={handleSaveTrigger}>
            Remove All
          </Button>
          <Button disabled={!rows.every((row) => row.weightage && row.metric && row.metric !== 'SELECT')} className="btn-add" onClick={handleSaveTrigger}>
            Save
          </Button>
        </Box>
      </AssignmentContainer>
    </Box>
  );
};

const AssignmentContainer = styled(Box)({
  // height: '272px',
  width: '100%',
});

export default LeadSettingTrigger;
