import { Button, styled } from '@mui/material';
import ICPerson from '../../../assets/Icons/ic-person.svg';
import { FieldInput, Toast } from '../../atoms';
import { HeadingFormAuth } from '../../molecules';
import { Form, Formik, FormikHelpers } from 'formik';
import { globalColors } from '../../../utils/constants/color';
import ResetPasswordRequestRepository from '../../../utils/api/repositories/resetPasswordRequestRepository';

interface FormForgotPasswordProps {
  className?: string;
  onConfirm: () => void;
  onClose: () => void;
}

interface ValuesFormForgotPassword {
  email: string;
}

const FormForgotPassword = ({ className, onConfirm, onClose }: FormForgotPasswordProps) => {
  return (
    <FormForgotPasswordStyle className={className}>
      <div className="wrapper-heading">
        <HeadingFormAuth
          title="Forgot your password?"
          subTitle="Please enter the email address that is attached to your account to get the password reset link."
          onGoBack={onClose}
        />
        <Formik
          initialValues={{
            email: '',
          }}
          validate={(values) => {
            const errors = {} as ValuesFormForgotPassword;
            if (values.email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]$/i.test(values.email) == false) {
              errors.email = 'Please enter a valid email.';
            }

            return errors;
          }}
          onSubmit={async (values: ValuesFormForgotPassword, { setSubmitting, resetForm }: FormikHelpers<ValuesFormForgotPassword>) => {
            try {
              const resetPasswordRequestRepository = new ResetPasswordRequestRepository();
              const initiatedRequestResponse = await resetPasswordRequestRepository.initiate(values.email);
              if (initiatedRequestResponse) {
                Toast(initiatedRequestResponse.message, 'success');
                resetForm();
                onClose();
              } else {
                Toast('Reset password request could not be generated successfully. Please try again.', 'error');
              }
            } catch (e) {
              Toast('Reset password request could not be generated successfully. Please try again.', 'error');
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form>
              <FieldInput
                label="Email"
                id="email"
                name="email"
                placeholder="Your email address"
                type="string"
                icon={ICPerson}
                iconPosition="left"
                mode={'dark'}
                error={errors.email && touched.email ? errors.email : false}
              />
              <div className="wrapper-btn">
                <Button variant="contained" className="btn-send" type="submit">
                  {isSubmitting ? 'Loading...' : 'Proceed'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </FormForgotPasswordStyle>
  );
};

const FormForgotPasswordStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '& .wrapper-heading': {
    height: '100%',
  },

  '& .wrapper-btn': {
    display: 'grid',
    placeItems: 'center',
    marginBottom: '60px',
    '& .btn-send': {
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '85%',
      },
    },
  },

  '& button': {
    backgroundColor: globalColors.blue,
    textTransform: 'none',
    position: 'absolute',
    bottom: '30px',
  },
}));

export default FormForgotPassword;
