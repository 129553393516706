import { Box, Checkbox, Typography, styled } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { selectColor } from '../../store/selector';
import { useState } from 'react';

interface DropDownInputProps {
  name: string;
  id: string;
  mode: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  optional?: true | false;
  disabled?: true | false;
  showCheckBox?: true | false;
  checked?: true | false;
  values?: { label: string; value: string }[]; // Updated type definition
  defaultValue?: string;
}
const Dropdowndata = <T, _>({
  label,
  checked,
  showCheckBox = false,
  value,
  name,
  mode,
  disabled = false,
  id,
  optional = true,
  values,
  defaultValue,
  onChange
}: DropDownInputProps) => {
  const color = useSelector(selectColor);
  const [checkBox, setCheckedBox] = useState(!showCheckBox ? true : checked || false);

  return (
    <DropdownInputStyle>
      <Box display="flex" justifyContent={optional ? 'space-between' : ''} gap="10px" mb={optional ? '1rem' : '0.8rem'} fontSize="1rem">
        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{label}</Typography>
        {!optional && (
          <Typography variant="subtitle1" sx={{ color: 'red' }}>
            *
          </Typography>
        )}
        {showCheckBox && (
          <Checkbox
            size="small"
            sx={{
              color: color || globalColors.blue,
              '&.Mui-checked': {
                color: color || globalColors.secondBlue,
              },
              marginTop: '-7px',
            }}
            onChange={() => setCheckedBox((prev: boolean) => !prev)}
            checked={checkBox || false}
          />
        )}
      </Box>
      {checkBox && (
        <div className={mode === 'dark' ? 'group-input' : 'group-input-light'}>
          <Field onChange={onChange} as="select" value={value} className="input" name={name} id={id} disabled={disabled}>
            {values?.map((value, idx) => (
              <option key={idx} selected={value?.value === defaultValue } value={value?.value} disabled={value.value == ''}>
                {value?.label}
              </option>
            ))}
          </Field>
        </div>
      )}
    </DropdownInputStyle>
  );
};

const DropdownInputStyle = styled('div')({
  width: '100%',
  color: 'white',

  '& .group-input': {
    padding: '0 1rem',
    backgroundColor: globalColors.black,
    border: '2px solid #303030',
    height: '48px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    fontSize: '13px',

    '& .input': {
      color: globalColors.text.secondary,
      backgroundColor: globalColors.black,

      border: 'none',
      flex: 1,
      width: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  '& .group-input-light': {
    padding: '0 1rem',
    backgroundColor: globalColors.white,
    border: `1px solid #303030`,
    height: '48px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',

    '& .input': {
      color: globalColors.text.secondary,
      backgroundColor: globalColors.white,

      border: 'none',
      flex: 1,
      width: '100%',
      '&:focus': {
        outline: 'none',
      },
    },
  },
});
export default Dropdowndata;
