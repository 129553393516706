import React, { FC, SVGProps } from 'react';

interface DoneIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
}

const DoneIcon: FC<DoneIconProps> = ({ width, height, stroke, color, ...rest }) => {
  return (
    <svg width={width ? width : '176'} height={height ? height : '176'} viewBox="0 0 176 176" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="1.5" width="173" height="173" rx="86.5" stroke={color} stroke-width="3" />
      <path d="M70.0334 132L28.2334 90.1997L38.6834 79.7497L70.0334 111.1L137.317 43.8164L147.767 54.2664L70.0334 132Z" fill={color} />
    </svg>
  );
};

export default DoneIcon;
