import React, { FC, SVGProps } from 'react';

interface FileIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
  color?: string;
}

const FileIcon: FC<FileIconProps> = ({ width, height, stroke, color, ...rest }) => {
  return (
    <svg width={width ? width : '24'} height={height ? height : '24'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '4%'}} >
      <path
        d="M13.3327 5.96065C13.3257 5.89941 13.3123 5.83907 13.2927 5.78065V5.72065C13.2606 5.6521 13.2179 5.58909 13.166 5.53398L9.16602 1.53398C9.11091 1.48213 9.0479 1.43937 8.97935 1.40732H8.91935L8.70602 1.33398H4.66602C4.13558 1.33398 3.62687 1.5447 3.2518 1.91977C2.87673 2.29484 2.66602 2.80355 2.66602 3.33398V12.6673C2.66602 13.1978 2.87673 13.7065 3.2518 14.0815C3.62687 14.4566 4.13558 14.6673 4.66602 14.6673H11.3327C11.8631 14.6673 12.3718 14.4566 12.7469 14.0815C13.122 13.7065 13.3327 13.1978 13.3327 12.6673V5.96065ZM9.33268 3.60732L11.0593 5.33398H9.33268V3.60732ZM11.9993 12.6673C11.9993 12.8441 11.9291 13.0137 11.8041 13.1387C11.6791 13.2637 11.5095 13.334 11.3327 13.334H4.66602C4.4892 13.334 4.31964 13.2637 4.19461 13.1387C4.06959 13.0137 3.99935 12.8441 3.99935 12.6673V3.33398C3.99935 3.15717 4.06959 2.9876 4.19461 2.86258C4.31964 2.73756 4.4892 2.66732 4.66602 2.66732H7.99935V6.00065C7.99935 6.17746 8.06959 6.34703 8.19461 6.47206C8.31964 6.59708 8.4892 6.66732 8.66602 6.66732H11.9993V12.6673Z"
        fill="#939393"
      />
    </svg>
  );
};

export default FileIcon;
