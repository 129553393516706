import { Box, Grid, styled } from '@mui/material';
import { Field } from 'formik';
import { Button } from '../../atoms';
import { globalColors } from '../../../utils/constants/color';
import { IAmenities, changeValueAmenities } from '../../../store/property';
import ModalAddAmenities from '../../organisms/product/modalAddAmenities';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../store/selector';

interface FieldAminitiesProps {
  className?: string;
  amenities: IAmenities[];
}
interface FieldAmitiesStyle {
  mode: string;
}
const FieldAminities = (props: FieldAminitiesProps) => {
  const dispatch = useDispatch();
  const [openModalAddAmenities, setOpenModalAddAmenities] = useState(false);
  const handleOpenModalAddAmenities = () => setOpenModalAddAmenities(true);
  const handleCloseModalAddAmenities = () => setOpenModalAddAmenities(false);

  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const handleChangeValueAmenities = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const field = name as keyof IAmenities;
    dispatch(
      changeValueAmenities({
        field,
        value,
      })
    );
  };
  return (
    <FieldAminitiesStyle mode={mode} className={props.className}>
      {/* Amenties Container */}
      <Box display={'flex'} flex={1}>
        <Grid container>
          {props.amenities.map((item, index) => (
            <Grid item xs={12} lg={6} display={'flex'} alignItems={'center'} flexDirection={'column'} justifyContent={'center'}>
              <Box padding={'0.8rem'} width={'100%'}>
                <p className="title-aminities">{item.label}</p>
                <Field type="number" name={item.label} placeholder={0} value={item.value} onChange={handleChangeValueAmenities} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Button */}
      <Box width={'100%'} display={'flex'} justifyContent={'end'}>
        <Button type="button" onClick={handleOpenModalAddAmenities}>
          Add Amenity
        </Button>
      </Box>
      <ModalAddAmenities mode={mode} color={color} open={openModalAddAmenities} onClose={handleCloseModalAddAmenities} />
    </FieldAminitiesStyle>
  );
};

const FieldAminitiesStyle = styled('div')<FieldAmitiesStyle>(({ theme, mode }) => ({
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '1rem',
  paddingTop: '2rem',
  paddingBottom: '2rem',
  flex: 1,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  '& .title-aminities': {
    margin: '0 0px 8px 0 !important',
    textAlign: 'center',
    fontSize: '13px',
    color: globalColors.text.secondary,
    fontWeight: 600,
  },
  '& input': {
    all: 'unset',
    width: '100%',
    height: '40px',
    background: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
    borderRadius: '12px',
    color: mode === 'dark' ? 'white' : globalColors.black,
    textAlign: 'center',
    margin: '0px 5px 0px 5px',
  },
  '& .item-wrapper': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      gridTemplateColumns: '1fr',
    },

    '& .item': {
      flex: 1,
      minWidth: '160px',
      '& .title-aminities': {
        margin: '0 0px 8px 0 !important',
        textAlign: 'center',
        fontSize: '13px',
        color: globalColors.text.secondary,
        fontWeight: 600,
      },

      '& input': {
        all: 'unset',
        width: '100%',
        height: '60px',
        background: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
        borderRadius: '12px',
        color: mode === 'dark' ? 'white' : globalColors.black,
        textAlign: 'center',
      },
    },
  },

  '& .item-flex-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    flexWrap: 'wrap',
    alignItems: 'center',
    flex: 1,

    '& .item': {
      flex: 1,
      maxWidth: '20%',
      '& .title-aminities': {
        margin: '0 0px 8px 0 !important',
        textAlign: 'center',
        fontSize: '13px',
        color: globalColors.text.secondary,
        fontWeight: 600,
      },

      '& input': {
        all: 'unset',
        width: '100%',
        height: '40px',
        background: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
        borderRadius: '12px',
        color: mode === 'dark' ? 'white' : globalColors.black,
        textAlign: 'center',
      },
    },
  },

  '& .btn-add': {
    '& p': { visibility: 'hidden', margin: 0 },
    [theme.breakpoints.down('sm')]: {
      '& p': { display: 'none' },
    },
    '& button': {
      // minWidth: '160px',
      padding: '0px',
      width: '100%',
      height: '40px',
      textAlign: 'center',
      fontSize: '12px',
    },
  },
}));

export default FieldAminities;
