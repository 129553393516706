import { Typography, styled } from '@mui/material';
import React from 'react';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';
interface LeadInputProps {
  id: string;
  name: string;
  label?: string;
  onChange?: any;
  placeholder?: string;
  value?: string;
  className?: string;
  type?: string;
  error?: string;
  width?: any
  min?: any;
  max?: any;
}

const LeadInput = ({ placeholder, onChange, value, name, id, className, type, error, width, min, max }: LeadInputProps) => {
  const mode = useSelector(selectThemeMode);

  return (
    <LeadInputStyle className={className} mode={mode} width={width}>
      <div className="group-input">
        <input id={id} name={name} onChange={onChange} placeholder={placeholder} value={value} className="input" type={type} min={min} max={max}/>
      </div>
      {error && (
        <Typography variant="caption" className="error">
          {error}
        </Typography>
      )}
    </LeadInputStyle>
  );
};

const LeadInputStyle = styled('div')<any>((props) => ({
  width: props.width ? props.width : '100%',
  color: props.mode === 'dark' ? 'white' : 'black',

  '& .group-input': {
    padding: '0 1rem',
    backgroundColor: props.mode === 'dark' ? globalColors.black : globalColors.white,
    // border: props.mode === 'dark' ? '2px solid #303030' : '1px solid #303030',
    height: '48px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    '& .input': {
      color: props.mode === 'dark' ? globalColors.text.main : globalColors.black,
      backgroundColor: 'transparent',
      height: '13px',
      border: 'none',
      flex: 1,
      width: props.width ? props.width : '100%',
      '&:focus': {
        outline: 'none',
      },
    },

    '& .unit': {
      color: props.mode === 'dark' ? globalColors.text.main : globalColors.black,
      fontSize: '13px',

      '&.active': {
        color: props.mode === 'dark' ? globalColors.text.main : globalColors.black,
      },
    },
  },

  '.error': {
    color: 'red',
  },
}));

export default LeadInput;