import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from '../../atoms/loader';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import { globalColors } from '../../../utils/constants/color';
import moment from 'moment';
import { FieldInput, Toast } from '../../atoms';
import { Form, Formik, FormikHelpers } from 'formik';
import NoteModel from '../../../utils/api/models/noteModel';
import LeadModel from '../../../utils/api/models/leadModel';
import { BsCameraVideo, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import Paginator from '../../atoms/paginator';
import { MdPeopleOutline } from 'react-icons/md';

interface ViewAllCommentsProp {
  leadId: string;
  mode: string;
  color: string;
  changeStatus?: () => Promise<void>;
}
interface ValuesNote {
  note: string;
  date: string;
  objectId: string;
}

const ViewAllComments = ({ leadId, mode, color, changeStatus }: ViewAllCommentsProp) => {
  const today = new Date().toISOString().split('T')[0];

  const [loading, setloading] = useState<boolean>(true);
  const [noteElements, setNoteElements] = useState<JSX.Element[] | JSX.Element>();
  const [lead, setLead] = useState<LeadModel | null>(null);
  const [selectedMethodContact, setSelectedMethodContact] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const getTotalCount = async () => {
    try {
      const repository = new LeadRepository();

      const totalCount = await repository.getNotesCount(leadId);
      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  const getAllNotes = async () => {
    try {
      setloading(true);
      const repository = new LeadRepository();

      const notes = await repository.getNotes(leadId);

      const notesOutput: JSX.Element | JSX.Element[] =
        notes.length > 0 ? (
          notes?.reverse()?.map((note, index) => {
            const body = note.get('body');
            const createdBy = note.get('createdBy');
            const createdAt = note.get('createdAt');

            return (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'0'}
                key={index}
                padding={'10px 0px'}
                borderBottom={index < notes.length - 1 ? `1px solid ${globalColors.border.gray}` : 'none'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontSize={'16px'} marginRight={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                    {body}
                  </Typography>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    fontSize={'10px'}
                    gap={'5px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    <Typography fontSize={'12px'}>{createdBy.get('firstName')}</Typography>
                    <Typography fontSize={'12px'}>{createdBy.get('lastName')}</Typography>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'} marginTop={'-4px'}>
                  <Typography color={globalColors.gray} fontSize={'12px'}>
                    {moment(createdAt)?.fromNow()}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{ alignItems: 'center', color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center', margin: '20px' }}
          >
            No Follow up Date and comments have been added yet.
          </Typography>
        );
      setNoteElements(notesOutput);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setloading(false);
    }
  };

  const getMoreNotes = async (page: number) => {
    try {
      setloading(true);
      const repository = new LeadRepository();

      const notes = await repository.getNotes(leadId, page);

      const notesOutput: JSX.Element | JSX.Element[] =
        notes.length > 0 ? (
          notes?.reverse()?.map((note, index) => {
            const body = note.get('body');
            const createdBy = note.get('createdBy');
            const createdAt = note.get('createdAt');

            return (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'0'}
                key={index}
                padding={'10px 0px'}
                borderBottom={index < notes.length - 1 ? `1px solid ${globalColors.border.gray}` : 'none'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontSize={'16px'} marginRight={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                    {body}
                  </Typography>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    fontSize={'10px'}
                    gap={'5px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    <Typography fontSize={'12px'}>{createdBy.get('firstName')}</Typography>
                    <Typography fontSize={'12px'}>{createdBy.get('lastName')}</Typography>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'} marginTop={'-4px'}>
                  <Typography color={globalColors.gray} fontSize={'12px'}>
                    {moment(createdAt)?.fromNow()}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{ alignItems: 'center', color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center', margin: '20px' }}
          >
            No Follow up Date and comments have been added yet.
          </Typography>
        );
      setNoteElements(notesOutput);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setloading(false);
    }
  };

  const getFollowUpDate = async () => {
    const repository = new LeadRepository();
    if (leadId) {
      await repository
        .getModelById(leadId)
        .then((res) => {
          if (res) {
            setLead(res);
            setloading(false);
          }
        })
        .catch((err) => {
          setLead(null);
          setloading(false);
        });
    }
  };

  useEffect(() => {
    getAllNotes();
    getFollowUpDate();
  }, [leadId]);

  return (
    <Box marginTop={'30px'} sx={{ overflow: 'auto' }} display={'flex'} flexDirection={'column'} gap={'10px'} className="scroll">
      {lead && (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography
            sx={{
              color: globalColors.lightgray,
              textAlign: 'center',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Upcoming Follow Up
          </Typography>
          <Typography
            sx={{
              color: mode === 'dark' ? color || globalColors.white : color || globalColors.black,
              textAlign: 'center',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {lead.followUpDate ?? 'None'}
          </Typography>
        </Box>
      )}
      <Divider sx={{ backgroundColor: globalColors.border.gray }} />
      {!loading && (
        <Formik
          initialValues={{
            note: '',
            objectId: leadId || '',
            date: today,
          }}
          validate={(values) => {
            const errors = {} as ValuesNote;
            if (values.date === '' || values.note.trim() === '' || selectedMethodContact === '') {
              errors.note = 'Please provide all three: next follow up date, a comment and a method of contact';
            }
            return errors;
          }}
          onSubmit={async (values: ValuesNote, { setSubmitting, resetForm }: FormikHelpers<ValuesNote>) => {
            if (values.note.trim() === '') {
              setSubmitting(false);
            } else {
              const note: NoteModel = { body: values.note, leadId: values?.objectId };
              const leadRepository = new LeadRepository();
              const savedLead = await leadRepository.addFollowUp(values?.objectId, values.date, selectedMethodContact, note);
              if (savedLead) {
                getAllNotes();
                getFollowUpDate();
                if (changeStatus) {
                  await changeStatus();
                }
              } else {
                Toast('Follow up could not be added successfully. Please try again.', 'error');
              }
              resetForm({
                values: {
                  note: '',
                  objectId: leadId || '',
                  date: lead?.followUpDate || '',
                },
              });
              setSelectedMethodContact('');
              setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <>
              <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <FieldInput mode={mode} id="note" name="note" placeholder="Type your note here" type="text" label="Comments" />
                {values?.note !== '' && (
                  <>
                    <FieldInput
                      min={today}
                      value={lead?.followUpDate}
                      mode={mode}
                      id="date"
                      name="date"
                      placeholder="Add a follow-up date"
                      type="date"
                      label="Next Follow Up"
                    />
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Box display={'flex'} flexDirection={'column'}>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Method of Contact</Typography>
                        <Box display={'flex'} flexDirection={'row'} gap={'5px'} marginTop={'1rem'}>
                          <Box
                            onClick={() => {
                              if (selectedMethodContact === 'Call') {
                                setSelectedMethodContact('');
                              } else {
                                setSelectedMethodContact('Call');
                              }
                            }}
                            sx={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 50,
                              backgroundColor: selectedMethodContact === 'Call' ? color : 'transparent',
                            }}
                          >
                            <BsTelephone size={'1rem'} color={selectedMethodContact === 'Call' ? globalColors.white : color || globalColors.blue} />
                          </Box>
                          <Box
                            onClick={() => {
                              if (selectedMethodContact === 'Email') {
                                setSelectedMethodContact('');
                              } else {
                                setSelectedMethodContact('Email');
                              }
                            }}
                            sx={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 50,
                              backgroundColor: selectedMethodContact === 'Email' ? color : 'transparent',
                            }}
                          >
                            <BiEnvelope size={'1rem'} color={selectedMethodContact === 'Email' ? globalColors.white : color || globalColors.blue} />
                          </Box>
                          <Box
                            onClick={() => {
                              if (selectedMethodContact === 'Meeting') {
                                setSelectedMethodContact('');
                              } else {
                                setSelectedMethodContact('Meeting');
                              }
                            }}
                            sx={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 50,
                              backgroundColor: selectedMethodContact === 'Meeting' ? color : 'transparent',
                            }}
                          >
                            <BsCameraVideo
                              size={'1rem'}
                              color={selectedMethodContact === 'Meeting' ? globalColors.white : color || globalColors.blue}
                            />
                          </Box>
                          <Box
                            onClick={() => {
                              if (selectedMethodContact === 'Physical Meeting') {
                                setSelectedMethodContact('');
                              } else {
                                setSelectedMethodContact('Physical Meeting');
                              }
                            }}
                            sx={{
                              cursor: 'pointer',
                              width: '30px',
                              height: '30px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: 50,
                              backgroundColor: selectedMethodContact === 'Physical Meeting' ? color : 'transparent',
                            }}
                          >
                            <MdPeopleOutline
                              size={'1rem'}
                              color={selectedMethodContact === 'Physical Meeting' ? globalColors.white : color || globalColors.blue}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Button type="submit" sx={{ color: color || globalColors.blue, marginTop: '10px' }}>
                        Add
                      </Button>
                    </Box>
                    {touched.note && errors.note && (
                      <Typography color={'red'} fontSize={'12px'} marginTop={'2px'}>
                        {errors.note}
                      </Typography>
                    )}
                  </>
                )}
              </Form>
            </>
          )}
        </Formik>
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          {noteElements}
          <Paginator
            getTotalCount={getTotalCount}
            onChangePage={async (newPage) => {
              setCurrentPage(newPage);
              await getMoreNotes(newPage);
              return true;
            }}
            internal
            activePage={currentPage}
          />
        </>
      )}
    </Box>
  );
};

export default ViewAllComments;
