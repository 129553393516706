import { Box, Checkbox, Grid, Typography } from '@mui/material';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectBulkEdit, selectColor, selectLeadState, selectLeadsFilter, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CompanyCard from './companyCards';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import { formatMoney, hasPermissionForAction } from '../../../../utils/helpers';
import ConversationsCard from './conversionsCardView';
import NotFoundLottie from '../../../atoms/notFound';
import LeadModel from '../../../../utils/api/models/leadModel';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { Button, SearchInput } from '../../../atoms';
import FilterLeads from '../../../organisms/leads/filterLeads';
import ButtonView from '../../buttonView';
import { EnumViews } from '../../../../store/projects';
import { useDispatch } from 'react-redux';
import { FilterDataFtn, onSwitchView } from '../../../../store/slices';
import Loader from '../../../atoms/loader';
import { UserRole } from '../../../../store/user';
import LeadsList from '../../../organisms/leads/leadsListView';
import LeadsCard from '../../../organisms/leads/leadsCardView';
import NoPermission from '../../../atoms/noPermission';
import { setAddNew } from '../../../../store/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import ConversionsDisabledCard from './conversionsDisabledCardView';
import ConversionsDisabledList from './conversionsDisabledListView';
import Paginator from '../../../atoms/paginator';

const Conversions = () => {
  // Selectors
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const filterData = useSelector(selectLeadsFilter);
  const leadViewState = useSelector(selectLeadState);
  const edit = useSelector(selectBulkEdit);
  // User
  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();
  // States
  const [loadingFigures, setLoadingFigures] = useState(true);
  const [salesCount, setSalesCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [conversationsCount, setConversationsCount] = useState(0);
  const [conversation, setConversation] = useState<any[]>([]);
  const [showLead, setshowLead] = useState<LeadModel[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setsearch] = useState<string>('');
  const [loading, setloading] = useState<boolean>(true);
  const [view, setview] = useState<EnumViews | string>(leadViewState || EnumViews.Cards);
  const [rowCheckboxState, setRowCheckboxState] = useState<{ [key: string]: boolean }>(Object.fromEntries(showLead.map((lead) => [lead.id, false])));
  const [openBulkEditLeadModal, setOpenBulkEditLeadModal] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(parseInt(page ?? '1'));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTotalCount = async () => {
    const repository = new LeadRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const totalCount = await repository.getAllCount(currentUser, search, 'Converted', filterData.assignedTo, filterData.priority);

      return totalCount;
    }
    return 0;
  };

  const getAllLeads = async () => {
    setloading(true);
    const repository = new LeadRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser?.get('role') === UserRole.admin) {
      setIsAdmin(true);
    }

    await repository
      .getAll(currentUser, search, 'Converted', filterData.assignedTo, filterData.priority)
      .then((res) => {
        setshowLead(res);
        setConversationsCount(res.length);
        setOpen(false);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };

  const getMoreLeads = async (page: number) => {
    setloading(true);
    const repository = new LeadRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    await repository
      .getAll(currentUser, search, 'Converted', filterData.assignedTo, filterData.priority, page)
      .then((res) => {
        setshowLead(res);
        setOpenBulkEditLeadModal(false);
        setOpen(false);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };

  const handleSwitchView = () => {
    const viewActive = view === EnumViews.Cards ? EnumViews.List : EnumViews.Cards;
    dispatch(onSwitchView(viewActive));
    setview(viewActive);
  };

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const updatedRowCheckboxState = Object.fromEntries(showLead.map((lead) => [lead.id, checked]));
    setRowCheckboxState(updatedRowCheckboxState);
  };

  useEffect(() => {
    getAllLeads();
  }, []);

  useEffect(() => {
    getAllLeads();
  }, [filterData.status, filterData.assignedTo, filterData.priority, search]);

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Leads', 'View'));
    const fetchCompanyFigures = async () => {
      const organizationRepository = new OrganizationRepository();
      const sales = await organizationRepository.getTotalSales();
      if (sales) {
        setSalesCount(sales);
      }
      const leads = await organizationRepository.getLeadsCount();
      if (leads) {
        setLeadsCount(leads);
      }
      const properties = await organizationRepository.getSoldPropertiesCount();
      if (properties) {
        setPropertiesCount(properties);
      }
      const projects = await organizationRepository.getSoldProjectsCount();
      if (projects) {
        setProjectsCount(projects);
      }
      setLoadingFigures(false);
    };

    fetchCompanyFigures();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Conversions'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        <Box width={'100%'} padding={3}>
          <Box display={'flex'} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} justifyContent={'space-between'} gap={'30px'}>
            <CompanyCard value={conversationsCount} title={'Conversions'} iconName={'Users'} loading={loadingFigures} />
            <CompanyCard value={formatMoney(salesCount)} title={'Total Sales'} iconName={'Sales'} loading={loadingFigures} />
            <CompanyCard value={propertiesCount} title={'Properties Sold'} iconName={'Properties'} loading={loadingFigures} />
            <CompanyCard value={projectsCount} title={'Projects Sold'} iconName={'Projects'} loading={loadingFigures} />
          </Box>

          {hasPermission ? (
            <Box width={'100%'} paddingTop={3} paddingBottom={3}>
              <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} marginBottom={'10px'}>
                {isAdmin && (
                  <Button
                    className="btn-add"
                    onClick={() => {
                      dispatch(setAddNew({ addNew: true, path: '/conversions' }));
                      navigate('/conversions/requests');
                    }}
                  >
                    Conversion Requests
                  </Button>
                )}

                <Box
                  component={'span'}
                  display={'flex'}
                  gap={'20px'}
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    width: {
                      xs: '100%',
                      md: 'auto',
                    },
                    marginTop: {
                      xs: '20px',
                      md: '0px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: 'flex',
                      },
                      gap: {
                        xs: '5px',
                        md: `15px`,
                      },
                    }}
                  >
                    {(showLead.length > 0 || search !== '') && (
                      <SearchInput
                        value={search}
                        onChange={(e) => {
                          setsearch(e.target.value);
                        }}
                        className="input-search"
                        placeholder="Search Conversions"
                      />
                    )}
                  </Box>
                  {showLead.length > 0 && (
                    <Box display={'flex'} width={'100%'} justifyContent={'space-between'} gap={'20px'}>
                      <ButtonView className="btn-view" onChangeView={handleSwitchView} defaultView={view} view={view} />
                    </Box>
                  )}
                </Box>
              </Box>
              <Typography
                sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
                onClick={() => dispatch(FilterDataFtn({ status: '', assignedTo: '', priority: '' }))}
              >
                {filterData.status !== '' || filterData.assignedTo !== '' || filterData.priority !== '' ? 'Clear Filter' : ''}
              </Typography>

              {view === EnumViews.Cards ? (
                <>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      {showLead.length > 0 ? (
                        <>
                          {currentUser?.get('role') !== UserRole.executive && edit && (
                            <Checkbox
                              sx={{
                                color: color !== 'default' && color !== '' ? color : globalColors.blue,
                                '&.Mui-checked': {
                                  color: color || globalColors.secondBlue,
                                },
                              }}
                              onChange={handleHeaderCheckboxChange}
                              checked={Object.values(rowCheckboxState).length > 0 && Object.values(rowCheckboxState).every((isChecked) => isChecked)}
                            />
                          )}

                          <Grid container spacing={2}>
                            {showLead.map((val, idx) => (
                              <Grid key={idx} item xs={12} md={6} lg={4}>
                                <ConversionsDisabledCard
                                  mode={mode}
                                  content={val}
                                  color={color !== 'default' && color !== '' ? color : ''}
                                  setloading={setloading}
                                  edit={edit}
                                  rowCheckboxState={rowCheckboxState}
                                  setRowCheckboxState={setRowCheckboxState}
                                  setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                                  openBulkEditLeadModal={openBulkEditLeadModal}
                                />
                              </Grid>
                            ))}
                          </Grid>
                          <Paginator
                            getTotalCount={getTotalCount}
                            onChangePage={async (newPage) => {
                              await getMoreLeads(newPage);
                              return true;
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {search !== '' || filterData.status !== '' || filterData.assignedTo !== '' ? (
                            <Typography
                              sx={{
                                color: color || globalColors.blue,
                                textAlign: 'center',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100vh',
                              }}
                            >
                              No Conversions Found
                            </Typography>
                          ) : (
                            <NotFoundLottie
                              showButton={true}
                              text="Looks like you don't have any conversions yet. Press the button below to view your leads."
                              buttonText="View Leads"
                              navigateTo="/leads"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <ConversionsDisabledList
                    mode={mode}
                    loading={loading}
                    showLead={showLead}
                    search={search}
                    color={color !== 'default' && color !== '' ? color : ''}
                    setloading={setloading}
                    edit={edit}
                    rowCheckboxState={rowCheckboxState}
                    setRowCheckboxState={setRowCheckboxState}
                    setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                    openBulkEditLeadModal={openBulkEditLeadModal}
                  />
                  <Paginator
                    getTotalCount={getTotalCount}
                    onChangePage={async (newPage) => {
                      await getMoreLeads(newPage);
                      return true;
                    }}
                  />
                </>
              )}
            </Box>
          ) : loading ? (
            <Loader />
          ) : (
            <NoPermission />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Conversions;
