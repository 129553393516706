import { Button, Typography, styled } from '@mui/material';
import { useState } from 'react';
import OTPInput from 'react-otp-input';
import { globalColors } from '../../../utils/constants/color';
import { HeadingFormAuth } from '../../molecules';

interface FormVerificationProps {
  className?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

const FormVerificationCode = ({
  className,
  onConfirm,
  onClose,
}: FormVerificationProps) => {
  const [otp, setOtp] = useState('');

  return (
    <FormForgotPasswordStyle className={className}>
      <div className="wrapper-heading">
        <HeadingFormAuth
          title="Forget your password ?"
          subTitle="Enter The verification code of 6 numbers"
          onGoBack={onClose}
        />

        <Typography className="head-code">Code</Typography>
        <OTPInput
          containerStyle="otp-wrapper"
          inputStyle="opt-input"
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="number"
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="wrapper-btn">
        <Button variant="contained" className="btn-send" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </FormForgotPasswordStyle>
  );
};

const FormForgotPasswordStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '& .wrapper-heading': {
    height: '100%',
  },

  '& .wrapper-btn': {
    display: 'grid',
    placeItems: 'center',
    marginBottom: '60px',
    '& .btn-send': {
      width: '291px',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },

  '& button': {
    textTransform: 'none',
  },

  // * OTP Styling
  '& .head-code': {
    color: globalColors.white,
    fontSize: '1rem',
    marginBottom: '1rem',
  },
  '& .otp-wrapper': {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'start',
    },
    '& input': {
      height: '48px',
      width: '48px !important',
      border: '2px solid #303030',
      backgroundColor: globalColors.black,
      borderRadius: '12px',
      color: globalColors.text.secondary,
    },
  },
}));

export default FormVerificationCode;
