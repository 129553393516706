import { Box, Typography, styled } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { selectColor, selectThemeMode } from '../../store/selector';
import { getInitials } from '../../utils/helpers';

interface RoundedAvatarProps {
  title: string;
  showInitials?: boolean;
}

const RoundedAvatar = ({ title, showInitials = true }: RoundedAvatarProps) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  return (
    <Box
      style={{
        height: '24px',
        width: '24px',
        border: `1px solid ${globalColors.gray}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '16px',
        position: 'relative',
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        padding: '2px',
        cursor: 'pointer'
      }}
    >
        <Typography fontSize={10}>{ showInitials ? getInitials(title) : title }</Typography>
    </Box>
  );
};

export default RoundedAvatar;
