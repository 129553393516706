import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { onProjectLoading, setFormEditProject } from '../../store/projects';
import ProjectRepository from '../../utils/api/repositories/projectRepository';
import { Toast } from '../../components/atoms';
import { selectProjectState } from '../../store/selector';
// import ProjectModel from '../../utils/api/models/projectModel';

const useEditProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectState = useSelector(selectProjectState);

  const { id: objectId } = useParams();
  const [title, setTitle] = useState<string>('Projects / Edit');
  const projectRepostiroy = new ProjectRepository();

  useEffect(() => {
    findOneProject();
  }, [objectId]);

  // useEffect(() => {
  //   document.title = 'Project | ' + projectState.formProject.name;
  // }, [projectState.formProject.name]);

  const findOneProject = async () => {
    dispatch(onProjectLoading(true));
    if (!objectId) {
      navigate('/projects');
      return null;
    }

    const getData = await projectRepostiroy.getByObjectId(objectId);
    dispatch(onProjectLoading(false));

    if (!getData) {
      Toast('Project not found', 'error');
      navigate('/projects');
      return null;
    }

    if (getData) {
      // setTitle(getData.name);
      dispatch(setFormEditProject(getData));
    }
  };

  return { formProject: projectState.formProject, title, isLoading: projectState.isLoading };
};

export default useEditProject;
