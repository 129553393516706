import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './accordion.css';
import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../../utils/constants/color';
import { selectThemeMode } from '../../../../store/selector';

export default function SupportAccordion() {
  const mode = useSelector(selectThemeMode);
  const elements = document.getElementsByClassName('css-1elwnq4-MuiPaper-root-MuiAccordion-root');

  useEffect(() => {
    for (let elem = 0; elem < elements.length; elem++) {
      const element = elements[elem] as HTMLElement;
      if (mode === 'dark') {
        element?.style.setProperty('--background-color', `${globalColors.black}`);
        element?.style.setProperty('--text-color', `${globalColors.white}`);
      } else {
        element?.style.setProperty('--background-color', `${globalColors.whiteLight}`);
        element?.style.setProperty('--text-color', `${globalColors.black}`);
      }
    }
  }, [mode]);

  return (
    <div>
      {/* 1 */}
      <Accordion sx={{ borderRadius: '20px !important', backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white, marginBottom: '1rem' }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>How can I create a lead?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Heading mode={mode}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas dignissimos optio eum officiis voluptatum magni expedita eius maxime
            amet nam nulla quae error provident inventore accusantium, nisi quo ut quam?
          </Heading>
        </AccordionDetails>
      </Accordion>

      {/* 2 */}
      <Accordion sx={{ borderRadius: '20px !important', backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white, marginBottom: '1rem' }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>How can I create a project?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Heading mode={mode}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas dignissimos optio eum officiis voluptatum magni expedita eius maxime
            amet nam nulla quae error provident inventore accusantium, nisi quo ut quam?
          </Heading>
        </AccordionDetails>
      </Accordion>

      {/* 3 */}
      <Accordion sx={{ borderRadius: '20px !important', backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white, marginBottom: '1rem' }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>How can I create a property?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Heading mode={mode}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas dignissimos optio eum officiis voluptatum magni expedita eius maxime
            amet nam nulla quae error provident inventore accusantium, nisi quo ut quam?
          </Heading>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const Heading = styled(Typography)(({ mode }: { mode: any }) => ({
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: globalColors?.gray,
}));
