import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses } from '@mui/material';
import { BsArrowRight, BsTelephone } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { globalColors } from '../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { BiEnvelope } from 'react-icons/bi';
import { selectColor, selectThemeMode } from '../../../store/selector';
import Heading from '../../atoms/heading';
import RadialBarchart from '../charts/radialBarChart';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import UserRepository from '../../../utils/api/repositories/userRepository';
import Loader from '../../atoms/loader';
import NotFoundLottie from '../../atoms/notFound';
import Piechart from '../charts/pieChart';

interface ChartData {
  name: string;
  value: number;
}

const PriorityChart = () => {
  const [data, setData] = useState<ChartData[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasNoLead, setHasNoLead] = useState(false);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);

  useEffect(() => {
    const fetchStatusCounts = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser && currentUser.get('organization')) {
        const leadRepository = new LeadRepository();
        const counts = await leadRepository.getCountsByPriority(currentUser.get('organization'));
        if (counts) {
          const temp: ChartData[] = [];
          let totalCount = 0;
          for (let i = 0; i < counts.length; i++) {
            totalCount += counts[i].value;
            temp.push({ name: counts[i].name, value: counts[i].value });
          }
          if (totalCount === 0) {
            setHasNoLead(true);
          }
          setData(temp);
        }
      }
      setLoading(false);
    };

    fetchStatusCounts();
  }, []);

  return (
    <>
      {loading ? (
        <Box height={'270px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : hasNoLead ? (
        <Box height={'270px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <NotFoundLottie
            text="Looks like you have not added any leads yet. Press the button below to add a lead."
            buttonText="Add New Lead"
            size="small"
            navigateTo="/leads"
            showButton={true}
          />
        </Box>
      ) : (
        <>
          <Heading text={'Priority'} />
          <Piechart data={data} />
        </>
      )}
    </>
  );
};

export default PriorityChart;
