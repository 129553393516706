import React, { useEffect, useRef } from 'react';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Sidebar from '../../sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAddNew,
  selectColor,
  selectCommissionMode,
  selectNavigationLayout,
  selectOpenSidebar,
  selectOrganizationTier,
  selectRefreshTasksState,
  selectSortTasksState,
  selectThemeMode,
} from '../../../../store/selector';
import { useState } from 'react';
import AddEmployee from '../../settings/Commission/addEmployee';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalColors } from '../../../../utils/constants/color';
import { Button, SearchInput } from '../../../atoms';
import { setAddNew } from '../../../../store/routes';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import NotFoundLottie from '../../../atoms/notFound';
import './Task.css';
import ContentTable from './contentTable';
import CreateTaskModal from './createTaskModal';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import TaskRepository from '../../../../utils/api/repositories/taskRepository';
import { PricingTiers, TaskStatus, hasPermissionForAction } from '../../../../utils/helpers';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import InsufficientPlan from '../../../atoms/insufficientPlan';
import Paginator from '../../../atoms/paginator';

const Tasks = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [value, setValue] = React.useState(0);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const sideBar = useSelector(selectOpenSidebar);
  const navigation = useSelector(selectNavigationLayout);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState(false);
  const [allTasks, setAllTasks] = useState<Parse.Object[]>([]);
  const [allTasksUnfiltered, setAllTasksUnfiltered] = useState<Parse.Object[]>([]);
  const [inProgressTasks, setInProgressTasks] = useState<Parse.Object[]>([]);
  const [blockedTasks, setBlockedTasks] = useState<Parse.Object[]>([]);
  const [completedTasks, setCompletedTasks] = useState<Parse.Object[]>([]);
  const [hasPermission, setHasPermission] = useState(false);
  const organizationTier = useSelector(selectOrganizationTier);
  const reSortTasks = useSelector(selectSortTasksState);
  const refreshTasks = useSelector(selectRefreshTasksState);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(parseInt(page ?? '1'));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    const taskRepository = new TaskRepository();
    const tasks = await taskRepository.getAll(event.target.value);
    if (tasks) {
      setAllTasks(tasks);
    }
  };

  const useDebounce = (callback: Function, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    return (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const debouncedHandleSearch = useDebounce(handleSearch, 300);

  useEffect(() => {
    if (allTasks.length > 0) {
      setInProgressTasks(allTasks.filter((t) => t.get('status') === TaskStatus.inProgress));
      setBlockedTasks(allTasks.filter((t) => t.get('status') === TaskStatus.blocked));
      setCompletedTasks(allTasks.filter((t) => t.get('status') === TaskStatus.completed));
    }
  }, [allTasks, reSortTasks]);

  const getTotalCount = async () => {
    const repository = new TaskRepository();
    const totalCount = await repository.getAllCount(search);
    return totalCount;
  };

  const fetchAllTasks = async () => {
    setLoading(true);
    const taskRepository = new TaskRepository();
    const tasks = await taskRepository.getAll();
    if (tasks) {
      setAllTasks(tasks);
      setAllTasksUnfiltered(tasks);
    }
    setLoading(false);
  };

  const fetchMoreTasks = async (page: number) => {
    setLoading(true);
    const taskRepository = new TaskRepository();
    const tasks = await taskRepository.getAll(search, page);
    if (tasks) {
      setAllTasks(tasks);
      setAllTasksUnfiltered(tasks);
    }
    setLoading(false);
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Tasks', 'View'));
    fetchAllTasks();
  }, [refreshTasks]);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text={'Tasks'} />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {loading ? (
            <Loader />
          ) : hasPermission && organizationTier == PricingTiers.Professional ? (
            <Box width={'100%'} padding={3}>
              <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} marginBottom={'10px'}>
                <Box
                  sx={{
                    width: { xl: sideBar ? '45%' : '38%', lg: sideBar ? '45%' : '38%', md: '45%', sm: '100%', xs: '100%' },
                    bgcolor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                    borderRadius: '8px',
                  }}
                >
                  <Tabs
                    sx={{
                      '& .css-heg063-MuiTabs-flexContainer': {
                        justifyContent: 'space-between',
                      },
                      borderRadius: '8px',
                    }}
                    orientation={isMobile ? 'vertical' : 'horizontal'}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab sx={{ color: globalColors.gray, textTransform: 'none' }} label={`All`} />
                    <Tab sx={{ color: globalColors.gray, textTransform: 'none' }} label={`In Progress`} />
                    <Tab sx={{ color: globalColors.gray, textTransform: 'none' }} label={`Blocked`} />
                    <Tab sx={{ color: globalColors.gray, textTransform: 'none' }} label={`Completed`} />
                  </Tabs>
                </Box>

                <Box
                  component={'span'}
                  display={'flex'}
                  gap={'20px'}
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    width: {
                      xs: '100%',
                      md: 'auto',
                    },
                    marginTop: {
                      xs: '20px',
                      md: '0px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: 'flex',
                      },
                      gap: {
                        xs: '5px',
                        md: `15px`,
                      },
                      flexDirection: {
                        xl: 'row',
                        lg: 'row',
                        md: 'row',
                        sm: 'column',
                        xs: 'column',
                      },
                    }}
                  >
                    <Button
                      // mode={mode}
                      // color={color}
                      onClick={() => {
                        // dispatch(setAddNew({ addNew: true, path: '/activity/task' }));
                        setOpenModal(true);
                      }}
                    >
                      Add a Task
                    </Button>
                    {allTasksUnfiltered.length > 0 && <SearchInput onChange={debouncedHandleSearch} className="input-search" placeholder="Search" />}
                  </Box>
                </Box>
              </Box>

              {allTasksUnfiltered?.length > 0 ? (
                <>
                  <Box
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    padding={'20px'}
                    borderRadius={'10px'}
                    color={globalColors.white}
                    fontSize={'12px'}
                    boxShadow={'#171717'}
                    marginTop={'1rem'}
                  >
                    <ContentTable tasks={value === 0 ? allTasks : value === 1 ? inProgressTasks : value === 2 ? blockedTasks : completedTasks} />
                  </Box>
                  <Paginator
                    getTotalCount={getTotalCount}
                    onChangePage={async (newPage) => {
                      await fetchMoreTasks(newPage);
                      return true;
                    }}
                  />
                </>
              ) : !loading ? (
                <NotFoundLottie
                  showButton={false}
                  text="Looks like you don't have any scheduled tasks added yet. Press the button above to add a new task."
                  buttonText=""
                  navigateTo=""
                />
              ) : (
                <></>
              )}
            </Box>
          ) : !hasPermission ? (
            <NoPermission />
          ) : (
            <InsufficientPlan />
          )}
        </Box>
      </Box>
      <CreateTaskModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        mode={mode}
        color={color}
      />
    </div>
  );
};

const ButtonStyle = styled('button')<any>((props) => ({
  height: '32px',
  textAlign: 'center',
  all: 'unset',
  cursor: 'pointer',
  background:
    props.color !== 'default' && props.color !== ''
      ? props.color
      : props.selected === undefined
      ? props.type === 'reset'
        ? globalColors.blackLight
        : globalColors.blue
      : props.selected
      ? globalColors.blue // Add a color for when the button is selected
      : globalColors.blackLight,
  color: props.mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '4px 40px',
  borderRadius: '6px',
  transition: 'opacity 0.3s ease',
  // border: `1px solid ${globalColors.secondBlue}`,
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 576px)': {
    padding: '10px 40px',
  },
}));

export default Tasks;
