import React, { useState, useEffect } from 'react';
import { Box, Typography, styled, TextField, Chip, CircularProgress } from '@mui/material';
import { selectColor, selectOrganizationTier, selectThemeMode } from '../../../store/selector';
import { useDispatch, useSelector } from 'react-redux';
import { globalColors } from '../../../utils/constants/color';
import { FloraEditor } from '../../atoms/floraEditior';
import { Button, Toast } from '../../atoms';
import EmailTemplateRepository from '../../../utils/api/repositories/emailTemplateRepository';
import EmailTemplateModel from '../../../utils/api/models/emailTemplateModel';
import EmailRepository from '../../../utils/api/repositories/emailRepository';
import EmailModel from '../../../utils/api/models/emailModel';
import { FloraEditorValue } from '../../../store/slices';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { PricingTiers, hasPermissionForAction } from '../../../utils/helpers';
import Loader from '../../atoms/loader';
import NoPermission from '../../atoms/noPermission';
import { BsInfoCircle } from 'react-icons/bs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalAddTemplate from './modalAddTemplate';
import InsufficientPlan from '../../atoms/insufficientPlan';

interface SendEmailStyleProps {
  mode: string;
}

const SendEmail: React.FC = () => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [emailValue, setEmailValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [inputCCValue, setCCInputValue] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [emailTags, setEmailTags] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Parse.Object[]>([]);
  const submitted = false;
  const [content, setContent] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<number>(-1);
  const [hasPermission, setHasPermission] = useState(false);
  const organizationTier = useSelector(selectOrganizationTier);
  const [loading, setLoading] = useState(true);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const navigate = useNavigate();

  const handleChangeContent = (value: string) => {
    setContent(value);
  };

  const isUnique = (value: string) => {
    if (emailTags.includes(value) || tags.includes(value)) {
      return false;
    }
    return true;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const repository = new EmailTemplateRepository();
      const templatesData = await repository.getEmailTemplatesByUser();
      if (templatesData) {
        setTemplates(templatesData);
        if (templatesData.length > 0) {
          setSelectedTemplate(0);
          setInputValue(templatesData[0].get('subject') ?? '');
          setContent(templatesData[0].get('text') ?? '');
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error('Error fetching email templates:', error.message);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCCInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputCCValue.trim()) {
      const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/);
      if (!regex.test(inputCCValue.trim())) {
        Toast('Please enter a valid email address.', 'error');
      } else {
        if (isUnique(inputCCValue.trim())) {
          setTags([...tags, inputCCValue.trim()]);
          setCCInputValue('');
        } else {
          Toast('This email address has already been added.', 'error');
        }
      }
    }
  };

  const handleToEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && emailValue.trim()) {
      const regex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/);
      if (!regex.test(emailValue.trim())) {
        Toast('Please enter a valid email address.', 'error');
      } else {
        if (isUnique(emailValue.trim())) {
          setEmailTags([...emailTags, emailValue.trim()]);
          setEmailValue('');
        } else {
          Toast('This email address has already been added.', 'error');
        }
      }
    }
  };

  const handleToEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handleEmailTagDelete = (tagToDelete: string) => {
    setEmailTags(emailTags.filter((tag) => tag !== tagToDelete));
  };

  const handleTagDelete = (tagToDelete: string) => {
    setTags(tags.filter((tag) => tag !== tagToDelete));
  };

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Email', 'Create'));
    fetchData();
  }, []);

  const handleAddTemplate = async (templateName: string) => {
    const emailTemplateRepository = new EmailTemplateRepository();
    const template: EmailTemplateModel = {
      name: templateName,
    };
    const savedTemplate = await emailTemplateRepository.createEmailTemplate(template);
    if (savedTemplate) {
      const temp = [...templates];
      temp.push(savedTemplate);
      setTemplates(temp);
      setSelectedTemplate(temp.length - 1);
      Toast('Email Template added successfully.', 'success');
    } else {
      Toast('Email Template could not be added successfully. Please try again.', 'error');
    }
  };

  const handleSendEmail = async () => {
    if (emailTags.length === 0) {
      Toast(`Please add at least one recipient.`, 'error');
    } else {
      const emailModel: EmailModel = {
        to: emailTags,
        CC: tags,
        subject: inputValue,
        text: content,
      };

      const repository = new EmailRepository();
      const savedEmail = await repository.createEmail(emailModel, false, null);

      if (savedEmail) {
        Toast('Email sent successfully.', 'success');
      } else {
        Toast("Email could not be sent successfully. Please try again. Make sure you haven't reached your daily quota.", 'error');
      }

      if (templates.length > 0 && selectedTemplate !== -1) {
        const emailTemplate: EmailTemplateModel = {
          name: templates[selectedTemplate].get('name') ?? '',
          subject: inputValue,
          text: content,
        };

        const templateRepository = new EmailTemplateRepository();
        await templateRepository.updateEmailTemplate(templates[selectedTemplate].id, emailTemplate);
      }

      setContent('');
      navigate('/email');
    }
  };

  const handleTemplateClick = (val: number) => {
    setSelectedTemplate(val);
    setInputValue(templates[val].get('subject') ?? '');
    setContent(templates[val].get('text') ?? '');
  };

  const isSelected = (idx: number) => {
    return selectedTemplate === idx;
  };

  const handleClearIconClick = (idx: number) => {
    const temp = [...templates];
    temp.splice(idx, 1);
    setTemplates(temp);
  };

  const deleteTemplate = async () => {
    const templateRepository = new EmailTemplateRepository();
    const isDeleted = await templateRepository.deleteEmailTemplate(templates[selectedTemplate].id);

    if (isDeleted) {
      Toast('Email Template deleted successfully.', 'success');
      const temp = [...templates];
      temp.splice(selectedTemplate, 1);
      setTemplates(temp);
      if (temp.length > 0) {
        setSelectedTemplate(selectedTemplate - 1 >= 0 ? selectedTemplate - 1 : 0);
        setInputValue(selectedTemplate - 1 >= 0 ? temp[selectedTemplate - 1].get('subject') ?? '' : temp[0].get('subject') ?? '');
        setContent(selectedTemplate - 1 >= 0 ? temp[selectedTemplate - 1].get('text') ?? '' : temp[0].get('text') ?? '');
      } else {
        setSelectedTemplate(-1);
        setInputValue('');
        setContent('');
      }
    } else {
      Toast('Email Template could not be deleted successfully. Please try again.', 'error');
    }
  };

  useEffect(() => {
    console.log(templates, 'TEMPLATES');
  }, [templates]);

  const reSizeSubject = () => {
    const elem1 = document.getElementById('fortagsinputBox');
    const elem2 = document.getElementById('fortagsinput');
    if (elem1 && elem2) {
      elem2.style.paddingLeft = elem1.clientWidth + 30 + 'px';
    }
  };

  const reSizeCC = () => {
    const elem1 = document.getElementById('fortagsinput1Box');
    const elem2 = document.getElementById('fortagsinput1');
    if (elem1 && elem2) {
      elem2.style.paddingLeft = elem1.clientWidth + 30 + 'px';
    }
  };
  const handleResize = () => {
    reSizeCC();
    reSizeSubject();
  };
  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener('resize', handleResize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return loading ? (
    <Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Loader />
    </Box>
  ) : hasPermission && organizationTier == PricingTiers.Professional ? (
    <SendEmailStyle mode={mode}>
      {/* Header */}
      <Box className="head">
        <Box display={'flex'} justifyContent={'start'} gap={'1rem'} flexWrap={'wrap'}>
          {templates?.map((t, idx) => (
            <TemplateStyle
              color={color}
              key={t.id}
              className="btn-add"
              onClick={() => handleTemplateClick(idx)}
              selected={isSelected(idx)}
              mode={mode}
            >
              {t.get('name')}
              <ClearIcon onClick={() => handleClearIconClick(idx)} />
            </TemplateStyle>
          ))}
        </Box>
        <Box width={{ xs: '97%', md: 'auto' }}>
          <Button
            className=""
            onClick={() => {
              setOpenTemplateModal(true);
            }}
          >
            Add New Template
          </Button>
        </Box>
      </Box>
      {/* To */}
      <Box className="bgColor">
        <Box className="input-container">
          <Typography color={color || globalColors.blue}>To:</Typography>
          <input
            placeholder="Email"
            style={{ backgroundColor: mode === 'dark' ? globalColors?.darkblack : globalColors?.white }}
            value={emailValue}
            onChange={handleToEmailChange}
            onKeyDown={handleToEmail}
            className="input"
            type="email"
          />
          {/* {inputValue && <FaTimes className="clear-icon" onClick={handleClearInput} />} */}
        </Box>
        <Box display={'flex'} flexDirection={'row'} gap={'5px'} alignItems={'center'}>
          <BsInfoCircle color={globalColors.gray} size={'1rem'} />
          <Typography fontSize={'12px'} color={globalColors.gray} paddingTop={'0.5rem'}>
            Press enter to add values. If there is more than one value added, the rest would be treated as CC.
          </Typography>
        </Box>

        <Box display={'flex'} justifyContent={'start'} gap={'10px'} paddingTop={'1rem'} flexWrap={'wrap'}>
          {emailTags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleEmailTagDelete(tag)}
              sx={{ backgroundColor: color || globalColors.blue, color: mode === 'dark' ? globalColors.white : globalColors.black }}
            />
          ))}
        </Box>

        <Box
          marginTop={'1rem'}
          display={'flex'}
          bgcolor={mode === 'dark' ? globalColors?.darkblack : globalColors?.white}
          borderRadius={'20px'}
          alignItems={'center'}
        >
          <Box
            bgcolor={mode === 'dark' ? globalColors?.darkblack : globalColors?.white}
            borderRadius={'20px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            padding={'10px'}
            color={mode === 'dark' ? globalColors.white : globalColors.black}
            fontSize={'13px'}
            height={'62px'}
            paddingLeft={'20px'}
            paddingRight={'20px'}
            boxShadow={'2px 0px 4px 0px #836F6F40'}
            zIndex={10}
          >
            CC:
          </Box>
          <TextField
            className="fortagsinput"
            variant="outlined"
            id="fortagsinput1"
            sx={{
              backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray,
              fontSize: '13px !important',
              marginBottom: '0px',
              '& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root': {
                fontSize: '13px !important',
              },
            }}
            fullWidth
            value={inputCCValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            InputLabelProps={{ shrink: false }}
          />
        </Box>
        <Box display={'flex'} flexDirection={'row'} gap={'5px'} alignItems={'center'}>
          <BsInfoCircle color={globalColors.gray} size={'1rem'} />
          <Typography fontSize={'12px'} color={globalColors.gray} paddingTop={'0.5rem'}>
            Press enter to add values.
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'start'} gap={'10px'} paddingTop={'1rem'} flexWrap={'wrap'}>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleTagDelete(tag)}
              sx={{ backgroundColor: color || globalColors.blue, color: mode === 'dark' ? globalColors.white : globalColors.black }}
            />
          ))}
        </Box>
      </Box>
      {/* Subject */}
      <Box
        marginTop={'1rem'}
        display={'flex'}
        bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
        borderRadius={'20px'}
        alignItems={'center'}
      >
        <Box
          bgcolor={mode === 'dark' ? globalColors?.darkblack : globalColors?.white}
          borderRadius={'20px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          padding={'10px'}
          color={mode === 'dark' ? globalColors.white : globalColors.black}
          fontSize={'13px'}
          height={'62px'}
          paddingLeft={'20px'}
          paddingRight={'20px'}
          boxShadow={'2px 0px 4px 0px #836F6F40'}
          zIndex={10}
        >
          Subject:
        </Box>
        <input
          style={{
            backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray,
            padding: '1rem',
            fontSize: '13px',
            marginBottom: '0px',
          }}
          value={inputValue}
          placeholder="Type Your Subject"
          onChange={(e) => setInputValue(e.target.value)}
          className="fortagsinput"
          id="fortagsinput"
          type="email"
        />
      </Box>
      {/* Editor */}
      <Box className="bgColor">
        {/* <FloraEditor setContent={setContent} /> */}
        <ReactQuill
          theme="snow"
          value={content}
          onChange={handleChangeContent}
          style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}
        />
      </Box>
      <Box display={'flex'} gap={'10px'} justifyContent={'space-between'} marginTop={'1.5rem'}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            gap: '10px',
          }}
        >
          <Button type="submit" disabled={submitted} onClick={handleSendEmail}>
            {!submitted ? 'Send Email' : <CircularProgress color="inherit" style={{ width: '25px', height: '25px' }} />}
          </Button>
          {/* <Button type="reset">Cancel</Button> */}
        </Box>
        <Box
          sx={{
            display: 'flex',
            // justifyContent: 'flex-start',
            // gap: '10px',
          }}
        >
          {templates?.length > 0 && selectedTemplate !== -1 && (
            <ButtonStyle color={color} type="delete" disabled={submitted} onClick={deleteTemplate}>
              {!submitted ? 'Delete Template' : <CircularProgress color="inherit" style={{ width: '25px', height: '25px' }} />}
            </ButtonStyle>
          )}
        </Box>
      </Box>
      <ModalAddTemplate
        open={openTemplateModal}
        onClose={() => {
          setOpenTemplateModal(false);
        }}
        mode={mode}
        color={color}
        onSubmit={handleAddTemplate}
      />
    </SendEmailStyle>
  ) : !hasPermission ? (
    <NoPermission />
  ) : (
    <InsufficientPlan />
  );
};

const SendEmailStyle = styled('div')<SendEmailStyleProps>(({ theme, mode }) => ({
  '& .head': {
    display: 'flex !important',
    justifyContent: 'space-between',
    maxWidth: '100%',
    gap: '1rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    '& .headerbox': {
      cursor: 'pointer',
      borderRadius: '8px',
      padding: '0.5rem',
      backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors?.white,
      color: mode === 'dark' ? globalColors.white : globalColors.black,
      '&:hover': {
        backgroundColor: mode === 'dark' ? '#0455C6' : '',
      },
    },
  },
  '& .bgColor': {
    backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors?.whiteLight,
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    padding: '1rem',
    borderRadius: '20px',
    marginTop: '1rem',
  },
  '& .input-container': {
    position: 'relative',
    width: '25%',
    display: 'flex',
    gap: '0.6rem',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  '& .clear-icon': {
    position: 'absolute',
    top: '50%',
    right: '20px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    color: mode === 'dark' ? globalColors.white : globalColors.black,
  },
  '& .input': {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    padding: '1rem 3rem 1rem 1rem',
    borderRadius: '100px',
    border: 'none',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .fortaginput-container': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  '& .cc-box': {
    position: 'absolute',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '0',
    height: '100%',
    width: '8%',
    fontSize: '16px',
    boxShadow: '2px 0px 4px 0px #836F6F40',
    cursor: 'pointer',
    zIndex: 10,
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    [theme.breakpoints.down('lg')]: {
      width: '16%',
      fontSize: '10px',
    },
  },
  '& .fortagsinput': {
    backgroundColor: mode === 'dark' ? globalColors?.darkblack : globalColors?.white,
    color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
    borderRadius: '20px',
    border: 'none',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    color: `${mode === 'dark' ? globalColors.white : globalColors.black} !important`,
  },
  '& .tag-container': {
    position: 'absolute',
    right: '10px !important',
    left: 'auto',
    top: '50%',
    // width: '70vw',
    display: 'flex',
    // justifyContent: 'flex-end',
    overflowX: 'scroll',
    gap: '1rem',
    transform: 'translateY(-50%)',
  },
  '& .tag': {
    backgroundColor: globalColors.blue,
    margin: '1rem 0 0 0',
    color: 'white',
    marginLeft: 'auto !important',
    marginRight: '0',
  },
}));

const ButtonStyle = styled('button')<any>((props) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  opacity: props.disabled ? '0.5' : '1',
  height: '40px',
  background: globalColors.red,
  color: globalColors.white,
  width: '260px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: props.type === 'reset' ? `1px solid ${props.color ? props.color : globalColors.blue}` : '',
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

const TemplateStyle = styled(Box)<any>((props) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  opacity: props.disabled ? '0.5' : '1',
  height: '40px',
  background: props.mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
  border: `2px solid ${
    props.color !== 'default' && props.color !== '' && props.selected
      ? props.color
      : props.selected === undefined
      ? props.type === 'reset'
        ? globalColors.blackLight
        : globalColors.blue
      : props.selected
      ? globalColors.blue // Add a color for when the button is selected
      : 'transparent'
  }`,
  color: props.mode === 'dark' ? globalColors.white : globalColors.black,
  width: '260px',
  padding: '0px 8px',
  display: 'flex',
  rowGap: '3rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  // border: props.type === 'reset' ? `1px solid ${props.color ? props.color : globalColors.blue}` : '',
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default SendEmail;
