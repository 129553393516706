import { useEffect, useState } from 'react';
import { Box, TableContainer, Table, TableHead, TableBody, TableCell, TableRow, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { globalColors } from '../../../../../utils/constants/color';
import { selectAddNew, selectColor, selectCommissionFilter, selectEmployeeFilter, selectThemeMode } from '../../../../../store/selector';
import ContentRow from './contentRow';
import NotFoundLottie from '../../../../atoms/notFound';
import { SearchInput, Toast } from '../../../../atoms';
import Loader from '../../../../atoms/loader';
import FilterPayroll from './filterPayroll';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../../store/user';
import SalesExecutiveRepository from '../../../../../utils/api/repositories/salesExecutiveRepository';
import { FilterCommissionDataFtn, FilterEmployeeDataFtn } from '../../../../../store/slices';
import FilterCommission from '../../../settings/Commission/filterCommission';

const headerContent = ['Name', 'Role', 'Email', 'Commission (%)', 'Commission Priority', 'Actions'];

export interface CompanyMember {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  commissionPercentage: number;
  commissionPriority: string;
}

const ContentTable = () => {
  const [user, setUser] = useState<any[]>([]);
  const [members, setMembers] = useState<CompanyMember[]>([]);
  const [search, setsearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const filterData = useSelector(selectCommissionFilter);
  const dispatch = useDispatch();

  const handleSearch = (search: string) => {
    const searchTerm = search.toLowerCase().trim();

    const filteredUser = user.filter((user: any) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchTerm);
    });
    setMembers(filteredUser);
  };

  const fetchMembers = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      if (currentUser.get('role') === UserRole.admin) {
        const users = await userRepository.getAllUsersForOrganizationIncludingCurrentUser();
        if (users) {
          setUser(users);
          let temp: CompanyMember[] = [];
          for (let i = 0; i < users.length; i++) {
            temp.push({
              id: users[i].objectId ?? '',
              firstName: users[i].firstName ?? '',
              lastName: users[i].lastName ?? '',
              role: users[i].role ?? '',
              email: users[i].email ?? '',
              commissionPercentage: users[i].commissionPercentage ?? 0,
              commissionPriority: users[i].commissionPriority ?? 'None',
            });
          }
          setMembers(temp);
        }
      } else if (currentUser.get('role') === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const users = await salesExecutiveRepository.getAllManagedBy();
        if (users) {
          setUser(users);
          let temp: CompanyMember[] = [];
          for (let i = 0; i < users.length; i++) {
            temp.push({
              id: users[i].get('user').id ?? '',
              firstName: users[i].get('user').get('firstName'),
              lastName: users[i].get('user').get('lastName'),
              role: users[i].get('user').get('role'),
              email: users[i].get('user').get('email'),
              commissionPercentage: users[i].get('user').get('commissionPercentage') ?? 0,
              commissionPriority: users[i].get('user').get('commissionPriority') ?? 'None',
            });
          }
          setMembers(temp);
        }
      }
    }

    setLoading(false);
  };

  const fetchmembersWithFilters = async () => {
    const queryFilters = {
      role: filterData.role,
      commissionPriority: filterData.commissionPriority,
      commissionLow: filterData.commissionLow,
      commissionHigh: filterData.commissionHigh,
    };
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      if (currentUser.get('role') === UserRole.admin) {
        const users = await userRepository.getAllUsersForOrganizationIncludingCurrentUser(queryFilters);
        if (users) {
          setUser(users);
          let temp: CompanyMember[] = [];
          for (let i = 0; i < users.length; i++) {
            temp.push({
              id: users[i].objectId ?? '',
              firstName: users[i].firstName ?? '',
              lastName: users[i].lastName ?? '',
              role: users[i].role ?? '',
              email: users[i].email ?? '',
              commissionPercentage: users[i].commissionPercentage ?? 0,
              commissionPriority: users[i].commissionPriority ?? 'None',
            });
          }
          setMembers(temp);
        }
      } else if (currentUser.get('role') === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const users = await salesExecutiveRepository.getAllManagedBy(queryFilters);
        if (users) {
          setUser(users);
          let temp: CompanyMember[] = [];
          for (let i = 0; i < users.length; i++) {
            temp.push({
              id: users[i].get('user').id ?? '',
              firstName: users[i].get('user').get('firstName'),
              lastName: users[i].get('user').get('lastName'),
              role: users[i].get('user').get('role'),
              email: users[i].get('user').get('email'),
              commissionPercentage: users[i].get('user').get('commissionPercentage') ?? 0,
              commissionPriority: users[i].get('user').get('commissionPriority') ?? 'None',
            });
          }
          setMembers(temp);
        }
      }
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    handleSearch(search);
  }, [search]);

  useEffect(() => {
    fetchmembersWithFilters();
  }, [filterData]);

  return (
    <Box
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={{ xs: 0, md: '30px' }}
      paddingRight={{ xs: 0, md: '30px' }}
      height={'100%'}
    >
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'200px'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Grid container paddingBottom={'15px'} paddingTop={'10px'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={12} sm={12} paddingTop={{ xs: '1rem', sm: 0 }} display={'flex'} justifyContent={'end'}>
              <Box
                component={'span'}
                display={'flex'}
                gap={'20px'}
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },
                  width: {
                    xs: '100%',
                    md: 'auto',
                  },
                  marginTop: {
                    xs: '20px',
                    md: '0px',
                  },
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: 'flex',
                    },
                    gap: {
                      xs: '5px',
                      md: `15px`,
                    },
                  }}
                >
                  <SearchInput
                    value={search}
                    onChange={(e) => {
                      setsearch(e.target.value);
                    }}
                    className="input-search"
                    placeholder="Search"
                    bgColor="black"
                  />
                </Box>
                <Box display={'flex'} width={'100%'} justifyContent={'center'} gap={'20px'}>
                  <FilterCommission setOpen={setOpen} open={open} />
                </Box>
              </Box>
            </Grid>
            <Box display={'flex'} justifyContent={'end'} width={'100%'} paddingTop={'1rem'}>
              <Typography
                sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
                onClick={() =>
                  dispatch(FilterCommissionDataFtn({ role: 'Select', commissionPriority: 'Select', commissionLow: 0, commissionHigh: 100 }))
                }
              >
                {filterData.role !== 'Select' ||
                filterData.commissionPriority !== 'Select' ||
                filterData.commissionLow !== 0 ||
                filterData.commissionHigh !== 100
                  ? 'Clear Filter'
                  : ''}
              </Typography>
            </Box>
          </Grid>

          {members.length > 0 ? (
            <TableContainer sx={{ paddingBottom: '30px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headerContent.map((val) => (
                      <TableCell key={val} sx={{ color: globalColors.gray }}>
                        {val}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members.map((item) => (
                    <ContentRow data={item} key={item?.id} id={item?.id ?? ''} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : search === '' &&
            filterData.role === 'Select' &&
            filterData.commissionPriority === 'Select' &&
            filterData.commissionLow === 0 &&
            filterData.commissionHigh === 100 ? (
            <NotFoundLottie
              showButton={true}
              text="Looks like you don't have any members added yet. Press the button below to add a new member."
              buttonText="Add New Member"
              navigateTo="/hr/members/addMember"
              setAddNewPath='/hr/members'
            />
          ) : (
            <Typography
              sx={{
                color: color || globalColors.blue,
                textAlign: 'center',
                fontSize: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              No Member Found
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default ContentTable;
