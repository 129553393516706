import type { View } from '@aldabil/react-scheduler/components/nav/Navigation';
import type { ProcessedEvent as ProcessedEventOri } from '@aldabil/react-scheduler/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { calendarEvents } from '../utils/api/dummies/calendar.data';

interface NotificationState {
  newNotifications: boolean;
}

const initialState: NotificationState = {
  newNotifications: false,
};

const NotificationReducer = createSlice({
  name: 'Notification',
  initialState,
  reducers: {
    addNewNotifications: (state) => {
      state.newNotifications = true;
    },
    readNewNotifications: (state) => {
      state.newNotifications = false;
    }
  },
});

export const { addNewNotifications, readNewNotifications } = NotificationReducer.actions;
export default NotificationReducer.reducer;
