import { Box, FormGroup, InputLabel, Modal, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { NameInput } from './element.style';
import { FieldInput, FieldInputTextarea, Toast } from '../../../../atoms';
import { globalColors } from '../../../../../utils/constants/color';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs, { Dayjs } from 'dayjs';
import './MyDatePicker.css';
import DateModal from './dateModal';
import ToTimeModal from './toTimeModal';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers } from 'formik';
import Dropdown from '../../../../atoms/dropdown';
import UserRepository from '../../../../../utils/api/repositories/userRepository';
import SupportRequestRepository from '../../../../../utils/api/repositories/supportRequestRepository';
import SupportRequestModel from '../../../../../utils/api/models/supportRequestModel';

interface ModalProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  openResponse: () => void;
}

interface ValuesContactUs {
  name: string;
  phone: string;
  email: string;
  category: string;
  message: string;
}

const ContactUsModal = ({ open, onClose, mode, color, openResponse }: ModalProps) => {
  const [formValues, setFormValues] = useState<ValuesContactUs>({
    name: '',
    email: '',
    phone: '',
    category: '',
    message: '',
  });

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '728px',
    maxHeight: '90vh',
    bgcolor: mode === 'dark' ? 'background.paper' : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
    padding: '1.5rem',
    borderRadius: '20px',
    overflow: 'auto',
    '@media (max-width: 576px)': {
      width: '80%',
    },
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        setFormValues({
          name: currentUser.get('firstName') + ' ' + currentUser.get('lastName'),
          phone: currentUser.get('phone'),
          email: currentUser.get('email'),
          message: '',
          category: ''
        })
      }
    }

    fetchDetails();
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      <Box sx={style} className="content" display={'flex'} flexDirection={'column'}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 500,
              lineHeight: '30px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: mode === 'dark' ? globalColors.white : globalColors.black,
            }}
          >
            Contact Us
          </Typography>
          <ClearIcon sx={{ cursor: 'pointer', color: mode === 'dark' ? globalColors.white : globalColors.black }} onClick={onClose} />
        </Box>

        <Box sx={{ width: '100%', marginTop: '10px', borderBottom: `1px solid ${globalColors.background.gray}` }}></Box>

        <Formik
          enableReinitialize
          initialValues={{
            name: formValues.name,
            phone: formValues.phone,
            email: formValues.email,
            category: '',
            message: '',
          }}
          validate={(values) => {
            const errors = {} as ValuesContactUs;

            if (!values.name || !values.name.trim()) {
              errors.name = 'Required';
            }

            if (!values.phone || !values.phone.trim()) {
              errors.phone = 'Required';
            }

            if (!values.email || !values.email.trim()) {
              errors.email = 'Required';
            }

            if (!values.category || values.category === 'Select') {
              errors.category = 'Required';
            }

            return errors;
          }}
          onSubmit={async (values: ValuesContactUs, { setSubmitting, resetForm }: FormikHelpers<ValuesContactUs>) => {
            const supportRequestRepository = new SupportRequestRepository();
            const supportRequest: SupportRequestModel = {
              category: values.category,
              message: values.message,
              type: 'contact'
            };
            const savedSupportRequest = await supportRequestRepository.create(supportRequest);
            if (savedSupportRequest) {
              onClose();
              setTimeout(() => {
                openResponse();
              }, 1000)
            }
            else {
              onClose();
              Toast('Support request could not be submitted successfully. Please try again.', 'error');
            }
            resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form>
              <Box borderRadius={'20px'} marginBottom={'2px'} width={'100%'} display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
                <FieldInput
                  optional={false}
                  label="Full Name"
                  disabled
                  id="name"
                  mode={mode}
                  name={'name'}
                  placeholder="Your Full Name"
                  type="text"
                  error={touched.name && errors.name ? errors.name : false}
                />
                <FieldInput
                  optional={false}
                  label="Phone"
                  disabled
                  id="phone"
                  mode={mode}
                  name={'phone'}
                  placeholder="(+YY) XXX XXXXXXX"
                  type="text"
                  error={touched.phone && errors.phone ? errors.phone : false}
                />
                <FieldInput
                  optional={false}
                  label="Email"
                  disabled
                  id="email"
                  mode={mode}
                  name={'email'}
                  placeholder="example@domain.com"
                  type="text"
                  error={touched.email && errors.email ? errors.email : false}
                />

                <Box>
                  <Dropdown
                    label="Category"
                    id="category"
                    name="category"
                    optional={false}
                    mode={mode}
                    values={['Select', 'Feedback', 'Support', 'Functionality Problem']}
                    defaultValue="Select"
                  />
                  <StyledTypography>{touched.category && errors.category ? errors.category : ''}</StyledTypography>
                </Box>

                <FieldInputTextarea
                  className="col"
                  placeholder="Write your message here"
                  name="message"
                  id="description"
                  label="Message"
                  value={values.message}
                  onChange={(e) => {
                    values.message = e.target.value;
                  }}
                />
                <Box sx={{ width: '100%', display: 'flex', marginTop: '1rem', justifyContent: 'center' }}>
                  <ButtonStyle type="submit" color={color} mode={mode}>
                    Send
                  </ButtonStyle>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

const ButtonStyle = styled('button')<any>(({ color, disabled, type, mode }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '42px',
  background:
    color !== 'default' && color !== ''
      ? color
      : type === 'reset'
      ? mode === 'dark'
        ? globalColors.blackLight
        : globalColors.whiteLight
      : globalColors.blue,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  width: '215px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: '8px',
  color: 'red',
  fontSize: '10px',
}));

export default ContactUsModal;
