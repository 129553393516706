import { OutlinedInput, InputLabel, MenuItem, Select, FormControl, Stack, Chip, Typography } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputAdornment from '@mui/material/InputAdornment';
import { globalColors } from '../../../../utils/constants/color';
import { Box } from '@mui/system';
import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';

interface EmployeeDropdownProp {
  names: any;
  mode: string;
  selectedNames: any;
  setSelectedNames: any;
}

const EmployeeDropdown = ({ names, mode, selectedNames, setSelectedNames }: EmployeeDropdownProp) => {
  return (
    <>
      <Select
        style={{
          borderRadius: '12px',
          width: '200px',
          marginLeft: '20px',
          border: `1px solid ${mode === 'dark' ? globalColors.black : globalColors.black}`,
          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.lightgray,
          color: globalColors.gray,
        }}
        multiple
        value={selectedNames}
        onChange={(e) => setSelectedNames(e.target.value as string[])}
        placeholder="Select"
        input={
          <OutlinedInput
            inputProps={{
              style: { color: 'white' },
            }}
            // Remove it if you dont need custom white arrow
            endAdornment={
              <InputAdornment position="end">
                <ArrowDropDownIcon
                  style={{
                    color: 'white',
                  }}
                />
              </InputAdornment>
            }
          />
        }
        renderValue={(_) => '...'}
      >
        {names.map((name: any) => {
          const isSelected = selectedNames.some((selectedName: string) => JSON.parse(selectedName).id === name.id);
          return (
            <MenuItem
              key={name.id}
              value={JSON.stringify(name)}
              sx={{
                justifyContent: 'space-between',
                backgroundColor: isSelected
                  ? mode === 'dark'
                    ? globalColors.blackLight
                    : globalColors.lightgray
                  : mode === 'dark'
                  ? globalColors.black
                  : globalColors.white,
                color: isSelected ? globalColors.black : mode === 'dark' ? globalColors.white : globalColors.red,
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '18px',
                letterSpacing: '-0.3061120808124542px',
                textAlign: 'left',
                p: '10px',

                '&:hover': {
                  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight,
                },
              }}
            >
              <Typography
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  color: isSelected ? mode === 'dark' ? globalColors.black : globalColors.black : mode === 'dark' ? globalColors.white : globalColors.black,
                  padding: '5px 5px',
                }}
              >
                {name.name}
                {isSelected ? (
                  <CheckBoxIcon sx={{ color: globalColors.black }} />
                ) : (
                  <CheckBoxOutlineBlank sx={{ 
                    color: mode === 'dark' ? globalColors.white : globalColors.black ,
                  }} />
                )}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default EmployeeDropdown;
