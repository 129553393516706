import { Box, Select, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { globalColors } from '../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { sortEmailFtn } from '../../../store/slices';
import { sortEmail } from '../../../store/selector';

const names: string[] = ['Old', 'New'];

interface SortTableProps {
  mode: string;
}

const SortTable: React.FC<SortTableProps> = ({ mode }) => {
  const [selectedName, setSelectedName] = useState<string>('Old');
  const dispatch = useDispatch();
  const sortData = useSelector(sortEmail);

  return (
    <Box>
      <Box
        bgcolor={mode === 'dark' ? globalColors?.black : globalColors.white}
        border={`1px solid ${mode === 'dark' && globalColors?.border.gray}`}
        display="flex"
        gap={'10px'}
        sx={{
          borderRadius: '30px',
          height: '40px',
          paddingLeft: '10px',
          marginTop: '0px',
        }}
      >
        <Typography margin={'auto'} fontSize={'12px'} color="#939393">
          Sort
        </Typography>
        <Select
          // value={selectedName}
          value={sortData}
          onChange={(event) => {
            // setSelectedName(event.target.value);
            dispatch(sortEmailFtn(event.target.value));
          }}
          sx={{
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            fontSize: '12px',
            '& .MuiSelect-icon': {
              color: mode === 'dark' ? globalColors.white : globalColors.black,
              backgroundColor: mode === 'dark' ? globalColors?.black : globalColors.white,
            },
            '.MuiOutlinedInput-notchedOutline': { borderStyle: 'none' },
          }}
        >
          {names.map((name) => (
            <MenuItem 
              sx={{
                border: 'none', 
                backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white, 
                color: mode === 'dark' ? globalColors.white : globalColors.black,  
                '&:hover': {
                  color: mode === 'dark' ? globalColors.black : globalColors.white,
                  backgroundColor: mode === 'dark' ? globalColors.white : globalColors.black, 
                },
              }}
              key={name} value={name} className={mode === 'dark' ? 'bgColorFilter' : 'bgColorFilterLight'}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default SortTable;
