import React from 'react';
import Lottie from 'react-lottie';
import noPermissions from '../../assets/lottifile/noPermissions.json';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { globalColors } from '../../utils/constants/color';
import { Button } from '../../components/atoms';
import { useNavigate } from 'react-router-dom';
import { selectColor, selectThemeMode } from '../../store/selector';
import { useDispatch } from 'react-redux';
import { setOpenSettings } from '../../store/routes';
// import UserRepository from '../../utils/api/repositories/userRepository';

interface NoPermissionProps {
  size?: string;
}

const NoPermission: React.FC<NoPermissionProps> = ({size = 'large'}) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [showButton, setShowButton] = useState(false);
  const notfoundOptions = {
    loop: true,
    autoplay: true,
    animationData: noPermissions,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // const checkUserType = () => {
  //   const userRepository = new UserRepository();
  //   const currentUser = userRepository.getCurrentUser();
  //   if (currentUser) {
  //     if (currentUser.get('role') === 'manager' || currentUser.get('role') === 'admin') {
  //       setShowButton(true);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   checkUserType();
  // }, [])

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      gap={'10px'}
      justifyContent={'center'}
      flexDirection={'column'}
      alignContent={'center'}
      // height={'90vh'}
      padding={'2rem'}
    >
      <Lottie options={notfoundOptions} width={size === 'large' ? 250 : 150} height={size === 'large' ? 250 : 150} />
      <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} sx={{ textAlign: 'center' }} fontSize={'12px'}>
        Looks like you don't have the necessary permissions for this action. Please contact your company admin to change this.
      </Typography>
      <Typography color={globalColors.gray} sx={{ textAlign: 'center', fontSize: '10px', paddingTop: '20px' }}>
        If you believe this is an error, please click{' '}
        <Typography
          onClick={() => {
            dispatch(setOpenSettings());
            navigate('/settings/support');
          }}
          component={'span'}
          sx={{ color: color || globalColors.blue, textDecoration: 'none', cursor: 'pointer', fontSize: '10px' }}
        >
          here
        </Typography>{' '}
        to contact support.
      </Typography>
    </Box>
  );
};

export default NoPermission;
