import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './leads.css';
import { Box } from '@mui/material';
import { useProjects, useProperties } from '../../../hooks';
import ProjectCard from './project/projectCards';
import ListHistory from './history/listHistory';
import { useEffect } from 'react';
import { selectThemeMode } from '../../../store/selector';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../utils/constants/color';

export default function BasicAccordion() {
  const mode = useSelector(selectThemeMode);
  const { projects } = useProjects();
  const { properties } = useProperties();
  const elements = document.getElementsByClassName('css-1elwnq4-MuiPaper-root-MuiAccordion-root');

  useEffect(() => {
    for (let elem = 0; elem < elements.length; elem++) {
      const element = elements[elem] as HTMLElement;
      if (mode === 'dark') {
        element?.style.setProperty('--background-color', `${globalColors.blackLight}`);
        element?.style.setProperty('--text-color', `${globalColors.white}`);
      } else {
        element?.style.setProperty('--background-color', `${globalColors.whiteLight}`);
        element?.style.setProperty('--text-color', `${globalColors.black}`);
      }
    }
  }, [mode]);

  return (
    <div>
      {/* Property */}
      <Accordion sx={{ borderRadius: '20px !important', backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight, marginBottom: '2rem' }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>Properties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: { xl: '1fr 1fr 1fr', lg: '1fr 1fr 1fr', md: '1fr 1fr 1fr', sm: '1fr', xs: '1fr' },
              gap: '1rem',
            }}
            className="content"
          >
            
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Project */}
      <Accordion sx={{ borderRadius: '20px !important', backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight, marginBottom: '2rem' }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Projects</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: { xl: '1fr 1fr 1fr', lg: '1fr 1fr 1fr', md: '1fr 1fr 1fr', sm: '1fr', xs: '1fr' },
              gap: '1rem',
            }}
            className="content"
          >
            {projects?.map((data) => (
              <ProjectCard key={data.id} data={data} active={true} />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Transaction History */}
      <Accordion sx={{ borderRadius: '20px !important', backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight, marginBottom: '2rem' }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Transaction History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ListHistory />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
