import { Box, Button, Divider, InputLabel, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from '../../atoms/loader';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import { globalColors } from '../../../utils/constants/color';
import moment from 'moment';
import { FieldInput, Toast } from '../../atoms';
import { Form, Formik, FormikHelpers } from 'formik';
import NoteModel from '../../../utils/api/models/noteModel';
import LeadModel from '../../../utils/api/models/leadModel';
import { BsCameraVideo, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import Dropdown from '../../atoms/dropdown';
import Dropdowndata from '../../atoms/dropdown2';
import ProjectRepository from '../../../utils/api/repositories/projectRepository';
import PropertyRepository from '../../../utils/api/repositories/propertyRepository';
import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProperty } from '../../../store/property';
import GroupInput from '../../molecules/groupInput';
import UserModel from '../../../utils/api/models/userModel';
import SalesExecutiveRepository from '../../../utils/api/repositories/salesExecutiveRepository';
import { UserRole } from '../../../store/user';
import UserRepository from '../../../utils/api/repositories/userRepository';
import ProjectComponentModel from '../../../utils/api/models/projectComponentModel';
import AttachmentUpload from './AttachmentUpload';
import { crossFileContainerStyle } from './leadsForm.style';
import FileIcon from './FileIcon';
import ClearIcon from '@mui/icons-material/Clear';
import { ThemeProvider } from '@mui/styles';
import { confirmFilesTotalSize } from '../../../utils/helpers';
import Paginator from '../../atoms/paginator';
import { MdPeopleOutline } from 'react-icons/md';

interface ViewAllCommentsProp {
  leadId: string;
  mode: string;
  color: string;
  changeStatus?: () => Promise<void>;
  onClose: () => void;
}
interface ValuesNote {
  convertedOn: string;
  propertyProject: string;
  projectComponent: string;
  sellingPrice: string;
  soldAt: string;
  soldBy: string;
  note: string;
  date: string;
  objectId: string;
  discountPercentage: string;
  nature: string;
  paymentMethod: string;
  currency: string;
}

const ViewAllCommentsAndConvert = ({ leadId, mode, color, changeStatus, onClose }: ViewAllCommentsProp) => {
  const today = new Date().toISOString().split('T')[0];

  const [loading, setloading] = useState<boolean>(true);
  const [noteElements, setNoteElements] = useState<JSX.Element[] | JSX.Element>();
  const [lead, setLead] = useState<LeadModel | null>(null);
  const [selectedMethodContact, setSelectedMethodContact] = useState('');
  const [project, setProject] = useState<IFormDetailProject[]>([]);
  const [property, setProperty] = useState<IFormDetailProperty[]>([]);
  const [projectComponents, setProjectComponents] = useState<ProjectComponentModel[]>([]);
  const [organizationMembers, setOrganizationMembers] = useState<UserModel[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleRemoveAttachment = (index: number) => {
    let tempFiles = [...selectedFiles];
    let tempFileNames = [...selectedFileNames];
    tempFiles.splice(index, 1);
    tempFileNames.splice(index, 1);
    setSelectedFiles(tempFiles);
    setSelectedFileNames(tempFileNames);
  };

  const getTotalCount = async () => {
    try {
      const repository = new LeadRepository();

      const totalCount = await repository.getNotesCount(leadId);
      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  const getAllNotes = async () => {
    try {
      setloading(true);
      const repository = new LeadRepository();

      const notes = await repository.getNotes(leadId);

      const notesOutput: JSX.Element | JSX.Element[] =
        notes.length > 0 ? (
          notes?.reverse()?.map((note, index) => {
            const body = note.get('body');
            const createdBy = note.get('createdBy');
            const createdAt = note.get('createdAt');

            return (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'0'}
                key={index}
                padding={'10px 0px'}
                borderBottom={index < notes.length - 1 ? `1px solid ${globalColors.border.gray}` : 'none'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontSize={'16px'} marginRight={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                    {body}
                  </Typography>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    fontSize={'10px'}
                    gap={'5px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    <Typography fontSize={'12px'}>{createdBy.get('firstName')}</Typography>
                    <Typography fontSize={'12px'}>{createdBy.get('lastName')}</Typography>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'} marginTop={'-4px'}>
                  <Typography color={globalColors.gray} fontSize={'12px'}>
                    {moment(createdAt)?.fromNow()}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{ alignItems: 'center', color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center', margin: '20px' }}
          >
            No Follow up Date and comments have been added yet.
          </Typography>
        );
      setNoteElements(notesOutput);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setloading(false);
    }
  };

  const getMoreNotes = async (page: number) => {
    try {
      setloading(true);
      const repository = new LeadRepository();

      const notes = await repository.getNotes(leadId, page);

      const notesOutput: JSX.Element | JSX.Element[] =
        notes.length > 0 ? (
          notes?.reverse()?.map((note, index) => {
            const body = note.get('body');
            const createdBy = note.get('createdBy');
            const createdAt = note.get('createdAt');

            return (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'0'}
                key={index}
                padding={'10px 0px'}
                borderBottom={index < notes.length - 1 ? `1px solid ${globalColors.border.gray}` : 'none'}
              >
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography fontSize={'16px'} marginRight={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                    {body}
                  </Typography>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    fontSize={'10px'}
                    gap={'5px'}
                    color={mode === 'dark' ? globalColors.white : globalColors.black}
                  >
                    <Typography fontSize={'12px'}>{createdBy.get('firstName')}</Typography>
                    <Typography fontSize={'12px'}>{createdBy.get('lastName')}</Typography>
                  </Box>
                </Box>
                <Box display={'flex'} justifyContent={'end'} marginTop={'-4px'}>
                  <Typography color={globalColors.gray} fontSize={'12px'}>
                    {moment(createdAt)?.fromNow()}
                  </Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{ alignItems: 'center', color: mode === 'dark' ? globalColors.white : globalColors.black, textAlign: 'center', margin: '20px' }}
          >
            No Follow up Date and comments have been added yet.
          </Typography>
        );
      setNoteElements(notesOutput);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setloading(false);
    }
  };

  const getFollowUpDate = async () => {
    const repository = new LeadRepository();
    if (leadId) {
      await repository
        .getModelById(leadId)
        .then((res) => {
          if (res) {
            setLead(res);
            setloading(false);
          }
        })
        .catch((err) => {
          setLead(null);
          setloading(false);
        });
    }
  };

  const getAllProjects = async () => {
    const projectRepository = new ProjectRepository();
    await projectRepository
      .getAll()
      .then((res) => {
        if (res) {
          setProject(res);
        }
      })
      .catch((err) => {
        setProject([]);
      });
  };

  const getProjectComponents = async (projectId: string) => {
    const projectRepository = new ProjectRepository();
    await projectRepository
      .getAllComponents(projectId)
      .then((res) => {
        if (res) {
          setProjectComponents(res);
        }
      })
      .catch((err) => {
        setProjectComponents([]);
      });
  };

  const getAllProperties = async () => {
    const propertyRepository = new PropertyRepository();
    await propertyRepository
      .getAll()
      .then((res) => {
        if (res) {
          setProperty(res);
        }
      })
      .catch((err) => {
        setProject([]);
      });
  };

  const getOrganizationMembers = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: UserModel[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData: UserModel = {
              objectId: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i].get('firstName'),
              lastName: managersAndSalesExecutives[i].get('lastName'),
              username: managersAndSalesExecutives[i].get('username'),
              role: managersAndSalesExecutives[i].get('role'),
              email: managersAndSalesExecutives[i].get('email'),
              phone: managersAndSalesExecutives[i].get('phone'),
            };
            users.push(userData);
          }
          setOrganizationMembers([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: UserModel[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData: UserModel = {
              objectId: salesExecutives[i].id,
              firstName: salesExecutives[i].get('firstName'),
              lastName: salesExecutives[i].get('lastName'),
              username: salesExecutives[i].get('username'),
              role: salesExecutives[i].get('role'),
              email: salesExecutives[i].get('email'),
              phone: salesExecutives[i].get('phone'),
            };
            users.push(userData);
          }
          setOrganizationMembers([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData: UserModel = {
          objectId: currentUser.id,
          firstName: currentUser.get('firstName'),
          lastName: currentUser.get('lastName'),
          username: currentUser.get('username'),
          role: currentUser.get('role'),
          email: currentUser.get('email'),
          phone: currentUser.get('phone'),
        };
        setOrganizationMembers([userData]);
      }
    }
  };

  useEffect(() => {
    getAllProjects();
    getAllProperties();
    getOrganizationMembers();
    getAllNotes();
    getFollowUpDate();
  }, [leadId]);

  return (
    <Box marginTop={'30px'} sx={{ overflow: 'auto' }} display={'flex'} flexDirection={'column'} gap={'10px'} className="scroll">
      {lead && (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography
            sx={{
              color: globalColors.lightgray,
              textAlign: 'center',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Upcoming Follow Up
          </Typography>
          <Typography
            sx={{
              color: mode === 'dark' ? color || globalColors.white : color || globalColors.black,
              textAlign: 'center',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {lead.followUpDate ?? 'None'}
          </Typography>
        </Box>
      )}
      <Divider sx={{ backgroundColor: globalColors.border.gray }} />
      {!loading && (
        <ChangeStatusForm>
          <Formik
            initialValues={{
              convertedOn: '',
              propertyProject: '',
              projectComponent: '',
              sellingPrice: '',
              soldAt: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
                .getDate()
                .toString()
                .padStart(2, '0')}`,
              soldBy: '',
              note: '',
              objectId: leadId || '',
              date: today,
              paymentMethod: 'Select',
              nature: 'Select',
              currency: 'Select',
              discountPercentage: lead?.discountPercentage?.toString() ?? '',
            }}
            validate={(values) => {
              const errors = {} as ValuesNote;

              if (values.convertedOn === 'Select' || !values.convertedOn) {
                errors.convertedOn = 'Required';
              }

              if (values.propertyProject === '') {
                errors.propertyProject = 'Required';
              }

              if (values.convertedOn === 'Project' && projectComponents.length > 0 && values.projectComponent === '') {
                errors.projectComponent = 'Required';
              }

              if (values.soldBy === '') {
                errors.soldBy = 'Required';
              }

              if (!values.sellingPrice || parseFloat(values.sellingPrice) <= 0) {
                errors.sellingPrice = 'Required';
              }

              if (!values.paymentMethod || values.paymentMethod === 'Select') {
                errors.paymentMethod = 'Required';
              }

              if (!values.currency || values.currency === 'Select') {
                errors.currency = 'Required';
              }

              if (!values.nature || values.nature === 'Select') {
                errors.nature = 'Required';
              }

              if (values.date === '' || values.note.trim() === '' || selectedMethodContact === '') {
                errors.note = 'Please provide all three: next follow up date, a comment and a method of contact';
              }
              return errors;
            }}
            onSubmit={async (values: ValuesNote, { setSubmitting, resetForm }: FormikHelpers<ValuesNote>) => {
              if (confirmFilesTotalSize(selectedFiles)) {
                if (values.note.trim() === '') {
                  setSubmitting(false);
                } else {
                  const note: NoteModel = { body: values.note, leadId: values?.objectId };
                  const leadRepository = new LeadRepository();
                  const savedLead = await leadRepository.convertLead(
                    values?.objectId,
                    values.convertedOn,
                    values.propertyProject,
                    values.projectComponent,
                    values.soldBy,
                    parseFloat(values.sellingPrice),
                    values.soldAt,
                    values.date,
                    selectedMethodContact,
                    note,
                    selectedFiles,
                    parseFloat(values.discountPercentage),
                    values.nature,
                    values.paymentMethod,
                    values.currency
                  );
                  if (savedLead) {
                    Toast('Conversion has been sent to admin for approval successfully.', 'success');
                    getAllNotes();
                    getFollowUpDate();
                    // if (changeStatus) {
                    //   await changeStatus();
                    // }
                    onClose();
                  } else {
                    Toast('Follow up could not be added successfully. Please try again.', 'error');
                  }
                  resetForm();
                  setSelectedMethodContact('');
                  setSubmitting(false);
                }
              } else {
                Toast('You have selected too many files. The max total allowed size of the files is 40MB.', 'error');
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <>
                <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  {/* CONVERSION DETAILS */}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <InputLabel
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '32px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                      }}
                      htmlFor="nameInput"
                    >
                      Proof of Payment
                    </InputLabel>
                    <AttachmentUpload
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      selectedFileNames={selectedFileNames}
                      setSelectedFileNames={setSelectedFileNames}
                    />
                  </Box>

                  {/* Selected File */}
                  <Box>
                    {selectedFiles.length > 0 && (
                      <Box sx={{ marginTop: '2%' }}>
                        <ThemeProvider
                          theme={{
                            palette: {
                              primary: {
                                main: '#007FFF',
                                dark: '#0066CC',
                              },
                            },
                          }}
                        >
                          <Box display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
                            {selectedFileNames.map((fileName, index) => {
                              return (
                                <Box sx={crossFileContainerStyle({ selectedFiles, color, mode })}>
                                  <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <FileIcon />
                                    <Typography
                                      noWrap
                                      style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        lineHeight: '32px',
                                        letterSpacing: '0px',
                                        textAlign: 'left',
                                        color: mode === 'dark' ? globalColors.lightgray : globalColors.blackLight,
                                      }}
                                    >
                                      {fileName.replaceAll(' ', '_').substring(0, 10)}
                                    </Typography>
                                  </Box>
                                  {/* X button to remove image */}
                                  {fileName && (
                                    <ClearIcon
                                      onClick={() => handleRemoveAttachment(index)}
                                      sx={{
                                        fontSize: 16,
                                        marginLeft: '10px',
                                        color: mode === 'dark' ? 'white' : 'black',
                                        '&:hover': {
                                          color: globalColors.red,
                                        },
                                      }}
                                    />
                                  )}
                                </Box>
                              );
                            })}
                          </Box>
                        </ThemeProvider>
                      </Box>
                    )}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap="1rem">
                    <Box>
                      <Dropdown
                        optional={false}
                        label="Converted On"
                        mode={mode}
                        id="convertedOn"
                        name="convertedOn"
                        values={['Select', 'Property', 'Project']}
                        defaultValue="Select"
                        onChange={(e) => {
                          setFieldValue('convertedOn', e.target.value);
                        }}
                      />
                      <StyledTypography>{errors?.convertedOn}</StyledTypography>
                    </Box>
                    {values.convertedOn !== 'Select' && values.convertedOn !== '' && (
                      <>
                        <Box>
                          <Dropdowndata
                            optional={false}
                            label={values.convertedOn === 'Project' ? 'Select Project' : 'Select Property'}
                            mode={mode}
                            onChange={async (e) => {
                              setFieldValue('propertyProject', e.target.value);
                              if (values.convertedOn === 'Project') {
                                await getProjectComponents(e.target.value);
                              }
                            }}
                            value={values.propertyProject}
                            id="propertyProject"
                            name="propertyProject"
                            values={[
                              { label: 'Select', value: '' },
                              ...(values.convertedOn === 'Project'
                                ? project.map((val) => ({
                                    label: val?.name,
                                    value: val?.id,
                                  }))
                                : property.map((val) => ({
                                    label: val?.name,
                                    value: val?.id,
                                  }))),
                            ]}
                            defaultValue=""
                          />
                          <StyledTypography>{errors?.propertyProject}</StyledTypography>
                        </Box>
                        {projectComponents.length > 0 && (
                          <Box>
                            <Dropdowndata
                              optional={false}
                              label={'Inventory'}
                              mode={mode}
                              id="projectComponent"
                              name="projectComponent"
                              values={[
                                { label: 'Select', value: '' },
                                ...projectComponents.map((val) => ({
                                  label: val.name ?? '',
                                  value: val.objectId ?? '',
                                })),
                              ]}
                              value={values.projectComponent}
                              onChange={async (e) => {
                                setFieldValue('projectComponent', e.target.value);
                              }}
                              defaultValue=""
                            />
                            <StyledTypography>{errors?.projectComponent}</StyledTypography>
                          </Box>
                        )}
                      </>
                    )}
                    <Box>
                      <Dropdowndata
                        optional={false}
                        label="Sold By"
                        id="soldBy"
                        name="soldBy"
                        onChange={(value) => {
                          setFieldValue('soldBy', value.target.value);
                        }}
                        value={values.soldBy}
                        mode={mode}
                        values={[
                          { label: 'Select', value: '' },
                          ...organizationMembers.map((user) => ({
                            label: `${user.firstName} ${user.lastName}`,
                            value: user.objectId ?? 'dawdwa',
                          })),
                        ]}
                        defaultValue=""
                      />
                      <StyledTypography>{errors?.soldBy}</StyledTypography>
                    </Box>
                    <GroupInput className="group-input">
                      <FieldInput
                        optional={false}
                        label="Amount Paid"
                        id="sellingPrice"
                        mode={mode}
                        name={'sellingPrice'}
                        placeholder="Amount Paid"
                        type="number"
                        min={0}
                        error={touched.sellingPrice && errors.sellingPrice ? errors.sellingPrice : false}
                      />
                      <FieldInput
                        optional={false}
                        label="Paid At"
                        id="soldAt"
                        mode={mode}
                        name="soldAt"
                        placeholder="Paid At"
                        type="date"
                        error={touched.soldAt && errors.soldAt ? errors.soldAt : false}
                      />
                    </GroupInput>
                    <GroupInput className="group-input">
                      <FieldInput
                        label="Discount (%)"
                        id="discountPercentage"
                        mode={mode}
                        name={'discountPercentage'}
                        placeholder="Discount Percentage"
                        type="number"
                        min={0}
                        error={touched.discountPercentage && errors.discountPercentage ? errors.discountPercentage : false}
                      />
                      <Dropdown
                        optional={false}
                        label="Nature"
                        mode={mode}
                        id="nature"
                        name="nature"
                        values={['Select', 'Token', 'Partial Payment', 'Full Payment']}
                        defaultValue={values.nature}
                        error={touched.nature && errors.nature ? errors.nature : false}
                      />
                    </GroupInput>
                    <GroupInput className="group-input">
                      <Dropdown
                        optional={false}
                        label="Payment Method"
                        mode={mode}
                        id="paymentMethod"
                        name="paymentMethod"
                        values={['Select', 'Bank Transfer', 'Cash', 'Cheque', 'Other']}
                        defaultValue={values.paymentMethod}
                        error={touched.paymentMethod && errors.paymentMethod ? errors.paymentMethod : false}
                      />
                      <Dropdown
                        optional={false}
                        label="Currency"
                        mode={mode}
                        id="currency"
                        name="currency"
                        values={['Select', 'PKR', 'USD', 'AED']}
                        defaultValue={values.currency}
                        error={touched.currency && errors.currency ? errors.currency : false}
                      />
                    </GroupInput>
                    {/* {lead?.discountPercentage && (
                      <Typography fontSize={'13px'} color={globalColors.gray}>
                        This lead qualifies for a <span style={{ color: color || globalColors.blue }}>{lead?.discountPercentage}%</span> discount
                      </Typography>
                    )} */}
                  </Box>
                  <FieldInput mode={mode} id="note" name="note" placeholder="Type your note here" type="text" label="Comments" optional={false} />
                  {values?.note !== '' && (
                    <>
                      <FieldInput
                        optional={false}
                        min={today}
                        value={lead?.followUpDate}
                        mode={mode}
                        id="date"
                        name="date"
                        placeholder="Add a follow-up date"
                        type="date"
                        label="Next Follow Up"
                      />
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>Method of Contact</Typography>
                          <Box display={'flex'} flexDirection={'row'} gap={'5px'} marginTop={'1rem'}>
                            <Box
                              onClick={() => {
                                if (selectedMethodContact === 'Call') {
                                  setSelectedMethodContact('');
                                } else {
                                  setSelectedMethodContact('Call');
                                }
                              }}
                              sx={{
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 50,
                                backgroundColor: selectedMethodContact === 'Call' ? color : 'transparent',
                              }}
                            >
                              <BsTelephone size={'1rem'} color={selectedMethodContact === 'Call' ? globalColors.white : color || globalColors.blue} />
                            </Box>
                            <Box
                              onClick={() => {
                                if (selectedMethodContact === 'Email') {
                                  setSelectedMethodContact('');
                                } else {
                                  setSelectedMethodContact('Email');
                                }
                              }}
                              sx={{
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 50,
                                backgroundColor: selectedMethodContact === 'Email' ? color : 'transparent',
                              }}
                            >
                              <BiEnvelope size={'1rem'} color={selectedMethodContact === 'Email' ? globalColors.white : color || globalColors.blue} />
                            </Box>
                            <Box
                              onClick={() => {
                                if (selectedMethodContact === 'Meeting') {
                                  setSelectedMethodContact('');
                                } else {
                                  setSelectedMethodContact('Meeting');
                                }
                              }}
                              sx={{
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 50,
                                backgroundColor: selectedMethodContact === 'Meeting' ? color : 'transparent',
                              }}
                            >
                              <BsCameraVideo
                                size={'1rem'}
                                color={selectedMethodContact === 'Meeting' ? globalColors.white : color || globalColors.blue}
                              />
                            </Box>
                            <Box
                              onClick={() => {
                                if (selectedMethodContact === 'Physical Meeting') {
                                  setSelectedMethodContact('');
                                } else {
                                  setSelectedMethodContact('Physical Meeting');
                                }
                              }}
                              sx={{
                                cursor: 'pointer',
                                width: '30px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 50,
                                backgroundColor: selectedMethodContact === 'Physical Meeting' ? color : 'transparent',
                              }}
                            >
                              <MdPeopleOutline
                                size={'1rem'}
                                color={selectedMethodContact === 'Physical Meeting' ? globalColors.white : color || globalColors.blue}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Button type="submit" sx={{ color: color || globalColors.blue, marginTop: '10px' }}>
                          Add
                        </Button>
                      </Box>
                      {touched.note && errors.note && (
                        <Typography color={'red'} fontSize={'12px'} marginTop={'2px'}>
                          {errors.note}
                        </Typography>
                      )}
                    </>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </ChangeStatusForm>
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          {noteElements}
          <Paginator
            getTotalCount={getTotalCount}
            onChangePage={async (newPage) => {
              setCurrentPage(newPage);
              await getMoreNotes(newPage);
              return true;
            }}
            internal
            activePage={currentPage}
          />
        </>
      )}
    </Box>
  );
};

const ChangeStatusForm = styled('div')(({ theme }) => ({
  marginBottom: '2rem',
  overflow: 'auto',
  '& .group-input': {
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'red',
  fontSize: '0.75rem',
  [theme.breakpoints.down('sm')]: {
    bottom: '-15px',
  },

  [theme.breakpoints.up('md')]: {
    bottom: '-20px',
  },
}));

export default ViewAllCommentsAndConvert;
