import { Box, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { globalColors } from '../../../utils/constants/color';
import logo from '../../../assets/Images/logo.svg';
import { useNavigate } from 'react-router';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <Box bgcolor={globalColors.mainBackground} maxHeight={'100%'} width={'100%'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>

      <Box className="bgrightshade" position={'absolute'}></Box>
      <Box
        maxHeight={'100vh'}
        // overflow={'scroll'}
        display={'flex'}
        flexDirection={'column'}
        gap={'3rem'}
        maxWidth={'lg'}
        margin={'auto'}
        padding={'3rem'}
        marginBottom={'3rem'}
      >
        <Box>
          <Box display={'flex'} justifyContent={'center'}>
            <img
              src={logo}
              style={{ zIndex: 9999, cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            ></img>
          </Box>
          <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant="h4">Terms & Conditions</Typography>
            <Typography>Effective Date: January 1, 2024</Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">1. Introduction:</Typography>
          <Typography>
            These Terms and Conditions ("Terms") govern your access and use of the propertycloud.ai website and services ("Services"). Please read
            these Terms carefully before accessing or using the Services. By accessing or using the Services, you agree to be bound by these Terms and
            all applicable laws and regulations. If you do not agree to these Terms, you may not access or use the Services.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">2. Definitions:</Typography>
          <Box paddingLeft={'10px'} display={'flex'} gap={'1rem'} flexDirection={'column'}>
            <Typography>
              &#x2022; “Content” means any information, data, text, software, code, photographs, graphics, videos, audio files, or other materials
              available through the Services.
            </Typography>
            <Typography>
              &#x2022; “Service” means the propertycloud.ai website and all of its related features, functions, and functionalities, including the
              CRM/ERP software.
            </Typography>
            <Typography>&#x2022; “Subscriber” means any individual or entity that subscribes to and uses the Services.</Typography>
            <Typography>&#x2022; “User” means any individual or entity that accesses or uses the Services, including Subscribers.</Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">3. Account Creation and Use:</Typography>
          <Typography>
            3.1. To access certain features of the Services, you may be required to create an account. You are responsible for maintaining the
            confidentiality of your account information, including your password, and for any activity that occurs under your account.
          </Typography>
          <Typography>
            3.2. You agree to provide accurate and complete information when creating your account and agree to update your information as necessary.{' '}
          </Typography>
          <Typography>
            3.3. You agree to use the Services only for lawful purposes and in accordance with these Terms. You agree not to use the Services:{' '}
          </Typography>
          <Box paddingLeft={'10px'} display={'flex'} gap={'1rem'} flexDirection={'column'}>
            <Typography>&#x2022; In any way that violates any applicable law or regulation.</Typography>
            <Typography>&#x2022; For any harmful or unlawful purpose, including but not limited to, fraud, hacking, or identity theft.</Typography>
            <Typography>&#x2022; To interfere with or disrupt the Services or any servers or networks connected to the Services.</Typography>
            <Typography>&#x2022; To impersonate any person or entity, or to falsely state or misrepresent your identity or affiliation.</Typography>
            <Typography>&#x2022; To access or use the Services for unauthorized commercial purposes.</Typography>
          </Box>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">4. Subscriptions and Payments:</Typography>
          <Typography>
            4.1. The Services are offered on a subscription basis. You must choose a subscription plan and pay the applicable fees before accessing
            certain features of the Services.
          </Typography>
          <Typography>4.2. Subscription fees are payable in advance and are non-refundable, except as expressly provided in these Terms.</Typography>
          <Typography>4.3. We reserve the right to change our subscription fees at any time, with notice to you.</Typography>
          <Typography>4.4. You are responsible for all taxes associated with your use of the Services.</Typography>
          <Typography>
            4.5 All purchases shall be considered non-refundable and non-transferable except at the discretion of the service provided in certain
            scenarios.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">5. Content:</Typography>
          <Typography>
            5.1. The Services contain Content owned by us and by third parties. You agree not to copy, modify, distribute, transmit, display, sell,
            license, or otherwise exploit any Content without our prior written consent or the consent of the respective rights holder.
          </Typography>
          <Typography>
            5.2. You are solely responsible for any Content you upload or post to the Services. You agree that you will not upload or post any Content
            that is infringing, obscene, defamatory, or otherwise unlawful.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">6. Intellectual Property:</Typography>
          <Typography>
            6.1. We own all right, title, and interest in and to the Services, including all intellectual property rights. You agree not to remove,
            alter, or obscure any copyright notices or other intellectual property notices contained in the Services.
          </Typography>
          <Typography>
            6.2. You are granted a non-exclusive, non-transferable, revocable license to use the Services in accordance with these Terms.
          </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">7. Disclaimers and Warranties:</Typography>
          <Typography>
            7.1. THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
          </Typography>
          <Typography>
            7.2. WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR COMPLETELY SECURE. WE DO NOT WARRANT THAT THE RESULTS THAT
            MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE.
          </Typography>
          <Typography>7.3. YOU ASSUME ALL RISK FOR YOUR USE OF THE SERVICES.</Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          <Typography variant="h5">8. Limitation of Liability:</Typography>
          <Typography>
            8.1. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
            DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </Typography>
          <Typography>8.2. THIS LIMITATION OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE. </Typography>
        </Box>

        <Box display={'flex'} flexDirection={'column'} gap={'1rem'} paddingBottom={'3rem'}>
          <Typography variant="h5">9. Indemnification:</Typography>
          <Typography>You agree to indemnify, defend, and hold harmless us and our officers, directors.</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
