import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import UserModel from '../models/userModel';
import SalesExecutiveRepository from './salesExecutiveRepository';
import commission from '../../../store/commission';
import { UserRole } from '../../../store/user';
import OrganizationRepository from './organizationRepository';
import { PermissionsInterface, dataLimit, extendedDataLimit } from '../../helpers';
import SalaryModel from '../models/salaryModel';
import SalaryRepository from './salaryRepository';
// import { UserUpdateModel } from '../models/updateUserModel';

class UserRepository {
  private className = '_User';
  // constructor() {}

  private handleErrors(code: number, action: string, message?: string) {
    switch (code) {
      case 101:
        return `User could not be ${action} successfully. ${message ?? ''}`;
      case 200:
        return `User could not be ${action} successfully. Please enter a valid username and try again.`;
      case 201:
        return `User could not be ${action} successfully. Please enter a valid password and try again.`;
      case 202:
        return `User could not be ${action} successfully as the username you entered is already taken. Please try again.`;
      case 203:
        return `User could not be ${action} successfully as the email you entered is already taken. Please try again.`;
      case 204:
        return `User could not be ${action} successfully. Please enter a valid email and try again.`;
      case 205:
        return `User could not be ${action} successfully. Please enter an email that has been signed up and try again.`;
      default:
        return `User could not be ${action} successfully. Please try again.`;
    }
  }

  public async signUp(object: UserModel) {
    try {
      const User = Parse.User;
      const user = new User();

      user.set('username', object.username);
      user.set('email', object.email);
      user.set('phone', object.phone);
      user.set('password', 'Abcd.1234!');
      user.set('firstName', object.firstName);
      user.set('lastName', object.lastName);
      user.set('organization', object.organization ?? null);
      user.set('profilePicture', null);
      user.set('layoutPreference', 'left');
      user.set('colorPreference', 'default');
      user.set('themePreference', 'dark');
      user.set('role', object.role);
      user.set('permissions', object.permissions);

      return new Promise((resolve, _) => {
        user.signUp(null, { useMasterKey: true }).then(
          (savedUser: any) => {
            Toast(`User signed up successfully.`, 'success');
            resolve(savedUser);
          },
          (error: any) => {
            Toast(this.handleErrors(error.code, 'signed up'), 'error');
            resolve(null);
          }
        );
      });
    } catch (e) {
      return null;
    }
  }

  public async login(credentials: string, password: string) {
    try {
      return new Promise((resolve, _) => {
        Parse.User.logIn(credentials, password, { useMasterKey: true }).then(
          (_: any) => {
            resolve(true);
          },
          (error: any) => {
            Toast(this.handleErrors(error.code, 'logged in', error.code === 101 ? error.message : null), 'error');
            resolve(false);
          }
        );
      });
    } catch (e) {
      return false;
    }
  }

  public async updatePassword(credentials: string, password: string, newPassword: string) {
    try {
      return new Promise((resolve, _) => {
        Parse.User.logIn(credentials, password, { useMasterKey: true }).then(
          async (user: Parse.User) => {
            const updatedUser = await Parse.Cloud.run('updatePassword', { newPassword: newPassword, id: user.id });
            if (updatedUser.status === 'success' && updatedUser.data !== null) {
              resolve(true);
            } else {
              resolve(false);
            }
          },
          (error: any) => {
            resolve(false);
          }
        );
      });
    } catch (e) {
      return false;
    }
  }

  public getCurrentUser() {
    try {
      const currentUser = Parse.User.current();
      return currentUser;
    } catch {
      return null;
    }
  }

  public async getCurrentUserAsync() {
    try {
      const currentUser = await Parse.User.currentAsync();
      return currentUser;
    } catch {
      return null;
    }
  }

  public async logout(): Promise<boolean> {
    try {
      return new Promise((resolve, _) => {
        Parse.User.logOut().then(
          (_: any) => {
            resolve(true);
          },
          (error: any) => {
            // Toast(this.handleErrors(error.code, 'logged out'), 'error');
            resolve(false);
          }
        );
      });
    } catch (e) {
      return false;
    }
  }

  public async updateUserName(firstName: string, lastName: string): Promise<Parse.Object | null> {
    try {
      const currentUser = this.getCurrentUser();

      if (currentUser) {
        return new Promise((resolve, _) => {
          currentUser.set('firstName', firstName);
          currentUser.set('lastName', lastName);

          currentUser
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(updatedUser);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(null);
            });
        });
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  public async updateUserLayout(layout: string) {
    try {
      const currentUser = this.getCurrentUser();

      if (currentUser) {
        return new Promise((resolve, _) => {
          currentUser.set('layoutPreference', layout);

          currentUser
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(true);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async updateUserThemeMode(theme: string) {
    try {
      const currentUser = this.getCurrentUser();

      if (currentUser) {
        return new Promise((resolve, _) => {
          currentUser.set('themePreference', theme);

          currentUser
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(true);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async updateUserThemeColor(color: string) {
    try {
      const currentUser = this.getCurrentUser();

      if (currentUser) {
        return new Promise((resolve, _) => {
          currentUser.set('colorPreference', color);

          currentUser
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(true);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async getUsersByOrganizationId(organizationId: string) {
    const User = Parse.User;
    const query = new Parse.Query(User).limit(extendedDataLimit);

    try {
      query.equalTo('organization', organizationId);
      const users = await query.find({ useMasterKey: true });
      return users;
    } catch (error) {
      console.error('Error fetching users by organization ID:', error);
      return null;
    }
  }

  public async getCurrentOrganization() {
    const currentUser = this.getCurrentUser();

    try {
      if (currentUser) {
        const organizationRepository = new OrganizationRepository();
        const organization = await organizationRepository.getObjectById(currentUser.get('organization'));
        return organization;
        // const User = Parse.User;
        // const query = new Parse.Query(User);
        // query.equalTo('objectId', currentUser.id);
        // const user = await query.first({ useMasterKey: true });
        // if (user) {
        //   return user.get('organization');
        // }
        // return null;
      } else {
        return null;
      }
    } catch (error) {}
  }

  public async getAllUsersForOrganization(): Promise<UserModel[] | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization');
        const User = Parse.Object.extend(this.className);
        const query = new Parse.Query(User)
          .equalTo('organization', organizationId)
          .notEqualTo('objectId', currentUser.id)
          .descending('role')
          .limit(extendedDataLimit);
        const queryUser = await query.find({ useMasterKey: true });

        const users: UserModel[] = [];
        await Promise.all(
          queryUser.map(async (user: any) => {
            const userData = user.toJSON();

            const data = {
              objectId: userData.objectId,
              username: userData.username ?? '-',
              email: userData.email ?? '-',
              phone: userData.phone ?? '-',
              firstName: userData.firstName ?? '-',
              lastName: userData.lastName ?? '-',
              commissionPercentage: userData.commissionPercentage,
              commissionPriority: userData.commissionPriority,
              role: userData.role,
              permissions: userData.permissions ?? [],
            } as UserModel;

            users.push(data);
          })
        );

        return users;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getAllManagersForOrganization(): Promise<Parse.Object[] | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization');
        const User = Parse.Object.extend(this.className);
        const query = new Parse.Query(User)
          .equalTo('organization', organizationId)
          .notEqualTo('objectId', currentUser.id)
          .equalTo('role', 'manager')
          .limit(extendedDataLimit);
        const queryUser = await query.find({ useMasterKey: true });
        return queryUser;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getAllExecutivesForOrganization(): Promise<Parse.Object[] | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization');
        const User = Parse.Object.extend(this.className);
        const query = new Parse.Query(User)
          .equalTo('organization', organizationId)
          .notEqualTo('objectId', currentUser.id)
          .equalTo('role', 'executive')
          .limit(extendedDataLimit);
        const queryUser = await query.find({ useMasterKey: true });
        return queryUser;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getAllUsersForOrganizationIncludingCurrentUser(queryFilters?: {
    role?: string;
    commissionPriority?: string;
    commissionLow?: number;
    commissionHigh?: number;
    search?: string;
    orderBy?: string;
  }): Promise<UserModel[] | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization');
        const User = Parse.Object.extend(this.className);
        var query = new Parse.Query(User).ascending('role').limit(extendedDataLimit);

        if (queryFilters) {
          if (queryFilters.search && queryFilters.search !== '') {
            const firstNameQuery = new Parse.Query(User).matches('firstName', RegExp(queryFilters.search), 'i');
            const lastNameQuery = new Parse.Query(User).matches('lastName', RegExp(queryFilters.search), 'i');
            query = Parse.Query.or(firstNameQuery, lastNameQuery);
          }
          if (queryFilters.role) {
            if (queryFilters.role === 'Admin') {
              query.equalTo('role', UserRole.admin);
            } else if (queryFilters.role === 'Manager') {
              query.equalTo('role', UserRole.manager);
            } else if (queryFilters.role === 'Executive') {
              query.equalTo('role', UserRole.executive);
            }
          }
          if (queryFilters.commissionPriority) {
            if (queryFilters.commissionPriority !== 'Select') {
              query.equalTo('commissionPriority', queryFilters.commissionPriority);
            }
          }
          if (queryFilters.commissionLow) {
            query.greaterThanOrEqualTo('commissionPercentage', queryFilters.commissionLow);
          }
          if (queryFilters.commissionHigh) {
            query.lessThanOrEqualTo('commissionPercentage', queryFilters.commissionHigh);
          }
          if (queryFilters.orderBy) {
            if (queryFilters.orderBy === 'Ascending') {
              query.ascending('commissionPercentage');
            } else if (queryFilters.orderBy === 'Descending') {
              query.descending('commissionPercentage');
            }
          }
        }
        query.equalTo('organization', organizationId);
        const queryUser = await query.find({ useMasterKey: true });

        const users: UserModel[] = [];
        await Promise.all(
          queryUser.map(async (user: any) => {
            const userData = user.toJSON();

            const data = {
              objectId: userData.objectId,
              username: userData.username ?? '-',
              email: userData.email ?? '-',
              phone: userData.phone ?? '-',
              firstName: userData.firstName ?? '-',
              lastName: userData.lastName ?? '-',
              commissionPercentage: userData.commissionPercentage,
              commissionPriority: userData.commissionPriority,
              role: userData.role,
              notes: userData.notes,
            } as UserModel;

            users.push(data);
          })
        );

        return users;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getSalesExecutives(user?: Parse.User | null): Promise<UserModel[] | null> {
    try {
      if (!user) {
        return [];
      }

      const User = Parse.Object.extend(this.className);
      let query = new Parse.Query(User).ascending('createdAt').limit(extendedDataLimit);
      let queryAssignToSelf = new Parse.Query(User).limit(extendedDataLimit);

      const userRole = user.get('role');
      const users: UserModel[] = [];
      if (userRole === UserRole.admin) {
        const userOrganization = user.get('organization');
        query.equalTo('organization', userOrganization);
      } else if (userRole === UserRole.manager) {
        // query.equalTo('assignedTo', user);
        // query.containedIn('assignedTo', user.get('managing'));

        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          // const users: UserModel[] = [];

          salesExecutives.map(async (executive: any) => {
            const data = {
              objectId: executive.get('user').id,
              username: executive.get('user').get('username') ?? '-',
              email: executive.get('user').get('email') ?? '-',
              phone: executive.get('user').get('phone') ?? '-',
              firstName: executive.get('user').get('firstName') ?? '-',
              lastName: executive.get('user').get('lastName') ?? '-',
            } as UserModel;

            users.push(data);
          });

          queryAssignToSelf.equalTo('objectId', user?.id);
          const getAssignToSelf = await queryAssignToSelf.find({ useMasterKey: true });
          await Promise.all(
            getAssignToSelf.map(async (user: any) => {
              const userData = user.toJSON();

              const data = {
                objectId: userData.objectId,
                username: userData.username ?? '-',
                email: userData.email ?? '-',
                phone: userData.phone ?? '-',
                firstName: userData.firstName ?? '-',
                lastName: userData.lastName ?? '-',
              } as UserModel;

              users.push(data);
            })
          );

          return users;
        }
      } else if (userRole === UserRole.executive) {
        query.equalTo('objectId', user?.id);
      } else {
        return [];
      }

      // query.contains('objectId', user?.id);
      // query.orWhere('role', 'salesExecutive');
      const getUsers = await query.find({ useMasterKey: true });
      await Promise.all(
        getUsers.map(async (user: any) => {
          const userData = user.toJSON();

          const data = {
            objectId: userData.objectId,
            username: userData.username ?? '-',
            email: userData.email ?? '-',
            phone: userData.phone ?? '-',
            firstName: userData.firstName ?? '-',
            lastName: userData.lastName ?? '-',
          } as UserModel;

          users.push(data);
        })
      );

      return users;
    } catch (error) {
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const User = Parse.Object.extend(this.className);
      const query = new Parse.Query(User);
      query.include('profilePicture');
      const user = await query.get(id, { useMasterKey: true });

      return user;
    } catch (error) {
      // console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getObjectByEmail(email: string): Promise<Parse.Object | null> {
    try {
      const User = Parse.Object.extend(this.className);
      const query = new Parse.Query(User).equalTo('email', email).include('organization.logo', 'organization');
      const user = await query.first({ useMasterKey: true });
      if (user) {
        return user;
      }
      return null;
    } catch (error) {
      // console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getAllManagersAndSalesExecutives(): Promise<Parse.Object[] | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const User = Parse.Object.extend(this.className);
        const query = new Parse.Query(User).equalTo('organization', currentUser.get('organization').id).limit(extendedDataLimit);

        return new Promise<Parse.Object[] | null>((resolve, _) => {
          query.find({ useMasterKey: true }).then(
            (managersAndSalesExecutives: any) => {
              resolve(managersAndSalesExecutives);
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async getAllManagers(): Promise<Parse.Object[] | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const User = Parse.Object.extend(this.className);
        const query = new Parse.Query(User)
          .equalTo('organization', currentUser.get('organization').id)
          .equalTo('role', 'manager')
          .limit(extendedDataLimit);

        return new Promise<Parse.Object[] | null>((resolve, _) => {
          query.find({ useMasterKey: true }).then(
            (managers: any) => {
              resolve(managers);
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fetching user by ID:', error);
      return null;
    }
  }

  public async updateUserCommissionByObjectId(objectId: string, commission: { percentage: number; priority: string }) {
    try {
      const user = await this.getObjectById(objectId);

      if (user) {
        return new Promise((resolve, _) => {
          user.set('commissionPercentage', commission.percentage);
          user.set('commissionPriority', commission.priority);

          user
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(true);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async getNotificationSettings(): Promise<{ medium: boolean; low: boolean } | null> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const User = Parse.User;
        const query = new Parse.Query(User);
        query.equalTo('objectId', currentUser.id);
        const user = await query.first({ useMasterKey: true });
        if (user) {
          return user.get('notificationSettings');
        }
        return null;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async updateNotificationSettings(settings: { medium: boolean; low: boolean }): Promise<boolean> {
    try {
      const currentUser = this.getCurrentUser();

      if (currentUser) {
        return new Promise((resolve, _) => {
          currentUser.set('notificationSettings', settings);

          currentUser
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(true);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async addNoteForUser(note: { createdAt: string; createdBy: string; note: string }, objectId: string): Promise<boolean> {
    try {
      const currentUser = this.getCurrentUser();
      const user = await this.getObjectById(objectId);

      if (user && currentUser) {
        return new Promise((resolve, _) => {
          user.add('notes', note);

          user
            .save(null, { useMasterKey: true })
            .then((updatedUser) => {
              resolve(true);
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async getNotesForUser(objectId: string): Promise<any[] | null> {
    try {
      const user = await this.getObjectById(objectId);
      if (user) {
        if (user.get('notes') == null) {
          return [];
        }
        return user.get('notes');
      } else {
        return [];
      }
    } catch (error) {
      return null;
    }
  }

  public async updatePermissions(id: string, permissions: PermissionsInterface[]): Promise<boolean> {
    try {
      const user = await this.getObjectById(id);

      if (user) {
        return new Promise((resolve, _) => {
          user.set('permissions', permissions);

          user
            .save(null, { useMasterKey: true })
            .then(async (updatedUser) => {
              const organization = await this.getCurrentOrganization();
              if (organization) {
                if (updatedUser.get('role') === UserRole.manager) {
                  organization.set('managerGenericPermissions', false);
                } else if (updatedUser.get('role') === UserRole.executive) {
                  organization.set('executiveGenericPermissions', false);
                }
                const savedOrganization = await organization.save(null, { useMasterKey: true });
                if (savedOrganization) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              } else {
                resolve(false);
              }
            })
            .catch((error) => {
              console.error('Error updating user data:', error);
              resolve(false);
            });
        });
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  public async update(object: UserModel, objectId: string): Promise<Parse.Object | null> {
    try {
      // console.log(object);
      const userObject = await this.getObjectById(objectId);
      if (userObject) {
        if (object.firstName) {
          userObject.set('firstName', object.firstName);
        }
        if (object.lastName) {
          userObject.set('lastName', object.lastName);
        }
        if (object.phone) {
          userObject.set('phone', object.phone);
        }
        if (object.commissionPriority && object.commissionPriority !== 'Select') {
          userObject.set('commissionPriority', object.commissionPriority);
        }
        if (object.commissionPercentage && object.commissionPercentage >= 0) {
          userObject.set(
            'commissionPercentage',
            typeof object.commissionPercentage === 'string' ? parseFloat(object.commissionPercentage) : object.commissionPercentage
          );
        }
        if (object.salary && object.salary >= 0) {
          userObject.set('salary', typeof object.salary === 'string' ? parseFloat(object.salary) : object.salary);
        }
        if (object.tags) {
          userObject.set('tags', object.tags);
        }

        return new Promise((resolve, _) => {
          userObject.save(null, { useMasterKey: true }).then(
            (savedUser: any) => {
              resolve(savedUser);
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error updating user:', error);
      return null;
    }
  }

  public async addSalarySlip(salary: SalaryModel, objectId: string): Promise<boolean> {
    try {
      const user = await this.getObjectById(objectId);
      if (user) {
        const salaryRepository = new SalaryRepository();
        const newSalary = await salaryRepository.create(salary);
        if (newSalary) {
          user.relation('salaryHistory').add(newSalary);
          const savedUser = await user.save(null, { useMasterKey: true });
          if (savedUser) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  public async getSalarySlipsForUserCount(objectId: string): Promise<number> {
    try {
      const user = await this.getObjectById(objectId);
      if (user) {
        const salaryRelation = user.relation('salaryHistory').query().descending('createdAt');
        const salaries = await salaryRelation.count();
        return salaries;
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  public async getSalarySlipsForUser(objectId: string, page?: number): Promise<Parse.Object[] | null> {
    try {
      const user = await this.getObjectById(objectId);
      if (user) {
        const salaryRelation = user.relation('salaryHistory').query().descending('createdAt');

        if (page) {
          salaryRelation.skip((page - 1) * dataLimit);
        }
        const salaries = await salaryRelation.find();
        return salaries;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default UserRepository;
