import React, { FC, SVGProps } from 'react';
import { globalColors } from '../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../store/selector';
interface SettingIconProps extends SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  stroke?: string;
}

const SettingUploadIcon: FC<SettingIconProps> = ({ width, height, stroke, ...rest }) => {
  const mode = useSelector(selectThemeMode);
  return (
    <svg  width="85" height="78" viewBox="0 0 85 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9999 55.001C-6.00009 58.001 -3.00009 25.001 20.9999 28.001C11.9999 -4.99903 62.9999 -4.99903 59.9999 19.001C89.9999 10.001 89.9999 58.001 62.9999 55.001M26.9999 43.001L41.9999 31.001M41.9999 31.001L56.9999 43.001M41.9999 31.001V76.001"
        stroke={mode==="dark" ? "#F7F7F7": globalColors.black}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill={mode==="dark" ? globalColors.blackLight : globalColors.lightgray}
      />
    </svg>
  );
};

export default SettingUploadIcon;