import Parse from 'parse';
import UserRepository from './userRepository';
import LeadConversionRequestModel from '../models/leadConversionRequestModel';
import FileRepository from './fileRepository';
import FileModel from '../models/fileModel';
import LeadRepository from './leadRepository';
import ProjectRepository from './projectRepository';
import PropertyRepository from './propertyRepository';
import ProjectComponentRepository from './projectComponentRepository';
import ManagerRepository from './managerRepository';
import { UserRole } from '../../../store/user';
import SalesExecutiveRepository from './salesExecutiveRepository';
import { count } from 'console';
import { dataLimit } from '../../helpers';
import LeadClientPaymentModel from '../models/leadClientPaymentModel';
import LeadClientPaymentRepository from './leadClientPaymentRepository';

class LeadConversionRequestRepository {
  private className = 'LeadConversionRequest';

  public async create(object: LeadConversionRequestModel): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const ConversionRequest = Parse.Object.extend(this.className);
        const conversionRequest = new ConversionRequest();

        conversionRequest.set('convertedBy', currentUser);
        conversionRequest.set('convertedOn', object.convertedOn);
        conversionRequest.set('currency', object.currency);
        conversionRequest.set('discountPercentage', object.discountPercentage);
        conversionRequest.set('lead', object.lead);
        conversionRequest.set('nature', object.nature);
        conversionRequest.set('organization', currentUser.get('organization'));
        conversionRequest.set('paymentMethod', object.paymentMethod);
        conversionRequest.set('project', object.project);
        conversionRequest.set('projectComponent', object.projectComponent);
        conversionRequest.set('property', object.property);
        conversionRequest.set('sellingPrice', object.sellingPrice);
        conversionRequest.set('soldAt', object.soldAt);
        conversionRequest.set('soldBy', object.soldBy);

        if (object.attachments && object.attachments.length > 0) {
          const fileRepository = new FileRepository();
          let savedFiles: any[] = [];
          const attachmentFiles = object.attachments as File[];
          await Promise.all(
            attachmentFiles.map(async (file) => {
              const fileModel: FileModel = {
                file: file,
              };
              const savedFile = await fileRepository.create(fileModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            })
          );
          const attachmentsRelation = conversionRequest.relation('attachments');
          attachmentsRelation.add(savedFiles);
        }

        return new Promise((resolve, _) => {
          conversionRequest
            .save(null, { useMasterKey: true })
            .then((savedRequest: Parse.Object | null) => {
              resolve(savedRequest);
            })
            .catch((error: Error) => {
              resolve(null);
            });
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error creating conversion request:', error);
      return null;
    }
  }

  public async getAllCount(searchName?: string | null): Promise<number> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const LeadConversionRequest = Parse.Object.extend(this.className);
      const Lead = Parse.Object.extend('Lead');
      var query = new Parse.Query(LeadConversionRequest).descending('createdAt').equalTo('organization', currentUser.get('organization'));

      if (searchName) {
        const regex = new RegExp(searchName, 'i');

        const firstNameQuery = new Parse.Query(Lead);
        firstNameQuery.matches('firstName', regex);

        const lastNameQuery = new Parse.Query(Lead);
        lastNameQuery.matches('lastName', regex);

        var leadQuery = Parse.Query.or(firstNameQuery, lastNameQuery);
        query.matchesQuery('lead', leadQuery);
      }

      try {
        const requests = await query.count({ useMasterKey: true });
        return requests;
      } catch (error) {
        console.error('Error fetching lead conversion requests:', error);
        return 0;
      }
    } else {
      return 0;
    }
  }

  public async getAll(searchName?: string | null, page?: number): Promise<LeadConversionRequestModel[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const userRole = currentUser.get('role');
      const LeadConversionRequest = Parse.Object.extend(this.className);
      const Lead = Parse.Object.extend('Lead');
      var query = new Parse.Query(LeadConversionRequest)
        .descending('createdAt')
        .equalTo('organization', currentUser.get('organization'))
        .limit(dataLimit);

      if (searchName) {
        const regex = new RegExp(searchName, 'i');

        const firstNameQuery = new Parse.Query(Lead);
        firstNameQuery.matches('firstName', regex);

        const lastNameQuery = new Parse.Query(Lead);
        lastNameQuery.matches('lastName', regex);

        var leadQuery = Parse.Query.or(firstNameQuery, lastNameQuery);
        query.matchesQuery('lead', leadQuery);
      }

      query.include('lead', 'lead.assignedTo', 'property', 'project', 'projectComponent', 'convertedBy', 'soldBy');

      if (page) {
        query.skip((page - 1) * dataLimit);
      }

      try {
        const requests = await query.find({ useMasterKey: true });

        const leadConversionRequestModels = requests.map((request: Parse.Object) => {
          const conversionRequest: LeadConversionRequestModel = {
            objectId: request.id,
            convertedBy: request.get('convertedBy'),
            convertedOn: request.get('convertedOn'),
            currency: request.get('currency'),
            discountPercentage: request.get('discountPercentage'),
            lead: request.get('lead'),
            nature: request.get('nature'),
            paymentMethod: request.get('paymentMethod'),
            project: request.get('project'),
            projectComponent: request.get('projectComponent'),
            property: request.get('property'),
            sellingPrice: request.get('sellingPrice'),
            soldAt: request.get('soldAt'),
            soldBy: request.get('soldBy'),
          };

          return conversionRequest;
        });

        return leadConversionRequestModels;
      } catch (error) {
        console.error('Error fetching lead conversion requests:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getModelById(id: string): Promise<LeadConversionRequestModel | null> {
    const LeadConversionRequest = Parse.Object.extend(this.className);
    const query = new Parse.Query(LeadConversionRequest).include([
      'lead.assignedTo',
      'soldBy',
      'convertedBy',
      'project',
      'property',
      'projectComponent',
    ]);

    try {
      const request = await query.get(id, { useMasterKey: true });

      if (request) {
        const conversionRequest: LeadConversionRequestModel = {
          objectId: request.id,
          createdAt: request.get('createdAt'),
          convertedBy: request.get('convertedBy'),
          convertedOn: request.get('convertedOn'),
          currency: request.get('currency'),
          discountPercentage: request.get('discountPercentage'),
          lead: request.get('lead'),
          nature: request.get('nature'),
          paymentMethod: request.get('paymentMethod'),
          organization: request.get('organization'),
          project: request.get('project'),
          projectComponent: request.get('projectComponent'),
          property: request.get('property'),
          sellingPrice: request.get('sellingPrice'),
          soldAt: request.get('soldAt'),
          soldBy: request.get('soldBy'),
        };
        return conversionRequest;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching conversion request by ID:', error);
      return null;
    }
  }

  public async getAllAttachments(id: string): Promise<Parse.Object[] | null> {
    const LeadConversionRequest = Parse.Object.extend(this.className);
    const query = new Parse.Query(LeadConversionRequest);
    try {
      const request = await query.get(id, { useMasterKey: true });
      if (request) {
        const attachmentsQuery = request.relation('attachments').query().include('file').descending('createdAt');
        return new Promise((resolve, _) => {
          attachmentsQuery.find({ useMasterKey: true }).then(
            (attachments) => {
              if (attachments) {
                resolve(attachments);
              } else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async approveRequest(
    id: string,
    lockDetails?: { lockAmount: string; lockMetric: string; paymentMethod: string; currency: string; nature: string }
  ): Promise<boolean> {
    const LeadConversionRequest = Parse.Object.extend(this.className);
    const query = new Parse.Query(LeadConversionRequest).include([
      'lead.assignedTo',
      'soldBy',
      'convertedBy',
      'project',
      'property',
      'projectComponent',
    ]);

    try {
      const request = await query.get(id, { useMasterKey: true });

      if (request) {
        const leadRepository = new LeadRepository();
        const lead = await leadRepository.getObjectById(request.get('lead'));
        if (lead) {
          lead.set('status', 'Converted');
          lead.set('priority', 'Cold');
          if (request.get('convertedOn') === 'Property') {
            lead.set('category', 'Property');
            lead.set('property', request.get('property'));
          } else if (request.get('convertedOn') === 'Project') {
            lead.set('category', 'Project');
            lead.set('project', request.get('project'));
            if (request.get('projectComponent')) {
              lead.set('projectComponent', request.get('projectComponent'));
            }
          }

          const attachments = await request.relation('attachments').query().find({ useMasterKey: true });

          if (attachments) {
            for (let i = 0; i < attachments.length; i++) {
              const leadAttachments = lead.relation('attachments');
              leadAttachments.add(attachments[i]);
            }
          }

          const savedLead = await lead.save(null, { useMasterKey: true });
          if (savedLead) {
            const project = request.get('project');
            const projectComponent = request.get('projectComponent');
            const property = request.get('property');
            let lockedUntil: null | Date = null;
            if (lockDetails) {
              if (lockDetails.lockMetric === 'hour(s)') {
                lockedUntil = new Date();
                lockedUntil.setHours(lockedUntil.getHours() + parseInt(lockDetails.lockAmount));
              } else if (lockDetails.lockMetric === 'day(s)') {
                lockedUntil = new Date();
                lockedUntil.setDate(lockedUntil.getDate() + parseInt(lockDetails.lockAmount));
              } else {
                lockedUntil = new Date();
                lockedUntil.setMonth(lockedUntil.getMonth() + parseInt(lockDetails.lockAmount));
              }
            }
            if (project) {
              project.set('status', 'Sold');
              project.set('soldAt', new Date(request.get('soldAt')));
              project.set('soldBy', request.get('soldBy'));
              if (request.get('soldBy').get('role') === UserRole.executive) {
                const salesExecutiveRepository = new SalesExecutiveRepository();
                const salesExecutive = await salesExecutiveRepository.getObjectByUserId(request.get('soldBy').id);
                if (salesExecutive) {
                  project.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                }
              } else if (request.get('soldBy').get('role') === UserRole.manager) {
                const managerRepository = new ManagerRepository();
                const manager = await managerRepository.getObjectByUserId(request.get('soldBy').id);
                if (manager) {
                  project.set('saleManagedBy', manager.get('user'));
                }
              }
              project.set('sellingPrice', request.get('sellingPrice'));
              project.set('price', request.get('sellingPrice'));
              if (lockDetails && lockedUntil) {
                project.set('lockedUntil', lockedUntil);
                project.set('lockedFor', lead);
              }
              await project.save(null, { useMasterKey: true });
            }
            if (property) {
              property.set('status', 'Sold');
              property.set('soldAt', new Date(request.get('soldAt')));
              property.set('soldBy', request.get('soldBy'));
              if (request.get('soldBy').get('role') === UserRole.executive) {
                const salesExecutiveRepository = new SalesExecutiveRepository();
                const salesExecutive = await salesExecutiveRepository.getObjectByUserId(request.get('soldBy').id);
                if (salesExecutive) {
                  property.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                }
              } else if (request.get('soldBy').get('role') === UserRole.manager) {
                const managerRepository = new ManagerRepository();
                const manager = await managerRepository.getObjectByUserId(request.get('soldBy').id);
                if (manager) {
                  property.set('saleManagedBy', manager.get('user'));
                }
              }
              property.set('sellingPrice', request.get('sellingPrice'));
              property.set('price', request.get('sellingPrice'));
              if (lockDetails && lockedUntil) {
                property.set('lockedUntil', lockedUntil);
                property.set('lockedFor', lead);
              }
              await property.save(null, { useMasterKey: true });
            }
            if (projectComponent) {
              projectComponent.set('status', 'Sold');
              projectComponent.set('soldAt', new Date(request.get('soldAt')));
              projectComponent.set('soldBy', request.get('soldBy'));
              if (request.get('soldBy').get('role') === UserRole.executive) {
                const salesExecutiveRepository = new SalesExecutiveRepository();
                const salesExecutive = await salesExecutiveRepository.getObjectByUserId(request.get('soldBy').id);
                if (salesExecutive) {
                  projectComponent.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                }
              } else if (request.get('soldBy').get('role') === UserRole.manager) {
                const managerRepository = new ManagerRepository();
                const manager = await managerRepository.getObjectByUserId(request.get('soldBy').id);
                if (manager) {
                  projectComponent.set('saleManagedBy', manager.get('user'));
                }
              }
              projectComponent.set('sellingPrice', request.get('sellingPrice'));
              projectComponent.set('price', request.get('sellingPrice'));
              if (lockDetails && lockedUntil) {
                projectComponent.set('lockedUntil', lockedUntil);
                projectComponent.set('lockedFor', lead);
              }
              await projectComponent.save(null, { useMasterKey: true });
            }

            const leadClientPayment: LeadClientPaymentModel = {
              paymentMethod: lockDetails?.paymentMethod,
              currency: lockDetails?.currency,
              nature: lockDetails?.nature,
              lead: lead,
              project: lead.get('project'),
              projectComponent: lead.get('projectComponent'),
              property: lead.get('property'),
              organization: lead.get('organization'),
              amount: request.get('sellingPrice'),
            };

            const leadClientPaymentRepository = new LeadClientPaymentRepository();
            await leadClientPaymentRepository.create(leadClientPayment);

            const deletedRequest = await this.delete(id);
            if (deletedRequest) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error fetching conversion request by ID:', error);
      return false;
    }
  }

  public async delete(id: string) {
    try {
      const LeadConversionRequest = Parse.Object.extend(this.className);
      const query = new Parse.Query(LeadConversionRequest);

      const request = await query.get(id, { useMasterKey: true });

      if (request) {
        await request.destroy({ useMasterKey: true });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error deleting lead conversion request:', error);
      return false;
    }
  }
}
export default LeadConversionRequestRepository;
