import { Box, Button, Checkbox, Divider, IconButton, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';

// import PlusIcon from '../../../assets/Icons/PlusIcon.svg';
import { Form, Formik, FormikHelpers } from 'formik';
import { CiEdit } from 'react-icons/ci';
import { MdDelete, MdPeopleOutline } from 'react-icons/md';
import { BsCameraVideo, BsEyeFill, BsTelephone } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FieldInput, Toast } from '../../../atoms';
import { globalColors } from '../../../../utils/constants/color';
import { setAddNew } from '../../../../store/routes';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import ModalDeleteConfirm from '../../../organisms/leads/modalDeleteConfirm';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import ModalDetailLead from '../../../organisms/leads/modalDetailLead';
import LeadModel from '../../../../utils/api/models/leadModel';
import { getColorForPriority, getColorForStatus } from '../../../../utils/helpers';
import { ContactToolTip } from '../../../atoms/contactTooltip';
import NoteModel from '../../../../utils/api/models/noteModel';
import Loader from '../../../atoms/loader';

interface LeadsCardProps {
  mode: string;
  color: string;
  content: LeadModel;
  setloading: (isLoading: boolean) => void;
  edit: boolean;
  setOpenBulkEditLeadModal: (isLoading: boolean) => void;
  openBulkEditLeadModal: boolean;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
}

interface ValuesFollowUpDate {
  date: string;
  note: string;
  objectId: string;
}

const ConversionsDisabledCard: React.FC<LeadsCardProps> = ({
  mode,
  content,
  color,
  setloading,
  edit,
  rowCheckboxState,
  setRowCheckboxState,
  setOpenBulkEditLeadModal,
  openBulkEditLeadModal,
}) => {
  const today = new Date().toISOString().split('T')[0];
  const navigate = useNavigate();
  const [openDeleteLeadModal, setOpenDeleteLeadModal] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState<boolean>(true);
  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();
  const [openDetailLeadModal, setOpenDetailLeadModal] = useState<boolean>(false);

  const [leadId, setleadId] = useState<string>('');
  const [changeStatus, setChangeStatus] = useState<string>(content?.status ?? 'New');
  const [newStatus, setNewStatus] = useState<string>('');
  const [changePriority, setChangePriority] = useState<string>(content?.priority ?? 'Cold');
  const [selectedMethodContact, setSelectedMethodContact] = useState('');
  const [latestCommunication, setLatestCommunication] = useState<{ type: string; note: string; createdAt: string; doneBy: string } | null>(null);
  const [communicationLoading, setCommunicationLoading] = useState(true);
  const dispatch = useDispatch();
  const [revealCommunication, setRevealCommunication] = useState(
    currentUser && (currentUser.get('role') === UserRole.admin || content.assignedTo.id === currentUser.id)
  );

  const handleOpenModalDeleteLeads = () => setOpenDeleteLeadModal(false);
  const handleOpenModalDetailsLeads = () => {
    setOpenDetailLeadModal(false);
    setNewStatus('');
  };

  const handleDeleteLeads = () => {
    const leadRepository = new LeadRepository();
    if (content?.id) {
      const leadIdToDelete = content?.id;

      leadRepository
        .delete(leadIdToDelete)
        .then((result) => {
          if (result) {
            setOpenDeleteLeadModal(false);
            setloading(true);
          } else {
            setOpenDeleteLeadModal(false);
          }
        })
        .catch((error) => {
          console.error('Error deleting lead:', error);
        });
    }
  };
  const handleOpenModalBulkEditLeads = () => setOpenBulkEditLeadModal(false);
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, leadId: string | undefined) => {
    if (leadId) {
      const checked = event.target.checked;
      setRowCheckboxState((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [leadId]: checked,
      }));
    }
  };

  const fetchLatestCommunication = async () => {
    setCommunicationLoading(true);
    const leadRepository = new LeadRepository();
    const latest = await leadRepository.getLatestCommunication(content?.id ?? '');
    if (latest) {
      setLatestCommunication({
        type: latest.get('method'),
        doneBy: latest.get('doneBy').get('firstName') + ' ' + latest.get('doneBy').get('lastName'),
        createdAt: moment(latest.get('createdAt')).fromNow(),
        note: latest.get('note').get('body'),
      });
    }
    setCommunicationLoading(false);
  };

  useEffect(() => {
    fetchLatestCommunication();
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'10px'}
        bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
        padding={'20px'}
        borderRadius={'10px'}
        color={globalColors.white}
        fontSize={'12px'}
        height={'100%'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'0'} marginBottom={'10px'}>
          {currentUser?.get('role') !== UserRole.executive && (
            <Box display={'flex'} justifyContent={'start'}>
              {edit && content && content.id && (
                <Checkbox
                  size="medium"
                  sx={{
                    color: color || globalColors.blue,
                    '&.Mui-checked': {
                      color: color || globalColors.secondBlue,
                    },
                  }}
                  onChange={(event) => {
                    handleRowCheckboxChange(event, content?.id);
                  }}
                  checked={rowCheckboxState[content?.id] || false}
                />
              )}
            </Box>
          )}
          <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
            <Typography
              fontSize={'20px'}
              textTransform={'capitalize'}
              color={mode === 'dark' ? globalColors.white : globalColors.black}
              marginTop={'auto'}
              marginBottom={'auto'}
            >
              {content?.firstName + ' ' + content?.lastName}
            </Typography>
            <Box display={'flex'} justifyContent={'end'} gap={'10px'}>
              <BsEyeFill
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowDetail(true);
                  setOpenDetailLeadModal(true);
                  setleadId(content?.id || '');
                }}
              />
              {currentUser?.get('role') !== UserRole.executive && (
                <MdDelete
                  onClick={() => setOpenDeleteLeadModal(true)}
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'start'}>
            <Typography marginTop={'auto'} marginBottom={'auto'} color={color || globalColors.lightblue}>
              {content?.propertyProject?.get('name') ?? content?.category}
            </Typography>
          </Box>
        </Box>
        {content.assignedTo && (
          <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
            <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
              Assigned To
            </Typography>
            <Typography color={color || globalColors.blue} fontSize={'12px'} marginTop={'auto'} marginBottom={'auto'}>
              {`${content?.assignedTo.get('firstName')} ${content?.assignedTo.get('lastName')}`}
            </Typography>
          </Box>
        )}

        <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Priority
          </Typography>
          <Typography
            color={getColorForPriority(changePriority) ?? (mode === 'dark' ? globalColors.white : globalColors.black)}
            fontSize={'12px'}
            marginTop={'auto'}
            marginBottom={'auto'}
          >
            {changePriority}
          </Typography>
        </Box>
        <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Status
          </Typography>
          <Typography
            color={getColorForStatus(changeStatus) ?? (mode === 'dark' ? globalColors.white : globalColors.black)}
            fontSize={'12px'}
            marginTop={'auto'}
            marginBottom={'auto'}
          >
            {changeStatus}
          </Typography>
        </Box>
        <Box display={'flex'} component={'span'} justifyContent={'space-between'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Communication
          </Typography>
          <Typography sx={{ display: 'flex', gap: '20px' }}>
            {content.primaryPhone &&
              (revealCommunication ? (
                <ContactToolTip value={content.primaryPhone} href={`tel:${content.primaryPhone}`} action={'Call'}>
                  <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BsTelephone size={'1rem'} color={color || globalColors.blue} />
              ))}
            {content.secondaryPhone &&
              (revealCommunication ? (
                <ContactToolTip value={content.secondaryPhone} href={`tel:${content.secondaryPhone}`} action={'Call'}>
                  <BsTelephone size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BsTelephone size={'1rem'} color={color || globalColors.blue} />
              ))}
            {content.email &&
              (revealCommunication ? (
                <ContactToolTip value={content.email} href={`mailto:${content.email}`} action={'Email'}>
                  <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
                </ContactToolTip>
              ) : (
                <BiEnvelope size={'1rem'} color={color || globalColors.blue} />
              ))}
          </Typography>
        </Box>

        <Box display={'flex'} gap={'15px'} flexDirection={'column'} component={'span'} marginTop={'15px'}>
          <Typography fontSize={'13px'} color={globalColors.gray} marginTop={'auto'} marginBottom={'auto'}>
            Latest Communication
          </Typography>
          {communicationLoading ? (
            <Box minHeight={'60px'} display={'flex'} alignItems={'center'}>
              <Loader size={'14px'} />
            </Box>
          ) : latestCommunication === null ? (
            <Box minHeight={'60px'} display={'flex'} alignItems={'center'}>
              <Typography>No communication found.</Typography>
            </Box>
          ) : (
            <Box
              component={'span'}
              bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}
              paddingLeft={'20px'}
              color={mode === 'dark' ? globalColors.white : globalColors.black}
              paddingRight={'20px'}
              paddingTop={'10px'}
              paddingBottom={'10px'}
              borderRadius={'12px'}
            >
              <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={'10px'}>
                <Typography>{latestCommunication?.type}</Typography>
                {latestCommunication?.type === 'Call' && <BsTelephone size={'1rem'} color={color || globalColors.blue} />}
                {latestCommunication?.type === 'Email' && <BiEnvelope size={'1rem'} color={color || globalColors.blue} />}
                {latestCommunication?.type === 'Meeting' && <BsCameraVideo size={'1rem'} color={color || globalColors.blue} />}
                {latestCommunication?.type === 'Physical Meeting' && <MdPeopleOutline size={'1rem'} color={color || globalColors.blue} />}
              </Box>
              <Box marginTop={'0.5rem'}>
                <Typography sx={{ marginTop: '6px', fontSize: '14px', color: mode === 'dark' ? globalColors.white : globalColors.black }}>
                  {latestCommunication.note.length > 130 ? latestCommunication.note.substring(0, 127) + '...' : latestCommunication.note}
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} marginTop={'5px'}>
                  <span style={{ color: globalColors.gray, fontSize: '12px' }}>{latestCommunication.createdAt}</span>
                  <span style={{ color: color || globalColors.blue, fontSize: '12px' }}>{latestCommunication.doneBy}</span>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <ModalDeleteConfirm
          open={openDeleteLeadModal}
          onClose={handleOpenModalDeleteLeads}
          mode={mode}
          color={color}
          onDelete={handleDeleteLeads}
          action={{ permissionName: 'Leads', action: 'Delete' }}
        />
        <ModalDetailLead
          open={openDetailLeadModal}
          onClose={handleOpenModalDetailsLeads}
          mode={mode}
          showDetail={showDetail}
          color={color}
          leadId={leadId}
          newStatus={newStatus}
        />
      </Box>
    </div>
  );
};

export default ConversionsDisabledCard;
