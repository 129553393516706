import { Box, Stack, styled } from '@mui/material';
import React from 'react';
import IMGHeroLogin from '../../assets/Images/login.png';
import { FormLogin } from '../../components/organisms';
import { globalColors } from '../../utils/constants/color';
import ModalForgotPassword from './modalForgotPassword';
import ModalRegister from './modalRegister';
// import UserModel from '../../utils/api/models/userModel';
import { CustomCarousel } from '../../components/atoms/carousel';
import logo from '../../assets/Images/logo.svg';
import { useNavigate } from 'react-router';

const Login = () => {
  // * State for Modal register
  const [openModalRegister, setOpenModalRegister] = React.useState(false);
  const handleOpenModalRegister = () => setOpenModalRegister(true);
  const handleCloseModalRegister = () => setOpenModalRegister(false);

  // * State for Modal forgot password
  const [openModalForgotPassword, setOpenModalForgotPassword] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenModalForgotPassword = () => setOpenModalForgotPassword(true);
  const handleCloseModalForgotPassword = () => setOpenModalForgotPassword(false);

  return (
    <LoginStyle>
      <Stack className="wrapper">
        {/* <img className="hero-image-auth" src={IMGHeroLogin} alt="hero login property platform" /> */}
        <Box sx={{ display: 'flex', flex: 1, height: '100%' }}>
          <CustomCarousel images={[logo, IMGHeroLogin]} showArrows={false} />
        </Box>

        <FormLogin onCreateAccount={() => {navigate('/onboarding')}} onForgotPassword={handleOpenModalForgotPassword} className="form-login" />
      </Stack>

      {/* MODAL Register */}
      <ModalRegister open={openModalRegister} onClose={handleCloseModalRegister} onLogin={handleCloseModalRegister} />

      {/* MODAL Forgot Password */}
      <ModalForgotPassword open={openModalForgotPassword} onClose={handleCloseModalForgotPassword} onLogin={handleCloseModalForgotPassword} />
    </LoginStyle>
  );
};

// * Styling
const LoginStyle = styled('div')(({ theme }) => ({
  height: '100vh',
  backgroundColor: globalColors.black,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
  '& .wrapper': {
    backgroundColor: globalColors.blackLight,
    // padding: '10rem',
    padding: '20px',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: '20px',
    alignItems: 'center',

    // width: '1175px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0rem',
      height: '100vh',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
      padding: '3rem 0',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1000px',
    },

    '& img.hero-image-auth': {
      flex: 1,
      objectFit: 'contain',
      width: '471px',
      height: '400px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '50%',
      },
    },
    '& .form-login': {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flex: 2,
      },
      [theme.breakpoints.down('md')]: {
        flex: 2,
        width: '80%',
      },
    },
  },
}));

export default Login;
