import { BiSolidNotification } from 'react-icons/bi';
import { toast } from 'react-toastify';

export const NotificationToast = (message: string) => {
  const toastOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 8000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  toast.info(message, toastOptions);
};
