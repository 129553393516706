import { Modal } from '@mui/base/Modal';
import { Backdrop, Divider, Typography } from '@mui/material';
import { Box, Theme, styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { IMGDefault } from '../../../assets/Images';
import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProperty } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { ProductCategories } from '../../../utils/types/products';
import { CardSkeleton } from '../../molecules';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { formatDate, formatMoney, getColorForProductStatus, getColorForStatus } from '../../../utils/helpers';
import ComponentAdd from '../product/addComponent';
import ModalViewProjectComponent from './modalViewProjectComponent';
import { useEffect, useState } from 'react';
import UserRepository from '../../../utils/api/repositories/userRepository';
import LeadModel from '../../../utils/api/models/leadModel';
import PropertyRepository from '../../../utils/api/repositories/propertyRepository';
import ProjectRepository from '../../../utils/api/repositories/projectRepository';
import LeadRepository from '../../../utils/api/repositories/leadRepository';
import Loader from '../../atoms/loader';
import { CiEdit } from 'react-icons/ci';
import { UserRole } from '../../../store/user';
import { BsEyeFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { setAddNew } from '../../../store/routes';
import { useNavigate } from 'react-router-dom';
import ModalDetailLead from '../leads/modalDetailLead';
import NotFoundLottie from '../../atoms/notFound';
import Paginator from '../../atoms/paginator';
import ProjectComponentModel from '../../../utils/api/models/projectComponentModel';

interface ModalViewProductProps {
  onClose: () => void;
  open: boolean;
  data: IFormDetailProject | IFormDetailProperty | null;
  type: ProductCategories | null;
  loading: boolean;
}

const ModalViewProduct = ({ onClose, open, data, type, loading }: ModalViewProductProps) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);

  const dataProperty = data as IFormDetailProperty;
  const dataProject = data as IFormDetailProject;
  const isProperty = type === ProductCategories.Property;
  const isProject = type === ProductCategories.Project;

  const [openProjectComponent, setOpenProjectComponent] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(-1);
  const [soldBy, setSoldBy] = useState('');
  const [activeItem, setActiveItem] = useState('details');
  const [currentUser, setCurrentUser] = useState<Parse.Object | null | undefined>(null);
  const [interested, setInterested] = useState<LeadModel[]>([]);
  const [loadingLeads, setLoadingLeads] = useState(true);
  const [openDetailLeadModal, setOpenDetailLeadModal] = useState(false);
  const [showDetail, setShowDetail] = useState(true);
  const [leadId, setLeadId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const userRepository = new UserRepository();
    const user = userRepository.getCurrentUser();
    setCurrentUser(user);
    const fetchSoldBy = async () => {
      if (data?.status === 'Sold' && data?.soldBy) {
        const userRepository = new UserRepository();
        const seller = await userRepository.getObjectById(data?.soldBy);

        if (seller) {
          setSoldBy((seller.get('firstName') ?? '') + ' ' + (seller.get('lastName') ?? ''));
        }
      }
    };

    fetchSoldBy();
  }, [data]);

  const getTotalCount = async () => {
    const leadRepository = new LeadRepository();
    const totalCount = await leadRepository.getInterestedCount(isProperty ? 'property' : 'project', data?.id ?? '');
    return totalCount;
  };

  const fetchInterested = async () => {
    setLoadingLeads(true);
    const leadRepository = new LeadRepository();
    const interestedLeads = await leadRepository.getInterested(isProperty ? 'property' : 'project', data?.id ?? '');
    if (interestedLeads) {
      setInterested(interestedLeads);
    }
    setLoadingLeads(false);
  };

  const fetchMoreInterested = async (page: number) => {
    setLoadingLeads(true);
    const leadRepository = new LeadRepository();
    const interestedLeads = await leadRepository.getInterested(isProperty ? 'property' : 'project', data?.id ?? '', page);
    if (interestedLeads) {
      setInterested(interestedLeads);
    }
    setLoadingLeads(false);
  };

  useEffect(() => {
    if (open) {
      fetchInterested();
    }
  }, [open, data]);

  return (
    <div>
      <StyledModal
        mode={mode}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={onClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style} bgcolor={mode === 'dark' ? '#171717' : globalColors.lightgray}>
          {loading && (
            <div className="content">
              <CardSkeleton />
            </div>
          )}
          {!loading && (
            <>
              <div className="img-cover">
                <img src={dataProperty?.cover?.preview || IMGDefault} alt="img" />
              </div>
              <div className="content">
                <Box display={'flex'} flexWrap={'wrap'} gap={'10px'} marginBottom={'30px'}>
                  <Box
                    onClick={() => setActiveItem('details')}
                    border={`2px solid ${activeItem === 'details' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                    borderRadius={'10px'}
                    bgcolor={
                      mode === 'dark'
                        ? activeItem === 'details'
                          ? color || globalColors.blue
                          : globalColors.blackLight
                        : activeItem === 'details'
                        ? color || globalColors.blue
                        : globalColors.white
                    }
                    color={mode === 'dark' ? globalColors.white : activeItem === 'details' ? globalColors.white : color || globalColors.black}
                    paddingLeft={'10px'}
                    sx={{ cursor: 'pointer' }}
                    paddingRight={'10px'}
                    paddingTop={'2px'}
                    paddingBottom={'2px'}
                  >
                    Details
                  </Box>
                  <Box
                    onClick={() => setActiveItem('interested')}
                    border={`2px solid ${activeItem === 'interested' ? color || globalColors.border.gray : color || globalColors.border.gray}`}
                    borderRadius={'10px'}
                    bgcolor={
                      mode === 'dark'
                        ? activeItem === 'interested'
                          ? color || globalColors.blue
                          : globalColors.blackLight
                        : activeItem === 'interested'
                        ? color || globalColors.blue
                        : globalColors.white
                    }
                    color={mode === 'dark' ? globalColors.white : activeItem === 'interested' ? globalColors.white : color || globalColors.black}
                    paddingLeft={'10px'}
                    sx={{ cursor: 'pointer' }}
                    paddingRight={'10px'}
                    paddingTop={'2px'}
                    paddingBottom={'2px'}
                  >
                    Leads
                  </Box>
                </Box>
                {activeItem === 'details' && (
                  <>
                    <Box marginBottom={'1rem'}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography variant="h5" color={mode === 'dark' ? globalColors.white : globalColors.black}>
                          {dataProperty?.name || '-'}
                        </Typography>
                      </Box>
                      <Typography>{dataProperty?.description}</Typography>
                    </Box>

                    <Box display={'flex'} marginBottom={'1rem'} justifyContent={'space-between'}>
                      <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Status</Typography>
                      <Typography
                        color={getColorForProductStatus(dataProperty?.status) || (mode === 'dark' ? globalColors.white : globalColors.black)}
                      >
                        {dataProperty?.status || '-'}
                      </Typography>
                    </Box>

                    {dataProperty?.status === 'Sold' && (
                      <Box marginBottom={'1rem'}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Selling Price</Typography>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                            {dataProperty?.sellingPrice ? `PKR ${formatMoney(parseFloat(dataProperty?.sellingPrice))}` : '-'}
                          </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Sold At</Typography>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                            {dataProperty?.soldAt ? formatDate(dataProperty?.soldAt) : '-'}
                          </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Sold By</Typography>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{soldBy}</Typography>
                        </Box>
                      </Box>
                    )}

                    {isProject && (
                      <Box display={'flex'} marginBottom={'1rem'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Type</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProject?.type || '-'}</Typography>
                      </Box>
                    )}
                    <Box marginBottom={'1rem'}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Country</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.country || '-'}</Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>State</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.state || '-'}</Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>City</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.city || '-'}</Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Address</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.address || '-'}</Typography>
                      </Box>
                    </Box>
                    <Box marginBottom={'1rem'}>
                      <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Amenities</Typography>
                      <Box display={'flex'} justifyContent={'end'} marginTop={'10px'} gap={'1rem'} flexWrap={'wrap'}>
                        {dataProperty?.amenities?.map((item) => (
                          <Box display={'flex'} flexDirection={'column'} gap={'10px'} alignItems={'center'}>
                            <Typography fontSize={'12px'} color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>
                              {item.label}
                            </Typography>
                            <Typography>{item.value}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box marginBottom={'1rem'}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Area</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                          {dataProperty?.area ? `${dataProperty?.area} ${dataProperty?.areaUnit ?? ''}` : '-'}
                        </Typography>
                      </Box>
                    </Box>

                    {isProperty && (
                      <Box marginBottom={'1rem'}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Price</Typography>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                            {dataProperty.price ? `PKR ${formatMoney(parseFloat(dataProperty.price))}` : '-'}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    <Box marginBottom={'1rem'}>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Contact Name</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                          {dataProperty?.contactName && dataProperty?.contactName !== '' ? dataProperty?.contactName : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Contact Phone</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                          {dataProperty?.contactPhone && dataProperty?.contactPhone !== '' ? dataProperty?.contactPhone : '-'}
                        </Typography>
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'}>
                        <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Contact Secondary Phone</Typography>
                        <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>
                          {dataProperty?.contactSecondaryPhone && dataProperty?.contactSecondaryPhone !== ''
                            ? dataProperty?.contactSecondaryPhone
                            : '-'}
                        </Typography>
                      </Box>
                    </Box>

                    {isProject && dataProject?.components && dataProject?.components.length > 0 && (
                      <Box marginBottom={'1rem'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Divider
                          sx={{ color: globalColors.gray, backgroundColor: globalColors.gray, width: '50%', marginBottom: '2rem', marginTop: '1rem' }}
                        />
                        <Box width={'100%'}>
                          <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Inventory</Typography>
                        </Box>
                        <Box width={'100%'} display={'flex'} justifyContent={'start'} marginTop={'1rem'} flexWrap={'wrap'}>
                          <ComponentAdd
                            components={dataProject.components}
                            mode={mode}
                            color={color}
                            onClickNew={() => {}}
                            onClickAdded={(idx) => {
                              setSelectedComponent(idx);
                              setTimeout(() => {
                                setOpenProjectComponent(true);
                              }, 500);
                            }}
                            onAddComponent={(data) => {}}
                            onRemoveComponent={(idx) => {}}
                            viewOnly={true}
                          />
                        </Box>
                      </Box>
                    )}
                  </>
                )}
                {activeItem === 'interested' && (
                  <>
                    {loadingLeads ? (
                      <Loader />
                    ) : interested.length > 0 ? (
                      <>
                        {interested.map((lead, idx) => {
                          return (
                            <Box
                              key={idx}
                              sx={{
                                borderRadius: '8px',
                                backgroundColor: mode  === 'dark' ? globalColors.black : globalColors.white,
                                padding: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '8px'
                              }}
                            >
                              <Box display={'flex'} flexDirection={'column'} gap={'0.25rem'}>
                                <Typography
                                  sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}
                                >{`${lead.firstName} ${lead.lastName}`}</Typography>
                                {lead.assignedTo ? (
                                  <Typography sx={{ color: mode === 'dark' ? globalColors.text.secondary : globalColors.black, fontSize: '12px' }}>
                                    Assigned to&nbsp;
                                    <span style={{ color: color || globalColors.blue }}>
                                      {`${lead.assignedTo.get('firstName') ?? 'Unknown'} ${lead.assignedTo.get('lastName') ?? 'Member'}`}
                                    </span>
                                    &nbsp;on&nbsp;
                                    {lead.assignedAt && <span style={{ color: color || globalColors.blue }}>{formatDate(lead.assignedAt)}</span>}
                                  </Typography>
                                ) : (
                                  <Typography sx={{ color: mode === 'dark' ? globalColors.text.secondary : globalColors.black, fontSize: '12px' }}>
                                    Unassigned
                                  </Typography>
                                )}
                              </Box>
                              <Box display={'flex'} justifyContent={'end'} gap={'10px'}>
                                <BsEyeFill
                                  size={'1.3rem'}
                                  color={color || globalColors.blue}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setShowDetail(true);
                                    setOpenDetailLeadModal(true);
                                    setLeadId(lead.id || '');
                                  }}
                                />
                                {currentUser?.get('role') !== UserRole.executive && (
                                  <CiEdit
                                    size={'1.3rem'}
                                    color={color || globalColors.blue}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      dispatch(setAddNew({ addNew: true, path: isProperty ? '/properties' : '/projects' }));
                                      navigate(`/leads/edit/${lead.id}`);
                                    }}
                                  />
                                )}
                              </Box>
                            </Box>
                          );
                        })}
                        <Paginator
                          getTotalCount={getTotalCount}
                          onChangePage={async (newPage) => {
                            setCurrentPage(newPage);
                            await fetchMoreInterested(newPage);
                            return true;
                          }}
                          internal
                          activePage={currentPage}
                        />
                      </>
                    ) : (
                      <NotFoundLottie
                        text={`No leads or clients are interested in this ${
                          isProperty ? 'property' : 'project'
                        } yet. Add a new lead right now.`}
                        buttonText="Add New Lead"
                        setAddNewPath={isProperty ? '/properties' : '/projects'}
                        showButton
                        navigateTo="/leads/new"
                        size="small"
                      />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </Box>
      </StyledModal>
      <ModalViewProjectComponent
        open={openProjectComponent}
        onClose={() => {
          setOpenProjectComponent(false);
        }}
        data={dataProject?.components && selectedComponent !== -1 ? dataProject?.components[selectedComponent] : null}
        loading={false}
      />
      <ModalDetailLead
        open={openDetailLeadModal}
        onClose={() => {
          setOpenDetailLeadModal(false);
        }}
        mode={mode}
        showDetail={showDetail}
        color={color}
        leadId={leadId}
      />
    </div>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = (theme: Theme) => ({
  width: '50%',
  height: '60vh',
  borderRadius: '12px',
  color: 'white',
  overflowY: 'scroll',

  '& p': {
    margin: '0px',
  },
  '.img-cover': {
    height: '250px',
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },

  '& .content': {
    padding: '1.2rem',

    '& .title': {
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '10px',
    },

    '& .text-raw': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      fontWeight: 400,
      fontSize: '14px',
      color: globalColors.text.secondary,
      flexWrap: 'wrap',
      '&.block': {
        flexDirection: 'column',
        gap: '8px',
        margin: '10px 0',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    '& .value': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      fontWeight: 400,
      fontSize: '1rem',
      color: globalColors.text.main,
      maxWidth: '60%',
      flexWrap: 'wrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:hover': {
        overflow: 'visible',
      },
    },

    '& .amenities': {
      marginTop: '8px',
      '& .items': {
        maxWidth: '70%',
        display: 'flex',
        justifyContent: 'end',
        gap: '1rem',
        flexWrap: 'wrap',
        '& .item': {
          minWidth: '70px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
    },

    '& .pictures': {
      margin: '1rem 0',
      '& .pictures-wrap': {
        margin: '1rem 0 0 0',
        display: 'flex',
        gap: '1rem',
        '& .picture': {
          width: '100px',
          height: '100px',
          borderRadius: '10px',
          objectFit: 'cover',
        },
      },
    },
  },

  // Responsive
  [theme.breakpoints.up('xl')]: {
    minWidth: '50rem',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80%',
  },
});

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export default ModalViewProduct;
