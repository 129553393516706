import { Box, Skeleton, styled } from '@mui/material';

const CardSkeleton = () => {
  return (
    <Wrapper>
      <Skeleton variant="rectangular" className="cover skeleton" />
      <Box>
        <Skeleton className="head skeleton" />
        <Skeleton className="text skeleton" />
        <Skeleton className="text skeleton" />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  minWidth: '300px',
  '& .skeleton': {
    backgroundColor: '#333333',

    '&.cover': {
      width: '100%',
      height: '10rem',
    },

    '&.head': {
      width: '70%',
    },

    '&.text': {
      width: '40%',
      height: '1.2rem',
    },
  },
}));

export default CardSkeleton;
