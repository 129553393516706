import Parse from 'parse';
import DemoModel from '../models/demoModel';
import { Toast } from '../../../components/atoms';

class ResetPasswordRequestRepository {
  private className = 'ResetPasswordRequest';

  public async initiate(email: string): Promise<{status: string, message: string, data: any} | null> {
    try {
      const resetPassword = await Parse.Cloud.run('sendResetPasswordEmail', {emailToCheck: email}, { useMasterKey: true });
      return resetPassword;
    } catch (error) {
      console.error('Error initiating reset password request:', error);
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const ResetPasswordRequest = Parse.Object.extend(this.className);
      const query = new Parse.Query(ResetPasswordRequest).include('user');
      const resetPasswordRequest = await query.get(id, { useMasterKey: true });
      return resetPasswordRequest;
    } catch (error) {
      console.error('Error fetching reset password request by ID:', error);
      return null;
    }
  }

  public async change(newPassword: string, id: string): Promise<any | null> {
    try {
      const resetPassword = await Parse.Cloud.run('resetPassword', {newPassword: newPassword, id: id}, { useMasterKey: true });
      return resetPassword;
    } catch (error) {
      console.error('Error fetching reset password request by ID:', error);
      return null;
    }
  }
}

export default ResetPasswordRequestRepository;
