import Parse, { User } from 'parse';
import { IMGDefault } from '../../../assets/Images';
import { Toast } from '../../../components/atoms';
import { IFormDetailProject, IImages } from '../../../store/projects';
import { dataLimit, getYearQuarter, isArray, isFile } from '../../helpers';
import GalleryModel from '../models/galleryModel';
import ProjectModel from '../models/projectModel';
import GalleryRepository from './galleryRepository';
import { Picture } from '../models/propertyModel';
import UserRepository from './userRepository';
import FileRepository from './fileRepository';
import FileModel from '../models/fileModel';
import ProjectComponentModel from '../models/projectComponentModel';
import { UserRole } from '../../../store/user';
import SalesExecutiveRepository from './salesExecutiveRepository';
import ManagerRepository from './managerRepository';

class ProjectComponentRepository {
  private className = 'ProjectComponent';

  constructor() {}

  public async create(object: ProjectComponentModel, projectId?: string): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const ProjectComponent = Parse.Object.extend(this.className);
      const projectComponent = new ProjectComponent();

      projectComponent.set('area', object.area);
      projectComponent.set('areaUnit', object.areaUnit);

      if (object.attachments && object.attachments.length > 0) {
        const fileRepository = new FileRepository();
        let savedFiles: any[] = [];
        const attachmentFiles = object.attachments as File[];
        await Promise.all(
          attachmentFiles.map(async (file) => {
            const fileModel: FileModel = {
              file: file,
            };
            const savedFile = await fileRepository.create(fileModel);
            if (savedFile) {
              savedFiles.push(savedFile);
            }
          })
        );
        const attachmentsRelation = projectComponent.relation('attachments');
        attachmentsRelation.add(savedFiles);
      }

      projectComponent.set('contactName', object.contactName);
      projectComponent.set('contactPhone', object.contactPhone);
      projectComponent.set('contactSecondaryPhone', object.contactSecondaryPhone);

      if (object.coverPicture && isFile(object.coverPicture.file)) {
        const galleryRepository = new GalleryRepository();
        const galleryModel: GalleryModel = {
          file: object.coverPicture.file,
        };
        const savedFile = await galleryRepository.createAndReturn(galleryModel);
        projectComponent.set('coverPicture', savedFile);
      }

      projectComponent.set('createdBy', currentUser);
      projectComponent.set('description', object.description);
      projectComponent.set('downPayment', object.downPayment);
      projectComponent.set('name', object.name);
      projectComponent.set('organization', currentUser.get('organization'));
      projectComponent.set('customFields', object.customFields);

      if (isArray(object.pictures) && object.pictures.length > 0) {
        const galleryRepository = new GalleryRepository();
        let savedFiles: any[] = [];
        const imagesFiles = object.pictures as File[];

        await Promise.all(
          imagesFiles.map(async (file) => {
            const galleryModel: GalleryModel = {
              file: file,
            };
            const savedFile = await galleryRepository.createAndReturn(galleryModel);
            if (savedFile) {
              savedFiles.push(savedFile);
            }
          })
        );
        const galleriesRelation = projectComponent.relation('pictures');
        galleriesRelation.add(savedFiles);
      }

      projectComponent.set('price', object.price);
      projectComponent.set('priority', object.priority);
      projectComponent.set('purpose', object.purpose);

      if (object.status === 'Sold') {
        projectComponent.set('soldAt', object.soldAt);
        projectComponent.set('price', object.sellingPrice);
        projectComponent.set('sellingPrice', object.sellingPrice);
        if (object.soldBy) {
          const seller = await userRepository.getObjectById(object.soldBy);
          if (seller) {
            if (seller.get('role') === UserRole.executive) {
              const salesExecutiveRepository = new SalesExecutiveRepository();
              const salesExecutive = await salesExecutiveRepository.getObjectByUserId(seller.id);
              if (salesExecutive) {
                projectComponent.set('saleManagedBy', salesExecutive.get('manager').get('user'));
              }
            } else if (seller.get('role') === UserRole.manager) {
              const managerRepository = new ManagerRepository();
              const manager = await managerRepository.getObjectByUserId(seller.id);
              if (manager) {
                projectComponent.set('saleManagedBy', manager.get('user'));
              }
            }
            projectComponent.set('soldBy', seller);
          } else {
            return null;
          }
        }
      }

      const status = object.status ? object.status : 'Available';
      projectComponent.set('status', status);
      projectComponent.set('updatedBy', currentUser);
      projectComponent.set('useDownPayment', object.useDownPayment);

      if (projectId) {
        const project = Parse.Object.createWithoutData(projectId);
        projectComponent.set('project', project);
      }

      return new Promise((resolve, _) => {
        projectComponent.save(null, { useMasterKey: true }).then(
          (projectComponent: any) => {
            resolve(projectComponent);
          },
          (error: Error) => {
            console.log(error);
            resolve(null);
          }
        );
      });
    } else {
      return null;
    }
  }

  public async getByObjectId(objectId: string): Promise<ProjectComponentModel | null> {
    const ProjectComponent = Parse.Object.extend(this.className);
    const query = new Parse.Query(ProjectComponent);

    query.include('coverPicture', 'project');

    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (projectComponent) => {
        if (projectComponent) {
          const projectComponentData = projectComponent.toJSON();

          // ! Handling cover picture
          const coverPicture = projectComponent.get('coverPicture');
          let coverPictureUrl = IMGDefault;
          if (coverPicture) {
            coverPictureUrl = coverPicture.toJSON()?.file?.url;
          }

          // ! Handling pictures
          const picturesRelation = projectComponent.relation('pictures');

          // Query the relation to get the gallery
          const urlPictures: IImages[] = [];
          await picturesRelation
            .query()
            .find({ useMasterKey: true })
            .then((gallery: { [x: string]: any }) => {
              if (gallery) {
                if (gallery && gallery.length > 0) {
                  gallery.forEach((picture: any) => {
                    const getPicture = picture.toJSON();
                    if (getPicture) {
                      const picture = {
                        id: getPicture.objectId,
                        url: getPicture.file?.url,
                        file: getPicture.file,
                      };
                      urlPictures.push(picture);
                    }
                  });
                }
              } else {
                console.error('Gallery not found.');
              }
            })
            .catch((error: { message: string }) => {
              console.error('Error querying gallery relation: ' + error.message);
            });

          const data = {
            objectId: projectComponent.id,
            name: projectComponentData.name ?? '-',
            coverPicture: {
              file: null,
              preview: coverPictureUrl,
            },
            area: projectComponentData.area ?? '',
            areaUnit: projectComponentData.areaUnit ?? '',
            purpose: projectComponentData.purpose ?? '', // TODO : add created
            description: projectComponentData.description ?? '',
            contactName: projectComponentData.contactName ?? '',
            contactPhone: projectComponentData.contactPhone ?? '',
            contactSecondaryPhone: projectComponentData.contactSecondaryPhone ?? '',
            status: projectComponentData.status ?? '',
            images: urlPictures ?? null,
            priority: projectComponentData.priority ?? '',
            project: projectComponent.get('project'),
            price: (projectComponent.get('useDownPayment') ? projectComponent.get('downPayment') : projectComponent.get('price')) ?? 0,
            downPayment: projectComponent.get('downPayment'),
            useDownPayment: projectComponent.get('useDownPayment'),
            soldAt: projectComponent.get('soldAt'),
            soldBy: projectComponent.get('soldBy'),
            sellingPrice: projectComponent.get('sellingPrice'),
          };

          return data;
        }
        return null;
      })
      .catch((error) => {
        console.log(error);
        // console.error('Error:', error);
        return null;
      });
  }

  public async getObjectByObjectId(objectId: string): Promise<Parse.Object | null> {
    const ProjectComponent = Parse.Object.extend(this.className);
    const query = new Parse.Query(ProjectComponent);

    query.include('coverPicture', 'project');

    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (projectComponent) => {
        return projectComponent;
      })
      .catch((error) => {
        console.log(error);
        // console.error('Error:', error);
        return null;
      });
  }

  public async updateByObjectId(objectId: string, object: ProjectComponentModel): Promise<any> {
    // IFormDetailProject | null
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const ProjectComponent = Parse.Object.extend(this.className);
      const query = new Parse.Query(ProjectComponent);
      const galleryRepository = new GalleryRepository();

      query.include('coverPicture', 'project');

      const projectComponent = await query.get(objectId, { useMasterKey: true });

      try {
        if (!projectComponent) {
          return false;
        }

        if (projectComponent) {
          if (object.name !== projectComponent.get('name')) {
            projectComponent.set('name', object.name?.toString());
          }

          if (object.contactName) {
            projectComponent.set('contactName', object.contactName.toString());
          }

          if (object.contactPhone) {
            projectComponent.set('contactPhone', object.contactPhone);
          }

          if (object.contactSecondaryPhone) {
            projectComponent.set('contactSecondaryPhone', object.contactSecondaryPhone);
          }

          if (object.area) {
            projectComponent.set('area', +object.area);
          }

          if (object.areaUnit) {
            projectComponent.set('areaUnit', object.areaUnit.toString());
          }

          if (object.status) {
            const status = object.status ? object.status : 'Available';
            projectComponent.set('status', status);
          }

          if (object.purpose) {
            projectComponent.set('purpose', object.purpose);
          }

          if (object.description) {
            projectComponent.set('description', object.description);
          }

          if (object.notes) {
            projectComponent.set('notes', object.notes);
          }

          if (object.status === 'Sold') {
            projectComponent.set('soldAt', object.soldAt);
            projectComponent.set('price', object.sellingPrice);
            projectComponent.set('sellingPrice', object.sellingPrice);
            if (object.soldBy) {
              const seller = await userRepository.getObjectById(object.soldBy);
              if (seller) {
                if (seller.get('role') === UserRole.executive) {
                  const salesExecutiveRepository = new SalesExecutiveRepository();
                  const salesExecutive = await salesExecutiveRepository.getObjectByUserId(seller.id);
                  if (salesExecutive) {
                    projectComponent.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                  }
                } else if (seller.get('role') === UserRole.manager) {
                  const managerRepository = new ManagerRepository();
                  const manager = await managerRepository.getObjectByUserId(seller.id);
                  if (manager) {
                    projectComponent.set('saleManagedBy', manager.get('user'));
                  }
                }
                projectComponent.set('soldBy', seller);
              } else {
                return null;
              }
            }
          } else {
            projectComponent.set('soldAt', null);
            projectComponent.set('soldBy', null);
            projectComponent.set('saleManagedBy', null);
            projectComponent.set('sellingPrice', null);
          }

          if (object.customFields) {
            projectComponent.set('customFields', object.customFields);
          }

          if (object.downPayment) {
            projectComponent.set('downPayment', object.downPayment);
          }

          if (object.price) {
            projectComponent.set('price', object.price);
          }

          if (object.useDownPayment !== null) {
            projectComponent.set('useDownPayment', object.useDownPayment);
          }

          if (object.attachments && object.attachments.length > 0) {
            const fileRepository = new FileRepository();
            let savedFiles: any[] = [];
            const attachmentFiles = object.attachments as File[];
            await Promise.all(
              attachmentFiles.map(async (file) => {
                const fileModel: FileModel = {
                  file: file,
                };
                const savedFile = await fileRepository.create(fileModel);
                if (savedFile) {
                  savedFiles.push(savedFile);
                }
              })
            );
            const attachmentsRelation = projectComponent.relation('attachments');
            attachmentsRelation.add(savedFiles);
          }

          projectComponent.set('updatedAt', object.updatedAt);
          projectComponent.set('updatedBy', currentUser);

          if (object.coverPicture && isFile(object.coverPicture.file)) {
            const galleryRepository = new GalleryRepository();
            const galleryModel: GalleryModel = {
              file: object.coverPicture.file,
            };
            const savedFile = await galleryRepository.createAndReturn(galleryModel);
            if (savedFile) {
              if (projectComponent.get('coverPicture')) {
                await galleryRepository.destroyImage(projectComponent.get('coverPicture').id);
              }
              projectComponent.set('coverPicture', savedFile);
            }
          }

          // const imagesFiles = object.pictures as Picture[];
          // if (isArray(imagesFiles) && imagesFiles.length > 0) {
          //   let savedFiles: any[] = [];

          //   // ! delete prev images
          //   const picturesRelation = projectComponent.relation('pictures');
          //   const getPrevPictures = await picturesRelation.query().find({ useMasterKey: true });
          //   if (getPrevPictures.length > 0) {
          //     await Promise.all(
          //       getPrevPictures.map(async (picture: any) => {
          //         const getPicture = picture.toJSON();

          //         const isPictureExist = imagesFiles.find((picture: any) => picture.id === getPicture.objectId);
          //         if (!isPictureExist) {
          //           await galleryRepository.destroyImage(getPicture.objectId);
          //         }
          //       })
          //     );
          //   }

          //   await Promise.all(
          //     imagesFiles.map(async (file) => {
          //       if (isFile(file)) {
          //         const galleryModel: GalleryModel = {
          //           file: file,
          //         };
          //         const savedFile = await galleryRepository.createAndReturn(galleryModel);
          //         if (savedFile) {
          //           savedFiles.push(savedFile);
          //         }
          //       }
          //     })
          //   );
          //   const galleriesRelation = projectComponent.relation('pictures');
          //   galleriesRelation.add(savedFiles);

          // }
          const savedComponent = await projectComponent.save(null, { useMasterKey: true });
          if (savedComponent) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  public async updateMultipleComponents(
    componentIds: string[],
    status: string | null,
    price: number,
    sellingPrice: number,
    soldAt: string,
    soldBy: string
  ) {
    const ProjectComponent = Parse.Object.extend(this.className);
    const query = new Parse.Query(ProjectComponent).containedIn('objectId', componentIds);
    const userRepository = new UserRepository();
    try {
      const components = await query.find({ useMasterKey: true });

      var success = true;
      for (const component of components) {
        if (status) {
          component.set('status', status);
          if (status === 'Sold') {
            component.set('soldAt', new Date(soldAt));
            component.set('price', sellingPrice);
            component.set('sellingPrice', sellingPrice);
            if (soldBy) {
              const seller = await userRepository.getObjectById(soldBy);
              if (seller) {
                if (seller.get('role') === UserRole.executive) {
                  const salesExecutiveRepository = new SalesExecutiveRepository();
                  const salesExecutive = await salesExecutiveRepository.getObjectByUserId(seller.id);
                  if (salesExecutive) {
                    component.set('saleManagedBy', salesExecutive.get('manager').get('user'));
                  }
                } else if (seller.get('role') === UserRole.manager) {
                  const managerRepository = new ManagerRepository();
                  const manager = await managerRepository.getObjectByUserId(seller.id);
                  if (manager) {
                    component.set('saleManagedBy', manager.get('user'));
                  }
                }
                component.set('soldBy', seller);
              } else {
                return null;
              }
            }
          } else {
            component.set('soldAt', null);
            component.set('soldBy', null);
            component.set('saleManagedBy', null);
            component.set('sellingPrice', null);
          }
        }
        if (price && price !== 0) {
          component.set('price', price);
        }

        await component.save(null, { useMasterKey: true }).then(
          (_: any) => {},
          (error: Error) => {
            success = false;
          }
        );
      }
      if (success) {
        Toast(`Inventory updated successfully.`, 'success');
        return true;
      } else {
        Toast(`Inventory could not be updated successfully.`, 'error');
        return false;
      }
    } catch (error) {
      console.error('Error updating inventory:', error);
      return false;
    }
  }

  public async getAll(
    projectId?: string,
    search?: string | null,
    status?: string | null,
    type?: string | null,
    purpose?: string | null,
    page?: number
  ): Promise<ProjectComponentModel[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const ProjectComponent = Parse.Object.extend(this.className);
      var query = new Parse.Query(ProjectComponent).descending('createdAt');

      if (projectId) {
        query.equalTo('project', Parse.Object.createWithoutData(projectId));
      }

      query.include('coverPicture', 'project');
      query.equalTo('organization', currentUser.get('organization'));
      query.limit(dataLimit);
      if (status && status !== '') query.equalTo('status', status);
      if (type && type !== '') query.equalTo('type', type);
      if (purpose && purpose !== '') query.equalTo('purpose', purpose);
      if (search) {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }

      if (page) {
        query.skip((page - 1) * dataLimit);
      }
      try {
        const components = await query.find({ useMasterKey: true });
        // Convert the Parse objects to ProjectModel
        const componentsDataResult: ProjectComponentModel[] = [];

        for (let i = 0; i < components.length; i++) {
          const projectComponent = components[i];
          const projectComponentData = projectComponent.toJSON();

          // ! Handling cover picture
          const coverPicture = projectComponent.get('coverPicture');
          let coverPictureUrl = IMGDefault;
          if (coverPicture) {
            coverPictureUrl = coverPicture.toJSON()?.file?.url;
          }

          // ! Handling pictures
          const picturesRelation = projectComponent.relation('pictures');

          // Query the relation to get the gallery
          const urlPictures: IImages[] = [];
          await picturesRelation
            .query()
            .find({ useMasterKey: true })
            .then((gallery: { [x: string]: any }) => {
              if (gallery) {
                if (gallery && gallery.length > 0) {
                  gallery.forEach((picture: any) => {
                    const getPicture = picture.toJSON();
                    if (getPicture) {
                      const picture = {
                        id: getPicture.objectId,
                        url: getPicture.file?.url,
                        file: getPicture.file,
                      };
                      urlPictures.push(picture);
                    }
                  });
                }
              } else {
                console.error('Gallery not found.');
              }
            })
            .catch((error: { message: string }) => {
              console.error('Error querying gallery relation: ' + error.message);
            });

          const data = {
            objectId: projectComponent.id,
            name: projectComponentData.name ?? '-',
            coverPicture: {
              file: null,
              preview: coverPictureUrl,
            },
            area: projectComponentData.area ?? '',
            areaUnit: projectComponentData.areaUnit ?? '',
            purpose: projectComponentData.purpose ?? '', // TODO : add created
            description: projectComponentData.description ?? '',
            contactName: projectComponentData.contactName ?? '',
            contactPhone: projectComponentData.contactPhone ?? '',
            contactSecondaryPhone: projectComponentData.contactSecondaryPhone ?? '',
            status: projectComponentData.status ?? '',
            images: urlPictures ?? null,
            priority: projectComponentData.priority ?? '',
            project: projectComponent.get('project'),
            price: (projectComponent.get('useDownPayment') ? projectComponent.get('downPayment') : projectComponent.get('price')) ?? 0,
            downPayment: projectComponent.get('downPayment'),
            useDownPayment: projectComponent.get('useDownPayment'),
            soldAt: projectComponent.get('soldAt'),
            soldBy: projectComponent.get('soldBy'),
            sellingPrice: projectComponent.get('sellingPrice'),
          };

          componentsDataResult.push(data);
        }

        return componentsDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project components:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getAllCount(projectId?: string, search?: string | null, status?: string | null, type?: string | null, purpose?: string | null): Promise<number> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const ProjectComponent = Parse.Object.extend(this.className);
      var query = new Parse.Query(ProjectComponent).descending('createdAt');

      if (projectId) {
        query.equalTo('project', Parse.Object.createWithoutData(projectId));
      }

      query.equalTo('organization', currentUser.get('organization'));
      if (status && status !== '') query.equalTo('status', status);
      if (type && type !== '') query.equalTo('type', type);
      if (purpose && purpose !== '') query.equalTo('purpose', purpose);
      if (search) {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }

      try {
        const projects = await query.count({ useMasterKey: true });
        return projects;
      } catch (error) {
        console.error('Error fetching project:', error);
        return 0;
      }
    } else {
      return 0;
    }
  }

  public async delete(id: string) {
    try {
      const ProjectComponent = Parse.Object.extend(this.className);
      const query = new Parse.Query(ProjectComponent).include('coverPicture');

      const projectComponent = await query.get(id, { useMasterKey: true });

      if (projectComponent) {
        if (projectComponent.get('coverPicture')) {
          await projectComponent.get('coverPicture').destroy({ useMasterKey: true });
        }

        const attachmentsRelation = projectComponent.relation('attachments');
        const attachments = await attachmentsRelation.query().find({ useMasterKey: true });

        for (let i = 0; i < attachments.length; i++) {
          await attachments[i].destroy({ useMasterKey: true });
        }

        const picturesRelation = projectComponent.relation('pictures');
        const pictures = await picturesRelation.query().find({ useMasterKey: true });

        for (let i = 0; i < pictures.length; i++) {
          await pictures[i].destroy({ useMasterKey: true });
        }

        await projectComponent.destroy({ useMasterKey: true });
        Toast(`Project component deleted successfully.`, 'success');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error deleting project:', error);
      return false;
    }
  }

  public async getSalesCountForOrganizationByYear(organizationId: string, year: number): Promise<number | null> {
    const ProjectComponent = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(ProjectComponent)
      .equalTo('status', 'Sold')
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('soldAt', yearStartDate)
      .lessThanOrEqualTo('soldAt', yearEndDate)
      .limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getAllAttachments(id: string): Promise<Parse.Object[] | null> {
    const ProjectComponent = Parse.Object.extend(this.className);
    const query = new Parse.Query(ProjectComponent);
    try {
      const projectComponent = await query.get(id, { useMasterKey: true });
      if (projectComponent) {
        const attachmentsQuery = projectComponent.relation('attachments').query().include('file').descending('createdAt');
        return new Promise((resolve, _) => {
          attachmentsQuery.find({ useMasterKey: true }).then(
            (attachments) => {
              if (attachments) {
                resolve(attachments);
              } else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async unlock(id: string): Promise<Parse.Object | null> {
    const ProjectComponent = Parse.Object.extend(this.className);
    const query = new Parse.Query(ProjectComponent);
    try {
      const projectComponent = await query.get(id, { useMasterKey: true });
      if (projectComponent) {
        projectComponent.set('lockedUntil', null);
        projectComponent.set('lockedFor', null);
        return new Promise((resolve, _) => {
          projectComponent.save(null, { useMasterKey: true }).then(
            (savedProjectComponent) => {
              resolve(savedProjectComponent);
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async deleteMultipleComponents(ProjectIds: string[]): Promise<boolean | null> {
    const ProjectComponent = Parse.Object.extend(this.className);
    const query = new Parse.Query(ProjectComponent).containedIn('objectId', ProjectIds);

    try {
      const projectComponents = await query.find({ useMasterKey: true });

      return new Promise((resolve, _) => {
        Parse.Object.destroyAll(projectComponents, { useMasterKey: true }).then(
          (_) => {
            Toast(`Inventory deleted successfully.`, 'success');
            resolve(true);
          },
          (error: Error) => {
            Toast(`Inventory could not be deleted successfully. The following error occurred: ${error.message}`, 'error');
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.error('Error deleting Project:', error);
      return false;
    }
  }
}

export default ProjectComponentRepository;
