import { useDispatch, useSelector } from 'react-redux';
import { IFormDetailProject, onShowModalBulk } from '../../../store/projects';
import { FilterDataFtn, IFormDetailProperty } from '../../../store/property';
import { ListProductsProps, ProductCategories } from '../../../utils/types/products';
import ListProductItem from '../../molecules/products/listProductItem';
import ListHeadTables from './listHeadTables.json';
// import { useProjects } from '../../../hooks';
import { globalColors } from '../../../utils/constants/color';
// import ModalBulkEdit from '../leads/modalBulkEdit';
import NotFoundLottie from '../../atoms/notFound';
import { UserRole } from '../../../store/user';
import { NavAddFilterView } from '../../molecules';
import { Typography, TableContainer, TableBody, TableRow, Table, TableHead, Paper, Checkbox } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import {
  selectBulkEdit,
  selectColor,
  selectPropertiesFilter,
  selectShowModalBulkEdit,
  selectThemeMode,
  selectUserRole,
} from '../../../store/selector';
import ModalBulkEditProduct from './modalBulkEditProduct';
import Paginator from '../../atoms/paginator';
import Loader from '../../atoms/loader';

/**
 * Renders a list of projects.
 * ! This component can be used for Projects and Properties
 *
 * @return {JSX.Element} The rendered list of projects.
 */
const ListProducts = (props: ListProductsProps) => {
  const dispatch = useDispatch();
  const showModalBulkEdit = useSelector(selectShowModalBulkEdit);
  const edit = useSelector(selectBulkEdit);
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const userRole = useSelector(selectUserRole);
  const filterData = useSelector(selectPropertiesFilter);

  const isCanEdit = userRole !== UserRole.executive;
  // const { isLoading, onDelete } = useProjects();
  const { category } = props;
  const dataProperties = props.datas as IFormDetailProperty[];
  const dataProjects = props.datas as IFormDetailProject[];
  const isProperty = category === ProductCategories.Property;
  const isProject = category === ProductCategories.Project;

  return (
    <>
      <NavAddFilterView
        onlyShowAddButton={(isProperty && dataProperties.length === 0) || (isProject && dataProjects.length === 0)}
        onChangeView={props.handleSwitchView}
        view={props.view}
        property={isProperty}
        onClickNewProduct={props.onClickNewProduct}
        titleButton={props.category === ProductCategories.Project ? `Add New Project` : `Add New Property`}
      />
      {props.isLoading ? (
        <Loader />
      ) : (
        <>
          <Typography
            sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
            onClick={() => dispatch(FilterDataFtn({ status: '', priceLow: 0, priceHigh: 0, location: '', minArea: 0, maxArea: 0 }))}
          >
            {filterData.status !== '' ||
            filterData.priceLow !== 0 ||
            filterData.priceHigh !== 0 ||
            filterData.location !== '' ||
            filterData.minArea !== 0
              ? 'Clear Filter'
              : ''}
          </Typography>
          {(isProperty && dataProperties.length > 0) || (isProject && dataProjects.length > 0) ? (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
                  border: `1px solid ${mode === 'dark' && globalColors.border.gray}`,
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  paddingBottom: '20px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  borderRadius: '10px',
                  marginTop: '20px',
                }}
              >
                {
                  <Table
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {edit && (
                            <Checkbox
                              sx={{
                                color: color || globalColors.blue,
                                '&.Mui-checked': {
                                  color: color || globalColors.secondBlue,
                                },
                              }}
                              onChange={props.handleHeaderCheckboxChange}
                              checked={
                                Object.values(props.rowCheckboxState).length > 0 &&
                                Object.values(props.rowCheckboxState).every((isChecked) => isChecked)
                              }
                            />
                          )}
                        </TableCell>
                        {isProperty ? (
                          <>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Properties.cell1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Properties.cell2}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Properties.cell3}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Properties.cell4}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Properties.cell5}
                              </Typography>
                            </TableCell>
                            {/* <TableCell>
                          <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                            {ListHeadTables.Properties.cell6}
                          </Typography>
                        </TableCell> */}
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                Actions
                              </Typography>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Projects.cell1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Projects.cell2}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Projects.cell3}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Projects.cell4}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                {ListHeadTables.Projects.cell5}
                              </Typography>
                            </TableCell>
                            {/* <TableCell>
                          <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                            {ListHeadTables.Projects.cell6}
                          </Typography>
                        </TableCell> */}
                            <TableCell>
                              <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
                                Actions
                              </Typography>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isProperty &&
                        dataProperties.map((data) => (
                          <ListProductItem
                            rowCheckboxState={props.rowCheckboxState}
                            setRowCheckboxState={props.setRowCheckboxState}
                            isCanEdit={isCanEdit}
                            edit={edit}
                            key={data.id}
                            mode={mode}
                            category={category}
                            data={data}
                          />
                        ))}
                      {isProject &&
                        dataProjects.map((data) => (
                          <ListProductItem
                            rowCheckboxState={props.rowCheckboxState}
                            setRowCheckboxState={props.setRowCheckboxState}
                            isCanEdit={isCanEdit}
                            edit={edit}
                            key={data.id}
                            mode={mode}
                            category={category}
                            data={data}
                          />
                        ))}
                    </TableBody>
                  </Table>
                }
              </TableContainer>
              <Paginator
                getTotalCount={props.getTotalCount}
                onChangePage={async (newPage) => {
                  await props.onChangePage(newPage);
                  return true;
                }}
              />
            </>
          ) : isProject ? (
            <NotFoundLottie
              showButton={userRole !== UserRole.executive}
              text={
                userRole !== UserRole.executive
                  ? 'Looks like you have not added any projects yet. Press the button below and add a new project right now.'
                  : 'Looks like your company has not added any projects yet. Contact your manager or admin to add a new project.'
              }
              buttonText="Add New Project"
              navigateTo="/projects/new"
              setAddNewPath="/projects"
            />
          ) : isProperty ? (
            <NotFoundLottie
              showButton={userRole !== UserRole.executive}
              text={
                userRole !== UserRole.executive
                  ? 'Looks like you have not added any properties yet. Press the button below and add a new property right now.'
                  : 'Looks like your company has not added any properties yet. Contact your manager or admin to add a new property.'
              }
              buttonText="Add New Property"
              navigateTo="/properties/new"
              setAddNewPath="/properties"
            />
          ) : (
            <></>
          )}
        </>
      )}

      <ModalBulkEditProduct
        rowCheckboxState={props?.rowCheckboxState}
        open={showModalBulkEdit}
        isProperty={isProperty}
        onClose={() => dispatch(onShowModalBulk(false))}
        mode={mode}
        color={color}
      />
    </>
  );
};

export default ListProducts;
