import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import { globalColors } from '../../../../../utils/constants/color';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../../../store/selector';
import { useNavigate } from 'react-router';
import { GoArrowUpRight } from 'react-icons/go';
import { setAddNew } from '../../../../../store/routes';
import { CompanyMember } from '.';
import { CiEdit } from 'react-icons/ci';

interface ContentRowProp {
  id: string;
  data: CompanyMember;
}

const ContentRow: React.FC<ContentRowProp> = ({ id, data }) => {
  // Menu
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  return (
    <TableRow
      sx={{
        backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
        width: '100%',
        color: mode === 'dark' ? globalColors.white : globalColors.black,
        borderRadius: '10px',
        gap: '20px',
        marginBottom: '20px',
        paddingBottom: '20px',
        borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
      }}
      key={id}
    >
      {/* Name */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {/* {data.name || '-'} */}
              {`${data.firstName} ${data.lastName}`}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* role */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data?.role || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* email */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                textTransform: 'none',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data?.email || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                textTransform: 'none',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data?.commissionPercentage}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      <TableCell sx={style} component="th" scope="row">
        <Box display={'flex'} gap={'10px'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '28px',
                letterSpacing: '-2%',
                textTransform: 'none',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
              }}
            >
              {data?.commissionPriority || '-'}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Profile */}
      <TableCell>
        <Box sx={{ width: '100%' }}>
          <CiEdit
            size={'1.3rem'}
            color={color || globalColors.blue}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch(setAddNew({ addNew: true, path: '/hr/members' }));
              navigate(`/hr/member/${id}`);
            }}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ContentRow;
