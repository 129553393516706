import { Box, Typography } from '@mui/material';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import CardTestimonials from '../../molecules/cardTestimonials';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
// import { globalColors } from '../../../utils/constants/color';

interface testimonial {
  description: string;
  name: string;
  role: string;
}

const Testinomals = () => {
  const testimonialsData: testimonial[] = [
    {
      description: "The seamless integration of lead management and property tracking in this CRM has revolutionized our approach — it's an indispensable tool for our team's success.",
      name: 'Maria Rodriguez',
      role: 'Senior Sales Manager'
    },
    {
      description: "It streamlines our processes and enhances our client interactions. As a Project Coordinator, I've found Property Cloud's ability to centralize customer data invaluable.",
      name: 'Ahmed Khan',
      role: 'Project Coordinator'
    },
    {
      description: "I find it instrumental in nurturing client relationships and maximizing sales opportunities. Managing converted customers efficiently is pivotal in our industry, and Property Cloud delivers.",
      name: 'Emily Chen',
      role: 'Marketing Director'
    },
    {
      description: "From employee performance tracking to property management, Property Cloud has become our backbone. I highly recommend it for its user-friendly interface and comprehensive features.",
      name: 'Javier Morales',
      role: 'Operations Manager'
    },
    {
      description: "As a Realtor, I rely on this CRM to streamline my day-to-day tasks. Its lead management and project organization functionalities are indispensable for staying ahead in a competitive market.",
      name: 'Priya Patel',
      role: 'Realtor'
    },
  ];
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  // const maxIndex = testimonialsData.length - 1;
  // const getCardsPerPage = () => {
  //   if (window.innerWidth >= 992) {
  //     return 3;
  //   } else {
  //     return 1;
  //   }
  // };

  const handleNext = () => {
    // const cardsPerPage = getCardsPerPage();
    // if (currentCardIndex + cardsPerPage <= maxIndex) {
    //   setCurrentCardIndex(currentCardIndex + cardsPerPage);
    // }
    setCurrentCardIndex((currentCardIndex + 1) % testimonialsData.length);
  };

  const handlePrev = () => {
    setCurrentCardIndex((currentCardIndex - 1 + testimonialsData.length) % testimonialsData.length);
  };

  return (
    <Box sx={{ padding: { lg: '5rem', xs: '3rem' } }}>
      <Typography sx={{ fontSize: { lg: '50px', sm: '30px', xs: '25px' } }}>
        <Typography sx={{ color: '#0455C6', margin: '0', marginBottom: '0px', fontSize: { lg: '50px', sm: '30px', xs: '25px' } }}>
          Testimonials:
        </Typography>
        What Clients think about us
      </Typography>
      <Box display={'flex'} gap={'4px'} justifyContent={'end'}>
        <Box
          onClick={() => handlePrev()}
          sx={{
            backgroundColor: '#FFFFFF0D',
            position: 'relative',
            borderRadius: '6px',
            padding: '2px 5px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <BsArrowLeftShort size={'1.5rem'} style={{ margin: 'auto' }} />
        </Box>
        <Box
          onClick={() => {
            handleNext();
          }}
          sx={{
            backgroundColor: '#FFFFFF0D',
            position: 'relative',
            borderRadius: '6px',
            padding: '2px 5px',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <BsArrowRightShort size={'1.5rem'} style={{ margin: 'auto' }} />
        </Box>
      </Box>
      <Box marginLeft={0}>
        <Carousel
          showIndicators={false}
          showThumbs={false}
          centerMode
          centerSlidePercentage={window.innerWidth > 600 ? 30 : 100}
          autoPlay={false}
          selectedItem={currentCardIndex}
          showStatus={false}
          showArrows={false}
          infiniteLoop
        >
          {testimonialsData.map((testimonial, index) => (
            <div key={index} style={{ margin: '30px 10px' }}>
              <CardTestimonials testimonial={testimonial} />
            </div>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Testinomals;
