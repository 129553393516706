import { useState } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../../utils/constants/color';
import { ColorStatusProduct } from '../../../../utils/types/products';
import { Gallery } from '../../../organisms';
import { CardProductItemStyle } from '../cardproducttItem.style';
import { selectColor, selectThemeMode } from '../../../../store/selector';
import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IFormDetailProject } from '../../../../store/projects';

const dummyData = ['Dev1', 'November'];

interface ProductItemProps {
  data: IFormDetailProject;
  active: boolean;
  key: string;
}

const ProjectCard = (props: ProductItemProps) => {
  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);

  const dataProject = props.data as IFormDetailProject;
  // const isProject = props.category === ProductCategories.Project;
  let styleStatusProperty = props.data.status ? ColorStatusProduct[props.data.status] : 'colorGray';

  // Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <CardProductItemStyle mode={mode}>
      <div style={{ justifyContent: 'flex-end' }} className="cover-wrapper">
        {props?.data?.cover?.preview && props?.active && (
          <img onClick={handleOpen} className="cover-image-product" src={props?.data?.cover?.preview} alt="project name" />
        )}
        <div className="count-photo-wrapper" onClick={() => (props.active ? handleOpen() : '')}>
          <CameraAltOutlinedIcon sx={{ color: mode === 'dark' ? globalColors.white : globalColors.black }} />
          <p style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>View Photos ({props?.data?.images?.length ?? 0})</p>
        </div>
      </div>
      <div className="description-wrapper">
        <h3 className={`title-project ${'property'}`}>
          {/* Name */}
          <span>{props?.data?.name ? props?.data?.name : 'Title'}</span>
        </h3>

        {/* consist of */}
        <p style={{ marginTop: '10px' }} className="detail">
          Consists of: {dataProject.consistOf ? dataProject.consistOf : 'Not Defined'} Floors
        </p>

        {/* type */}
        <p style={{ marginTop: '10px' }} className="detail">
          Type: {dataProject.type ? dataProject.type : 'Not Defined'}
        </p>

        {/* Square footage */}
        <p style={{ marginTop: '10px' }} className="detail">
          Square footage : 160 m2
        </p>

        {/* Interested */}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <span className="detail">Interested people</span>
          <Typography
            sx={{ display: 'flex', color: `${globalColors.white}` }}
            width={'10px'}
            fontSize={'12px'}
            paddingLeft={'0.2rem'}
            flexGrow={1}
            textAlign="left"
          >
            {dummyData.length > 0
              ? dummyData.length === 1
                ? dummyData[0]
                : dummyData.map((e: any, i: any) => {
                    return <AvatarContainer sx={{ marginLeft: i === 0 ? '' : '-8px !important' }}>{e.charAt(0).toUpperCase()}</AvatarContainer>;
                  })
              : ''}
          </Typography>
        </div>

        {/* Details */}
        <p className={`detail status ${styleStatusProperty}`}>{props?.data?.status ? props?.data?.status : 'Status'}</p>
      </div>
      <Gallery open={open} onClose={handleClose} images={props?.data?.images} />
    </CardProductItemStyle>
  );
};

const AvatarContainer = styled(Box)({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: '#101010',
  padding: '7px',
});

export default ProjectCard;
