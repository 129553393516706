import { Box, Typography } from '@mui/material';
import { BsArrowRight, BsBuilding, BsHouse } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalColors } from '../../../utils/constants/color';
import { selectColor, selectThemeMode } from '../../../store/selector';
import { useStyles } from './style';
import { PiHandshakeFill, PiUsersThreeLight } from 'react-icons/pi';
import { FaMoneyBills, FaUsersGear } from 'react-icons/fa6';
import { HiUserGroup } from 'react-icons/hi';
import OrganizationRepository from '../../../utils/api/repositories/organizationRepository';
import Loader from '../../atoms/loader';
import { number } from 'yargs';

interface InfoBoxProps {
  title: string;
  value: string;
  icon: React.ReactElement;
  mode: string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, value, icon, mode }) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoBox} bgcolor={mode === 'dark' ? globalColors.black : globalColors.white}>
      <Box className={classes.infoBoxContent}>
        {icon}
        <Typography className={classes.infoBoxTitle}>{title}</Typography>
      </Box>
      <Typography
        className={classes.infoBoxValue}
        paddingLeft={'10px'}
        marginTop={'auto'}
        color={mode === 'dark' ? globalColors.white : globalColors.black}
      >
        {value}
      </Typography>
    </Box>
  );
};

const Counts = () => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const classes = useStyles();
  const [projectsCount, setProjectsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [membersCount, setMembersCount] = useState({existing: 0, total: 0});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataCounts = async () => {
      const organizationRepository = new OrganizationRepository();
      const projectCount = await organizationRepository.getProjectsCount();
      if (projectCount) {
        setProjectsCount(projectCount);
      }
      const propertyCount = await organizationRepository.getPropertiesCount();
      if (propertyCount) {
        setPropertiesCount(propertyCount);
      }
      const leadCount = await organizationRepository.getLeadsCount();
      if (leadCount) {
        setLeadsCount(leadCount);
      }
      const memberCount = await organizationRepository.getSignedUpMembers();
      if (memberCount) {
        setMembersCount(memberCount);
      }
      setLoading(false);
    };

    fetchDataCounts();
  }, []);

  return (
    <Box
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      className={classes.card}
      paddingTop={'20px'}
      paddingBottom={'20px'}
      height={'100%'}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <InfoBox
            mode={mode}
            title="Projects"
            value={projectsCount.toString()}
            icon={<BsBuilding style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />}
          />
          <InfoBox
            mode={mode}
            title="Properties"
            value={propertiesCount.toString()}
            icon={<BsHouse style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />}
          />
          <InfoBox
            mode={mode}
            title="Leads"
            value={leadsCount.toString()}
            icon={
              <PiUsersThreeLight style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />
            }
          />
          <InfoBox
            mode={mode}
            title="Members"
            value={`${membersCount.existing} / ${membersCount.total}`}
            icon={<HiUserGroup style={{ margin: 'auto' }} size={'1.5rem'} color={color !== 'default' && color !== '' ? color : globalColors.blue} />}
          />
        </>
      )}
    </Box>
  );
};

export default Counts;
