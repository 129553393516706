import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Option } from '../../components/atoms/fieldSelectOption';
import ActivityModel from '../../utils/api/models/activityModel';
import ActivityRepository from '../../utils/api/repositories/activityRepository';
import UserRepository from '../../utils/api/repositories/userRepository';
import LeadRepository from '../../utils/api/repositories/leadRepository';
import { globalColors } from '../../utils/constants/color';

const useCalendarActivity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [optionExecutive, setOptionExecutive] = useState<{ id: string; value: string }[]>([]);
  const [optionLead, setOptionLead] = useState<{ id: string; value: string }[]>([]);

  const onAddNewActivity = async (values: ActivityModel) => {
    const activityRepository = new ActivityRepository();

    let startAppointment = values.start;
    let endAppointment = values.end;
    if (values.time) {
      const date = new Date(values.time);
      const timeArray = values.time.split(':');
      date.setHours(+timeArray[0]);
      date.setMinutes(+timeArray[1]);

      startAppointment = date;

      const endDate = new Date(date);
      endDate.setTime(date.getTime() + 1 * 60 * 60 * 1000);

      endAppointment = endDate;
    }
    const activity: ActivityModel = {
      title: values.title,
      start: startAppointment,
      end: endAppointment,
      // executive: values.executive,
      assignedTo: values.assignedTo,
      lead: values.lead,
      type: values.type,
      color: values.color,
      others: values.others,
      time: values.time,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    await activityRepository.create(activity);
  };

  const onEditActivity = async (objectId: string, values: ActivityModel) => {
    if (objectId) {
      const activityRepository = new ActivityRepository();
      const activity: ActivityModel = {
        title: values.title,
        start: values.start,
        end: values.end,
        executive: values.executive,
        assignedTo: values.assignedTo,
        lead: values.lead,
        type: values.type,
        color: values.color,
      };

      await activityRepository.update(objectId, activity);
    }
  };

  const getAllExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    const getUsers = await userRepository.getSalesExecutives(currentUser);
    const options: any[] = [{ id: '1', value: 'Select' }];
    if (getUsers) {
      getUsers.forEach((user) => {
        if (user.objectId) {
          // options.push(`${user?.firstName} ${user?.lastName}`);
          options.push({
            id: user.objectId,
            value: `${user?.firstName} ${user?.lastName}`,
            // value: user?.username,
          });
        }
      });
    }
    setOptionExecutive(options);
  };

  const getAllLead = async () => {
    // const leadRepository = new LeadRepository();
    // const userRepository = new UserRepository();
    // const currentUser = userRepository.getCurrentUser();
    // const getLeads = await leadRepository.getAll(currentUser);
    const activityRepository = new ActivityRepository();
    const getLeads = await activityRepository.getLeads();

    if (getLeads) {
      const options: { id: string; value: string }[] = [{ id: '1', value: 'Select' }];
      getLeads.forEach((lead) => {
        if (lead.id) {
          options.push({
            id: lead.id,
            value: lead?.name,
          });
          // options.push(lead?.name);

          setOptionLead(options);
        }
      });
    }
  };

  useEffect(() => {
    getAllExecutive();
    getAllLead();
  }, []);

  return {
    onAddNewActivity,
    onEditActivity,
    optionExecutive,
    optionLead,
  };
};

export default useCalendarActivity;
