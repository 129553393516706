import { Box, Typography } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../../../store/selector';
import { styled } from '@mui/material';
import Heading from '../../../atoms/heading';
import { useEffect, useState } from 'react';
import PropertyRepository from '../../../../utils/api/repositories/propertyRepository';
import Loader from '../../../atoms/loader';
import { getYearsArray } from '../../../../utils/helpers';
import StackChart from '../../../atoms/stackChart';
import YearSelector from '../../../atoms/yearSelector';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import ProjectRepository from '../../../../utils/api/repositories/projectRepository';
// import UserRepository from '../../../../utils/api/repositories/userRepository';
import Dropdown from '../../../atoms/dropdown';
import { Form, Formik } from 'formik';
import { IFormDetailProject } from '../../../../store/projects';
import Dropdowndata from '../../../atoms/dropdown2';

export interface FormValues {
  name: string;
  role: string;
  commission: string;
  email: string;
  phone: string;
  phone2: string;
}

interface QuarterlyData {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
}

const ProjectGraph = () => {
  const mode = useSelector(selectThemeMode);
  const [quarterlyData, setQuarterlyData] = useState<QuarterlyData>({ Q1: 0, Q2: 0, Q3: 0, Q4: 0 });
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState('');
  const [managers, setManagers] = useState<Parse.Object[]>([]);
  const [selectedStartYear, setSelectedStartYear] = useState<number>(new Date().getFullYear());
  const [selectedEndYear, setSelectedEndYear] = useState<number>(new Date().getFullYear());
  const [projects, setProjects] = useState<IFormDetailProject[]>([]);
  const [selectedManagerId, setSelectedManagerId] = useState('');

  useEffect(() => {
    const getAllProjects = async () => {
      const projectRepository = new ProjectRepository();
      const allProjects = await projectRepository.getAll();
      if (allProjects) {
        setProjects(allProjects);
      }
    };
    getAllProjects();
  }, []);

  useEffect(() => {
    const fetchYearData = async () => {
      setLoading(true);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const organizationId = currentUser.get('organization').id;
        if (organizationId) {
          const projectRepository = new ProjectRepository();
          const data = await projectRepository.getTotalSalesForOrganizationByRange(
            organizationId,
            selectedStartYear,
            selectedEndYear,
            selectedProject === 'all' ? '' : selectedProject,
            selectedManagerId === 'all' ? '' : selectedManagerId
          );
          if (data) {
            setQuarterlyData(data);
          }
        }
      }
      setLoading(false);
    };

    fetchYearData();
  }, [selectedStartYear, selectedEndYear, selectedProject, selectedManagerId]);

  useEffect(() => {
    const fetchManagers = async () => {
      const userRepository = new UserRepository();
      const allManagers = await userRepository.getAllManagers();
      if (allManagers) {
        setManagers(allManagers);
      }
    };
    fetchManagers();
  }, []);

  return (
    <CardContainer
      sx={{
        flexDirection: 'column',
        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
      }}
    >
      {loading ? (
        <Box height={'346px'} display={'flex'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            paddingBottom={'20px'}
            paddingLeft={'30px'}
            paddingRight={'30px'}
            paddingTop={'10px'}
          >
            <Heading text={'Revenue'} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box>
                <YearSelector
                  label="Start"
                  mode={mode}
                  values={getYearsArray()}
                  selectedValue={selectedStartYear}
                  setSelectedValue={setSelectedStartYear}
                />
              </Box>
              <Box sx={{ marginLeft: '5px' }}>
                <YearSelector
                  label="End"
                  mode={mode}
                  values={getYearsArray()}
                  selectedValue={selectedEndYear}
                  setSelectedValue={setSelectedEndYear}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection={'column'} paddingBottom={'20px'}>
            <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'center'} gap={'10px'}>
              {projects.length > 0 && (
                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                  <Box width={'220px'} paddingLeft={'0.5rem'} paddingRight={'0.5rem'}>
                    <Formik
                      initialValues={{
                        project: '',
                      }}
                      validate={(values) => {}}
                      onSubmit={async (values, { resetForm, setSubmitting }) => {
                        // await handleFilterValues(values);

                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form>
                          <Dropdowndata
                            mode={mode}
                            id="category"
                            name="category"
                            values={[
                              { label: 'All Projects', value: 'all' },
                              ...projects.map((project) => {
                                return { label: project.name, value: project.id };
                              }),
                            ]}
                            value={selectedProject}
                            onChange={(e) => {
                              setSelectedProject(e.target.value);
                            }}
                            defaultValue={''}
                          />
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              )}
              {managers.length > 0 && (
                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                  <Box width={'220px'} paddingLeft={'0.5rem'} paddingRight={'0.5rem'}>
                    <Formik
                      initialValues={{
                        manager: '',
                      }}
                      validate={(values) => {}}
                      onSubmit={async (values, { resetForm, setSubmitting }) => {
                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form>
                          <Dropdowndata
                            mode={mode}
                            id="manager"
                            name="manager"
                            values={[
                              { label: 'All Teams', value: 'all' },
                              ...managers.map((manager) => {
                                return { label: manager.get('firstName') + ' ' + manager.get('lastName'), value: manager.id };
                              }),
                            ]}
                            value={selectedManagerId}
                            onChange={(e) => {
                              setSelectedManagerId(e.target.value);
                            }}
                            defaultValue={'all'}
                          />
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '300px',
                padding: '10px 20px',
              }}
            >
              <StackChart
                data={[
                  { name: 'Q1', uv: quarterlyData['Q1'], pv: quarterlyData['Q1'], amt: quarterlyData['Q1'] },
                  { name: 'Q2', uv: quarterlyData['Q2'], pv: quarterlyData['Q2'], amt: quarterlyData['Q2'] },
                  { name: 'Q3', uv: quarterlyData['Q3'], pv: quarterlyData['Q3'], amt: quarterlyData['Q3'] },
                  { name: 'Q4', uv: quarterlyData['Q4'], pv: quarterlyData['Q4'], amt: quarterlyData['Q4'] },
                ]}
              />
            </Box>
          </Box>
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  // marginTop: '3%',
  borderRadius: '16px',
  border: `1px solid ${globalColors?.border.gray}`,
});

const Card = styled(Box)(({ mode }: { mode: any }) => ({
  width: '30%',
  height: '240px',
  border: `1px solid ${globalColors.black}`,
  backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.white,
  borderRadius: '32px',
  padding: '10px 20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
}));

export default ProjectGraph;
