import { globalColors } from '../../../utils/constants/color';

interface CrossFileContainerProps {
  selectedFiles: any[];
  mode: string;
  color: string;
}

export const crossFileContainerStyle = ({ selectedFiles, color, mode }: CrossFileContainerProps) => ({
  padding: '5px 1% 5px 1%',
  height: 48,
  borderRadius: '12px',
  border: `1px solid transparent`,
  bgcolor: selectedFiles.length > 0 ? (mode === 'dark' ? globalColors.black : globalColors.white) : 'transparent',
  '&:hover': {
    border: `1px solid ${color || globalColors.blue}`,
  },
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface IconButtonProps {
  theme: any;
  mode: string;
}

export const iconButtonStyle = ({ theme, mode }: IconButtonProps) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '8px',
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  //   backgroundColor: globalColors.black,
});
