import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StatusProduct } from '../utils/types/products';
import ProjectComponentModel from '../utils/api/models/projectComponentModel';
import { CustomField } from '../components/molecules/customFields';

// TODO : make it global types
export enum EnumViews {
  Cards = 'Cards',
  List = 'List',
}

// TODO : make it global types
export interface ICover {
  files: File[];
  preview: string;
}

export interface IImages {
  id: string;
  file?: File;
  url: string;
}

export interface IFormDetailProject {
  id: string;
  cover: ICover;
  name: string;
  type: string;
  country: string;
  state: string;
  city: string;
  address: string;
  consistOf: string;
  consistOfUnit: string;
  area: string;
  areaUnit: string;
  purpose: string;
  description: string;
  contactName: string;
  contactPhone: string;
  contactSecondaryPhone: string;
  status: StatusProduct | '';
  images: IImages[];
  priority: string;
  sellingPrice?: string;
  soldAt?: string;
  soldBy?: string;
  attachments?: File[] | Parse.File[];
  components?: ProjectComponentModel[];
  customFields?: CustomField[];
  lockedUntil?: null | Date;
  lockedFor?: null | Parse.Object;
}

interface FilterData {
  status: string;
  type: string;
  purpose: string;
}
interface ProjectDataState {
  isLoading: boolean;
  viewActive: EnumViews;
  Search: string;
  formProject: IFormDetailProject;
  projects: IFormDetailProject[];
  showModalBulkEdit: boolean;
  filterData: FilterData;
  refresh: boolean;
}

const initialState: ProjectDataState = {
  isLoading: false,
  showModalBulkEdit: false,
  viewActive: EnumViews.Cards,
  projects: [],
  Search: '',
  formProject: {
    id: '',
    cover: {
      files: [],
      preview: '',
    },
    name: '',
    type: '',
    country: 'Pakistan',
    state: '',
    city: '',
    address: '',
    consistOf: '',
    consistOfUnit: 'Floor',
    area: '',
    areaUnit: '',
    purpose: '',
    description: '',
    contactName: '',
    contactPhone: '',
    contactSecondaryPhone: '',
    status: '',
    images: [],
    priority: '',
    sellingPrice: '0',
    soldAt: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    soldBy: '',
    attachments: [],
    components: [],
    customFields: [],
    lockedFor: null,
    lockedUntil: null,
  },
  filterData: {
    status: '',
    type: '',
    purpose: '',
  },
  refresh: false
};
const ProjectReducer = createSlice({
  name: 'Project Data',
  initialState,
  reducers: {
    // * Root project page
    onSwitchView: (state, action: PayloadAction<EnumViews>) => {
      state.viewActive = action.payload;
    },
    refreshProjects: (state) => {
      state.refresh = !state.refresh;
    },
    // * Detail Project
    updateFieldProject: (state, action: PayloadAction<{ field: keyof IFormDetailProject; value: string }>) => {
      const { field, value } = action.payload;

      // * Adjustment type
      if (field === 'type') {
        if (value === 'Horizontal') {
          state.formProject.consistOfUnit = 'Block(s)';
        } else if (value === 'Vertical') {
          state.formProject.consistOfUnit = 'Floor(s)';
        }
      }

      state.formProject = { ...state.formProject, [field]: value };
    },
    updateCover: (state, action: PayloadAction<{ value: ICover }>) => {
      const { value } = action.payload;

      // * Handling changes images
      state.formProject = {
        ...state.formProject,
        cover: value,
      };
    },
    updateImages: (state, action: PayloadAction<{ newImages: IImages[] }>) => {
      const { newImages } = action.payload;
      const stateImages = state.formProject.images;
      newImages.forEach((images) => {
        const isImageExist = stateImages.find((image) => image.id === images.id);
        if (!isImageExist) {
          stateImages.push(images);
        }
      });

      // * Handling changes images
      state.formProject = {
        ...state.formProject,
        images: stateImages,
      };
    },
    updateAttachments: (state, action: PayloadAction<{ files: File[] }>) => {
      state.formProject = {
        ...state.formProject,
        attachments: action.payload.files,
      };
    },
    updateCustomFields: (state, action: PayloadAction<{ customFields: CustomField[] }>) => {
      state.formProject = {
        ...state.formProject,
        customFields: action.payload.customFields,
      };
    },
    updateComponents: (state, action: PayloadAction<{ component: ProjectComponentModel }>) => {
      if (state.formProject.components) {
        state.formProject = {
          ...state.formProject,
          components: [...state.formProject.components, action.payload.component],
        };
      }
    },
    fetchComponents: (state, action: PayloadAction<{ components: ProjectComponentModel[] }>) => {
      state.formProject = {
        ...state.formProject,
        components: action.payload.components,
      };
    },
    editComponent: (state, action: PayloadAction<{ component: ProjectComponentModel; idx: number }>) => {
      if (state.formProject.components) {
        const temp = [...state.formProject.components];
        temp[action.payload.idx] = action.payload.component;
        state.formProject = {
          ...state.formProject,
          components: temp,
        };
      }
    },
    removeComponent: (state, action: PayloadAction<{ idx: number }>) => {
      if (state.formProject.components) {
        state.formProject = {
          ...state.formProject,
          components: state.formProject.components.filter((value, i) => i !== action.payload.idx),
        };
      }
    },
    deleteImageProject: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const stateImages = state.formProject.images;
      // delete image by id from array stateImages
      const updatedImages = stateImages.filter((image) => image.id !== id);

      state.formProject = {
        ...state.formProject,
        images: updatedImages,
      };
    },
    addNewProject: (state, action: PayloadAction<IFormDetailProject>) => {
      state.projects = [...state.projects, action.payload];
    },
    setDataProjects: (state, action: PayloadAction<IFormDetailProject[]>) => {
      state.projects = action.payload;
    },
    resetFormProject: (state) => {
      state.formProject = initialState.formProject;
    },
    setFormEditProject: (state, action: PayloadAction<IFormDetailProject>) => {
      state.formProject = action.payload;
    },
    updateProject: (state, action: PayloadAction<IFormDetailProject>) => {
      state.projects = state.projects.map((project) => (project.id === action.payload.id ? action.payload : project));
    },
    deleteCoverProject: (state) => {
      state.formProject = {
        ...state.formProject,
        cover: {
          files: [],
          preview: '',
        },
      };
    },
    onProjectLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    onShowModalBulk: (state, action: PayloadAction<boolean>) => {
      state.showModalBulkEdit = action.payload;
    },
    ProjectFilterDataFtn: (state, action: PayloadAction<FilterData>) => {
      state.filterData = action.payload;
    },
    SearchProject: (state, action: PayloadAction<string>) => {
      state.Search = action.payload;
    },
  },
});

export const {
  refreshProjects,
  onSwitchView,
  updateFieldProject,
  addNewProject,
  updateCover,
  ProjectFilterDataFtn,
  SearchProject,
  updateProject,
  resetFormProject,
  setFormEditProject,
  updateImages,
  updateAttachments,
  updateCustomFields,
  deleteImageProject,
  setDataProjects,
  deleteCoverProject,
  onProjectLoading,
  onShowModalBulk,
  updateComponents,
  fetchComponents,
  removeComponent,
  editComponent,
} = ProjectReducer.actions;
export default ProjectReducer.reducer;
