// import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import ManagerModel from '../models/managerModel';
import UserRepository from './userRepository';

class ManagerRepository {
  private className = 'Manager';

  constructor() {}

  public async create(object: ManagerModel) {
    try {
      const Manager = Parse.Object.extend(this.className);
      const managerObject = new Manager();
      managerObject.set('user', object.user);
      // if (object.managing) {
      //   var managingRelation = managerObject.relation('managing');
      //   for (let i = 0; i < (object.managing as any[]).length; i++) {
      //     managingRelation.add((object.managing as any)[i]);
      //   }
      // }

      return new Promise((resolve, _) => {
        managerObject.save(null, { useMasterKey: true }).then(
          (savedManager: any) => {
            resolve(savedManager);
          },
          (error: any) => {
            console.log(error);
            resolve(null);
          }
        );
      });
    } catch (error) {
      // console.error('Error adding note:', error);
      return null;
    }
  }
 
  public async addManaging(savedManager: Parse.Object, object: ManagerModel) {
    try {
      if (object.managing) {
        var managingRelation = savedManager.relation('managing');
        for (let i = 0; i < (object.managing as any[]).length; i++) {
          managingRelation.add((object.managing as any)[i]);
        }
      }
      
      return new Promise((resolve, _) => {
        savedManager.save(null, { useMasterKey: true }).then(
          (savedManager: any) => {
            resolve(savedManager);
          },
          (error: any) => {
            console.log(error);
            resolve(null);
          }
        );
      });
    } catch (error) {
      // console.error('Error adding note:', error);
      return null;
    }
    
  }

  // public async getObjectByUserId(id: string): Promise<Parse.Object | null> {
  //   try {
  //     const User = Parse.User;
  //     const userQuery = new Parse.Query(User).equalTo('objectId', id);

  //     const Manager = Parse.Object.extend(this.className);
  //     const query = new Parse.Query(Manager).matchesQuery('user', userQuery);

  //     query.include(['leads', 'activities']);

  //     return new Promise<Parse.Object | null>((resolve, _) => {
  //       query
  //         .first({ useMasterKey: true })
  //         .then(async (manager) => {
  //           if (manager) {
  //             resolve(manager);
  //           }
  //           resolve(null);
  //         })
  //         .catch((error) => {
  //           resolve(null);
  //         });
  //     });
  //   } catch (error) {
  //     return null;
  //   }
  // }

  public async getObjectByUserId(id: string): Promise<Parse.Object | null> {
    try {
      const User = Parse.User;
      const userQuery = new Parse.Query(User).equalTo('objectId', id);
  
      const Manager = Parse.Object.extend(this.className);
      const managerQuery = new Parse.Query(Manager).matchesQuery('user', userQuery);
  
      // Fetch the first matching manager object
      const manager = await managerQuery.first({ useMasterKey: true });
  
      if (manager) {
         const managingRelation = manager.relation('managing');
        const relatedQuery = managingRelation.query();
  
         relatedQuery.include('user');
        relatedQuery.include('manager');
  
         const relatedManaging = await relatedQuery.find({ useMasterKey: true });
  
    
         manager.set('relatedManaging', relatedManaging);
  
         return manager;
      } else {
        return null;  
      }
    } catch (error) {
      console.error('Error fetching manager by user objectId:', error);
      return null;
    }
  }

  public async addLeadToCurrentUser(lead: Parse.Object): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const User = Parse.User;
      const userQuery = new Parse.Query(User).equalTo('objectId', currentUser.id);

      const Manager = Parse.Object.extend(this.className);
      const query = new Parse.Query(Manager).matchesQuery('user', userQuery);

      return new Promise((resolve, _) => {
        query
          .first({ useMasterKey: true })
          .then(async (manager) => {
            if (manager) {
              var managerLeads = manager.relation('leads');
              managerLeads.add(lead);
              const savedManager = await manager.save(null, { useMasterKey: true });
              resolve(savedManager);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  // public async addLeadToSpecificUser(lead: Parse.Object, userId: string): Promise<Parse.Object | null> {
  //   const userRepository = new UserRepository();
  //   const user = await userRepository.getObjectById(userId);
  //   if (user) {
  //     const User = Parse.User;
  //     const userQuery = new Parse.Query(User).equalTo('objectId', user.id);

  //     const Manager = Parse.Object.extend(this.className);
  //     const query = new Parse.Query(Manager).matchesQuery('user', userQuery);

  //     return new Promise((resolve, _) => {
  //       query
  //         .first({ useMasterKey: true })
  //         .then(async (manager) => {
  //           if (manager) {
  //             var managerLeads = manager.relation('leads');
  //             managerLeads.add(lead);
  //             const savedManager = await manager.save(null, { useMasterKey: true });
  //             resolve(savedManager);
  //           } else {
  //             resolve(null);
  //           }
  //         })
  //         .catch((error) => {
  //           resolve(null);
  //         });
  //     });
  //   } else {
  //     return null;
  //   }
  // }

  public async addLeadsToSpecificUser(leads: Parse.Object, userId: string): Promise<Parse.Object | null> {
    const userRepository = new UserRepository();
    const user = await userRepository.getObjectById(userId);

    if (user) {
      const User = Parse.User;
      const userQuery = new Parse.Query(User).equalTo('objectId', user.id);

      const Manager = Parse.Object.extend(this.className);
      const query = new Parse.Query(Manager).matchesQuery('user', userQuery);

      return new Promise((resolve, _) => {
        query
          .first({ useMasterKey: true })
          .then(async (manager) => {
            if (manager) {
              var managerLeads = manager.relation('leads');
              managerLeads.add(leads);
              const savedManager = await manager.save(null, { useMasterKey: true });
              resolve(savedManager);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } else {
      return null;
    }
  }

  public async getManaging(id: string) {
    try {
      const User = Parse.User;
      const userQuery = new Parse.Query(User).equalTo('objectId', id);

      const Manager = Parse.Object.extend(this.className);
      const query = new Parse.Query(Manager).matchesQuery('user', userQuery);

      query.include(['leads', 'managing']);

      return new Promise((resolve, _) => {
        query
          .first({ useMasterKey: true })
          .then(async (manager) => {
            if (manager) {
              const managingRelationQuery = manager.relation('managing').query();
              const managingUsers = await managingRelationQuery.find({ useMasterKey: true });

              resolve(managingUsers);
            } else {
              console.log('Organization not found.');
              resolve(null);
            }
          })
          .catch((error) => {
            resolve(null);
          });
      });
    } catch (error) {
      console.error('Error fetching users:', error);
      return null;
    }
  }

  
}

export default ManagerRepository;
