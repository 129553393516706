import { Box, Grid, Typography } from '@mui/material';
import Sidebar from '../../../molecules/sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import Heading from '../../../atoms/heading';
import { useEffect, useState } from 'react';
import Barchart from '../../../organisms/charts/barCharts';
import PropertyCard from './propertyCards';
import ContentTable from './contentTable';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import PropertyGraph from './propertyGraph';
import { formatMoney } from '../../../../utils/helpers';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import PropertyRepository from '../../../../utils/api/repositories/propertyRepository';
import TopPerformerProperty from './topPerformer';

const PropertyPerfomance = () => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigation = useSelector(selectNavigationLayout);
  const [loadingFigures, setLoadingFigures] = useState(true);
  const [salesCount, setSalesCount] = useState(0);
  const [totalPropertiesCount, setTotalPropertiesCount] = useState(0);
  const [soldPropertiesCount, setSoldPropertiesCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const fetchCompanyFigures = async () => {
      const organizationRepository = new OrganizationRepository();
      const sales = await organizationRepository.getTotalSales(true);
      if (sales) {
        setSalesCount(sales);
      }
      const totalProperties = await organizationRepository.getPropertiesCount();
      if (totalProperties) {
        setTotalPropertiesCount(totalProperties);
      }
      const soldProperties = await organizationRepository.getSoldPropertiesCount();

      if (soldProperties) {
        setSoldPropertiesCount(soldProperties);
      }
      setLoadingFigures(false);
    };

    fetchCompanyFigures();
    setLoading(false);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Properties Performance'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? '80px' : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          <Box width={'100%'} padding={3}>
            <Box display={'flex'} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} justifyContent={'space-between'} gap={'30px'}>
              <PropertyCard value={formatMoney(salesCount)} title={'Total Sales'} loading={loadingFigures} iconName={'Sales'} />
              <PropertyCard value={totalPropertiesCount} title={'Total Properties'} loading={loadingFigures} iconName={'Properties'} />
              <PropertyCard value={soldPropertiesCount} title={'Total Sold'} loading={loadingFigures} iconName={'Sold'} />
              <PropertyCard
                value={totalPropertiesCount - soldPropertiesCount}
                title={'Total Pending'}
                loading={loadingFigures}
                iconName={'Pending'}
              />
            </Box>
            <TopPerformerProperty />
            <Box width={'100%'} paddingTop={1} paddingBottom={1}>
              <Box marginTop={'1%'}>
                <PropertyGraph />
              </Box>
            </Box>
            <ContentTable />
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

export default PropertyPerfomance;
