import Parse from 'parse';

const test = false;

export function initializeParse() {
  if (test) {
    Parse.initialize(process.env.REACT_APP_PARSE_APP_ID_TEST ?? '', process.env.REACT_APP_PARSE_JAVASCRIPT_KEY_TEST ?? '');
    Parse.serverURL = process.env.REACT_APP_SERVER_URL_TEST ?? '';
    Parse.masterKey = process.env.REACT_APP_PARSE_MASTER_KEY_TEST ?? '';
    Parse.encryptedUser = false;
  } else {
    Parse.initialize(process.env.REACT_APP_PARSE_APP_ID ?? '', process.env.REACT_APP_PARSE_JAVASCRIPT_KEY ?? '');
    Parse.serverURL = process.env.REACT_APP_SERVER_URL ?? '';
    Parse.masterKey = process.env.REACT_APP_PARSE_MASTER_KEY ?? '';
    Parse.encryptedUser = false;
  }
}
