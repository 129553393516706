import { Toast } from '../../../components/atoms';
import Parse, { User } from 'parse';
import EmailModel from '../models/emailModel';
import UserRepository from './userRepository';
import { UserRole } from '../../../store/user';
import { dataLimit } from '../../helpers';

class EmailRepository {
  private className = 'Email';

  public async createEmail(object: EmailModel, scheduled: boolean, scheduledAt: Date | null): Promise<Parse.Object | null> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const Email = Parse.Object.extend(this.className);
        const newEmail = new Email();

        newEmail.set('user', currentUser);
        newEmail.set('organization', currentUser.get('organization'));
        newEmail.set('to', object.to);
        newEmail.set('CC', object.CC || []);
        newEmail.set('subject', object.subject || '');
        newEmail.set('text', object.text || '');
        newEmail.set('scheduled', scheduled);

        if (scheduled && scheduledAt !== null) {
          newEmail.set('scheduledAt', scheduledAt);
        }

        const savedEmail = await newEmail.save(null, { useMasterKey: true });
        if (savedEmail) {
          const sendEmail = await Parse.Cloud.run(
            'sendEmail',
            {
              emailId: savedEmail.id,
            },
            { useMasterKey: true }
          );
          if (sendEmail && sendEmail.status === 'success') {
            return savedEmail;
          } else {
            await savedEmail.destroy({ useMasterKey: true });
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error saving email: ${error.message}`, 'error');
      return null;
    }
  }

  public async getEmailsCount(from?: string, to?: string, createdAt?: string | number | Date): Promise<number> {
    try {
      const Email = Parse.Object.extend('Email');
      const query = new Parse.Query(Email);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        if (currentUser.get('role') === UserRole.admin) {
          query.equalTo('organization', currentUser.get('organization'));
        } else {
          query.equalTo('user', currentUser);
        }
        query.include('user');
        query.ascending('createdAt');

        if (to) {
          const regexTo = new RegExp(to, 'i');
          query.containsAll('to', [regexTo]);
        }

        if (from) {
          const userQuery = new Parse.Query(User);
          userQuery.matches('username', new RegExp(from, 'i'));
          query.matchesQuery('user', userQuery);
        }

        if (createdAt) {
          const createdDate = new Date(createdAt);
          query.greaterThanOrEqualTo('createdAt', createdDate);
        }

        const emails = await query.count({ useMasterKey: true });
        return emails;
      } else {
        return 0;
      }
    } catch (error: any) {
      console.log(error);
      // Assuming Toast is a function to display messages
      Toast(`Error retrieving emails: ${error.message}`, 'error');
      return 0;
    }
  }

  public async getEmails(
    from?: string,
    to?: string,
    createdAt?: string | number | Date,
    orderBy: string = 'New',
    page?: number
  ): Promise<Parse.Object[]> {
    try {
      const Email = Parse.Object.extend('Email');
      const query = new Parse.Query(Email).limit(dataLimit);
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();

      if (currentUser) {
        if (currentUser.get('role') === UserRole.admin) {
          query.equalTo('organization', currentUser.get('organization'));
        } else {
          query.equalTo('user', currentUser);
        }
        query.include('user');
        query.ascending('createdAt');

        if (to) {
          const regexTo = new RegExp(to, 'i');
          query.containsAll('to', [regexTo]);
        }

        if (from) {
          const userQuery = new Parse.Query(User);
          userQuery.matches('username', new RegExp(from, 'i'));
          query.matchesQuery('user', userQuery);
        }

        if (createdAt) {
          const createdDate = new Date(createdAt);
          query.greaterThanOrEqualTo('createdAt', createdDate);
        }

        if (orderBy == 'New') {
          query.descending('createdAt');
        } else {
          query.ascending('createdAt');
        }

        if (page) {
          query.skip((page - 1) * dataLimit);
        }

        const emails = await query.find({ useMasterKey: true });

        const emailData: any[] = emails.map((email) => {
          const user = email.get('user');
          const userName = currentUser ? user.get('username') : '';

          return {
            objectId: email.id,
            to: email.get('to'),
            CC: email.get('CC'),
            subject: email.get('subject'),
            text: email.get('text'),
            senderName: userName,
            createdAt: email.createdAt,
            updatedAt: email.updatedAt,
            // Add other fields as needed
          };
        });

        return emailData;
      } else {
        return [];
      }
    } catch (error: any) {
      console.log(error);
      // Assuming Toast is a function to display messages
      Toast(`Error retrieving emails: ${error.message}`, 'error');
      return [];
    }
  }

  public async deleteEmail(userId: string, emailId: string): Promise<boolean> {
    try {
      const Email = Parse.Object.extend('Email');
      const User = Parse.Object.extend('_User');
      const userPointer = User.createWithoutData(userId);

      const query = new Parse.Query(Email);
      query.equalTo('user', userPointer);
      query.equalTo('objectId', emailId);

      const emailToDelete = await query.first({ useMasterKey: true });

      if (emailToDelete) {
        // Delete the email
        await emailToDelete.destroy({ useMasterKey: true });
        console.log('Email deleted successfully.');
        Toast('Email deleted successfully.', 'success');
        return true;
      } else {
        console.log('Email not found.');
        Toast('Email not found.', 'warning');
        return false;
      }
    } catch (error: any) {
      console.log(error);
      Toast(`Error deleting email: ${error.message}`, 'error');
      return false;
    }
  }
}

export default EmailRepository;
