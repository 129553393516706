import { Box, Grid, Checkbox, Typography } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import * as React from 'react';
// import ViewLeadsiFlter from '../../components/organisms/leads/filter';
// import ViewType from '../../components/organisms/leads/viewType';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, SearchInput } from '../../components/atoms';
import LeadRepository from '../../utils/api/repositories/leadRepository';
import LeadModel from '../../utils/api/models/leadModel';
import Loader from '../../components/atoms/loader';
import BulkEditLeads from '../../components/organisms/leads/bulkEdit';
import UserRepository from '../../utils/api/repositories/userRepository';
import ButtonView from '../../components/molecules/buttonView';
import NotFoundLottie from '../../components/atoms/notFound';
import { EnumViews } from '../../store/projects';
import {
  selectBulkEdit,
  selectClientsFilter,
  selectColor,
  selectLeadState,
  selectLeadsFilter,
  selectLoading,
  selectNavigationLayout,
  selectThemeMode,
} from '../../store/selector';
import { ClientFilterDataFtn, onSwitchView } from '../../store/slices';
import { setAddNew } from '../../store/routes';
import { UserRole } from '../../store/user';
import { hasPermissionForAction } from '../../utils/helpers';
import NoPermission from '../../components/atoms/noPermission';
import SalesExecutiveRepository from '../../utils/api/repositories/salesExecutiveRepository';
import ClientsCard from '../../components/organisms/clients/clientsCardView';
import ClientsList from '../../components/organisms/clients/clientsListView';
import FilterClients from '../../components/organisms/clients/filterClients';
import Paginator from '../../components/atoms/paginator';

type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

const ViewClients = () => {
  const navigate = useNavigate();
  const leadViewState = useSelector(selectLeadState);
  const dispatch = useDispatch();
  const [view, setview] = useState<EnumViews | string>(leadViewState || EnumViews.Cards);

  const [loading, setloading] = useState<boolean>(true);
  const [search, setsearch] = useState<string>('');
  const [showLead, setshowLead] = useState<LeadModel[]>([]);
  const [openBulkEditLeadModal, setOpenBulkEditLeadModal] = useState<boolean>(false);
  const [rowCheckboxState, setRowCheckboxState] = useState<{ [key: string]: boolean }>(Object.fromEntries(showLead.map((lead) => [lead.id, false])));
  const [open, setOpen] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState(false);
  const [managersandSalesExecutive, setManagersandSalesExecutive] = useState<ManagerSalesExecutive[]>([]);

  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();

  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const edit = useSelector(selectBulkEdit);
  const leadsloader = useSelector(selectLoading);
  const clientFilterData = useSelector(selectClientsFilter);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page');
  const [currentPage, setCurrentPage] = useState(parseInt(page ?? '1'));

  const getTotalCount = async () => {
    const repository = new LeadRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const totalCount = await repository.getAllUnassignedCount(currentUser, search, clientFilterData.status, clientFilterData.priority, currentPage);

      return totalCount;
    }
    return 0;
  };

  const getAllLeads = async () => {
    setloading(true);
    const repository = new LeadRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    await repository
      .getAllUnassigned(currentUser, search, clientFilterData.status, clientFilterData.priority)
      .then((res) => {
        setshowLead(res);
        setOpenBulkEditLeadModal(false);
        setOpen(false);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };

  const getMoreLeads = async (page: number) => {
    setloading(true);
    const repository = new LeadRepository();
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    await repository
      .getAllUnassigned(currentUser, search, clientFilterData.status, clientFilterData.priority, page)
      .then((res) => {
        setshowLead(res);
        setOpenBulkEditLeadModal(false);
        setOpen(false);
        setloading(false);
      })
      .catch((err) => {
        setshowLead([]);
        setloading(false);
      });
  };

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser?.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i]?.get('firstName'),
              lastName: managersAndSalesExecutives[i]?.get('lastName'),
              user: managersAndSalesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i]?.get('user').id,
              firstName: salesExecutives[i]?.get('user')?.get('firstName'),
              lastName: salesExecutives[i]?.get('user')?.get('lastName'),
              user: salesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser?.get('firstName'),
          lastName: currentUser?.get('lastName'),
          user: currentUser,
        };
        setManagersandSalesExecutive([userData]);
      }
    }
  };

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const updatedRowCheckboxState = Object.fromEntries(showLead.map((lead) => [lead.id, checked]));
    setRowCheckboxState(updatedRowCheckboxState);
  };

  const handleSwitchView = () => {
    const viewActive = view === EnumViews.Cards ? EnumViews.List : EnumViews.Cards;
    dispatch(onSwitchView(viewActive));
    setview(viewActive);
  };

  useEffect(() => {
    getAllLeads();
    getManagersandSalesExecutive();
  }, []);

  useEffect(() => {
    getAllLeads();
  }, [clientFilterData.status, clientFilterData.priority, leadsloader, search]);

  useEffect(() => {
    setHasPermission(hasPermissionForAction('Leads', 'View'));
  }, []);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <div>
          <Sidebar text="Clients" />
        </div>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            mt: 10,
            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
            minHeight: '100vh',
            overflow: 'auto',
            width: {
              sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
            },
            paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
            marginTop: {
              sm: navigation ? '0px' : `130px`,
            },
          }}
        >
          {hasPermission ? (
            <Box width={'100%'} padding={3}>
              <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'} marginBottom={'10px'}>
                <Button
                  className="btn-add"
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: '/clients' }));
                    navigate('/clients/new');
                  }}
                >
                  Add New Client
                </Button>

                <Box
                  component={'span'}
                  display={'flex'}
                  gap={'20px'}
                  sx={{
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                    width: {
                      xs: '100%',
                      md: 'auto',
                    },
                    marginTop: {
                      xs: '20px',
                      md: '0px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: 'flex',
                      },
                      gap: {
                        xs: '5px',
                        md: `15px`,
                      },
                    }}
                  >
                    {showLead.length > 0 && currentUser?.get('role') !== UserRole.executive && (
                      <BulkEditLeads
                        color={color}
                        bgcolor={mode === 'dark' ? globalColors.black : globalColors?.lightgray}
                        setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                      />
                    )}
                    {(showLead.length > 0 || search !== '') && (
                      <SearchInput
                        value={search}
                        onChange={(e) => {
                          setsearch(e.target.value);
                        }}
                        className="input-search"
                        placeholder="Search Clients"
                      />
                    )}
                  </Box>
                  {showLead.length > 0 && (
                    <Box display={'flex'} width={'100%'} justifyContent={'space-between'} gap={'20px'}>
                      <FilterClients setOpen={setOpen} open={open} />
                      <ButtonView className="btn-view" onChangeView={handleSwitchView} defaultView={view} view={view} />
                    </Box>
                  )}
                </Box>
              </Box>
              <Typography
                sx={{ color: color || globalColors.blue, textAlign: 'right', alignItems: 'end', cursor: 'pointer' }}
                onClick={() => dispatch(ClientFilterDataFtn({ status: '', priority: '' }))}
              >
                {clientFilterData.status !== '' || clientFilterData.priority !== '' ? 'Clear Filter' : ''}
              </Typography>

              {view === EnumViews.Cards ? (
                <>
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      {showLead.length > 0 ? (
                        <>
                          {currentUser?.get('role') !== UserRole.executive && edit && (
                            <Checkbox
                              sx={{
                                color: color !== 'default' && color !== '' ? color : globalColors.blue,
                                '&.Mui-checked': {
                                  color: color || globalColors.secondBlue,
                                },
                              }}
                              onChange={handleHeaderCheckboxChange}
                              checked={Object.values(rowCheckboxState).length > 0 && Object.values(rowCheckboxState).every((isChecked) => isChecked)}
                            />
                          )}

                          <Grid container spacing={2}>
                            {showLead.map((val, idx) => (
                              <Grid key={idx} item xs={12} md={6} lg={4}>
                                <ClientsCard
                                  mode={mode}
                                  content={val}
                                  color={color !== 'default' && color !== '' ? color : ''}
                                  setloading={setloading}
                                  edit={edit}
                                  rowCheckboxState={rowCheckboxState}
                                  setRowCheckboxState={setRowCheckboxState}
                                  setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                                  openBulkEditLeadModal={openBulkEditLeadModal}
                                  managersandSalesExecutive={managersandSalesExecutive}
                                />
                              </Grid>
                            ))}
                          </Grid>
                          <Paginator
                            getTotalCount={getTotalCount}
                            onChangePage={async (newPage) => {
                              await getMoreLeads(newPage);
                              return true;
                            }}
                          />
                        </>
                      ) : (
                        <>
                          {search !== '' || clientFilterData.status !== '' ? (
                            <Typography
                              sx={{
                                color: color || globalColors.blue,
                                textAlign: 'center',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100vh',
                              }}
                            >
                              No Client Found
                            </Typography>
                          ) : (
                            <NotFoundLottie
                              showButton={true}
                              text="Looks like you have no unassigned leads yet. Press the button below and add a new client right now."
                              buttonText="Add New Client"
                              navigateTo="/clients/new"
                              setAddNewPath="/clients"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <ClientsList
                    mode={mode}
                    loading={loading}
                    showLead={showLead}
                    search={search}
                    color={color !== 'default' && color !== '' ? color : ''}
                    setloading={setloading}
                    edit={edit}
                    rowCheckboxState={rowCheckboxState}
                    setRowCheckboxState={setRowCheckboxState}
                    setOpenBulkEditLeadModal={setOpenBulkEditLeadModal}
                    openBulkEditLeadModal={openBulkEditLeadModal}
                    showAssignedTo={false}
                  />
                  <Paginator
                    getTotalCount={getTotalCount}
                    onChangePage={async (newPage) => {
                      await getMoreLeads(newPage);
                      return true;
                    }}
                  />
                </>
              )}
            </Box>
          ) : loading ? (
            <Loader />
          ) : (
            <NoPermission />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ViewClients;
