import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../store/selector';
import { globalColors } from '../../utils/constants/color';

interface LeadDropdownProps {
  values: any;
  height?: any;
  width?: any;
  mode: string;
  onChange: (_: any) => void,
  initialValue: any;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 196,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    backgroundColor:useSelector(selectThemeMode)==="dark" ? 'rgba(16, 16, 16, 1)': globalColors.white,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const StyledButton = styled(Button)<{ mode:string }>((props) => ({
  backgroundColor:  props.mode==="dark" ? 'rgba(16, 16, 16, 1)': globalColors.white,
  width: '196px',
  borderRadius: '10px',
  padding: '11px 16px',
  color: props.mode==="dark" ? globalColors.white: globalColors.black,
  '&:hover': {
    color:props.mode==="dark" ? globalColors.white: globalColors.black,
    backgroundColor: props.mode==="dark" ? 'rgba(16, 16, 16, 1)': globalColors.white,
  },
}));

export default function LeadDropdown({ values, height, onChange, initialValue }: LeadDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOption, setSelectedOption] = React.useState(initialValue);
  const color = useSelector(selectColor);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const mode = useSelector(selectThemeMode);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (value: string) => {
    setSelectedOption(value);
    onChange(value);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setSelectedOption(initialValue);
  }, [initialValue])

  return (
    <div>
      <StyledButton
      mode={mode}
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon style={{ marginLeft: '45%' }} />}
      >
        {selectedOption || values[0] || ''}
      </StyledButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {values.map((value: any) => (
          <MenuItem
            sx={{
              color: 'rgba(147, 147, 147, 1)',
              fontFamily: 'Poppins',
              fontWeight: '400',
              fontSIze: '12px',
              lineHeight: '18px',
              '&:hover': {
                borderRadius: '8px',
                color: color || globalColors.blue,
              },
            }}
            onClick={() => handleMenuItemClick(value)}
            disableRipple
            disabled={value == 'SELECT'}
          >
            {value}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={() => handleMenuItemClick('Duplicate')} disableRipple>
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => handleMenuItemClick('Archive')} disableRipple>
          Archive
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('More')} disableRipple>
          More
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}