import { useDispatch, useSelector } from 'react-redux';
import { EnumViews, onProjectLoading, onShowModalBulk, onSwitchView, setDataProjects } from '../../store/projects';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ProjectRepository from '../../utils/api/repositories/projectRepository';
import { selectProjectFilter, selectProjectState, selectProjectloader, selectRefreshProjectsState, selectSearchProject } from '../../store/selector';
import { setAddNew } from '../../store/routes';

const useProjects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectState = useSelector(selectProjectState);
  const filterData = useSelector(selectProjectFilter);
  const search = useSelector(selectSearchProject);
  const refreshProjects = useSelector(selectRefreshProjectsState);

  const handleCreateNewProject = () => {
    dispatch(setAddNew({ addNew: true, path: '/projects' }));
    navigate('/projects/new');
  };

  const handleSwitchView = () => {
    const viewActive = projectState.viewActive === EnumViews.Cards ? EnumViews.List : EnumViews.Cards;
    dispatch(onSwitchView(viewActive));
  };

  useEffect(() => {
    getAllData();
    // dispatch(onProjectLoading(true));
  }, [filterData.status, filterData.type, filterData.purpose, search]);

  useEffect(() => {
    getAllData();
  }, [refreshProjects]);
  
  useEffect(() => {
    getAllData();
  }, []);

  // useEffect(() => {
  //   document.title = 'Projects';
  // }, []);

  const getTotalCount = async () => {
    const projectRepository = new ProjectRepository();
    const totalCount = await projectRepository.getAllCount(search, filterData.status, filterData.type, filterData.purpose);
    return totalCount;
  };

  const getAllData = async () => {
    dispatch(onProjectLoading(true));
    const projectRepository = new ProjectRepository();
    const get = await projectRepository.getAll(search, filterData.status, filterData.type, filterData.purpose);
    dispatch(setDataProjects(get));
    dispatch(onProjectLoading(false));
    dispatch(onShowModalBulk(false));
  };

  const getMoreData = async (page: number) => {
    dispatch(onProjectLoading(true));
    const projectRepository = new ProjectRepository();
    const get = await projectRepository.getAll(search, filterData.status, filterData.type, filterData.purpose, page);
    dispatch(setDataProjects(get));
    dispatch(onProjectLoading(false));
    dispatch(onShowModalBulk(false));
  };

  return {
    projects: projectState.projects,
    view: projectState.viewActive,
    handleSwitchView,
    handleCreateNewProject,
    getMoreData,
    getTotalCount,
    isLoading: projectState.isLoading,
  };
};

export default useProjects;
