import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getRandomAnimation } from '../../../utils/functions';
import { Map, PropertyCloud } from '../../../assets/Images';
import { Button } from '../../atoms';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { forwardRef } from 'react';
import ScheduleModel from './scheduleModel';
import { globalColors } from '../../../utils/constants/color';

const PropertCloud = forwardRef((props, ref) => {

  const [openScheduleModal, setopenScheduleModal] = useState({open: false, from: ''});

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <Box
      bgcolor={globalColors.mainBackground}
      height={'100%'}
      display={'flex'}
      sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' }, padding: { lg: '5rem', xs: '3rem' } }}
      justifyContent={'space-between'}
      gap={'10px'}
    >
      <Box
        display={'flex'}
        ref={ref}
        flexDirection={'column'}
        gap={'10px'}
        data-aos={`fade-${getRandomAnimation()}`}
        data-aos-offset="50"
        data-aos-easing="ease-in-sine"
        order={{ xs: 2, lg: 1 }}
      >
        <Typography sx={{ fontSize: { lg: '50px', sm: '30px', xs: '25px' } }}>
          Become a regional partner and deliver market-leading real estate solutions.
        </Typography>
        <Typography sx={{ fontSize: { lg: '18px', sm: '16px', xs: '14px' } }}>
          Join us in our mission to transform the real estate industry. As a Regional Partner with Property Cloud, you'll be at the forefront of
          innovation, empowering real estate professionals in your region.
        </Typography>

        <Box marginTop={'3rem'}>
          <Button buttonColor={globalColors.blue} onClick={() => setopenScheduleModal({open: true, from: 'Regional Partnership'})}>Request Partnership</Button>
        </Box>
      </Box>

      <Box data-aos={`fade-${getRandomAnimation()}`} data-aos-offset="100" data-aos-easing="ease-in-sine" order={{ xs: 1, lg: 2 }}>
        <img style={{ width: '100%', maxWidth: '100%', objectFit: 'cover' }} src={Map} alt="" />
      </Box>
      <ScheduleModel
        open={openScheduleModal.open}
        onClose={() => {
          setopenScheduleModal({open: false, from: ''});
        }}
        from={openScheduleModal.from}
      />
    </Box>
  );
});

export default PropertCloud;
