import { Box, Grid, Typography } from '@mui/material';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ContentTable from './contentTable';
import ProjectCard from './projectCards';
import OrganizationRepository from '../../../../utils/api/repositories/organizationRepository';
import ProjectGraph from './projectGraph';
import { formatMoney } from '../../../../utils/helpers';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import { UserRole } from '../../../../store/user';
import Loader from '../../../atoms/loader';
import NoPermission from '../../../atoms/noPermission';
import TopPerformerProject from './topPerformer';

const ProjectPerfomance = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const [loadingFigures, setLoadingFigures] = useState(true);
  const [salesCount, setSalesCount] = useState(0);
  const [totalProjectsCount, setTotalProjectsCount] = useState(0);
  const [soldProjectsCount, setSoldProjectsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser?.get('role') === UserRole.admin) {
      setHasPermission(true);
    }
    const fetchCompanyFigures = async () => {
      const organizationRepository = new OrganizationRepository();
      const sales = await organizationRepository.getTotalSales(false, true);
      if (sales) {
        setSalesCount(sales);
      }
      const totalProjects = await organizationRepository.getProjectsCount();
      if (totalProjects) {
        setTotalProjectsCount(totalProjects);
      }
      const soldProjects = await organizationRepository.getSoldProjectsCount();
      if (soldProjects) {
        setSoldProjectsCount(soldProjects);
      }
      setLoadingFigures(false);
    };

    fetchCompanyFigures();
    setLoading(false);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Projects Performance'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? '80px' : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          <Box width={'100%'} padding={3}>
            <Box display={'flex'} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} justifyContent={'space-between'} gap={'30px'}>
              <ProjectCard value={formatMoney(salesCount)} title={'Total Sales'} loading={loadingFigures} iconName={'Sales'} />
              <ProjectCard value={totalProjectsCount} title={'Total Projects'} loading={loadingFigures} iconName={'Projects'} />
              <ProjectCard value={soldProjectsCount} title={'Total Sold'} loading={loadingFigures} iconName={'Sold'} />
              <ProjectCard value={totalProjectsCount - soldProjectsCount} title={'Total Pending'} loading={loadingFigures} iconName={'Pending'} />
            </Box>
            <TopPerformerProject />
            <Box width={'100%'} paddingTop={1} paddingBottom={1}>
              <Box marginTop={'1%'}>
                <ProjectGraph />
              </Box>
            </Box>
            <ContentTable />
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

export default ProjectPerfomance;
