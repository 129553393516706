import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectColor } from '../../store/selector';
import { globalColors } from '../../utils/constants/color';
import { useStyles } from '../organisms/dashboard/style';

const loaderStyle = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
};

interface LoaderProps {
  loaderColor?: string;
  size?: string;
}

const Loader = ({loaderColor, size}: LoaderProps) => {

  const color = useSelector(selectColor);

  return (
    <Box sx={loaderStyle}>
      <CircularProgress size={size} sx={{color: loaderColor ?? (color || globalColors.blue)}}/>
    </Box>
  );
};

export default Loader;
