import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import Loader from '../../components/atoms/loader';
import { useNavigate, useParams } from 'react-router';
import { LoginErrors } from '../../utils/helpers';
import UserRepository from '../../utils/api/repositories/userRepository';
import { useDispatch } from 'react-redux';
import { resetUserData } from '../../store/user';
import { setCloseSettings } from '../../store/routes';

interface LoginErrorProps {
  message: string;
}

const LoginError = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const processUserStatus = async () => {
      const userRepository = new UserRepository();
      if (id) {
        if (id == LoginErrors.paymentDue) {
          setMessage(
            'You are unable to access your Property Cloud dashboard due to a pending renewal payment. Please contact your account admin for further details.'
          );
          const logout = await userRepository.logout();
          if (logout) {
            dispatch(resetUserData());
            dispatch(setCloseSettings());
          }
          setTimeout(() => {
            navigate('/login');
          }, 8000);
        } else if (id == LoginErrors.actionRequired) {
          setMessage(
            'You are unable to access your Property Cloud dashboard due to a pending action required on your account. Please contact your account admin for further details.'
          );
          const logout = await userRepository.logout();
          if (logout) {
            dispatch(resetUserData());
            dispatch(setCloseSettings());
          }
          setTimeout(() => {
            navigate('/login');
          }, 8000);
        } else {
          const logout = await userRepository.logout();
          if (logout) {
            dispatch(resetUserData());
            dispatch(setCloseSettings());
          }
          setTimeout(() => {
            navigate('/login');
          }, 8000);
        }
      } else {
        const logout = await userRepository.logout();
          if (logout) {
            dispatch(resetUserData());
            dispatch(setCloseSettings());
          }
        setTimeout(() => {
          navigate('/login');
        }, 8000);
      }
    };

    processUserStatus();
  }, []);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      <Box display={'flex'} flexDirection={'column'} gap={'2rem'} alignItems={'center'} justifyContent={'center'} height={'100%'} padding={'2rem'}>
        <Loader loaderColor={globalColors.mainBlue} />
        <Typography variant="h6" textAlign={'center'}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginError;
