import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import AlertGroupModel from '../models/alertGroupModel';
import UserRepository from './userRepository';

class AlertGroupRepository {
    private className = 'AlertGroup';

    public async create(object: AlertGroupModel): Promise<Parse.Object | null> {
        try {
            const userRepository = new UserRepository();
            const currentUser = userRepository.getCurrentUser();

            if (currentUser) {
                const AlertGroup = Parse.Object.extend(this.className);
                const alertGroup = new AlertGroup();
        
                alertGroup.set('name', object.name);
                alertGroup.set('description', object.description);
                alertGroup.set('organization', currentUser.get('organization'));
                var members = alertGroup.relation('members');
                if (object.selectedMembers) {
                    for (let i = 0; i < object.selectedMembers.length; i++) {
                        const selectedMember = await userRepository.getObjectById(object.selectedMembers[i]);
                        if (selectedMember) {
                            members.add(selectedMember);
                        }
                    }
                }
        
                return new Promise((resolve, _) => {
                    alertGroup.save(null, { useMasterKey: true })
                    .then(async (savedAlertGroup: Parse.Object | null) => {
                        console.log(savedAlertGroup);
                        if (savedAlertGroup) {
                            Toast(`Alert Group created successfully!`, 'success');
                            resolve(savedAlertGroup);
                        }
                        else {
                            Toast(`Error creating alert group. Please try again.`, 'error');
                            resolve(null);
                        }
                    })
                    .catch((error: any) => {
                        console.log(error);
                        Toast(`Error creating alert group. Please try again.`, 'error');
                        resolve(null);
                    });
                });
            }
            else {
                Toast(`Error creating alert group. Please try again.`, 'error');
                return null;
            }


        } catch (error: any) {
            console.log(error);
            Toast(`Error creating alert group: ${error.message}`, 'error');
            return null;
        }
    }

    public async getAllAlertGroupsForOrganization(): Promise<AlertGroupModel[] | null> {
        try {

            const userRepository = new UserRepository();
            const currentUser = userRepository.getCurrentUser();

            if (currentUser) {
                const organizationId = currentUser.get('organization');
                const AlertGroup = Parse.Object.extend(this.className);
                const query = new Parse.Query(AlertGroup).equalTo('organization', organizationId);
                const queryAlertGroup = await query.find({ useMasterKey: true });
        
                const alertGroups: AlertGroupModel[] = [];
                await Promise.all(
                    queryAlertGroup.map(async (alertGroup: any) => {
                    const alertGroupData = alertGroup.toJSON();
        
                    const data = {
                        objectId: alertGroupData.objectId,
                        name: alertGroupData.name,
                        description: alertGroupData.description,
                    } as AlertGroupModel;
        
                    alertGroups.push(data);
                    })
                );
        
                return alertGroups;
            }
            else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching alert group by ID:', error);
            return null;
        }
    }

    public async getAllMembersForAlertGroup(objectId: string): Promise<Parse.Object[] | null> {
      try {
          const AlertGroup = Parse.Object.extend(this.className);
          const query = new Parse.Query(AlertGroup).equalTo('objectId', objectId);
          const queryAlertGroup = await query.find({ useMasterKey: true });

          var usersList: Parse.Object[] = [];
          await Promise.all(
            queryAlertGroup.map(async (alertGroup: Parse.Object) => {
              const membersQuery = alertGroup.relation('members').query();

              const members = await membersQuery.find();

              if (members) {
                usersList = members;
              }           
            
            })
          );
          return usersList;
      } catch (error) {
          console.error('Error fetching alert group by ID:', error);
          return null;
      }
    }
  
}

export default AlertGroupRepository;
