import { Box, Grid, Typography, styled } from '@mui/material';
import Sidebar from '../../sidebar';
import { globalColors } from '../../../../utils/constants/color';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../../store/selector';
import { drawerWidth } from '../../../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormEmployeeStyle, HyperLinkStyle, InputStyle } from './form.style';
import { FieldInput } from '../../../atoms';
import { FaAngleRight } from 'react-icons/fa6';
import CommunicationHistory from './communicationHistory';
import { useParams } from 'react-router-dom';
import LeadRepository from '../../../../utils/api/repositories/leadRepository';
import LeadModel from '../../../../utils/api/models/leadModel';
import Dropdowndata from '../../../atoms/dropdown2';
import { UserRole } from '../../../../store/user';
import SalesExecutiveRepository from '../../../../utils/api/repositories/salesExecutiveRepository';
import UserRepository from '../../../../utils/api/repositories/userRepository';

export interface FormValues {
  name: string;
  salesExecutive: string;
  assigned: string;
  paidAmount: string;
  email: string;
  phone: string;
  conversionTime: string;
}

export interface FormValuesErrors {
  name: string;
  salesExecutive: string;
  assigned: string;
  paidAmount: string;
  email: string;
  phone: string;
  conversionTime: string;
}

type ManagerSalesExecutive = {
  id: string;
  firstName: string;
  lastName: string;
  user: any;
};

const ConversationPage = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    salesExecutive: '',
    assigned: '',
    paidAmount: '',
    email: '',
    phone: '',
    conversionTime: '',
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [assignedTo, setassignedTo] = useState<string>('auto');
  const [lead, setLead] = useState<LeadModel | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [managersandSalesExecutive, setManagersandSalesExecutive] = useState<ManagerSalesExecutive[]>([]);
  const [showAssigningOptions, setShowAssigningOptions] = useState(true);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const { id } = useParams();

  const onSubmit = async (values: any, actions: FormikHelpers<FormValues>) => {
    // console.log('submit', values);
    const conversionGroup: LeadModel = {
      firstName: values?.name,
      email: values?.email,
      primaryPhone: values?.phone,
      // salesExecutive: values?.salesExecutive,
      assignedTo: values.assigned === '' ? (managersandSalesExecutive.length > 0 ? managersandSalesExecutive[0].id : '') : values.assigned,
      paidAmount: values?.paidAmount,
      conversionTime: values?.conversionTime,
    };
    // console.log('conversionGroup', conversionGroup);
    if (id) {
      setIsUpdating(true);
      const conversionRepository = new LeadRepository();
      const response = await conversionRepository.update(id, conversionGroup);

      if (response) {
        console.log('Updated');
        // actions.resetForm();
      }
      setIsUpdating(false);
    }
  };

  const getAllLeads = async () => {
    const repository = new LeadRepository();
    if (id) {
      await repository
        .getModelById(id)
        .then((res) => {
          // console.log('res',res)
          setLead(res);
          setFormValues({
            name: res?.firstName || '',
            salesExecutive: '',
            assigned: res?.assignedTo.get('id') ?? 'Select',
            paidAmount: res?.paidAmount || '',
            email: res?.email || '',
            phone: res?.primaryPhone || '',
            conversionTime: res?.conversionTime || '',
          });
        })
        .catch((err) => {
          setLead(null);
        });
    }
  };

  const getManagersandSalesExecutive = async () => {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const userRole = currentUser.get('role');
      if (userRole === UserRole.admin) {
        const userRepository = new UserRepository();
        const managersAndSalesExecutives = await userRepository.getAllManagersAndSalesExecutives();

        if (managersAndSalesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < managersAndSalesExecutives.length; i++) {
            const userData = {
              id: managersAndSalesExecutives[i].id,
              firstName: managersAndSalesExecutives[i].get('firstName'),
              lastName: managersAndSalesExecutives[i].get('lastName'),
              user: managersAndSalesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      } else if (userRole === UserRole.manager) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutives = await salesExecutiveRepository.getAllManagedBy();
        if (salesExecutives) {
          const users: ManagerSalesExecutive[] = [];
          for (let i = 0; i < salesExecutives.length; i++) {
            const userData = {
              id: salesExecutives[i].get('user').id,
              firstName: salesExecutives[i].get('user').get('firstName'),
              lastName: salesExecutives[i].get('user').get('lastName'),
              user: salesExecutives[i],
            };
            users.push(userData);
          }
          setManagersandSalesExecutive([...users]);
        }
      }
      if (userRole === UserRole.executive) {
        const userData = {
          id: currentUser.id,
          firstName: currentUser.get('firstName'),
          lastName: currentUser.get('lastName'),
          user: currentUser,
        };
        setManagersandSalesExecutive([userData]);
        setShowAssigningOptions(false);
      }
    }
  };

  useEffect(() => {
    getAllLeads();
    getManagersandSalesExecutive();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Conversions Page'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        <Box width={'100%'} paddingTop={3} paddingLeft={3} paddingRight={3} paddingBottom={1}>
          <Box marginBottom={'10px'}></Box>
          {/* Lead Assignment Container */}
          <AssignmentContainer
            bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
            padding={'20px'}
            borderRadius={'16px'}
            color={globalColors.white}
            fontSize={'12px'}
            boxShadow={'#171717'}
            marginTop={'1rem'}
          >
            <Formik
              enableReinitialize
              initialValues={{
                name: formValues.name,
                salesExecutive: formValues.salesExecutive,
                assigned: formValues.assigned,
                paidAmount: formValues.paidAmount,
                email: formValues.email,
                phone: formValues.phone,
                conversionTime: formValues.conversionTime,
              }}
              validate={(values) => {
                const errors = {} as FormValuesErrors;

                return errors;
              }}
              onSubmit={onSubmit}
            >
              {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
                <Form>
                  <Box
                    borderRadius={'20px'}
                    marginBottom={'2px'}
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    padding={{ xs: '0px', md: '20px' }}
                  >
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <Grid item md={6} xs={12} pr={'10px'} pl={'10px'} mt={'0.8rem'} gap={'0.8rem'} display={'flex'} flexDirection={'column'}>
                        <FieldInput label="Name" id="name" value={values.name} mode={mode} name={'name'} placeholder={'Employee Name'} type="text" />
                        <FieldInput label="Phone" id="firstName" value={values.phone} mode={mode} name={'phone'} placeholder={'Phone'} type="text" />
                        <FieldInput label="Email" id="email" value={values.email} mode={mode} name={'email'} placeholder={'Email'} type="text" />
                      </Grid>

                      <Grid item md={6} xs={12} pr={'10px'} pl={'10px'} mt={'0.8rem'} gap={'0.8rem'} display={'flex'} flexDirection={'column'}>
                        <FieldInput
                          label="Sales Executive"
                          id="salesExecutive"
                          value={values.salesExecutive}
                          mode={mode}
                          name={'salesExecutive'}
                          placeholder={'Example'}
                          type="text"
                        />
                        {/* <FieldInput
                          label="Assigned project/Property"
                          id="assigned"
                          value={values.assigned}
                          mode={mode}
                          name={'assigned'}
                          placeholder={'Example'}
                          type="text"
                        /> */}
                        {/* <Box sx={{ width: '100%' }}>
                          <Typography
                            sx={{
                              marginBottom: '1rem',
                              fontFamily: 'Poppins',
                              fontSize: '16px',
                              fontWeight: 400,
                              letterSpacing: '0px',
                              textAlign: 'left',
                              color: mode === 'dark' ? globalColors.white : globalColors.black,
                            }}
                          >
                            Assigned project/Property
                          </Typography>
                          <HyperLinkStyle mode={mode} id="assigned">
                            <Typography
                              sx={{
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontWeight: 500,
                                letterSpacing: '0px',
                                textAlign: 'left',
                                textDecoration: 'underline',
                                color: color || globalColors.blue,
                              }}
                            >
                              Project/Property Name
                            </Typography>
                            <FaAngleRight size={'1rem'} color={color || globalColors.blue} />
                          </HyperLinkStyle>
                        </Box> */}
                        <Dropdowndata
                          label="Assigned project/Property"
                          id="assigned"
                          name="assigned"
                          optional={false}
                          mode={mode}
                          onChange={(e) => {
                            setFieldValue('assigned', e.target.value);
                          }}
                          values={managersandSalesExecutive.map((user) => ({
                            label: `${user.firstName} ${user.lastName}`,
                            value: user.id,
                          }))}
                          defaultValue={lead?.assignedTo.id ?? 'Select'}
                        />
                        <FieldInput
                          label="Paid Amount"
                          id="paidAmount"
                          value={values.paidAmount}
                          mode={mode}
                          name={'paidAmount'}
                          placeholder={'Paid Amount'}
                          type="text"
                        />
                        <FieldInput
                          label="Conversion time"
                          id="conversionTime"
                          value={values.conversionTime}
                          mode={mode}
                          name={'conversionTime'}
                          placeholder={'Conversion Time'}
                          type="text"
                        />
                      </Grid>

                      {/* Button */}
                      <Box sx={{ width: '100%', marginTop: '10px', display: 'flex', justifyContent: 'end' }}>
                        <ButtonStyle type="submit" color={color} mode={mode}>
                          {isUpdating ? 'Updating...' : 'Update Conversions'}
                        </ButtonStyle>
                      </Box>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </AssignmentContainer>

          <AssignmentContainer
            bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
            padding={'20px'}
            borderRadius={'16px'}
            color={globalColors.white}
            fontSize={'12px'}
            boxShadow={'#171717'}
            marginTop={'1rem'}
          >
            <CommunicationHistory color={color} mode={mode} />
          </AssignmentContainer>
        </Box>
      </Box>
    </Box>
  );
};

const AssignmentContainer = styled(Box)({
  height: '100%',
  width: '100%',
});

const ButtonStyle = styled('button')<any>(({ color, disabled, type }) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? '0.5' : '1',
  height: '40px',
  backgroundColor: color !== 'default' && color !== '' ? color : type === 'reset' ? globalColors.blackLight : globalColors.blue,
  color: globalColors.white,
  width: '181px',
  padding: '0px 8px',
  display: 'grid',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '25px',
  transition: 'opacity 0.3s ease',
  border: type === 'reset' ? `1px solid ${color ? color : globalColors.lightblue}` : '',
  '&:hover': {
    opacity: disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default ConversationPage;
