import { Box, Radio, Typography } from '@mui/material';
import { Button } from '../atoms';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';
interface CardPlanProps {
  title: string;
  price: number;
  paymentCycle: string;
  index: number;
  content: string[];
  extraContent: string[];
  bgcolor?: string;
  modeSensitive?: boolean;
  showAnimation?: boolean;
}

const CardPlanShow = ({ title, price, paymentCycle, content, extraContent, index, modeSensitive = false, showAnimation = true }: CardPlanProps) => {
  const mode = useSelector(selectThemeMode);

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <Box
      data-aos={showAnimation && (index === 0 ? 'fade-right' : index === 1 ? 'fade-left' : '')}
      data-aos-offset={showAnimation && '50'}
      data-aos-delay={showAnimation && '300'}
      data-aos-easing={showAnimation && 'ease-in-sine'}
      data-aos-duration={showAnimation && '500'}
      sx={{
        border: '1px solid #599BF7',
        position: 'relative',
        width: '100%',
        // height: '100%',
        paddingBottom: '80px',
        borderRadius: '16px',
        // cursor: 'pointer',
        // '&::before': {
        //   content: '""',
        //   position: 'absolute',
        //   // top: 0,
        //   left: 0,
        //   bottom: 0,
        //   width: '0',
        //   color: 'white',
        //   borderRadius: '16px',
        //   height: '0',
        //   // backgroundColor:
        //   // 'linear-gradient(45deg, rgba(4, 85, 198, 0.7) 15.75%, #121C2B 26.11%, #121C2B 81.06%),linear-gradient(0deg, #599BF7, #599BF7)',
        //   // backgroundColor: '#EDA841',
        //   transition: 'height 0.6s ease',
        //   zIndex: 1,
        // },

        // '&:hover': {
        //   '&::before': {
        //     content: '""',
        //     position: 'absolute',
        //     border: '1px solid #599BF7',
        //     bottom: 0,
        //     left: 0,
        //     width: '100%',
        //     borderRadius: '16px',
        //     height: '100%',
        //     background: `linear-gradient(161.01deg, rgba(4, 85, 198, 0.7) 15.75%, ${
        //       modeSensitive ? (mode === 'dark' ? '#121C2B' : '#eee') : '#121C2B'
        //     }  76.11%, ${modeSensitive ? (mode === 'dark' ? '#121C2B' : '#eee') : '#121C2B'} 81.06%),linear-gradient(0deg, #599BF7, #599BF7)`,
        //     color: 'white',
        //     transition: 'height 0.6s ease',
        //   },
        //   // transform: 'scale(1.05)',
        //   transition: 'transform 0.3s ease',
        //   // '& .inner-box': {
        //   //   backgroundColor: '#F6F6F6',
        //   //   color: 'black',
        //   // },
        // },
      }}
    >
      {/* <div onClick={() => {setSelectedIndex(index)}} style={{width: '100%'}}> */}
      <Typography
        position={'relative'}
        sx={{ fontSize: { xs: '20px', lg: '30px' }, paddingTop: '20px' }}
        alignContent={'center'}
        textAlign={'center'}
      >
        {title}
      </Typography>
      <Box display={'flex'} justifyContent={'center'} marginTop={'10px'} sx={{}}>
        <Box
          className="inner-box"
          sx={{
            // backgroundColor: title === 'Premium' ? '#F6F6F6' : '#0455C6',
            // color: title === 'Premium' ? '#101010' : '#F6F6F6',
            borderRadius: '80px 0px 80px 80px',
            width: '148px',
            // height: { xs: '120px', sm: '120px' },
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            transition: 'background-color 0.3s', // Add this line
            padding: '10px',
          }}
        >
          <Typography
            sx={{ fontSize: '26px' }}
            textAlign={'center'}
            alignItems={'center'}
            alignContent={'center'}
            noWrap
            color={'inherit'}
            fontWeight={'bold'}
          >
            {`$${price}`}
          </Typography>
          <Typography sx={{ fontSize: '14px' }} textAlign={'center'} alignItems={'center'} alignContent={'center'} noWrap fontWeight={'light'}>
            per month
          </Typography>
          <hr style={{ width: '100%', marginTop: '20px', marginBottom: '20px', backgroundColor: globalColors.gray }} />
          <Typography sx={{ fontSize: '14px' }} textAlign={'center'} alignItems={'center'} alignContent={'center'} noWrap fontWeight={'light'}>
            <span style={{ fontWeight: 'bold' }}>{paymentCycle}</span> Plan
          </Typography>

          <hr style={{ width: '100%', marginTop: '20px', marginBottom: '20px', backgroundColor: globalColors.gray }} />
        </Box>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        margin={'auto'}
        alignContent={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'10px'}
        marginTop={'20px'}
        sx={{}}
      >
        {content.map((val) => (
          <Typography
            sx={{
              fontSize: { xs: '12px', lg: '14px' },
              textAlign: 'center',
              fontWeight: 400,
            }}
            key={val}
          >
            {val}
          </Typography>
        ))}
        {extraContent.map((val) => (
          <Typography
            sx={{
              fontSize: { xs: '12px', lg: '14px' },
              textAlign: 'center',
              fontWeight: 800,
              color: globalColors.blue,
            }}
            key={val}
          >
            {val}
          </Typography>
        ))}
      </Box>
      {/* </div> */}
    </Box>
  );
};

export default CardPlanShow;
