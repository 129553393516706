import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { globalColors } from '../../utils/constants/color';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../../components/molecules/paymentForm/paymentForm';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import Loader from '../../components/atoms/loader';
import { useNavigate, useParams } from 'react-router';
import OnboardingRepository from '../../utils/api/repositories/onboardingRepository';

const OnboardingPayment = () => {
  const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [amountDue, setAmountDue] = useState(0);
  const [onboardingObject, setOnboardingObject] = useState<Parse.Object | null>(null); 
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchClientSecret = async () => {
    const objectId = id ?? '';
    if (objectId !== '' && amountDue !== 0) {
      const organizationRepository = new OrganizationRepository();
      const paymentDetail = await organizationRepository.initiatePayment(amountDue * 100, 'usd', 'onboarding', objectId, onboardingObject?.get('organizationName'), onboardingObject?.get('ownerEmail'));
      if (paymentDetail) {
        return paymentDetail;
      }
      else {
        return '';
      }
    }
    return '';
  };

  useEffect(() => {
    const fetchOnboardingDetails = async () => {
      const objectId = id ?? '';
      if (objectId !== '') {
        const onboardingRepository = new OnboardingRepository();
        const onboarding = await onboardingRepository.getObjectById(objectId);
        if (onboarding) {
          setOnboardingObject(onboarding);
          if (onboarding.get('completed')) {
            setLoading(false);
            setError('This payment has been successfully paid. Taking you to login...');
            setTimeout(() => {
              navigate('/login');
            }, 6000);
          }
          else {
            setAmountDue(parseFloat(onboarding.get('amountDue').toFixed(2)));
            setName(onboarding.get('fullName'));
            const publishableKey = process.env.REACT_APP_STRIPE_PK_TEST;
            if (publishableKey) {
              setStripe(loadStripe(publishableKey));
            }
            setLoading(false);
          }
          
        } else {
          setLoading(false);
          setError('Error fetching your payment details. Please select your preferences and come back again. Taking you back...');
          setTimeout(() => {
            navigate('/onboarding/tier');
          }, 6000);
        }
      } else {
        setLoading(false);
        setError('Error fetching your payment details. Please select your preferences and come back again. Taking you back...');
        setTimeout(() => {
          navigate('/onboarding/tier');
        }, 6000);
      }
    };

    fetchOnboardingDetails();
  }, []);

  return (
    <Box minHeight={'100vh'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} color={globalColors.white}>
      <Box className="bgleftshade" position={'absolute'}></Box>
      <Box className="bgrightshade" position={'absolute'}></Box>
      {loading ? (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Loader loaderColor={globalColors.mainBlue} />
        </Box>
      ) : error !== '' ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'2rem'}
          alignItems={'center'}
          justifyContent={'center'}
          height={'100%'}
          padding={'2rem'}
        >
          <Loader loaderColor={globalColors.mainBlue} />
          <Typography variant="h6" textAlign={'center'}>
            {error}
          </Typography>
        </Box>
      ) : (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={'2rem'} padding={'3rem'}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} maxWidth={'md'} flexDirection={'column'} gap={'1rem'}>
            <Typography variant="h6" textAlign={'center'} sx={{ color: globalColors.white }}>
              Hi <span style={{ color: globalColors.mainBlue }}>{name}</span>,<br />
              please initiate your total payment of 
              <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>&nbsp;${amountDue.toFixed(2)}&nbsp;</span>
              to successfully complete the onboarding process and become part of the <span style={{ fontWeight: 'bold', color: globalColors.mainBlue }}>Property Cloud</span> network.
            </Typography>
            <Typography textAlign={'center'} sx={{ color: globalColors.gray }}>
              (You can visit this URL at anytime to complete this payment, if you're not ready yet. It has also been emailed to the admin email address you entered while onboarding.)
            </Typography>
          </Box>
          {stripe && amountDue !== 0 && (
            <Elements
              stripe={stripe}
              options={{
                mode: 'payment',
                currency: 'usd',
                amount: amountDue * 100,
                loader: 'always',
                appearance: {
                  theme: 'night',
                  labels: 'floating',
                  variables: {
                    colorPrimaryText: globalColors.white,
                    focusOutline: `1px solid ${globalColors.mainBlue}`,
                    fontFamily: 'sans-serif',
                    colorPrimary: globalColors.mainBlue,
                    colorTextPlaceholder: globalColors.gray,
                    colorText: globalColors.white,
                    colorTextSecondary: globalColors.white,
                    colorBackground: globalColors.blackLight,
                    colorDanger: globalColors.red,
                  },
                },
              }}
            >
              {/* <PaymentForm successURL={`/onboarding/secure/payment/success/${id}`}/> */}
              <PaymentForm successURL={`https://propertycloud.ai/onboarding/secure/payment/confirmation`} preProcessingPayment={fetchClientSecret}/>
            </Elements>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OnboardingPayment;
