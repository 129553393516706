import { Box, Typography } from '@mui/material';
import CardPlan from '../../molecules/cardPlan';
import { PricingTiers } from '../../../utils/helpers';

const Section6 = () => {
  const Plan = [
    {
      title: PricingTiers.Essential,
      price: 99,
      content: [
        'Projects & Properties',
        'Inventory Management',
        'Leads Management',
        'Lead Scoring',
        'Documents Upload',
        'Reports & Analytics',
        'Dashboard Customization',
        'Social Media Integration',
        'HR & Payroll',
      ],
      extraContent: [],
    },
    {
      title: PricingTiers.Professional,
      price: 120,
      content: [
        'Projects & Properties',
        'Inventory Management',
        'Leads Management',
        'Lead Scoring',
        'Documents Upload',
        'Reports & Analytics',
        'Dashboard Customization',
        'Social Media Integration',
        'HR & Payroll',
      ],
      extraContent: [
        'Cloud AI Lead Assignment',
        'Company-wide Alerts',
        'Social Media Management',
        'Task Assignment',
        'Emails & Templates',
        'Calendar Scheduling',
      ],
    },
    {
      title: 'Custom',
      price: 'Custom Pricing',
      content: [
        'Choose Your Features',
        'Tailoured to Your Needs',
        'Pay for What You Use',
        'Select What You Want to Manage',
        'Customized Scoring Criteria',
        'Choose from Our Automated Workflows',
        'Leverage Our AI',
        'Selective Commission Tracking',
        'Customized Reports & Analytics',
        'Integrate What You Like',
      ],
      extraContent: [],
    },
  ];
  return (
    <Box sx={{ padding: { lg: '5rem', xs: '3rem' } }}>
      <Typography
        sx={{
          fontSize: { xs: '20px', sm: '30px', lg: '50px' },
        }}
        whiteSpace={'normal'}
      >
        {' '}
        <Typography sx={{ color: '#0455C6', margin: '0', marginBottom: '0px', fontSize: { xs: '20px', sm: '30px', lg: '50px' } }} fontSize={'50px'}>
          Pricing:&nbsp;
        </Typography>{' '}
        Find the Plan that Fits Your Needs
      </Typography>
      <Box display={'flex'} sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' } }} gap={'30px'} marginTop={'3rem'}>
        {Plan.map((val, index) => (
          <CardPlan title={val.title} content={val.content} extraContent={val.extraContent} price={val.price.toString()} index={index} />
        ))}
      </Box>
    </Box>
  );
};

export default Section6;
