import { styled } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';
export const FormAlertsStyle = styled('form')(({ theme }) => ({
  gap: '1rem',
  '& .row': {
    display: 'flex',
    alignItems: 'center',
    gap: '3.5rem',

    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      flexDirection: 'column',
      alignItems: 'start',

      '& button': {
        width: '100%',
      },
    },

    '& .label': {
      width: '200px',
    },

    '& .col': {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '&.btn-wrapper': {
      margin: '1rem 0',
      justifyContent: 'center',
    },
  },

  '& .column': {
    // alignItems: 'center',
    gap: '3.5rem',
    // width: '100%',

    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      flexDirection: 'column',
      alignItems: 'start',

      '& button': {
        width: '100%',
      },
    },

    '& .label': {
      width: '200px',
    },

    '& .col': {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    '&.btn-wrapper': {
      margin: '1rem 0',
      justifyContent: 'center',
    },
  },

  '& .address': {
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .group-input': {
      gap: '1rem',
      '& .item': {
        width: '100%',
        color: globalColors.text.secondary,
      },
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
  },

  '& .group-row': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  '& .group-column': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  '& .area-wrapper': {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    '& .area': {
      flex: 1,
    },
    '& .area-unit': {
      width: '15%',
      minWidth: '160px',
    },
  },

  [theme.breakpoints.down('md')]: {
    '& .area-wrapper': {
      flexDirection: 'column',
      '& .area-unit': {
        width: '100%',
        minWidth: '160px',
      },
    },
  },
}));

export const InputStyle = styled('input')<any>(({ theme, mode }) => ({
    width: '100%',
  height: '48px',
  marginTop: '4px',
  // marginLeft: '32px',
  padding: '8px 16px 8px 16px',
  borderRadius: '12px',
  border: '1px solid rgba(48, 48, 48,1)',
  backgroundColor: useSelector(selectThemeMode) === 'dark' ? globalColors.black : globalColors.lightgray,
  color: mode === 'dark' ? globalColors?.white : globalColors.black,
  outline: 'none',
  '&:focus': {
    border: '1px solid rgba(48, 48, 48,1', // Change border color on focus
  },
}));

interface CrossFileContainerProps {
  selectedFile: File | null;
  theme: any;
  mode: string;
}

export const crossFileContainerStyle = ({ selectedFile, theme, mode }: CrossFileContainerProps) => ({
  padding: '5px 0px 5px 1%',
  width: 177,
  height: 48,
  borderRadius: 12,
  border: `1px solid ${mode === 'dark' ? 'none' : globalColors.blackLight}`,
  position: 'relative',
  cursor: 'pointer',
});

export const imageNameStyle: React.CSSProperties = {
  // color: 'rgba(246, 246, 246, 1)',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '32px',
  letterSpacing: '0px',
  textAlign: 'left',
};

interface IconButtonProps {
  theme: any;
  mode: string;
}

export const iconButtonStyle = ({ theme, mode }: IconButtonProps) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '8px',
  color: mode === 'dark' ? globalColors.white : globalColors.black,
});
